import {Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {DocumentRef} from './document-ref';
import {FacadePlatform} from '../facade-platform';


@Injectable()
export class WindowRef {

    scrollingCnt = 0;
    scrollingRef:number;
    isBrowser;

    constructor(injector:Injector,
                private documentRef:DocumentRef,
                private facadePlatform:FacadePlatform) {
        this.isBrowser = isPlatformBrowser(injector.get(PLATFORM_ID));
    }

    get nativeWindow() {
        return this.documentRef.nativeDocument.defaultView;
    }

    get navigator():Navigator {
        return this.nativeWindow.navigator;
    }

    get geolocation():Geolocation {
        return this.navigator.geolocation;
    }

    get innerWidth():number {
        return this.nativeWindow.innerWidth;
    }

    get innerHeight():number {
        return this.nativeWindow.innerHeight;
    }

    get href():string {
        return this.nativeWindow.location.href;
    }

    get origin():string {
        return this.nativeWindow.location.origin;
    }

    get pathname():string {
        return this.nativeWindow.location.pathname;
    }

    get pageYOffset():number {
        return this.nativeWindow.pageYOffset;
    }

    get startupData() {
        return this.nativeWindow['startupData'];
    }

    get addThis() {
        return this.nativeWindow['addthis'];
    }

    get matchMedia() {
        return this.nativeWindow?.matchMedia;
    }

    get standalone() {
        return this.nativeWindow['standalone'];
    }

    // Do not use this ever. Use only LocalStorageUtil.instance
    /*get localStorage():Storage {
        return window.localStorage;
    }*/

    hasProperty(name:string):boolean {
        return !!this.nativeWindow[name];
    }

    addProperty(name:string, value) {
        this.nativeWindow[name] = value;
    }

    removeProperty(name:string) {
        delete this.nativeWindow[name];
    }

    reload() {
        this.nativeWindow.location.reload();
    }

    // Should this method be needed, update it to check the LocationStrategy
    /*setHref(href:string) {
        if (href.charAt(0) !== '/') {
            href = '/' + href;
        }
        if (environment.useHashUrl) {
            href                            = '/#' + href;
            this.nativeWindow.location.href = href;
            console.log('Calling reload as using a hash url');
            this.reload();
        }
        else {
            this.nativeWindow.location.href = href;
        }
    }*/

    open(href:string, target?:string, params?:any) {
        return this.nativeWindow.open(href, target, params);
    }

    scrollTo(x:number, y:number) {
        if (this.isBrowser) this.nativeWindow.scrollTo(x, y);
    }

    scrollToAnimate(y:number, duration = 300):Promise<any> {
        //console.log('Scrolling to position: ', y);
        // ripped from https://gist.github.com/xFlatlinex/a9acca823e85379ac9f6
        return new Promise(resolve => {
            // decelerating to zero velocity
            const outQuad = function (t) {
                return t * (2 - t);
            };

            // Create a reference to allow cancellations
            this.scrollingRef = this.scrollingCnt++;
            const localRef    = this.scrollingRef;

            //console.log('easing to: ', document.documentElement && document.documentElement.scrollTop);

            const start = Date.now();
            const elem  = <any>(this.documentRef.documentElement || this.documentRef.body.parentNode || this.documentRef.body);
            // Test that scrollTop actually works otherwise use doc.body
            /*const current = elem.scrollTop;
            elem.scrollTop = current + 1;
            if (elem.scrollTop !== current + 1) {
                console.log('Using body as document not working', elem.scrollTop, current);
                elem = document.body;
            }
            else {
                elem.scrollTop = current - 1;
            }*/
            const from = elem.scrollTop;

            if (from === y) {
                resolve(null);
                return; /* Prevent scrolling to the Y point if already there */
            }

            const scroll = (timestamp) => {
                if (localRef !== this.scrollingRef) {
                    resolve(null);
                    return;
                }
                const currentTime = Date.now();
                const time        = Math.min(1, ((currentTime - start) / duration));
                const easedT      = outQuad(time);

                elem.scrollTop = (easedT * (y - from)) + from;

                if (time < 1) {
                    requestAnimationFrame(scroll);
                }
                else {
                    //console.log('Completed scroll to position', localRef === this.scrollingRef, elem, elem.scrollTop, (easedT * (y - from)) + from);
                    resolve(null);
                }
            };

            requestAnimationFrame(scroll);
        });
    }

    get scrollY():number {
        return this.nativeWindow.scrollY != null ? this.nativeWindow.scrollY : this.nativeWindow.pageYOffset;
    }

    get scrollX():number {
        return this.nativeWindow.scrollX != null ? this.nativeWindow.scrollX : this.nativeWindow.pageXOffset;
    }

    addEventListener(type:string, listener?:EventListenerOrEventListenerObject, options?:boolean | EventListenerOptions) {
        return this.nativeWindow?.addEventListener(type, listener, options);
    }

    removeEventListener(type:string, listener?:EventListenerOrEventListenerObject, options?:boolean | EventListenerOptions) {
        return this.nativeWindow?.removeEventListener(type, listener, options);
    }

    addEventListenerRemovable(type:string, listener?:EventListener, options?:boolean | EventListenerOptions) {
        const innerListener = e => listener(e);
        this.nativeWindow.addEventListener(type, innerListener, options);
        return () => {
            this.nativeWindow.removeEventListener(type, innerListener);
        };
    }

    addFormAndSubmit(data:{ [key:string]:any }, formAction:string, formMethod = 'POST') {
        const id           = 'dynamicForm-' + new Date().getTime();
        const elementsHTML = Object.keys(data)
            .map(key => `<input type="hidden" name="${key}" value="${data[key]}" />`)
            .join('\n');
        const html         = `<form id="${id}" ngNoForm action="${formAction}" method="${formMethod}">${elementsHTML}</form>`;
        const div          = this.documentRef.createElement('div');
        div.innerHTML      = html;
        this.documentRef.body.appendChild(div);
        const formEle:any = this.documentRef.getElementById(id);
        formEle.submit();
    }

    getDevicePixelRatio():number {
        let pixRatio = 1; // default for desktop and mobile if pixel ratio is equal to 2 or less
        if (!this.facadePlatform.isDesktop && this?.nativeWindow?.devicePixelRatio > 2) {
            pixRatio = 2;
        }
        return pixRatio;
    }

    getCurrentPosition(options?:PositionOptions):Promise<GeolocationPosition> {
        return new Promise((resolve, reject) => {
            this.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }
}
