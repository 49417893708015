import {gql} from 'apollo-angular';

export const WP_GET_PAGES_CACHE = true;

export const WP_GET_PAGES = gql`
    query wpPages(
        $facade_url_tree: String
        $status: String
        $page: String
        $per_page: String
        $facade_parent_include_level: Int
        $include:String
    ) {
        wpPages(
            facade_url_tree: $facade_url_tree
            status: $status
            page: $page
            per_page: $per_page
            facade_parent_include_level: $facade_parent_include_level
            include:$include
        ) {
            total
            totalPages
            items {
                id
                title {
                    rendered
                }
                slug
                parent
                link
                template
                acf
            }
        }
    }
`;
