import {Injector} from '@angular/core';

// Must be implemented per implementation
export abstract class NewsletterSignUpDialogService {

    constructor(injector:Injector) {

    }

    abstract showDialog()
}
