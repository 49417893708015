import {gql} from 'apollo-angular';

export const GUEST_CART_OTP_VALIDATE = gql`
    mutation validateOtp_guestCart($pin: String!, $sessionId: String!) {
        validateOtp(pin: $pin, sessionId: $sessionId) {
            message
            status
        }
    }
`;
