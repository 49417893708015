import {Logger} from '../../shared/logger';
import {LogLevel} from '../../shared/logger-impl-core';

interface PromiseRef<T = any> {
    resolve?:() => void;
    reject?:(err) => void;
    promise?:Promise<any>;
}

export class ScriptLoader {
    private static _logger:Logger;

    private static loadCache:{ [key:string]:PromiseRef } = {};
    private static loadedCache:{ [key:string]:Boolean }  = {};

    // Understanding the difference between async and defer
    // https://www.growingwiththeweb.com/2014/02/async-vs-defer-attributes.html
    public static loadNewScript(src:string, retryCount = 0, asyncOrDefer:'async' | 'defer' = 'async'):Promise<any> {
        if (ScriptLoader.loadedCache[src]) {
            return Promise.resolve();
        }
        else if (ScriptLoader.loadCache[src]) {
            return ScriptLoader.loadCache[src].promise;
        }
        else {
            ScriptLoader.loadCache[src]         = {};
            const promise                       = new Promise<void>((_resolve, _reject) => {
                ScriptLoader.loadCache[src].resolve = _resolve;
                ScriptLoader.loadCache[src].reject  = _reject;
            });
            ScriptLoader.loadCache[src].promise = promise;


            let attempt       = 0;
            const tryNextLoad = () => {
                attempt++;
                ScriptLoader.runScriptLoad(src, attempt, asyncOrDefer).then(
                    () => {
                        const ref = ScriptLoader.loadCache[src];
                        delete ScriptLoader.loadCache[src];
                        ScriptLoader.loadedCache[src] = true;
                        ref?.resolve();
                    },
                    err => {
                        // If attempt is less than retryCount then lets try again
                        if (attempt <= retryCount) {
                            tryNextLoad();
                        }
                        else {
                            const ref = ScriptLoader.loadCache[src];
                            delete ScriptLoader.loadCache[src];
                            ref?.reject(err);
                        }
                    }
                );
            };
            tryNextLoad();

            return promise;
        }
    }

    public static removeScriptLoaded(src:string) {
        if (document?.head?.querySelectorAll) {
            const scriptTag = document?.head?.querySelector(`script[src="${src}"]`);
            if (scriptTag) {
                document.head.removeChild(scriptTag);
                delete ScriptLoader.loadCache[src];
                ScriptLoader.loadedCache[src] = false;
            }
        }
    }

    private static runScriptLoad(src:string, attempt:number, asyncOrDefer:'async' | 'defer') {
        return new Promise<void>((resolve, reject) => {
            ScriptLoader.logger.debug(`Loading new script attempt ${attempt}, with src: ${src}, is asyncOrDefer ${asyncOrDefer}`);

            const script = document.createElement('script');
            script.src   = src;
            switch (asyncOrDefer) {
                case 'async':
                    script.async = true;
                    break;
                case 'defer':
                    script.defer = true;
                    break;
            }
            script.onload  = () => resolve();
            script.onerror = () => reject('failed to load script ' + src);
            document.head.appendChild(script);
        });
    }

    public static loadNewCss(src:string, retryCount = 0) {
        if (ScriptLoader.loadedCache[src]) {
            return Promise.resolve();
        }
        else if (ScriptLoader.loadCache[src]) {
            return ScriptLoader.loadCache[src].promise;
        }
        else {
            ScriptLoader.loadCache[src]         = {};
            const promise                       = new Promise<void>((_resolve, _reject) => {
                ScriptLoader.loadCache[src].resolve = _resolve;
                ScriptLoader.loadCache[src].reject  = _reject;
            });
            ScriptLoader.loadCache[src].promise = promise;


            let attempt       = 0;
            const tryNextLoad = () => {
                attempt++;
                ScriptLoader.runCssLoad(src, attempt).then(
                    () => {
                        const ref = ScriptLoader.loadCache[src];
                        delete ScriptLoader.loadCache[src];
                        ScriptLoader.loadedCache[src] = true;
                        ref.resolve();
                    },
                    err => {
                        // If attempt is less than retryCount then lets try again
                        if (attempt <= retryCount) {
                            tryNextLoad();
                        }
                        else {
                            const ref = ScriptLoader.loadCache[src];
                            delete ScriptLoader.loadCache[src];
                            ref.reject(err);
                        }
                    }
                );
            };
            tryNextLoad();
            return promise;
        }
    }

    private static runCssLoad(src:string, attempt:number) {
        return new Promise<void>((resolve, reject) => {
            ScriptLoader.logger.debug(`Loading new styles attempt ${attempt}, with href: ${src}`);
            const styles   = document.createElement('link');
            styles.rel     = 'stylesheet';
            styles.type    = 'text/css';
            styles.href    = src;
            styles.onload  = () => resolve();
            styles.onerror = () => reject('failed to load styles ' + src);
            document.head.appendChild(styles);
        });
    }

    private static get logger():Logger {
        if (!ScriptLoader._logger) {
            ScriptLoader._logger          = new Logger().getLogger('ScriptLoader');
            ScriptLoader._logger.logLevel = LogLevel.NONE;
        }
        return ScriptLoader._logger;
    }
}
