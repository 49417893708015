import {Injectable, Injector} from '@angular/core';
import {Observable, of} from 'rxjs';
import {
    NoContentLink
} from '../../../../../framework/src/cross-platform/no-content-page/abstract-no-content-include.component';
import {SEODefaults} from '../../../../../framework/src/cross-platform/seo/seo-service';
import {UrlService} from '../../../../../framework/src/cross-platform/shared/url.service';
import {PepCMSPageLinks, PepExternalSites} from '../pep-custom-page-mapping';
import {PageTypeEnum} from '../../../../../framework/src/cross-platform/router/page-type.enum';
import {
    GridSettings,
    ImplementationLinks,
    PWASettings,
    RecaptchaSectionType,
    ScrollSettings
} from '../../../../../framework/src/cross-platform/implementation-config/implementation-data.types';
import {environment} from '../../../../../framework/src/cross-platform/implementation/environment';
import {StockOnHandType} from '../../../../../framework/src/cross-platform/stock-on-hand/stock-on-hand.types';
import {
    ProductInteractiveAttributeConfig,
    ProductModuleConfig,
    ProductModuleType
} from '../../../../../framework/src/cross-platform/product-page/product.types';
import {
    FMMenuItemType,
    FMMobileMenuItemCatBut,
    FMMobileMenuItemGroup
} from '../../../../../framework/src/cross-platform/external-data/firebase/firebase.types';
import {GenericSEOData} from '../../../../../framework/src/cross-platform/seo/seo-types';
import {
    ImplementationDataService,
    ProductFilterSettings
} from '../../../../../framework/src/cross-platform/implementation-config/implementation-data.service';
import {SwiperOptions} from 'swiper';

@Injectable()
export class PepImplementationDataService extends ImplementationDataService {

    public urlService:UrlService;

    constructor(injector:Injector) {
        super(injector);
        this.urlService = injector.get(UrlService);
    }

    getSEOBase(env:string, versionNumber:string):GenericSEOData {
        return environment.config.api.seoBase;
    }

    getSEODefaults():SEODefaults {
        return new SEODefaults(
            this.getSEOBase(null, null).title,
            this.getSEOBase(null, null).description
        );
    }

    getNoContentLinks():Observable<NoContentLink[]> {
        return of([
            {href: environment.config.api.careersUrl, newTab: true, localeKey: 'menu.more.careers'},
            {href: this.urlService.buildUrlForContact(), localeKey: 'menu.contactUs'},
            {href: this.urlService.buildUrlForStoreFinder(), localeKey: 'menu.findAStore'},
            {href: this.urlService.buildUrlForHome(), localeKey: 'menu.home'},
        ]);
    }

    getImplementationLinks():ImplementationLinks {
        return {
            pepkorRetailSegments  : PepExternalSites.pepkorRetailSegments,
            pepkorPrivacyStatement: PepExternalSites.pepkorPrivacyStatement,
            termsGeneral          : PepCMSPageLinks.termsGeneral,
            termsPrivacy          : PepCMSPageLinks.termsPersonalInfo
        };
    }

    getUrlForPaymentTermsAndConditionsPage() {
        return this.urlService.buildUrlForCDNRoot(PepCMSPageLinks.orderAndCollectTermsAndConditions);
    }

    getUrlForCancelOrder():string {
        return this.urlService.buildUrlForBuiltIn(PageTypeEnum.PRODUCT_LIST, 'order-collect');
    }

    getUrlForCompleteOrder():string {
        return this.getUrlForCancelOrder();
    }

    getProductFilterSettings():ProductFilterSettings {
        return {
            showFirstOnly               : false,
            showCategoryChildrenOnly    : false,
            funnelFilterAttributes      : false,
            defaultCollapsed            : true,
            productCategoryFilterEnabled: !this.facadePlatform.isDesktop,
            productCategoryNavEnabled   : this.facadePlatform.isDesktop,
            showSortOnFilter            : true,
            filterSortOrder             : {
                'category-nav': 1,
                'sort'        : 2,
            }
        };
    }

    getScrollSettings():ScrollSettings {
        if (this.facadePlatform.isDesktop) {
            return {
                header        : {
                    fixed           : true,
                    scrollingOffset : 200,
                    hideOnScrollDown: true
                },
                productFilters: {
                    fixed         : true,
                    adjustToHeader: true,
                    headerOffset  : 130,
                    footerOffset  : 50
                }
            };
        }
        else {
            return {
                header: {
                    hideOnScrollDown  : false,
                    unCollapseAtBottom: true
                }
            };
        }
    }

    getProductGridSettings():GridSettings {
        if (this.facadePlatform.isDesktop) {
            return {
                default: 1,
                grid   : [{
                    pageSize : 21,
                    gridClass: 'grid-1',
                    iconClass: 'grid-1',
                    dots     : 6,
                }, {
                    pageSize : 24,
                    gridClass: 'grid-2',
                    iconClass: 'grid-2',
                    dots     : 8
                }, {
                    pageSize : 48,
                    gridClass: 'grid-3',
                    iconClass: 'grid-3',
                    dots     : 12
                }]
            };
        }
    }

    getShowNewProductListCategoryNavigation():boolean {
        return true;
    }

    getCustomPlpLayout():boolean {
        return this.facadePlatform.isDesktop ? false : true;
    }

    getProductListUseInfiniteScroll():boolean {
        return true;
    }

    getShowCategoryDiscription():boolean {
        return true;
    }

    getShowStockFinder():boolean {
        return true;
    }

    getShowWasNowPrice():boolean {
        return true;
    }

    getProductModules():ProductModuleConfig[] {
        let modules:ProductModuleConfig[] = [];

        modules = modules.concat([
            {
                id         : 'product-details',
                title      : 'product.module.details',
                icon       : 'icon-list',
                type       : ProductModuleType.ATTRIBUTES,
                collapsable: true,
                defaultOpen: true,
            },
            {
                id         : 'product-guides',
                title      : 'product.module.guides',
                icon       : 'icon-tag',
                type       : ProductModuleType.WASHCARE_GUIDE,
                collapsable: false,
                defaultOpen: false
            }
        ]);

        if (this.getShowAddThisShareButtons()) {
            modules.push({
                id         : 'product-share',
                title      : 'product.module.share',
                icon       : 'icon-share',
                type       : ProductModuleType.SHARE,
                collapsable: true,
                defaultOpen: false
            });
        }

        return modules;
    }

    getShowSortOnFilter() {
        return true;
    }

    getProductSelectableAttributeConfig():{ attributes:ProductInteractiveAttributeConfig[] } {
        return {
            attributes: [{
                label               : 'product.selectable.attributes.colour',
                name                : this.getColorAttrName(),
                parent              : null,
                display             : false,
                selectFirstByDefault: true
            }, {
                label               : 'product.selectable.attributes.size',
                name                : this.getSizeAttrName(),
                parent              : this.getColorAttrName(),
                display             : true,
                selectFirstByDefault: true
            }]
        };
    }

    getShowBreadcrumbOnCoreProductPage():boolean {
        return false;
    }

    controlBreadcrumbPositionInCMS():boolean {
        return true;
    }

    getShowAttributeLabel():boolean {
        return true;
    }

    getShowSubcategoryNavigation():boolean {
        return false;
    }

    getShowFrameworkCatalogueRoute():boolean {
        return false;
    }

    getPreventScrollingOnSearch():boolean {
        return true;
    }

    getShowBuyButton():boolean {
        return true;
    }

    getCatalogModalEnabled():boolean {
        return true;
    }

    showBlogTagPages():boolean {
        return false;
    }

    getStockOnHand():StockOnHandType {
        return StockOnHandType.PEP;
    }

    getAutoCompleteMaxItems():number {
        return 3;
    }

    displayStoreLocatorTradingHoursDisclaimer():boolean {
        return true;
    }

    getAuthEnabled() {
        return false;
    }

    getShowStoreFinderInFooter() {
        return false;
    }

    getEnableMobileSearchHeader() {
        return false;
    }

    getCoreCategories():FMMobileMenuItemGroup[] {
        // find the categories in the clothing menu item
        const coreCats_clothing = (environment.uiConfig.mobileMainMenu.items as FMMobileMenuItemCatBut[]).find(item => item['locale'] === 'menu.clothing').subList.items.filter(item => item.type === FMMenuItemType.categoryButton);
        const coreCats_other    = environment.uiConfig.mobileMainMenu.items.filter(item => item.type === FMMenuItemType.categoryButton);
        // combine with other top level categories in the menu
        const coreCats          = coreCats_clothing.concat(coreCats_other);
        return coreCats;
    }

    getPagerSetting():{ maxPages:number } {
        return {maxPages: 6};
    }

    getShowCmsMenuContent():boolean {
        return true;
    }

    getProductCarouselSwiperConfig(instanceId?:number | string):SwiperOptions {
        if (this.facadePlatform.isDesktop) {
            return {
                breakpoints  : {
                    1250: {
                        slidesPerView: 5,
                        spaceBetween : 15,
                        freeMode     : {
                            enabled              : true,
                            sticky               : false,
                            momentum             : true,
                            momentumRatio        : 2,
                            momentumVelocityRatio: 1,
                            momentumBounce       : false,
                        },
                        pagination   : {
                            clickable: true,
                            enabled  : false
                        },
                        navigation   : {
                            enabled: true,
                        }
                    }
                },
                slidesPerView: 5,
                spaceBetween : 15,
                freeMode     : {
                    enabled              : true,
                    sticky               : false,
                    momentum             : true,
                    momentumRatio        : 2,
                    momentumVelocityRatio: 1,
                    momentumBounce       : false,
                },
                pagination   : {
                    clickable: true,
                    enabled  : false
                },
                navigation   : {
                    enabled: true,
                }
            };
        }
        else {
            return {
                breakpoints: {
                    360: {
                        slidesPerView: 2,
                        spaceBetween : 9,
                        freeMode     : {
                            enabled              : true,
                            sticky               : false,
                            momentum             : true,
                            momentumRatio        : 2,
                            momentumVelocityRatio: 1,
                            momentumBounce       : false,
                        },
                        navigation   : {
                            enabled: false,
                        },
                        pagination   : {
                            clickable: true,
                            enabled  : false
                        }
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween : 9,
                        freeMode     : {
                            enabled              : true,
                            sticky               : false,
                            momentum             : true,
                            momentumRatio        : 2,
                            momentumVelocityRatio: 1,
                            momentumBounce       : false,
                        },
                        navigation   : {
                            enabled: false,
                        },
                        pagination   : {
                            clickable: true,
                            enabled  : false
                        }
                    },
                },
            };
        }
    }

    getContentItemsCarouselSwiperConfig(style:'style1' | 'style2' | 'style3' | 'style4'):SwiperOptions {
        if (this.facadePlatform.isDesktop) {
            if (style === 'style1' || style === 'style2') {
                return {
                    slidesPerView: 5,
                    spaceBetween : 15,
                    freeMode     : {
                        enabled              : true,
                        sticky               : false,
                        momentum             : true,
                        momentumRatio        : 2,
                        momentumVelocityRatio: 1,
                        momentumBounce       : true,
                    },
                    loop         : false,
                    navigation   : {
                        enabled: true,
                    },
                    pagination   : {
                        clickable: true,
                        enabled  : true
                    },
                };
            }
            else if (style === 'style3' || style === 'style4') {
                return {
                    slidesPerView: 6,
                    spaceBetween : 15,
                    freeMode     : {
                        enabled              : true,
                        sticky               : false,
                        momentum             : true,
                        momentumRatio        : 2,
                        momentumVelocityRatio: 1,
                        momentumBounce       : false,
                    },
                    loop         : false,
                    navigation   : {
                        enabled: true,
                    },
                    pagination   : {
                        clickable: true,
                        enabled  : true
                    },
                };
            }
            else if (style === 'style5') {
                return {
                    slidesPerView: 3,
                    spaceBetween : 15,
                    freeMode     : {
                        enabled              : true,
                        sticky               : false,
                        momentum             : true,
                        momentumRatio        : 2,
                        momentumVelocityRatio: 1,
                        momentumBounce       : false,
                    },
                    loop         : false,
                    navigation   : {
                        enabled: true,
                    },
                    pagination   : {
                        clickable: true,
                        enabled  : true
                    },
                };
            }
        }
        else {
            if (style === 'style1' || style === 'style2') {
                return {
                    slidesPerView: 2,
                    spaceBetween : 9,
                    loop         : false,
                    freeMode     : {
                        enabled              : true,
                        sticky               : false,
                        momentum             : true,
                        momentumRatio        : 2,
                        momentumVelocityRatio: 1,
                        momentumBounce       : false,
                    },
                    navigation   : {
                        enabled: false,
                    },
                    pagination   : {
                        clickable: true,
                        enabled  : true
                    },
                };
            }

            else if (style === 'style3' || style === 'style4') {
                return {
                    slidesPerView: 3,
                    spaceBetween : 9,
                    loop         : false,
                    freeMode     : {
                        enabled              : true,
                        sticky               : false,
                        momentum             : true,
                        momentumRatio        : 2,
                        momentumVelocityRatio: 1,
                        momentumBounce       : false,
                    },
                    navigation   : {
                        enabled: false,
                    },
                    pagination   : {
                        clickable: true,
                        enabled  : true
                    },
                };
            }
            else if (style === 'style5') {
                return {
                    slidesPerView: 1,
                    spaceBetween : 9,
                    loop         : false,
                    freeMode     : {
                        enabled              : true,
                        sticky               : false,
                        momentum             : true,
                        momentumRatio        : 2,
                        momentumVelocityRatio: 1,
                        momentumBounce       : false,
                    },
                    navigation   : {
                        enabled: false,
                    },
                    pagination   : {
                        clickable: true,
                        enabled  : true
                    },
                };
            }
            return {
                breakpoints: {
                    360: {
                        slidesPerView: 2,
                        spaceBetween : 9,
                        pagination   : {
                            clickable: true,
                            enabled  : true
                        },
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween : 9,
                        pagination   : {
                            clickable: true,
                            enabled  : true
                        },
                    },
                },
                freeMode   : {
                    enabled              : true,
                    sticky               : false,
                    momentum             : true,
                    momentumRatio        : 2,
                    momentumVelocityRatio: 1,
                    momentumBounce       : false,
                },
                pagination : {
                    clickable: true,
                    enabled  : true
                },
                navigation : {
                    enabled: false,
                }
            };
        }
    }

    getShowProductsTooltip():boolean {
        return false;
    }

    getShowProductCategoryLabels():boolean {
        return true;
    }

    getPWASettings():PWASettings {
        return {
            enabled      : true,
            notifications: {
                enabled: true
            },
            installPrompt: {
                enabled: true
            }
        };
    }

    getBarcodeSeach():boolean {
        return true;
    }

    parseBarcode(input:string):string {
        // PEP has '10001' appended to all SKUs to makes up their barcode
        // This is a constant and we can assume this number will always be the same
        // If this number is at the end of a scanned barcode it should be excluded
        const barcodeAppend = '10001';
        const indexOf       = input.lastIndexOf(barcodeAppend);
        if (indexOf > 0 && indexOf === input.length - barcodeAppend.length) {
            input = input.substring(0, indexOf);
        }
        return input;
    }

    getNoIndexMinProductCount():number | undefined {
        return undefined;
    }

    getPushNotificationConfig() {
        return {
            enabled: true,
            delay  : 1000 * 60
            //delay: 0
        };
    }

    recaptchaEnabled() {
        return true;
    }

    recaptchaNoticeEnabled(section:RecaptchaSectionType) {
        return this.recaptchaEnabled();
    }
}
