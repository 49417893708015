import {Component, Injector} from '@angular/core';
import {AbstractFacadeComponent} from '../../../../cross-platform/shared/ui/abstract-facade.component';
import {SharedCartService} from '../../../../cross-platform/cart-shared/shared-cart.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cart-doesnt-exist-modal',
    template: `
        <div class="modal-header">
            <h4 class="modal-title modal-basic-title">{{'cart.cartExpiredModal.title' | translate}}</h4>
        </div>
        <div class="modal-body">
            <p [innerHTML]="'cart.cartExpiredModal.message' | translate"></p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="handleHomeClick()">Ok</button>
        </div>
    `
})
export class CartDoesntExistModalComponent extends AbstractFacadeComponent {

    private sharedCartService:SharedCartService;
    private ngbActiveModal:NgbActiveModal;

    constructor(injector:Injector) {
        super(injector);
        this.ngbActiveModal    = injector.get(NgbActiveModal);
        this.sharedCartService = injector.get(SharedCartService);
    }

    handleHomeClick() {
        this.sharedCartService.clearCart();
        this.handleLinkDirect(this.urlService.buildUrlForHome());
        this.ngbActiveModal.dismiss();
    }

    get name() {
        return 'CartDoesntExistModalComponent';
    }
}
