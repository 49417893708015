<!-- Explicitly un localized in case there is no locale -->
<div class="critical-error" [class.hidden]="!(criticalError$ | async)">
    <div class="content">
        <div class="title" *ngIf="(criticalError$ | async)"><h2>{{ 'error.heading' | translate }}</h2></div>
        <p [class.hidden]="(runTimeErrorType$ | async) != null" class="message">
            Looks like something went wrong on our end.
        </p>
        <p [class.hidden]="(runTimeErrorType$ | async) != RuntimeErrorEnum.ERROR_LOADING_CHUNK" class="message">
            <facade-icon icon="icon-connection-bars"></facade-icon><br />A network error has occurred.<br />Please check your connection and try again.
        </p>
        <p class="reload"><a [fakeLink]="'#'" (fakeClick)="reload()">Click here</a> to reload the page. Or go <a [fakeLink]="urlService.buildUrlForHome()" (fakeClick)="handleLinkAndReload($event)">back to home</a></p>
    </div>
    <div class="critical-error-footer"></div>
</div>

<!--
This is the vertical alignment system.
Keeping the height at a minimum of 100% with the footer at the bottom.
https://getbootstrap.com/docs/4.0/utilities/flex/
-->
<div class="app-container" [class.hidden]="hideContent$ | async" [ngClass]="{'is-checkout-section' : isInCheckoutSection, 'out-checkout-section': !isInCheckoutSection}">
    <div class="app-container-inner">
        <div #header class="desktop-header-holder" [ngClass]="!headerPinned ? 'unpinned' : ''">
            <inject-component componentClass="DesktopHeaderComponent"></inject-component>
        </div>
        <div class="app-content">
            <desktop-page-preloader *ngIf="isPreloading$ | async"></desktop-page-preloader>
            <!-- This div is needed to set visibility during loading cycle. The route content is added below the router-outlet -->
            <div class="router-holder" [class.invisible]="isPreloading$ | async">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="desktop-footer-holder" *ngIf="isBrowser && pageLoaded">
            <inject-component componentClass="AppFooterComponent"></inject-component>
        </div>
        <div #promptHolder></div>
    </div>
</div>
<app-messages
    [message]="appMessage?.message"
    [show]="appMessage != null"
    [dismissible]="true"
    [bgColor]="appMessage?.backgroundColour"
    [bgOpacity]="100"
    [color]="appMessage?.textColour"
    (onDismiss)="dismissAppAlert()"
></app-messages>
