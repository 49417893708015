import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {TextSearchResponse} from '../../external-data/magento/search/search.service';
import {ExternalResponse} from '../../external-data/external-response';
import produce from 'immer';
import {ProductModel} from '../../external-data/magento/elasticsearch/product.model';

export namespace Search {
    class SearchTerm {
        constructor(public searchTerm:string) {
        }
    }

    export class AutoCompleteStart extends SearchTerm {
        static readonly type = '[Search] Auto complete start';
    }

    export class UpdateCurrentSearchTerm extends SearchTerm {
        static readonly type = '[Search] Update current search term';
    }

    export class UpdateSearchResultsPageTerm extends SearchTerm {
        static readonly type = '[Search] Update search results page term';
    }

    export class StoreSearchTerm extends SearchTerm {
        static readonly type = '[Search] Store search term';
    }

    export class ResetSearchResultsPageTerm {
        static readonly type = '[Search] Reset search results page term';
    }

    export class SaveAutoCompleteResults {
        static readonly type = '[Search] Save auto complete results';

        constructor(public results:TextSearchResponse) {
        }
    }

    export class SaveAutoCompleteError {
        static readonly type = '[Search] Save auto complete error';

        constructor(public error:ExternalResponse) {
        }
    }

    export class ClearAutoCompleteResults {
        static readonly type = '[Search] Clear auto complete results';
    }

    export class BarcodeSearchStart extends SearchTerm {
        static readonly type = '[Search] Barcode search start';
    }

    export class BarcodeSearchInitiating {
        static readonly type = '[Search] Barcode search initiated';
        constructor(public result:boolean) {
        }
    }

    export class BarcodeSearchResult {
        static readonly type = '[Search] Barcode search result';
        constructor(public result:ProductModel) {
        }
    }

    export class BarcodeSearchError {
        static readonly type = '[Search] Barcode search error';
        constructor(public error:ExternalResponse) {
        }
    }

}


export interface SearchResultsStateModel {
    // This is the value stored
    searchResultsPageTerm?:string;
    // This is set when the search takes place
    autoCompleteCurrentSearchTerm?:string;
    // This is only set on a successful results set, i.e. this term links the current data set
    autoCompleteSearching?:boolean;
    autoCompleteSearchTerm?:string;
    autoCompleteResults?:TextSearchResponse;
    autoCompleteError?:ExternalResponse;

    searchParams?:string;

    barcodeSearchTerm?:string;
    barcodeSearchInitiating?:boolean;
    barcodeSearching?:boolean;
    barcodeSearchError?:ExternalResponse;
    barcodeSearchResult?:ProductModel;
}

@State<SearchResultsStateModel>({
    name: 'search'
})
@Injectable()
export class SearchState {

    @Action(Search.AutoCompleteStart)
    autoCompleteStart(ctx:StateContext<SearchResultsStateModel>, action:Search.AutoCompleteStart) {
        return ctx.setState(produce(draft => {
            draft.autoCompleteSearching         = true;
            draft.autoCompleteCurrentSearchTerm = action.searchTerm;
        }));
    }

    @Action(Search.UpdateCurrentSearchTerm)
    updateCurrentSearchTerm(ctx:StateContext<SearchResultsStateModel>, action:Search.UpdateCurrentSearchTerm) {
        return ctx.setState(produce(draft => {
            draft.autoCompleteCurrentSearchTerm = action.searchTerm;
        }));
    }

    @Action(Search.UpdateSearchResultsPageTerm)
    updateSearchResultsPageTerm(ctx:StateContext<SearchResultsStateModel>, action:Search.UpdateSearchResultsPageTerm) {
        return ctx.setState(produce(draft => {
            draft.searchResultsPageTerm         = action.searchTerm;
            draft.autoCompleteCurrentSearchTerm = action.searchTerm;
        }));
    }

    @Action(Search.ResetSearchResultsPageTerm)
    resetSearchResultsPageTerm(ctx:StateContext<SearchResultsStateModel>) {
        return ctx.setState(produce(draft => {
            draft.autoCompleteCurrentSearchTerm = draft.searchResultsPageTerm;
        }));
    }

    @Action(Search.StoreSearchTerm)
    storeSearchTerm(ctx:StateContext<SearchResultsStateModel>, action:Search.StoreSearchTerm) {
        return ctx.setState(produce(draft => {
            draft.autoCompleteSearchTerm = action.searchTerm;
        }));
    }

    @Action(Search.SaveAutoCompleteResults)
    saveAutoCompleteResults(ctx:StateContext<SearchResultsStateModel>, action:Search.SaveAutoCompleteResults) {
        return ctx.setState(produce(draft => {
            // @ts-ignore
            draft.autoCompleteResults   = action.results;
            draft.autoCompleteError     = null;
            draft.autoCompleteSearching = false;
        }));
    }

    @Action(Search.SaveAutoCompleteError)
    saveAutoCompleteError(ctx:StateContext<SearchResultsStateModel>, action:Search.SaveAutoCompleteError) {
        return ctx.setState(produce(draft => {
            // @ts-ignore
            draft.autoCompleteError     = action.error;
            draft.autoCompleteResults   = null;
            draft.autoCompleteSearching = false;
        }));
    }

    @Action(Search.ClearAutoCompleteResults)
    clearAutoCompleteResults(ctx:StateContext<SearchResultsStateModel>) {
        return ctx.setState(produce(draft => {
            draft.autoCompleteError             = null;
            draft.autoCompleteResults           = null;
            draft.autoCompleteSearchTerm        = null;
            draft.autoCompleteCurrentSearchTerm = null;
        }));
    }


    @Action(Search.BarcodeSearchInitiating)
    BarcodeSearchInitiating(ctx:StateContext<SearchResultsStateModel>, action:Search.BarcodeSearchInitiating) {
        return ctx.setState(produce(draft => {
            draft.barcodeSearchInitiating = action.result;
        }));
    }

    @Action(Search.BarcodeSearchStart)
    barcodeSearchStart(ctx:StateContext<SearchResultsStateModel>, action:Search.BarcodeSearchStart) {
        return ctx.setState(produce(draft => {
            draft.barcodeSearchTerm = action.searchTerm;
            draft.barcodeSearching   = true;
            draft.barcodeSearchError = null;
            draft.barcodeSearchResult = null;
        }));
    }

    @Action(Search.BarcodeSearchResult)
    barcodeSearchResult(ctx:StateContext<SearchResultsStateModel>, action:Search.BarcodeSearchResult) {
        return ctx.setState(produce(draft => {
            draft.barcodeSearchResult = action.result;
            draft.barcodeSearching   = null;
            draft.barcodeSearchError = null;
        }));
    }

    @Action(Search.BarcodeSearchError)
    barcodeSearchError(ctx:StateContext<SearchResultsStateModel>, action:Search.BarcodeSearchError) {
        return ctx.setState(produce(draft => {
            // @ts-ignore
            draft.barcodeSearchError = action.error;
            draft.barcodeSearching   = null;
        }));
    }

}
