import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {BlockedWhenAuthenticatedGuard} from '../../../../../framework/src/cross-platform/auth/auth-guards/blocked-when-authenticated.guard';

@Injectable()
export class PEPBlockedWhenAuthenticatedGuard extends BlockedWhenAuthenticatedGuard {

    constructor(injector:Injector) {
        super(injector);
    }

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.facadePlatform.handleLink({href: this.urlService.buildUrlForHome(), newTab: false, isBackLink: false});
        return false;
    }
}

