export interface DebouncedReference {
    ():void;

    clear?:() => void;
    flush?:() => void;
}

function debounceAPI(func:Function, wait:number, immediate:boolean, previousReference:DebouncedReference) {
    let timeout, args, context, timestamp, result;
    if (null == wait) wait = 100;
    if (previousReference != null) previousReference.clear();

    function later() {
        //console.log('----> debounce execute');
        const last = Date.now() - timestamp;

        if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
        }
        else {
            timeout = null;
            if (!immediate) {
                result  = func.apply(context, args);
                context = args = null;
            }
        }
    }

    const debounced:DebouncedReference = function () {
        //console.log('----> debounce create');
        context       = this;
        args          = arguments;
        timestamp     = Date.now();
        const callNow = immediate && !timeout;
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result  = func.apply(context, args);
            context = args = null;
        }

        return result;
    };

    debounced.clear = function () {
        if (timeout) {
            //console.log('----> timeout clear');
            clearTimeout(timeout);
            timeout = null;
        }
    };

    debounced.flush = function () {
        if (timeout) {
            result  = func.apply(context, args);
            context = args = null;

            clearTimeout(timeout);
            timeout = null;
        }
    };

    return debounced;
}

export function debounce(func:Function, wait:number, previousReference:DebouncedReference):DebouncedReference {
    const reference = debounceAPI(func, wait, false, previousReference);
    reference();
    return reference;
}

/**
 * Much simpler system than the above
 */
export class Debouncer {
    setTimeoutRef;

    constructor(public callback:Function, public timeout:number = 100) {
    }

    public trigger(immediate = false) {
        this.cancel();
        if (immediate) {
            this.callback();
        }
        else {
            this.setTimeoutRef = setTimeout(() => this.callback(), this.timeout);
        }
    }

    public cancel() {
        clearTimeout(this.setTimeoutRef);
    }

    public destroy() {
        this.cancel();
        this.callback = null;
    }
}
