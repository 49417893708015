import {gql} from 'apollo-angular';

export const CREATE_CUSTOMER_ADDRESS = gql`
    mutation createCustomerAddress($address:CustomerAddressInput!) {
        createCustomerAddress(input:$address) {
            id
            firstname
            lastname
            company
            street
            region {
                region
            }
            city
            country_code
            postcode
            default_billing
            default_shipping
        }
    }
`;
