<div *ngIf="menuElement" class="menu-button-component" [class.active]="showMenu">
    <a
        domPosition
        class="btn btn-light menu-btn"
        [style.class]="menuClass"
        (mouseover)="buttonMouseOver()"
        (mouseleave)="buttonMouseOut()"
        (fakeClick)="select.emit($event)"
        [fakeLink]="buttonUrl"
        role="button">
        <facade-icon *ngIf="customIcon" [icon]="customIcon"></facade-icon>
        {{$buttonName | async}}
        <facade-icon *ngIf="hasSubMenu" icon="icon-chevron-down"></facade-icon>
    </a>

    <div
        *ngIf="hasSubMenu"
        domPosition
        [style.top]="menuTop + 'px'"
        [style.left]="menuLeft + menuOffset + 'px'"
        class="{{menuClass}} menu-drop-down"
        (mouseover)="menuMouseOver()"
        (mouseleave)="menuMouseOut()"
    >
        <ng-content></ng-content>
    </div>
</div>