import {gql} from 'apollo-angular';

export const SET_EMAIL_ON_AUTHENTICATED_CART = gql`
    mutation setEmailOnAuthenticatedCart($input: SetEmailOnAuthenticatedCartInput) {
        setEmailOnAuthenticatedCart(input: $input) {
            cart {
                email
            }
        }
    }
`;
