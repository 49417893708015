import {IMetaPageData} from '../../../../../framework/src/cross-platform/seo/seo-service';

/**
 * This class is here to defined locale keys for non custom page, implementation specific
 * pages. For example custom account sections.
 * This class leverages the framework LocaleConventionUtil to generate the mappings.
 */
export class PepImplementationLocaleConventionUtil {
    //static seoPageTest     = ImplementationLocaleConventionUtil.getSeoPage(AckBuiltInPageMapping.account.laybys.localePageType, AckBuiltInPageMapping.account.laybys.seoLocaleConvention);

    public static getSeoPage(pageType:string, localeSection:string):IMetaPageData {
        const result            = {title: `seo.${localeSection}.title`, description: `seo.${localeSection}.description`};
        result['localeSection'] = localeSection;
        result['pageType']      = pageType;
        return result;
    }
}
