import {gql} from 'apollo-angular';

export interface MergeCartVariables {
    guestCartId:string;
    authenticatedCartId:string;
}

export const MERGE_CART = gql`
    mutation mergeCarts($guestCartId:String!, $authenticatedCartId:String!) {
        mergeCarts(
            source_cart_id: $guestCartId
            destination_cart_id: $authenticatedCartId
        ) {
            id
            total_quantity
        }
    }
`;


