export enum MagentoAttribute {
    id                = 'id',
    category_id       = 'category_id',
    description       = 'description',
    url_key           = 'url_key',
    price             = 'price',
    position          = 'position',
    on_sale           = 'on_sale',
    stock_status      = 'stock_status',
    name              = 'name',
    // These are different depending on implementation so have been moved to ImplementationDataService
    //size              = 'productsize',
    //color             = 'colour',

    msisdn            = 'msisdn',
    product_decal_old = 'product_attribute',
    created_at        = 'created_at',
    updated_at        = 'updated_at',
    // This only really pertains to ES searching
    relevance         = 'relevance'
}

export enum MagentoOnSaleValues {
    yes = 'yes',
    no  = 'no'
}

export enum MagentoStockStatusValues {
    in_stock     = 'in_stock',
    out_of_stock = 'out_of_stock'
}

export enum MagentoErrorMessage {
    outOfStock         = 'Some of the products are out of stock.',
    quantityBelowLevel = 'The requested qty is not available'
}

export enum MagentoCheckoutEnum {
    pickup_location_code = 'pickup_location_code',
    otp_session          = 'otp_session'
}

export enum MagentoOrderStatus {
    new = 'new'
}

export enum MagentoAttributeFrontendInput {
    select      = 'select',
    dropdown    = 'dropdown',
    multiselect = 'multiselect'
}

export const MagentoProductTypeEnum = {
    simple             : 'SimpleProduct',
    downloadableProduct: 'DownloadableProduct',
    configurable       : 'ConfigurableProduct'
};


export const MagentoImageSizes = {
    /*
    Desktop
    Big image: 1100x1100
    Small image: 470x470
    Thumbnail: 200x200

    Mobile
    Big image: 900x900
    Small image: 370x370
    Thumbnail: 150x150
    */

    d_lg: 1100, // desktop full screen
    d_md: 600,  // desktop product details
    d_sm: 370,  // desktop product list

    thumb: 260,

    m_lg: 600,  // mobile full screen
    m_md: 370,  // mobile product details
    m_sm: 260,   // mobile product list

};

export const MagentoShippingMethods = {
    clickAndCollect: 'clickcollect',
    economy        : 'economy',
};

export const MagentoShippingCarrierCodes = {
    twoShip: 'twoship',
};
