import {ChangeDetectionStrategy, Component, Injector, Input} from '@angular/core';
import {AbstractFacadeComponent} from '../../../../../cross-platform/shared/ui/abstract-facade.component';
import {BreadcrumbLink} from '../../../../../cross-platform/shared/ui/breadcrumb/breadcrumb.types';

@Component({
    selector       : 'bread-crumb',
    templateUrl    : './bread-crumb.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadCrumbComponent extends AbstractFacadeComponent {

    @Input()
    public breadcrumbs:Array<BreadcrumbLink>;

    constructor(injector:Injector) {
        super(injector);
    }

    get name() {
        return 'BreadCrumbComponent';
    }

}
