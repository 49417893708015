import {Injectable, Injector} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {FacadePlatform} from '../../shared/facade-platform';
import {UrlService} from '../../shared/url.service';
import {Logger} from '../../shared/logger';
import {AuthType} from '../components/auth.types';
import {SharedCartService} from '../../cart-shared/shared-cart.service';
import {Store} from '@ngxs/store';
import {map} from 'rxjs/operators';
import {GTMActions} from '../../external-data/google/google-tag-manager/gtm.actions';
import {CheckoutOptions} from '../../external-data/google/google-tag-manager/google-analytics.types';

@Injectable()
export class BlockedWhenAuthenticatedGuard implements CanActivate {

    facadePlatform:FacadePlatform;
    urlService:UrlService;
    logger:Logger;
    sharedCartService:SharedCartService;
    store:Store;

    constructor(injector:Injector) {
        this.facadePlatform    = injector.get(FacadePlatform);
        this.urlService        = injector.get(UrlService);
        this.logger            = injector.get(Logger);
        this.sharedCartService = injector.get(SharedCartService);
        this.store             = injector.get(Store);
        this.logger            = this.logger.getLogger('BlockedWhenAuthenticatedGuard');
    }

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.store.selectOnce(s => s.customer.isLoggedIn)
            .pipe(
                map((isLoggedIn) => {
                    const canActivate       = !isLoggedIn;
                    const authType:AuthType = this.urlService.isInCheckoutSection(state.url) ? AuthType.CHECKOUT : AuthType.STANDARD;
                    if (!canActivate) {
                        this.logger.debug('Blocked route as already logged in');
                        let url = this.urlService.buildUrlForAccountRoot();
                        if (authType === AuthType.CHECKOUT || AuthType.RE_AUTHENTICATE) {
                            this.store.dispatch(new GTMActions.TrackCheckoutAuthentication(CheckoutOptions.AUTH_EXISTING_USER, this.sharedCartService.getCartId()));
                            url = this.urlService.buildUrlForCheckoutDelivery();
                        }
                        this.facadePlatform.handleLink({href: url, newTab: false, isBackLink: false});
                    }
                    return canActivate;
                })
            );
    }

}
