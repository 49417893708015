import {Injectable, Injector} from '@angular/core';

export interface ComponentRegistrationConfig {
    className:string;
    componentClass:any;
}

/**
 * Very basic component store class, used by the InjectComponent to resolve components to create
 * Note: Multi dependency injection idea dropped as it doesn't work with lazily loaded modules
 */

@Injectable()
export class InjectComponentService {
    private components:{ [key:string]:ComponentRegistrationConfig } = {};
    private overrides:{ [key:string]:ComponentRegistrationConfig }  = {};

    constructor(public injector:Injector) {
    }

    register(newComps:ComponentRegistrationConfig[]) {
        if (newComps && newComps instanceof Array && newComps.length > 0) {
            newComps.forEach(config => this.components[config.className] = config);
        }
    }

    registerOverrides(newComps:ComponentRegistrationConfig[]) {
        if (newComps && newComps instanceof Array && newComps.length > 0) {
            newComps.forEach(config => this.overrides[config.className] = config);
        }
    }

    getComponentClass<T>(key:string):T {
        const comp     = this.components[key];
        const override = this.overrides[key];

        // console.log(`Looking for ${key} in`, this.components, this.overrides);

        return override ? override.componentClass : (comp ? comp.componentClass : null);
    }
}
