import {Injectable, Injector} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ExternalResponse} from '../external-response';
import {GenericCustomer} from '../../auth/models/generic-customer';
import {AbstractFacadeService} from '../abstract-facade.service';
import {Customer, CustomerAddressInput, Response} from '../../../../../generated/graphql';
import {RecaptchaService} from '../recaptcha/recaptcha-service';
import {
    FETCH_CUSTOMER_STORE_CREDIT,
    FETCH_CUSTOMER_STORE_CREDIT_CACHE
} from './gql-queries/fetch-customer-store-credit';
import {RootState} from '../../ngxs/root.state';
import {catchError, filter, mergeMap, switchMap, tap} from 'rxjs/operators';
import {ResponseCode} from '../enum/response-code.enum';
import {FETCH_CUSTOMER_INFO, FETCH_CUSTOMER_INFO_CACHE} from './gql-queries/fetch-customer-info';
import {throwError} from 'rxjs/internal/observable/throwError';
import {
    FETCH_CUSTOMER_ADDRESSES,
    FETCH_CUSTOMER_ADDRESSES_CACHE
} from '../../../../../implementation/ack/main/cross-platform/external-data/customer/gql-queries/fetch-customer-addresses';
import {
    CREATE_CUSTOMER_ADDRESS
} from '../../../../../implementation/ack/main/cross-platform/external-data/customer/gql-mutations/create-customer-address';
import {
    UPDATE_CUSTOMER_ADDRESS
} from '../../../../../implementation/ack/main/cross-platform/external-data/customer/gql-mutations/update-customer-address';
import {CustomerAuth} from '../../auth/customer-auth.state';
import {StaticConfig} from '../../shared/static-config';
import {environment} from '../../implementation/environment';


export interface FetchCustomerAddressesResponse {
    customer:Customer;
}

export interface FetchOnlineStoreCreditHistoryResponse {
    customer:Customer;
}

export interface FetchCustomerResponse {
    customer:Customer;
}

@Injectable()
export class CustomerLogicService extends AbstractFacadeService {

    protected cachedCustomer:GenericCustomer;
    protected recaptchaService:RecaptchaService;

    constructor(injector:Injector) {
        super(injector);
        this.recaptchaService = injector.get(RecaptchaService);
    }

    getCustomerDetails<T>(bypassCache = false):Observable<ExternalResponse<GenericCustomer<T>>> {
        this.logger.debug('Calling getCustomerDetails() bypassCache', bypassCache);

        return this.store.select((s:RootState) => s.customer).pipe(
            filter(v => v != null),
            filter(v => v.isLoggedIn), // Added because logout causes customer state to be updated
            mergeMap(customer => {
                if (this.cachedCustomer && !bypassCache) {
                    return of({responseCode: ResponseCode.SUCCESS, data: this.cachedCustomer});
                }
                else {
                    // Important to set this back to false as updating the customer below, triggers a state update that this method uses.
                    bypassCache = false;
                    return this.apollo.query<FetchCustomerResponse>(
                        {query: FETCH_CUSTOMER_INFO},
                        FETCH_CUSTOMER_INFO_CACHE,
                        true
                    ).pipe(
                        switchMap(
                            res => {

                                const genericCustomer:GenericCustomer = this.convertToGenericCustomer(res.data.customer);
                                this.cachedCustomer                   = genericCustomer;
                                const newRes                          = <ExternalResponse<GenericCustomer<T>>><any>res;
                                newRes.data                           = genericCustomer;
                                return of(newRes);
                            }
                        ),
                        catchError((error) => {
                            this.cachedCustomer = null;
                            return throwError(() => error);
                        })
                    );
                }
            }),
            tap(res => {
                // Update the global state
                this.store.dispatch(new CustomerAuth.UpdatePlusMore(res?.data?.plusMoreOptIn === true));
            })
        );
    }

    getCustomerAddresses():Observable<ExternalResponse<FetchCustomerAddressesResponse>> {
        return this.apollo.query(
            {query: FETCH_CUSTOMER_ADDRESSES},
            FETCH_CUSTOMER_ADDRESSES_CACHE,
            true
        );
    }

    createCustomerAddress(address:CustomerAddressInput):Observable<ExternalResponse<any>> {
        return this.apollo.mutate<Response, any>(
            {
                mutation : CREATE_CUSTOMER_ADDRESS,
                variables: {
                    address: address
                },
            },
            true
        );
    }

    updateCustomerAddress(id:number, address:CustomerAddressInput):Observable<ExternalResponse<any>> {
        return this.apollo.mutate<Response, any>(
            {
                mutation : UPDATE_CUSTOMER_ADDRESS,
                variables: {
                    id     : id,
                    address: address
                },
            },
            true
        );
    }

    fetchOnlineStoreCreditHistory(currentPage:number, pageSize:number):Observable<ExternalResponse<FetchOnlineStoreCreditHistoryResponse>> {
        return this.apollo.query<FetchOnlineStoreCreditHistoryResponse>(
            {
                query    : FETCH_CUSTOMER_STORE_CREDIT,
                variables: {
                    currentPage: currentPage,
                    pageSize   : pageSize
                },
            },
            FETCH_CUSTOMER_STORE_CREDIT_CACHE,
            true
        );
    }

    linkIdNumber<T>(idNumber:string):Observable<ExternalResponse<GenericCustomer<T>>> {
        throw new Error('Please override to implement');
    }

    private convertToGenericCustomer(customer:Customer):GenericCustomer {
        const result:GenericCustomer = {
            id                  : customer?.id,
            firstName           : customer?.firstname,
            lastName            : customer?.lastname,
            emailAddress        : customer?.email,
            cellPhoneNumber     : customer?.cellphone_number,
            title               : customer?.prefix,
            newsletterSubscribe : customer?.is_subscribed,
            dateOfBirth         : customer?.date_of_birth,
            gender              : customer?.gender,
            plusMoreOptIn       : customer?.plus_more_opt_in,
            rsaIdNumber         : customer?.rsa_id,
            passportNumber      : customer?.passport_number,
            asylumDocumentNumber: customer?.asylum_document_number,
            plusMoreCardNumber  : customer?.plus_more_card_number,
        };
        
        // Legacy implementation required first and last names on magento but not in the frontend
        // hence there is a placeholder to strip
        if (result.firstName === StaticConfig.PLACEHOLDER_NAME) result.firstName = null;
        if (result.lastName === StaticConfig.PLACEHOLDER_NAME) result.lastName = null;

        // Test and assign email
        const testReg:RegExp = new RegExp(environment.config.api.emailPlaceholderReg);

        if (result.emailAddress && testReg.test(result.emailAddress)) {
            result.emailAddressPlaceholder = result.emailAddress;
            result.emailAddress            = null;
        }

        return result;
    }

    invalidateCachedCustomer() {
        this.cachedCustomer = null;
    }

    getName() {
        return 'CustomerLogicService';
    }
}
