import {ChangeDetectionStrategy, Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {SearchService} from '../../../cross-platform/external-data/magento/search/search.service';
import {Logger} from '../../../cross-platform/shared/logger';
import {AbstractFacadeComponent} from '../../../cross-platform/shared/ui/abstract-facade.component';
import {SearchBarGenericComponent} from './search/search-bar-generic.component';
import {map, tap} from 'rxjs/operators';
import {
    FMDesktopMenuElementSizes,
    FMDesktopTopMenuItemGroup,
    FMMenuItemType
} from '../../../cross-platform/external-data/firebase/firebase.types';
import {environment} from '../../../cross-platform/implementation/environment';
import {CategoryHash} from '../../../cross-platform/external-data/magento/magento.reducer.types';
import {LazyComponentLoader} from '../../../cross-platform/utils/lazy-component-loader';
import {QuicklookCartComponent} from './quicklook-cart/quicklook-cart.component';
import {Router} from '@angular/router';

@Component({
    selector       : 'desktop-header',
    templateUrl    : './desktop-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopHeaderComponent extends AbstractFacadeComponent {
    @ViewChild('quicklookCartHolder', {static: true}) protected quicklookCartHolder:ElementRef;

    // UI Dep
    productSearchDataSource:SearchService;
    logger:Logger;

    @ViewChild(SearchBarGenericComponent, {static: true})
    search:SearchBarGenericComponent;

    menuItems:FMDesktopTopMenuItemGroup[];
    sizes:FMDesktopMenuElementSizes;
    categoryUrlKeyHash:CategoryHash;

    loggedIn$:Observable<boolean>;

    quickLookCart:QuicklookCartComponent;
    cartTotal:number                 = 0;
    quickCartUpdateAvailable:boolean = false;

    lazyComponentLoader:LazyComponentLoader;
    router:Router;
    private quickLookCartLoaded:boolean;

    constructor(public injector:Injector) {
        super(injector);
        this.productSearchDataSource = injector.get(SearchService);
        this.logger                  = injector.get(Logger).getLogger(this.name);
        this.loggedIn$               = this.storeSelect(s => s.customer.isLoggedIn).pipe(map(isLoggedIn => isLoggedIn === true));
        this.lazyComponentLoader     = injector.get(LazyComponentLoader);
        this.router                  = injector.get(Router);
    }

    init() {
        super.init();
        let topItems:FMDesktopTopMenuItemGroup[];

        const value = environment.uiConfig.desktopMainMenu;
        topItems    = value.items;
        this.sizes  = value.sizes;

        this.addSub = this.selectWhenReadyOnce(s => s.magento.categoryUrlKeyHash).pipe(
            tap(value => {
                this.categoryUrlKeyHash = value;
                // this.logger.debug('Received cat buts', value);
                // prune elements by cat enabled
                this.menuItems = topItems
                    .filter(item => {
                        if (item.type === FMMenuItemType.categoryButton) {
                            return value[item.urlKeyPath] != null && value[item.urlKeyPath].is_active;
                        }
                        return true;
                    })

                    // filter out plus more
                    .filter(item => {
                        if (item.type === FMMenuItemType.customButton && item.locale === 'menu.plusMore') return this.implementationDataService.getPlusMoreEnabled();
                        return true;
                    });
            })
        )
            .subscribe(items => {
                this.changeDetectorRef.detectChanges();
            });
    }

    private async openQuickLook() {
        if (this.implementationDataService.getQuickCartEnabled() && !this.urlService.isUrlCheckoutCart(this.router.url)) {
            if (!this.quickLookCart) await this.lazyLoadQuickCartComponent();
            if (this.quickCartUpdateAvailable && this.quickLookCart) {
                this.quickCartUpdateAvailable = false;
                this.quickLookCart.refreshCart();
            }
            this.quickLookCart?.setVisibility(true);
        }
    }

    private closeQuickLook() {
        if (this.implementationDataService.getQuickCartEnabled()) {
            this.quickLookCart?.setVisibility(false);
        }
    }

    protected async lazyLoadQuickCartComponent() {
        if (this.quicklookCartHolder) {
            this.quickLookCart = await this.lazyComponentLoader.load(
                import('./quicklook-cart/quicklook-cart.component').then(c => c.QuicklookCartComponent),
                this.quicklookCartHolder.nativeElement
            );
        }
        // this.quicklookCartHolder.is
        this.quickLookCartLoaded = true;
    }

    get name() {
        return 'DesktopHeaderComponent';
    }

}
