import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Logger} from '../shared/logger';
import {StaticConfig} from '../shared/static-config';
import {CloudFunctionsWrapperService} from '../external-data/cloud-functions/cloud-functions-wrapper.service';
import {from, Observable, of, timer} from 'rxjs';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {CountryPrefixCode, TelInputUtils} from './tel-input.utils';
import {UploadComponentValue} from './abstract-form-upload.component';
import {DateUtil} from '../shared/util/date-util';
import {ExternalResponse} from '../external-data/external-response';
import {ValidatePhoneNumberResponse} from '../../../../generated/graphql';
import {LibPhoneErrorCodes, LibPhoneType, ParseAndCheckError} from '../external-data/cloud-functions/lib-phone.types';
import {ResponseCode} from '../external-data/enum/response-code.enum';
import dayjs from 'dayjs';

// This is a redefinition of AsyncValidatorFn except the promise returns something else

export interface FacadeValidationErrors {
    [valudatorName:string]:FacadeValidationError;
}

export interface FacadeValidationError {
    message:string;
}

export type FacadeValidatorFn = (c:AbstractControl) => Promise<FacadeValidationErrors | null> | Observable<FacadeValidationErrors | null>;

@Injectable()
export class FacadeValidators {

    constructor(public translateService:TranslateService,
                public logger:Logger,
                public cloudFunctionsWrapperService:CloudFunctionsWrapperService) {
        this.logger = this.logger.getLogger('FacadeValidators');
    }
    parseDateWithTwoDigitYear(dateStr: string): dayjs.Dayjs {
        const yearStr = dateStr.slice(0, 2);
        const restOfDateStr = dateStr.slice(2);
        const year = parseInt(yearStr, 10) + 1900;
        return dayjs(year.toString() + restOfDateStr, 'YYYYMMDD');
    }
    /**
     * Note all our validators are async as they do translations asynchronously
     * All of them return a validator function to allow for customization of parameters for the validator in question
     */

    required(messageKey:string = 'validation.required'):FacadeValidatorFn {
        return async (control) => {
            if (control.value === '' || control.value == null || control.value === false) {
                return await this.localize('required', messageKey);
            }
        };
    }

    maxLength(maxLength:number, messageKey:string = 'validation.maxLength'):FacadeValidatorFn {
        return async (control) => {
            const len = control.value ? control.value.length : 0;
            if (len > maxLength) {
                return await this.localize('maxLength', messageKey, {maxLength});
            }
        };
    }

    minLength(minLength:number, messageKey:string = 'validation.minLength'):FacadeValidatorFn {
        return async (control) => {
            const len = control.value ? control.value.length : 0;
            if (len > 0 && len < minLength) {
                return await this.localize('minLength', messageKey, {minLength});
            }
        };
    }

    noEmptyStringOrNumberOnly(messageKey:string = 'validation.noEmptyStringOrNumberOnly'):FacadeValidatorFn {
        return async (control) => {
            const emptySpaces = /^\s+$/g;
            if (typeof control.value === 'string' && !isNaN(Number(control.value)) && emptySpaces.test(control.value)) {
                return await this.localize('noEmptyStringOrNumberOnly', messageKey);
            }
            return null;
        };
    }

    maxLengthInput(messageKey:string = 'validation.maxLength') {
        return this.maxLength(StaticConfig.INPUT_MAX, messageKey);
    }

    // validation specific to one profile
    oneProfilePassport(messageKey:string = 'validation.oneProfilePassport'):FacadeValidatorFn {
        return async (control) => {
            const reg = /^[a-zA-Z0-9]{9}$/g;
            if (typeof control.value != null && control.value !== '' && !reg.test(control.value)) {
                return await this.localize('oneProfilePassport', messageKey);
            }
            return null;
        };
    }

    oneProfileRsaId(messageKey:string = 'validation.rsaIdOneProfile'):FacadeValidatorFn {
        return async (control) => {
            const reg = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/g;
            if (typeof control.value != null && control.value !== '' && !reg.test(control.value)) {
                return await this.localize('oneProfileRsaId', messageKey);
            }
            return null;
        };
    }

    oneProfileEmail(messageKey:string = 'validation.email') {
        return async (control) => {
            const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            if (typeof control.value != null && control.value !== '' && !reg.test(control.value)) {
                return await this.localize('oneProfileEmail', messageKey);
            }
            return null;
        };
    }

    dateFormat(regMessageKey:string = 'validation.dateFormatReg', notRealMessageKey:string = 'validation.dateFormatNotReal') {
        return async control => {
            const result = this.parseDateAndValidate(control.value);
            if (result.isPopulated) {
                if (!result.regValid) {
                    return await this.localize('dateFormat', regMessageKey);
                }
                else if (!result.dateValid) {
                    return await this.localize('dateFormat', notRealMessageKey);
                }
            }
        };
    }

    dateMinAge(ageInYears:number = 18, messageKey:string = 'validation.dateMinAge') {
        return async control => {
            const result = this.parseDateAndValidate(control.value);
            if (result.dateValid) {
                const now      = dayjs();
                const formDate = dayjs(result.dateObj);
                const diff     = now.diff(formDate, 'year');
                //console.log('Min age: ', diff, ageInYears);
                if (diff < ageInYears) {
                    return await this.localize('dateMinAge', messageKey, {minYears: ageInYears});
                }
            }
        };
    }

    parseDateAndValidate(dateStr:string):{
        isPopulated:boolean,
        regValid:boolean,
        dateValid:boolean,
        date:number,
        month:number,
        year:number,
        dateObj:Date,
        formatted:string
    } {
        const dateReg   = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})$/;
        let regValid    = false;
        let isPopulated = false;
        let date:number;
        let month:number;
        let year:number;
        let dateValid   = false;
        let dateObj:Date;
        let formatted:string;
        if (dateStr && dateStr !== '') {
            isPopulated = true;
            dateStr     = dateStr.trim();

            if (dateReg.test(dateStr)) regValid = true;

            // Only try parse if in the valid format
            if (regValid) {
                const parts = dateStr.split('/');
                date        = Number(parts[0]);
                month       = Number(parts[1]);
                year        = Number(parts[2]);
                // Construct a date to check its validity, should have matching values if valid
                dateObj     = new Date();
                dateObj.setFullYear(year, month - 1, date);
                if (dateObj.getFullYear() === year && dateObj.getMonth() === (month - 1) && dateObj.getDate() === date) {
                    dateValid = true;
                    formatted = dayjs(dateObj).format('YYYY-MM-DD');
                }
            }
        }
        return {isPopulated, regValid, dateValid, date, month, year, dateObj, formatted};
    }

    email(messageKey:string = 'validation.email'):FacadeValidatorFn {
        return async (control) => {
            if (this.isPopulated(control)) {
                const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (emailReg.test(control.value)) {
                    return null;
                }
                else {
                    return await this.localize('email', messageKey);
                }
            }
            return null;
        };
    }

    phoneNumberDefaultGroup(required:boolean, includeServerSide:boolean, ensureMobile:boolean):FacadeValidatorFn {
        const validators = [
            this.phoneLengthValidation(),
            this.phoneNumberRestrict()
        ];
        if (required) validators.push(this.required());
        if (includeServerSide) validators.push(this.serverSidePhoneNumber(ensureMobile));
        return this.joinValidators(validators);
        //return this.serverSidePhoneNumber(ensureMobile);
    }


    adhocValidator(uniqueValidatorName:string, messageKey:string, isValid:(control:AbstractControl) => boolean, localeParams?:object):FacadeValidatorFn {
        return async (control) => {
            if (!isValid(control)) {
                return await this.localize('uniqueValidatorName', messageKey, localeParams);
            }
        };
    }

    /*phoneOrEmail(includeServerSide:boolean = false, phoneNumberRestrictKey:string = 'validation.phoneNumber', emailKey:string = 'validation.email'):FacadeValidatorFn {
        return async (control) => {
            // First check if populated
            if (this.isPopulated(control)) {
                // First lets see if its numbers spaces & pluses, meaning phone number
                const numberChars = /^[0-9 +]+$/g;
                if (numberChars.test(control.value)) {
                    // Now execute original phone number validation
                    let result = await this.phoneNumberRestrict(phoneNumberRestrictKey)(control);
                    if (result == null && includeServerSide) {
                        result = await this.serverSidePhoneNumber()(control);
                    }
                    return result;
                }
                // Otherwise must be an attempt at an email
                else {
                    // Now we can test against basic email
                    return await this.email(emailKey)(control);
                }
            }
            return null;
        };
    }*/

    /*
    Utils
    --------------------------------------------
    */

    private phoneLengthValidation(messageKeyMin:string = 'validation.phoneNumberMin', messageKeyMax:string = 'validation.phoneNumberMax') {
        return async (control) => {
            if (this.isPopulated(control)) {
                const parts = this.convertPhoneToParts(control.value);
                const len   = parts.localNumber ? parts.localNumber.length : 0;
                //console.log('Checking: ', parts);
                if (len < 9) {
                    return await this.localize('phoneLengthValidation', messageKeyMin);
                }
                /*else if (len > 9) {
                    return await this.localize('phoneLengthValidation', messageKeyMax);
                }*/
            }
        };
    }

    public phoneNumberRestrict(messageKey:string = 'validation.phoneNumber'):FacadeValidatorFn {
        return async (control) => {
            if (this.isPopulated(control) && !this.isPhoneTestSync(control.value)) {
                return await this.localize('phoneNumberRestrict', messageKey);
            }
        };
    }

    private serverSidePhoneNumber(ensureMobile = true):FacadeValidatorFn {
        let lastValue:string;
        let lastResponse:FacadeValidationErrors | null;

        return (control) => {
            if (this.isPopulated(control)) {
                const vName = 'phoneNumberServer';

                // Caching of the last value
                if (lastValue != null && lastValue === control.value) {
                    return of(lastResponse);
                }

                //console.log('Running server side phone is populated', control);

                // Debounce API call
                return timer(300).pipe(
                    mergeMap(() => {
                        const parts = this.convertPhoneToParts(control.value);
                        if (parts.region == null) {
                            throw 'Unsupported null region';
                        }
                        else {
                            return this.cloudFunctionsWrapperService.libPhoneNumber({phoneNumber: control.value, phoneNumberRegion: parts.region.regionCode, checkIsValidNumber: true, checkIsValidNumberForRegion: parts.region.regionCode, getNumberType: true}).pipe(
                                catchError(err => of(err))
                            );
                        }
                    }),
                    mergeMap(
                        e => {
                            if (e.hasOwnProperty('responseCode')) {
                                const succRes:ExternalResponse<ValidatePhoneNumberResponse> = e;
                                const errRes:ExternalResponse<ParseAndCheckError>           = e;

                                if (succRes.responseCode === ResponseCode.SUCCESS) {
                                    if (!succRes.data.validNumber) {
                                        return this.localizeObs(vName, 'validation.phoneNumberServer.invalid');
                                    }
                                    else if (!succRes.data.validNumberForRegion) {
                                        return this.localizeObs(vName, 'validation.phoneNumberServer.region');
                                    }
                                    else if (ensureMobile) {
                                        switch (succRes.data.numberType) {
                                            case LibPhoneType.FIXED_LINE:
                                            case LibPhoneType.TOLL_FREE:
                                            case LibPhoneType.PREMIUM_RATE:
                                            case LibPhoneType.SHARED_COST:
                                            case LibPhoneType.VOIP:
                                            case LibPhoneType.PAGER:
                                            case LibPhoneType.UAN:
                                            case LibPhoneType.VOICEMAIL:
                                                return this.localizeObs(vName, 'validation.phoneNumberServer.notMobile');
                                        }
                                    }

                                    return of(null);
                                }
                                else if (errRes.error && errRes.error && errRes.error.error && errRes.error.error.code) {
                                    switch (errRes.error.error.code) {
                                        case LibPhoneErrorCodes.INVALID_PARAMETER:
                                        case LibPhoneErrorCodes.UNEXPECTED:
                                            return this.localizeObs(vName, 'validation.phoneNumberServer.validatorError');
                                        case LibPhoneErrorCodes.INVALID_COUNTRY_CODE:
                                            return this.localizeObs(vName, 'validation.phoneNumberServer.invalidCountryCode');
                                        case LibPhoneErrorCodes.NOT_A_NUMBER:
                                            return this.localizeObs(vName, 'validation.phoneNumberServer.NaN');
                                        case LibPhoneErrorCodes.TOO_SHORT_AFTER_IDD:
                                            return this.localizeObs(vName, 'validation.phoneNumberServer.invalid');
                                        case LibPhoneErrorCodes.TOO_SHORT_NSN:
                                            return this.localizeObs(vName, 'validation.phoneNumberMin');
                                        case LibPhoneErrorCodes.TOO_LONG:
                                            return this.localizeObs(vName, 'validation.phoneNumberMax');
                                    }
                                }
                            }
                            return of(this.buildMessage(vName, 'Unexpected error: ' + e['message']));
                        }
                    ),
                    tap({
                        // Caching
                        next : v => {
                            lastResponse = v;
                            lastValue    = control.value;
                        },
                        error: v => {
                            lastResponse = null;
                            lastValue    = null;
                        }
                    })
                );

            }
            else {
                return of(null);
            }
        };
    }

    private convertPhoneToParts(phoneNumber:string):{ region:CountryPrefixCode, localNumber:string } {
        const region    = TelInputUtils.findRegionByNumber(phoneNumber);
        let localNumber = null;
        if (phoneNumber && typeof phoneNumber === 'string' && region) {
            localNumber = phoneNumber.substr(region.value.length);
        }
        return {region: region, localNumber: localNumber};
    }

    /*phoneOrEmail(includeServerSide:boolean = false, phoneNumberRestrictKey:string = 'validation.phoneNumber', emailKey:string = 'validation.email'):FacadeValidatorFn {
        return async (control) => {
            // First check if populated
            if (this.isPopulated(control)) {
                // First lets see if its numbers spaces & pluses, meaning phone number
                const numberChars = /^[0-9 +]+$/g;
                if (numberChars.test(control.value)) {
                    // Now execute original phone number validation
                    let result = await this.phoneNumberRestrict(phoneNumberRestrictKey)(control);
                    if (result == null && includeServerSide) {
                        result = await this.serverSidePhoneNumber()(control);
                    }
                    return result;
                }
                // Otherwise must be an attempt at an email
                else {
                    // Now we can test against basic email
                    return await this.email(emailKey)(control);
                }
            }
            return null;
        };
    }*/

    maxFileSize(maxSizeMB?:number, messageKey:string = 'validation.maxFileSize'):FacadeValidatorFn {
        return async (control) => {
            if (maxSizeMB != null && control.value) {
                const maxSizeBytes = maxSizeMB * 1024 /*KiloBytes*/ * 1024 /*Bytes*/;
                if (control.value.file) {
                    const file:File = control.value.file;
                    if (file.size > maxSizeBytes) {
                        return await this.localize('maxFileSize', messageKey, {maxSizeMB});
                    }
                }
                else if (control.value.length > 0) {
                    let sizeExceeded = false;
                    control.value.forEach(controlValue => {
                        if (controlValue?.file) {
                            const file:File = controlValue.file;
                            if (file.size > maxSizeBytes) {
                                sizeExceeded = true;
                            }
                        }
                    });
                    if (sizeExceeded) return await this.localize('maxFileSize', messageKey, {maxSizeMB});
                }
            }
        };
    }

    numberRestrict(messageKey:string = 'validation.numberOnly'):FacadeValidatorFn {
        return async (control) => {
            // Only checks the values are 0-9 only
            const phoneReg = /^[0-9]+$/g;
            if (control.value && !phoneReg.test(control.value)) {
                return await this.localize('numberRestrict', messageKey);
            }
        };
    }

    passwordMatch(firstControlName:string, messageKey:string = 'validation.passwordMatch'):FacadeValidatorFn {
        return async control => {
            if (control.parent && control.parent.get(firstControlName)) {
                const firstControl = control.parent.get(firstControlName);
                if (control.value !== firstControl.value) {
                    // Only checks the values are 0-9 only
                    return await this.localize('passwordMatch', messageKey);
                }
            }
        };
    }


    allowedExtensions(extensions?:string[], messageKey:string = 'validation.allowedExtensions'):FacadeValidatorFn {
        return async (control) => {
            if (extensions != null && extensions.length > 0 && control.value) {
                if (control.value.file) {
                    const file:File = control.value.file;
                    this.logger.debug('Checking allowed extension', extensions, file);
                    // Has no extension
                    if (file.name.indexOf('.') === -1) {
                        return await this.localize('allowedExtensions', messageKey);
                    }
                    else {
                        const ext   = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
                        const found = extensions.find(testExt => testExt.toLowerCase() === ext);
                        if (found == null) {
                            return await this.localize('allowedExtensions', messageKey);
                        }
                    }
                }
                else if (control.value.length > 0) {
                    const files:UploadComponentValue[] = control.value;
                    let hasExtension                   = true;
                    let hasValidExtension              = true;
                    files.forEach((fileValue) => {
                        const file = fileValue.file;

                        if (file?.name?.indexOf('.') === -1 && hasExtension) {
                            hasExtension = false;
                        }
                        else {
                            const ext   = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
                            const found = extensions.find(testExt => testExt.toLowerCase() === ext);
                            if (found == null) {
                                hasValidExtension = false;
                            }
                        }
                    });

                    if (!hasExtension || !hasValidExtension) {
                        return await this.localize('allowedExtensions', messageKey);
                    }
                }

            }
        };
    }


    rsaId(messageKey:string = 'validation.rsaID'):FacadeValidatorFn {
        return async (control) => {
            const result = this.parseRsaIdNumber(control.value);
            if (result.populated) {
                if (!result.regValid || !result.checkValid) return await this.localize('rsaID', messageKey);
            }
        };
    }

    minAgeOnRsaId(ageInYears:number = 18, messageKey:string = 'validation.dateMinAge') {
        return async (control) => {
            const result = this.parseRsaIdNumber(control.value);
            // only if we have a valid rsa id number do we look at the value for the date
            if (result.checkValid) {
                // 86 11 16
                const dateStr  = control.value.trim().substring(0, 6) as string;
                const now      = dayjs();
               // const formDate = dayjs(dateStr, 'YYMMDD');
                const formDate = this.parseDateWithTwoDigitYear(dateStr);
                const diff       = now.diff(formDate, 'year');
                //console.log('Min age: ', diff, ageInYears);
                if (diff < ageInYears) {
                    return await this.localize('dateMinAge', messageKey, {minYears: ageInYears});
                }

            }
        };
    }

    parseRsaIdNumber(idNumber:string):{ populated:boolean, regValid:boolean, checkValid:boolean } {
        let populated  = false;
        let regValid   = false;
        let checkValid = false;
        if (idNumber && idNumber !== '') {
            idNumber  = idNumber.trim();
            populated = true;

            const idReg = /^\d{13}$/g;
            if (idReg.test(idNumber)) {
                regValid = true;
            }
            if (regValid) {
                let nCheck = 0;
                let nDigit = 0;
                let bEven  = false;
                let value  = idNumber.replace(/\D/g, '');
                for (let n = value.length - 1; n >= 0; n--) {
                    let cDigit = value.charAt(n);
                    nDigit     = parseInt(cDigit, 10);
                    if (bEven) {
                        if ((nDigit *= 2) > 9) nDigit -= 9;
                    }

                    nCheck += nDigit;
                    bEven = !bEven;
                }
                if ((nCheck % 10) === 0) {
                    checkValid = true;
                }
            }
        }
        return {populated, regValid, checkValid};
    }

    genericIdentificationNumber(messageKey:string = 'validation.genericID'):FacadeValidatorFn {
        return async control => {
            const regEx = /^[0-9A-Za-z]{6,25}$/g;
            if (control.value && !regEx.test(control.value)) {
                return await this.localize('genericIdentificationNumber', messageKey);
            }
        };
    }

    validDateString(messageKey:string = 'validation.date'):FacadeValidatorFn {
        return async control => {
            if (control.value) {
                if (!DateUtil.isDate(control.value)) {
                    return await this.localize('validDate', messageKey);
                }
            }
        };
    }

    /*
    Utils
    --------------------------------------------
    */


    /**
     * Used to join a series of validators so they execute in sequence
     */
    private joinValidators(validators:FacadeValidatorFn[]):FacadeValidatorFn {
        return control => {
            const workingList = validators.concat();

            const next = (currentErr) => {
                // If there are no errors and validators to run
                if (currentErr == null && workingList.length > 0) {
                    // Execute the next validator
                    const nextValidator = workingList.shift();
                    const resAny        = nextValidator(control);
                    let resObs:Observable<FacadeValidationErrors | null>;

                    // Convert to an observable if not already
                    if (typeof resAny['then'] === 'function') {
                        resObs = from(resAny as Promise<FacadeValidationErrors | null>);
                    }
                    else {
                        resObs = resAny as Observable<FacadeValidationErrors | null>;
                    }
                    // Return obs and chain to this same method with its response
                    return resObs.pipe(mergeMap(errors => next(errors)));
                }
                else {
                    return of(currentErr);
                }
            };
            return next(null);
        };
    }

    private async localize(validatorName:string, messageKey:string, params?:any):Promise<FacadeValidationErrors> {
        const message           = await this.translateService.get(messageKey, params).toPromise();
        const response          = {};
        response[validatorName] = {message};
        return response;
    }

    private localizeObs(validatorName:string, messageKey:string, params?:any):Observable<FacadeValidationErrors> {
        return from(this.localize(validatorName, messageKey, params));
    }


    private buildMessage(validatorName:string, message:string):FacadeValidationErrors {
        const response          = {};
        response[validatorName] = {message};
        return response;
    }

    private isPopulated(control:AbstractControl) {
        return !this.isEmpty(control);
    }

    private isEmpty(control:AbstractControl) {
        return control == null || control.value == null || control.value === '' || control.value === ' ';
    }

    private isPhoneTestSync(value:string) {
        // Only checks the values are +0-9
        const phoneReg = /^\+?[0-9 ]+$/g;
        return this.isEmpty(<any>{value: value}) || phoneReg.test(value);
    }


}
