import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ImplementationDataService} from '../implementation-config/implementation-data.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(public implementationDataService:ImplementationDataService) {
    }

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.implementationDataService.getEcomEnabled();
    }

}
