import {gql} from 'apollo-angular';

export const WP_GET_PAGE_BY_ID_CACHE = true;

export const WP_GET_PAGE_BY_ID = gql`
    query wpPages_pageById(
        $status: String
        $page: String
        $per_page: String
        $include:String
    ) {
        wpPages(
            status: $status
            page: $page
            per_page: $per_page
            include:$include
        ) {
            total
            totalPages
            items {
                slug
            }
        }
    }
`;
