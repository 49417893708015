import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ResponseCode} from '../enum/response-code.enum';
import {ExternalResponse} from '../external-response';
import {GenericFormApiFileReference} from './generic-form-api.types';
import {ExtendedApolloService} from '../extended-apollo/extended-apollo.service';
import {UPLOAD_DOCUMENT} from './gql-mutations/upload-document';
import {environment} from '../../implementation/environment';
import {RecaptchaAction} from '../recaptcha/recaptcha-action';
import {RecaptchaService} from '../recaptcha/recaptcha-service';

interface UploadDocumentResponse {
    uploadDocument:{
        ResponseCode:string;
        ResponseDescription:string;
    };
}

@Injectable()
export class GenericFormApiWrapperService {

    constructor(private apollo:ExtendedApolloService,
                private recaptchaService:RecaptchaService) {
    }

    send<T>(action:RecaptchaAction, data:T, destinationId:string, files?:GenericFormApiFileReference[]):Observable<ExternalResponse<Object>> {
        const api = environment.config.api;

        // No need for an interface as its only defined here
        const variables = {
            // Add the basic auth information for the call
            CompanyNo    : Number(api.genericFormCompanyNo),
            Origin       : api.genericFormOrigin,
            DestinationId: Number(destinationId),

            /*
            Add the files from the references we have
            From the documentation, no clue what it means in terms of the request but we can guess...
            examples: OrderedMap {
                "ftpfile": OrderedMap { "value": "randomfile.jpg", "summary": "A file dropped onto the ftp server specified in the destination" },
                "httpfile": OrderedMap { "value": "http://example.com/randomfile.jpg", "summary": "The url to the file to be downloaded and sent to the destination" }
            }
            */
            Files: files ? files.map(fileRef => ({filename: fileRef.filename, url: fileRef.downloadURL})) : [],

            // Now add the data
            Metadata: data
        };

        return this.recaptchaService.execute(action).pipe(
            switchMap(res => {
                return this.apollo.mutate({
                    mutation : UPLOAD_DOCUMENT,
                    variables: variables,
                    context  : {headers: res.data}
                });
            }),
            map((response:ExternalResponse<UploadDocumentResponse>) => {
                // 4 zeros in a string means it was a success
                // No real data other than that
                if (!(response?.data?.uploadDocument?.ResponseCode === '0000')) {
                    // There are no expected errors here so all are critical
                    response.responseCode = ResponseCode.INVALID_RESPONSE_FORMAT;
                    throw response;
                }
                return response;
            })
        );
    }

}
