import {gql} from 'apollo-angular';

export const WP_GET_TAGS_CACHE = true;

export const WP_GET_TAGS = gql`
    query wpTags($slug: String, $page: String, $per_page: String) {
        wpTags(slug: $slug, page: $page, per_page: $per_page) {
            items {
                id
                name
                description
                slug
            }
        }
    }
`;
