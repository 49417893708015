import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class DocumentRef {

    constructor(@Inject(DOCUMENT) private document:Document) {
    }

    get nativeDocument():any {
        return this.document;
    }

    get scrollTop():number {
        return this.document.documentElement.scrollTop;
    }

    get documentElement():HTMLElement {
        return this.document.documentElement;
    }

    get head():HTMLElement {
        return this.document.head;
    }

    get body():HTMLElement {
        return this.document.body;
    }

    createElement(tagName:string):any {
        return this.document.createElement(tagName);
    }

    getElementsByTagName(tagName:string) {
        return this.document.getElementsByTagName(tagName);
    }

    getElementById(elementId: string) {
        return this.document.getElementById(elementId);
    }

    querySelectorAll(selectors:string):any {
        return this.document.querySelectorAll(selectors);
    }

    querySelector<E extends Element = Element>(selectors:string):E | null {
        return this.document.querySelector(selectors);
    }

    addEventListener(type:string, listener?:EventListenerOrEventListenerObject, options?:boolean | EventListenerOptions) {
        return this.document.addEventListener(type, listener, options);
    }

    removeEventListener(type:string, listener?:EventListenerOrEventListenerObject, options?:boolean | EventListenerOptions) {
        return this.document.removeEventListener(type, listener, options);
    }

    rewritePage(newPage:string) {
        this.document.open();
        this.document.write(newPage);
        this.document.close();
    }

}
