import {Component, Injector} from '@angular/core';
import {AbstractAuthLoginComponent} from '../../../../cross-platform/auth/components/abstract-auth-login.component';

@Component({
    selector   : 'auth-login',
    templateUrl: 'auth-login.component.html'
})
export class AuthLoginComponent extends AbstractAuthLoginComponent {
    constructor(injector:Injector) {
        super(injector);
    }

    get name() {
        return 'AuthLoginComponent';
    }
}
