import {
    GAAddToCart,
    GACheckout,
    GAEventActionType,
    GAProductFieldObject,
    GAPurchase,
    GARemoveFromCart,
    GoogleAnalyticsEcommerceEvent
} from './google-analytics.types';
import {GA4Custom, GA4Ecommerce, GA4General} from './google-analytics-4.types';
import {StaticConfig} from '../../../shared/static-config';
import {Logger} from '../../../shared/logger';
import {Injectable, Injector} from '@angular/core';
import {FacadeConfig} from '../../../../../../../facade.config';
import {
    GA4Event,
    GAAddPaymentInfo,
    GAAddShippingInfo,
    GAAddToCartGA4,
    GABarcodeSearch,
    GABarcodeSearchFailed,
    GABarcodeSearchModal,
    GABarcodeSearchSuccess,
    GACheckInStoreStock,
    GAContactSubmission,
    GACustomEvent,
    GAFileDownload,
    GAFindAStoreCall,
    GAFindAStoreDirections,
    GAFindAStoreSearch,
    GALogin,
    GANewsletterSignupSubmit,
    GAPageView,
    GAPeachNonce,
    GAProductsFilter,
    GASearch,
    GASelectItem,
    GAShareContent,
    GASignUp,
    GAViewCart,
    GAViewItem,
    GAViewItemList
} from './google-analytics-4-internal.types';
import {CartItemInterface, ItemTrackingInfo, SimpleProduct} from '../../../../../../generated/graphql';
import {ProductModel} from '../../magento/elasticsearch/product.model';
import {ChildProductModel} from '../../magento/elasticsearch/child-product.model';
import {environment} from '../../../implementation/environment';

@Injectable()
export class GoogleAnalytics4BuilderService {
    public logger:Logger;

    constructor(injector:Injector) {
        this.logger = injector.get(Logger);
        this.logger = this.logger.getLogger(GoogleAnalytics4BuilderService.name);
    }

    public buildGA4Event(action:GoogleAnalyticsEcommerceEvent | GA4Event) {
        let retEvent;
        const eventType:GAEventActionType = action.eventType;
        switch (eventType) {
            case 'custom-event':
                retEvent = this.customEvent((<GA4Event>action).eventData as GACustomEvent);
                break;

            // Required General
            case 'sign-up':
                retEvent = this.signUp((<GA4Event>action).eventData as GASignUp);
                break;
            case 'login':
                retEvent = this.login((<GA4Event>action).eventData as GALogin);
                break;
            case 'share-content':
                retEvent = this.shareContent((<GA4Event>action).eventData as GAShareContent);
                break;
            case 'page-view':
                retEvent = this.pageView((<GA4Event>action).eventData as GAPageView);
                break;
            case 'newsletter-signup-submit':
                retEvent = this.newsletterSignupSubmit((<GA4Event>action).eventData as GANewsletterSignupSubmit);
                break;
            case 'contact-submission':
                retEvent = this.contactSubmission((<GA4Event>action).eventData as GAContactSubmission);
                break;
            case 'find-a-store-search':
                retEvent = this.findAStoreSearch((<GA4Event>action).eventData as GAFindAStoreSearch);
                break;
            case 'find-a-store-call':
                retEvent = this.findAStoreCall((<GA4Event>action).eventData as GAFindAStoreCall);
                break;
            case 'find-a-store-directions':
                retEvent = this.findAStoreDirections((<GA4Event>action).eventData as GAFindAStoreDirections);
                break;
            case 'search':
                retEvent = this.search((<GA4Event>action).eventData as GASearch);
                break;
            case 'check-in-store-stock':
                retEvent = this.checkInStoreStock((<GA4Event>action).eventData as GACheckInStoreStock);
                break;

            // Ecommerce
            case 'view-item-list':
                retEvent = this.viewItemList((<GA4Event>action));
                break;
            case 'select-item':
                retEvent = this.selectItem((<GA4Event>action));
                break;
            case 'view-item':
                retEvent = this.viewItem((<GA4Event>action));
                break;
            case 'add-to-cart-ga4':
                retEvent = this.addToCartGa4((<GA4Event>action));
                break;
            case 'view-cart':
                retEvent = this.viewCart((<GoogleAnalyticsEcommerceEvent>action));
                break;
            case 'add-to-cart':
                retEvent = this.addToCart((<GoogleAnalyticsEcommerceEvent>action));
                break;
            case 'remove-from-cart':
                retEvent = this.removeFromCart((<GoogleAnalyticsEcommerceEvent>action));
                break;
            case 'checkout':
                retEvent = this.beginCheckout((<GoogleAnalyticsEcommerceEvent>action));
                break;
            case 'add-shipping-info':
                retEvent = this.addShippingInfo(action);
                break;
            case 'add-payment-info':
                retEvent = this.addPaymentInfo(action);
                break;
            case 'purchase':
                retEvent = this.purchase((<GoogleAnalyticsEcommerceEvent>action));
                break;
            case  'peach-nonce':
                retEvent = this.peachNonce(action);
                break;
            case  'file-download':
                retEvent = this.fileDownload(action);
                break;
        }
        // this.logger.debug('GA4 Event: ' + eventType + '\n', JSON.stringify(retEvent, null, 4));
        return retEvent;
    }

    private customEvent(action:GACustomEvent):GA4General.CustomEvent {
        return {
            event: 'custom_event',
            ...action
        };
    }


    // General
    private signUp(data:GASignUp):GA4General.SignUp {
        return {
            event  : 'sign_up',
            method : data.method,
            user_id: data.user_id
        };
    }

    private login(data:GALogin):GA4General.Login {
        return {
            event  : 'login',
            method : data.method,
            user_id: data.user_id
        };
    }

    private shareContent(data:GAShareContent):GA4General.ShareContent {
        return {
            event       : 'share_content',
            content_type: data.content_type,
            item_id     : data.item_id
        };
    }

    private productsFilter(data:GAProductsFilter):GA4General.ProductsFilter {
        return {
            event               : 'products_filter',
            filter_category_name: data.filter_category_name,
            filter_category_id  : data.filter_category_id,
            filter_type         : data.filter_type,
            filter_name         : data.filter_name,
            filter_value        : data.filter_value,
            filter_action       : data.filter_action
        };
    }

    private pageView(data:GAPageView):GA4General.PageView {
        const retObj:GA4General.PageView = {
            event            : 'pageview',
            page_title       : data.page_title,
            page_path        : data.page_path,
            page_category    : data.page_category,
            page_sub_category: data.page_sub_category
        };
        if (data.dynamic_sub_category) retObj.page_sub_category = data.dynamic_sub_category;
        return retObj;
    }

    private newsletterSignupSubmit(data:GANewsletterSignupSubmit):GA4General.NewsletterSignupSubmit {
        return {
            event: 'newsletter_signup_submit'
        };
    }

    private contactSubmission(data:GAContactSubmission):GA4General.ContactSubmission {
        return {
            event       : 'contact_submission',
            contact_type: data.contact_type
        };
    }

    private findAStoreSearch(data:GAFindAStoreSearch):GA4General.FindAStoreSearch {
        return {
            event         : 'find_a_store_search',
            search_type   : data.search_type,
            search_address: data.search_address
        };
    }

    private findAStoreCall(data:GAFindAStoreCall):GA4General.FindAStoreCall {
        return {
            event         : 'find_a_store_call',
            store_code    : data.store_code,
            store_name    : data.store_name,
            store_province: data.store_province
        };
    }

    private findAStoreDirections(data:GAFindAStoreDirections):GA4General.FindAStoreDirections {
        return {
            event         : 'find_a_store_directions',
            store_code    : data.store_code,
            store_name    : data.store_name,
            store_province: data.store_province
        };
    }

    private search(data:GASearch):GA4General.Search {
        return {
            event      : 'search',
            search_term: data.search_term
        };
    }

    private checkInStoreStock(data:GACheckInStoreStock):GA4General.CheckInStoreStock {
        return {
            event   : 'check_in_store_stock',
            currency: StaticConfig.PRODUCT_CURRENCY_CODE,
            value   : data?.value,
            items   : [this.convertIProduct(data?.product, data?.selectedCategoryId, null, data?.simpleProduct?.sku)]
        };
    }

    private fileDownload(action:GA4Event):GA4General.FileDownload {
        const fileDownload:GAFileDownload = action.eventData as GAFileDownload;
        const filePathArray               = fileDownload.file.pathname.split('/');
        return {
            event         : 'file_download',
            file_extension: fileDownload.file.pathname.match(/\.[0-9a-z]+$/i)[0],
            file_name     : filePathArray[filePathArray.length - 1],
            link_url      : fileDownload.file.pathname,
        };
    }

    private BarcodeSearchModal(data:GABarcodeSearchModal):GA4General.BarcodeSearchModal {
        return {
            event: 'barcode_search_modal',
            value: data.action
        };
    }

    private barcodeSearch(data:GABarcodeSearch):GA4General.BarcodeSearch {
        return {
            event: 'barcode_search',
            type : data.search_type,
            sku  : data.search_sku
        };
    }

    private barcodeSearchSuccess(data:GABarcodeSearchSuccess):GA4General.BarcodeSearchSuccess {
        return {
            event: 'barcode_search_success',
            type : data.search_type,
            sku  : data.search_sku
        };
    }

    private barcodeSearchFailed(data:GABarcodeSearchFailed):GA4General.BarcodeSearchFailed {
        return {
            event  : 'barcode_search_failed',
            type   : data.search_type,
            sku    : data.search_sku,
            message: data.error_message
        };
    }


    // eCommerce
    private viewItemList(action:GA4Event):GA4Ecommerce.ViewItemList {
        const viewItemList:GAViewItemList = action.eventData as GAViewItemList;
        return {
            event         : 'view_item_list',
            currency      : StaticConfig.PRODUCT_CURRENCY_CODE,
            item_list_name: viewItemList?.item_list_name
        };
    }

    private selectItem(action:GA4Event):GA4Ecommerce.SelectItem {
        const selectItem:GASelectItem = action.eventData as GASelectItem;
        return {
            event         : 'select_item',
            currency      : StaticConfig.PRODUCT_CURRENCY_CODE,
            item_list_id  : selectItem.itemTracking?.item_list_id,
            item_list_name: selectItem.itemTracking?.item_list_name,
            items         : [this.convertIProduct(selectItem.product, selectItem.selectedCategoryId, undefined, undefined, selectItem.itemTracking)]
        };
    }

    private viewItem(action:GA4Event):GA4Ecommerce.ViewItem {
        const viewItem:GAViewItem = action.eventData as GAViewItem;
        return {
            event   : 'view_item',
            currency: StaticConfig.PRODUCT_CURRENCY_CODE,
            value   : String(viewItem.product.price),
            items   : [this.convertIProduct(viewItem.product, viewItem.selectedCategoryId)]
        };
    }

    private viewCart(action:GA4Event):GA4Ecommerce.ViewCart {
        const viewCart:GAViewCart = action.eventData as GAViewCart;
        return {
            event   : 'view_cart',
            currency: StaticConfig.PRODUCT_CURRENCY_CODE,
            value   : viewCart.value,
            items   : this.convertProductsFromCartItems(viewCart.cartItems)
        };
    }

    private addToCartGa4(action:GA4Event):GA4Ecommerce.AddToCart {
        const addToCart:GAAddToCartGA4 = action.eventData as GAAddToCartGA4;

        // For add to cart the price needs to represent the simple product
        const item = this.convertIProduct(addToCart?.configurableProduct, addToCart.selectedCategoryId, addToCart?.quantity, addToCart?.simpleProduct?.sku, addToCart?.itemTracking);
        item.price = addToCart.simpleProduct?.price + '';

        return {
            event   : 'add_to_cart',
            currency: StaticConfig.PRODUCT_CURRENCY_CODE,
            value   : this.calculateValueGA4(addToCart?.simpleProduct, addToCart?.quantity),
            items   : [item]
        };
    }

    private addToCart(action:GoogleAnalyticsEcommerceEvent):GA4Ecommerce.AddToCart {
        const addToCart:GAAddToCart = action.eventData as GAAddToCart;
        return {
            event   : 'add_to_cart',
            currency: StaticConfig.PRODUCT_CURRENCY_CODE,
            value   : this.calculateValue(addToCart?.add?.products),
            items   : this.convertProductsArr(addToCart?.add?.products)
        };
    }

    private removeFromCart(action:GoogleAnalyticsEcommerceEvent):GA4Ecommerce.RemoveFromCart {
        const removeFromCart:GARemoveFromCart = action.eventData as GARemoveFromCart;
        return {
            event   : 'remove_from_cart',
            currency: StaticConfig.PRODUCT_CURRENCY_CODE,
            value   : this.calculateValue(removeFromCart?.remove?.products),
            items   : this.convertProductsArr(removeFromCart?.remove?.products)
        };
    }

    private beginCheckout(action:GoogleAnalyticsEcommerceEvent):GA4Ecommerce.BeginCheckout {
        const checkout:GACheckout = action.eventData as GACheckout;
        return {
            event   : 'begin_checkout',
            currency: StaticConfig.PRODUCT_CURRENCY_CODE,
            value   : this.calculateValue(checkout?.checkout?.products),
            items   : this.convertProductsArr(checkout?.checkout?.products)
        };
    }

    private addShippingInfo(action:GA4Event):GA4Ecommerce.AddShippingInfo {
        const shippingInfo:GAAddShippingInfo = action.eventData as GAAddShippingInfo;
        return {
            event        : 'add_shipping_info',
            currency     : StaticConfig.PRODUCT_CURRENCY_CODE,
            value        : shippingInfo.value,
            shipping_tier: shippingInfo.shipping_tier,
            items        : this.convertProductsFromCartItems(shippingInfo.cartItems)
        };
    }

    private addPaymentInfo(action:GA4Event):GA4Ecommerce.AddPaymentInfo {
        const paymentInfo:GAAddPaymentInfo = action.eventData as GAAddPaymentInfo;
        return {
            event       : 'add_payment_info',
            currency    : StaticConfig.PRODUCT_CURRENCY_CODE,
            value       : paymentInfo?.value,
            payment_type: paymentInfo?.payment_type,
            items       : this.convertProductsFromCartItems(paymentInfo?.cartItems)
        };
    }

    private purchase(action:GoogleAnalyticsEcommerceEvent):GA4Ecommerce.Purchase {
        const purchase:GAPurchase = action.eventData as GAPurchase;
        return {
            event         : 'purchase',
            currency      : StaticConfig.PRODUCT_CURRENCY_CODE,
            affiliation   : environment.pwaName,
            value         : String(purchase?.purchase?.actionField?.revenue),
            shipping      : String(purchase?.purchase?.actionField?.shipping),
            transaction_id: purchase?.purchase?.actionField?.id,
            items         : this.convertProductsArr(purchase?.purchase?.products),
        };
    }

    private peachNonce(action:GA4Event):GA4Custom.PeachNonce {
        const peachNonce:GAPeachNonce = action.eventData as GAPeachNonce;
        return {
            event      : 'peach_nonce',
            nonce      : peachNonce.nonce,
            orderNumber: peachNonce.orderNumber,
            paymentType: peachNonce.paymentType
        };
    }

    // UTILS
    private calculateValue(products:GAProductFieldObject[]):string {
        let totalValue = 0;
        products.forEach(product => {
            if (product?.price && product?.quantity) {
                try {
                    totalValue = totalValue + (Number(product.price) * Number(product.quantity));
                }
                catch (e) {
                    this.logger.error(`Product's price or quantity is missing or wrong. It hasn't been included in the value sent to Google Analytics: Price: ${product.price} Quantity: ${product.price}`);
                }
            }
        });
        return String(totalValue);
    }

    private calculateValueGA4(product:ChildProductModel, quantity:number):string {
        let totalValue = 0;
        if (product?.price && quantity) {
            try {
                totalValue = totalValue + (Number(product?.price) * Number(quantity));
            }
            catch (e) {
                this.logger.error(`Product's price or quantity is missing or wrong. It hasn't been included in the value sent to Google Analytics: Price: ${product.price} Quantity: ${product.price}`);
            }
        }
        return String(totalValue);
    }

    private convertIProduct(product:ProductModel, selectedCategoryId?:number, quantity?:number, variant?:string, itemTracking:ItemTrackingInfo = {}):GA4Ecommerce.Product {
        let retObj:GA4Ecommerce.Product = {
            currency   : StaticConfig.PRODUCT_CURRENCY_CODE,
            affiliation: environment.pwaName,
            item_brand : environment.pwaName,
            item_id    : String(product?.sku),
            item_name  : String(product?.name),
            price      : String(product?.price)
        };

        if (product.index) retObj.index = String(product.index);
        if (quantity) retObj.quantity = String(quantity);
        if (variant) retObj.item_variant = variant;

        const catHash = product.categoryIDHash;

        retObj.item_list_name = itemTracking?.item_list_name;

        if (itemTracking.item_list_name && !itemTracking?.item_category && !itemTracking?.item_list_id) {
            retObj.item_list_id = undefined;
            /*
                NOTE: exit early,
                this caters for tracking when the item has been selected from lists without a known category
                e.g. search-results
            */
            return retObj;
        }

        let baseCat = undefined;

        if (itemTracking?.item_category && catHash.hasOwnProperty(itemTracking?.item_category)) {
            // NOTE: the tracking object should take priority over default categories
            baseCat = catHash[itemTracking?.item_category];
        }
        else if (selectedCategoryId && catHash.hasOwnProperty(selectedCategoryId)) {
            // NOTE: default back the page category compiled from url
            baseCat = catHash[selectedCategoryId];
        }

        if (!baseCat) {
            // NOTE: exit early, base category cannot be null or empty after this point
            return retObj;
        }

        retObj.item_list_id   = itemTracking?.item_list_id ? itemTracking?.item_list_id : (selectedCategoryId ? String(baseCat.id) : undefined);
        retObj.item_list_name = itemTracking?.item_list_name ? itemTracking?.item_list_name : (selectedCategoryId ? baseCat.name : undefined);

        let currId = baseCat.id;
        while (currId != null) {
            const currCat = catHash[currId];
            switch (currCat.level) {
                case 2:
                    retObj.item_category = currCat.name;
                    break;
                case 3:
                    retObj.item_category2 = currCat.name;
                    break;
                case 4:
                    retObj.item_category3 = currCat.name;
                    break;
                case 5:
                    retObj.item_category4 = currCat.name;
                    break;
                case 6:
                    retObj.item_category5 = currCat.name;
                    break;
            }
            currId = currCat?.parent_id;
            if (currCat.level === 2) currId = null;
        }

        return retObj;
    }

    private convertProductsArr(products:GAProductFieldObject[]):GA4Ecommerce.Product[] {
        return products?.map(old => {
            let retObj:GA4Ecommerce.Product = {
                currency   : StaticConfig.PRODUCT_CURRENCY_CODE,
                affiliation: environment.pwaName,
                item_brand : environment.pwaName,
                item_id    : String(old?.id),
                item_name  : String(old?.name),
                price      : String(old?.price)
            };
            if (old.position) retObj.index = String(old.position);
            if (old.quantity) retObj.quantity = String(old.quantity);
            if (old.variant) retObj.item_variant = old.variant;
            if (old.item_list_id) retObj.item_list_id = old.item_list_id;
            if (old.item_list_name) retObj.item_list_name = old.item_list_name;
            if (old.item_category) retObj.item_category = old.item_category;
            return retObj;
        });
    }

    private convertProductsFromCartItems(items:CartItemInterface[]):GA4Ecommerce.Product[] {
        return items.map(item => {
            let retObj:GA4Ecommerce.Product = {
                currency   : StaticConfig.PRODUCT_CURRENCY_CODE,
                affiliation: environment.pwaName,
                item_brand : environment.pwaName,
                item_id    : String((<SimpleProduct>item?.product)?.parent_product?.sku),
                item_name  : String((<SimpleProduct>item?.product)?.parent_product?.name),
                price      : String(item.product?.price_range?.minimum_price?.final_price?.value)
            };
            if (item?.product?.sku) retObj.item_variant = item.product.sku;
            if (item?.quantity) retObj.quantity = String(item.quantity);
            if (item?.itemTracking?.item_list_id) retObj.item_list_id = item.itemTracking?.item_list_id;
            if (item?.itemTracking?.item_list_name) retObj.item_list_name = item.itemTracking?.item_list_name;
            if (item?.itemTracking?.item_category) retObj.item_category = item.itemTracking?.item_category;
            return retObj;
        });
    }
}
