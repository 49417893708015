import {Component, EventEmitter, HostBinding, Injector, Output, QueryList, ViewChildren} from '@angular/core';
import {combineLatest} from 'rxjs/operators';
import {MagentoImageSizes} from '../../external-data/enum/magento-constants';
import {ISearchBarRenderer} from '../../external-data/magento/search/search.types';
import {debug} from '../../shared/rxjs-operators';
import {StaticConfig} from '../../shared/static-config';
import {AbstractFacadeComponent} from '../../shared/ui/abstract-facade.component';
import {DomPositionDirective} from '../../shared/ui/dom-position.directive';
import {FakeLinkEvent} from '../../shared/ui/fake-link.directive';
import {WindowRef} from '../../shared/ui/window-ref';
import {IProductModel} from '../../external-data/magento/model/iproduct.model';
import {GAProductModel} from '../../external-data/magento/model/ga-product-model';
import {CatSearchItem} from '../../external-data/magento/search/category-search.service';
import {ProductModel} from '../../external-data/magento/elasticsearch/product.model';
import {ESWPSearch} from '../../external-data/wordpress/model/wordpress.types';
import {GTMActions} from '../../external-data/google/google-tag-manager/gtm.actions';

@Component({template: ''})
export abstract class AbstractProductSearchBarRendererComponent extends AbstractFacadeComponent implements ISearchBarRenderer {
    @HostBinding('class.search-2-3') ratio: boolean = this.implementationDataService.getProductImageRatio() === 2 / 3;
    StaticConfig      = StaticConfig;
    MagentoImageSizes = MagentoImageSizes;

    @Output()
    onFocusLost:EventEmitter<any> = new EventEmitter();

    @Output()
    onHideRequest:EventEmitter<any> = new EventEmitter();

    @Output()
    onItemSelect:EventEmitter<any> = new EventEmitter();

    isSearching:boolean;
    hasError:boolean;

    categoryData:Array<CatSearchItem>;
    productData:Array<ProductModel>;
    dataCount:number;
    wpData:Array<ESWPSearch>;
    wpDataCount:number;
    searchTerm:string;
    currentIndex = -1;
    lastIndex    = 0;

    @ViewChildren('productResult', {read: DomPositionDirective})
    productResultElements:QueryList<DomPositionDirective>;

    // Deps
    windowRef:WindowRef;

    protected constructor(injector:Injector) {
        super(injector);
        this.windowRef = injector.get(WindowRef);
        //this.logger.logLevel = LogLevel.TEST;
    }

    init() {
        super.init();

        this.addSub = this.storeSelect(s => s.search.autoCompleteSearching).subscribe(val => {
            this.isSearching = val;
            this.changeDetectorRef.detectChanges();
        });

        this.addSub = this.storeSelect(s => s.search.autoCompleteResults)
            .pipe(
                combineLatest(this.storeSelect(s => s.search.autoCompleteError)),
                debug('Search results being rendered')
            )
            .subscribe(values => {
                const error   = values[1];
                this.hasError = error != null;
                if (values[0] != null && values[0].productRes && values[0].productRes.data && values[0].productRes.data) {
                    this.dataCount   = values[0].productRes.data.length;
                    this.productData = values[0].productRes.data.map(product => new ProductModel(product, this.urlService.categoryIDHash));
                }
                else {
                    this.productData = null;
                }
                this.categoryData = (values[0] != null && values[0].categories) ? values[0].categories : null;

                if (values[0] != null && values[0].blogRes && values[0].blogRes.getTotalItems() > 0) {
                    this.wpDataCount = values[0].blogRes.getTotalItems();
                    this.wpData      = values[0].blogRes.getAllData();
                }
                else {
                    this.wpData = null;
                }


                this.lastIndex = 0; // Always reset the last index on new data
                this.changeDetectorRef.markForCheck();
                this.changeDetectorRef.detectChanges();
            });
        this.addSub = this.storeSelect(s => s.search.autoCompleteCurrentSearchTerm)
            .subscribe(searchTerm => {
                this.searchTerm = searchTerm;
                this.changeDetectorRef.markForCheck();
            });
    }

    onShowHide(showing:boolean) {
        this.logger.test('Show hide of results: ', showing);
        if (!showing) this.setSelectedIndex(-1);
    }

    handleNavigation(event:KeyboardEvent) {
        // temp removal as this functionality is broken and has not been updated to support categories & or pages
        // Also a lot more people using mobile where there is no keyboard nav

        // If we are in a position to select any index
        /*if (!this.isSearching && !this.hasError && this.productData != null) {

            if (event.keyCode === KeyCode.UP) {
                this.logger.test('Up navigation');
                this.setSelectedIndex(this.currentIndex - 1);
                if (this.currentIndex === -1) this.onFocusLost.emit();
            }
            else if (event.keyCode === KeyCode.DOWN || event.keyCode === KeyCode.TAB) {
                if (this.currentIndex === -1 && this.lastIndex > 0) {
                    this.logger.test('Down navigation to the last index of:', this.lastIndex);
                    this.setSelectedIndex(this.lastIndex);
                }
                else {
                    this.logger.test('Down navigation');
                    this.setSelectedIndex(this.currentIndex + 1);
                }
            }
            else if (event.keyCode === KeyCode.ENTER && this.currentIndex !== -1) {
                this.dispatch(GTMActions.trackProductClick(new GAProductModel(this.productData[this.currentIndex]).getGAProductClick('search-autocomplete')));
                this.handleLinkDirect(this.urlService.buildUrlForProduct(this.productData[this.currentIndex]));
            }
        }
        // Otherwise dispatch back to the component
        else {*/
        this.logger.test('Not valid time for navigation, emitting focus lost');
        this.onFocusLost.emit();
        //}
    }


    setSelectedIndex(index:number, isRollOver:boolean = false) {
        // Don't allow any invalid values
        const productLength = this.productData ? this.productData.length : 0;
        if (index < -1 || index > productLength - 1) {
            this.logger.debug('Set index rejected: ', index, this.productData);
            return;
        }
        this.lastIndex    = this.currentIndex;
        this.currentIndex = index;
        this.logger.test('Selected index change to: ', index);
        // Now lets retrieve the product element
        if (!isRollOver) this.scrollToIndex(index, this.lastIndex < this.currentIndex);
        this.changeDetectorRef.markForCheck();
    }

    handleLink(fakeLinkEvent:FakeLinkEvent) {
        this.onHideRequest.emit();
        super.handleLink(fakeLinkEvent);
    }

    handleProductClick(fakeLinkEvent:FakeLinkEvent, product:IProductModel) {
        this.store.dispatch(new GTMActions.TrackProductClick(new GAProductModel(product).getGAProductClick('search-autocomplete')));
        this.store.dispatch(new GTMActions.TrackSelectItem({
            itemTracking: {
                item_list_name    : 'search-autocomplete'
            },
            selectedCategoryId: null,
            product           : product as ProductModel
        }));
        this.handleLink(fakeLinkEvent);
    }

    handleCategoryClick(fakeLinkEvent:FakeLinkEvent, category:CatSearchItem) {
        this.handleLink(fakeLinkEvent);
    }

    abstract scrollToIndex(index:number, isDown:boolean);

    get name() {
        return 'ProductSearchResultsComponent';
    }
}
