import {
    ChangeDetectorRef,
    Directive,
    ErrorHandler,
    EventEmitter,
    Injector,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID
} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, first, take} from 'rxjs/operators';
import {GTMEvents} from '../../external-data/google/google-tag-manager/gtm.events';
import {GlobalSelectors} from '../../ngxs/global.selectors';
import {SEOService} from '../../seo/seo-service';
import {FacadePlatform} from '../facade-platform';
import {Logger} from '../logger';
import {UrlService} from '../url.service';
import {ViewStateSelector} from '../view-state/view-state.selector';
import {FakeLinkEvent} from './fake-link.directive';
import {ImplementationDataService} from '../../implementation-config/implementation-data.service';
import {WindowRef} from './window-ref';
import {RuntimeErrorHandlerService} from '../../error-handling/runtime-error-handler.service';
import {ApiErrorHelper} from '../../error-handling/api-error-helper';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {GTMActions} from '../../external-data/google/google-tag-manager/gtm.actions';
import {isPlatformBrowser} from '@angular/common';
import {DocumentRef} from './document-ref';
import {RootState} from '../../ngxs/root.state';


@Directive()
export abstract class AbstractFacadeComponent implements OnInit, OnDestroy {
    @Output() onCreated = new EventEmitter<any>();

    static instanceCount = 10;
    private static cnt   = 1;


    protected _subscriptions:Subscription[]          = [];
    protected _componentSubscriptions:Subscription[] = [];

    protected isMobile = false;

    private _destroyed      = false;
    protected createdCalled = false;

    public retryLoad = new BehaviorSubject(1);
    public logger:Logger;
    public seoService:SEOService;
    public facadePlatform:FacadePlatform;
    public globalSelectors:GlobalSelectors;
    public viewStateSelector:ViewStateSelector;
    public urlService:UrlService;
    public changeDetectorRef:ChangeDetectorRef;
    public implementationDataService:ImplementationDataService;
    public windowRef:WindowRef;
    public documentRef:DocumentRef;
    public runtimeErrorHandlerService:RuntimeErrorHandlerService;
    public apiErrorHelper:ApiErrorHelper;
    public translate:TranslateService;
    public store:Store;
    public isBrowser:boolean;

    // References
    public GTMEvents   = GTMEvents;
    protected instance = AbstractFacadeComponent.instanceCount++;

    protected constructor(protected injector:Injector) {
        this.isBrowser                  = isPlatformBrowser(injector.get(PLATFORM_ID));
        this.logger                     = injector.get(Logger);
        this.seoService                 = injector.get(SEOService);
        this.facadePlatform             = injector.get(FacadePlatform);
        this.globalSelectors            = injector.get(GlobalSelectors);
        this.viewStateSelector          = injector.get(ViewStateSelector);
        this.urlService                 = injector.get(UrlService);
        this.changeDetectorRef          = injector.get(ChangeDetectorRef);
        this.implementationDataService  = injector.get(ImplementationDataService);
        this.windowRef                  = injector.get(WindowRef);
        this.documentRef                = injector.get(DocumentRef);
        this.runtimeErrorHandlerService = <RuntimeErrorHandlerService>injector.get(ErrorHandler);
        this.apiErrorHelper             = injector.get(ApiErrorHelper);
        this.translate                  = injector.get(TranslateService);
        this.store                      = injector.get(Store);
        // Auto determine with app type this is
        this.isMobile                   = this.facadePlatform.isMobile;
        this.setupLogger();
    }

    ngOnInit() {
        this.created();
        this.init();
    }

    ngOnDestroy() {
        this.destroy();
    }

    // Utility for desktop to reinitialize a component
    reInit() {
        this.reset();
        this.init();
    }


    // Called once on component creation
    created() {
        this.createdCalled = true;
        this.onCreated.emit();
        //this.logger             = this.logger.getLogger(this.name + '_' + AbstractFacadeComponent.cnt++);
        this.logger.test('LIFECYCLE: created()');
    }

    setupLogger() {
        // Only allow setup once so you can override earlier if you choose
        if (this.logger.name !== this.name) {
            this.logger = this.logger.getLogger(this.name, this.logger.logLevel);
        }
    }

    // Called when the component needs to initialize, may be called multiple times during life cycle
    init() {
        this.logger.test('LIFECYCLE: init()');

        // Lifecycle event method
        this.addSub = this.globalSelectors.appReady$
            .pipe(first(value => value === true))
            .subscribe(() => {
                this.initAfterAppReady();
            });

    }

    // Called after app ready is set to true
    initAfterAppReady() {
        this.logger.test('LIFECYCLE: initAfterAppReady()');
    }

    // Called when the component needs to destroy listeners created in init
    // This will never be called automatically for a component, but will be called higher up in the page component
    // This can be manually called if needed
    reset() {
        this.logger.test('LIFECYCLE: reset()');
        this.removeAllSubscriptions();
    }

    // Called when the component is actually destroyed
    destroy() {
        this.logger.test('LIFECYCLE: destroy()');
        this.removeAllSubscriptions();
        this._componentSubscriptions.forEach(sub => sub.unsubscribe());
        this._componentSubscriptions = [];
        this._destroyed              = true;
    }

    get destroyed() {
        return this._destroyed;
    }


    removeAllSubscriptions() {
        this.logger.test('removeAllSubscriptions()');
        this._subscriptions.forEach(sub => sub.unsubscribe());
        this._subscriptions = [];
    }


    abstract get name():string;

    set addSub(value:Subscription) {
        this._subscriptions.push(value);
    }

    set addComponentSub(value:Subscription) {
        this._componentSubscriptions.push(value);
    }

    selectWhenReady<T>(selector:(state:RootState, ...states:any[]) => T) {
        return this.storeSelect(selector).pipe(filter(v => v != null));
    }

    selectWhenReadyOnce<T>(selector:(state:RootState, ...states:any[]) => T) {
        return this.storeSelect(selector).pipe(filter(v => v != null), take(1));
    }

    // Util function entirely designed to types easier for selects
    storeSelect<T>(selectorFunction:(state:RootState) => T) {
        return this.store.select<T>(selectorFunction);
    }

    handleLink(fakeLinkEvent:FakeLinkEvent) {
        this.facadePlatform.handleLink(fakeLinkEvent);
    }

    handleLinkDirect(url:string, newTab:boolean = false, isBackLink = false, replaceState = false) {
        this.handleLink({href: url, newTab: newTab, isBackLink: isBackLink, replaceState});
    }

    trackGTMEvent(eventName:string, meta?:object) {
        this.store.dispatch(new GTMActions.TrackEvent(eventName, meta));
    }

    // Simply util to await for 10 frames for an element to resolve like an elementRef
    async waitForElementRef(check:() => boolean) {
        let cnt = 0;
        while (!check() && cnt < 10) {
            cnt++;
            await new Promise(resolve => setTimeout(resolve));
        }
    }
}
