import {Injectable, Injector} from '@angular/core';
import {NewsletterSignUpDialogService} from '../../../cross-platform/shared/ui/newsletter/newsletter-sign-up-dialog.service';

@Injectable()
export class DesktopNewsletterSignUpDialogService extends NewsletterSignUpDialogService {

    constructor(injector:Injector) {
        super(injector);
    }

    showDialog() {
        // No default implementation - used in VA
    }
}
