import {BasicImageModel, IProductModel} from '../model/iproduct.model';
import {
    AmLabel,
    ConfigurableVariant,
    GqlMagentoAttribute,
    ProductStockStatus
} from '../../../../../../generated/graphql';


export class ChildProductModel implements IProductModel<ConfigurableVariant> {

    public labels:AmLabel[]; // These are applied to products as a separate GraphQL call
    public source:ConfigurableVariant;

    constructor(product:ConfigurableVariant) {
        this.source = product;
    }

    get id():number {
        return this.source.product.id;
    }

    get sku():string {
        return this.source.product.sku;
    }

    get name():string {
        return null;
    }

    get description():string {
        return null;
    }

    get images():BasicImageModel[] {
        return null;
    }

    getFirstImagePath():string {
        return null;
    }

    get price():number {
        return this.source?.product?.price_range?.minimum_price?.final_price?.value;
    }

    get oldPrice():number {
        return this.source?.product?.price_range?.minimum_price?.regular_price?.value;
    }

    get discount():number {
        return this.source?.product?.price_range?.minimum_price?.discount?.amount_off;
    }

    get categoryIds():string[] {
        return null;
    }

    get categoryId():string {
        return null;
    }

    get urlKey() {
        return null;
    }

    get metaTitle() {
        return null;
    }

    get metaDescription() {
        return null;
    }

    get isOnPromotion():boolean {
        return false;
    }

    get brandLogo() {
        return null;
    }

    get customAttributeHash() {
        const hash = {};
        this.source.attributes.forEach(value => {
            hash[value.code] = value.value_index;
        });
        return hash;
    }

    get customAttributeMetadata():GqlMagentoAttribute[] {
        return null;
    }

    get customAttributeMetadataHash():{ [id:string]:GqlMagentoAttribute } {
        return null;
    }

    // Used for adding to cart
    get selectedOptions():string[] {
        return this.source.attributes.map(value => value.uid);
    }

    get isInStock() {
        return this.source.product.stock_status === ProductStockStatus.InStock;
    }

    get isSimple() {
        return true;
    }

    get isConfigurable() {
        return false;
    }

    get rawCustomAttributeHash() {
        return this.source.attributes ? this.source.attributes : [];
    }

    get productDecalOld() {
        return null;
    }

    get washcareGuide() {
        return null;
    }

    get sizeGuide() {
        return null;
    }

    get variantSortOrder() {
        return this.source?.product?.sizesortseq && !isNaN(Number(this.source?.product?.sizesortseq)) ? Number(this.source?.product?.sizesortseq) : null;
    }
}
