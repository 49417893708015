export enum ResponseCode {
    SUCCESS                 = 'SUCCESS',                // Successful
    PROGRESS                = 'PROGRESS',               // Progress event

    // These are specific error types that may or may not be handled
    CONNECTION_ERROR        = 'CONNECTION_ERROR',       // Deemed a connection error internally
    CLIENT_SIDE_TIMEOUT     = 'CLIENT_SIDE_TIMEOUT',    // Client side timeout error
    SERVER_SIDE_TIMEOUT     = 'SERVER_SIDE_TIMEOUT',    // Server side timeout error
    RUNTIME_ERROR           = 'RUNTIME_ERROR',          // Client side runtime error, should never really happen, prefer these to be uncaught errors
    PARSING_ERROR           = 'PARSING_ERROR',          // JSON or other data from the server failed to parse
    INTERCEPTOR_REJECT      = 'INTERCEPTOR_REJECT',     // Used in http interceptors to reject an API call. In this case the error can be ignored as the interceptor should handle the action.
    AUTHENTICATION_ERROR    = 'AUTHENTICATION_ERROR',   // Server returned an embedded 401

    // Any server error that doesn't adhere to its qualified swagger description
    // For example an API returns null data when we always expect data
    // Unlike all the other errors, this will only ever be implemented in a business logic service
    // or if you supply {expectDataOrError:true} to the facade http service and the result has no data
    INVALID_RESPONSE_FORMAT = 'INVALID_RESPONSE_FORMAT',

    // Anything not in the above list will be a general error
    GENERAL_ERROR           = 'GENERAL_ERROR',
    RECAPTCHA_ERROR         = 'RECAPTCHA_ERROR',
}
