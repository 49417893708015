import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CrossPlatformModule} from '../../../cross-platform/cross-platform.module';
import {TranslateModule} from '@ngx-translate/core';
import {NoContentPageComponent} from './no-content-page.component';
import {GlobalSharedUIModule} from '../shared/ui/global-shared-ui.module';
import {NoContentComponent} from './no-content.component';
import {InjectComponentService} from '../../../cross-platform/inject-component/inject-component.service';
import {NoContentIncludeComponent} from './no-content-include.component';


const injectableComponents = [
    NoContentIncludeComponent
];

const injectableComponentsToRegister = [
    {className: 'NoContentIncludeComponent', componentClass: NoContentIncludeComponent},
];

@NgModule({
    imports: [
        CommonModule,
        CrossPlatformModule,
        TranslateModule,
        GlobalSharedUIModule
    ],
    declarations: [
        NoContentPageComponent,
        NoContentComponent,
        ...injectableComponents
    ],
    exports: [
        NoContentPageComponent,
        NoContentComponent
    ]
})
export class NoContentPageModule {
    constructor(injectComponentService:InjectComponentService) {
        injectComponentService.register(injectableComponentsToRegister);
    }
}
