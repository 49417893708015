import {gql} from 'apollo-angular';
import {fullCartGQL} from '../gql-queries/fetch-cart';

const simpleCart = `
    id
    total_quantity
`;

export const ADD_TO_CART = (hasPimSupport:boolean, fetchFullCart:boolean, loggedIn:boolean) => gql`
    mutation addProductsToCart($cartId:String!, $cartItems:[CartItemInput!]!) {
        addProductsToCart(
            cartId: $cartId,
            cartItems: $cartItems
        ) {
            cart {
                ${(fetchFullCart) ? fullCartGQL(hasPimSupport, loggedIn) : simpleCart}
            }
            user_errors {
                code
                message
            }
        }
    }
`;
