import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {NetworkLoadMonitor} from '../../external-data/interceptors/network-load-monitor.service';
import {ScriptLoader} from '../../external-data/script-loader/script-loader';
import {environment} from '../../implementation/environment';
import {AbstractFacadeComponent} from './abstract-facade.component';
import {ProductModel} from '../../external-data/magento/elasticsearch/product.model';
import {GTMActions} from '../../external-data/google/google-tag-manager/gtm.actions';
import {WindowRef} from './window-ref';

@Component({
    selector       : 'add-this-inline',
    template       : `
        <div class="add-this-holder" *ngIf="shouldDisplay">
            <div class="share" *ngIf="title != null">{{title}}</div>
            <div class="share" *ngIf="title == null">{{'product.share' | translate}}</div>
            <div class="addthis_inline_share_toolbox" *ngIf="url == null"></div>
            <div class="addthis_inline_share_toolbox" [attr.data-url]="url" *ngIf="url != null"></div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddThisInlineComponent extends AbstractFacadeComponent implements AfterViewInit {
    @Input()
    product:ProductModel;

    @Input()
    title:string;

    @Input()
    url:string;

    @Output() scriptLoaded = new EventEmitter<boolean>();

    checkRunning = false;
    checkCount   = 0;
    currentUrl:string;
    repeatRef:any;

    networkLoadMonitor:NetworkLoadMonitor;


    constructor(injector:Injector,
                public windowRef:WindowRef) {
        super(injector);
        this.networkLoadMonitor = injector.get(NetworkLoadMonitor);
    }

    /**
     * Special url polling interval setup to cater for mobile bug.
     * Ionic will tell you when a view is in place but the url change happens only after
     * There is no event for this, this hack should be avoidable after ionic v4 & angular 6 router
     */
    ngAfterViewInit() {
        if (this.checkUrlRefresh()) {
            this.startUrlCheck();
        }
    }

    initPlugin() {
        const addThisTracker = environment.config.api.addThisTracker;
        // Empty handlers as we don't care if it fails or loads
        // Lets retry at least once
        if (addThisTracker != null && this.implementationDataService.getShowAddThisShareButtons()) {
            const addListeners = () => {
                if (!this.windowRef.addThis.gtmEventListener) {
                    const addThisEventHandler = (evt) => {
                        switch (evt.type) {
                            case 'addthis.menu.share':
                                this.windowRef['dataLayer'] = this.windowRef['dataLayer'] || [];
                                this.windowRef['dataLayer'].push({
                                    'event'        : 'addthis-social-share-event',
                                    'socialNetwork': evt.data.service,
                                    'socialAction' : 'share',
                                    'socialTarget' : evt.data.url
                                });
                                // GA4
                                this.store.dispatch(new GTMActions.TrackGAShareContent({content_type: 'product', item_id: this?.product?.sku}));
                                break;
                        }
                    };
                    this.windowRef.addThis.addEventListener('addthis.menu.share', addThisEventHandler);
                    this.windowRef.addThis.gtmEventListener = true;
                }

            };
            if (!this.windowRef.addThis) {
                this.addSub = this.networkLoadMonitor.whenXhrIdle(500)
                    .subscribe(() => {
                        ScriptLoader.loadNewScript(`https://s7.addthis.com/js/300/addthis_widget.js#pubid=${addThisTracker}`, 1, 'defer')
                            .then(() => {
                                addListeners();
                                this.scriptLoaded.emit(true);
                            }, (err) => {
                                // Moved to track via google analytics instead, because this happens so often and is eating our sentry event count
                                this.store.dispatch(new GTMActions.TrackAddThisError(new URL(this.windowRef.href)));
                                this.scriptLoaded.emit(false);
                            });
                    });

            }
            else {
                this.scriptLoaded.emit(true);
                this.windowRef.addThis.layers.refresh();
            }
        }
        else {
            this.scriptLoaded.emit(false);
        }
    }

    checkUrlRefresh() {
        this.checkCount++;

        if (this.currentUrl !== this.windowRef.href) {
            this.currentUrl = this.windowRef.href;

            // https://www.addthis.com/academy/using-dashboard-configuration-tools-dynamically/
            // SPA implementation to trigger add this after page load
            //console.log('Triggering add this init: ', window['addthis']);
            try {
                console.log('Running addthis url refresh', this.windowRef.href);
                this.windowRef.addThis.layers.refresh();
                return true;
            }
            catch (err) {
                // This may fail if not loaded or if add this not turned on
                //console.log('Error running the add this refresh');
                return false;
            }

        }
    }

    destroy() {
        super.destroy();
        if (this.checkRunning) {
            this.checkRunning = false;
            clearTimeout(this.repeatRef);
        }
    }


    startUrlCheck() {
        if (!this.checkRunning) {
            this.checkRunning = true;
            this.checkUrlRepeat();
        }
    }

    checkUrlRepeat() {
        this.checkUrlRefresh();

        // give up after 20 seconds
        if (this.checkCount < 110) {
            // Every 100ms for the first 10 seconds, thereafter every second
            const time     = this.checkCount < 100 ? 100 : 1000;
            //console.log('recheck in ', time, 'ms');
            this.repeatRef = setTimeout(() => this.checkUrlRepeat(), time);
        }
        else {
            //console.log('Giving up on check');
            this.checkRunning = false;
        }
    }

    get shouldDisplay() {
        return this.implementationDataService.getShowAddThisShareButtons();
    }

    get name() {
        return 'AddThisInlineComponent';
    }

}
