import {TranslateService} from '@ngx-translate/core';

export enum IdType {
    ID       = 'ID',
    PASSPORT = 'PASSPORT',
    ASYLUM   = 'ASYLUM'
}

export const GetIdTypeDropDown = async (translate:TranslateService):Promise<SelectOptions[]> => {
    return [
        {label: await translate.get('authReg.idNumber').toPromise(), value: IdType.ID},
        {label: await translate.get('authReg.passportNumber').toPromise(), value: IdType.PASSPORT},
        {label: await translate.get('authReg.asylumDocumentNumber').toPromise(), value: IdType.ASYLUM}
    ];
};

export interface SelectOptions {
    label:string;
    value:any;
}
