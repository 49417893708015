import {gql} from 'apollo-angular';

export const FETCH_CUSTOMER_ADDRESSES_CACHE = false;

export const FETCH_CUSTOMER_ADDRESSES = gql`
    query customer_addresses {
        customer {
            addresses {
                id
                company
                firstname
                lastname
                telephone
                city
                street
                region {
                    region
                    region_code
                    region_id
                }
                city
                country_code
                postcode
                default_billing
                default_shipping
            }
        }
    }
`;
