import {Component, Injector, Input} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ApiErrorHelper} from '../../error-handling/api-error-helper';
import {FacadeValidators} from '../../forms/facade-validators';
import {AbstractFacadeComponent} from '../../shared/ui/abstract-facade.component';
import {ViewState} from '../../shared/view-state/view-state';
import {AuthConfig, AuthType} from './auth.types';
import {Router} from '@angular/router';
import {OtpTimer} from '../../shared/otp-timer';
import {RecaptchaSectionType} from '../../implementation-config/implementation-data.types';

@Component({template: ''})
export abstract class AbstractAuthBaseComponent extends AbstractFacadeComponent {
    @Input() config:AuthConfig;

    AuthType = AuthType;

    pageForm:FormGroup;
    formBuilder:FormBuilder;
    facadeValidators:FacadeValidators;
    apiErrorHelper:ApiErrorHelper;
    translate:TranslateService;
    router:Router;
    state:ViewState   = new ViewState();
    otpTimer:OtpTimer = new OtpTimer();

    abstract buildForm();

    abstract handleFormAPI();

    protected recaptchaNoticeEnabled:boolean = this.implementationDataService.recaptchaNoticeEnabled(RecaptchaSectionType.AUTH);

    constructor(injector:Injector) {
        super(injector);
        this.formBuilder      = injector.get(FormBuilder);
        this.facadeValidators = injector.get(FacadeValidators);
        this.apiErrorHelper   = injector.get(ApiErrorHelper);
        this.translate        = injector.get(TranslateService);
        this.router           = injector.get(Router);
    }

    init() {
        super.init();
        // Activate the timer to disable otp resend buttons
        this.otpTimer.activateDisableResendTimer();
        // Create group
        this.buildForm();
        this.state.setReady();
        this.changeDetectorRef.detectChanges();

        this.addSub = this.otpTimer.onTimerTick.subscribe(time => this.changeDetectorRef.detectChanges());
    }

    destroy() {
        super.destroy();
        this.otpTimer.destroy();
    }

    submitForm() {
        this.logger.info('Submit called, form:', this.pageForm);
        Object.keys(this.pageForm.controls).forEach(controlKey => this.pageForm.get(controlKey).markAsTouched({onlySelf: true}));

        if (this.pageForm.valid) {
            this.pageForm.disable();
            this.handleFormAPI();
        }
        else {
            this.logger.debug('Ignoring submission as the form is invalid');
        }
    }
}
