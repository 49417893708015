export type GAEventActionType =
// General
    'search' |
    '404' |
    'page-view' |
    'add-this-error' |
    'firebase-upload-error' |
    'share-content' |
    'newsletter-signup-submit' |
    'contact-submission' |

    // Auth
    'auth-event' |
    'sign-up' |
    'login' |

    // Ecommerce
    'product-click' |
    'product-detail' |
    'add-to-cart' |
    'remove-from-cart' |
    'checkout' |
    'checkout-step' |
    'purchase' |
    'add-shipping-info' |
    'add-payment-info' |
    'view-cart' |
    'view-item-list' |
    'select-item' |
    'view-item' |
    'add-to-cart-ga4' |
    'add-to-cart-pdp' |
    'peach-nonce' |

    // Find a store
    'find-a-store-search' |
    'find-a-store-call' |
    'find-a-store-directions' |

    // SOH
    'soh' | // GA3 only
    'soh-modal' |
    'soh-progress' |
    'soh-product' |
    'soh-ui' |
    'soh-store' |
    'soh-location' |
    'stock-in-store-modal' |
    'stock-in-store-progress' |
    'stock-in-store-ui' |
    'stock-in-store-store' |
    'stock-in-store-location' |
    'check-in-store-stock' |
    'products-filter' | // GA3 only
    'products-filter-update' |
    'file-download' |

    // Custom
    'custom-event' |
    'cellular-contract-form' |
    'cellular-contract-progress' |
    'barcode-search' |
    'barcode-search-modal' |
    'barcode-search-result';

export interface GoogleAnalyticsEventAction {
    category:string;
    action:string;
    label?:string;
    value?:number | string;
    eventType:GAEventActionType;
}

export interface GoogleAnalyticsEcommerceEvent {
    eventData:GAeCommerce;
    eventType:GAEventActionType;
}

// eCommerce types
export interface GAeCommerce {
    currencyCode:string;
}

// Core Objects
export interface GAImpression extends GAeCommerce {
    impressions:GAProductFieldObject[];
}

export interface GAPurchase extends GAeCommerce {
    purchase:GAPurchaseData;
}

export interface GACheckout extends GAeCommerce {
    checkout:GACheckoutData;
}

export interface GACheckout extends GAeCommerce {
    checkout:GACheckoutData;
}

export interface GAAddToCart extends GAeCommerce {
    add:GAAddToCartData;
}

export interface GARemoveFromCart extends GAeCommerce {
    remove:GARemoveFromCartData;
}

export interface GACheckoutOption extends GAeCommerce {
    checkout_option:GACheckoutOptionData;
}

export interface GAProductClick extends GAeCommerce {
    click:GAProductClickData;
}

export interface GAProductDetail extends GAeCommerce {
    detail:GAProductDetailData;
}

export interface GAAuthEvent {
    action:GAAuthStep;
    label?:string;
    value?:number;
}

// Supplementary objects
export interface GAPurchaseData {
    actionField:GAActionField;
    products?:Array<GAProductFieldObject>;
}

export interface GACheckoutData {
    actionField?:GAActionField;
    products?:Array<GAProductFieldObject>;
}

export interface GAAddToCartData {
    actionField?:GAActionField;
    products:Array<GAProductFieldObject>;
}

export interface GARemoveFromCartData {
    actionField?:GAActionField;
    products:Array<GAProductFieldObject>;
}

export interface GAProductClickData {
    actionField:GAActionField;
    products:Array<GAProductFieldObject>;
}

export interface GAProductDetailData {
    actionField?:GAActionField;
    products:Array<GAProductFieldObject>;
}

export interface GACheckoutOptionData {
    actionField:GAActionField;
}

export interface GAActionField {
    id?:string;             // text	Yes*	The transaction ID (e.g. T1234). *Required if the action type is purchase or refund.
    affiliation?:string;    // text	No	The store or affiliation from which this transaction occurred (e.g. Google Store).
    revenue?:number;        // number	No	Specifies the total revenue or grand total associated with the transaction (e.g. 11.99). This value may include shipping, tax costs, or other adjustments to total revenue that you want to include as part of your revenue calculations. Note: if revenue is not set, its value will be automatically calculated using the product quantity and price fields of all products in the same hit.
    tax?:number;            // number	No	The total tax associated with the transaction.
    shipping?:number;       // number	No	The shipping cost associated with the transaction.
    coupon?:string;         // text	No	The transaction coupon redeemed with the transaction.
    list?:string;           // text	No	The list that the associated products belong to. Optional.
    step?:number;           // integer	No	A number representing a step in the checkout process. Optional on checkout actions.
    option?:string;         // text	No	Additional field for checkout and checkout_option actions that can describe option information on the checkout page, like selected payment method.
}

export interface GAProductFieldObject {
    id:string;              // text	Yes*	The product ID or SKU (e.g. P67890). *Either this field or name must be set.
    name:string;            // text	Yes*	The name of the product (e.g. Android T-Shirt). *Either this field or id must be set.
    brand?:string;          // text	No	    The brand associated with the product (e.g. Google).
    category?:string;       // text	No	    The category to which the product belongs (e.g. Apparel). Use / as a delimiter to specify up to 5-levels of hierarchy (e.g. Apparel/Men/T-Shirts).
    variant?:string;        // text	No	    The variant of the product (e.g. Black).
    price?:number;          // number	    No	The price of a product (e.g. 29.20).
    quantity?:number;       // integer	    No	The quantity of a product (e.g. 2).
    coupon?:string;         // text	No	    The coupon code associated with a product (e.g. SUMMER_SALE13).
    position?:number;       // integer	    No	The product's position in a list or collection (e.g. 2).

    // The category of the item. If used as part of a category hierarchy or taxonomy then this will be the first category.
    item_category?:string,
    // The ID of the list in which the item was presented to the user.
    item_list_id?:string,
    // The name of the list in which the item was presented to the user.
    item_list_name?:string
}

export enum GASohProgressValue {
    INITIALISED       = '1_initialised',
    SIZE_SELECTED     = '2_size_selected',
    SHOW_CLICKED      = '3_show_clicked',
    LOCATION_SELECTED = '4_location_selected',
    STORES_DISPLAYED  = '5_stores_displayed',
    CLOSED            = '6_closed'
}

export interface GASohModalEvent {
    action:'open' | 'close';
    label:string;
    value:string;
}

export interface GASohProgressEvent {
    action:'closed_early';
    label:GASohProgressValue;
    value:string;
}

export interface GASohProductEvent {
    action:'select_size';
    label:string;
}

export interface GASohUIEvent {
    action:'clicked_show-stock' | 'clicked_tab-view' | 'clicked_change-size';
    label:'configurable_sku' | 'list' | 'map';
    value:string;
}

export interface GASohStoreEvent {
    action:'store_interaction';
    label:'hours' | 'phone_number';
    value:string;
}

export interface GASohLocationEvent {
    action:'location';
    label:'geolocation' | 'input';
    value:string;
}

export enum GACellularContractsProgressValue {
    INITIALISED = '1_initialised',
    PREVETTED   = '2_pre-vetted',
    COMPLETE    = '3_complete'
}

export enum GACellularContractsFormEventValue {
    PREVETTING_SUCCESS = 'pre-vetting_success',
    PREVETTING_FAILED  = 'pre-vetting_failed',
    FORM_SUCCESS       = 'application_success',
    FORM_FAILED        = 'application_failed'
}

export interface GACellularContractsProgressEvent {
    action:'progress';
    label:GACellularContractsProgressValue;
    value:string;
}

export interface GACellularContractsFormEvent {
    action:'form-submission';
    label:GACellularContractsFormEventValue;
    value:string;
}

export enum GAStockInStoreProgressValue {
    INITIALISED       = '1_initialised',
    SIZE_SELECTED     = '2_size_selected',
    COUNTRY_SELECTED  = '3_country_selected',
    PROVINCE_SELECTED = '4_province_selected',
    AREA_SELECTED     = '5_area_selected',
    STORE_SELECTED    = '6_store_selected',
    CLOSED            = '7_closed'
}

export interface GAStockInStoreModalEvent {
    action:'open' | 'close';
    label:string;
}

export interface GAStockInStoreUiEvent {
    action:'size_changed' | 'country_changed' | 'province_changed' | 'area_changed' | 'store_changed' | 'location_clicked';
    label:string;
    value:string | number;
}

export interface GAStockInStoreProgressEvent {
    action:'closed_early' | 'closed';
    label:GAStockInStoreProgressValue;
    value:string | number;
}

export interface GAStockInStoreStoreEvent {
    action:'store_interaction';
    label:'hours' | 'phone_number';
    value:string | number;
}

export interface GAStockInStoreLocationEvent {
    action:'location_used' | 'location_preference_set';
    label:'geolocation' | 'manual';
    value:string | number;
}

export enum GAAuthStep {
    AUTH_EXISTING_USER                 = 'auth-existing-user', // User successfully logged in
    AUTH_NEW_USER                      = 'auth-new-user', // User successfully registered
    AUTH_NEW_USER_OTP                  = 'auth-new-user-opt', // New user successfully entered their OTP
    AUTH_EXISTING_USER_FORGOT_PASS     = 'auth-existing-user-forgot-pass', // User requested a password reset OTP
    AUTH_EXISTING_USER_FORGOT_PASS_OTP = 'auth-existing-user-opt', // User successfully entered their forgot password OTP
}

export enum CheckoutStepEnum {
    CART           = 1,
    AUTHENTICATION = 2,
    DELIVERY       = 3,
    PAYMENT        = 4,
    COMPLETE       = 5
}

export enum CheckoutOptions {
    AUTH_NONE                          = 'auth-none',
    AUTH_GUEST                         = 'auth-guest-user', // User clicked on checkout as a guest button
    AUTH_EXISTING_USER                 = 'auth-existing-user', // User successfully logged in
    AUTH_NEW_USER                      = 'auth-new-user', // User successfully registered
    AUTH_NEW_USER_OTP                  = 'auth-new-user-opt', // New user successfully entered their OTP
    AUTH_EXISTING_USER_FORGOT_PASS     = 'auth-existing-user-forgot-pass', // User requested a password reset OTP
    AUTH_EXISTING_USER_FORGOT_PASS_OTP = 'auth-existing-user-opt', // User successfully entered their forgot password OTP


    DELIVERY_METHOD_COLLECT            = 'delivery-method-collect',
    DELIVERY_INPUT_LOCATION            = 'delivery-input-location',
    DELIVERY_INPUT_MANUAL              = 'delivery-input-manual',
    DELIVERY_GUEST_OTP_SUCCESS         = 'delivery-guest-otp-success',
    PAYMENT_METHOD_CARD                = 'payment-method-card',
    PAYMENT_METHOD_APLUS               = 'payment-method-aplus',
    PAYMENT_METHOD_ONLINE_STORE_CREDIT = 'payment-method-online-store-credit',
    COMPLETE_FAILED_FROM_EXTERNAL      = 'complete-failed-from-external'
}
