import {
    AccountPageEnum,
    AuthPageEnum,
    BlogPageEnum,
    CheckoutPageEnum,
    PageTypeEnum,
    PageTypeVariables,
    PlusMorePageEnum,
    ProductsDisplayPageEnum,
    StoreDirectoryPageEnum
} from '../router/page-type.enum';
import {IMetaPageData} from '../seo/seo-service';
import {
    AckImplementationLocaleConventionUtil
} from '../../../../implementation/ack/main/cross-platform/locale/ack-implementation-locale-convention-util';
import {
    PepImplementationLocaleConventionUtil
} from '../../../../implementation/pep/main/cross-platform/locale/pep-implementation-locale-convention-util';
import {
    CubeImplementationLocaleConventionUtil
} from '../../../../implementation/ack/cube/cross-platform/locale/cube-implementation-locale-convention-util';


/**
 * This class is here to enforce conventions used in the locale
 * This class is then reused to build up JSON for the php based meta data implementation
 * so its critical that this single location is used!
 */
export class LocaleConventionUtil {

    // These need to be here for each section, they allow for easy page population
    // They also serve as a map for the url-config for page type to locale key
    // Please ensure each new entry starts with seoPage, see getPageTypeToSeoMapping() below
    static seoPageHome        = LocaleConventionUtil.getSeoPage(PageTypeEnum.HOME, 'home');
    static seoPageContact     = LocaleConventionUtil.getSeoPage(PageTypeEnum.CONTACT_US, 'contactUs');
    static seoPage404         = LocaleConventionUtil.getSeoPage(PageTypeEnum.NOT_FOUND_404, '404');
    static seoPageStoreFinder = LocaleConventionUtil.getSeoPage(PageTypeEnum.STORE_FINDER, 'storeFinder');

    static seoPageStoreDirectory         = LocaleConventionUtil.getSeoPage(PageTypeEnum.STORE_DIRECTORY, 'storeDirectory');
    static seoPageStoreDirectoryCountry  = LocaleConventionUtil.getSeoPage(PageTypeEnum.STORE_DIRECTORY + StoreDirectoryPageEnum.COUNTRY, 'storeDirectory.country');
    static seoPageStoreDirectoryProvince = LocaleConventionUtil.getSeoPage(PageTypeEnum.STORE_DIRECTORY + StoreDirectoryPageEnum.PROVINCE, 'storeDirectory.province');
    static seoPageStoreDirectoryCity     = LocaleConventionUtil.getSeoPage(PageTypeEnum.STORE_DIRECTORY + StoreDirectoryPageEnum.CITY, 'storeDirectory.city');
    static seoPageStoreDirectoryStore    = LocaleConventionUtil.getSeoPage(PageTypeEnum.STORE_DIRECTORY + StoreDirectoryPageEnum.STORE, 'storeDirectory.store');

    static seoPageCatalogue          = LocaleConventionUtil.getSeoPage(PageTypeEnum.CATALOGUE, 'catalogue');
    static seoPageEmailConfirm       = LocaleConventionUtil.getSeoPage(PageTypeEnum.EMAIL_CONFIRM, 'emailConfirm');
    static seoPageSearch             = LocaleConventionUtil.getSeoPage(PageTypeEnum.SEARCH_RESULTS, 'search');
    static seoPageProductDisplayTerm = LocaleConventionUtil.getSeoPage(PageTypeEnum.PRODUCT_LIST + ProductsDisplayPageEnum.TERM, 'productsDisplay');
    static seoPageProductDisplayList = LocaleConventionUtil.getSeoPage(PageTypeEnum.PRODUCT_LIST + ProductsDisplayPageEnum.LIST, 'productsDisplay');
    static seoPageBlog               = LocaleConventionUtil.getSeoPage(PageTypeEnum.BLOG, 'blog');
    static seoPageBlogCategories     = LocaleConventionUtil.getSeoPage(PageTypeEnum.BLOG + BlogPageEnum.CATEGORIES, 'blog.categories');
    static seoPageBlogTags           = LocaleConventionUtil.getSeoPage(PageTypeEnum.BLOG + BlogPageEnum.TAGS, 'blog.tags');

    static seoPageAuthReg          = LocaleConventionUtil.getSeoPage(PageTypeEnum.AUTH + AuthPageEnum.REGISTER, 'auth.register');
    static seoPageAuthOTP          = LocaleConventionUtil.getSeoPage(PageTypeEnum.AUTH + AuthPageEnum.OTP, 'auth.otp');
    static seoPageAuthEmailConfirm = LocaleConventionUtil.getSeoPage(PageTypeEnum.AUTH + AuthPageEnum.EMAIL_CONFIRM, 'auth.emailConfirm');
    static seoPageAuthLogin        = LocaleConventionUtil.getSeoPage(PageTypeEnum.AUTH + AuthPageEnum.LOGIN, 'auth.login');
    static seoPageAuthForgotPass   = LocaleConventionUtil.getSeoPage(PageTypeEnum.AUTH + AuthPageEnum.FORGOT, 'auth.forgotPass');
    static seoPageAuthResetPass    = LocaleConventionUtil.getSeoPage(PageTypeEnum.AUTH + AuthPageEnum.RESET, 'auth.resetPass');

    static seoPageAccountMenu                  = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + AccountPageEnum.MENU, 'account.accountMenu');
    static seoPageAccountDetails               = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + AccountPageEnum.ACCOUNT_DETAILS, 'account.accountDetails');
    static seoPageAccountDetailsChangeName     = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + AccountPageEnum.ACCOUNT_DETAILS, 'account.accountDetails.changeName');
    static seoPageAccountDetailsChangeEmail    = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + AccountPageEnum.ACCOUNT_DETAILS, 'account.accountDetails.changeEmail');
    static seoPageAccountDetailsChangePhone    = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + AccountPageEnum.ACCOUNT_DETAILS, 'account.accountDetails.changePhone');
    static seoPageAccountDetailsChangePhoneOtp = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + AccountPageEnum.ACCOUNT_DETAILS, 'account.accountDetails.changePhoneOtp');
    static seoPageAccountDetailsChangePass     = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + AccountPageEnum.ACCOUNT_DETAILS, 'account.accountDetails.changePass');
    static seoPageAddresses                    = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + AccountPageEnum.ADDRESSES, 'account.addresses');

    static seoPagePlusMoreProfile = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + PageTypeEnum.PLUS_MORE + PlusMorePageEnum.PROFILE, 'account.plusMore.profile');

    static seoPageCart     = LocaleConventionUtil.getSeoPage(PageTypeEnum.CART, 'checkout.cart');
    static seoPageDelivery = LocaleConventionUtil.getSeoPage(PageTypeEnum.CHECKOUT, 'checkout.delivery');
    static seoPagePayment  = LocaleConventionUtil.getSeoPage(PageTypeEnum.CHECKOUT + CheckoutPageEnum.PAYMENT, 'checkout.payment');
    static seoPageComplete = LocaleConventionUtil.getSeoPage(PageTypeEnum.CHECKOUT + CheckoutPageEnum.CONFIRMATION, 'checkout.complete');

    static seoPageOrdersList = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + AccountPageEnum.ORDERS, 'account.orders');
    static seoPageOrderPage  = LocaleConventionUtil.getSeoPage(PageTypeEnum.ACCOUNT + AccountPageEnum.ORDERS + `/:` + PageTypeVariables.ID, 'account.order');

    /**
     * This is used in the php meta data script to map page types to SEO data
     * This list needs to be kept up to date as new pages are added
     */
    public static getPageTypeToSeoMapping():{ [key:string]:IMetaPageData } {
        const output = {};
        const add    = (key:string, value) => {
            if (key.indexOf('seoPage') === 0) {
                const data     = JSON.parse(JSON.stringify(value));
                const pageType = data.pageType;
                delete data.pageType;
                delete data.localeSection;
                output[pageType] = data;
            }
        };
        Object.keys(LocaleConventionUtil).forEach(key => add(key, LocaleConventionUtil[key]));
        Object.keys(PepImplementationLocaleConventionUtil).forEach(key => add(key, PepImplementationLocaleConventionUtil[key]));
        Object.keys(AckImplementationLocaleConventionUtil).forEach(key => add(key, AckImplementationLocaleConventionUtil[key]));
        Object.keys(CubeImplementationLocaleConventionUtil).forEach(key => add(key, CubeImplementationLocaleConventionUtil[key]));
        return output;
    }

    public static getSeoPage(pageType:string, localeSection:string):IMetaPageData {
        const result            = {title: `seo.${localeSection}.title`, description: `seo.${localeSection}.description`};
        result['localeSection'] = localeSection;
        result['pageType']      = pageType;
        return result;
    }
}
