import {Action, State, StateContext} from '@ngxs/store';
import {Injectable, Injector} from '@angular/core';
import {GTMActions} from './gtm.actions';
import {Logger} from '../../../shared/logger';
import {
    CheckoutStepEnum,
    GAAddToCart,
    GACheckout,
    GACheckoutOption, GAEventActionType,
    GAPurchase,
    GARemoveFromCart,
    GoogleAnalyticsEcommerceEvent,
    GoogleAnalyticsEventAction
} from './google-analytics.types';
import {GTMEvents} from './gtm.events';
import {GoogleAnalytics4BuilderService} from './google-analytics4-builder.service';
import {GA4Event, GAFileDownload} from './google-analytics-4-internal.types';
import {StaticConfig} from '../../../shared/static-config';
import {TranslateService} from '@ngx-translate/core';
import produce from 'immer';
import {ItemTrackingInfo} from '../../../../../../generated/graphql';

export interface CartItemTrackingObject {
    itemTracking?:ItemTrackingInfo;
    sku?:string;
}

export interface GoogleTagManagerStateModel {
    cartTracking?:CartItemTrackingObject;
}

@State<GoogleTagManagerStateModel>({
    name: 'googleTagManager'
})
@Injectable()
export class GoogleTagManagerState {

    private emailAddressRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
    public logger:Logger;
    protected translateService:TranslateService;

    // General tracking
    @Action(GTMActions.TrackEvent)
    trackEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackEvent) {
        this.sendEvent(action.eventName, action.meta);
    }

    @Action(GTMActions.TrackPage)
    trackPage(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackPage) {
        this.sendPageTrack('TRACK_PAGEVIEW', action.page);
    }

    @Action(GTMActions.TrackAddThisError)
    trackAddThisError(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackAddThisError) {
        this.sendCustomEvent({
            category : 'error',
            action   : 'add-this-load-fail',
            label    : action.url.pathname + action.url.search + action.url.hash,
            eventType: 'add-this-error'
        });
    }

    @Action(GTMActions.TrackEvent404)
    trackEvent404(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackEvent404) {
        this.sendCustomEvent({
            category : 'error',
            action   : '404',
            label    : action.url.pathname + action.url.search + action.url.hash,
            eventType: '404'
        });
    }

    @Action(GTMActions.TrackEventFirebaseUploadError)
    trackEventFirebaseUploadError(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackEventFirebaseUploadError) {
        this.sendCustomEvent({
            category : 'error',
            action   : 'firebase-upload',
            label    : action.errorMsg,
            eventType: 'firebase-upload-error'
        });
    }

    // eCommerce
    @Action(GTMActions.TrackProductClick)
    trackProductClick(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackProductClick) {
        this.sendEcomEvent({
            eventData: action.gaProductClick,
            eventType: 'product-click'
        });
    }

    @Action(GTMActions.TrackProductDetail)
    trackProductDetail(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackProductDetail) {
        this.sendEcomEvent({
            eventData: action.gaProductDetail,
            eventType: 'product-detail'
        });
    }

    @Action(GTMActions.TrackAddToCart)
    trackAddToCart(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackAddToCart) {
        const cartItem              = action.cartItem;
        const eventData:GAAddToCart = {
            currencyCode: StaticConfig.PRODUCT_CURRENCY_CODE,
            add         : {
                products: [{
                    id      : cartItem.sku,
                    variant : cartItem.simpleSku,
                    name    : cartItem.name,
                    price   : cartItem.price,
                    quantity: action.quantity,
                    // needs to be added to impressions call
                    category: cartItem.category
                }]
            }
        };
        if (cartItem.category && eventData.add.products[0]) {
            eventData.add.products[0].category = cartItem.category;
        }

        if (cartItem.list) {
            eventData.add.actionField = {list: cartItem.list};
        }
        this.sendEcomEvent({
            eventData: eventData,
            eventType: (action.isPDP) ? 'add-to-cart-pdp' : 'add-to-cart'
        });
    }

    @Action(GTMActions.TrackRemoveFromCart)
    trackRemoveFromCart(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackRemoveFromCart) {
        const eventData:GARemoveFromCart = {
            currencyCode: StaticConfig.PRODUCT_CURRENCY_CODE,
            remove      : {
                actionField: {
                    option: action.option
                },
                products   : [{
                    id      : action.cartItem.sku,
                    variant : action.cartItem.simpleSku,
                    name    : action.cartItem.name,
                    quantity: action.quantity,
                    price   : action.cartItem.price,
                    category: action.cartItem.category
                }]
            }
        };
        this.sendEcomEvent({
            eventData: eventData,
            eventType: 'remove-from-cart'
        });
    }

    @Action(GTMActions.TrackCheckout)
    trackCheckout(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackCheckout) {
        const eventData:GACheckout = {
            currencyCode: StaticConfig.PRODUCT_CURRENCY_CODE,
            checkout    : {
                actionField: {
                    id  : action.cartId,
                    step: CheckoutStepEnum.CART
                },
                products   : action.products
            }
        };
        if (action.list) {
            eventData.checkout.actionField = {list: action.list};
        }
        this.sendEcomEvent({
            eventData: eventData,
            eventType: 'checkout'
        });
    }

    @Action(GTMActions.TrackCheckoutStep)
    trackCheckoutStep(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackCheckoutStep) {
        const eventData:GACheckout = {
            currencyCode: StaticConfig.PRODUCT_CURRENCY_CODE,
            checkout    : {
                actionField: {
                    id    : action.cartId,
                    step  : action.step,
                    option: action.option
                }
            }
        };

        this.sendEcomEvent({
            eventData: eventData,
            eventType: 'checkout-step'
        });
    }

    @Action(GTMActions.TrackCheckoutAuthentication)
    trackCheckoutAuthentication(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackCheckoutAuthentication) {
        const eventData:GACheckout = {
            currencyCode: StaticConfig.PRODUCT_CURRENCY_CODE,
            checkout    : {
                actionField: {
                    id    : action.cartId,
                    step  : CheckoutStepEnum.AUTHENTICATION,
                    option: action.option,
                }
            }
        };
        this.sendEcomEvent({
            eventData: eventData,
            eventType: 'checkout-step'
        });
    }

    @Action(GTMActions.TrackCheckoutOption)
    trackCheckoutOption(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackCheckoutOption) {
        const eventData:GACheckoutOption = {
            currencyCode   : StaticConfig.PRODUCT_CURRENCY_CODE,
            checkout_option: {
                actionField: {
                    id    : action.cartId,
                    step  : action.step,
                    option: action.option
                }
            }
        };
        this.sendEcomEvent({
            eventData: eventData,
            eventType: 'checkout-step'
        });
    }

    @Action(GTMActions.TrackPurchase)
    trackPurchase(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackPurchase) {
        const eventData:GAPurchase = {
            currencyCode: StaticConfig.PRODUCT_CURRENCY_CODE,
            purchase    : {
                actionField: {
                    id      : action.orderNumber,
                    revenue : action.amount,
                    shipping: action.shipping
                },
                products   : action.products
            }
        };
        this.sendEcomEvent({
            eventData: eventData,
            eventType: 'purchase'
        });
    }

    @Action(GTMActions.TrackAuthenticationStep)
    trackAuthenticationStep(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackAuthenticationStep) {
        const event:GoogleAnalyticsEventAction = {
            category : 'auth-event',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'auth-event'
        };
        this.sendCustomEvent(event);
        this.sendCustomGA4Event(event);
    }

    @Action(GTMActions.TrackStockInStoreModalEvent)
    trackStockInStoreModalEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackStockInStoreModalEvent) {
        const event:GoogleAnalyticsEventAction = {
            category : 'stock-in-store',
            action   : action.eventData.action,
            label    : action.eventData.label,
            eventType: 'stock-in-store-modal'
        };
        this.sendCustomEvent(event);
        this.sendCustomGA4Event(event);
    }

    @Action(GTMActions.TrackStockInStoreProgressEvent)
    trackStockInStoreProgressEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackStockInStoreProgressEvent) {
        const event:GoogleAnalyticsEventAction = {
            category : 'stock-in-store',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'stock-in-store-progress'
        };
        this.sendCustomEvent(event);
        this.sendCustomGA4Event(event);
    }

    @Action(GTMActions.TrackStockInStoreUIEvent)
    trackStockInStoreUIEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackStockInStoreUIEvent) {
        const event:GoogleAnalyticsEventAction = {
            category : 'stock-in-store',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'stock-in-store-ui'
        };
        this.sendCustomEvent(event);
        this.sendCustomGA4Event(event);
    }

    @Action(GTMActions.TrackStockInStoreStoreEvent)
    trackStockInStoreStoreEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackStockInStoreStoreEvent) {
        const event:GoogleAnalyticsEventAction = {
            category : 'stock-in-store',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'stock-in-store-store'
        };

        this.sendCustomEvent(event);
        this.sendCustomGA4Event(event);
    }

    @Action(GTMActions.TrackStockInStoreLocationEvent)
    trackStockInStoreLocationEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackStockInStoreLocationEvent) {
        const event:GoogleAnalyticsEventAction = {
            category : 'stock-in-store',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'stock-in-store-location'
        };

        this.sendCustomEvent(event);
        this.sendCustomGA4Event(event);
    }

    // Stock on hand
    @Action(GTMActions.TrackSohModalEvent)
    trackSohModalEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackSohModalEvent) {
        this.sendCustomEvent({
            category : 'SOH',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'soh'
        });

        // GA4
        this.sendCustomGA4Event({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'soh-modal'
        });
    }

    @Action(GTMActions.TrackSohProgressEvent)
    trackSohProgressEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackSohProgressEvent) {
        this.sendCustomEvent({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'soh-progress'
        });

        // GA4
        this.sendCustomGA4Event({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'soh-progress'
        });
    }

    @Action(GTMActions.TrackSohProductEvent)
    trackSohProductEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackSohProductEvent) {
        this.sendCustomEvent({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            eventType: 'soh-product'
        });

        this.sendCustomGA4Event({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            eventType: 'soh-product'
        });
    }

    @Action(GTMActions.TrackSohUIEvent)
    trackSohUIEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackSohUIEvent) {
        this.sendCustomEvent({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'soh-ui'
        });

        this.sendCustomGA4Event({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'soh-ui'
        });
    }

    @Action(GTMActions.TrackSohStoreEvent)
    trackSohStoreEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackSohStoreEvent) {
        this.sendCustomEvent({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'soh-store'
        });

        this.sendCustomGA4Event({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'soh-store'
        });
    }

    @Action(GTMActions.TrackSohLocationEvent)
    trackSohLocationEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackSohLocationEvent) {
        this.sendCustomEvent({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'soh-location'
        });

        this.sendCustomGA4Event({
            category : 'stock-on-hand',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'soh-location'
        });
    }

    @Action(GTMActions.TrackCellularContractFormEvent)
    trackCellularContractFormEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackCellularContractFormEvent) {
        this.sendCustomEvent({
            category : 'cellular-contracts',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'cellular-contract-form'
        });

        this.sendCustomGA4Event({
            category : 'cellular-contracts',
            action   : 'form-submission',
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'cellular-contract-form'
        });
    }

    @Action(GTMActions.TrackCellularContractProgressEvent)
    trackCellularContractProgressEvent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackCellularContractProgressEvent) {
        this.sendCustomEvent({
            category : 'CellularContracts',
            action   : action.eventData.action,
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'cellular-contract-progress'
        });

        this.sendCustomGA4Event({
            category : 'cellular-contracts',
            action   : 'form-submission',
            label    : action.eventData.label,
            value    : action.eventData.value,
            eventType: 'cellular-contract-progress'
        });
    }


    // GA 4 only events
    // TODO Create these as objects instead, which will be much cleaner, but rather do it as part of the NgXs implementation, cause it's going to need to be redone anyways
    // Ecommerce
    @Action(GTMActions.TrackSelectItem)
    trackSelectItem(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackSelectItem) {
        const event:GA4Event = {eventData: action.eventData, eventType: 'select-item'};
        this.sendGA4Event(event);

        /**
         * Persist the GA4 Cart Item Object in memory for later usage
         */
        return ctx.setState(produce(draft => {
            draft.cartTracking = {
                itemTracking: action.eventData.itemTracking,
                sku: action.eventData.product.sku
            };
        }));
    }

    @Action(GTMActions.TrackViewItem)
    trackViewItem(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackViewItem) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'view-item'});
    }

    @Action(GTMActions.TrackAddToCartGA4)
    trackAddToCartGA4(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackAddToCartGA4) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'add-to-cart-ga4'});
    }

    @Action(GTMActions.TrackViewItemList)
    trackViewItemList(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackViewItemList) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'view-item-list'});
    }

    @Action(GTMActions.TrackAddShippingInfo)
    trackAddShippingInfo(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackAddShippingInfo) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'add-shipping-info'});
    }

    @Action(GTMActions.TrackAddPaymentInfo)
    trackAddPaymentInfo(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackAddPaymentInfo) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'add-payment-info'});
    }

    @Action(GTMActions.TrackViewCart)
    trackViewCart(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackViewCart) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'view-cart'});
    }

    // General
    @Action(GTMActions.TrackPeachNonce)
    trackPeachNonce(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackPeachNonce) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'peach-nonce'});
    }

    @Action(GTMActions.TrackSignUp)
    trackSignUp(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackSignUp) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'sign-up'});
    }

    @Action(GTMActions.TrackGALogin)
    trackGALogin(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGALogin) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'login'});
    }

    @Action(GTMActions.TrackGAShareContent)
    trackGAShareContent(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGAShareContent) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'share-content'});
    }

    @Action(GTMActions.TrackGAPageView)
    trackGAPageView(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGAPageView) {
        this.getDeps();
        // POPIA Compliance, strip email addresses
        if (action?.eventData?.page_path && action.eventData.page_title) {
            action.eventData.page_path  = action.eventData.page_path.replace(this.emailAddressRegex, '');
            action.eventData.page_title = action.eventData.page_title.replace(this.emailAddressRegex, '');
        }

        try {
            action.eventData.page_title = this.translateService.instant(action.eventData.page_title);
        }
        catch (error) {
            this.logger.warn('Unable to translate pageview page title: ', action.eventData.page_title);
        }
        this.sendGA4Event({eventData: action.eventData, eventType: 'page-view'});
    }

    @Action(GTMActions.TrackGANewsletterSignupSubmit)
    trackGANewsletterSignupSubmit(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGANewsletterSignupSubmit) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'newsletter-signup-submit'});
    }

    @Action(GTMActions.TrackGAContactSubmission)
    trackGAContactSubmission(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGAContactSubmission) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'contact-submission'});
    }

    @Action(GTMActions.TrackGAFindAStoreSearch)
    trackGAFindAStoreSearch(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGAFindAStoreSearch) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'find-a-store-search'});
    }

    @Action(GTMActions.TrackGAFindAStoreCall)
    trackGAFindAStoreCall(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGAFindAStoreCall) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'find-a-store-call'});
    }

    @Action(GTMActions.TrackGAFindAStoreDirections)
    trackGAFindAStoreDirections(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGAFindAStoreDirections) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'find-a-store-directions'});
    }

    @Action(GTMActions.TrackGASearch)
    trackGASearch(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGASearch) {
        // POPIA Compliance, strip email addresses
        if (action?.eventData?.search_term) {
            action.eventData.search_term = action.eventData.search_term.replace(this.emailAddressRegex, '');
        }
        this.sendGA4Event({eventData: action.eventData, eventType: 'search'});
    }

    @Action(GTMActions.TrackGACheckInStoreStock)
    trackGACheckInStoreStock(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGACheckInStoreStock) {
        this.sendGA4Event({eventData: action.eventData, eventType: 'check-in-store-stock'});
    }

    @Action(GTMActions.TrackFileDownload)
    trackFileDownload(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackFileDownload) {
        this.sendGA4Event({
            eventData: action.eventData,
            eventType: 'file-download'
        });
    }

    @Action(GTMActions.TrackGABarcodeSearchModal)
    trackGABarcodeSearchModal(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGABarcodeSearchModal) {
        // this.sendGA4Event({eventData: action.eventData, eventType: 'barcode-search-modal'});
        const event:GoogleAnalyticsEventAction = {
            category : 'barcode-search',
            eventType: 'barcode-search-modal',
            action   : action.eventData.action
        };

        this.sendCustomEvent(event);
        this.sendCustomGA4Event(event);
    }

    @Action(GTMActions.TrackGABarcodeSearch)
    trackGABarcodeSearch(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGASearch) {
        // this.sendGA4Event({eventData: action.eventData, eventType: 'barcode-search'});
        const event:GoogleAnalyticsEventAction = {
            category : 'barcode-search',
            eventType: 'barcode-search',
            action   : 'search',
            label    : action.eventData.search_term
        };
        this.sendCustomEvent(event);
        this.sendCustomGA4Event(event);
    }

    @Action(GTMActions.TrackGABarcodeSearchSuccess)
    trackGABarcodeSearchSuccess(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGABarcodeSearchSuccess) {
        // this.sendGA4Event({eventData: action.eventData, eventType: 'barcode-search-success'});
        const event:GoogleAnalyticsEventAction = {
            category : 'barcode-search',
            eventType: 'barcode-search-result',
            action   : 'success',
            label    : action.eventData.search_type
        };
        this.sendCustomEvent(event);
        this.sendCustomGA4Event(event);
    }

    @Action(GTMActions.TrackGABarcodeSearchFailed)
    trackGABarcodeSearchFailed(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGABarcodeSearchFailed) {
        // this.sendGA4Event({eventData: action.eventData, eventType: 'barcode-search-failed'});
        const event:GoogleAnalyticsEventAction = {
            category : 'barcode-search',
            eventType: 'barcode-search-result',
            action   : 'failed',
            label    : action.eventData.search_type
        };

        this.sendCustomEvent(event);
        this.sendCustomGA4Event(event, {
            message: action.eventData?.error_message
        });
    }

    @Action(GTMActions.TrackGAProductsFilter)
    trackGAProductsFilter(ctx:StateContext<GoogleTagManagerStateModel>, action:GTMActions.TrackGAProductsFilter) {
        // GA3
        this.sendCustomEvent({
            category : 'products-filter',
            eventType: 'products-filter',
            action   : 'update',
            label    : action.eventData.filter_name,
            value    : action.eventData.filter_value
        });

        // GA 4
        const data = {
            category_name: action.eventData?.filter_category_name,
            category_id  : action.eventData?.filter_category_id,
            type         : action.eventData?.filter_type,
            value        : action.eventData?.filter_value
        };
        this.sendCustomGA4Event({
            category : 'products-filter',
            eventType: 'products-filter-update',
            action   : 'update',
            label    : action.eventData.filter_name,
            value    : action.eventData.filter_value
        }, data);
    }

    constructor(private injector:Injector,
                private googleAnalytics4Builder:GoogleAnalytics4BuilderService) {
        // NOTE: Be wary of adding dependancies here as they may cause a cyclic dependency pls use getDeps()
        this.logger = injector.get(Logger).getLogger('GoogleTagManagerState');
    }

    private getDeps() {
        if (!this.translateService) {
            this.translateService = this.injector.get(TranslateService);
        }
    }

    private sendCustomEvent(event:GoogleAnalyticsEventAction) {
        // NOTE: The meta data variables used here must remain the same as they are configured in GTM
        this.sendEvent(GTMEvents.GOOGLE_ANALYTICS_EVENT, {gaEventCategory: event.category, gaEventAction: event.action, gaEventLabel: event.label, gaEventValue: event.value});
    }

    private sendEvent(eventName:string, meta?:object):void {
        let evtObj = {event: eventName};
        if (meta) evtObj = Object.assign(evtObj, meta);
        this.logger.debug('GTM Event: ', evtObj);
        this.dataLayer.push(evtObj);
    }

    private sendPageTrack(eventName:string, page:string):void {
        const evtObj = {event: eventName, page};
        this.logger.debug('GTM Page: ', evtObj);
        this.dataLayer.push(evtObj);
    }

    private sendEcomEvent(action:GoogleAnalyticsEcommerceEvent) {
        // GA4
        const ga4Event = this.googleAnalytics4Builder.buildGA4Event(action as GoogleAnalyticsEcommerceEvent);
        if (ga4Event != null) {
            this.dataLayer.push({});
            this.dataLayer.push(ga4Event);
        }
        // GA3
        const evtObj = {
            'event'    : 'ecommerce-event',
            'ecommerce': action.eventData
        };
        this.logger.debug('GTM eCommerce Event: ', evtObj);
        // It's recommended that you use the following command to clear the ecommerce object prior to pushing an ecommerce event to the data layer. Clearing the object will prevent multiple ecommerce events on a page from affecting each other.
        // https://developers.google.com/tag-manager/enhanced-ecommerce#clear-ecommerce
        this.dataLayer.push({ecommerce: null});
        this.dataLayer.push(evtObj);
    }

    private sendGA4Event(action:GA4Event) {
        // GA4
        this.dataLayer.push({});
        const ga4Event = this.googleAnalytics4Builder.buildGA4Event(action as GA4Event);
        if (ga4Event != null) {
            this.dataLayer.push({});
            this.dataLayer.push(ga4Event);
        }
    }

    private sendCustomGA4Event(event:GoogleAnalyticsEventAction, data:any = null) {
        // New GA4
        const action:GA4Event = {
            eventType: 'custom-event',
            eventData: {
                type    : event.eventType,
                category: event.category,
                action  : event.action,
                label   : event.label,
                value   : event.value
            }
        };
        // append data if available
        if (data) action.eventData['data'] = data;
        this.sendGA4Event(action as GA4Event);
    }

    private get dataLayer():any[] {
        if (window['dataLayer'] == null) {
            window['dataLayer'] = [];
        }
        return window['dataLayer'];
    }
}
