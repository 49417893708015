import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Injector,
    Input,
    ViewChild
} from '@angular/core';
import {IMenuDropDown} from '../menu-button/menu-buttton.component';
import {AbstractFacadeComponent} from '../../../../cross-platform/shared/ui/abstract-facade.component';
import {
    FMDesktopMenuDropDown,
    FMDesktopMenuElementSizes,
    FMDesktopMenuItemGroup,
    FMDesktopTopMenuItemCatBut,
    FMDesktopTopMenuItemCustomBut,
    FMDesktopTopMenuItemGroup,
    FMMenuItemType
} from '../../../../cross-platform/external-data/firebase/firebase.types';
import {DomPositionDirective} from '../../../../cross-platform/shared/ui/dom-position.directive';
import {TranslateService} from '@ngx-translate/core';
import {FakeLinkEvent} from '../../../../cross-platform/shared/ui/fake-link.directive';
import {DesktopMainMenuConfigParser, DesktopMenuUIItem} from './desktop-main-menu-config-parser';
import {CategoryHash} from '../../../../cross-platform/external-data/magento/magento.reducer.types';
import {WordpressLogicService} from '../../../../cross-platform/external-data/wordpress/wordpress-logic.service';
import {MenuPageConfigComponentACF} from '../../../../cross-platform/external-data/wordpress/model/wordpress.types';


@Component({
    selector       : 'column-menu',
    template       : `
        <div domPosition [ngClass]="{hidden:!visible}" class="column-menu-wrapper">
            <div class="pointer" [ngStyle]="pointerStyle"></div>
            <div class="column-wrapper-container" *ngIf="hasColumns">
                <div class="column-wrapper clearfix" [ngStyle]="columnWrapperStyle">
                    <div class="column-outer" *ngFor="let col of uiColumns" [ngStyle]="col.style" [class]="col.cssClasses">
                        <div class="column-inner">
                            <div class="item-wrapper" *ngFor="let item of col.uiElements" [ngStyle]="item.wrapperStyle" [attr.data-urlKeyPath]="item.urlKeyPath">
                                <ng-container *ngIf="item.type === 'link'">
                                    <a [fakeLink]="item.link" (fakeClick)="handleLink($event)" [attr.class]="item.cssClasses">
                                        <facade-icon *ngIf="item.icon" [icon]="item.icon"></facade-icon> {{item.label}}
                                    </a>
                                </ng-container>
                                <!-- category list is converted to buttons -->

                                <ng-container *ngIf="item.type === 'button'">
                                    <a role="button" [fakeLink]="item.link" (fakeClick)="handleLink($event)" [attr.class]="item.cssClasses">
                                        <facade-icon *ngIf="item.icon" [icon]="item.icon"></facade-icon> {{item.label}}
                                    </a>
                                </ng-container>

                                <ng-container *ngIf="item.type === 'divider'">
                                    <div [attr.class]="item.cssClasses">
                                        <div class="divider-inner"></div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="item.type === 'image'">
                                    <a *ngIf="item.link" [fakeLink]="item.link" (fakeClick)="handleLink($event)">
                                        <img [facadeImg]="item.src" [class]="item.cssClasses">
                                    </a>
                                    <img *ngIf="!item.link" [facadeImg]="item.src" [class]="item.cssClasses">
                                </ng-container>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnMenuComponent extends AbstractFacadeComponent implements IMenuDropDown {

    @ViewChild(DomPositionDirective, {static: true})
    mainWrapper:DomPositionDirective;

    visible    = false;
    onRollOver = new EventEmitter();
    onRollOut  = new EventEmitter();
    pointerStyle;

    dropDown:FMDesktopMenuDropDown;

    columnWrapperStyle:object;
    uiColumns:{ style:object, cssClasses?:string, uiElements:DesktopMenuUIItem[] }[];
    hasColumns:boolean;
    defaultColumnWidth                             = 300;
    laidOut                                        = false;
    menuConfig:MenuPageConfigComponentACF;
    configLoadStatus:'none' | 'loading' | 'loaded' = 'none';

    parentItem:FMDesktopTopMenuItemGroup;
    parentButton:DomPositionDirective;

    constructor(injector:Injector,
                public translateService:TranslateService,
                public parser:DesktopMainMenuConfigParser,
                public wordpressLogicService:WordpressLogicService) {
        super(injector);
    }

    @Input()
    set menuData(value:{ dropDown:FMDesktopMenuDropDown, sizes:FMDesktopMenuElementSizes, categoryUrlKeyHash:CategoryHash, parentItem?:FMDesktopTopMenuItemGroup }) {
        // Clone to make editable
        this.dropDown                  = value ? JSON.parse(JSON.stringify(value.dropDown)) : null;
        this.parser.categoryUrlKeyHash = value ? value.categoryUrlKeyHash : null;
        this.parser.sizes              = value ? value.sizes : null;
        this.laidOut                   = false;
        this.parentItem                = value.parentItem;
        if (this.dropDown) {
            this.parser.convertDesktopDynamicLayouts(this.dropDown.columns).then(cols => {
                this.dropDown.columns = cols;
            });
        }
    }

    @HostListener('mouseover', ['$event'])
    mouseover($event) {
        this.onRollOver.emit($event);
    }

    @HostListener('mouseleave', ['$event'])
    mouseleave($event) {
        this.onRollOut.emit($event);
    }

    /*
    Layout
    --------------------------------------------
    */
    async layoutColumns() {
        if (this.dropDown && !this.laidOut) {
            this.laidOut = true;

            this.dropDown.columns.forEach(col => {
                if (col.defaultWidth == null) col.defaultWidth = this.dropDown.defaultColumnWidth ? this.dropDown.defaultColumnWidth : this.defaultColumnWidth;
            });

            let uiColumns = [];

            for (let i = 0; i < this.dropDown.columns.length; i++) {
                const col = this.dropDown.columns[i];
                uiColumns.push({
                    defaultWidth: col.defaultWidth,
                    cssClasses: col.cssClasses,
                    uiElements: await this.parser.buildUIElements(col.items as FMDesktopMenuItemGroup[])
                });
            }

            // Calculate max width based on column sizes
            // (filter columns with empty uiElements)
            let maxWidth = 0;
            uiColumns = uiColumns.filter(col => {
                const include = col.uiElements?.length > 0;
                if (include) maxWidth += col.defaultWidth;
                return include;
            }).map(col => {
                col.style = {
                    'width': col.defaultWidth / maxWidth * 100 + '%'
                };
                return col;
            });

            this.columnWrapperStyle = {
                'max-width': maxWidth + 'px'
            };

            this.hasColumns = uiColumns.map(i => i.uiElements).reduce((all, curr) => {
                all.push(curr);
                return all;
            }, []).reduce((acc, val) => acc.concat(val), []).length > 0;

            this.uiColumns  = uiColumns;
            this.changeDetectorRef.detectChanges();
        }
    }

    handleLink(fakeLinkEvent:FakeLinkEvent) {
        super.handleLink(fakeLinkEvent);
        this.onRollOut.emit(null);
    }

    async show(rolledOverElement:DomPositionDirective) {
        this.parentButton = rolledOverElement;
        this.visible = true;
        await this.layoutColumns();
        this.changeDetectorRef.detectChanges();

        // Get element positions and update as per the current state
        const buttonRect  = rolledOverElement.getAbsoluteBounds();
        const wrapperRect = this.mainWrapper.getAbsoluteBounds();

        this.pointerStyle = {
            'left': buttonRect.left + (buttonRect.width / 2) - wrapperRect.left + 'px'
        };

        if (this.dropDown && this.dropDown.startUnderButton) {
            const max               = this.columnWrapperStyle['max-width'];
            this.columnWrapperStyle = {
                'max-width'  : max,
                'margin-left': buttonRect.left - wrapperRect.left + 'px',
            };
        }
        if (this.implementationDataService.getShowCmsMenuContent() && !this.menuConfig && this.parentItem) this.loadMenuConfig();
        this.changeDetectorRef.detectChanges();
    }

    loadMenuConfig() {
        if (this.configLoadStatus === 'none' && !this.menuConfig) {
            this.configLoadStatus = 'loading';
            let pageLink          = '';
            if (this.parentItem.type === FMMenuItemType.categoryButton) {
                pageLink = this.urlService.buildUrlForProductList(this.parser.categoryUrlKeyHash[(<FMDesktopTopMenuItemCatBut>this.parentItem).urlKeyPath]);
            }
            else {
                pageLink = (<FMDesktopTopMenuItemCustomBut>this.parentItem).link;
            }

            this.wordpressLogicService.getMenuConfigPage(pageLink).subscribe((result) => {
                this.configLoadStatus = 'loaded';
                this.menuConfig       = (result as any).data?.acf as MenuPageConfigComponentACF;

                if (this.menuConfig?.menuAdvertImage) {
                    this.dropDown?.columns.push({
                        cssClasses: 'cms-image-column',
                        items     : [{
                            cssClasses : 'cms-image',
                            type       : FMMenuItemType.image,
                            src        : this.menuConfig.menuAdvertImage,
                            link       : this.menuConfig.menuAdvertLink,
                            imageHeight: 100
                        }]
                    });
                    this.laidOut = false;
                    this.layoutColumns();
                    // align with parent button if menu is already open
                    if (this.parentButton && this.visible) this.show(this.parentButton);
                }
            });

        }
    }

    hide() {
        this.visible = false;
        this.changeDetectorRef.detectChanges();
    }

    get name() {
        return 'ColumnMenuComponent';
    }
}
