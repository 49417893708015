import {Injectable, Injector} from '@angular/core';
import {GlobalSelectors} from '../../ngxs/global.selectors';
import {Observable} from 'rxjs';
import {ExternalResponse} from '../external-response';
import {AbstractFacadeService} from '../abstract-facade.service';
import {WindowRef} from '../../shared/ui/window-ref';
import {VALIDATE_PHONE_NUMBER, VALIDATE_PHONE_NUMBER_CACHE} from './gql-queries/validate-phone-number';
import {map} from 'rxjs/operators';
import {
    GenerateSignatureResponse,
    QueryValidatePhoneNumberArgs,
    ValidatePhoneNumberResponse
} from '../../../../../generated/graphql';
import {DONATIONS_GENERATE_SIGNATURE, DONATIONS_GENERATE_SIGNATURE_CACHE} from './gql-queries/layby-generate-signature';
import {environment} from '../../implementation/environment';
import {GTMActions} from '../google/google-tag-manager/gtm.actions';
import {CheckoutStepEnum} from '../google/google-tag-manager/google-analytics.types';

export interface AdHocPaymentDetails {
    amount:string;
    name?:string;
    email?:string;
    country?:string;
    province?:string;
    category?:string;
    phoneNumber?:string;
    defaultPaymentMethod:'CARD' | 'MASTERPASS';
    redirectPath?:string;
}

interface ValidatePhoneNumberGQLResponse {
    validatePhoneNumber:ValidatePhoneNumberResponse;
}


export interface GenerateSignatureAndStoreResponse {
    generateSignatureAndStore:GenerateSignatureResponse;
}

@Injectable()
export class CloudFunctionsWrapperService extends AbstractFacadeService {

    constructor(injector:Injector,
                public globalSelectors:GlobalSelectors,
                public windowRef:WindowRef) {
        super(injector);
    }

    libPhoneNumber(variables:QueryValidatePhoneNumberArgs):Observable<ExternalResponse<ValidatePhoneNumberResponse>> {
        return this.apollo.query({
            query    : VALIDATE_PHONE_NUMBER,
            variables: variables
        }, VALIDATE_PHONE_NUMBER_CACHE).pipe(
            map((response:ExternalResponse<ValidatePhoneNumberGQLResponse>) => {
                // @ts-ignore
                const newResponse:ExternalResponse<ParseAndCheckResponse> = Object.assign({}, response);
                newResponse.data                                          = response.data.validatePhoneNumber;
                return newResponse;
            })
        );
    }

    generatePaymentParams(formParams:AdHocPaymentDetails) {
        let sendParams:object;
        const uuid = new Date().getTime() + '-' + Math.round(Math.random() * 1000);

        this.dispatchDonationGACheckoutEvent(uuid, CheckoutStepEnum.PAYMENT, formParams.amount);

        const redirectUrl = formParams.redirectPath ? encodeURIComponent(this.windowRef.origin + formParams.redirectPath) : encodeURIComponent(this.windowRef.href);
        sendParams        = {
            'amount'               : formParams.amount,
            'currency'             : 'ZAR',
            'defaultPaymentMethod' : formParams.defaultPaymentMethod,
            'merchantTransactionId': uuid,
            'nonce'                : uuid,
            'paymentType'          : 'DB',
            // Redirect url will be the same cloud function with different action and url
            'shopperResultUrl': `${environment.config.api.middlewareBasePath}/api/v1/pep/peach-payment/callback?url=${redirectUrl}`
        };
        // Optional fields
        if (formParams.name) sendParams['customer.givenName'] = formParams.name;
        if (formParams.phoneNumber) sendParams['customer.mobile'] = formParams.phoneNumber;
        if (formParams.email) sendParams['customer.email'] = formParams.email;
        if (formParams.country) sendParams['customParameters.customer_country'] = formParams.country;
        if (formParams.province) sendParams['customParameters.customer_province'] = formParams.province;
        if (formParams.category) sendParams['customParameters.customer_category'] = formParams.category;


        return this.apollo.mutate<GenerateSignatureAndStoreResponse>(
            {
                mutation : DONATIONS_GENERATE_SIGNATURE,
                variables: {formDataJson: JSON.stringify(sendParams)}
            },
            DONATIONS_GENERATE_SIGNATURE_CACHE
        );
    }

    dispatchDonationGACheckoutEvent(orderId:string, checkoutStep:CheckoutStepEnum, amount:string) {
        this.store.dispatch(new GTMActions.TrackCheckoutStep(orderId, checkoutStep));
        /*this.ngRedux.dispatch(GTMActions.trackCheckout({
            currencyCode: StaticConfig.PRODUCT_CURRENCY_CODE,
            checkout    : {
                actionField: {
                    id  : orderId,
                    step: checkoutStep
                },
                products   : [{
                    id      : 'donation',
                    name    : 'donation',
                    quantity: 1,
                    price   : Number(amount)
                }]
            }
        }));*/
    }

    dispatchDonationGAPurchaseEvent(orderId:string, amount:number) {
        this.store.dispatch(new GTMActions.TrackPurchase(orderId, amount, 0, []));

        /*this.ngRedux.dispatch(GTMActions.trackPurchase(
            {
                currencyCode: StaticConfig.PRODUCT_CURRENCY_CODE,
                purchase    : {
                    actionField: {
                        id      : orderId,
                        revenue : amount,
                        shipping: 0,
                    },
                    products   : [{
                        id      : 'donation',
                        name    : 'donation',
                        quantity: 1,
                        price   : amount
                    }]
                }
            }
        ));*/
    }

    getName() {
        return 'CloudFunctionsWrapperService';
    }


}
