import {gql} from '@apollo/client/core';

export const FACADE_URL_RESOLVER_CACHE = true;

export const FACADE_URL_RESOLVER = gql`
    query facadeUrlResolver($url:String!) {
        facadeUrlResolver(url: $url) {
            entityId
            redirectCode
            relativeUrl
            type
            source
        }
    }
`;
