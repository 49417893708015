import {ElementRef, Inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, concat, defer, fromEvent, Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, flatMap, map, take} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {DocumentRef} from './ui/document-ref';

@Injectable()
export class VisibilityService {

    private pageVisible$:Observable<boolean>;
    private triggerAllVisible$:BehaviorSubject<boolean>;

    constructor(documentRef:DocumentRef) {
        this.pageVisible$       = concat(
            defer(() => of(!documentRef.nativeDocument.hidden)),
            fromEvent(documentRef.nativeDocument, 'visibilitychange')
                .pipe(
                    map(e => !documentRef.nativeDocument.hidden)
                )
        );
        this.triggerAllVisible$ = new BehaviorSubject<boolean>(false);
    }

    triggerAllVisible() {
        this.triggerAllVisible$.next(true);
    }

    elementInSight(element:ElementRef, options:IntersectionObserverInit = null):Observable<boolean> {
        try {
            let testObserver = new IntersectionObserver(entries => entries); // Test for Intersection Observer as it's not available in IE at all
            testObserver.disconnect();

            const elementVisible$ = new Observable<IntersectionObserverEntry[]>(observer => {
                const intersectionObserver = new IntersectionObserver((entries:IntersectionObserverEntry[]) => {
                    observer.next(entries);
                }, options);

                intersectionObserver.observe(element.nativeElement);

                return () => {
                    intersectionObserver.disconnect();
                };

            })
                .pipe(
                    flatMap(entries => entries),
                    // Critical check for older partial support browsers that didn't yet implement isIntersecting
                    map((entry:IntersectionObserverEntry) => entry.isIntersecting === undefined ? true : entry.isIntersecting),
                    distinctUntilChanged()
                );

            return combineLatest(
                this.pageVisible$,
                elementVisible$,
                this.triggerAllVisible$,
                (pageVisible, elementVisible, allVisible) => pageVisible && elementVisible || allVisible
            ).pipe(
                distinctUntilChanged(),
                filter(visible => visible),
                take(1)
            );
        }
        catch (e) {
            return of(true);
        }
    }

}
