import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Logger} from '../../shared/logger';
import {ResponseCode} from '../enum/response-code.enum';
import {ExternalResponse} from '../external-response';
import {FMConfigAPI} from '../firebase/firebase.types';
import {NewsletterResponse, NewsletterResponseCodeEnum} from './newsletter.types';
import {environment} from '../../implementation/environment';
import {ExtendedApolloService} from '../extended-apollo/extended-apollo.service';
import {SUBSCRIBE_TO_NEWSLETTER} from './gql-mutations/subscript-to-newsletter';
import {CONFIRM_EMAIL} from './gql-mutations/confirm-email';
import {GTMActions} from '../google/google-tag-manager/gtm.actions';
import {Store} from '@ngxs/store';


interface SubscribeToNewsletterResponse {
    prospect:NewsletterResponse;
}

interface EmailConfirmResponse {
    emailConfirm:any;
}

@Injectable()
export class CustomerCommunicationService {

    constructor(public logger:Logger,
                private apollo:ExtendedApolloService,
                private store:Store) {
        this.logger = this.logger.getLogger('CustomerCommunicationService');
    }

    newsletterSubscribe(emailAddress:string):Observable<ExternalResponse<NewsletterResponse>> {
        this.logger.debug('Received newsletter API request');

        return this.newsletterSubscribeAPI(environment.config.api, emailAddress);
    }

    emailConfirm(customerKey:string):Observable<ExternalResponse> {
        this.logger.debug('Received email confirm API request');

        return this.emailConfirmAPI(environment.config.api, customerKey);
    }


    /*
    API Implementations
    --------------------------------------------
    */
    private newsletterSubscribeAPI(api:FMConfigAPI, emailAddress:string) {
        // https://competitions.pdws.co.za/REST/Prospect

        this.logger.debug('Making newsletter API request');

        // No need for an interface as its only defined here
        const variables = {
            // Add the basic auth information for the call
            emailAddress: emailAddress,
            brand       : api.newsletterAPIBrand,
            type        : 'newsletter'
        };

        return this.apollo.mutate({
            mutation : SUBSCRIBE_TO_NEWSLETTER,
            variables: variables
        })
            .pipe(
                map((response:ExternalResponse<SubscribeToNewsletterResponse>) => {
                    // @ts-ignore
                    const newResponse:ExternalResponse<NewsletterResponse> = Object.assign({}, response);
                    newResponse.data                                       = response.data.prospect;
                    // Lets create a default response if we don't find the correct data
                    let data                                               = newResponse.data;
                    if (data == null || typeof data !== 'object' || data.Response == null || data.Response.ResponseCode == null) {
                        data = {Response: {ResponseCode: NewsletterResponseCodeEnum.UNEXPECTED_ERROR}};
                    }
                    // We now know there is a data object and it has a response code
                    else {
                        // Lets ensure its a know response code
                        let known = false;
                        for (const key in NewsletterResponseCodeEnum) {
                            if (NewsletterResponseCodeEnum.hasOwnProperty(key)) {
                                const code = NewsletterResponseCodeEnum[key];
                                if (data.Response.ResponseCode === code) {
                                    known = true;
                                    break;
                                }
                            }
                        }
                        if (!known) data.Response.ResponseCode = NewsletterResponseCodeEnum.UNEXPECTED_ERROR;
                    }

                    // So now we know we have a response code and its known
                    // Update the main response object to be inline with what we cater for
                    // Also lets reassign the data object in case its changed
                    newResponse.data         = data;
                    newResponse.responseCode = newResponse.data.Response.ResponseCode === NewsletterResponseCodeEnum.SUCCESS ? ResponseCode.SUCCESS : ResponseCode.GENERAL_ERROR;
                    this.logger.debug('Mapping request back to the callee: ', newResponse);

                    if (newResponse.responseCode === ResponseCode.SUCCESS) {
                        // GA4
                        this.store.dispatch(new GTMActions.TrackGANewsletterSignupSubmit({}));
                        return newResponse;
                    }
                    else {
                        throw newResponse;
                    }
                })
            );
    }

    private emailConfirmAPI(api:FMConfigAPI, customerKey:string) {
        // https://competitions.pdws.co.za/REST/EmailConfirm

        this.logger.debug('Making email confirm API request');

        const variables = {
            confirmation_code: customerKey
        };

        return this.apollo.mutate({
            mutation : CONFIRM_EMAIL,
            variables: variables
        }).pipe(
            switchMap((response:ExternalResponse<EmailConfirmResponse>) => {
                const newResponse = Object.assign({}, response);
                newResponse.data  = response.data.emailConfirm;
                return of(newResponse);
            })
        );
        /*.pipe(
            map((response:ExternalResponse) => {

                // Lets create a default response if we don't find the correct data
                let data = response.data;
                if (data == null || typeof data !== 'object' || data.Response == null || data.Response.ResponseCode == null){
                    data = {Response:{ResponseCode:NewsletterResponseCodeEnum.UNEXPECTED_ERROR}};
                }
                // We now know there is a data object and it has a response code
                else {
                    // Lets ensure its a know response code
                    let known = false;
                    for (const key in NewsletterResponseCodeEnum){
                        if (NewsletterResponseCodeEnum.hasOwnProperty(key)) {
                            const code = NewsletterResponseCodeEnum[key];
                            if (data.Response.ResponseCode === code) {
                                known = true;
                                break;
                            }
                        }
                    }
                    if (!known) data.Response.ResponseCode = NewsletterResponseCodeEnum.UNEXPECTED_ERROR;
                }

                // So now we know we have a response code and its known
                // Update the main response object to be inline with what we cater for
                // Also lets reassign the data object in case its changed
                response.data           = data;
                response.responseCode   = response.data.Response.ResponseCode === NewsletterResponseCodeEnum.SUCCESS ? ResponseCode.SUCCESS : ResponseCode.GENERAL_ERROR;
                this.logger.debug('Mapping request back to the callee: ', response);

                if (response.responseCode === ResponseCode.SUCCESS){
                    return response;
                }
                else{
                    throw response;
                }
            })
        )*/
    }

}
