import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import produce from 'immer';


export namespace UserPreferences {
    export class StoreFinderUseLocation {
        static readonly type = '[User Preferences] Store finder: Use location';

        constructor(public useLocation:boolean) {
        }
    }

    export class StockOnHandUseLocation {
        static readonly type = '[User Preferences] Stock on hand: Use location';

        constructor(public useLocation:boolean) {
        }
    }

    export class CheckoutUseLocation {
        static readonly type = '[User Preferences] Checkout: Use location';

        constructor(public useLocation:boolean) {
        }
    }

    export class ProductAttributeScrolled {
        static readonly type = '[User Preferences] Product attribute scrolled';

        constructor(public scrolled:boolean) {
        }
    }
}

export interface UserPreferencesStateModel {
    storeFinderUseLocation?:boolean;
    stockOnHandUseLocation?:boolean;
    checkoutUseLocation?:boolean;
    productAttributeScrolled?:boolean;
}

@State<UserPreferencesStateModel>({
    name: 'userPreferences'
})
@Injectable()
export class UserPreferencesState {

    @Action(UserPreferences.StoreFinderUseLocation)
    storeFinderUseLocation(ctx:StateContext<UserPreferencesStateModel>, action:UserPreferences.StoreFinderUseLocation) {
        return ctx.setState(produce(draft => {
            draft.storeFinderUseLocation = action.useLocation
        }));
    }

    @Action(UserPreferences.StockOnHandUseLocation)
    stockOnHandUseLocation(ctx:StateContext<UserPreferencesStateModel>, action:UserPreferences.StockOnHandUseLocation) {
        return ctx.setState(produce(draft => {
            draft.stockOnHandUseLocation = action.useLocation
        }));
    }

    @Action(UserPreferences.CheckoutUseLocation)
    checkoutUseLocation(ctx:StateContext<UserPreferencesStateModel>, action:UserPreferences.CheckoutUseLocation) {
        return ctx.setState(produce(draft => {
            draft.checkoutUseLocation = action.useLocation
        }));
    }

    @Action(UserPreferences.ProductAttributeScrolled)
    productAttributeScrolled(ctx:StateContext<UserPreferencesStateModel>, action:UserPreferences.ProductAttributeScrolled) {
        return ctx.setState(produce(draft => {
            draft.productAttributeScrolled = action.scrolled
        }));
    }


}
