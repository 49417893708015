import {gql} from 'apollo-angular';

export const GET_PRODUCTS_FOR_RELATED_AND_CAROUSEL_CACHE = true;

export const GET_PRODUCTS_FOR_RELATED_AND_CAROUSEL = (hasPimSupport:boolean) => {
    return gql`
        query products_relatedAndCarousel($filter: ProductAttributeFilterInput, $sort:ProductAttributeSortInput, $pageSize:Int) {
            products(filter:$filter sort:$sort pageSize:$pageSize) {
                items {
                    id
                    sku
                    name
                    url_key
                    product_attribute
                    product_decal
                    ${
                    hasPimSupport ?
                    `media_gallery {
                        file_name
                        label
                        position
                        disabled
                    }` :
                    `image_name
                    image_name_label
                    product_image_names`
                    }
                    price_range {
                        minimum_price {
                            ...AllPriceFields
                        }
                    }
                    url_key
                    categories {
                        id
                    }
                }
            }
        }

        fragment AllPriceFields on ProductPrice {
            final_price {
                ...MoneyFields
            }
            regular_price {
                ...MoneyFields
            }
        }

        fragment MoneyFields on Money {
            value
        }
    `;
};
