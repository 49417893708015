import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import produce from 'immer';

export namespace History {
    export class AddHistory {
        static readonly type = '[History] Add history entry';

        constructor(public history:HistoryStateObject) {
        }
    }

    export class SetBack {
        static readonly type = '[History] Back';
    }

}

export interface HistoryStateObject {
    path:string;
    domPosition:{
        x:number,
        y:number
    };
}

export interface HistoryStateModel {
    history:HistoryStateObject[];
    current?:HistoryStateObject;
}

@State<HistoryStateModel>({
    name    : 'history',
    defaults: {
        history: [],
        current: null
    }
})
@Injectable()
export class HistoryState {

    @Action(History.AddHistory)
    addHistory(ctx:StateContext<HistoryStateModel>, action:History.AddHistory) {
        ctx.setState(produce(draft => {
            draft.history = draft.history.concat(action.history).slice(Math.max(draft.history.length - 10, 0));
            draft.current = null;
        }));
    }

    @Action(History.SetBack)
    setBack(ctx:StateContext<HistoryStateModel>) {
        const history = ctx.getState().history;
        const current = history && history.length > 0 ? history[history.length - 1] : null;
        const remaining = history && history.length > 0 ? history.slice(0, history.length - 1) : [];
        ctx.setState(produce(draft => {
            draft.current = current;
            draft.history = remaining;
        }));
    }

}
