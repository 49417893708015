import {NgModule} from '@angular/core';
import {ImplementationDataService} from '../../../../framework/src/cross-platform/implementation-config/implementation-data.service';
import {PepImplementationDataService} from './implementation-config/pep-implementation-data.service';
import {PepFilterModelService} from './product-list/filters/pep-filter-model.service';
import {FilterModelService} from '../../../../framework/src/cross-platform/product-list-page/filters/filter-model.service';
import {ACCORDION_CONFIG_TOKEN} from '../../../../framework/src/cross-platform/accordion/facade-accordion.component';
import {PEPBlockWhenNotAuthenticatedGuard} from './auth-guards/block-when-not-authenticated.guard';
import {BlockWhenNotAuthenticatedGuard} from '../../../../framework/src/cross-platform/auth/auth-guards/block-when-not-authenticated.guard';
import {BlockedWhenAuthenticatedGuard} from '../../../../framework/src/cross-platform/auth/auth-guards/blocked-when-authenticated.guard';
import {PEPBlockedWhenAuthenticatedGuard} from './auth-guards/blocked-when-authenticated.guard';

@NgModule({})
export class CrossPlatformImplementationAppModule {
    static forRoot() {
        return {
            ngModule : CrossPlatformImplementationAppModule,
            providers: [
                {provide: BlockWhenNotAuthenticatedGuard, useClass:PEPBlockWhenNotAuthenticatedGuard},
                {provide: BlockedWhenAuthenticatedGuard, useClass:PEPBlockedWhenAuthenticatedGuard},
                {provide: ImplementationDataService, useClass: PepImplementationDataService},
                {provide: FilterModelService, useClass: PepFilterModelService},
                {provide: ACCORDION_CONFIG_TOKEN, useValue: {scrollingOffset: 0}},
            ]
        };
    }
}
