// NOTE: In future this will be only stored in the magento DB but here now for convenience


export class PepCustomPageSeoConstants {
    static SocialInvestmentTitle                  = `Find Out More | Social Investment | PEP`;
    static SocialInvestmentDescription            = `Find out more about PEP's corporate social investment initiatives. PEP, South Africa's largest single brand retailer.`;
    static SocialInvestmentApplicationTitle       = `Find Out More | Social Investment Application | PEP`;
    static SocialInvestmentApplicationDescription = `Apply for the corporate social investment programme. PEP, South Africa's largest single brand retailer.`;
    static NewsTitle                              = `News Releases | Latest News | Find Out More | PEPtalk | PEP`;
    static NewsDescription                        = `Find out what is new at Pep, South Africa's largest single brand retailer.  PEP provides a lifeline to local people with affordable, good quality merchandise.`;
    static StatementsTitle                        = `Holding Statements | Find Out More | PEP`;
}
