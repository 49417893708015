import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import produce from 'immer';
import {BillingCartAddress, ShippingCartAddress} from '../../../../../generated/graphql';

export namespace Delivery {
    export class UpdateHomeDeliveryShippingCartAddressGoogleAddressValid {
        static readonly type = '[Delivery] Update Home Delivery Shipping Cart Address Google Address Valid';

        constructor(public homeDeliveryShippingCartAddressIsGoogleValid:boolean) {
        }
    }

    export class UpdateHomeDeliveryShippingAddressEligibility {
        static readonly type = '[Delivery] Update Home Delivery Shipping Address Eligibility';

        constructor(public homeDeliveryShippingCartAddressEligibility:IHomeDeliveryShippingCartAddressEligibility) {
        }
    }

    export class UpdateAllShippingCartAddresses {
        static readonly type = '[Delivery] Update All Shipping Cart Address';

        constructor(public homeDeliveryShippingCartAddress:ShippingCartAddress,
                    public clickAndCollectShippingCartAddress:ShippingCartAddress,
                    public homeDeliveryBillingCartAddress:BillingCartAddress,
                    public clickAndCollectBillingCartAddress:BillingCartAddress) {
        }
    }

    export class UpdateHomeDeliveryShippingCartAddress {
        static readonly type = '[Delivery] Update Home Delivery Shipping Cart Address';

        constructor(public homeDeliveryShippingCartAddress:ShippingCartAddress) {
        }
    }

    export class UpdateClickAndCollectShippingCartAddress {
        static readonly type = '[Delivery] Update Click and Collect Shipping Cart Address';

        constructor(public clickAndCollectShippingCartAddress:ShippingCartAddress) {
        }
    }

    export class UpdateHomeDeliveryBillingCartAddress {
        static readonly type = '[Delivery] Update Home Delivery Billing Cart Address';

        constructor(public homeDeliveryBillingCartAddress:BillingCartAddress) {
        }
    }

    export class UpdateClickAndCollectBillingCartAddress {
        static readonly type = '[Delivery] Update Click and Collect Billing Cart Address';

        constructor(public clickAndCollectBillingCartAddress:BillingCartAddress) {
        }
    }
}

export interface IHomeDeliveryShippingCartAddressEligibility {
    eligible:boolean;
    reason?:number;
}

export interface DeliveryStateModel {
    homeDeliveryShippingCartAddressIsGoogleValid?:boolean;
    homeDeliveryShippingCartAddressEligibility?:IHomeDeliveryShippingCartAddressEligibility;
    homeDeliveryShippingCartAddress?:ShippingCartAddress;
    homeDeliveryBillingCartAddress?:BillingCartAddress;

    clickAndCollectShippingCartAddress?:ShippingCartAddress;
    clickAndCollectBillingCartAddress?:BillingCartAddress;
}

@State<DeliveryStateModel>({
    name: 'delivery',
})
@Injectable()
export class DeliveryState {

    @Action(Delivery.UpdateHomeDeliveryShippingAddressEligibility)
    updateHomeDeliveryShippingAddressEligibility(ctx:StateContext<DeliveryStateModel>, action:Delivery.UpdateHomeDeliveryShippingAddressEligibility) {
        ctx.setState(produce(draft => {
            if (!draft.homeDeliveryShippingCartAddressEligibility) draft.homeDeliveryShippingCartAddressEligibility = {eligible: false, reason: null};
            draft.homeDeliveryShippingCartAddressEligibility.eligible = action.homeDeliveryShippingCartAddressEligibility.eligible;
            draft.homeDeliveryShippingCartAddressEligibility.reason   = action.homeDeliveryShippingCartAddressEligibility.reason;
        }));
    }

    @Action(Delivery.UpdateHomeDeliveryShippingCartAddressGoogleAddressValid)
    updateHomeDeliveryShippingCartAddressGoogleValid(ctx:StateContext<DeliveryStateModel>, action:Delivery.UpdateHomeDeliveryShippingCartAddressGoogleAddressValid) {
        ctx.setState(produce(draft => {
            draft.homeDeliveryShippingCartAddressIsGoogleValid = action.homeDeliveryShippingCartAddressIsGoogleValid;
        }));
    }

    @Action(Delivery.UpdateHomeDeliveryShippingCartAddress)
    updateHomeDeliveryShippingCartAddress(ctx:StateContext<DeliveryStateModel>, action:Delivery.UpdateHomeDeliveryShippingCartAddress) {
        ctx.setState(produce(draft => {
            draft.homeDeliveryShippingCartAddress = action.homeDeliveryShippingCartAddress;
        }));
    }

    @Action(Delivery.UpdateClickAndCollectShippingCartAddress)
    updateClickAndCollectShippingCartAddress(ctx:StateContext<DeliveryStateModel>, action:Delivery.UpdateClickAndCollectShippingCartAddress) {
        ctx.setState(produce(draft => {
            draft.clickAndCollectShippingCartAddress = action.clickAndCollectShippingCartAddress;
        }));
    }

    @Action(Delivery.UpdateHomeDeliveryBillingCartAddress)
    updateBillingCartAddress(ctx:StateContext<DeliveryStateModel>, action:Delivery.UpdateHomeDeliveryBillingCartAddress) {
        ctx.setState(produce(draft => {
            draft.homeDeliveryBillingCartAddress = action.homeDeliveryBillingCartAddress;
        }));
    }

    @Action(Delivery.UpdateClickAndCollectBillingCartAddress)
    updateClickAndCollectBillingCartAddress(ctx:StateContext<DeliveryStateModel>, action:Delivery.UpdateClickAndCollectBillingCartAddress) {
        ctx.setState(produce(draft => {
            draft.clickAndCollectBillingCartAddress = action.clickAndCollectBillingCartAddress;
        }));
    }

    @Action(Delivery.UpdateAllShippingCartAddresses)
    updateAllShippingCartAddresses(ctx:StateContext<DeliveryStateModel>, action:Delivery.UpdateAllShippingCartAddresses) {
        ctx.setState(produce(draft => {
            draft.homeDeliveryShippingCartAddress    = action.homeDeliveryShippingCartAddress;
            draft.clickAndCollectShippingCartAddress = action.clickAndCollectShippingCartAddress;
            draft.homeDeliveryBillingCartAddress     = action.homeDeliveryBillingCartAddress;
            draft.clickAndCollectBillingCartAddress  = action.clickAndCollectBillingCartAddress;
        }));
    }
}
