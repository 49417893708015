import {gql} from 'apollo-angular';

export const VALIDATE_PHONE_NUMBER_CACHE = false;

export const VALIDATE_PHONE_NUMBER = gql`
    query validatePhoneNumber ($checkIsPossibleNumber: Boolean, $checkIsValidNumber: Boolean, $checkIsValidNumberForRegion: String, $getNumberType: Boolean, $getRegionCodeForNumber: Boolean, $phoneNumber: String, $phoneNumberRegion: String) {
        validatePhoneNumber (checkIsPossibleNumber: $checkIsPossibleNumber, checkIsValidNumber: $checkIsValidNumber, checkIsValidNumberForRegion: $checkIsValidNumberForRegion, getNumberType: $getNumberType, getRegionCodeForNumber: $getRegionCodeForNumber, phoneNumber: $phoneNumber, phoneNumberRegion: $phoneNumberRegion) {
            countryCode
            countryCodeSource
            extension
            italianLeadingZero
            nationalNumber
            numberType
            possibleNumber
            rawInput
            regionCodeForNumber
            validNumber
            validNumberForRegion
        }
    }
`;
