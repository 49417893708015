// NB: Don't change these keys as they are used in recaptcha action generation
export enum AuthType {
    STANDARD        = 'standard',
    CHECKOUT        = 'checkout',
    GUEST_CHECKOUT  = 'guest_checkout',
    RE_AUTHENTICATE = 're-authenticate'
}

export interface AuthConfig {
    type:AuthType;
    allowGuest?:boolean;
}
