export class LocationSearchAddress {

    address:string;
    suburb:string;
    province_code:string;
    postcode:string;
    city:string;
    country_code:string;
    country:string;

    private streetNumber = '';
    private streetName   = '';

    public constructor(addressComponents?:google.maps.GeocoderAddressComponent[], googleAutoCompleteResult?:string) {
        if (!addressComponents) return;
        addressComponents.forEach((x) => {
            if (x.types.indexOf('street_number') >= 0) this.streetNumber = x.long_name;
            if (x.types.indexOf('route') >= 0) this.streetName = x.long_name;

            if (x.types.indexOf('sublocality') >= 0) this.suburb = x.long_name;
            if (x.types.indexOf('administrative_area_level_1') >= 0) this.province_code = x.short_name;
            if (x.types.indexOf('postal_code') >= 0) this.postcode = x.short_name;
            if (x.types.indexOf('locality') >= 0) this.city = x.long_name;
            if (x.types.indexOf('country') >= 0) this.country_code = x.short_name;
            if (x.types.indexOf('country') >= 0) this.country = x.long_name;
        });

        // Google Maps sometimes strips the address number from the place fetch, hence we're passing in the autocomplete label as that always includes the street number
        // https://www.notion.so/pepkorit/Home-Delivery-Google-returns-missing-street-number-db333196861d4e3d9b032456ee923ed7
        if (!this.hasStreetNumber() && googleAutoCompleteResult) {
            const numberMatch          = googleAutoCompleteResult.match(/^\s*(\d+)/); // Match digits at the start of the string, ignoring leading spaces
            const originalStreetNumber = numberMatch ? numberMatch[1] : undefined;
            if (originalStreetNumber) this.streetNumber = originalStreetNumber;
        }

        this.address = [this.streetNumber, this.streetName].join(' ').trim();
    }

    hasAllValues():boolean {
        return this.address != '' &&
            this.suburb != undefined &&
            this.province_code != undefined &&
            this.postcode != undefined &&
            this.city != undefined &&
            this.country_code != undefined &&
            this.country != undefined;
    }

    hasStreetNumber() {
        return this.streetNumber !== '' && this.streetNumber != null;
    }

}
