import {Injectable} from '@angular/core';
import {LoggerImplCore, LogLevel} from './logger-impl-core';


@Injectable()
export class LoggerImpl extends LoggerImplCore {

}


@Injectable()
export class Logger extends LoggerImpl {

    public getLogger(name?:string, logLevel?:LogLevel) {
        return super.getLogger(name, logLevel) as Logger;
    }

    protected get time() {
        // Lets use the angular lib here then
        return '';
    }

    protected newInst() {
        return new Logger();
    }
}
