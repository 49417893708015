import {PagedSet} from '../util/paginator';
import {ProductModel} from '../elasticsearch/product.model';
import {Aggregation, GqlMagentoAttribute, SortFields} from '../../../../../../generated/graphql';

export class ProductsPagedSet<P = ProductModel> extends PagedSet<P> {

    baseAggregations:Aggregation[];
    filteredAggregations:Aggregation[];
    allAttributeMetadata:GqlMagentoAttribute[];
    sortFields:SortFields;

    clone() {
        const inst                = new ProductsPagedSet<P>();
        inst.items                = this.items ? Object.assign({}, this.items) : {};
        inst.totalPerPage         = this.totalPerPage;
        inst.sortFields           = this.sortFields;
        inst.totalItems           = this.totalItems;
        inst.baseAggregations     = this.baseAggregations;
        inst.filteredAggregations = this.filteredAggregations;
        inst.allAttributeMetadata = this.allAttributeMetadata;
        return inst;
    }
}
