import {ChangeDetectorRef, Injector} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {CustomerCommunicationService} from '../external-data/newsletter/customer-communication.service';
import {FacadeValidators} from '../forms/facade-validators';
import {AbstractFacadeComponent} from '../shared/ui/abstract-facade.component';
import {ViewState} from '../shared/view-state/view-state';
import {filter} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';
import {View} from '../shared/view-state/view.state';

interface ViewProps {
    submissionSent?:boolean;
    submitted?:boolean;
    duplicateEmail?:boolean;
}

export abstract class AbstractNewsletterSignUpComponent extends AbstractFacadeComponent {

    state$:Observable<ViewState>;
    viewProps$:Observable<ViewProps>;
    newsletterForm:FormGroup;
    emailControl:FormControl;
    formBuilder:FormBuilder;


    // Dependencies
    facadeValidators:FacadeValidators;
    newsletterApiService:CustomerCommunicationService;
    changeDetectorRef:ChangeDetectorRef;
    router:Router;

    abstract dismissModal();

    protected constructor(injector:Injector) {
        super(injector);
        this.formBuilder          = injector.get(FormBuilder);
        this.facadeValidators     = injector.get(FacadeValidators);
        this.newsletterApiService = injector.get(CustomerCommunicationService);
        this.changeDetectorRef    = injector.get(ChangeDetectorRef);
        this.router               = injector.get(Router);

        this.newsletterForm = this.formBuilder.group({
            emailControl: ['', null, []]
        });
        this.state$         = this.viewStateSelector.getState$(this.viewName);
        this.viewProps$     = this.viewStateSelector.getProps$(this.viewName);
    }

    created() {
        super.created();

        this.addSub = this.state$
            .subscribe(state => {
                if (state.isProcessing) {
                    this.newsletterForm.disable();
                }
                else if (state.isError) {
                    this.newsletterForm.enable();
                }
                else if (state.isReady) {
                    this.addSub = this.viewProps$.subscribe((props:ViewProps) => {
                        this.newsletterForm.enable();
                        if (props && props.submissionSent) {
                            this.store.dispatch(new View.SetProps<ViewProps>(this.viewName, {submitted: true}));
                        }
                    });
                }
            });

        this.addSub = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(event => {
                // dismiss modal
                this.dismissModal();
            });
    }

    init() {
        super.init();
        // Init the view's props

        // Execute this after the validation cycle has completed
        // https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
        Promise.resolve(null).then(() => this.store.dispatch(new View.SetProps<ViewProps>(this.viewName, {submitted: false, duplicateEmail: false})));
        this.changeDetectorRef.detectChanges();
    }

    handleSubmit() {
        // submit even if form is not complete (validation will happen on the modal form)
        this.launchModal(this.newsletterForm.value.emailControl);
    }

    handleModalClose(completed?:boolean) {
        // clear email field on modal close (currently when completed or not)
        this.newsletterForm.controls.emailControl.setValue('');
    }

    abstract launchModal(email?:string);


    get name():string {
        return 'NewsletterSignUpComponent';
    }

    get viewName() {
        return this.name;
    }

    get formIsDisabled() {
        return this.newsletterForm.status === 'DISABLED'; // Can't seem to import the constant
    }
}
