import {ChangeDetectionStrategy, Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {AbstractAppComponent} from '../../cross-platform/abstract-app.component';
import {NavigationEnd, NavigationStart} from '@angular/router';
import {ScrollSettings} from '../../cross-platform/implementation-config/implementation-data.types';
import {fromEvent} from 'rxjs';
import {IPromptComponent} from '../../cross-platform/prompts/IPromptComponent';

@Component({
    selector       : 'app-root',
    templateUrl    : './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends AbstractAppComponent {
    @ViewChild('promptHolder', {static: true}) protected promptHolder:ElementRef;
    @ViewChild('header', {static: true}) protected header:ElementRef;

    private scrollSettings:ScrollSettings;

    protected isInCheckoutSection = false;
    protected headerPinned        = true;
    protected pageLoaded          = false;

    constructor(public injector:Injector) {
        super(injector);
        this.setup();
    }

    init() {
        super.init();
        this.scrollSettings  = this.implementationDataService.getScrollSettings();
        let prevScrollOffset = 0;
        if (this.scrollSettings?.header?.fixed) {
            this.addSub = fromEvent(this.windowRef.nativeWindow, 'scroll').subscribe((event) => {
                // conditional check for collapsing
                const conditionalElement = this.scrollSettings.header.conditionalCssClass ? this.header.nativeElement.querySelector(`.${this.scrollSettings.header.conditionalCssClass}`) : null;
                const conditionIsMet     = conditionalElement ? conditionalElement && this.windowRef.nativeWindow?.getComputedStyle(conditionalElement)?.display !== 'none' : true;

                if (!conditionIsMet) {
                    return; // dont run if there is no sister sitebar (eg: checkout)
                }

                const verticalOffset = this.windowRef.pageYOffset || this.documentRef.documentElement.scrollTop || 0;
                let pinned           = (verticalOffset > this.scrollSettings.header.scrollingOffset) ? (verticalOffset > prevScrollOffset) ? false : true : true;

                if (this.scrollSettings.header.unCollapseAtBottom && (this.windowRef.nativeWindow?.innerHeight + this.windowRef.nativeWindow?.scrollY) >= this.windowRef.nativeWindow.document.body.scrollHeight) {
                    // Your code for when the bottom is reached goes here
                    pinned = true;
                }

                if (pinned !== this.headerPinned) {
                    this.headerPinned = pinned;
                    this.changeDetectorRef.detectChanges();
                }
                prevScrollOffset = verticalOffset;
            });
        }

        this.addSub = this.storeSelect(state => state?.app?.initialPageLoaded).subscribe(value => {
            if (this.pageLoaded !== value) {
                this.pageLoaded = value;
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    initConsentCookie():Promise<IPromptComponent> {
        return this.lazyComponentLoader.load(
            import('./prompts/cookie-consent/cookie-consent-prompt.component').then(c => c.CookieConsentPromptComponent),
            this.promptHolder.nativeElement
        );
    }

    async initPWA():Promise<IPromptComponent> {
        const comp                    = await this.lazyComponentLoader.load(
            import('./prompts/pwa-install/pwa-install-prompt.component').then(c => c.PwaInstallPromptComponent),
            this.promptHolder.nativeElement
        );
        // We have to capture and proxy in this event otherwise it gets missed
        comp.beforeinstallpromptEvent = this.beforeinstallpromptEvent;
        return comp;
    }

    initPushNotifications():Promise<IPromptComponent> {
        return this.lazyComponentLoader.load(
            import('./prompts/push-notification/push-notification-prompt.component').then(c => c.PushNotificationPromptComponent),
            this.promptHolder.nativeElement
        );
    }

    onPageNavEnd(event:NavigationEnd, bypassScroll:boolean = false) {
        super.onPageNavEnd(event);
        if (!bypassScroll) setTimeout(() => this.facadePlatform.scrollToTop());
        setTimeout(() => this.isInCheckoutSection = (this.urlService.isInCheckoutSection(event.url) /*&& !this.urlService.isUrlCheckoutComplete(event.url)*/));
    }

    onPageNavStart(event:NavigationStart) {
        super.onPageNavStart(event);
        // Sometimes the url changes twice during 1 phase so lets move it to the next frame
        setTimeout(() => {
            const isInCheckout = (this.urlService.isInCheckoutSection(event.url) /*&& !this.urlService.isUrlCheckoutComplete(event.url)*/);
            // Only if it changes run change detection
            if (this.isInCheckoutSection !== isInCheckout) {
                this.isInCheckoutSection = isInCheckout;
                this.changeDetectorRef.detectChanges();
            }
        });
    }
}
