import {ComponentFactory, Directive, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[componentContainer]',
})
export class ComponentContainerDirective {
    constructor(public viewContainerRef:ViewContainerRef) {
    }

    public placeComponent<T>(factory:ComponentFactory<T>) {
        this.viewContainerRef.clear();
        return this.viewContainerRef.createComponent(factory);
    }

    public clearComponent() {
        this.viewContainerRef.clear();
    }
}
