import {Injectable} from '@angular/core';
import {WindowRef} from '../../../../../cross-platform/shared/ui/window-ref';
import {
    ImplementationDataService
} from '../../../../../cross-platform/implementation-config/implementation-data.service';
import {
    BannerScrollerComponentACF,
    BannerScrollerWithTextComponentACF,
    WordpressConstants,
    WordpressImage
} from '../../../../../cross-platform/external-data/wordpress/model/wordpress.types';
import {FacadeSwiperItem} from '../../../../../cross-platform/shared/ui/facade-swiper/facade-swiper.types';
import {DomSanitizer} from '@angular/platform-browser';
import {HTMLUtil} from '../../../../../cross-platform/shared/util/html-util';
import {UrlService} from '../../../../../cross-platform/shared/url.service';

@Injectable()
export class FacadeSwiperLazyUtils {

    constructor(public windowRef:WindowRef,
                public implementationDataService:ImplementationDataService,
                public domSantizer:DomSanitizer,
                private urlService:UrlService) {
    }

    mapBannerScrollerComponentData(comp:BannerScrollerComponentACF, isDesktop:boolean):FacadeSwiperItem[] {
        return comp.bannerScrollerData
            .map(item => {
                let path = '';
                let loop = false;
                let imageSize:WordpressImage;
                switch (item.mediaType) {
                    case WordpressConstants.MEDIA_YOU_TUBE:
                        path = item.youTubeVideoId;
                        break;
                    case WordpressConstants.MEDIA_VIDEO:
                        path = this.determineVideoTransformations(isDesktop, item.desktopVideo, item.mobileVideo);
                        loop = true;
                        break;
                    default:
                        imageSize = this.determineImageSizeToUse(isDesktop, item.imageXL, null, null, item.imageSM);
                        path      = imageSize?.url;
                        break;
                }
                const result:FacadeSwiperItem = {
                    path      : path,
                    width     : imageSize?.width,
                    height    : imageSize?.height,
                    link      : item.link,
                    alt       : item.altTag,
                    isYouTube : item.mediaType === WordpressConstants.MEDIA_YOU_TUBE,
                    isImageMap: item.mediaType === WordpressConstants.MEDIA_IMAGE_MAP && (item.imageMap && item.imageMap.length > 0),
                    isVideo   : item.mediaType === WordpressConstants.MEDIA_VIDEO,
                    loop      : loop
                };
                return result;
            })
            .filter(item => (item.path != null && (<any>item.path) !== false));
    }

    mapBannerScrollerWithTextComponentData(comp:BannerScrollerWithTextComponentACF, isDesktop:boolean):FacadeSwiperItem[] {
        return comp.bannerScrollerData
            .map(item => {
                const imageSize = this.determineImageSizeToUse(isDesktop, item.imageXL, null, null, item.imageSM);

                const result:FacadeSwiperItem = {
                    path      : imageSize.url,
                    width     : imageSize.width,
                    height    : imageSize.height,
                    isYouTube : false,
                    isVideo   : false,
                    htmlText  : this.domSantizer.bypassSecurityTrustHtml(HTMLUtil.parseCmsHtml(item.bannerText, null, [])),
                    cssClasses: item.cssClasses,
                    isImageMap: item.imageMap && item.imageMap.length > 0,
                    loop      : false
                };
                return result;
            })
            .filter(item => (item.path != null && (<any>item.path) !== false));
    }

    private determineImageSizeToUse(isDesktop:boolean, pathXL:WordpressImage, pathLG:WordpressImage, pathMD:WordpressImage, pathSM:WordpressImage):WordpressImage {
        if (isDesktop) {
            return pathXL;
        }
        else {
            const screenSize = this.windowRef.innerWidth;
            // Code assumes either MD or SM populated
            if (screenSize <= this.implementationDataService.getGridBreakPoints().md) {
                return pathSM != null ? pathSM : pathMD;
            }
            else {
                return pathMD != null ? pathMD : pathSM;
            }
        }
    }

    private determineVideoTransformations(isDesktop:boolean, desktopVideo:string, mobileVideo:string):string {
        if (isDesktop) {
            return desktopVideo + `?${this.urlService.getVideoTransformations()}`;
        }
        else {
            return mobileVideo + `?${this.urlService.getVideoTransformations()}`;
        }
    }
}
