// Typescript only implementation, no angular tie

export enum LogLevel {
    TEST  = 0,
    DEBUG = 1,
    INFO  = 2,
    WARN  = 3,
    ERROR = 4,
    NONE  = 5
}

// Define the console.x method to use
const LogLevelConsoleMethods           = {};
LogLevelConsoleMethods[LogLevel.TEST]  = 'log';
LogLevelConsoleMethods[LogLevel.DEBUG] = 'log';
LogLevelConsoleMethods[LogLevel.INFO]  = 'log';
LogLevelConsoleMethods[LogLevel.WARN]  = 'warn';
LogLevelConsoleMethods[LogLevel.ERROR] = 'error';

// Define the names to show in the console
const LogLevelNames           = {};
LogLevelNames[LogLevel.TEST]  = 'test';
LogLevelNames[LogLevel.DEBUG] = 'debug';
LogLevelNames[LogLevel.INFO]  = 'info';
LogLevelNames[LogLevel.WARN]  = 'warn';
LogLevelNames[LogLevel.ERROR] = 'error';

// Defaults that will apply to all loggers created
export const LoggerGlobalDefaults = {
    // This is the basic format to use
    prefixFormat: '[$LOG_LEVEL$ $TIME$] $NAME$ -',

    // Default to an empty name
    name: '',

    // Default to show everything
    logLevelOverride: null
};

export class LoggerImplCore {
    public prefixFormat:string;
    public name:string;
    public logLevel:LogLevel;

    constructor() {
    }

    test(...messages:any[]):void {
        this.outputLog(LogLevel.TEST, messages);
    }

    debug(...messages:any[]):void {
        this.outputLog(LogLevel.DEBUG, messages);
    }

    info(...messages:any[]):void {
        this.outputLog(LogLevel.INFO, messages);
    }

    warn(...messages:any[]):void {
        this.outputLog(LogLevel.WARN, messages);
    }

    error(...messages:any[]):void {
        this.outputLog(LogLevel.ERROR, messages);
    }

    getLogger(name?:string, logLevel?:LogLevel) {
        // Create a new logger and inherit from global defaults
        const logger = this.newInst();
        logger.name  = name || LoggerGlobalDefaults.name;
        if (logLevel != null) {
            logger.logLevel = logLevel;
        }
        return logger;
    }

    protected newInst() {
        return new LoggerImplCore();
    }

    public outputLog(logLevel:LogLevel, messages:any[]):void {
        // Disable certain logs if needed
        //console.log('outputLog: ', this.name, logLevel, this._logLevel)
        if (logLevel >= this._logLevel) {
            if (messages == null) messages = [];
            // Add the defined prefix for this logger
            const method = LogLevelConsoleMethods[logLevel];
            const name   = LogLevelNames[logLevel];
            messages.unshift(this.getPrefix(name));
            console[method].apply(console, messages);
        }
        /*else {
            console.log('Skipped: ', LogLevelNames[logLevel], logLevel, messages);
        }*/
    }

    protected getPrefix(logLevelName:string):string {
        let prefix = this._prefixFormat;
        prefix     = prefix.replace(/\$LOG_LEVEL\$/g, logLevelName.toUpperCase());
        prefix     = prefix.replace(/\$NAME\$/g, this._name);
        prefix     = prefix.replace(/\$TIME\$/g, this.time);
        return prefix;
    }

    protected get time():string {
        // Basic timestamp implementation
        const p = this.pad1;
        const d = new Date();
        return p(d.getHours()) + ':' + p(d.getMinutes()) + ':' + p(d.getSeconds());
    }

    private pad1(value:any, character:string = '0') {
        value = value ? value + '' : '';
        if (value.length === 2) {
            return character + value;
        }
        else if (value.length === 1) {
            return character + value;
        }
        else if (value.length === 0) {
            return character + character;
        }
    }

    private get _prefixFormat():string {
        return this.prefixFormat ? this.prefixFormat : LoggerGlobalDefaults.prefixFormat;
    }

    private get _name():string {
        return this.name != null ? this.name : LoggerGlobalDefaults.name;
    }

    private get _logLevel():LogLevel {
        if (LoggerGlobalDefaults.logLevelOverride != null) {
            return LoggerGlobalDefaults.logLevelOverride;
        }
        else if (this.logLevel == null) {
            return LogLevel.DEBUG;
        }
        else {
            return this.logLevel;
        }
    }
}
