import {Injectable, Injector} from '@angular/core';
import {AbstractFacadeService} from '../../external-data/abstract-facade.service';
import {ExternalResponse} from '../../external-data/external-response';
import {
    AvailableShippingMethodsOutput,
    BillingAddressInput, Cart,
    CustomerUpdateInput,
    SetBillingAddressOnCartInput,
    SetBillingAddressOnCartOutput,
    SetEmailOnAuthenticatedCartInput,
    SetEmailOnAuthenticatedCartOutput,
    SetGuestPhoneNumberAndEmailOnCart,
    SetGuestPhoneNumberAndEmailOnCartInput,
    SetShippingAddressesOnCartInput,
    SetShippingMethodsOnCartInput,
    SetShippingMethodsOnCartOutput,
    ShippingAddressInput,
    ShippingCartAddress
} from '../../../../../generated/graphql';
import {from, Observable, of} from 'rxjs';
import {SET_SHIPPING_ADDRESSES_ON_CART} from './mutations/set-shipping-addresses-on-cart';
import {SharedCartService} from '../../cart-shared/shared-cart.service';
import {SET_BILLING_ADDRESSES_ON_CART} from './mutations/set-billing-addresses-on-cart';
import {FETCH_STORE_BY_BRANCH_CODE, FETCH_STORE_BY_BRANCH_CODE_CACHE} from './queries/fetch-store-by-branch-code';
import {FETCH_CUSTOMER_LOCATION_CODE, FETCH_CUSTOMER_LOCATION_CODE_CACHE} from './queries/fetch-customer-location-code';
import {UPDATE_CUSTOMER_WITH_LOCATION_CODE} from './mutations/update-customer-with-location-code';
import {SET_SHIPPING_METHODS_ON_CART} from './mutations/set-shipping-methods-on-cart';
import {
    MagentoCheckoutEnum,
    MagentoShippingCarrierCodes,
    MagentoShippingMethods
} from '../../external-data/enum/magento-constants';
import {SET_GUEST_PHONE_NUMBER_AND_EMAIL_ON_CART} from './mutations/set-guest-phone-number';
import {GUEST_CART_OTP_CREATE_SESSION} from './mutations/guest-cart-otp-create-session';
import {GUEST_CART_OTP_RESEND} from './mutations/guest-cart-otp-resend';
import {GUEST_CART_OTP_VALIDATE} from './mutations/guest-cart-otp-validate';
import {ResponseCode} from '../../external-data/enum/response-code.enum';
import {switchMap, tap} from 'rxjs/operators';
import {EnhancedAckStore} from './enhanced-ack-store';
import {SET_EMAIL_ON_AUTHENTICATED_CART} from './mutations/set-email-on-authenticated-cart';
import {
    AckStoresByLocation,
    SharedPhysicalStoreService
} from '../../external-data/physical-store/shared-physical-store.service';
import {StorageService} from '../../shared/ui/storage/storage.service';
import {RecaptchaService} from '../../external-data/recaptcha/recaptcha-service';
import {RecaptchaAction} from '../../external-data/recaptcha/recaptcha-action';
import {DeliveryType} from '../../implementation-config/implementation-data.types';
import {
    VALIDATE_ADDRESS_WITH_2SHIP,
    VALIDATE_ADDRESS_WITH_2SHIP_CACHE
} from '../../cart-shared/gql-queries/validate-address-with-2ship';
import {Delivery, IHomeDeliveryShippingCartAddressEligibility} from './delivery.state';
import {ErrorCodes} from '../../external-data/error-codes';
import {MagentoFacadeAddress, MagentoFacadeAddressUtil} from '../../external-data/magento/magento-facade-address.util';
import {LocationSearchAddress} from '../../shared/ui/location-search/location-search-address';
import {ValidateShippingCartAddressWithGoogleResponse} from './delivery-logic.service';
import {GoogleMapsApiService} from '../../external-data/google/google-maps/google-maps-api.service';
import {CountryService, SouthAfricanProvincesHash} from '../../shared/country.service';
import {GoogleMapsScriptLoaderService} from '../../external-data/google/google-maps/google-maps-script-loader.service';
import {ImplementationDataService} from '../../implementation-config/implementation-data.service';
import GeocoderResult = google.maps.GeocoderResult;
import {FetchCartResponse} from '../cart/cart.service';


export interface FetchStoreByBranchCodeResponse {
    storeByBranchCode:EnhancedAckStore;
}

export interface SetShippingAddressesOnCartResponse {
    setShippingAddressesOnCart:SetBillingAddressOnCartOutput;
}

export interface SetBillingAddressesOnCartResponse {
    setBillingAddressOnCart:SetBillingAddressOnCartOutput;
}

interface SetGuestPhoneNumberOnCartResponse {
    setGuestPhoneNumberOnCart:SetGuestPhoneNumberAndEmailOnCart;
}

export interface SetEmailOnAuthenticatedCartResponse {
    setEmailOnAuthenticatedCart:SetEmailOnAuthenticatedCartOutput;
}

interface SavePickupLocationCodeResponse {
    updateCustomerV2:{
        customer:{
            pickup_location_code:string;
        }
    };
}

interface FetchPickupLocationCodeResponse {
    customer:{
        pickup_location_code:string;
    };
}

export interface CreateOtpSessionResponse {
    createSession:CreateOtpSessionOutput;
}

interface CreateOtpSessionOutput {
    sessionId:string;
}

export interface ValidateOtpResponse {
    validateOtp:ValidateOtpOutput;
}

interface ValidateOtpOutput {
    message:string;
    status:string;
}

interface ResendOtpResponse {
    resendOtp:ResendOtpOutput;
}

interface ResendOtpOutput {
    message:string;
    status:string;
}

interface ValidateAddressWith2ShipResponse {
    availableShippingMethods:AvailableShippingMethodsOutput;
}

export interface FetchHomeDeliveryEligibilityAndSetShippingMethodResponse {
    cart:Cart;
}

export interface SetShippingMethodOnCartResponse {
    setShippingMethodsOnCart:SetShippingMethodsOnCartOutput;
}

// Need to test the following scenarios:
// Already verified and we try to resend / create session / verify
export enum OtpStatus {
    // Success
    // -----------------------------------
    VERIFIED                             = 'VERIFIED',  // 200: Status when session is validated
    RESEND_REQUEST_SUCCESS               = 'RESEND_REQUEST_SUCCESS', // 200: Returned when user successfully requests a resend


    // SMS Gateway
    // -----------------------------------
    // Session has been created, but user needs to try and resend the SMS
    FAILED_TO_SEND                       = 'FAILED_TO_SEND', // 500: SMS gateway failed


    // Session errors
    // -----------------------------------
    // Store the sessionId and allow the user to continue
    FAIL_CREATE_ACTIVE_SESSION           = 'FAIL_CREATE_ACTIVE_SESSION',  // 403: User already has an active session. The session is returned in the response
    // Tell the user they need to try again in a few mins
    FAIL_CREATE_TOO_MANY_REQUESTS        = 'FAIL_CREATE_TOO_MANY_REQUESTS', // 429: User has tried to create too many new sessions


    // Resend errors
    // -----------------------------------
    // Tell the user they need to try again in a few mins
    RESEND_FAIL_TOO_MANY_REQUESTS        = 'RESEND_FAIL_TOO_MANY_REQUESTS', // 429: User tried to perform too many resends
    // We don't know what happened, let the user retry
    RESEND_FAIL                          = 'RESEND_FAIL', // 500: Unknown server error when resend fails
    // Create a new session
    NOT_IN_STATE_TO_RESEND               = 'NOT_IN_STATE_TO_RESEND', // 200: Session is closed


    // Verification errors
    // -----------------------------------
    // Tell the user the pin they entered was wrong
    VERIFICATION_FAIL                    = 'VERIFICATION_FAIL', // 200: PIN did not match
    // Tell the user they need to try again in a few mins
    FAIL_SESSION_CLOSED_TOO_MANY_RETRIES = 'FAIL_SESSION_CLOSED_TOO_MANY_RETRIES', // 200: User tried to validate too many times
    // Create a new session and inform the user to use the new pin
    NOT_IN_STATE_TO_BE_VERIFIED          = 'NOT_IN_STATE_TO_BE_VERIFIED', // 200: Session has been closed
    // Create a new session and retry
    FAIL_SESSION_CLOSED_TIME_OUT         = 'FAIL_SESSION_CLOSED_TIME_OUT',  // 200: Session timed out. User tried to validate on session after validity period


    // General exception
    // -----------------------------------
    // We don't know what happened, let the user retry
    EXCEPTION                            = 'EXCEPTION', // 400 or 500: General exception
}

@Injectable()
export class DeliveryService extends AbstractFacadeService {

    private countryCodeHash:{ [key:string]:string };
    private provincesHash:SouthAfricanProvincesHash;


    constructor(injector:Injector,
                private sharedCartService:SharedCartService,
                private storageService:StorageService,
                private recaptchaService:RecaptchaService,
                private googleMapsApiService:GoogleMapsApiService,
                private countryService:CountryService,
                private googleMapsScriptLoaderService:GoogleMapsScriptLoaderService,
                private implementationDataService:ImplementationDataService,
                private sharedPhysicalStoreService:SharedPhysicalStoreService) {
        super(injector);
        this.provincesHash = this.countryService.getSouthAfricanProvincesHash();
    }

    fetchStoresByLocation(clickAndCollectOnly:boolean, lat:number, lon:number, from?:number, size?:number):Observable<ExternalResponse<AckStoresByLocation>> {
        return this.sharedPhysicalStoreService.fetchStoresByLocation(clickAndCollectOnly, lat, lon, from, size);
    }

    fetchStoreByBranchCode(clickAndCollectOnly:boolean, branchCode:string):Observable<ExternalResponse<EnhancedAckStore>> {
        return this.apollo.query<FetchStoreByBranchCodeResponse>({
            query    : FETCH_STORE_BY_BRANCH_CODE,
            variables: {
                branchCode: branchCode,
            },
        }, FETCH_STORE_BY_BRANCH_CODE_CACHE).pipe(
            switchMap(response => {
                // @ts-ignore
                const newResponse:ExternalResponse<EnhancedAckStore> = Object.assign({}, response);
                if (response?.data?.storeByBranchCode?.clickAndCollectEnabled != clickAndCollectOnly) {
                    newResponse.data = null;
                }
                else {
                    // It's possible that the user's saved store is no longer available,
                    // hence this can come back as null and we need to handle
                    newResponse.data = (response?.data?.storeByBranchCode) ? new EnhancedAckStore(response.data.storeByBranchCode) : null;
                }
                return of(newResponse);
            })
        );
    }

    setShippingAddressOnCart(shippingAddress:ShippingAddressInput):Observable<ExternalResponse<SetShippingAddressesOnCartResponse>> {
        const input:SetShippingAddressesOnCartInput = {
            cart_id           : this.sharedCartService.getCartId(),
            shipping_addresses: [shippingAddress]
        };
        return this.apollo.mutate<SetShippingAddressesOnCartResponse>(
            {
                mutation : SET_SHIPPING_ADDRESSES_ON_CART,
                variables: {input: input}
            },
            true
        ).pipe(
            tap((response:ExternalResponse<SetShippingAddressesOnCartResponse>) => {
                this.store.dispatch(new Delivery.UpdateHomeDeliveryShippingCartAddress(response.data.setShippingAddressesOnCart.cart.shipping_addresses[0]));
            })
        );
    }

    setBillingAddressOnCart(billingAddress:BillingAddressInput, deliveryType:DeliveryType):Observable<ExternalResponse<SetBillingAddressesOnCartResponse>> {
        const input:SetBillingAddressOnCartInput = {
            cart_id        : this.sharedCartService.getCartId(),
            billing_address: billingAddress
        };
        return this.apollo.mutate<SetBillingAddressesOnCartResponse>(
            {
                mutation : SET_BILLING_ADDRESSES_ON_CART,
                variables: {input: input}
            },
            true
        ).pipe(
            tap((response:ExternalResponse<SetBillingAddressesOnCartResponse>) => {
                if (deliveryType === DeliveryType.COLLECT) {
                    this.store.dispatch(new Delivery.UpdateClickAndCollectBillingCartAddress(response.data.setBillingAddressOnCart.cart.billing_address));
                }
                else if (deliveryType === DeliveryType.DELIVERY) {
                    this.store.dispatch(new Delivery.UpdateHomeDeliveryBillingCartAddress(response.data.setBillingAddressOnCart.cart.billing_address));
                }
            })
        );
    }

    savePickupLocationCodeOnCustomer(pickupLocationCode:string):Observable<ExternalResponse<SavePickupLocationCodeResponse>> {
        const input:CustomerUpdateInput = {
            pickup_location_code: pickupLocationCode
        };
        return this.apollo.mutate<SavePickupLocationCodeResponse>({mutation: UPDATE_CUSTOMER_WITH_LOCATION_CODE, variables: {input: input}}, true);
    }

    fetchPickupLocationCodeFromCustomer():Observable<ExternalResponse<FetchPickupLocationCodeResponse>> {
        return this.apollo.query<FetchPickupLocationCodeResponse>(
            {
                query    : FETCH_CUSTOMER_LOCATION_CODE,
                variables: {},
            },
            FETCH_CUSTOMER_LOCATION_CODE_CACHE,
            true
        );
    }

    savePickupLocationCodeLocally(pickupLocationCode:string):Observable<void> {
        return of(this.storageService.setItem(MagentoCheckoutEnum.pickup_location_code, pickupLocationCode));
    }

    getPickupLocationCodeLocally():Observable<ExternalResponse<FetchPickupLocationCodeResponse>> {
        // Lets map a dummy object so the response is the same for customer and guest
        const res:ExternalResponse<FetchPickupLocationCodeResponse> = {
            responseCode: ResponseCode.SUCCESS,
            data        : {
                customer: {
                    pickup_location_code: this.storageService.getItem(MagentoCheckoutEnum.pickup_location_code)
                }
            }
        };
        return of(res);
    }

    setEmailOnAuthenticatedCart(email:string):Observable<ExternalResponse<SetEmailOnAuthenticatedCartResponse>> {
        const input:SetEmailOnAuthenticatedCartInput = {
            cart_id: this.sharedCartService.getCartId(),
            email  : email
        };
        return this.apollo.mutate<SetEmailOnAuthenticatedCartResponse>(
            {
                mutation : SET_EMAIL_ON_AUTHENTICATED_CART,
                variables: {input: input}
            },
            true
        );
    }

    setGuestPhoneNumberAndEmailOnCart(cellphoneNumber:string, email:string):Observable<ExternalResponse<SetGuestPhoneNumberOnCartResponse>> {
        const input:SetGuestPhoneNumberAndEmailOnCartInput = {
            cart_id         : this.sharedCartService.getCartId(),
            cellphone_number: cellphoneNumber,
            email           : email
        };
        return this.apollo.mutate<SetGuestPhoneNumberOnCartResponse>(
            {
                mutation : SET_GUEST_PHONE_NUMBER_AND_EMAIL_ON_CART,
                variables: {input: input}
            },
            true
        );
    }

    /*
    1. Check Eligibility with 2ship
    2. Set selected shipping method, only if not already set
    3. Return amount to display for shipping cost
     */
    fetchHomeDeliveryEligibilityAndSetShippingMethod(cartId:string):Observable<ExternalResponse<FetchHomeDeliveryEligibilityAndSetShippingMethodResponse | FetchCartResponse>> {
        let eligibility:IHomeDeliveryShippingCartAddressEligibility;
        return this.validateAddressWith2Ship(cartId)
            .pipe(
                switchMap((response:ExternalResponse<ValidateAddressWith2ShipResponse>) => {
                    // First, set state in NgXs based on response
                    const shippingMethods            = response?.data?.availableShippingMethods?.available_shipping_methods;
                    const homeDeliveryShippingMethod = shippingMethods?.find(method => method.method_code === MagentoShippingMethods.economy);
                    eligibility                      = {eligible: false};
                    if (homeDeliveryShippingMethod) {
                        eligibility.eligible = homeDeliveryShippingMethod.available;
                        eligibility.reason   = Number(homeDeliveryShippingMethod.error_message);
                    }
                    else {
                        eligibility.reason = ErrorCodes.ERR_MAGENTO_SHIPPING_API_ERROR;
                    }
                    this.store.dispatch(new Delivery.UpdateHomeDeliveryShippingAddressEligibility(eligibility));

                    // Second, if shipping method not already set, then set it
                    const selectedShippingMethod = response?.data?.availableShippingMethods?.selected_shipping_method;
                    if (selectedShippingMethod?.method_code !== MagentoShippingMethods.economy && homeDeliveryShippingMethod) {
                        return this.setShippingMethod(DeliveryType.DELIVERY).pipe(
                            switchMap((setShippingMethodResponse:ExternalResponse<SetShippingMethodOnCartResponse>) => {
                                // @ts-ignore
                                const newResponse:ExternalResponse<FetchHomeDeliveryEligibilityAndSetShippingMethodResponse> = Object.assign({}, setShippingMethodResponse);
                                newResponse.data                                                                             = {cart: setShippingMethodResponse.data.setShippingMethodsOnCart.cart};
                                return of(newResponse);
                            })
                        );
                    }
                    else {
                        return this.sharedCartService.fetchCart('checkout');
                    }
                })
            );
    }

    public validateShippingCartAddressWithGoogle(shippingCartAddress:ShippingCartAddress):Observable<ValidateShippingCartAddressWithGoogleResponse> {
        let addressInput: string;

        let stream = of(<any>null);
        if (!this.countryCodeHash) {
            stream = stream.pipe(
                switchMap(() => from(this.countryService.getCountryCodeHash())),
                tap((countryCodeHash) => this.countryCodeHash = countryCodeHash)
            );
        }

        return stream
            .pipe(
                switchMap(() => from(this.googleMapsScriptLoaderService.loadGoogleMaps())),
                switchMap(() => {
                    const address:MagentoFacadeAddress = MagentoFacadeAddressUtil.convertMagentoStreetArray(shippingCartAddress?.street);
                    addressInput                       = `${address.address} ${address.suburb} ${shippingCartAddress.city} ${shippingCartAddress.postcode} ${this.provincesHash[shippingCartAddress.region.code]} ${this.countryCodeHash[shippingCartAddress.country.code]}`;
                    return this.googleMapsApiService.getPlaceByStaticAddress(addressInput);
                }),
                switchMap((response:ExternalResponse<GeocoderResult>) => {
                    const parsedAddress = new LocationSearchAddress(response?.data?.address_components, addressInput);
                    let valid           = this.addressesAreEqual(shippingCartAddress, parsedAddress);
                    if (this.implementationDataService.getDeliverySettings()?.forceLeadingStreetNumber && !parsedAddress.hasStreetNumber()) {
                        valid = false;
                    }

                    return of({valid: valid, parsedAddress: parsedAddress});
                })
            );
    }

    private addressesAreEqual(usersAddress:ShippingCartAddress, googleAddress:LocationSearchAddress) {
        const address:MagentoFacadeAddress = MagentoFacadeAddressUtil.convertMagentoStreetArray(usersAddress?.street);
        return usersAddress && address.address === googleAddress.address &&
            address.suburb === googleAddress.suburb &&
            usersAddress.city === googleAddress.city &&
            usersAddress.region.code === googleAddress.province_code &&
            usersAddress.postcode === googleAddress.postcode &&
            usersAddress.country.code === googleAddress.country_code;
    }

    setShippingMethod(deliveryType:DeliveryType):Observable<ExternalResponse<SetShippingMethodOnCartResponse>> {
        let input:SetShippingMethodsOnCartInput = {
            cart_id         : this.sharedCartService.getCartId(),
            shipping_methods: null
        };
        if (deliveryType === DeliveryType.COLLECT) {
            input.shipping_methods = [{
                carrier_code: MagentoShippingCarrierCodes.twoShip,
                method_code : MagentoShippingMethods.clickAndCollect
            }];
        }
        else if (deliveryType === DeliveryType.DELIVERY) {
            input.shipping_methods = [{
                carrier_code: MagentoShippingCarrierCodes.twoShip,
                method_code : MagentoShippingMethods.economy
            }];
        }
        return this.store.selectOnce(s => s.customer.isLoggedIn).pipe(
            switchMap((isLoggedIn) => this.apollo.mutate<SetShippingMethodOnCartResponse>(
                {
                    mutation : SET_SHIPPING_METHODS_ON_CART(isLoggedIn),
                    variables: {input: input}
                },
                true
            ))
        );
    }

    /* OTP for Guest CHECKOUT */
    createOtpSession(msisdn:string):Observable<ExternalResponse<CreateOtpSessionResponse>> {
        return this.recaptchaService.execute(RecaptchaAction.GUEST_CREATE_SESSION).pipe(
            switchMap(res => {
                return this.apollo.mutate<CreateOtpSessionResponse>(
                    {
                        mutation : GUEST_CART_OTP_CREATE_SESSION,
                        variables: {
                            msisdn: msisdn
                        },
                        context  : {headers: res.data}
                    },
                    true
                );
            })
        );

    }


    resendOtp(msisdn:string, sessionId:string):Observable<ExternalResponse<ResendOtpResponse>> {
        return this.recaptchaService.execute(RecaptchaAction.GUEST_RESEND_OTP).pipe(
            switchMap(res => {
                return this.apollo.mutate<ResendOtpResponse>(
                    {
                        mutation : GUEST_CART_OTP_RESEND,
                        variables: {
                            msisdn   : msisdn,
                            sessionId: sessionId
                        },
                        context  : {
                            headers: res.data
                        }
                    },
                    true
                );
            })
        );
    }

    validateOtp(pin:string, sessionId:string):Observable<ExternalResponse<ValidateOtpResponse>> {
        this.recaptchaService.execute(RecaptchaAction.CHECKOUT_GUEST_VALIDATE_OTP);
        return this.apollo.mutate<ValidateOtpResponse>(
            {
                mutation : GUEST_CART_OTP_VALIDATE,
                variables: {
                    pin      : pin,
                    sessionId: sessionId
                }
            },
            true
        );
    }


    setOTPSession(value:string) {
        this.storageService.setItem(MagentoCheckoutEnum.otp_session, value);
    }

    getOTPSession():string {
        return this.storageService.getItem(MagentoCheckoutEnum.otp_session);
    }

    clearOTPSession() {
        this.storageService.removeItem(MagentoCheckoutEnum.otp_session);
    }


    /*setOTPIsVerified(value:string) {
        this.storageService.setItem(MagentoCheckoutEnum.otp_session, value);
    }

    getOTPSession():string {
        return this.storageService.getItem(MagentoCheckoutEnum.otp_session);
    }

    clearOTPSession() {
        this.storageService.removeItem(MagentoCheckoutEnum.otp_session);
    }*/

    private validateAddressWith2Ship(cartId:string):Observable<ExternalResponse<ValidateAddressWith2ShipResponse>> {
        const performMock = false;
        const variables   = {cartId: cartId};
        return this.apollo.query<ValidateAddressWith2ShipResponse>(
            {
                query    : VALIDATE_ADDRESS_WITH_2SHIP,
                variables: variables,
            },
            VALIDATE_ADDRESS_WITH_2SHIP_CACHE,
            true
        ).pipe(
            // Mocking for testing
            switchMap((response) => {
                if (!performMock) return of(response);

                const mockError = false;
                const eligible  = true;

                const errorCode = (mockError) ? ErrorCodes.ERR_MAGENTO_SHIPPING_API_NOT_AVAILABLE : ErrorCodes.ERR_MAGENTO_SHIPPING_ADDRESS_NOT_SUPPORTED;
                let newResponse:ExternalResponse<ValidateAddressWith2ShipResponse>;
                newResponse     = {
                    responseCode: ResponseCode.SUCCESS,
                    data        : {
                        availableShippingMethods: {
                            available_shipping_methods: [
                                // @ts-ignore
                                {
                                    available  : eligible,
                                    method_code: MagentoShippingMethods.clickAndCollect,
                                },
                                // @ts-ignore
                                {
                                    available    : eligible,
                                    method_code  : MagentoShippingMethods.economy,
                                    error_message: String(errorCode)
                                }
                            ]
                        }
                    }
                };
                return of(newResponse);
            })
        );
    }

    getName():string {
        return 'DeliveryService';
    }
}
