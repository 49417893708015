import {Injectable, Injector} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {ApolloQueryResult, MutationOptions, QueryOptions} from '@apollo/client/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {ExternalResponse} from '../external-response';
import {ResponseCode} from '../enum/response-code.enum';
import {EXTENDED_APOLLO_POST_REQUEST_HOOK, ExtendedApolloHook} from './extended-apollo-hook';
import {GraphQLError} from 'graphql/error/GraphQLError';
import {environment} from '../../implementation/environment';
import {EnvironmentEnum} from '../../../../scripts/shared/facade-config.types';
import {HttpHeaders} from '@angular/common/http';
import {EmptyObject} from 'apollo-angular/types';
import {Context} from 'apollo-angular/http/types';

export interface ExtendedApolloError {
    message:string;
    code:string;
    apolloError:GraphQLError;
}

@Injectable()
export class ExtendedApolloService {
    apollo:Apollo;
    interceptors?:ExtendedApolloHook[];

    constructor(public injector:Injector) {
    }

    inject() {
        if (!this.apollo) {
            this.apollo       = this.injector.get(Apollo);
            this.interceptors = this.injector.get(EXTENDED_APOLLO_POST_REQUEST_HOOK, []);
        }
    }

    // Insist on supplying a cache policy per call as this forces you to think about whether or not it should, or can be cached.
    // Calls requiring auth for example must never be cached.
    // Currently the cache is in memory only. So refreshing the browser will clear it.
    query<T, V = EmptyObject>(options:QueryOptions<V>, useCache:boolean, isAuthenticated = false, useVarnishCache = true):Observable<ExternalResponse<T>> {
        this.inject();

        // Cache is on by default but we don't want it for authenticated calls
        if (!useCache) options.fetchPolicy = 'no-cache';

        if (this.interceptors) this.interceptors.forEach(int => int.handleStart());

        this.decorateOptions(options, isAuthenticated, useVarnishCache);

        return this.apollo.query(options).pipe(
            // Catch first as switch may intentionally throw
            catchError((response:ApolloQueryResult<T>) => this.handleError(response)),
            switchMap((response:ApolloQueryResult<T>) => this.handleResponse<T>(response)),
        );
    }

    mutate<T, V = EmptyObject>(options:MutationOptions<T, V>, isAuthenticated:boolean = false):Observable<ExternalResponse<T>> {
        this.inject();

        if (this.interceptors) this.interceptors.forEach(int => int.handleStart());

        this.decorateOptions(options, isAuthenticated, true);

        return this.apollo.mutate(options).pipe(
            // Catch first as switch may intentionally throw
            catchError((response:ApolloQueryResult<T>) => this.handleError(response)),
            switchMap((response:ApolloQueryResult<T>) => this.handleResponse<T>(response)),
        );
    }


    /**
     * These 2 methods below should be implemented into the core but we would have to test EVERYTHING
     * Hence they are a stopgap to full implementation
     * They don't map the errors as the normal implementation does (which is also a bit overkill and needs to be removed)
     * They don't implement the interceptors correctly
     * But they do move this logic out of the calling services
     */
    queryHacked<T, V = EmptyObject>(options:QueryOptions<V>, useCache:boolean, isAuthenticated = false, useVarnishCache = true):Observable<ExternalResponse<T>> {
        options.errorPolicy = 'all';
        return this.query(options, useCache, isAuthenticated, useVarnishCache).pipe(tap(
            (res:ExternalResponse<T>) => {
                if (res?.apolloErrors?.length > 0) {
                    res.responseCode = ResponseCode.GENERAL_ERROR;
                    throw res;
                }
            }
        ));
    }

    mutateHacked<T, V = EmptyObject>(options:MutationOptions<T, V>, isAuthenticated:boolean = false):Observable<ExternalResponse<T>> {
        options.errorPolicy = 'all';
        return this.mutate(options, isAuthenticated).pipe(tap(
            (res:ExternalResponse<T>) => {
                if (res?.apolloErrors?.length > 0) {
                    res.responseCode = ResponseCode.GENERAL_ERROR;
                    throw res;
                }
            }
        ));
    }

    private decorateOptions(options:MutationOptions | QueryOptions, isAuthenticated:boolean, useVarnishCache:boolean) {
        // IMPORTANT, if you change this policy to all, then responses are turned into successful requests and
        // you have access to both the data and error properties. This is useful in very specific situations like:
        // fetch cart
        options.errorPolicy = (options?.errorPolicy != null) ? options.errorPolicy : 'none';

        // Special context type for apollo angular hence the manual types
        const context:Context = Object.assign((options?.context != null) ? options?.context : {}, {withCredentials: false});
        const headers         = Object.assign({}, options?.context?.headers || {});
        if (isAuthenticated) {
            headers['x-with-credentials'] = 'true';

            // locally we push the cookies manually with withCredentials as is a cors request
            if (environment.envName === EnvironmentEnum.local) {
                context.withCredentials = true;
            }
        }
        // This forces the middleware to skip the POST to GET conversion bypassing varnish
        if (useVarnishCache === false) {
            headers['x-skip-graph-conversion'] = 'true';
        }
        context.headers = new HttpHeaders(headers);

        options.context = context;
    }

    private handleResponse<T>(response:ApolloQueryResult<T>):Observable<ExternalResponse<T>> {
        //console.log('Apollo Response: ', response);
        const result:ExternalResponse<T> = {
            responseCode    : ResponseCode.SUCCESS,
            data            : response.data,
            networkStatus   : response.networkStatus,
            apolloErrors    : <any> response.errors,
            firstApolloError: response?.errors?.length > 0 ? response?.errors[0] : null
        };
        if (this.interceptors) this.interceptors.forEach(int => int.handle(result));

        // This is returned for any auth error
        if (result?.firstApolloError?.extensions?.exception?.status === 401) {
            result.responseCode = ResponseCode.AUTHENTICATION_ERROR;
            return throwError(result);
        }
        return of(result);
    }

    private handleError<T>(response:ApolloQueryResult<T>):Observable<ExternalResponse<T>> {
        const result = this.reformatErrorToGeneric(response);
        if (this.interceptors) this.interceptors.forEach(int => int.handleError(result));
        return throwError(result);
    }

    private reformatErrorToGeneric<T>(response:ApolloQueryResult<T>):ExternalResponse<any> {
        const errors                  = response['graphQLErrors'];
        const result:ExternalResponse = {
            responseCode    : ResponseCode.GENERAL_ERROR,
            networkStatus   : response.networkStatus,
            data            : response.data,
            apolloErrors    : errors,
            firstApolloError: errors?.length > 0 ? errors[0] : null,
            httpError       : response['networkError']
        };

        if (result.httpError) {
            if (result.httpError.status <= 0) {
                result.responseCode          = ResponseCode.CONNECTION_ERROR;
                result.developerErrorMessage = 'Connection error, status code < 0';
            }
            else if (result.httpError.status === 504) {
                result.responseCode          = ResponseCode.CONNECTION_ERROR;
                result.developerErrorMessage = '504 from server or service worker. Could be server or call is not available in SW Cache.';
            }
            else if (result.httpError.status === 408) {
                result.responseCode          = ResponseCode.SERVER_SIDE_TIMEOUT;
                result.developerErrorMessage = 'Server side timeout';
            }
            // An error on a 200 indicates a parse error
            else if (result.httpError.status === 200) {
                result.responseCode = ResponseCode.PARSING_ERROR;
            }
            else {
                result.responseCode = ResponseCode.GENERAL_ERROR;
                // And data will be in the error property
                result.error        = result.httpError.error;

                // This implementation leaves out 1 possible type.
                // A non 200 parsing error.
                // Add if needed but a mission and doesn't seem to be needed right now
            }
        }
        else if (result.apolloErrors?.length > 0) {
            // This allows us to repackage the original exception from the original rest call
            const apolloError:GraphQLError = result.apolloErrors[0];

            // If Magento encounters an error with the upstream rest services, code & message are populated
            // For the list of codes that can come back here see:
            // https://gitlab.pdws.co.za/swagger/magento/blob/master/docs/register-customer.md#errors
            if (apolloError?.extensions?.exception) {
                result.error = {code: apolloError?.extensions?.exception?.code, message: apolloError?.message, functionName: apolloError?.path?.join('/')};
                if (apolloError?.extensions?.code && !isNaN(apolloError.extensions.code)) {
                    result.error.code = apolloError.extensions.code;
                }
                if (apolloError?.extensions?.exception?.response) {
                    result.error.message = apolloError.extensions.exception.response;
                }
                if (result.error.hasOwnProperty('ResponseCode') && result.error.hasOwnProperty('ResponseDescription')) {
                    result.error.code    = result.error.ResponseCode;
                    result.error.message = result.error.ResponseDescription;
                }
            }
            // Catchall for any additional errors
            else if (apolloError.message) {
                result.error = apolloError.message;
            }
            else {
                result.error = apolloError;
            }

            if (apolloError?.extensions?.exception?.status === 401) {
                result.responseCode = ResponseCode.AUTHENTICATION_ERROR;
            }
        }

        return result;
    }

}
