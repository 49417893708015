export interface FacadeParsedUrl {
    /**
     * The full directory path such as '/home/user/dir'
     */
    dir:string;
    /**
     * The file name including extension (if any) such as 'index.html'
     */
    base:string;
    /**
     * The file extension (if any) such as '.html'
     */
    ext:string;
    /**
     * The file name without extension (if any) such as 'index'
     */
    name:string;

    // See URL
    protocol:string;
    host:string
    origin:string;
    port:string;
    href:string;
}

export class FacadeUrlUtil {

    static parse(url:string):FacadeParsedUrl {
        const parsedUrl:URL   = new URL(url);
        const filePath        = parsedUrl.pathname.split('?')[0];
        const slashSplit      = filePath.split('/');
        const fileNameWithExt = slashSplit.pop();
        const dir             = slashSplit.join('/');
        const fileNameSplit   = fileNameWithExt.split('.');
        const fileExt         = fileNameSplit.pop();
        const fileName        = fileNameSplit.join('.');
        return {
            dir     : dir,
            base    : fileNameWithExt,
            ext     : fileExt,
            name    : fileName,
            protocol: parsedUrl.protocol,
            host    : parsedUrl.host,
            origin  : parsedUrl.origin,
            port    : parsedUrl.port,
            href    : parsedUrl.href
        };
    }

}
