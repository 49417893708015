// NOTE: In future this will be only stored in the magento DB but here now for convenience
import {CustomPageSeoConstants} from './custom-page-seo.constants';
import {CustomPageEnum} from './custom-page.enum';
import {PageTypeEnum} from '../../../../framework/src/cross-platform/router/page-type.enum';

let cnt  = 0;
const id = () => cnt++ + '';

export let AckBuiltInPageMapping = {
    account: {
        onlineStoreCredit: {id: id(), urlKey: PageTypeEnum.ACCOUNT + '/' + CustomPageEnum.onlineStoreCredit, localePageType: PageTypeEnum.ACCOUNT + CustomPageEnum.onlineStoreCredit, seoLocaleConvention: 'account.onlineStoreCredit'},
        laybys           : {id: id(), urlKey: PageTypeEnum.ACCOUNT + '/' + CustomPageEnum.laybys, localePageType: PageTypeEnum.ACCOUNT + CustomPageEnum.laybys, seoLocaleConvention: 'account.laybys'},
        ackAccount       : {id: id(), urlKey: PageTypeEnum.ACCOUNT + '/' + CustomPageEnum.ackAccount, localePageType: PageTypeEnum.ACCOUNT + CustomPageEnum.ackAccount, seoLocaleConvention: 'account.ackAccount'},
        moreServices     : {id: id(), urlKey: PageTypeEnum.ACCOUNT + '/' + CustomPageEnum.moreServices, localePageType: PageTypeEnum.ACCOUNT + CustomPageEnum.moreServices, seoLocaleConvention: 'account.moreServices'}
    }
};

export class AckExternalSites {
    static magazine               = 'https://magazine.ackermans.co.za';
    static ackAccountApplication  = 'https://apply.ackermans.co.za/';
    static facesOfChange          = 'https://faces.ackermans.co.za/?utm_source=ackermans.co.za&utm_medium=Women%20Navigation&utm_campaign=Faces-of-Change';
    static styleSquad             = 'https://stylesquad.ackermans.co.za/';
    static cutestBabies           = 'http://boty.ackermans.co.za';
    static pepkorRetailSegments   = 'https://www.pepkor.co.za/retail-segments/';
    static pepkorPrivacyStatement = 'https://www.pepkor.co.za/wp-content/uploads/2021/04/Privacy-Statement.pdf';
}

export class AckCMSPageLinks {
    static competitionsLanding             = '/page/competitions';
    static termsGeneral                    = '/page/terms-and-conditions/general';
    static termsClickAndCollect            = '/page/terms-and-conditions/general#click-collect';
    static termsSocialMedia                = '/page/terms-and-conditions/general#social-media-policy';
    static termsPrivacy                    = '/page/terms-and-conditions/general#website-privacy-policy';
    static termsCookiePolicy               = '/page/terms-and-conditions/general#cookie-policy';
    static externalPrivacyStatement        = '/page/terms-and-conditions/general#external-privacy-statement';
    static termsPromotionOfAccess          = '/page/terms-and-conditions/general#paia';
    // static termsMarketingPersonalInfo      = '/page/terms-and-conditions/general#marketing-and-processing-of-personal-information-consent';
    static termsCompetitions               = '/page/terms-and-conditions/competitions';
    static financialServicesLanding        = '/page/financial-solutions';
    static financialServicesAccountCard    = '/page/financial-solutions/account-card';
    static financialServicesMoneyTransfers = '/page/financial-solutions/money-transfers';
    static financialServicesLoans          = '/page/financial-solutions/loans';
    static financialServicesInsurance      = '/page/financial-solutions/insurance';
    static financialServicesLayBy          = '/page/financial-solutions/lay-by';
    static financialServicesBillPayments   = '/page/financial-solutions/bill-payments';
    static financialServicesMoreServices   = '/page/financial-solutions/more-services';
    static corporateInformationAboutUs     = '/page/corporate-information/about-us';
    static corporateInformationCareers     = '/page/corporate-information/careers';
    static corporateInformationCsi         = '/page/corporate-information/csi';
    static soundOfAckermans                = '/page/the-sound-of-ackermans';
    static newStores                       = '/page/new-stores';
    static catalogues                      = '/page/catalogues';

    static howToOrder      = '/page/delivery-options#how-to-buy-online';
    static returnsFAQ      = '/page/terms-and-conditions/general#returns-exchange';
    static deliveryDetails = '/page/delivery-options#delivery';
    static theBabyCorner   = '/page/the-baby-corner';
    // TODO: COVID-19 to be removed
    static covid           = '/page/corona';
    static plusmore        = '/page/plusmore';
}

// Note its important that this mapping only include pages actually embedded as app custom pages and nothing else.
// This map is used to build the sitemap hence it needs to be accurate or we will get duplicate content
export let AckCustomPageMapping = {
    braGuide     : {
        index: {id: id(), urlKey: CustomPageEnum.braGuide, pageTitle: 'Bra Guide', seoTitle: CustomPageSeoConstants.BraGuideTitle, seoDescription: CustomPageSeoConstants.BraGuideDescription},
    },
    characterShop: {
        downloads   : {id: id(), urlKey: CustomPageEnum.characterShop + '/downloads', pageTitle: 'Lion King Downloads', seoTitle: CustomPageSeoConstants.LionKingDownloadsTitle, seoDescription: CustomPageSeoConstants.LionKingDownloadsDescription},
        movieTrailer: {id: id(), urlKey: CustomPageEnum.characterShop + '/movie-trailer', pageTitle: 'Lion King Movie Trailer', seoTitle: CustomPageSeoConstants.LionKingMovieTrailerTitle, seoDescription: CustomPageSeoConstants.LionKingMovieTrailerDescription},
    },
    more         : {
        // corporateAndSocial     : {id: id(), urlKey: CustomPageEnum.more + '/social-investment', pageTitle: 'Corporate Social Investment', seoTitle: CustomPageSeoConstants.SocialInvestmentTitle, seoDescription: CustomPageSeoConstants.SocialInvestmentDescription},
        // corporateAndSocialTerms: {id: id(), urlKey: CustomPageEnum.more + '/social-investment/application-terms', pageTitle: 'Corporate Social Investment Terms', seoTitle: CustomPageSeoConstants.SocialInvestmentTitle, seoDescription: CustomPageSeoConstants.SocialInvestmentDescription},
        corporateAndSocialForm    : {id: id(), urlKey: CustomPageEnum.more + '/social-investment/application-form', pageTitle: 'Sponsorship Form', seoTitle: CustomPageSeoConstants.SocialInvestmentApplicationTitle, seoDescription: CustomPageSeoConstants.SocialInvestmentApplicationDescription},
        corporateAndSocialThankYou: {id: id(), urlKey: CustomPageEnum.more + '/social-investment/application-form/thank-you', pageTitle: 'Thank You', seoTitle: CustomPageSeoConstants.SocialInvestmentApplicationTitle, seoDescription: CustomPageSeoConstants.SocialInvestmentApplicationDescription}
    },
};
