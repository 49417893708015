export class ArrayUtil {

    // The Fisher-Yates (aka Knuth) shuffle
    public static shuffle<T>(array:T[]):T[] {
        let currentIndex = array.length;
        let temporaryValue;
        let randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue      = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex]  = temporaryValue;
        }

        return array;
    }

    public static valueKeyTrueHash<T>(collection:Array<string | number>):{ [key:string]:boolean } {
        const hash = {};
        if (collection) collection.forEach(item => hash[item] = true);
        return hash;
    }

    public static straightHash<T>(collection:T[], keyCallback:(item:T) => string | number):{ [key:string]:T } {
        const hash = {};
        if (collection) {
            collection.forEach(item => {
                const key = keyCallback(item);
                if (key != null) {
                    hash[key] = item;
                }
            });
        }
        return hash;
    }

    public static valueHash<V, T>(collection:T[], keyCallback:(item:T, i:number) => string | number, valueCallback:(item:T, i:number) => V):{ [key:string]:V } {
        const hash = {};
        if (collection) {
            collection.forEach((item, i) => {
                const key = keyCallback(item, i);
                if (key != null) {
                    hash[key] = valueCallback(item, i);
                }
            });
        }
        return hash;
    }

    public static isEmptyHash(obj):boolean {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    public static hashValuesToArr<V>(hash:{ [key:string]:V }):V[] {
        return hash ? Object.keys(hash).map(key => hash[key]) : [];
    }

    public static hashKeysToArr(hash):string[] {
        return hash ? Object.keys(hash) : [];
    }

    public static hashToArray<T, M>(hash:{ [key:string]:T }, remapCallback:(key:string, value:T) => M):M[] {
        return Object.keys(hash).map(key => remapCallback(key, hash[key]));
    }

    public static numberToArr<T>(number:number, valueCallback:(index:number) => T):T[] {
        const result:T[] = [];
        for (let i = 0; i < number; i++) {
            result.push(valueCallback(i));
        }
        return result;
    }

    public static sortByProp<T>(collection:T[], descending:boolean, propertyCallback:(item:T) => number) {
        if (descending) {
            return collection.sort((a, b) => propertyCallback(a) < propertyCallback(b) ? 1 : -1);
        }
        else {
            return collection.sort((a, b) => propertyCallback(a) > propertyCallback(b) ? 1 : -1);
        }
    }

    /**
     * Same as Array.map but for hash arrays
     */
    public static hashMap<T, K>(hash:{ [key:string]:T }, remapCallback:(v:T) => K) {
        const newHash = {};
        Object.keys(hash).forEach(key => newHash[key] = remapCallback(hash[key]));
        return newHash;
    }

    public static joinToString<T>(collection:string[], join: string = ', '):string {
        return collection.filter((x) => {
            if (x) x = x.trim();
            return x && x.length > 0;
        }).join(join);
    }
}

export const forEachAsync = async <T>(items:T[], iterator:(item:T, index:number) => Promise<any>) => {
    for (let i = 0; i < items.length; i++) {
        const val = items[i];
        await iterator(val, i);
    }
};
