import {gql} from 'apollo-angular';

export const GET_PRODUCT_FOR_BARCODE_SCAN_CACHE = true;

export const GET_PRODUCT_FOR_BARCODE_SCAN = gql`
    query products_barcodeScan($sku: String) {
        products(search: $sku) {
            items {
                id
                sku
                name
                url_key
                categories {
                    id
                    name
                    url_path
                }
            }
        }
    }
`;
