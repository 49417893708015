import {gql} from 'apollo-angular';

export const WP_GET_RELATED_POSTS_CACHE = true;

export const WP_GET_RELATED_POSTS = gql`
    query wpRelatedPosts($postId:Int) {
        wpRelatedPosts(postId: $postId) {
            from
            to
        }
    }
`;
