import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
    FMMenuBlogButtonUI,
    FMMenuCategoryButtonUI,
    FMMenuCustomButtonUI,
    FMMenuElementsUI
} from '../external-data/firebase/firebase-ui.types';
import {Observable, of} from 'rxjs';
import {
    FMCustomPageSEOData,
    FMFooterPageTypeEnum,
    FMMenuElements,
    FMMenuElementsGroup,
    FMMenuElementTypeEnum
} from '../external-data/firebase/firebase.types';
import {PageTypeEnum} from '../router/page-type.enum';
import {Logger} from '../shared/logger';
import {CategoryHash} from '../external-data/magento/magento.reducer.types';

@Injectable()
export class FooterMenuService {

    constructor(public translateService:TranslateService,
                public logger:Logger) {
        this.logger = this.logger.getLogger('FooterMenuUtils');
    }

    public mapFooterMenuElements(menuElementGroup:FMMenuElementsGroup, customPageSEOData:FMCustomPageSEOData[], categoryUrlKeyHash:CategoryHash, categoryIDHash:CategoryHash, isLoggedIn?:boolean):FMMenuElementsUI[] {
        //this.logger.debug('Mapping menu elements: ', menuElements, customPageSEOData, categoryUrlKeyHash);
        if (!menuElementGroup || !customPageSEOData || !categoryUrlKeyHash || !categoryIDHash) return null;

        const newItems:FMMenuElementsUI[] = [];

        // Here we create an array of every item if its singular to keep the data iterable in the UI
        menuElementGroup.forEach(groupItem => {
            let menuElements:FMMenuElementsUI = (groupItem instanceof Array) ? groupItem : [groupItem];
            menuElements                      = this.mapMenuElements(menuElements, customPageSEOData, categoryUrlKeyHash, categoryIDHash, isLoggedIn);
            if (menuElements && menuElements.length > 0) {
                newItems.push(menuElements);
            }
        });

        return newItems;
    }

    public mapMenuElements(menuElements:FMMenuElements, customPageSEOData:FMCustomPageSEOData[], categoryUrlKeyHash:CategoryHash, categoryIDHash:CategoryHash, isLoggedIn?:boolean):FMMenuElementsUI {
        //this.logger.debug('Mapping menu elements: ', menuElements, customPageSEOData, categoryUrlKeyHash);
        if (!menuElements || !customPageSEOData || !categoryUrlKeyHash || !categoryIDHash) return null;

        const updateCategoryButtonRecursive = (button:FMMenuCategoryButtonUI, categoryId?:number) => {
            button.category = categoryId != null ? categoryIDHash[categoryId] : categoryUrlKeyHash[button.urlKeyPath];

            if (button.category == null) {
                //this.logger.debug(`Category button not included because category ${button.urlKeyPath} could not be found`, categoryId, categoryUrlKeyHash);
                this.logger.debug(`Category button not included because category ${button.urlKeyPath} could not be found`, categoryId);
                return null;
            }
            else if (button.category.is_active === false) {
                this.logger.debug(`Category button not included because category ${button.urlKeyPath} is not active`);
                return null;
            }

            if (button.category && button.category.children_data && button.enableChildren !== false) {
                // Create new category button children for the UI to have a consistent data set
                button.children = [];
                button.category.children_data.forEach(categoryChild => {
                    // When recusing children its important to reference it using its id and not the global_category_id as we cannot be certain that exists
                    const childButton = updateCategoryButtonRecursive({urlKeyPath: null, type: button.type}, categoryChild.id);
                    if (childButton) button.children.push(childButton);
                });
            }

            if (button.adHocButtons) {
                this.logger.debug('Processing adHocButtons', button);
                if (!button.children) button.adHocButtons = [];

                button.adHocButtons.forEach(adHocBut => {
                    adHocBut      = Object.assign({}, adHocBut);
                    const updated = updateCustomButtonRecursive(adHocBut);
                    // Might be null
                    if (updated) {
                        // With no index we simply add at the end
                        let added        = false;
                        let currentIndex = 0;

                        if (updated.offset == null) {
                            button.children.push(updated);
                            added = true;
                        }
                        // Negative
                        else if (updated.offset < 0) {
                            for (let i = button.children.length - 1; i >= 0; i--) {
                                if (button.children[i].type === FMMenuElementTypeEnum.CATEGORY_BUTTON) {
                                    currentIndex++;
                                }
                                if (currentIndex === updated.offset * -1) {
                                    added = true;
                                    button.children.splice(i, 0, updated);
                                    break;
                                }
                            }
                        }
                        // Positive
                        else {
                            for (let i = 0; i < button.children.length; i++) {
                                if (currentIndex === updated.offset) {
                                    added = true;
                                    button.children.splice(i, 0, updated);
                                    break;
                                }
                                if (button.children[i].type === FMMenuElementTypeEnum.CATEGORY_BUTTON) {
                                    currentIndex++;
                                }
                            }
                        }
                        // Someone might enter a useless invalid index so it never gets added
                        if (!added) button.children.push(updated);
                    }
                });
            }

            // If no children and no products filter out
            // Intentionally done after children have been generated to check against the children length
            if (button.category && button.category.product_count === 0 && button.children && button.children.length === 0) {
                this.logger.debug(`Category button ${button.category.urlKeyPath} not included because category has 0 products and no child categories`);
                return null;
            }

            return button;
        };

        // Create custom page hash
        const customPageHash:{ [id:number]:string } = {};
        customPageSEOData.forEach(item => customPageHash[item.id] = item.urlKey);

        const updateCustomButtonRecursive = (button:FMMenuCustomButtonUI):FMMenuCustomButtonUI => {
            if (button.builtInPageType === PageTypeEnum.CUSTOM && button.selectable !== false && button.builtInPageType !== FMFooterPageTypeEnum.FULL_LINK) {
                // Deal with built in links mapped to customPageHash
                if (!isNaN(Number(button.pathAfterType))) {
                    const id      = Number(button.pathAfterType);
                    button.urlKey = customPageHash[id];

                    // If no url key is found, return null as this button won't be shown
                    if (button.urlKey == null && button.builtInPageType !== FMFooterPageTypeEnum.FULL_LINK) {
                        this.logger.debug(`Custom button not included because custom page ${button.pathAfterType} could not be found`, button);
                        return null;
                    }
                }
                // Allow CMS links
                else {
                    button.urlKey = button.pathAfterType;
                }
            }
            if (button.children) {
                const oldChildren = button.children;
                button.children   = [];
                oldChildren.forEach(child => {
                    child = Object.assign({}, child); // Shallow clone of the child
                    // Show / hide depending on logged in state. This should also cater for falsy `authenticated` can be null / unset
                    const shouldShow = !(child.authenticated === false || child.authenticated) ? true : child.authenticated === false && isLoggedIn === false ? true : child.authenticated && isLoggedIn;
                    if (child.type === FMMenuElementTypeEnum.CATEGORY_BUTTON) {
                        const updateCategory = updateCategoryButtonRecursive(<any>child);
                        if (updateCategory && shouldShow) button.children.push(updateCategory);
                    }
                    else if (child.type === FMMenuElementTypeEnum.CUSTOM_BUTTON) {
                        const updatedCustom = updateCustomButtonRecursive(<any>child);
                        if (updatedCustom && shouldShow) button.children.push(updatedCustom);
                    }
                    else {
                        button.children.push(child);
                    }
                });
            }
            return button;
        };

        const newMenuElements:FMMenuElementsUI = [];
        menuElements.forEach(menuEle => {
            // Create a shallow clone
            menuEle = Object.assign({}, menuEle);

            if (menuEle.type === FMMenuElementTypeEnum.CATEGORY_BUTTON) {
                const updateCategory = updateCategoryButtonRecursive(<any>menuEle);
                if (updateCategory) newMenuElements.push(updateCategory);
            }
            else if (menuEle.type === FMMenuElementTypeEnum.CUSTOM_BUTTON) {
                const updatedCustom = updateCustomButtonRecursive(<any>menuEle);
                if (updatedCustom) newMenuElements.push(updatedCustom);
            }
            else {
                newMenuElements.push(menuEle);
            }
        });

        return newMenuElements;
    }

    public getButtonName(menuElement:FMMenuCustomButtonUI | FMMenuCategoryButtonUI | FMMenuBlogButtonUI):Observable<string> {
        if (menuElement.type === FMMenuElementTypeEnum.CATEGORY_BUTTON) {
            return of(menuElement.category.name);
        }
        else if (menuElement.type === FMMenuElementTypeEnum.CUSTOM_BUTTON) {
            if (menuElement.label) return of(menuElement.label);
            return this.translateService.get(menuElement.localeKey);
        }
        else if (menuElement.type === FMMenuElementTypeEnum.BLOG_BUTTON) {
            return this.translateService.get(menuElement.localeKey);
        }
    }
}
