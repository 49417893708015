import {gql} from 'apollo-angular';

export const REGISTER_CUSTOMER = gql`
    mutation createCustomerWithCellphone($input: CustomerInputWithCellphone!) {
        createCustomerWithCellphone(input: $input) {
            customer {
                created_at
            }
        }
    }
`;
