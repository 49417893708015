// NOTE: In future this will be only stored in the magento DB but here now for convenience


export class CustomPageSeoConstants {
    static BraGuideTitle       = `The Bra Guide | Ackermans`;
    static BraGuideDescription = `Find out your true bra size by using our Ackermans Bra Guide. View our range of Perfect fit bra's.`;

    static LionKingDownloadsTitle          = `Lion King Downloads | Ackermans`;
    static LionKingDownloadsDescription    = `Download our free Lion King activity pages.`;
    static LionKingMovieTrailerTitle       = `Lion King Movie Trailer | Ackermans`;
    static LionKingMovieTrailerDescription = `Lion King Movie Trailer.`;

    static SocialInvestmentTitle                  = `Find Out More | Social Investment | Ackermans`;
    static SocialInvestmentDescription            = `Find out more about Ackermans corporate social investment initiatives`;
    static SocialInvestmentApplicationTitle       = `Find Out More | Social Investment Application | Ackermans`;
    static SocialInvestmentApplicationDescription = `Apply for the corporate social investment programme`;
}
