import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ViewStateEnum} from './view-state';
import produce from 'immer';

export namespace View {
    export class SetState {
        static readonly type = '[View] Set State';

        constructor(public viewName:string, public state:ViewStateEnum, public additional?:any) {
        }
    }

    export class SetInitializing {
        static readonly type = '[View] Set Initializing';

        constructor(public viewName:string) {
        }
    }

    export class SetProcessing {
        static readonly type = '[View] Set Processing';

        constructor(public viewName:string) {
        }
    }

    export class SetErrorRecovery {
        static readonly type = '[View] Set ErrorRecovery';

        constructor(public viewName:string) {
        }
    }

    export class SetReady {
        static readonly type = '[View] Set Ready';

        constructor(public viewName:string) {
        }
    }

    export class SetError {
        static readonly type = '[View] Set Error';

        constructor(public viewName:string, error?:any) {
        }
    }

    export class SetProps<T> {
        static readonly type = '[View] Set Props';

        constructor(public viewName:string, public props:T) {
        }
    }

}

export interface ViewStateModel {
    stateHash:{ [viewName:string]:ViewStateEnum };
    propHash:{ [viewName:string]:any };
}

@State<ViewStateModel>({
    name    : 'view',
    defaults: {
        stateHash: {},
        propHash : {}
    }
})
@Injectable()
export class ViewState {

    @Action(View.SetState)
    setState(ctx:StateContext<ViewStateModel>, action:View.SetState) {
        ctx.setState(produce(draft => {
            draft.stateHash[action.viewName] = action.state;
            draft.propHash[action.viewName]  = action.additional;
        }));
    }

    @Action(View.SetInitializing)
    setInitializing(ctx:StateContext<ViewStateModel>, action:View.SetInitializing) {
        ctx.setState(produce(draft => {
            draft.stateHash[action.viewName] = ViewStateEnum.INITIALIZING;
        }));
    }

    @Action(View.SetProcessing)
    setProcessing(ctx:StateContext<ViewStateModel>, action:View.SetProcessing) {
        ctx.setState(produce(draft => {
            draft.stateHash[action.viewName] = ViewStateEnum.PROCESSING;
        }));
    }

    @Action(View.SetErrorRecovery)
    setErrorRecovery(ctx:StateContext<ViewStateModel>, action:View.SetErrorRecovery) {
        ctx.setState(produce(draft => {
            draft.stateHash[action.viewName] = ViewStateEnum.ERROR_RECOVERY;
        }));
    }

    @Action(View.SetReady)
    setReady(ctx:StateContext<ViewStateModel>, action:View.SetReady) {
        ctx.setState(produce(draft => {
            draft.stateHash[action.viewName] = ViewStateEnum.READY;
        }));
    }

    @Action(View.SetError)
    setError(ctx:StateContext<ViewStateModel>, action:View.SetError) {
        ctx.setState(produce(draft => {
            draft.stateHash[action.viewName] = ViewStateEnum.ERROR;
        }));
    }

    @Action(View.SetProps)
    setProps<T>(ctx:StateContext<ViewStateModel>, action:View.SetProps<T>) {
        ctx.setState(produce(draft => {
            if (!draft.propHash[action.viewName]) draft.propHash[action.viewName] = {};

            // Update each property passed dynamically
            Object.keys(action.props).forEach(key => {
                draft.propHash[action.viewName][key] = action.props[key];
            });
        }));
    }
}
