import {gql} from 'apollo-angular';

export const SET_BILLING_ADDRESSES_ON_CART = gql`
    mutation setBillingAddressOnCart($input: SetBillingAddressOnCartInput) {
        setBillingAddressOnCart(input: $input) {
            cart {
                billing_address {
                    firstname
                    lastname
                    telephone
                    company
                    street
                    city
                    postcode
                    region {
                        code
                        label
                        region_id
                    }
                    country {
                        code
                        label
                    }
                }
            }
        }
    }
`;
