import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CrossPlatformModule} from '../../../cross-platform/cross-platform.module';
import {GlobalSharedUIModule} from '../shared/ui/global-shared-ui.module';
import {CustomPageRouterComponent} from './custom-page-router/custom-page-router.component';

@NgModule({
    imports: [
        CommonModule,
        CrossPlatformModule,
        GlobalSharedUIModule
    ],
    declarations: [
        CustomPageRouterComponent
    ],
    exports: [
        CustomPageRouterComponent
    ]
})
export class CustomPageSharedModule {
}
