import {gql} from 'apollo-angular';

export const SUBSCRIBE_TO_NEWSLETTER = gql`
    mutation prospect_newsletterSubscribe(
        $brand:String,
        $emailAddress:String,
        $type:String
    ) {
        prospect(
            brand: $brand,
            email_address: $emailAddress,
            type: $type
        ) {
            Response {
                ResponseCode
                ResponseDescription
            }
        }
    }
`;
