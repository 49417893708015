// NOTE: In future this will be only stored in the magento DB but here now for convenience
import {CustomPageEnum} from './custom-page.enum';
import {PageTypeEnum} from '../../../../framework/src/cross-platform/router/page-type.enum';

let cnt  = 0;
const id = () => cnt++ + '';

export let CubeBuiltInPageMapping = {
    account: {
        ackAccount: {id: id(), urlKey: PageTypeEnum.ACCOUNT + '/' + CustomPageEnum.ackAccount, localePageType: PageTypeEnum.ACCOUNT + CustomPageEnum.ackAccount, seoLocaleConvention: 'account.ackAccount'},
    }
};

export class CubeCMSPageLinks {
    static termsGeneral                = '/page/terms-and-conditions/general';
    static termsCookiePolicy           = '/page/terms-and-conditions/general#cookie-policy';
    static externalPrivacyStatement    = '/page/terms-and-conditions/general#external-privacy-statement';
    static termsPromotionOfAccess      = '/page/terms-and-conditions/general#paia';
    static termsCompetitions           = '/page/terms-and-conditions/competitions';
    static corporateInformationAboutUs = '/page/corporate-information/about-us';
    static howToOrder                  = '/page/delivery-options#how-to-buy-online';
    static returnsFAQ                  = '/page/terms-and-conditions/general#returns-exchange';
    static deliveryDetails             = '/page/delivery-options#delivery';
}

// Note its important that this mapping only include pages actually embedded as app custom pages and nothing else.
// This map is used to build the sitemap hence it needs to be accurate or we will get duplicate content
export let CubeCustomPageMapping = {};
