import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {LogoutPromptService} from './logout-prompt.service';
import {LogoutPromptComponent} from './logout-prompt.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports     : [TranslateModule],
    declarations: [LogoutPromptComponent],
    exports     : [LogoutPromptComponent],
    providers   : [LogoutPromptService],
    schemas     : [CUSTOM_ELEMENTS_SCHEMA]
})
export class LogoutPromptModule {

}
