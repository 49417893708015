// Consistent across all implementations

export const EnvironmentEnum = {
    local   : 'local',
    minikube: 'minikube',
    dev     : 'dev',
    qa      : 'qa',
    uat     : 'uat',
    lte     : 'lte',
    staging : 'staging',
    prod    : 'prod',
};

export enum FacadeAppDefinitionType {
    desktop   = 'desktop',
    mobile    = 'mobile',
    mobileapp = 'mobileapp'
}

export interface IFacadeConfig {
    version:string;
}
