import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
    AbstractNoContentIncludeComponent
} from '../../../cross-platform/no-content-page/abstract-no-content-include.component';
import {environment} from '../../../cross-platform/implementation/environment';
import {combineLatest} from 'rxjs';
import {FooterMenuService} from '../../../cross-platform/menu/footer-menu.service';
import {FMMenuElementsUI} from '../../../cross-platform/external-data/firebase/firebase-ui.types';

@Component({
    selector       : 'no-content-include',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template       : `
        <div class="no-content-container" [class.no-padding]="noPadding">
            <div class="title"></div>
            <div class="message">
                <span class="first" [innerHTML]="'404.errorMessage' | translate"></span><br>
                <span class="second" [innerHTML]="'404.helpfulLinks' | translate:{'homeLink':urlService.buildUrlForHome()}"></span>
            </div>
        </div>
        <div class="no-content-sidebar">
            <div class="no-content-sidebar-inner">
                <div class="no-content-sidebar-inner-1">
                    <div [innerHTML]="'checkout.needHelp' | translate"></div>
                </div>
                <div class="no-content-sidebar-inner-2">
                    <ul class="page-nav">
                        <ng-template ngFor let-menuElement [ngForOf]="noContentMenuItems">
                            <li>
                                <a [fakeLink]="urlService.buildMenuElementUrl(menuElement)" (fakeClick)="handleLink($event)" *ngIf="menuElement.selectable !== false" class="page-nav-link">
                                    {{footerMenuService.getButtonName(menuElement) | async}}
                                    <div class="icon-holder">
                                        <facade-icon icon="icon-chevron-right"></facade-icon>
                                    </div>
                                </a>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </div>
        </div>
    `
})
export class NoContentIncludeComponent extends AbstractNoContentIncludeComponent {
    footerMenuService:FooterMenuService;
    noContentMenuItems:FMMenuElementsUI;

    constructor(injector:Injector) {
        super(injector);
        this.footerMenuService = injector.get(FooterMenuService);

    }

    init() {
        super.init();
        if (environment.uiConfig.noContentMenuElements) {
            this.addSub = combineLatest([
                this.selectWhenReady(s => s.magento.categoryUrlKeyHash),
                this.selectWhenReady(s => s.magento.categoryIDHash),
            ])
                .subscribe(
                    values => {
                        this.noContentMenuItems = this.footerMenuService.mapMenuElements(environment.uiConfig.noContentMenuElements, environment.config.customPageSEOData, values[0], values[1]);
                        this.changeDetectorRef.detectChanges();
                    }
                );
        }
    }
}
