import {Component, Injector} from '@angular/core';
import {AbstractFacadeComponent} from '../../../../cross-platform/shared/ui/abstract-facade.component';
import {AuthConfig, AuthType} from '../../../../cross-platform/auth/components/auth.types';
import {Router} from '@angular/router';
import {WindowRef} from '../../../../cross-platform/shared/ui/window-ref';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'auth-modal',
    template: `
        <div class="modal-header">
            <h4 class="modal-title modal-basic-title">{{'authModal.title' | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="closeCancel()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <auth-login [config]="authConfig" (onLogin)="handleLogin()" (onLogout)="handleLogout()"></auth-login>
        </div>
    `
})
export class AuthModalComponent extends AbstractFacadeComponent {
    router:Router;
    windowRef:WindowRef;

    authConfig:AuthConfig = {
        type      : AuthType.RE_AUTHENTICATE,
        allowGuest: false
    };
    ngbActiveModal:NgbActiveModal;

    constructor(injector:Injector) {
        super(injector);
        this.router    = injector.get(Router);
        this.windowRef = injector.get(WindowRef);
        // dont allow guest checkout on re auth modal
        // this.authConfig.allowGuest = this.urlService.isProductPageUrl(this.router.url);
        this.ngbActiveModal = injector.get(NgbActiveModal);
    }

    handleLogin() {
        // this.modalController.dismiss();
        this.destroy();
        this.ngbActiveModal.dismiss();
        this.windowRef.reload();
    }

    handleLogout() {
        this.ngbActiveModal.dismiss();
    }

    closeCancel() {
        this.ngbActiveModal.dismiss();
    }

    get name() {
        return 'AuthModalComponent';
    }
}
