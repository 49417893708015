import {Directive, Input} from '@angular/core';

@Directive({
    selector: '[localVariables]',
    exportAs: 'localVariables'
})
export class LocalVariablesDirective {

    @Input('localVariables')
    set localVariables(vars:any) {
        if (typeof vars === 'object') {
            for (const varName in vars) {
                if (vars.hasOwnProperty(varName)) this[varName] = vars[varName];
            }
        }
    }
}