import {gql} from 'apollo-angular';

export const WP_GET_HIT_COUNT_CACHE = false;

export const WP_GET_HIT_COUNT = gql`
    query wpGetHitCount($url:String) {
        wpGetHitCount (url: $url) {
            id
            url
            hitcount
        }
    }
`;
