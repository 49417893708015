<a role="button" class="btn btn-light" [routerLink]="['home']">{{'menu.home' | translate}}</a>

<menu-button *ngFor="let item of menuItems" [class]="item.cssClasses" [menuData]="{item:item, categoryUrlKeyHash:categoryUrlKeyHash}" (select)="handleLink($event)" #parent>
    <!--<menu-sub-menu [children]="menuElement['children']" (select)="selectMenuItem($event); parent.close()"></menu-sub-menu>-->
</menu-button>

<search-bar-generic [dropDownReference]="searchResults" [placeholder]="'search.placeholder' | translate" [dataSource]="productSearchDataSource">
    <product-search-bar-renderer #searchResults></product-search-bar-renderer>
</search-bar-generic>
<div class="quicklook-cart-holder" #quicklookCartHolder></div>
