import {gql} from 'apollo-angular';

export const FETCH_CUSTOMER_INFO_CACHE = false;

export const FETCH_CUSTOMER_INFO = gql`
    query customer_basic {
        customer {
            firstname
            lastname
            date_of_birth
            email
            gender
            is_subscribed
            prefix
            cellphone_number
            plus_more_opt_in
            rsa_id
            passport_number
            asylum_document_number
            plus_more_card_number
        }
    }
`;
