import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input
} from '@angular/core';

@Component({
    selector       : 'accordion-header',
    template       : `
        <ng-content></ng-content>
        <div class="icon-holder" *ngIf="_isCollapsible">
            <facade-icon icon="icon-chevron-down" *ngIf="_isOpen"></facade-icon>
            <facade-icon icon="icon-chevron-right" *ngIf="!_isOpen"></facade-icon>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacadeAccordionHeaderComponent {

    @HostBinding('class')
    class = 'closed';

    openClose = new EventEmitter();

    @Input() enabled      = true;
    @Input() canSelfClose = true;

    _isOpen = false;

    _isCollapsible = true;

    constructor(public el:ElementRef, public changeDetectorRef:ChangeDetectorRef) {
    }

    @HostListener('click', ['$event'])
    clickListener($event) {
        if (!this.enabled || (this._isOpen && !this.canSelfClose)) return;
        this.openClose.emit($event);
    }

    public set open(value:boolean) {
        if (!this.enabled) return;
        //console.log('accordion header is now open', value);
        this.class   = value ? 'open' : 'closed';
        this._isOpen = value;
        this.changeDetectorRef.detectChanges();
    }

    public set isCollapsible(value:boolean) {
        this._isCollapsible = value;
    }
    
}
