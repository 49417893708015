import {Injectable} from '@angular/core';
import {ExternalResponse} from '../external-data/external-response';
import {ResponseCode} from '../external-data/enum/response-code.enum';
import {ErrorCodeBackReference, ErrorCodes} from '../external-data/error-codes';
import {TranslateService} from '@ngx-translate/core';
import {Logger} from '../shared/logger';
import {GraphQLResponseError, RuntimeErrorHandlerService} from './runtime-error-handler.service';
import {OtpStatus} from '../checkout/delivery/delivery.service';
import {CartUserInputErrorType} from '../../../../generated/graphql';

export enum ApiErrorObjectFormatTypes {
    magento,
    microservice
}

@Injectable()
export class ApiErrorHelper {

    private errorCodesToExclude = [
        ErrorCodes.ERR_INVALID_CREDENTIAL,
        ErrorCodes.AUTH_SERVER_DISABLED,
        ErrorCodes.ERR_DUPLICATE_CREDENTIAL,
        ErrorCodes.INVALID_USERNAME,
        ErrorCodes.INVALID_ONE_TIME_PIN,
        ErrorCodes.ERR_CREDENTIAL_NOT_FOUND,
        ErrorCodes.PROFILE_NOT_VERIFIED,
        //ErrorCodes.ERR_SYSTEM_ERROR,
        ErrorCodes.ERR_PROFILE_USER_ALREADY_EXISTS,
        ErrorCodes.ERR_DISTINCT_PROFILE_VALUE_ALREADY_SET,
        //ErrorCodes.ERR_INVALID_PARAMETER,
        //ErrorCodes.ERR_INVALID_REQUEST,
        ErrorCodes.ERR_UNAUTHORISED_REQUEST,
        //ErrorCodes.ERR_NO_SUCH_PROFILE_TYPE,
        //ErrorCodes.ERR_MAX_COUNT_OF_PROFILE_TYPE_REACHED,
        ErrorCodes.ERR_INVALID_USER_ID,
        ErrorCodes.ERR_NO_SUCH_PARENT_PROFILE_TYPE,
        ErrorCodes.ERR_NO_SUCH_PARENT_PROFILE_VALUE,
        //ErrorCodes.ERR_KONG_CRITICAL,
        ErrorCodes.ERR_MAGENTO_EMAIL_DUPLICATE,
        ErrorCodes.ERR_MAGENTO_DUPLICATE_CUSTOMER,
        ErrorCodes.ERR_MAGENTO_SHIPPING_ADDRESS_NOT_SUPPORTED,
        ErrorCodes.ERR_MAGENTO_SHIPPING_API_ERROR,
        ErrorCodes.ERR_MAGENTO_SHIPPING_API_NOT_AVAILABLE,
        ErrorCodes.PLUS_MORE_DUPLICATE_ID,
        ErrorCodes.PLUS_MORE_DUPLICATE_CONTACT_API,
        ErrorCodes.PLUS_MORE_INVALID_ID_API,
        ErrorCodes.PLUS_MORE_ALREADY_LINKED,
        ErrorCodes.PLUS_MORE_CREATE_ERROR,
        ErrorCodes.OTP_EXPIRED_OR_LIMIT_REACHED,
        //ErrorCodes.ERR_MAGENTO_MISSING_EMAIL
        //ErrorCodes.SMS_FAILURE,

        ErrorCodes.ERR_MAGENTO_INSUFFICIENT_STOCK_WHEN_PLACING_ORDER,
        ErrorCodes.ERR_MAGENTO_INVALID_CART_ITEM_WITH_ZERO_PRICE,

        CartUserInputErrorType.ProductNotFound,
        CartUserInputErrorType.NotSalable,
        CartUserInputErrorType.InsufficientStock
    ];

    private statusToExclude = [
        OtpStatus.FAIL_CREATE_ACTIVE_SESSION,
        OtpStatus.VERIFICATION_FAIL,
        OtpStatus.NOT_IN_STATE_TO_BE_VERIFIED,
        OtpStatus.FAIL_SESSION_CLOSED_TIME_OUT
    ];

    constructor(private translate:TranslateService,
                private logger:Logger,
                private runtimeErrorHandlerService:RuntimeErrorHandlerService) {
        this.logger = this.logger.getLogger('ApiErrorHelper');
    }

    /**
     * Helper method designed to reduce boilerplate in handling API errors
     * It returns the actual error handler method for a subscribe() so you just need to provide 2 callbacks.
     * It takes in a method to check for expected error, this method should return a message if it finds something to handle.
     * If not this method does its best to construct a useful error message.
     * When complete the second callback is executed with the error message.
     */
    wrapErrorHandler<D>(errorFormatter:(error:ExternalResponse<D>) => Promise<any>, errorImplementor:(errorMessage:string, error:ExternalResponse<D>) => void, format?:ApiErrorObjectFormatTypes) {
        return async (originalError:ExternalResponse<any>) => {
            let returnMessage = '';
            try {
                if (originalError && typeof originalError === 'object') {
                    if (errorFormatter && originalError.error) returnMessage = await errorFormatter(originalError);

                    // Error returned but no explicit handling, means there was something wrong
                    if (returnMessage == null || returnMessage === '') {
                        this.logger.debug('Attempting to auto formatting error: ', originalError);

                        if (originalError.httpError && originalError.httpError.status === 401) {
                            // Do nothing as this will redirect and we don't want to show anything
                            returnMessage = '';
                        }
                        // Data from the server we can display
                        else if (originalError.responseCode === ResponseCode.GENERAL_ERROR) {
                            if (format === ApiErrorObjectFormatTypes.magento && originalError.error && originalError.error.code) {
                                returnMessage = await this.getLocale('error.wrapper.magentoError', {key1: originalError.error.code, key2: ErrorCodeBackReference[originalError.error.code]});
                            }
                            else if (format === ApiErrorObjectFormatTypes.microservice && originalError.error && originalError.error.ResponseCode) {
                                returnMessage = await this.getLocale('error.wrapper.magentoError', {key1: originalError.error.ResponseCode, key2: ErrorCodeBackReference[originalError.error.ResponseCode]});
                            }
                            else if (originalError.error) {
                                returnMessage = await this.getLocale('error.wrapper.serverError');
                            }
                            else {
                                returnMessage = await this.getLocale('error.wrapper.serverError', {key: originalError.httpError ? originalError.httpError.status : 'unknown'});
                            }
                        }
                        else {
                            switch (originalError.responseCode) {
                                case ResponseCode.CONNECTION_ERROR:
                                    returnMessage = await this.getLocale('error.wrapper.connectionError');
                                    break;
                                case ResponseCode.CLIENT_SIDE_TIMEOUT:
                                    returnMessage = await this.getLocale('error.wrapper.clientTimeout');
                                    break;
                                case ResponseCode.SERVER_SIDE_TIMEOUT:
                                    returnMessage = await this.getLocale('error.wrapper.serverTimeout');
                                    break;
                                case ResponseCode.PARSING_ERROR:
                                    returnMessage = await this.getLocale('error.wrapper.parsingError');
                                    break;
                                case ResponseCode.INTERCEPTOR_REJECT:
                                    // Expected from time to time, means we don't need to do anything
                                    // Will likely only be 401, handled above with the http code
                                    returnMessage = '';
                                    break;
                                case ResponseCode.INVALID_RESPONSE_FORMAT:
                                    returnMessage = await this.getLocale('error.wrapper.invalidResponse', {key: JSON.stringify(originalError.error)});
                                    break;
                                case ResponseCode.RECAPTCHA_ERROR:
                                    returnMessage = await this.getLocale('error.wrapper.recaptureError');
                                    break;
                            }
                        }
                    }
                }
                else {
                    returnMessage = 'Invalid or null error object';
                }
            }
            catch (err) {
                this.logger.error(err);
                returnMessage = 'Runtime error in error message building';
            }

            // Note: this is a poor way to implement handled error codes. This code should not need to know every handled error code
            // it should come from the implementation code. This code needs to be refactored
            if (originalError?.responseCode !== ResponseCode.CONNECTION_ERROR &&
                originalError?.responseCode !== ResponseCode.CLIENT_SIDE_TIMEOUT &&
                originalError?.responseCode !== ResponseCode.AUTHENTICATION_ERROR) {

                let matchesCodeToExclude = false;
                const errorCodeNum       = originalError?.error?.code != null ? Number(originalError.error.code) : null;
                if (errorCodeNum) {
                    matchesCodeToExclude = this.errorCodesToExclude.filter(code => errorCodeNum === code).length > 0;

                    if (errorCodeNum === ErrorCodes.ERR_INVALID_REQUEST &&
                        // This is obviously a horrific way to test for something, but alas, the error code used for this is a generic one -_-
                        originalError?.error?.message === 'Target customer has no accounts/products that could be linked') {
                        matchesCodeToExclude = true;
                    }
                }

                if (originalError?.error?.message?.status) {
                    for (let i = 0; i < this.statusToExclude.length; i++) {
                        if (originalError?.error?.message?.status === this.statusToExclude[i]) {
                            matchesCodeToExclude = true;
                            break;
                        }
                    }
                }

                if (originalError?.error?.message === 'recaptcha-fail') matchesCodeToExclude = true;
                // This is a terrible way to handle this error. Should be using a code not a message string which could change, but due to the project shut down
                // and no way any magento updates are going to be done now, this is the approach.
                // This error will only happen if a user has two tabs open and completes their checkout in another tab. Then comes back to this one and tries to continue.
                if (originalError?.error?.message === `The cart isn't active.` && originalError?.error?.functionName === `setShippingAddressesOnCart`) matchesCodeToExclude = true;

                if (!matchesCodeToExclude) {
                    await this.runtimeErrorHandlerService.addBreadcrumb(originalError);
                    let errorToSend:GraphQLResponseError;
                    let serverMessage  = originalError?.error?.message;
                    const functionName = originalError?.error?.functionName;
                    if (typeof originalError?.error === 'string') serverMessage = originalError.error;

                    const message = `GQL ${(functionName) ? functionName + '()' : ''}${errorCodeNum ? ', ' + errorCodeNum : ''}: ${(serverMessage) ? serverMessage : returnMessage}`;
                    errorToSend   = new GraphQLResponseError(message, serverMessage, functionName, errorCodeNum);
                    this.runtimeErrorHandlerService.logExceptionObject(errorToSend);
                }
            }

            errorImplementor(returnMessage, originalError);
        };
    }

    wrapErrorHandlerNoExpects(errorImplementor:(errorMessage:string, error:ExternalResponse<any>) => void, format?:ApiErrorObjectFormatTypes) {
        return this.wrapErrorHandler(() => null, errorImplementor, format);
    }

    getLocale(localeKey:string, params?:object) {
        if (params) {
            Object.keys(params).forEach(key => {
                if (params[key] == null) params[key] = 'undefined';
            });
        }
        return this.translate.get(localeKey, params).toPromise();
    }

}
