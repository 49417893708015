import {Directive, ElementRef, EventEmitter, HostListener, Injector, Input, Output} from '@angular/core';
import {AbstractFacadeComponent} from '../abstract-facade.component';
import {PhotoSwipeItem} from './photo-swipe.types';
import {PhotoSwipeService} from './photo-swipe.service';

@Directive({
    selector: '[photoSwipeLink]'
})
export class PhotoSwipeLinkDirective extends AbstractFacadeComponent {

    @Input() galleryItems:PhotoSwipeItem[];
    @Input() index:number;
    @Input() groupName:string;

    @HostListener('click', ['$event'])
    onClick() {
        this.photoSwipeService.showGallery(this.groupName, this.index, this.galleryItems, this.el)
            .then(() => this.photoSwipeLoadedCorrectly.emit(true))
            .catch(() => this.photoSwipeLoadedCorrectly.emit(false));
        return false;
    }

    @Output()
    photoSwipeLoadedCorrectly = new EventEmitter<boolean>();


    constructor(public injector:Injector,
                public photoSwipeService:PhotoSwipeService,
                public el:ElementRef) {
        super(injector);
    }

    get name():string {
        return 'PhotoSwipeLinkDirective';
    }
}
