import {Injectable, Injector} from '@angular/core';
import {Observable, of, from} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {ISearchBarDataSource} from './search.types';
import {CategorySearchService, CatSearchItem} from './category-search.service';
import {ProductPageResponse, ProductsService} from '../elasticsearch/products.service';
import {ExternalResponse} from '../../external-response';
import {WordpressSearchService} from '../../wordpress/wordpress-search.service';
import {Paginator} from '../util/paginator';
import {ProductInterface} from '../../../../../../generated/graphql';
import {ESWPSearch} from '../../wordpress/model/wordpress.types';
import {AbstractFacadeService} from '../../abstract-facade.service';
import {Search} from '../../../menu/search/search.state';
import {GTMActions} from '../../google/google-tag-manager/gtm.actions';
import {ProductModel} from '../elasticsearch/product.model';
import {ProductsResponse} from '../gql-queries/gql-query-response-types';
import {
    GET_PRODUCT_FOR_BARCODE_SCAN,
    GET_PRODUCT_FOR_BARCODE_SCAN_CACHE
} from '../gql-queries/get-product-for-barcode-scan.query';
import {RootState} from '../../../ngxs/root.state';
import AutoCompleteStart = Search.AutoCompleteStart;
import SaveAutoCompleteError = Search.SaveAutoCompleteError;
import StoreSearchTerm = Search.StoreSearchTerm;
import SaveAutoCompleteResults = Search.SaveAutoCompleteResults;
import ClearAutoCompleteResults = Search.ClearAutoCompleteResults;
import BarcodeSearchStart = Search.BarcodeSearchStart;


export interface TextSearchResponse {
    categories?:CatSearchItem[];
    productRes?:ExternalResponse<ProductInterface[]>;
    blogRes?:Paginator<ESWPSearch>;
}

@Injectable()
export class SearchService extends AbstractFacadeService implements ISearchBarDataSource {

    constructor(injector:Injector,
                public productsService:ProductsService,
                public categorySearchService:CategorySearchService,
                public wordpressSearchService:WordpressSearchService) {
        super(injector);
        this.logger = this.logger.getLogger('ProductSearchDataSource');
    }

    textSearch(searchTerm:string):Observable<TextSearchResponse> {
        this.store.dispatch(new AutoCompleteStart(searchTerm));

        let returnData:TextSearchResponse = {};
        return this.store.select((s:RootState) => s.search)
            .pipe(
                switchMap(search => {
                    if (searchTerm === '' || searchTerm == null) {
                        this.store.dispatch(new StoreSearchTerm(searchTerm));
                        this.store.dispatch(new SaveAutoCompleteResults(null));
                        return of(null);
                    }
                    else if (searchTerm === search.autoCompleteSearchTerm) {
                        this.store.dispatch(new StoreSearchTerm(searchTerm));
                        this.store.dispatch(new SaveAutoCompleteResults(search.autoCompleteResults));
                        return of(null);
                    }
                    else {
                        return this.productsService.fetchForAutocomplete(searchTerm)
                            .pipe(
                                tap(
                                    (res:ExternalResponse<ProductInterface[]>) => {
                                        returnData = {productRes: res};
                                    },
                                    error => {
                                        this.store.dispatch(new SaveAutoCompleteError(error));
                                    }
                                ),
                                switchMap(() => from(this.categorySearchService.search(searchTerm, 3))),
                                tap(
                                    (res:CatSearchItem[]) => {
                                        returnData.categories = res;
                                    },
                                    error => {
                                        this.store.dispatch(new SaveAutoCompleteError(error));
                                    }
                                ),
                                switchMap(() => this.wordpressSearchService.textSearch(searchTerm)),
                                tap(
                                    (res:Paginator<ESWPSearch>) => {
                                        returnData.blogRes = res;
                                        this.store.dispatch(new StoreSearchTerm(searchTerm));
                                        this.store.dispatch(new SaveAutoCompleteResults(returnData));
                                        // GA4
                                        this.store.dispatch(new GTMActions.TrackGASearch({search_term: searchTerm}));
                                    },
                                    error => {
                                        this.store.dispatch(new SaveAutoCompleteError(error));
                                    }
                                ),
                                switchMap(() => of(returnData))
                            );
                    }
                })
            );

    }

    barcodeSearch(sku:string, type:'scan' | 'manual-input'):Observable<ExternalResponse<ProductPageResponse>> {
        // this.store.dispatch(new AutoCompleteStart(sku));
        this.store.dispatch(new BarcodeSearchStart(sku));
        let product:ProductInterface;
        return this.apollo.query({
            query    : GET_PRODUCT_FOR_BARCODE_SCAN,
            variables: {sku: sku}
        }, GET_PRODUCT_FOR_BARCODE_SCAN_CACHE).pipe(
            tap((res:ExternalResponse<ProductsResponse>) => {
                    product = res.data.products?.items[0];
                },
                error => {
                    this.store.dispatch(new GTMActions.TrackGABarcodeSearchFailed({
                        search_type  : type,
                        search_sku   : sku,
                        error_message: error?.httpError?.message
                    }));
                }),
            map((res:ExternalResponse<ProductsResponse>) => {
                const newRes:ExternalResponse<ProductPageResponse> = <any>res;
                if (product) {
                    const convertedProduct = new ProductModel(product, this.urlService.categoryIDHash);
                    newRes.data            = {product: convertedProduct};
                }
                return newRes;
            })
        );
    }

    clearSearchResults():void {
        this.logger.debug('Clearing search results');
        this.store.dispatch(new ClearAutoCompleteResults());
    }

    getName():string {
        return 'SearchService';
    }
}
