import {gql} from 'apollo-angular';

export const DONATIONS_GENERATE_SIGNATURE_CACHE = false;

export const DONATIONS_GENERATE_SIGNATURE = gql`
    mutation generateSignatureAndStore($formDataJson: String!) {
        generateSignatureAndStore (formDataJson: $formDataJson) {
            formDataJson
            checkoutUrl
        }
    }
`;
