import {Injectable, Injector} from '@angular/core';
import {MagentoAttribute, MagentoAttributeFrontendInput} from '../../external-data/enum/magento-constants';
import {ArrayUtil} from '../../shared/util/array.util';
import {
    AttributeSelection,
    FilterCategoryNav,
    FilterControlCheckbox,
    FilterControlCheckboxOption,
    FilterControlCheckboxType,
    FilterControlLinks,
    FilterControlNav,
    FilterControlPriceRange,
    FilterGroup,
    FilterLink,
    FilterType
} from './filters.types';
import {ImplementationDataService} from '../../implementation-config/implementation-data.service';
import {Aggregation, GqlMagentoAttribute} from '../../../../../generated/graphql';
import {CategoryHash} from '../../external-data/magento/magento.reducer.types';
import {UrlService} from '../../shared/url.service';
import {PageTypeEnum} from '../../router/page-type.enum';
import {Logger} from '../../shared/logger';
import {PepkorCategoryapiDataCategoryapiTreeInterface} from '../../external-data/magento/magento.types';

interface AggregationHash {
    [key:string]:Aggregation;
}

@Injectable()
export class FilterModelService {
    public urlService:UrlService;
    public logger:Logger;
    private allAttributeIndexHash:{ [key:string]:{ [key:string]:number } } = {};

    constructor(injector:Injector) {
        this.implementationDataService = injector.get(ImplementationDataService);
        this.urlService                = injector.get(UrlService);
        this.logger                    = injector.get(Logger);
    }

    public implementationDataService:ImplementationDataService;

    public convertAttributesForFilterComp(categoryIDHash:CategoryHash,
                                          selectedAttr:AttributeSelection[],
                                          baseCategoryId:number,
                                          baseAggregations:Aggregation[],
                                          filteredAggregations:Aggregation[],
                                          allAttributeMetadata:GqlMagentoAttribute[]
    ) {
        //console.log('baseAggregations: ', baseAggregations);
        //console.log('filteredAggregations: ', filteredAggregations);
        /*
        This method is used to build up the attributes to show in the filters component
        It does this by comparing a base attribute set with a current set
        Additionally it makes decisions using the selected

        Rules:
        We can easily calculate the base filters, the challenge is when the
        user makes a selection and a new set of attributes is returned
        The below is a basic outline of what to do with attrs when a selection has been made

        Start with the base filters, then
        Categories, or              - needs to stay in all cases
        Price range, or             - needs to stay in all cases
        Any (Screen size), range    - needs to stay, if one is selected (counts probably cannot be determined)
        Any (Size), select          - needs to stay if one is selected, otherwise removed if not in the latest result set
        */

        if (baseAggregations == null) return [];

        // Create a hash of the attributes
        const filterSettings = this.implementationDataService.getProductFilterSettings();

        const baseAggregationsHash     = ArrayUtil.straightHash(baseAggregations, agg => agg.attribute_code);
        const filteredAggregationsHash = ArrayUtil.straightHash(filteredAggregations, agg => agg.attribute_code);

        const categoryControl:FilterControlCheckbox = filterSettings.productCategoryFilterEnabled ? this.buildCategoryControl(categoryIDHash, baseAggregationsHash[MagentoAttribute.category_id], baseCategoryId) : null;
        const categoryLinks:FilterControlLinks      = filterSettings.productFilterCategoryLinks?.enabled ? this.buildCategoryLinksControl(categoryIDHash, baseCategoryId) : null;
        const categoryNav:any                       = filterSettings.productCategoryNavEnabled ? this.buildCategoryNavControl(categoryIDHash, baseCategoryId) : null;
        const priceControl:FilterControlPriceRange  = this.buildPriceControl(baseAggregationsHash[MagentoAttribute.price], selectedAttr);
        const onSaleControl:FilterControlCheckbox   = filterSettings.showOnSaleFilter ? this.buildOnSaleControl() : null;
        const inStockControl:FilterControlCheckbox  = filterSettings.showInStockFilter ? this.buildInStockControl() : null;

        const hasCategoryControl = categoryControl && categoryControl.options.length > 0;
        const hasCategoryLinks   = categoryLinks && categoryLinks.options?.length > 0;
        const hasCategoryNav     = categoryNav && categoryNav.options?.length > 0;
        const hasPriceControl    = priceControl && priceControl.pricingSteps.length > 1;

        const showFirstOnly    = filterSettings.showFirstOnly;
        const defaultCollapsed = filterSettings.defaultCollapsed;

        const optionControls:FilterControlCheckbox[] = this.buildAttrOptions(allAttributeMetadata, baseAggregations, filteredAggregations, filteredAggregationsHash, selectedAttr, !hasPriceControl && !hasCategoryControl);
        const hasOptionsControls                     = optionControls && optionControls.length > 0;

        let result:FilterGroup[] = [];

        // if (hasSortControl) result.push({id: 'sort', localeKey: 'filters.categories', controls: [categoryControl], collapsed: defaultCollapsed ? defaultCollapsed : false})
        if (hasCategoryControl) result.push({id: 'category', localeKey: 'filters.categories', controls: [categoryControl], collapsed: defaultCollapsed ? defaultCollapsed : false, collapsable: true});
        if (hasCategoryLinks) result.push({id: 'category-links', localeKey: categoryLinks.name, controls: [categoryLinks], collapsed: false, collapsable: true});
        if (hasCategoryNav) result.push({id: 'category-nav', localeKey: 'filters.categories', controls: [categoryNav], collapsed: false, collapsable: false});
        if (hasPriceControl) result.push({id: 'price', localeKey: 'filters.price', controls: [priceControl], collapsed: !!defaultCollapsed || !!((hasCategoryControl || hasCategoryLinks || hasCategoryNav) && showFirstOnly), collapsable: true});
        if (onSaleControl) result.push({id: onSaleControl.id, localeKey: 'filters.onSale', controls: [onSaleControl], collapsed: false, collapsable: false});
        if (inStockControl) result.push({id: inStockControl.id, localeKey: 'filters.inStock', controls: [inStockControl], collapsed: false, collapsable: false});
        if (filterSettings.showSortOnFilter) result.push({id: 'sort', localeKey: 'product.sortBy', controls: [], collapsed: defaultCollapsed, collapsable: true});
        if (result.length > 0) {
            const filterSortOrder = filterSettings.filterSortOrder;
            if (filterSortOrder) {
                // add each attribute filter into its own filter result to be sortable
                optionControls.forEach((optionControl, index) => {
                    const filter = {id: `attributes-${index}`, controls: [optionControl], collapsed: false, collapsable: true};
                    result.push(filter);
                });

                // sorting by sort order defined in settings
                result = result.sort((filterA, filterB) => {
                    let propA  = filterA.controls?.length ? filterA.controls[0].id : filterA.id;
                    let propB  = filterB.controls?.length ? filterB.controls[0].id : filterB.id;
                    let orderA = filterSortOrder[propA] || 100; // position at bottom if not specified
                    let orderB = filterSortOrder[propB] || 100; // position at bottom if not specified
                    // this.logger.info('SORTING filters', propA, propB, orderA, orderB, orderA - orderB, filterA, filterB);
                    return orderA - orderB;
                });
            }
            else {
                // no sorting required
                result.push({id: 'attributes', controls: optionControls, collapsed: false, collapsable: true});
            }
        }


        return result;
    }

    public buildCategoryNavControl(categoryIDHash:CategoryHash, baseCategoryId:number):FilterControlNav {
        const arr:FilterCategoryNav[] = [];
        const buildCategoryNavItem    = (cat:PepkorCategoryapiDataCategoryapiTreeInterface, active:boolean = false) => {
            return {
                id           : cat.id,
                name         : cat.name,
                parent_id    : cat.parent_id,
                level        : cat.level,
                urlKeyPath   : '/' + PageTypeEnum.PRODUCT_LIST + cat.urlKeyPath,
                active       : active,
                product_count: cat.product_count,
                children     : active ? cat.children_data?.map(childCat => buildCategoryNavItem(childCat)) : []
            };
        };
        const getCategory             = (id:number) => {
            const cat = categoryIDHash[id];
            if (cat) {
                if (cat.parent_id && cat.include_in_menu && cat.is_active) {
                    const active                        = cat.id === baseCategoryId;
                    const categoryNav:FilterCategoryNav = buildCategoryNavItem(cat, active);
                    arr.unshift(categoryNav);
                    getCategory(cat.parent_id);
                }
            }
        };
        getCategory(baseCategoryId);
        return {
            localeKey: 'filters.categories',
            id       : 'category-nav',
            type     : FilterType.Nav,
            options  : arr,
            collapsed: false,
            name     : 'categorynav',
        };
    }

    public buildCategoryLinksControl(categoryIDHash:CategoryHash, baseCategoryId?:number):FilterControlLinks {
        // this.storeSelect(s => s.magento.categoryTree).pipe(filter(tree => tree != null))
        // Build a hash of IDs for the base category and it parents
        const isCategoryPage = baseCategoryId != null;
        let options:FilterLink[];
        let label            = '';
        if (isCategoryPage) {
            // if category level is greater than 3 then display parent cat, else display current cat
            const displayCatId = categoryIDHash[baseCategoryId].level > this.implementationDataService.getProductFilterSettings().productFilterCategoryLinks?.parentLevel ? categoryIDHash[baseCategoryId].parent_id : baseCategoryId;
            label              = categoryIDHash[displayCatId].name;
            options            = categoryIDHash[displayCatId].children_data.map(child => {
                return {
                    id        : child.id,
                    name      : child.name,
                    parent_id : child.parent_id,
                    level     : child.level,
                    urlKeyPath: '/' + PageTypeEnum.PRODUCT_LIST + child.urlKeyPath,
                    active    : child.id === baseCategoryId
                };
            });
        }

        return {id: 'category-links', name: label, type: FilterType.Links, options, collapsed: false};
    }

    public buildOnSaleControl():FilterControlCheckbox {
        const options:FilterControlCheckboxOption[] = [{localeKey: 'filters.onSale', values: ['true']}];
        return {id: 'onsale', attributeCode: MagentoAttribute.on_sale, compareType: FilterControlCheckboxType.Select, type: FilterType.Toggle, options, collapsed: false};
    }

    public buildInStockControl():FilterControlCheckbox {
        const options:FilterControlCheckboxOption[] = [{localeKey: 'filters.inStock', values: ['true']}];
        return {id: 'instock', attributeCode: MagentoAttribute.stock_status, compareType: FilterControlCheckboxType.Select, type: FilterType.Toggle, options, collapsed: false};
    }

    public buildCategoryControl(categoryIDHash:CategoryHash, categoryAgg:Aggregation, baseCategoryId?:number):FilterControlCheckbox {
        if (!categoryAgg) return null;

        // Build a hash of IDs for the base category and it parents
        const excludeIDHash  = {};
        const isCategoryPage = baseCategoryId != null;
        if (isCategoryPage) {
            let currentCat = baseCategoryId;
            while (categoryIDHash[currentCat]) {
                excludeIDHash[categoryIDHash[currentCat].id] = true;
                currentCat                                   = categoryIDHash[currentCat].parent_id;
            }
        }

        if (categoryAgg.count < 2) return null;

        const options:FilterControlCheckboxOption[] = categoryAgg.options
            .filter(category => {
                // If enabled, only show the direct child categories of the parent category
                return this.implementationDataService.getProductFilterSettings().showCategoryChildrenOnly ?
                    categoryIDHash[category.value]?.parent_id === baseCategoryId : true;
            })
            .filter(category => category.count > 0)
            .filter(category => categoryIDHash[category.value] != null)
            .filter(category => {
                // If a category id is passed we can assume its a category page so
                // only show ones NOT related to the base category
                // Additionally we don't want to show the parent category if there is one
                if (isCategoryPage) {
                    return !excludeIDHash[category.value];
                }
                else {
                    // Otherwise we can assume its a search results page and
                    // only show root level categories returned
                    return categoryIDHash[category.value].level === 2;
                }
            })
            .map(category => ({label: category.label, values: [categoryIDHash[category.value].urlKeyPath]/*, count: category.count*/}));

        return {id: 'category', type: FilterType.Checkbox, attributeCode: MagentoAttribute.category_id, compareType: FilterControlCheckboxType.Select, options: options, collapsed: false};
    }

    public buildPriceControl(basePriceAgg:Aggregation, selectedAttr?:AttributeSelection[]):FilterControlPriceRange {
        if (!basePriceAgg) return null;
        const pricingSteps = [];
        // Items come in like so:
        /*
            {count: 12, label: "20-30", value: "20_30"}
            {count: 15, label: "30-40", value: "30_40"}
            {count: 40, label: "40-50", value: "40_50"}
        */
        basePriceAgg.options.forEach((item, index) => {
            const values = item.value.split('_');
            pricingSteps.push(Number(values[0]));
            if (index === basePriceAgg.options.length - 1) pricingSteps.push(Number(values[1]));
        });
        let selectedRange = [pricingSteps[0], pricingSteps[pricingSteps.length - 1]];
        if (selectedAttr != null) {
            const priceResults = selectedAttr.filter(item => item.attributeCode === MagentoAttribute.price);
            if (priceResults && priceResults.length > 0) {
                selectedRange = priceResults[0].values;
            }
        }
        return {id: 'priceRange', type: FilterType.PriceRange, attributeCode: MagentoAttribute.price, compareType: FilterControlCheckboxType.Range, pricingSteps: pricingSteps, selectedRange: selectedRange, collapsed: false};
    }

    public buildAttrOptions(allAttributeMetadata:GqlMagentoAttribute[], baseAggregations:Aggregation[], filteredAggregations:Aggregation[], filteredAggregationsHash:AggregationHash, selectedAttr?:AttributeSelection[], isFirstControl?:boolean):FilterControlCheckbox[] {
        if (!allAttributeMetadata || !filteredAggregations) return null;

        const result:FilterControlCheckbox[] = [];
        const selectedAttrHash               = ArrayUtil.straightHash(selectedAttr, item => item.attributeCode);
        const metadataHash                   = ArrayUtil.straightHash(allAttributeMetadata, item => item.attribute_code);


        const showFirstOnly    = this.implementationDataService.getProductFilterSettings().showFirstOnly;
        const defaultCollapsed = this.implementationDataService.getProductFilterSettings().defaultCollapsed;

        let count = 0;
        baseAggregations.forEach((baseAgg) => {
            if (baseAgg.attribute_code === MagentoAttribute.category_id || baseAgg.attribute_code === MagentoAttribute.price) return;
            // Exclude options disabled in the config
            if (this.implementationDataService.getProductFilterSettings().attributesFilter?.exclude?.indexOf(baseAgg.attribute_code) >= 0) return;

            const metadata = metadataHash[baseAgg.attribute_code];

            if (metadata?.frontend_input === MagentoAttributeFrontendInput.select || metadata?.frontend_input === MagentoAttributeFrontendInput.multiselect) {
                let aggregation = baseAgg;
                let addAttr     = false;
                // Display all of the original options if any selected
                if (selectedAttrHash[baseAgg.attribute_code]) {
                    addAttr = true;
                }
                // otherwise let use the current ones
                else if (filteredAggregationsHash[baseAgg.attribute_code]) {
                    addAttr     = true;
                    // Replace with the current one
                    aggregation = filteredAggregationsHash[baseAgg.attribute_code];
                }
                // if filtered aggregation is available use that as the base (show filtered filters)
                if (filteredAggregationsHash[baseAgg.attribute_code] && this.implementationDataService.getProductFilterSettings().funnelFilterAttributes) {
                    addAttr     = true;
                    // Replace with the current one
                    aggregation = filteredAggregationsHash[baseAgg.attribute_code];
                }

                // If a setting is available to override the current label then replace it with the value in the config
                const replaceConfig = this.implementationDataService.getProductFilterSettings()?.replace;
                if (replaceConfig && replaceConfig[baseAgg.attribute_code]) aggregation.label = replaceConfig[baseAgg.attribute_code];

                if (addAttr && aggregation.options && aggregation.options.length > 0) {
                    const optionHash:{ [attr_val:string]:FilterControlCheckboxOption } = {};
                    aggregation.options.forEach((option, i) => {
                        optionHash[option.value + ''] = {label: option.label, values: [option.label], position: i, count: option.count};
                    });
                    // Creating an hash of the indexes for sorting
                    // This is needed to sort the categories attribute list to the a master attribute list
                    // This hash is cached in the services (only runs the first time) to increase performance
                    if (!this.allAttributeIndexHash[baseAgg.attribute_code]) {
                        metadata.options.forEach((option, idx) => {
                            if (!this.allAttributeIndexHash[baseAgg.attribute_code]) this.allAttributeIndexHash[baseAgg.attribute_code] = {};
                            this.allAttributeIndexHash[baseAgg.attribute_code][option.label] = idx;
                        });
                    }
                    const allOptions                            = this.allAttributeIndexHash[baseAgg.attribute_code]
                    const options:FilterControlCheckboxOption[] = aggregation.options
                        .map(bucket => optionHash[String(bucket.value)])
                        .filter(option => option != null)
                        // sort by master product list (cached hash)
                        .sort((a, b) => {
                            const aMetaIndex = allOptions[a.label];
                            const bMetaIndex = allOptions[b.label];
                            return (!aMetaIndex || !bMetaIndex) ? -1 : aMetaIndex - bMetaIndex;
                        });
                    // .sort((a, b) => (a.position > b.position) ? 1 : -1);

                    const collapsed = defaultCollapsed ? !!defaultCollapsed : showFirstOnly ? !(isFirstControl && count === 0) : false;
                    if (options.length > 0) {
                        result.push({id: baseAgg.attribute_code, type: FilterType.Checkbox, label: aggregation.label, attributeCode: baseAgg.attribute_code, compareType: FilterControlCheckboxType.Select, options: options, collapsed: collapsed});
                        count++;
                    }
                }
            }
        });
        return result;
    }

}
