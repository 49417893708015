import {ProductListType} from '../../../product-list-page/abstract-product-list-page.component';
import {
    GAProductClick,
    GAProductDetail,
    GAProductFieldObject
} from '../../google/google-tag-manager/google-analytics.types';
import {StaticConfig} from '../../../shared/static-config';
import {IProductModel} from './iproduct.model';

export class GAProductModel {
    constructor(private product:IProductModel) {
    }

    getGAProductClick(list:ProductListType | string, categoryName?:string, option?:string):GAProductClick {
        const retObj:GAProductClick = {
            currencyCode: StaticConfig.PRODUCT_CURRENCY_CODE,
            click       : {
                actionField: {
                    list  : list,
                    option: option
                },
                products   : [this.getGAProduct()]
            }
        };
        if (categoryName) retObj.click.products[0].category = categoryName;
        return retObj;
    }

    getGAProductDetail(categoryName?:string):GAProductDetail {
        const retObj:GAProductDetail = {
            currencyCode: StaticConfig.PRODUCT_CURRENCY_CODE,
            detail      : {
                products: [this.getGAProduct()]
            }
        };
        if (categoryName) retObj.detail.products[0].category = categoryName;
        return retObj;
    }

    private getGAProduct():GAProductFieldObject {
        const retObj:GAProductFieldObject = {
            id   : this.product.sku,
            name : this.product.name,
            price: this.product.price
        };
        if (this?.product?.index) retObj.position = this?.product?.index;
        if (this.product.brandLogo) retObj.brand = this.product.brandLogo;
        return retObj;
    }
}
