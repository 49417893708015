import {ChangeDetectionStrategy, Component, Injector, Input} from '@angular/core';
import {AbstractFacadeComponent} from '../../../../../cross-platform/shared/ui/abstract-facade.component';

@Component({
    selector       : 'error-message',
    template       : `
        <div class="form-error-message" role="alert" [class.hidden]="!show">
            <facade-icon icon="icon-exclamation-triangle"></facade-icon>
            <div *ngIf="errorMsgHtml == null" class="message">
                <ng-content></ng-content>
            </div>
            <div *ngIf="errorMsgHtml != null" class="message" [innerHTML]="errorMsgHtml"></div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorMessageComponent extends AbstractFacadeComponent {

    @Input()
    show:boolean = true;

    @Input()
    errorMsgHtml:string;

    constructor(injector:Injector) {
        super(injector);
    }

    get name() {
        return 'ErrorMessageComponent';
    }
}
