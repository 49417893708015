import {Injectable, Injector} from '@angular/core';
import {LogoutPromptComponent} from './logout-prompt.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FakeLinkEvent} from '../../../../cross-platform/shared/ui/fake-link.directive';
import {
    AbstractLogoutPromptService
} from '../../../../cross-platform/auth/logout-prompt/abstract-logout-prompt.service';

@Injectable()
export class LogoutPromptService extends AbstractLogoutPromptService {

    private ngbModal:NgbModal;

    constructor(injector:Injector) {
        super(injector);
        this.ngbModal = injector.get(NgbModal);
    }

    async initiateLogout($event:FakeLinkEvent) {
        this.store.selectOnce(s => s.sharedCart.total_quantity)
            .subscribe(cartQuantity => {
                if (cartQuantity > 0 && this.implementationDataService.getPromptOnAbandonCart()) {
                    const modalRef = this.ngbModal.open(LogoutPromptComponent, {windowClass: 'logout-prompt', backdrop: true});
                    modalRef.result.then(result => {
                        if (result === LogoutPromptComponent.LOGOUT) this.confirmLogout($event);
                    }, () => {
                        // clicked on backdrop
                    });
                }
                else {
                    this.confirmLogout($event);
                }
            });
    }
}
