import {gql} from 'apollo-angular';
import {cartForCheckoutGQL} from '../../checkout-shared/queries/fetch-cart-for-checkout';

export const SET_SHIPPING_METHODS_ON_CART = (loggedIn:boolean) => gql`
    mutation setShippingMethodsOnCart($input:SetShippingMethodsOnCartInput) {
        setShippingMethodsOnCart(input: $input) {
            cart {
                ${cartForCheckoutGQL(loggedIn)}
            }
        }
    }
`;
