import {gql} from 'apollo-angular';

export const WP_ADD_HIT = gql`
    mutation wpAddHit($url:String) {
        wpAddHit (url: $url) {
            id
            url
            hitcount
        }
    }
`;
