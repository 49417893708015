<!--<header *ngIf="config.type === AuthType.STANDARD">-->
<!--    <h1>{{'authLogin.title' | translate}}</h1>-->
<!--</header>-->
<section>
    <div class="login-columns-holder">
        <div class="login-column">
            <form *ngIf="pageForm" [formGroup]="pageForm" (ngSubmit)="submitForm()" novalidate>
                <d-form-tel-input
                    [autocomplete]="'login-cellphone'"
                    [label]="'auth.cellPhone' | translate"
                    [showRequired]="true"
                    [formControlName]="'phone'"></d-form-tel-input>

                <d-form-pass-input
                    [autocomplete]="'login-password'"
                    [label]="'authLogin.password' | translate"
                    [showRequired]="true"
                    [formControlName]="'password'"
                ></d-form-pass-input>

                @if (plusMoreEnabled) {
                    <plus-more-button data-cy="plus-more-toggle" [disabled]="pageForm.disabled" (clicked)="togglePlusMore()"></plus-more-button>

                    @if (plusMoreOptIn) {
                        <d-form-select
                            [label]="'authReg.id.idType' | translate"
                            [showRequired]="true"
                            [formControlName]="'idType'"
                            [options]="idTypes">
                        </d-form-select>

                        <d-form-input
                            *ngIf="isIdNumber"
                            [label]="idLabel"
                            [type]="'text'"
                            [showRequired]="true"
                            [formControlName]="'rsaIdNumber'">
                        </d-form-input>

                        <d-form-input
                            *ngIf="isPassport"
                            [label]="idLabel"
                            [type]="'text'"
                            [showRequired]="true"
                            [formControlName]="'passport'">
                        </d-form-input>

                        <d-form-input
                            *ngIf="isAsylum"
                            [label]="idLabel"
                            [type]="'text'"
                            [showRequired]="true"
                            [formControlName]="'asylum'">
                        </d-form-input>

                        <d-form-input
                            *ngIf="isPassport || isAsylum"
                            [label]="'authReg.id.dob' | translate"
                            [placeHolder]="'dd/mm/yyyy'"
                            [type]="'text'"
                            [showRequired]="true"
                            [formControlName]="'dob'">
                        </d-form-input>
                    }
                }

                <error-message *ngIf="state && state.isError && state.errorStateName === 'invalidCredential'">
                    {{ 'authLogin.loginError' | translate }} <a [fakeLink]="urlService.buildUrlForAuthForgot(config.type, router.url)" (fakeClick)="handleLink($event)">{{ 'authLogin.loginError.forgotPasswordValidate' | translate }}</a>
                </error-message>
                <error-message *ngIf="state && state.isError && state.errorStateName === 'profileNotVerified'">
                    {{ 'authLogin.profileNotVerified' | translate }} <a [fakeLink]=" " (fakeClick)="handleEnterOtpLink($event)">{{ 'authLogin.profileNotVerified.enterOtp' | translate }}</a>
                </error-message>
                <error-message *ngIf="state && state.isError && state.errorStateName === 'passwordExpired'">
                    {{ 'authLogin.passwordExpired' | translate }} <a [fakeLink]="urlService.buildUrlForAuthForgot(config.type, router.url)" (fakeClick)="handleLink($event)">{{ 'authLogin.forgotPasswordValidate.resetPassword' | translate }}</a>
                </error-message>
                <error-message *ngIf="state && state.isError && state.errorStateName === null" [errorMsgHtml]="state.errorMessage"></error-message>

                <button type="submit" class="btn btn-primary btn-block" [attr.disabled]="pageForm.disabled ? 'disabled' : null">
                    <span *ngIf="!pageForm.disabled">{{ 'authLogin.login' | translate }}</span>
                    <span *ngIf="pageForm.disabled"><spinner></spinner></span>
                </button>

                <div class="forgot-pass-wrapper">
                    {{ 'authLogin.forgotPrefix' | translate }}
                    <a [fakeLink]="urlService.buildUrlForAuthForgot(config.type, router.url)" (fakeClick)="handleLink($event)" class="forgot-pass">{{ 'authLogin.forgotClickHere' | translate }}</a>
                </div>

                <div class="register-link-wrapper">
                    {{ 'authLogin.newTo' | translate }}
                    <a class="register-link" [fakeLink]="urlService.buildUrlForAuthRegister(config.type)" (fakeClick)="handleLink($event)">
                        {{ 'authLogin.registerLink' | translate }}<facade-icon class="fa icon icon-android-arrow-back flip"></facade-icon>
                    </a>
                </div>
            </form>
        </div>

        <div class="auth-form-footer">
            <div class="register-holder register-column" *ngIf="config.type === AuthType.STANDARD">
                <h4>{{ 'authLogin.notRegistered' | translate }}</h4>
                <p class="register-motivation" [innerHTML]="'authLogin.notRegisteredMotivation' | translate"></p>
                <a class="register-button btn btn-secondary btn-sm" [fakeLink]="urlService.buildUrlForAuthRegister(config.type)" (fakeClick)="handleLink($event)">{{ 'authLogin.clickToRegister' | translate }}</a>
            </div>
            <div class="register-holder" *ngIf="config.type === AuthType.CHECKOUT">
                <a class="register-button btn btn-secondary btn-sm" [fakeLink]="urlService.buildUrlForAuthRegister(config.type)" (fakeClick)="handleLink($event)">{{ 'authCheckoutLogin.registerLink' | translate }}</a>
                <p [innerHTML]="'authCheckoutLogin.registerLinkDescription' | translate"></p>
            </div>
            <div class="guest-holder" *ngIf="config.type === AuthType.CHECKOUT">
<!--                <section class="line-around">-->
<!--                    <div class="line"></div>-->
<!--                    <div class="title">Or</div>-->
<!--                    <div class="line"></div>-->
<!--                </section>-->
<!--                <a class="register-button btn btn-secondary btn-sm" [fakeLink]="urlService.buildUrlForCheckoutDelivery()" (fakeClick)="handleGuestCheckout($event)">{{ 'authCheckoutLogin.guestLink' | translate }}</a>-->
<!--                <p class="guest-link-description" [innerHTML]="'authCheckoutLogin.guestLinkDescription' | translate"></p>-->
            </div>
        </div>
        <recaptcha-message *ngIf="recaptchaNoticeEnabled"></recaptcha-message>
    </div>
</section>


