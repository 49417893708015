import {gql} from 'apollo-angular';

export const FORGOT_PASSWORD_VALIDATE = gql`
    mutation forgotPasswordValidate(
        $oneTimePin:String!,
        $cellphoneNumber:String!,
        $password:String!
    ) {
        forgotPasswordValidate(
            cellphone_number:$cellphoneNumber
            newPassword:$password
            otp:$oneTimePin
        ) {
            message
        }
    }
`;
