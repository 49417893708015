import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
    selector       : 'spinner',
    template       : `
        <span *ngIf="isSupported" class="svg-icon svg-baseline">
            <svg width="200px" height="200px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-rolling" style="background: none;">
                <circle cx="50" cy="50" fill="none" stroke="currentColor" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(359.942 50.0039 50.0039)">
                    <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
                </circle>
            </svg>
        </span>
        <facade-icon *ngIf="!isSupported" icon="icon-spinner fa-spin"></facade-icon>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent implements OnInit {
    isSupported = true;

    ngOnInit():void {
        if (window['ieV']) {
            this.isSupported = false;
        }
    }
}
