import {Component, ViewChild} from '@angular/core';
import {ControlValueAccessor, DefaultValueAccessor} from '@angular/forms';
import {AbstractFormComponent} from './abstract-form.component';

/**
 * Used to wrap a normal input, proxies all form methods to the underlying form component
 */
@Component({template: ''})
// tslint:disable-next-line:directive-class-suffix
export abstract class AbstractProxyFormComponent extends AbstractFormComponent {

    // Fetch the default accessor from the view, override if not using the DefaultValueAccessor e.g. select
    // | null added as a workaround for webpack warning: https://github.com/angular/angular-cli/issues/2034
    @ViewChild(DefaultValueAccessor, {static: true}) proxyFormElement:ControlValueAccessor | null;

    // Form access methods
    writeValue(obj:any):void {
        super.writeValue(obj);
        this.proxyFormElement.writeValue(obj);
    }

    registerOnChange(fn:any):void {
        this.proxyFormElement.registerOnChange(fn);
    }

    registerOnTouched(fn:any):void {
        this.proxyFormElement.registerOnTouched(fn);
    }

    setDisabledState?(isDisabled:boolean):void {
        this.proxyFormElement.setDisabledState(isDisabled);
    }

}
