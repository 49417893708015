import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FacadePlatform} from '../shared/facade-platform';
import {RuntimeErrorEnum} from './runtime-error.enum';
import {UrlService} from '../shared/url.service';
import {environment} from '../implementation/environment';
import {Router} from '@angular/router';
import {Logger} from '../shared/logger';
import {ExternalResponse} from '../external-data/external-response';
import {ResponseCode} from '../external-data/enum/response-code.enum';
import {BrowserOptions} from '@sentry/browser';
import {Store} from '@ngxs/store';
import {ClearStorage} from '../ngxs/runtime-error-plugin';
import {RegexUtils} from '../../../scripts/shared/regex-utils';
import {ngxsStoragePluginOptions} from '../ngxs/ngxs-storage.config';
import {StorageService} from '../shared/ui/storage/storage.service';
import {SentryLib} from './sentry.types';

// Note there is a recursive bug error that occurs with angular template errors
// https://github.com/angular/angular/issues/17010
// Note this appears to be fixed now, @ChrisBeech may have fixed it.

export class GraphQLResponseError extends Error {
    /**
     *
     * @param message The localised frontend message
     * @param serverMessage The message returned by the server
     * @param functionName The name of the RPC function that was called (e.g. "getAllBlogArticles")
     * @param errorCode For example a HTTP status code returned by the server.
     */
    constructor(public message, public serverMessage?:string, public functionName?, public errorCode?) {
        super(message);
    }
}

@Injectable()
export class RuntimeErrorHandlerService implements ErrorHandler {

    public hasRuntimeError$  = new BehaviorSubject(false);
    public runTimeErrorType$ = new BehaviorSubject(null);
    private runTimeErrorEncountered:boolean;

    private sentryLib:SentryLib;
    private sentryInitialised = false;

    private facadePlatform:FacadePlatform;
    private urlService:UrlService;
    private router:Router;
    private logger:Logger;
    private store:Store;
    private storageService:StorageService;

    constructor(private injector:Injector) {
    }

    inject() {
        if (!this.facadePlatform) {
            this.facadePlatform = this.injector.get(FacadePlatform);
            this.urlService     = this.injector.get(UrlService);
            this.router         = this.injector.get(Router);
            this.logger         = this.injector.get(Logger).getLogger('RuntimeErrorHandlerService');
            this.store          = this.injector.get(Store);
            this.storageService = this.injector.get(StorageService);
        }
    }


    public async init() {
        this.inject();
        if (environment.config.api.sentryDSN == null) {
            // No sentryDSN
        }
        else if (!this.facadePlatform.isSupported) {
            // Platform not supported
        }
        else if (!this.sentryInitialised) {
            this.logger.debug('Loading sentry as its not present');
            this.sentryLib = await import('@sentry/browser');

            this.sentryInitialised            = true;
            const sentryConfig:BrowserOptions = {
                dsn           : environment.config.api.sentryDSN,
                environment   : environment.envName,
                release       : environment.version,
                maxBreadcrumbs: 80,
                ignoreErrors  : [
                    'Non-Error exception captured',
                    'Loading chunk',
                    // https://plan.pepkorit.com/browse/CDWE-5384
                    'Google map SDK query timed out',
                    // Invalid phonenumber on forgotpassword:
                    'isOpaqueElement',
                    'isImage',
                    'URI malformed',
                    'Failed to fetch',
                    'isProxied',
                    '.flat is not a function', // Old unsupported chrome version
                    // Timeout errors in Recaptcha gets reported as non-error promise rejections in sentry:
                    // Sentry: https://github.com/getsentry/sentry-javascript/issues/2292
                    // Recaptcha Bug (main cause): https://github.com/DethAriel/ng-recaptcha/issues/123
                    'Non-Error exception captured',
                    'Non-Error promise rejection captured',
                    // Error caused because google maps callback because function is no longer available
                    'DynamicScriptLoaderServiceCallback',
                    // Firebase uncaught offline errors, ignore all
                    /.{0,}FirebaseError.+\(installations\/app-offline\).{0,}/g,
                    'TimeoutError: Transaction timed out due to inactivity',
                    // This happens from the firebase lib sometimes when calling for /webConfig
                    // We really don't care about other libs and their unhandled rejections
                    // This is a generic error you get from the fetch() library when you abort a request
                    'AbortError: AbortError',
                    'Uncaught (in promise): AbortError: AbortError',
                    // These need to be handled gracefully, see: https://www.notion.so/pepkorit/Gracefully-fix-quota-exceeded-firebase-error-f1c0c74825fa4985ba97c8ae4538be5e
                    'QuotaExceededError',
                    'UnknownError: Database deleted by request of the user',
                    'UnknownError: Attempt to get a record from database without an in-progress transaction',
                    'UnknownError: Attempt to delete a record from database without an in-progress transaction',
                    'UnknownError: Internal error creating database backend for indexedDB.open',
                    'Uncaught (in promise): UnknownError: Attempt to delete range from database without an in-progress transaction',
                    'AbortError: Registration failed - push service',
                    'AbortError: The transaction was aborted, so the request cannot be fulfilled.',
                    'Messaging: The notification permission was not granted and blocked instead.',
                    'NotAllowedError: Registration failed - permission denied',
                    'The notification permission was not granted and blocked instead',
                    'Object store cannot be found in the backing store',
                    // https://bugs.webkit.org/show_bug.cgi?id=197050
                    'An internal error was encountered in the Indexed Database server',
                    /* Facebook postMessage causing error in Sentry  */
                    `null is not an object (evaluating 'e.contentWindow.postMessage')`,
                    `Failed to execute 'createObjectStore' on 'IDBDatabase': The database is not running a version change transaction.`,
                    `Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.`,
                    `Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker`,
                    `Failed to store record in an IDBObjectStore: The transaction is inactive or finished.`,
                    `Failed to execute 'transaction' on 'IDBDatabase': One of the specified object stores was not found.`,
                    /* this happens when customers try to link ID numbers that does not have an account associated
                    * https://www.notion.so/pepkorit/Sentry-GQL-linkCustomerIdNumber-5-5k-Users-affected-ec77228fe319474ba9529293bae9cdf0
                    *  */
                    'GQL linkCustomerIdNumber()',
                    /* Service workers failed - should be allowed to silently fail */
                    `Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker`,
                    `We are unable to register the default service worker. Failed to register a ServiceWorker`,
                    `GQL mergeCarts(): Could not find a cart with ID`,
                    'The I/O read operation failed'
                ],
                beforeSend    : function (event, hint) {
                    const exception = hint.originalException;
                    if (exception instanceof GraphQLResponseError) {
                        event.fingerprint = [String(exception.message)];
                        if (exception.errorCode) event.fingerprint.push(String(exception.errorCode));
                        if (exception.functionName) event.fingerprint.push(String(exception.functionName));
                    }
                    return event;
                },
                allowUrls     : environment.config?.api?.sentryAllowUrls?.map(url => new RegExp(url)),
                normalizeDepth: 10,
            };

            this.sentryLib.init(sentryConfig);
        }
    }

    async handleError(error:Error) {
        this.inject();
        if (!this.runTimeErrorEncountered) {
            // Keep console.error and not the logger to avoid any further errors
            console.error('Runtime Error Handler: ', error);
            this.runTimeErrorEncountered = true;


            // There are some scenarios we don't want to send errors to sentry
            if (error.message && error.message.indexOf(`Loading chunk`) !== -1) {
                this.runTimeErrorType$.next(RuntimeErrorEnum.ERROR_LOADING_CHUNK);
                //return false;
            }
                // Sometimes there are unhandled rxjs subscriptions for external response.
            // No need to send this to sentry, same for http errors but not sure where those are coming from :/
            else if ((error && error.hasOwnProperty('responseCode')) || (error && error.hasOwnProperty('name') && error.name === 'HttpErrorResponse')) {
                // nothing else to do really
                this.logger.error('Skipping error deemed an uncaught external response');
                return false;
            }
            else if (error && error.hasOwnProperty('rejection') && error['rejection'].hasOwnProperty('message') && error['rejection']['message'] === 'Failed to fetch') {
                // Google tagmanager loading errors should not prevent users from browsing
                if (error && error.hasOwnProperty('stack')) {
                    if (new RegExp(RegexUtils.escapeToReg('https://www.googletagmanager.com/', 'g')).test(error.stack)) {
                        this.logger.error('Skipping this error when failing to fetch', error);
                        return false;
                    }
                }
            }
            else if (
                error &&
                error.message.indexOf('Uncaught (in promise): QuotaExceededError') !== -1 ||
                error.message.indexOf('Uncaught (in promise): UnknownError: Database deleted by request of the user') !== -1 ||
                error.message.indexOf('Uncaught (in promise): UnknownError: Attempt to get a record from database without an in-progress transaction') !== -1
            ) {
                // See ticket to fix gracefully https://www.notion.so/pepkorit/Gracefully-fix-quota-exceeded-firebase-error-f1c0c74825fa4985ba97c8ae4538be5e
                this.logger.error('Skipping error. This is most likely coming from firebase analytics due to push notification subscription.');
                return false;
            }
            else {
                await this.logExceptionObject(error);
            }
            this.hasRuntimeError$.next(true);

            // Clear caches / storage
            this.logger.debug('Clearing all local storage & in memory cache');

            // Resetting store
            this.store.dispatch(new ClearStorage());
            // We wanna avoid clearing state local storage customer information as it tells us if a customer "should" be logged in
            // We also avoid clearing the cartState which is manually persisted to localStorage
            (<Array<string>>ngxsStoragePluginOptions.key).forEach(key => {
                if (key !== 'customer') this.storageService.removeItem(key);
            });
        }
    }

    public async checkForHttpErrors(res:ExternalResponse) {
        this.inject();
        // We are only gonna log errors for checkout 400 / 500
        if (res?.hasOwnProperty('responseCode')) {
            if (this.urlService.isInCheckoutSection(this.router.url) || this.urlService.isUrlCheckoutCart(this.router.url)) {

                if (res.responseCode !== ResponseCode.SUCCESS) {
                    if (res?.httpError?.status === 500 || res?.httpError?.status === 400) {
                        await this.logExceptionObject(res);
                    }
                }

                // Inner object status check
                else if (res.responseCode === ResponseCode.SUCCESS && res?.apolloErrors?.length > 0) {
                    const status = res?.apolloErrors[0]?.extensions?.exception?.status;
                    // Lets ignore 400, 401, 404
                    if (status > 404) {
                        await this.logExceptionObject(res);
                    }
                }
            }
        }
    }

    // TODO: The below 2 really need to be combined
    public async addBreadcrumb(data:Object) {
        try {
            await this.init();
            this.sentryLib.addBreadcrumb({data: data});
        }
        catch (err) {
            this.logger.error('Error in the try block for logging to sentry:');
            this.logger.error(err);
        }
    }

    public async logExceptionObject(error:any) {
        // Now lets add additional info, put into try block to avoid any bad code
        try {
            await this.init();
            this.logger.info('Processing error into sentry');

            if (error != null) this.sentryLib.captureException(error);
        }
        catch (err) {
            this.logger.error('Error in the try block for logging to sentry:');
            this.logger.error(err);
        }
    }
}
