import {gql} from 'apollo-angular';

export const LOGIN_CUSTOMER = gql`
    mutation loginCustomer(
        $cellphoneNumber: String = "default"
        $password: String = "default"
    ) {
        loginCustomer(cellphone_number: $cellphoneNumber, password: $password) {
            user_id
            magento_token
        }
    }
`;
