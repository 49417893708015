import {Injectable} from '@angular/core';
import {Logger} from '../../../shared/logger';
import {StaticConfig} from '../../../shared/static-config';
import {DocumentRef} from '../../../shared/ui/document-ref';
import {WindowRef} from '../../../shared/ui/window-ref';
import {GoogleMapsApiService} from './google-maps-api.service';
import {environment} from '../../../implementation/environment';

@Injectable({providedIn: 'root'})
export class GoogleMapsScriptLoaderService {

    static cnt = 0;

    private loaded = false;

    constructor(private documentRef:DocumentRef,
                private windowRef:WindowRef,
                public logger:Logger,
                public googleMapsApiService:GoogleMapsApiService) {
        this.logger = this.logger.getLogger('DynamicScriptLoaderService');
    }

    loadGoogleMaps():Promise<void> {
        let src = environment.config.api.middlewareBasePath + '/api/proxy/google-maps?libraries=geometry,places';

        if (!this.scriptExists(src)) {
            this.logger.debug('Adding Maps Script:  ', src);
            return new Promise<void>((resolve, reject) => {
                const callbackName = 'DynamicScriptLoaderServiceCallback_' + GoogleMapsScriptLoaderService.cnt++;

                // NOTE: in order for this to work the apiUrl needs to be at the end of the url
                src += `${src.indexOf('?') === -1 ? '?' : '&'}callback=${callbackName}`;

                // Add the script tag
                const scriptTag = this.documentRef.createElement('script');
                scriptTag.setAttribute('type', 'text/javascript');
                scriptTag.setAttribute('src', src);
                scriptTag.setAttribute('async', 'async');

                scriptTag.onload = () => {
                    this.loaded = true;
                };
                const parent     = (this.documentRef.getElementsByTagName('head')[0] || this.documentRef.documentElement);

                // allow 30 seconds to timeout
                const timeout = setTimeout(() => {
                    if (!this.loaded) {
                        this.logger.debug(`Script loading timed out`);
                        this.windowRef.removeProperty(callbackName);
                        parent.removeChild(scriptTag);
                        reject('timeout');
                    }
                }, StaticConfig.SCRIPT_TAG_TIMEOUT);

                this.windowRef.addProperty(callbackName, () => {
                    this.logger.debug(`Callback for load executing: ${callbackName}()`);
                    this.windowRef.removeProperty(callbackName);
                    // On success, lets initialize the service to load the rest of the scripts
                    this.googleMapsApiService.initializeServices();
                    clearTimeout(timeout);
                    resolve();
                });

                parent.appendChild(scriptTag);
            });
        }
        else if (this.loaded) {
            return Promise.resolve();
        }
        else {
            // Wait until the script has been loaded before resolving
            return new Promise<void>((resolve, reject) => {
                let timeoutCount = 0;
                const interval   = setInterval(() => {
                    if (!this.loaded) {
                        timeoutCount++;
                        // Wait for 30 seconds before rejecting
                        if (timeoutCount >= 60) {
                            clearInterval(interval);
                            reject('timeout');
                        }
                    }
                    else {
                        clearInterval(interval);
                        resolve();
                    }
                }, 500);
            });
        }
    }

    private scriptExists(src:string):boolean {
        let scriptExists              = false;
        const children:HTMLCollection = this.documentRef.getElementsByTagName('head')[0].children;
        for (let i = 0; i < children.length; i++) {
            const child = children.item(i);
            if (child.tagName.toLowerCase() === 'script' && child['src']) {
                if (child['src'].toLowerCase().indexOf(src.toLowerCase()) === 0) {
                    scriptExists = true;
                    break;
                }
            }
        }
        return scriptExists;
    }
}
