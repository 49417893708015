import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {AbstractFacadeComponent} from '../../../cross-platform/shared/ui/abstract-facade.component';

@Component({
    selector       : 'no-content',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template       : `
        <inject-component componentClass="NoContentIncludeComponent"></inject-component>
    `
})
export class NoContentComponent extends AbstractFacadeComponent {

    constructor(injector:Injector) {
        super(injector);
    }

    get name() {
        return 'NoContentComponent';
    }
}
