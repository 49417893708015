import {gql} from 'apollo-angular';

export const FETCH_CUSTOMER_CART_CACHE = false;

export const FETCH_CUSTOMER_CART = gql`
    query customerCart {
        customerCart{
            id
            total_quantity
        }
    }
`;
