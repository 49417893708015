import {gql} from 'apollo-angular';

export const fullCartGQL = (hasPimSupport:boolean, loggedIn:boolean) => {
    return `
        id
        email
        ${(!loggedIn) ? '' : `
            applied_store_credit {
                applied_balance {
                    value
                }
                current_balance {
                    value
                }
            }
        `}
        items {
            id
            quantity
        
            prices {
                row_total_including_tax {
                    value
                }
                price {
                    value
                }
                row_total {
                    value
                }
                total_item_discount {
                    value
                }
            }
        
            ... on SimpleCartItem {
                selected_configurable_options {
                    option_label
                    value_label
                }
                product {
                    id
                    sku
                    price_range {
                        minimum_price {
                            final_price {
                                value
                            }
                            regular_price {
                                value
                            }
                        }
                    }
                    ... on SimpleProduct {
                        parent_product {
                            sku
                            name
                            description {
                                html
                            }
                            ${hasPimSupport ?
                            `media_gallery {
                                file_name
                                label
                                position
                                disabled
                            }` :
                            `image_name
                            image_name_label
                            product_image_names`
                            }
                            url_key
                            meta_title
                            meta_description
                            categories {
                                id
                            }
                        }
                    }
                }
            }
            itemTracking {
                item_category
                item_list_id
                item_list_name
            }
        }
        shipping_addresses {
            selected_shipping_method {
                carrier_code
                method_code
                amount {
                    value
                    currency
                }
            }
        }
        prices {
            applied_taxes {
                amount {
                    value
                }
            }
            discounts {
                amount {
                    value
                }
                label
            }
            grand_total {
                value
            }
            subtotal_including_tax {
                value            
            }
            subtotal_excluding_tax {
                value
            }
        }
        applied_coupons {
            code
        }
        total_quantity
    `;
};

export const FETCH_CART_MAX_CACHE = false;

export const FETCH_CART_MAX = (hasPimSupport:boolean, loggedIn:boolean) => {
    return gql`
        query cart_max($cartId:String!) {
            cart(cart_id: $cartId) {
                ${fullCartGQL(hasPimSupport, loggedIn)}
            }
        }
    `;
};
