import {IMetaPageData} from '../../../../../framework/src/cross-platform/seo/seo-service';
import {CubeBuiltInPageMapping} from '../cube-custom-page-mapping';

/**
 * This class is here to defined locale keys for non custom page, implementation specific
 * pages. For example custom account sections.
 * This class leverages the framework LocaleConventionUtil to generate the mappings.
 */
export class CubeImplementationLocaleConventionUtil {
    static seoPageAccountAckAccount = CubeImplementationLocaleConventionUtil.getSeoPage(CubeBuiltInPageMapping.account.ackAccount.localePageType, CubeBuiltInPageMapping.account.ackAccount.seoLocaleConvention);


    public static getSeoPage(pageType:string, localeSection:string):IMetaPageData {
        const result            = {title: `seo.${localeSection}.title`, description: `seo.${localeSection}.description`};
        result['localeSection'] = localeSection;
        result['pageType']      = pageType;
        return result;
    }
}
