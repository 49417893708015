import {HttpLink} from 'apollo-angular/http';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {environment} from '../../implementation/environment';
import {NextLink, Operation} from '@apollo/client/link/core/types';
import {InterceptorService} from '../interceptors/interceptor.service';
import {ErrorResponse, onError} from '@apollo/client/link/error';
import {HttpErrorResponse} from '@angular/common/http';
import {GraphqlConstants} from './graphql.contstants';
import {ImplementationDataService} from '../../implementation-config/implementation-data.service';


export function createApollo(httpLink:HttpLink, interceptorService:InterceptorService, implementationDataService:ImplementationDataService):ApolloClientOptions<any> {
    const errorLink = onError((errorResponse:ErrorResponse) => {
        // Check for a downtime header, this will result in a parse error
        //console.error('Inside error object: ', errorResponse);
        const isDowntime = (<HttpErrorResponse>errorResponse?.networkError)?.headers?.get('x-downtime');
        if (isDowntime === 'true') interceptorService.handleDowntimePodHeader();
    });


    // our custom "afterware" that checks each response and saves the sessionID
    // if it contains an 'Authorization' header
    const middlewareLink = new ApolloLink((operation:Operation, forward:NextLink) => {
        if (operation.operationName) {
            operation.setContext({
                uri: environment.config.api.middlewareBasePath + `/graphql?operationName=${operation.operationName}`
            });
        }
        return forward(operation)
            .map(response => {
                // Check for a version change in the headers
                const context = operation.getContext();
                const version = context.response.headers.get('facade-frontend-version');
                if (version != null && version !== '') interceptorService.handleVersionHeader(version);

                if (response?.errors?.length > 0) {
                    // Check for a 401 auth error
                    if (response?.errors[0]?.extensions?.exception?.status === 401 && implementationDataService.getAuthEnabled()) {
                        interceptorService.handleAuthenticationBounce();
                    }
                    // This can happen when a cart is completed on another device
                    if (response?.errors[0]?.extensions?.category === GraphqlConstants.NO_SUCH_ENTITY) {
                        if (response.errors[0]?.path?.length > 0 && response.errors[0]?.path[0] === GraphqlConstants.PATH_APPLY_COUPON_TO_CART) {
                            // ignore errors on applying coupons
                        }
                        else {
                            interceptorService.handleCartNoLongerExists();
                        }
                    }
                }
                return response;
            });
    });

    const http = httpLink.create({uri: environment.config.api.middlewareBasePath + '/graphql'});

    return {
        link : middlewareLink.concat(errorLink).concat(http),
        cache: new InMemoryCache(),
        //connectToDevTools: (environment.envName === 'local')
    };
}
