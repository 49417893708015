import {Injectable} from '@angular/core';

@Injectable()
export class ComponentDelegate {
    private register = {};

    registerComponent(name:string, comp:any) {
        this.register[name] = comp;
    }

    removeComponent(name:string) {
        delete this.register[name];
    }

    getComponent<T>(name:string):T {
        return this.register[name];
    }
}
