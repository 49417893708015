import {AbstractFormComponent} from './abstract-form.component';
import {Component, Injector, Input} from '@angular/core';
import {InstanceCounter} from '../shared/instance-counter';
import {ControlValueAccessor} from '@angular/forms';
import {ImplementationDataService} from '../implementation-config/implementation-data.service';
import {CountryPrefixCode} from './tel-input.utils';

@Component({template: ''})
export abstract class AbstractFormTelInputComponent extends AbstractFormComponent {

    @Input()
    label:string;

    @Input()
    showRequired:boolean;

    @Input()
    hideLabel:boolean;

    @Input()
    maxChars:number;

    @Input()
    placeHolder:string;

    @Input()
    stripLeadingZeros = true;

    @Input()
    autocomplete = 'off';

    @Input()
    countryPrefixCodes:CountryPrefixCode[];

    name             = InstanceCounter.getName('AbstractFormTelInputComponent');
    type             = 'tel';
    nullRegionSymbol = '??';

    nullCountrySelectorPrefixAdded:boolean;
    changeFunctionToCall:Function;

    implementationDataService:ImplementationDataService;

    constructor(injector:Injector) {
        super(injector);
        this.implementationDataService = injector.get(ImplementationDataService);
    }

    init() {
        super.init();
        if (this.countryPrefixCodes == null) {
            this.countryPrefixCodes = this.implementationDataService.getCountryPrefixCodes();
        }
    }

    // Strip spaces, leading zeros and combine the country code prefix with the number for output
    handleChange(event:any) {
        const countryPrefix:string = this.selectValue;
        let phoneNumber:string     = this.textInputValue;
        // First strip any spaces
        phoneNumber                = phoneNumber.replace(/\s+/g, '');
        this.textInput.writeValue(phoneNumber);

        if (this.stripLeadingZeros) {
            const regex = RegExp(`^0`);
            if (regex.test(phoneNumber)) {
                phoneNumber = phoneNumber.slice(1, phoneNumber.length);
                this.textInput.writeValue(phoneNumber);
            }
        }
        const returnVal = (countryPrefix === this.nullRegionSymbol || phoneNumber === '') ? null : countryPrefix + phoneNumber;
        this.changeFunctionToCall(returnVal);
    }

    // Split country code prefix from number and populate accordingly
    writeValue(obj:any):void {
        super.writeValue(obj);

        if (obj === null) return;
        if (typeof obj !== 'string' && typeof obj !== 'number') throw new Error('Only strings and numbers supported as input');

        const findMatchingInternationalPrefix = (phoneNumber:string) => {
            let result:CountryPrefixCode;
            for (let i = 0; i < this.countryPrefixCodes.length; i++) {
                const number = this.countryPrefixCodes[i];
                if (number.value !== this.nullRegionSymbol) {
                    const regex = RegExp(`^${number.value}`);
                    if (regex.test(phoneNumber)) {
                        result = number;
                        break;
                    }
                }
            }
            return result;
        };
        const addNullCountrySelectorPrefix    = () => {
            if (this.nullCountrySelectorPrefixAdded) return;
            this.nullCountrySelectorPrefixAdded = true;
            this.countryPrefixCodes.unshift({value: this.nullRegionSymbol, label: this.nullRegionSymbol, regionCode: this.nullRegionSymbol, selected: false});
        };

        const newPhNumber = String(obj);
        if (newPhNumber !== '' && newPhNumber != null) {
            let numberToSelect = findMatchingInternationalPrefix(newPhNumber);
            // Should nothing match, set selected item to: ??
            if (numberToSelect == null) {
                addNullCountrySelectorPrefix();
                numberToSelect = this.countryPrefixCodes[0];
            }
            this.countryPrefixCodes.forEach(number => number.selected = (number.value === numberToSelect.value));
            const numberWithoutCountryPrefix = (numberToSelect.value !== this.nullRegionSymbol) ? newPhNumber.replace(RegExp(`^${numberToSelect.value}`), '') : newPhNumber;

            this.textInput.writeValue(numberWithoutCountryPrefix);
        }
    }

    registerOnChange(fn:any):void {
        this.changeFunctionToCall = fn;
    }

    registerOnTouched(fn:any):void {
        this.selectInput.registerOnTouched(fn);
        this.textInput.registerOnTouched(fn);
    }

    setDisabledState?(isDisabled:boolean):void {
        this.selectInput.setDisabledState(isDisabled);
        this.textInput.setDisabledState(isDisabled);
    }

    abstract get selectInput():ControlValueAccessor | null;

    abstract get textInput():ControlValueAccessor | null;

    abstract get selectValue():string;

    abstract get textInputValue():string;
}
