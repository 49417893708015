import {Injectable} from '@angular/core';
import {Logger} from '../logger';
import {AckStore, StoreOutput} from '../../../../../generated/graphql';

@Injectable()
export class Sanitize {

    constructor(public logger:Logger) {
        this.logger = this.logger.getLogger('Sanitize');
    }

    public removeHtmlTags(source:string) {
        if (source) {
            return String(source).replace(/<[^>]+>/gm, '');
        }
        return source;
    }

    public sanitizeStore(store:StoreOutput):StoreOutput {
        try {
            // Only update if all caps already
            //store.name          = store.name && store.name.toUpperCase() === store.name ? this.sanitizeName(store.name) : store.name;
            store.description      = store.description && store.description.toUpperCase() === store.description ? this.sanitizeName(store.description) : store.description;
            store.telephone_number = this.sanitizePhoneNumberForDisplay(store.telephone_number);
            store.business_hours   = this.sanitizeTradingHoursForDisplay(store.business_hours);

            // Create a single node address line
            /*let addressParts = [store.address1, store.address2, store.address3, store.address4, store.suburb, store.city, store.province, store.postal_code]
                .map(part => this.sanitizeName(part))
                .filter(part => part != null && part !== '' && part !== ' ');*/
            let addressParts = store.address.split(',')
                .filter(part => part !== '' && part !== ' ' && part !== '  ')
                .map(part => this.sanitizeName(part));

            const testJoin = addressParts.join(',').toLowerCase();

            // Look for duplicated entries in free text in other parts
            // e.g. address1 = 10 Devon Cape Town, city = cape town
            addressParts = addressParts.filter(part => {
                part        = part.toLowerCase();
                const find1 = testJoin.indexOf(part);
                const find2 = testJoin.lastIndexOf(part);
                // There should only be 1 match
                return find1 === find2;
            });

            store.address = addressParts.join(', ');
        }
        catch (err) {
            // No need to break everything is something missed here
            this.logger.error('Error sanitizing store: ', err);
        }
        return store;
    }

    public sanitizeAckStore(store:AckStore):AckStore {
        try {
            // Only update if all caps already
            store.branchName      = store.branchName && store.branchName.toUpperCase() === store.branchName ? this.sanitizeName(store.branchName) : store.branchName;
            store.branchTelephone = this.sanitizePhoneNumberForDisplay(store.branchTelephone);
            //store.businessHours   = this.sanitizeTradingHoursForDisplay(store.businessHours);
        }
        catch (err) {
            // No need to break everything is something missed here
            this.logger.error('Error sanitizing store: ', err);
        }
        return store;
    }

    public removeNonDisplayCharacters(str:string):string {
        if (str) {
            str = str.replace(/[↵]/g, '');
        }
        return str;
    }

    public sanitizeName(name:string):string {
        name                = this.removeNonDisplayCharacters(this.uppercaseWords(name));
        const reservedWords = ['PEP', 'PEPcell', 'ERF', ' of ', ' is ', ' the ', ' in ', ' a '];

        if (name != null && typeof name === 'string') {
            const lowerName = name.toLowerCase();

            reservedWords.forEach(word => {
                const index = lowerName.indexOf(word.toLowerCase());
                // Has the reserved word
                if (index !== -1) {
                    // Replace the word by creating 3 strings
                    name = name.substr(0, index) + word + name.substr(index + word.length);
                }
            });
        }
        return name;
    }

    public sanitizePhoneNumberForDisplay(phoneNumber:string):string {
        if (phoneNumber) {
            // Remove anything but numbers
            phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

            // We now know its an SA number so can do something
            // 27719257889
            if (phoneNumber.indexOf('27') === 0 && phoneNumber.length === 11) {
                phoneNumber = `+27 (0)${phoneNumber.substr(2, 2)} ${phoneNumber.substr(4, 3)} ${phoneNumber.substr(7)}`;
            }
            // 270719257889, still valid in most phones
            else if (phoneNumber.indexOf('270') === 0 && phoneNumber.length === 12) {
                phoneNumber = `+27 (0)${phoneNumber.substr(3, 2)} ${phoneNumber.substr(5, 3)} ${phoneNumber.substr(8)}`;
            }
            if (phoneNumber === '') phoneNumber = null;
        }
        return phoneNumber;
    }

    public sanitizeTradingHoursForDisplay(tradingHours:string):string {
        if (tradingHours && tradingHours.length > 2 && tradingHours.indexOf('\\n') !== -1) {
            return tradingHours.split('\\n').join('<br />');
        }
        return tradingHours;
    }

    public uppercaseWords(name:string):string {
        if (name) {
            // Lowercase everything
            name = name.toLowerCase();

            // Split up words
            const parts = name.split(' ');
            // Uppercase all words
            parts.forEach((part, i) => parts[i] = part.charAt(0).toUpperCase() + part.substr(1));

            name = parts.join(' ');
        }
        return name;
    }

    public cleanObject(data:object, clearEmptyStrings = true, clearEmptyArrays = true, clearObjectsAllNullKeys = true) {
        if (data != null) {
            if (typeof data === 'string' && data === '') {
                return clearEmptyStrings ? null : data;
            }
            else if (typeof data === 'number' || typeof data === 'boolean') {
                return data;
            }
            else if (data instanceof Array) {
                const newArr = [];
                data.forEach(item => {
                    const checked = this.cleanObject(item, clearEmptyStrings, clearEmptyArrays, clearObjectsAllNullKeys);
                    if (checked != null) newArr.push(checked);
                });
                return clearEmptyArrays && newArr.length === 0 ? null : newArr;
            }
            else if (typeof data === 'object') {
                const newObj = {};
                Object.keys(data).forEach(key => {
                    const checked = this.cleanObject(data[key], clearEmptyStrings, clearEmptyArrays, clearObjectsAllNullKeys);
                    if (checked != null) newObj[key] = checked;
                });
                return clearObjectsAllNullKeys && Object.keys(newObj).length === 0 ? null : newObj;
            }
        }
        return data;
    }
}
