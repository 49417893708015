import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

export interface FakeLinkEvent {
    href:string;
    newTab:boolean;
    isBackLink:boolean;
    // Used to bypass opening of a new tab for urls that are
    // for another domain which default to a new window
    bypassNewTab?:boolean;
    replaceState?:boolean;
}

@Directive({
    selector: 'a[fakeLink]'
})
export class FakeLinkDirective {
    @HostBinding()
    public href:string;

    // This improves performance on external pages, also a security concern if not there.
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    @HostBinding('attr.rel')
    rel = 'noopener';

    @Output()
    public fakeClick = new EventEmitter<FakeLinkEvent>();

    @Input()
    set fakeLink(value:string) {
        this.href = value;
    }

    @Input()
    isBackLink = false;

    @Input()
    disableLink = false;

    constructor(public el:ElementRef) {
    }

    @HostListener('click')
    onClickListener() {
        if (this.disableLink === true) return false;
        this.fakeClick.emit({href: this.href, isBackLink: this.isBackLink, newTab: this.el.nativeElement.getAttribute('target') === '_blank'});
        return false;
    }
}
