import {AbstractFacadeService} from '../../external-data/abstract-facade.service';
import {FakeLinkEvent} from '../../shared/ui/fake-link.directive';
import {Injector} from '@angular/core';
import {FacadePlatform} from '../../shared/facade-platform';
import {ImplementationDataService} from '../../implementation-config/implementation-data.service';
import {CustomerAuth} from '../customer-auth.state';
import Logout = CustomerAuth.Logout;

export abstract class AbstractLogoutPromptService extends AbstractFacadeService {
    facadePlatform:FacadePlatform;
    implementationDataService:ImplementationDataService;

    abstract initiateLogout($event:FakeLinkEvent);

    constructor(injector:Injector) {
        super(injector);
        this.facadePlatform            = injector.get(FacadePlatform);
        this.implementationDataService = injector.get(ImplementationDataService);
    }

    confirmLogout($event:FakeLinkEvent) {
        this.store.dispatch(new Logout());
        this.facadePlatform.handleLink($event);
    }

    getName():string {
        return 'LogoutPromptService';
    }
}
