import {gql} from 'apollo-angular';
import {
    ImplementationFilterSettings,
    ProductListUrlSerializer
} from '../../../product-list-page/product-list-url-serializer';
import {GqlMagentoAttribute} from '../../../../../../generated/graphql';
import {CategoryHash} from '../magento.reducer.types';


const buildSort = (serializer:ProductListUrlSerializer) => {
    return serializer.sortField ? `{${serializer.sortField}:${(serializer.sortDescending) ? 'DESC' : 'ASC'}}` : `{}`;
};

export const GET_PRODUCT_LIST_AND_AGGREGATIONS_CACHE = true;

export const GET_PRODUCT_LIST_AND_AGGREGATIONS = (serializer:ProductListUrlSerializer, restProductAttributes:GqlMagentoAttribute[], categoryUrlKeyHash:CategoryHash, hasPimSupport:boolean, implementationSettings:ImplementationFilterSettings) => gql`
    query products_listAndAggregations {
        products(
            filter: ${serializer.buildGraphQLFilter(restProductAttributes, categoryUrlKeyHash, implementationSettings)}
            search: ${(serializer.formattedSearchTerm != null ) ? JSON.stringify(serializer.formattedSearchTerm) /* JSON used to properly escape */ : '""'}
            sort: ${buildSort(serializer)}
            pageSize: ${serializer.pageSize}
            currentPage: ${serializer.currentPage}
        ) {
            items {
                __typename
                id
                sku
                stock_status
                name
                description {
                    html
                }
                product_attribute
                product_decal

                ${
                hasPimSupport ?
                `media_gallery {
                    file_name
                    label
                    position
                    disabled
                }` :
                `image_name
                image_name_label
                product_image_names`
                }

                price_range {
                    minimum_price {
                        ...AllPriceFields
                    }
                }
                url_key
                meta_title
                meta_description

                categories {
                    id
                }
            }
            total_count
            aggregations(filter: {category:{includeDirectChildrenOnly: true}}) {
                attribute_code
                count
                label
                options {
                    count
                    label
                    value
                }
            }
            sort_fields {
                default
                options {
                    label
                    value
                }
            }
        }
    }

    fragment AllPriceFields on ProductPrice {
        final_price {
            value
        }
        regular_price {
            value
        }
    }
`;
