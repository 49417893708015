export class InstanceCounter {
    private static cnt = 0;


    public static getInstance() {
        return InstanceCounter.cnt++;
    }

    public static getName(prefix:string):string {
        return prefix + InstanceCounter.getInstance();
    }
}
