import {gql} from 'apollo-angular';

export const WP_SEARCH_CACHE = true;

export const WP_SEARCH = gql`
    query wpSearch(
        $searchTerm: String
        $website: String
        $perPage: String
        $page: String
    ) {
        wpSearch(
            search: $searchTerm
            website: $website
            per_page: $perPage
            page: $page
        ) {
            hits {
                hits {
                    _source
                }
                total {
                    value
                }
            }
            error
            status
        }
    }
`;
