import {Component, Injector, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomPageData, CustomPageRender} from '../custom-pages/custom-page.types';
import {ImplementationDataService} from '../implementation-config/implementation-data.service';
import {LocaleConventionUtil} from '../locale/locale-convention-util';
import {AbstractFacadeComponent} from '../shared/ui/abstract-facade.component';
import {WindowRef} from '../shared/ui/window-ref';
import {GTMActions} from '../external-data/google/google-tag-manager/gtm.actions';

export interface NoContentLink {
    href:string;
    localeKey:string;
    newTab?:boolean;
}

/*
TODO:
Add url checking against redirects
Run redirect
Test
*/

@Component({template: ''})
export class AbstractNoContentIncludeComponent extends AbstractFacadeComponent implements CustomPageRender {

    @Input()
    noPadding = false;
    ready     = false;

    windowRef:WindowRef;
    implementationDataService:ImplementationDataService;
    links$:Observable<NoContentLink[]>;

    constructor(injector:Injector) {
        super(injector);
        this.windowRef                 = injector.get(WindowRef);
        this.implementationDataService = injector.get(ImplementationDataService);
        this.links$                    = this.implementationDataService.getNoContentLinks();
    }

    initAfterAppReady() {
        super.initAfterAppReady();
        this.ready = true;
        this.store.dispatch(new GTMActions.TrackEvent404(new URL(this.windowRef.href)));
        this.setMetaForPage();
        this.changeDetectorRef.detectChanges();
    }

    setData(value:CustomPageData<any>):void {
        // nothing to do here
    }

    setMetaForPage() {
        this.seoService.populatePageDataAutoTranslate(LocaleConventionUtil.seoPage404, true, null);
    }

    get name() {
        return 'NoContentComponent';
    }
}
