import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {AbstractNewsletterSignUpComponent} from '../../../cross-platform/newsletter/abstract-newsletter-sign-up.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NewsletterSignUpModalComponent} from './newsletter-sign-up-modal.component';

@Component({
    selector       : 'newsletter-sign-up',
    templateUrl    : 'newsletter-sign-up.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsletterSignUpComponent extends AbstractNewsletterSignUpComponent {
    private ngbModal:NgbModal;
    private modalRef:NgbModalRef;

    constructor(injector:Injector) {
        super(injector);
        this.ngbModal = injector.get(NgbModal);
    }

    launchModal(email?:string) {
        this.modalRef = this.ngbModal.open(NewsletterSignUpModalComponent, {windowClass: 'newsletter-signup-popup'});
        const comp    = this.modalRef.componentInstance as NewsletterSignUpModalComponent;
        comp.populate(email);
        this.modalRef.result
            .then(
                result => {
                    this.handleModalClose(result);
                },
                other => {
                    this.handleModalClose(other);
                }
            );
    }

    dismissModal() {
        // dismiss here
        if (this.modalRef) {
            this.modalRef.close(false);
        }
    }
}
