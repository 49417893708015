import {Injectable} from '@angular/core';
import {first} from 'rxjs/operators';
import {CatalogDataCategoryTreeInterface} from '../magento.types';
import {Store} from '@ngxs/store';
import {RootState} from '../../../ngxs/root.state';

//import * as fs from 'fs';

interface ICategoryKeywordSearchable extends CatalogDataCategoryTreeInterface {
    keywords?:string[];
    url_key?:string;
}

export interface CatSearchItem {
    id?:number;
    //parent_id?:number;
    product_count:number;
    name:string;
    keywords:string[];
}

@Injectable()
export class CategorySearchService {

    private flatSearchableList:CatSearchItem[];
    private _fuse:Fuse<CatSearchItem>;
    private initPromise;

    private Fuse;

    constructor(private store:Store) {
    }

    async init():Promise<any> {
        if (!this.initPromise) {
            this.initPromise = new Promise((resolve, reject) => {
                if (!this.flatSearchableList) {
                    this.store.select((s:RootState) => s.magento.categoryTree)
                        .pipe(first(tree => tree != null))
                        .subscribe(tree => {
                            this.flatSearchableList = this.parse(tree.children_data);
                            resolve(null);
                            this.initPromise = null;
                        });
                }
                else {
                    resolve(null);
                    this.initPromise = null;
                }
            });
        }
        if (!this.Fuse) {
            const fuse = await import('fuse.js');
            this.Fuse  = fuse.default;
        }
        return this.initPromise;
    }

    async search(query:string, maxResultSet?:number):Promise<CatSearchItem[]> {
        await this.init();
        const results = this.fuse.search(query);
        return (maxResultSet) ? results.slice(0, maxResultSet) : results;
    }

    // For testing
    consumeCategories(categoryData:ICategoryKeywordSearchable) {
        this.flatSearchableList = this.parse(categoryData.children_data);
        //console.log('this.flatList:', JSON.stringify(this.flatList));
        //fs.writeFileSync('export.json', JSON.stringify(this.flatSearchableList, null, ' '));
    }

    parse(data:Array<ICategoryKeywordSearchable>, categoryNames?:string[]):CatSearchItem[] {
        if (!categoryNames) categoryNames = [];
        let parsedArr:CatSearchItem[] = [];

        for (let i = 0; i < data.length; i++) {
            const item = data[i];

            // Only search active categories
            if (item.is_active) {
                // Keywords are already added in the backend data
                // bolster these with the parent category names to boost searchability
                let keywords = (!item.keywords) ? [] : item.keywords;
                keywords     = keywords.concat(categoryNames);

                // For top level categories, add it's own name
                // to boost it's presence in search
                if (categoryNames.length === 0) {
                    keywords.push(item.name);
                }

                parsedArr.push({
                    id: item.id,
                    //parent_id    : item.parent_id,
                    product_count: item.product_count,
                    name         : item.name,
                    keywords     : keywords
                });

                // Recursively parse children the same way
                // Add this category to the list of categories
                if (item.children_data && item.children_data.length > 0) {
                    parsedArr = parsedArr.concat(this.parse(
                        item.children_data,
                        [item.name].concat(categoryNames)
                    ));
                }
            }
        }
        return parsedArr;
    }


    get fuse() {
        if (!this._fuse) {
            this._fuse = new this.Fuse(
                this.flatSearchableList,
                {
                    //includeScore      : true, // For debugging results
                    tokenize: true,
                    //threshold         : 0.6,  // This make no difference when tokenize is on
                    //location          : 0,    // This make no difference when tokenize is on
                    //distance          : 0,    // This make no difference when tokenize is on
                    shouldSort        : true,
                    maxPatternLength  : 32,
                    minMatchCharLength: 1,
                    keys              : [
                        'name',
                        'keywords'
                    ]
                }
            );
        }
        return this._fuse;
    }
}
