import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Injector, OnDestroy, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {InstanceCounter} from '../shared/instance-counter';
import {AbstractFacadeComponent} from '../shared/ui/abstract-facade.component';

export function BuildCustomFormElementProvider(ClassRef) {
    return {
        provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ClassRef), multi: true
    };
}

/**
 * Used to wrap a normal input, proxies all form methods to the underlying form component
 */
@Component({template: ''})
export abstract class AbstractFormComponent extends AbstractFacadeComponent implements ControlValueAccessor, OnDestroy {

    @Output()
    blur = new EventEmitter();

    @Output()
    focus = new EventEmitter();

    // We create an instance so we don't have runtime errors till its available
    control:AbstractControl = new FormControl();
    name                    = InstanceCounter.getName('AbstractFormComponent_');

    isDestroyed = false;

    constructor(public injector:Injector) {
        super(injector);
    }

    ngOnDestroy() {
        this.isDestroyed = true;
    }

    // Form access methods
    writeValue(obj:any):void {
        // We have to wait 1 cycle here as its not immediately available it seems
        // https://github.com/angular/angular/issues/7391
        setTimeout(() => {
            // This is required when there is an ngIf associated with the element
            // This is a fix but still worrying as there might be a memory leak :(
            if (this.isDestroyed) return;

            this.control = this.injector.get(NgControl).control;
            this.onControlChange();
            this.injector.get(ChangeDetectorRef).detectChanges();
            //console.log('=============>>>', this.control);
        });
    }

    abstract registerOnChange(fn:any):void;

    abstract registerOnTouched(fn:any):void;

    abstract setDisabledState?(isDisabled:boolean):void;

    onControlChange() {
    }

    setFocus() {
    }
}
