import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CrossPlatformModule} from '../../../../../framework/src/cross-platform/cross-platform.module';
import {FacadeFormsModule} from '../../../../../framework/src/desktop/app/forms/facade-forms.module';
import {GlobalSharedUIModule} from '../../../../../framework/src/desktop/app/shared/ui/global-shared-ui.module';
import {AppModule, translateConfig} from '../../../../../framework/src/desktop/app/app.module';
import {CrossPlatformImplementationAppModule} from '../../cross-platform/cross-platform-implementation-app.module';
import {InjectComponentService} from '../../../../../framework/src/cross-platform/inject-component/inject-component.service';
import {PepDesktopHeaderComponent} from './header/pep-desktop-header.component';
import {AppComponent} from '../../../../../framework/src/desktop/app/app.component';
import {PepNoContentIncludeComponent} from './no-content-page/pep-no-content-include.component';
import {
    ImplementationDataService
} from '../../../../../framework/src/cross-platform/implementation-config/implementation-data.service';
import {FacadePlatform} from '../../../../../framework/src/cross-platform/shared/facade-platform';


const injectableComponents           = [
    PepDesktopHeaderComponent,
    PepNoContentIncludeComponent
];

const injectableComponentsToRegister = [
    {className: 'DesktopHeaderComponent', componentClass: PepDesktopHeaderComponent},
    {className: 'NoContentIncludeComponent', componentClass: PepNoContentIncludeComponent}
];


@NgModule({
    imports        : [
        AppModule,

        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot(translateConfig),

        CrossPlatformModule,
        FacadeFormsModule,

        CrossPlatformImplementationAppModule.forRoot(),

        FacadeFormsModule,

        RouterModule,
        GlobalSharedUIModule
    ],
    declarations   : [

        // Injectable components
        ...injectableComponents
    ],
    exports        : [],
    providers      : [],
    bootstrap      : [AppComponent]
})
export class DesktopImplementationAppModule {
    constructor(injectComponentService:InjectComponentService,
                private implementationDataService:ImplementationDataService,
                private facadePlatform:FacadePlatform) {
        injectComponentService.registerOverrides(injectableComponentsToRegister);
        this.registerRunTimeModules();
    }

    async registerRunTimeModules() {
        this.implementationDataService.registerGetCustomModulePathFromName(async (moduleName) => {
            let module;
            switch (moduleName) {
                case 'donation':
                    module = await import('../../../../../framework/src/desktop/app/wp-content/components/wp-donation/wp-donation.module');
                    module = module['WpDonationModule'];
                    break;
                case 'my-custom-embed-comp':
                    module = await import('../../cross-platform/test/test-custom-embed.module');
                    module = module['TestCustomEmbedModule'];
                    break;
                case 'call-me-back-desktop':
                    module = await import('./custom-pages/pep-money/call-me-back/call-me-back.module');
                    module = module['CallMeBackModule'];
                    break;
                case 'cellular-contracts':
                    module = await import('../../../../../framework/src/desktop/app/wp-content/components/wp-cellular-contracts/wp-cellular-contracts.module');
                    module = module['WpCellularContractsModule'];
                    break;
            }
            return module;
        });
    }
}
