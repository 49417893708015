import {Injectable} from '@angular/core';
import {WindowRef} from './window-ref';

@Injectable()
export class NavigatorRef {
    constructor(public windowRef:WindowRef) {
    }

    get nativeNavigator():any {
        return this.windowRef.navigator;
    }

    get serviceWorker():ServiceWorkerContainer {
        return this.nativeNavigator.serviceWorker;
    }

    get standalone():boolean {
        return this.nativeNavigator.standalone;
    }

}
