<div *ngIf="!breadcrumbs" class="ol"></div>
<ol *ngIf="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
    <ng-container *ngFor="let crumb of breadcrumbs; let index = index">
        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
            <a
                itemscope itemtype="http://schema.org/Thing" itemprop="item" [attr.itemid]="crumb.url"
                [fakeLink]="crumb.url"
                (fakeClick)="handleLink($event)"
                [isBackLink]="true"
                [disableLink]="index+1 === breadcrumbs.length"
                [ngClass]="((index+1) != breadcrumbs.length) ? '' : 'last-link'">
                <span itemprop="name" [innerHTML]="crumb.localeKey ? (crumb.localeKey | translate) : crumb.label"></span>
            </a>
            <meta itemprop="position" content="{{index+1}}" />
        </li>
        <span *ngIf="(index+1) != breadcrumbs.length" class="divider">/</span>
    </ng-container>
</ol>
