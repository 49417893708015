import {gql} from 'apollo-angular';

export const FETCH_STORE_BY_BRANCH_CODE_CACHE = true;

export const FETCH_STORE_BY_BRANCH_CODE = gql`
    query storeByBranchCode($branchCode:String!) {
        storeByBranchCode(
            branchCode:$branchCode
        ) {
            branchCode
            branchName
            branchNameUrlKey
            clickAndCollectEnabled
            addressLine1
            addressLine2
            suburb
            suburbUrlKey
            city
            cityUrlKey
            province
            provinceUrlKey
            locDivName
            locDivNameUrlKey
            postalCode
            countryName
            countryNameUrlKey
            countryCode
            branchTelephone
            businessHours {
                ClosingTime
                OpeningTime
                Weekday
            }
            distance
        }
    }
`;
