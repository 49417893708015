/**
 * Utility class to determine webP support
 * Additionally adds a css class onto the body for CSS help
 **/
import {LoggerImplCore, LogLevel} from '../shared/logger-impl-core';

export class WebPManager {

    private static _lossy     = false;
    private static _lossless  = false;
    private static _alpha     = false;
    private static _animation = false;

    // this excludes animation
    private static _supported = false;
    private static _logger:LoggerImplCore;


    public static testForSupport():Promise<any> {
        const logger = WebPManager.getLogger();
        return new Promise(resolve => {
            logger.debug('testing webP now ---------------------');
            let completed = 0;

            const kTestImages = {
                _lossy    : 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
                _lossless : 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
                _alpha    : 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
                _animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
            };

            const completeCB = () => {
                completed++;
                if (completed === 4) {
                    WebPManager._supported = WebPManager._lossy && WebPManager._lossless && WebPManager._alpha;
                    WebPManager.appendToBody();
                    resolve(null);
                }
            };

            ['_lossy', '_lossless', '_alpha', '_animation'].forEach(feature => {
                const img   = new Image();
                img.onload  = () => {
                    WebPManager[feature] = (img.width > 0) && (img.height > 0);
                    logger.debug(`webP result for ${feature} is: ${WebPManager[feature]}`, img);
                    completeCB();
                };
                img.onerror = () => {
                    this[feature] = false;
                    logger.debug(`webP result for ${feature} is: false`);
                    completeCB();
                };
                img.src     = 'data:image/webp;base64,' + kTestImages[feature];
            });
        });
    }

    private static appendToBody() {
        const body = document.body || document.getElementsByTagName('body')[0];
        if (WebPManager.supported) body.classList.add('webp');
        if (WebPManager.fullSupport) body.classList.add('webp-full');
    }

    public static get supported():boolean {
        return WebPManager._supported;
    }

    public static get fullSupport():boolean {
        return WebPManager._supported && WebPManager._animation;
    }

    public static get animation():boolean {
        return WebPManager._animation;
    }

    public static convertPathIfSupported(path:string) {
        if (path && WebPManager.supported) {
            if (path.indexOf('.') !== -1) {
                path = path.substr(0, path.lastIndexOf('.'));
            }
            path += '.webp';
        }
        return path;
    }

    private static getLogger() {
        if (WebPManager._logger == null) {
            WebPManager._logger          = new LoggerImplCore().getLogger('WebPManager');
            WebPManager._logger.logLevel = LogLevel.NONE;
        }
        return WebPManager._logger;
    }
}
