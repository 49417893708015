import {ChildProductModel} from '../external-data/magento/elasticsearch/child-product.model';


export interface ProductInteractiveAttr {
    index:number;
    default_frontend_label:string;
    code:string;
    value:string;
    label:string;
    simpleProduct:ChildProductModel;
    variant?:ChildProductModel;
}

export interface SelectableAttributes {
    label:string;
    code:string;
    values:ProductInteractiveAttr[];
    parent?:string;
    parentRef?:SelectableAttributes;
    display?:boolean;
    selectFirstByDefault?:boolean;
    selected?:number;
}

export interface ProductInteractiveAttributeConfig {
    name?:string;
    label?:string;
    parent?:string;
    display?:boolean;
    selectFirstByDefault?:boolean;
}

export enum ProductModuleType {
    INFO           = 'info',
    PROMOTIONS     = 'promotions',
    ATTRIBUTES     = 'attributes',
    WASHCARE_GUIDE = 'guide',
    STOCK          = 'stock',
    SHARE          = 'share',
    REVIEWS        = 'reviews',
    BUTTON         = 'button'
}

export interface ProductModuleConfig {
    id:string;
    title:string;
    icon?:string;
    type:ProductModuleType;
    collapsable:boolean;
    defaultOpen:boolean;
    content?:{
        descriptionLocaleKey?:string;
        linkLocaleKey?:string;
        modal?:{
            headingLocaleKey:string;
            bodyLocaleKey?:string;
            bodyLocaleVariables?:any;
            cmsContentSlug?:string;
        };
        link?:{
            path:string;
            target:'_blank' | '_self' | '_parent' | '_top';
        };
    };
}

export interface ProductGallerySettings {
    verticalAlign:boolean;
    slider?:{
        blockWidth:number,
        blockHeight:number,
        gap:number
    };
}
