<div class="form-group" [ngClass]="{'is-invalid': control.invalid && control.touched, 'is-valid': control.valid && control.touched, 'is-disabled': control.disabled}">
    <div *ngIf="label != null" class="label">
        <label>{{label}} {{showRequired ? ' *' : ''}}</label>

    </div>
    <div class="wrapper">
        <div class="form-tel__prefix">
            <select #selectInputRef (change)="handleChange($event)" class="form-control custom-select" ngDefaultControl>
                <option *ngFor="let countryPrefix of countryPrefixCodes" [value]="countryPrefix.value" [selected]="countryPrefix.selected">{{countryPrefix.label}} ({{countryPrefix.regionCode}})</option>
            </select>
        </div>
        <input #textInputRef autocomplete="tel-national" (keyup)="handleChange($event)" (blur)="handleChange($event)" type="tel" class="form-control" placeholder="{{placeHolder}}" ngDefaultControl>
    </div>
</div>
<d-form-messages [control]="control"></d-form-messages>
