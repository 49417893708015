// These are the url segment names used in the app, populate your custom values here if a change is needed
// These are also used as constants in the app for navigation
// This must be defined like this to allow for AOT static analysis
export const PageTypeEnum = {
    HOME            : 'home',
    HOME_SEGMENT    : '',
    SEARCH_RESULTS  : 'search',
    CMS_PREVIEW     : 'cms-preview',
    CMS_LOGIN       : 'cms-login',
    PRODUCT_LIST    : 'products',
    PRODUCT         : 'product',
    CART            : 'cart',
    CHECKOUT        : 'checkout',
    STORE_FINDER    : 'store-finder',
    STORE_DIRECTORY : 'store-directory',
    CUSTOM          : 'page',
    BLOG            : 'blog',
    CONTACT_US      : 'contact-us',
    KITCHEN_SINK    : 'kitchen-sink',
    IFRAME          : 'section',
    EMAIL_CONFIRM   : 'email-confirmation',
    CATEGORY_DISPLAY: 'category-display',
    NOT_FOUND_404   : '404',
    AUTH            : 'auth',
    ACCOUNT         : 'account',
    CATALOGUE       : 'catalogue',
    PLUS_MORE       : 'plus-more',
};

// Reserved for CMS pages that are implemented at a framework level
export const CMSPageEnum = {
    WASHCARE_GUIDE                : 'wash-care-guide',
    SIZE_GUIDE                    : 'size-guides',
    ONLINE_STORE_CREDIT_INFO_POPUP: 'online-store-credit-info-popup'
};


// This is a list of lazily loaded built in modules
// These modules will have a PageTypeEnum prefix for all of their pages
// This variable is used in the meta data php config only
export const LazySectionMap          = {};
LazySectionMap[PageTypeEnum.AUTH]    = true;
LazySectionMap[PageTypeEnum.ACCOUNT] = true;
LazySectionMap[PageTypeEnum.BLOG]    = true;

export const AuthPageEnum = {
    REGISTER     : 'register',
    LOGIN        : 'login',
    FORGOT       : 'forgot-password',
    RESET        : 'reset-password',
    EMAIL_CONFIRM: 'auth-email-confirmation',
    OTP          : 'otp',
};

export const PlusMorePageEnum = {
    PROFILE     : 'profile',
    PROFILE_LINK: 'link-profile',
};

export const AccountPageEnum = {
    MENU               : 'menu',
    ACCOUNT_DETAILS    : 'account-details',
    ADDRESSES          : 'addresses',
    ORDERS             : 'orders',
    ONLINE_STORE_CREDIT: 'online-store-credit',
    ACCOUNT_EDIT       : 'edit',
    EDIT_DETAILS       : 'details',
    EDIT_EMAIL         : 'email',
    EDIT_CELL          : 'cell',
    EDIT_CELL_OTP      : 'cell-otp',
    EDIT_PASSWORD      : 'password',
    LINK_ID            : 'link-id',
    PLUSMORE           : 'plusmore'
};

export const PageTypeVariables = {
    URL_KEY     : 'urlKey',
    PAGE        : 'page',
    CODE        : 'code',
    ORDER_ID    : 'order-id',
    REDIRECT    : 'redirect',
    PHONE_NUMBER: 'phone-number',
    ID          : 'id'
};

export const CheckoutAuthPageEnum = {
    AUTH_LOGIN         : 'login',
    AUTH_REGISTER      : 'register',
    AUTH_OTP           : 'otp',
    AUTH_FORGOTPASS    : 'forgot-password',
    AUTH_FORGOTPASS_OTP: 'forgot-password-otp'
};

export const CheckoutPageEnum = {
    // Node selection
    AUTH     : 'auth',
    DELIVERY : 'delivery',
    GUEST_OTP: 'guest-otp',
    // Just uses '' now
    // User details
    PAYMENT: 'payment',
    // Summary
    CONFIRMATION: 'confirmation'
};

export enum BlogPageEnum {
    CATEGORIES = 'categories',
    CATEGORY   = 'category',
    TAGS       = 'tags',
    TAG        = 'tag',
}

export enum SearchPageEnum {
    PRODUCTS = 'products',
    PAGES    = 'pages'
}

export enum ProductsDisplayPageEnum {
    TERM = 'term',
    LIST = 'list'
}

export enum StoreDirectoryPageEnum {
    COUNTRY  = 'country',
    PROVINCE = 'province',
    CITY     = 'city',
    STORE    = 'store',
}
