import {Injectable, Injector} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {Logger} from '../../../shared/logger';
import {ResponseCode} from '../../enum/response-code.enum';
import {ExternalResponse} from '../../external-response';
import {GoogleMapsScriptLoaderService} from './google-maps-script-loader.service';
import {StaticConfig} from '../../../shared/static-config';

// Generic interface, we often need these and google isn't loaded yet
export interface LatLng {
    latitude:number;
    longitude:number;
}

/**
 * Proxy service to the google analytics API that wraps up with observables & ExternalResponse objects
 */
@Injectable()
export class GoogleMapsApiService {

    // These are only available once the script tag has been loaded so must only be created when they are used
    private autoCompleteService:google.maps.places.AutocompleteService;
    private geocoder:google.maps.Geocoder;

    constructor(public logger:Logger,
                public injector:Injector) {
        this.logger = this.logger.getLogger('GoogleMapsApiService');
    }

    // So this needs to be called after the google maps sdk is loaded as its key in loading dependencies for error handling
    // We cannot handle errors for the dependency loads unfortunately
    async initializeServices() {
        if (!this.autoCompleteService) {
            await this.injector.get(GoogleMapsScriptLoaderService).loadGoogleMaps();
            this.autoCompleteService = new google.maps.places.AutocompleteService();
            this.geocoder            = new google.maps.Geocoder();
        }
    }

    public getQueryPredictions(input:string, restrictToSA:boolean, position?:google.maps.LatLng):Observable<ExternalResponse<google.maps.places.QueryAutocompletePrediction[]>> {

        return this.apiProxy(subscriber => {
            const response:ExternalResponse<google.maps.places.QueryAutocompletePrediction[]> = {
                data        : [],
                responseCode: ResponseCode.SUCCESS
            };

            // Will cause a runtime error down the line but we can default to an empty set
            if (input == null || input === '') {
                subscriber.next(response);
                subscriber.complete();
                return;
            }

            const request:google.maps.places.AutocompletionRequest = {
                input: input,
                /*types: ['geocode', 'establishment']*/
            };

            if (restrictToSA) {
                request.componentRestrictions = {
                    country: ['za']
                };
            }

            if (position) {
                this.logger.debug(`Bias the search with lat: ${position.lat()}, long: ${position.lng()}`);
                request.location = position;
                request.radius   = 100 * 1000; // 100km
            }

            this.logger.debug('request params: ', request);

            // MUST USE getPlacePredictions for the componentRestrictions to work
            // https://stackoverflow.com/questions/44972341/google-maps-api-autocomplete-predictions-for-usa-only
            this.autoCompleteService.getPlacePredictions(request, (predictions:google.maps.places.QueryAutocompletePrediction[], status:google.maps.places.PlacesServiceStatus) => {
                this.logger.debug('GOOGLE API: getQueryPredictions() result: ', request, predictions, status);

                if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    response.data = [];
                    subscriber.next(response);
                }
                else if (status === google.maps.places.PlacesServiceStatus.OK) {
                    response.data = predictions.filter(pre => pre.place_id != null);
                    subscriber.next(response);
                }
                else {
                    response.responseCode          = ResponseCode.INVALID_RESPONSE_FORMAT;
                    response.developerErrorMessage = status + '';
                    subscriber.error(response);
                }

                subscriber.complete();
            });
        });
    }

    public getPlaceById(placeId:string):Observable<ExternalResponse<google.maps.GeocoderResult>> {
        return this.apiProxy(subscriber => {
            this.geocoder.geocode({placeId}, (result:google.maps.GeocoderResult[], status:google.maps.GeocoderStatus) => {
                this.logger.debug('GOOGLE API: getPlaceById() result: ', result, status);

                // Invalid status or ambiguous result
                if (status !== google.maps.GeocoderStatus.OK || (result && result.length !== 1)) {
                    const errorResponse:ExternalResponse<google.maps.GeocoderResult[]> = {
                        error                : result,
                        responseCode         : ResponseCode.INVALID_RESPONSE_FORMAT,
                        developerErrorMessage: status.toString()
                    };
                    subscriber.error(errorResponse);
                }
                else {
                    const response:ExternalResponse<google.maps.GeocoderResult> = {
                        data        : result[0],
                        responseCode: ResponseCode.SUCCESS
                    };
                    subscriber.next(response);
                }
                subscriber.complete();
            });
        });
    }

    public getPlaceByStaticAddress(address:string):Observable<ExternalResponse<google.maps.GeocoderResult>> {
        return this.apiProxy(subscriber => {
            this.geocoder.geocode({address}, (result:google.maps.GeocoderResult[], status:google.maps.GeocoderStatus) => {
                this.logger.debug('GOOGLE API: getPlaceByStaticAddress() result: ', result, status);

                if(result) {
                    const response:ExternalResponse<google.maps.GeocoderResult> = {
                        data        : result[0],
                        responseCode: ResponseCode.SUCCESS
                    };
                    subscriber.next(response);
                }

                subscriber.complete();
            });
        });
    }

    private apiProxy<T>(callback:(subscriber:Subscriber<ExternalResponse<T>>) => void):Observable<ExternalResponse<T>> {

        return new Observable(subscriber => {
            const timeout = setTimeout(() => {
                // We have to assume this is an SDK loading issue as if one of the dependencies fails to load
                // then a hang does occur, the only solution would be to refresh the page.
                // NOTE: Do not change this text
                this.logger.error('Google map SDK query timed out');
                throw new Error('Google map SDK query timed out');
            }, StaticConfig.GOOGLE_PREDICTION_TIMEOUT);

            this.initializeServices().then(
                () => {
                    try {
                        callback(subscriber);
                    }
                    catch (err) {
                        const runtimeErrorResponse:ExternalResponse<google.maps.places.PlacesServiceStatus> = {
                            developerErrorMessage: err.message,
                            responseCode         : ResponseCode.RUNTIME_ERROR
                        };
                        subscriber.error(runtimeErrorResponse);
                        subscriber.complete();
                    }
                },
                err => {
                    throw err;
                }
            );

            return () => {
                this.logger.debug('Clearing timeout!!');
                clearTimeout(timeout);
            };
        });
    }

}
