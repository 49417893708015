import {Injectable, Injector} from '@angular/core';
import {AbstractFacadeService} from '../../../external-data/abstract-facade.service';
import {WindowRef} from '../window-ref';


@Injectable()
export class StorageService extends AbstractFacadeService {

    private storage:Storage;

    constructor(injector:Injector,
                private windowRef:WindowRef) {
        super(injector);
        let hasLocal;
        try {
            hasLocal = typeof windowRef.nativeWindow.localStorage !== 'undefined';
            // This adds a test for local storage quota issues as per:
            // https://github.com/marcuswestin/store.js/issues/232
            const x = 'testStorage';
            windowRef.nativeWindow.localStorage.setItem(x, x);
            windowRef.nativeWindow.localStorage.removeItem(x);
            hasLocal = true;
        }
        catch (e) {
            hasLocal = false;
        }
        if (hasLocal) {
            this.storage = windowRef.nativeWindow.localStorage;
        }
        else {
            this.storage = new MemoryStorage();
        }
    }

    clear():void {
        this.storage.clear();
    }

    getItem(key:string):string | null {
        return this.storage.getItem(key);
    }

    getAllKeys():string[] {
        return Object.keys(this.storage);
    }

    removeItem(key:string):void {
        this.storage.removeItem(key);
    }

    setItem(key:string, data:string):void {
        this.storage.setItem(key, data);
    }

    getItemBool(key:string, defaultBool?:boolean):boolean {
        const result = this.getItem(key);
        if (result == null && defaultBool != null) {
            this.setItemBool(key, defaultBool);
            return this.getItemBool(key);
        }
        else {
            return result === 'true';
        }
    }

    getItemBoolOrNull(key:string):boolean | null {
        const result = this.getItem(key);
        return result != null ? result === 'true' : null;
    }

    setItemBool(key:string, value:boolean):void {
        this.setItem(key, value === true ? 'true' : 'false');
    }

    setItemObject(key:string, data:any) {
        let storeStr:string;
        try {
            storeStr = JSON.stringify(data);
        }
        catch (err) {
            console.error('Error on JSON.stringify() object for local storage:' + err);
        }
        this.setItem(key, storeStr);
    }

    getItemObject<T = any>(key:string):T {
        const result = this.getItem(key);

        if (result === undefined) {
            return undefined;
        }
        else if (result === null || result === '') {
            return null;
        }
        else {
            let objResult;
            try {
                objResult = JSON.parse(result);
            }
            catch (err) {
                console.error('Error on JSON.parse() object for local storage:' + err);
            }
            return objResult;
        }
    }

    getName():string {
        return 'StorageService';
    }
}

class MemoryStorage implements Storage {
    store = {};

    clear():void {
        this.store = {};
    }

    getItem(key:string):string | null {
        const item = this.store[key];
        return (item === undefined) ? null : item;
    }

    removeItem(key:string):void {
        delete this.store[key];
    }

    setItem(key:string, data:string):void {
        this.store[key] = data;
    }

    key(index:number):string | null {
        return Object.keys(this.store)[index];
    }

    get length() {
        return Object.keys(this.store).length;
    }
}
