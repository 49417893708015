import {gql} from 'apollo-angular';

export const UPDATE_CUSTOMER_WITH_LOCATION_CODE = gql`
    mutation updateCustomerV2($input: CustomerUpdateInput!) {
        updateCustomerV2(input: $input) {
            customer {
                pickup_location_code
            }
        }
    }
`;
