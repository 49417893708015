import {gql} from 'apollo-angular';

export const FETCH_STORES_BY_LOCATION_CACHE = false;

export const FETCH_STORES_BY_LOCATION = gql`
    query ackStoresByLocation($lon:Float!, $lat:Float!, $from:Int, $size:Int, $clickAndCollectOnly:Boolean, $radius:Int, $branchTypes:[String]) {
        ackStoresByLocation(
            lon: $lon,
            lat: $lat,
            from: $from,
            size: $size,
            clickAndCollectOnly: $clickAndCollectOnly,
            radius: $radius,
            branchTypes: $branchTypes
        ) {
            stores {
                branchCode
                branchName
                branchNameUrlKey
                clickAndCollectEnabled
                addressLine1
                addressLine2
                suburb
                suburbUrlKey
                city
                cityUrlKey
                province
                provinceUrlKey
                locDivName
                locDivNameUrlKey
                postalCode
                countryName
                countryNameUrlKey
                countryCode
                branchTelephone
                location {
                    lat
                    lon
                }
                businessHours {
                    ClosingTime
                    OpeningTime
                    Weekday
                }
                distance
            }
            total
        }
    }
`;
