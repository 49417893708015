import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    Inject,
    InjectionToken,
    Injector,
    Input,
    OnDestroy,
    Optional,
    QueryList
} from '@angular/core';
import {Subscription} from 'rxjs';
import {FacadePlatform} from '../shared/facade-platform';
import {FacadeAccordionItemComponent} from './facade-accordion-item.component';
import {ComponentRegistrationConfig} from '../inject-component/inject-component.service';

export const ACCORDION_CONFIG_TOKEN = new InjectionToken<ComponentRegistrationConfig>('ACCORDION_CONFIG_TOKEN');

export interface FacadeAccordionConfig {
    // Used to offset the scroll position when changing the accordion
    // Needed depending on the implementation
    scrollingOffset:number;
}

@Component({
    selector       : 'accordion', // tslint:disable-line
    template       : '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacadeAccordionComponent implements AfterContentInit, OnDestroy {

    @ContentChildren(FacadeAccordionItemComponent)
    items:QueryList<FacadeAccordionItemComponent>;

    @Input() public customScrollingOffset = 0;

    @Input() scrollToFirstIndex = true;

    private _openIndex                   = -1;
    private subscriptions:Subscription[] = [];
    private initalised:boolean;

    constructor(public el:ElementRef,
                public facadePlatform:FacadePlatform,
                @Inject(ACCORDION_CONFIG_TOKEN) @Optional() public config:FacadeAccordionConfig,
                public injector:Injector) {
        // Some defaults for pep backward compatibility
        if (config == null) {
            this.config = {scrollingOffset: 15};
        }
    }

    ngAfterContentInit() {
        this.initalised = true;
        //console.log('Init the accordion: ', this.items);
        if (this._openIndex !== -1) this.openAnIndex(this._openIndex, true);

        // Listen to the click events
        if (this.items && this.items.length > 0) {
            this.items.toArray().forEach((item, index) => {
                if (item.header) {
                    this.subscriptions.push(
                        item.header.openClose.subscribe(event => {
                            // If already open then change the index to none
                            if (item.open) {
                                this.openAnIndex(-1, false);
                            }
                            else {
                                this.openAnIndex(index, false);
                            }
                        })
                    );
                }
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }

    @Input()
    set openIndex(value:number) {
        this.openAnIndex(Number(value), false);
    }

    get openIndex() {
        return this._openIndex;
    }

    private openAnIndex(index:number, isFirstChange:boolean) {
        if (index === this._openIndex && !isFirstChange) return;
        this._openIndex = index;
        if (!this.initalised) return;

        if (this.items && this.items.length > this._openIndex) {
            //console.log('setting open index to: ', this._openIndex);

            this.items.toArray().forEach((item, i) => {
                item.setOpen(i === this._openIndex, !isFirstChange);
            });

            if (index !== -1) {
                // Wait for the open close animation as it will affect the position possible
                setTimeout(() => {
                    const items = this.items.toArray();
                    let offset  = 0;
                    if (index !== 0) {
                        for (let i = 0; i < index; i++) {
                            offset += items[i].header.el.nativeElement.getBoundingClientRect().height + this.config.scrollingOffset;
                        }
                    }

                    if (this.customScrollingOffset) {
                        offset += this.customScrollingOffset;
                    }
                    //console.log('Scrolling to element > ', this.el.nativeElement, offset);
                    const scrollToElement = () => this.facadePlatform.scrollToElement(this.injector, this.el.nativeElement, offset);
                    if (isFirstChange && this.scrollToFirstIndex) {
                        scrollToElement();
                    }
                    else if (!isFirstChange) {
                        scrollToElement();
                    }
                }, 400);
            }
        }
    }

}
