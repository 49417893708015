import {AckStore, BranchLocation, TradingDay} from '../../../../../generated/graphql';
import {ArrayUtil} from '../../shared/util/array.util';

export class EnhancedAckStore implements AckStore {

    branchCode:string;
    branchName:string;
    branchNameUrlKey:string;
    clickAndCollectEnabled:boolean;
    addressLine1:string;
    addressLine2:string;
    suburb:string;
    suburbUrlKey:string;
    city:string;
    cityUrlKey:string;
    province:string;
    provinceUrlKey:string;
    locDivName:string;
    locDivNameUrlKey:string;
    postalCode:string;
    countryName:string;
    countryNameUrlKey:string;
    countryCode:string;
    location:BranchLocation;
    branchTelephone:string;
    businessHours:TradingDay[];
    distance:number;
    skus?:string[];

    private _formattedAddy:string;
    private _formattedBusHours:string;

    constructor(input:AckStore) {
        this.branchCode             = input.branchCode;
        this.branchName             = input.branchName;
        this.branchNameUrlKey       = input.branchNameUrlKey;
        this.clickAndCollectEnabled = input.clickAndCollectEnabled;
        this.addressLine1           = input.addressLine1;
        this.addressLine2           = input.addressLine2;
        this.suburb                 = input.suburb;
        this.suburbUrlKey           = input.suburbUrlKey;
        this.city                   = input.city;
        this.cityUrlKey             = input.cityUrlKey;
        this.province               = input.province;
        this.provinceUrlKey         = input.provinceUrlKey;
        this.locDivName             = input.locDivName;
        this.locDivNameUrlKey       = input.locDivNameUrlKey;
        this.postalCode             = input.postalCode;
        this.countryName            = input.countryName;
        this.countryNameUrlKey      = input.countryNameUrlKey;
        this.countryCode            = input.countryCode;
        this.location               = input.location;
        this.branchTelephone        = input.branchTelephone;
        this.businessHours          = input.businessHours;
        this.distance               = input.distance;
    }

    public get formattedStreetAddress():string {
        const notEmptyOrMissing = (value) => value !== undefined && value !== null && value !== '' && value !== ' ';
        if (!this._formattedAddy) {
            this._formattedAddy = '';
            if (notEmptyOrMissing(this.addressLine1)) this._formattedAddy += `${this.addressLine1}, `;
            if (notEmptyOrMissing(this.addressLine2)) this._formattedAddy += `${this.addressLine2}, `;
            if (notEmptyOrMissing(this.suburb) && this.suburb.toLowerCase() !== this.city.toLowerCase()) this._formattedAddy += `${this.suburb}, `;
            if (notEmptyOrMissing(this.city)) this._formattedAddy += `${this.city}, `;
            if (notEmptyOrMissing(this.province)) this._formattedAddy += `${this.province}`;
        }
        return this._formattedAddy;
    }

    public get formattedBusinessHours():string {
        if (!this._formattedBusHours) {
            const hoursHash         = ArrayUtil.straightHash(this.businessHours, b => b.Weekday.toLowerCase());
            const days              = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Public Holiday'];
            this._formattedBusHours = '';
            days.forEach(day => {
                if (!hoursHash.hasOwnProperty(day.toLowerCase())) return;
                const dayHash = hoursHash[day.toLowerCase()];
                if (dayHash) {
                    this._formattedBusHours += `${day}: ${dayHash?.OpeningTime ?? 'Unknown'} - ${dayHash?.ClosingTime ?? 'Unknown'} <br />`;
                }
            });
            if (this._formattedBusHours === '') this._formattedBusHours = `Sorry, the store's hours are currently not available`;
        }
        return this._formattedBusHours;
    }
}
