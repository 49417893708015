import {Component, Injector} from '@angular/core';
import {AbstractFormMessagesComponent} from '../../../cross-platform/forms/abstract-form-messages-component';

@Component({
    selector: 'd-form-messages',
    template: `
        <div class="text-holder text-danger" *ngIf="control && control.touched && control.invalid">
            <div [innerHTML]="getMessages()">
            </div>
        </div>
        <div class="pending text-holder" *ngIf="control && control.touched && control.pending">
            {{'validating' | translate}}
        </div>
    `
})
export class DFormMessagesComponent extends AbstractFormMessagesComponent {
    constructor(injector:Injector) {
        super(injector);
    }
}
