import {
    BaseItem,
    FMDesktopMenuDropDownColumn,
    FMDesktopMenuElementSizes,
    FMDesktopMenuInjectItem,
    FMDesktopMenuItemGroup,
    FMMenuButtonStyle,
    FMMenuItemType,
    FMMobileMenuItemGroup
} from '../../../../cross-platform/external-data/firebase/firebase.types';
import {Logger} from '../../../../cross-platform/shared/logger';
import {UrlService} from '../../../../cross-platform/shared/url.service';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ArrayUtil, forEachAsync} from '../../../../cross-platform/shared/util/array.util';
import {ImplementationDataService} from '../../../../cross-platform/implementation-config/implementation-data.service';
import {CategoryHash} from '../../../../cross-platform/external-data/magento/magento.reducer.types';


export interface DesktopMenuUIItem {
    type:'button' | 'link' | 'divider' | 'image';
    cssClasses?:string;
    style?:FMMenuButtonStyle;
    label?:string;
    link?:string;
    src?:string;
    wrapperStyle?:object;
    icon?:string;

    // Used for placing injected items
    urlKeyPath?:string;
    index?:number;
}


@Injectable()
export class DesktopMainMenuConfigParser {
    categoryUrlKeyHash:CategoryHash;
    buttonClassMap:{ [key:string]:string };
    sizes:FMDesktopMenuElementSizes;

    constructor(public urlService:UrlService,
                public logger:Logger,
                public translateService:TranslateService,
                public implementationDataService:ImplementationDataService
    ) {
        this.logger = this.logger.getLogger('MainMenuConfigParser');
        //this.logger.logLevel = LogLevel.TEST;

        this.buttonClassMap                                         = {};
        this.buttonClassMap[FMMenuButtonStyle.buttonPrimaryLarge]   = 'btn btn-primary';
        this.buttonClassMap[FMMenuButtonStyle.buttonPrimaryStd]     = 'btn btn-sm btn-primary';
        this.buttonClassMap[FMMenuButtonStyle.buttonSecondaryLarge] = 'btn btn-secondary';
        this.buttonClassMap[FMMenuButtonStyle.buttonSecondaryStd]   = 'btn btn-sm btn-secondary';
        this.buttonClassMap[FMMenuButtonStyle.buttonSuccessLarge]   = 'btn btn-success';
        this.buttonClassMap[FMMenuButtonStyle.buttonSuccessStd]     = 'btn btn-sm btn-success';
        this.buttonClassMap[FMMenuButtonStyle.buttonOutlineLarge]   = 'btn btn-outline-primary';
        this.buttonClassMap[FMMenuButtonStyle.buttonOutlineStd]     = 'btn btn-sm btn-outline-primary';
    }

    /**
     * Used to convert the dynamic layout items into standard config items.
     * The layout is then always built using standard config items.
     */
    async convertDesktopDynamicLayouts(columns:FMDesktopMenuDropDownColumn[]) {

        // Separate out columns without dynamic layouts
        const dynCols = columns.filter(col => {
            return col.items.find(item => item.type === FMMenuItemType.autoLayout) != null;
        });

        if (dynCols.length > 0) {
            // Now convert the dynamic cols into 1 big list
            let flatColItems:FMDesktopMenuItemGroup[] = [];
            let index                                 = 0;
            const processedCatHash                    = {};
            const globalExcludeHash                   = {};
            const iconHash                            = {};

            // Run through all the cols with auto layout
            // Build up flat list of new config
            await forEachAsync(dynCols, async col => {

                await forEachAsync(col.items, async item => {
                    if (item.type === FMMenuItemType.autoLayout) {
                        const parentCat = this.categoryUrlKeyHash[item.parentUrlKeyPath];

                        if (item.excludeCategoryIds) item.excludeCategoryIds.forEach(id => globalExcludeHash[id] = true);
                        if (item.icons) item.icons.forEach(icon => iconHash[icon.urlKeyPath] = icon.data);

                        // If cat is enabled.
                        if (parentCat && parentCat.is_active && parentCat.include_in_menu && parentCat.children_data && processedCatHash[parentCat.urlKeyPath] == null) {
                            processedCatHash[parentCat.urlKeyPath] = true;

                            // Hash out the inject items to allow for fast testing
                            const parsed = await this.hashInjectItems<FMDesktopMenuInjectItem>(item.injectItems);

                            parentCat.children_data.forEach(level1Cat => {
                                if (level1Cat && level1Cat.is_active && level1Cat.include_in_menu && globalExcludeHash[level1Cat.urlKeyPath] == null) {

                                    // Add any inject children
                                    if (parsed.aboveHash[level1Cat.urlKeyPath]) flatColItems = flatColItems.concat(parsed.aboveHash[level1Cat.urlKeyPath].map(inject => inject.item));

                                    flatColItems.push({
                                        type      : FMMenuItemType.categoryButton,
                                        urlKeyPath: level1Cat.urlKeyPath,
                                        style     : FMMenuButtonStyle.title,
                                        icon      : iconHash[level1Cat.urlKeyPath]
                                    });
                                    if (parsed.belowHash[level1Cat.urlKeyPath]) flatColItems = flatColItems.concat(parsed.belowHash[level1Cat.urlKeyPath].map(inject => inject.item));
                                    if (parsed.indexHash[index]) flatColItems = flatColItems.concat(parsed.indexHash[index].map(inject => inject.item));
                                    index++;

                                    const filtered = level1Cat.children_data ? level1Cat.children_data.filter(child => child.is_active && child.include_in_menu && globalExcludeHash[child.urlKeyPath] == null) : [];
                                    if (filtered.length > 0) {
                                        let innerInjectItems = [];
                                        level1Cat.children_data.forEach(childCat => {
                                            if (parsed.belowHash[childCat.urlKeyPath]) innerInjectItems = innerInjectItems.concat(parsed.belowHash[childCat.urlKeyPath]);
                                            if (parsed.aboveHash[childCat.urlKeyPath]) innerInjectItems = innerInjectItems.concat(parsed.aboveHash[childCat.urlKeyPath]);
                                            // Don't proxy index based children, they only apply to the full element index
                                        });

                                        flatColItems.push({
                                            type              : FMMenuItemType.categoryList,
                                            parentUrlKeyPath  : level1Cat.urlKeyPath,
                                            style             : FMMenuButtonStyle.subTitle,
                                            injectItems       : innerInjectItems,
                                            spaceAfter        : this.sizes.autoLayoutGap,
                                            excludeCategoryIds: item.excludeCategoryIds,
                                            icons             : item.icons
                                        });

                                        // Add any related children via index
                                        index++;
                                        if (parsed.indexHash[index]) flatColItems = flatColItems.concat(parsed.indexHash[index].map(inject => inject.item));
                                    }
                                    else {
                                        flatColItems[flatColItems.length - 1].spaceAfter = this.sizes.autoLayoutGap;
                                    }
                                }
                            });
                        }
                    }
                    else if (item.type === FMMenuItemType.categoryList) {
                        // Prune any invalid lists
                        const parentCat = this.categoryUrlKeyHash[item.parentUrlKeyPath];
                        const children  = parentCat && parentCat.children_data ? parentCat.children_data.filter(child => child.is_active && child.include_in_menu && globalExcludeHash[child.urlKeyPath] == null) : [];
                        if (children.length > 0) flatColItems.push(item);
                    }
                    else if (item.type === FMMenuItemType.categoryButton) {
                        // Prune any invalid category buttons
                        const cat = this.categoryUrlKeyHash[item.urlKeyPath];
                        if (cat && cat.is_active && cat.include_in_menu) flatColItems.push(item);
                    }
                    else {
                        flatColItems.push(item);
                    }
                });

            });


            if (dynCols.length > 1) {
                // Now that we have a full list of items we can determine its height
                const styleHeights                                   = {};
                styleHeights[FMMenuButtonStyle.title]                = this.sizes.title;
                styleHeights[FMMenuButtonStyle.subTitle]             = this.sizes.subTitle;
                styleHeights[FMMenuButtonStyle.buttonPrimaryLarge]   = this.sizes.buttonLarge;
                styleHeights[FMMenuButtonStyle.buttonPrimaryStd]     = this.sizes.buttonStd;
                styleHeights[FMMenuButtonStyle.buttonSecondaryLarge] = this.sizes.buttonLarge;
                styleHeights[FMMenuButtonStyle.buttonSecondaryStd]   = this.sizes.buttonStd;
                styleHeights[FMMenuButtonStyle.buttonSuccessLarge]   = this.sizes.buttonLarge;
                styleHeights[FMMenuButtonStyle.buttonSuccessStd]     = this.sizes.buttonStd;
                styleHeights[FMMenuButtonStyle.buttonOutlineLarge]   = this.sizes.buttonLarge;
                styleHeights[FMMenuButtonStyle.buttonOutlineStd]     = this.sizes.buttonStd;

                const setItemsHeight = (items:FMDesktopMenuItemGroup[]) => {
                    let totalHeight = 0;
                    if (items) {
                        items.forEach(item => {
                            let itemHeight = 0;
                            if (!item.height) {
                                if (item.type === FMMenuItemType.categoryButton || item.type === FMMenuItemType.customButton) {
                                    const style = item.style || FMMenuButtonStyle.title;
                                    itemHeight  = styleHeights[style];
                                }
                                else if (item.type === FMMenuItemType.categoryList) {
                                    const cat = this.categoryUrlKeyHash[item.parentUrlKeyPath];
                                    if (cat && cat.children_data && cat.children_data.length > 0) {
                                        const filtered = cat.children_data.filter(childCat => childCat.is_active && childCat.include_in_menu && globalExcludeHash[childCat.urlKeyPath] == null);
                                        itemHeight     = filtered.length * styleHeights[item.style];
                                        // Add children height
                                        itemHeight += setItemsHeight(item.injectItems.map(inject => inject.item));
                                    }
                                }
                                else if (item.type === FMMenuItemType.divider) {
                                    itemHeight = this.sizes.divider;
                                }
                                else if (item.type === FMMenuItemType.image) {
                                    itemHeight = item.imageHeight;
                                }
                                if (item.spaceBefore) itemHeight += item.spaceBefore;
                                if (item.spaceAfter) itemHeight += item.spaceAfter;
                                item.height = itemHeight;
                                totalHeight += itemHeight;
                            }
                        });
                    }
                    return totalHeight;
                };

                // Get the full height as well as size all of the blocks
                const fullHeight = setItemsHeight(flatColItems);


                const tryLayout = (columnHeightRestrict:number) => {
                    // Create columns
                    const cols:FMDesktopMenuDropDownColumn[] = [];
                    dynCols.forEach(col => cols.push({items: []}));

                    // Loop state
                    let colIndex      = 0;
                    let currColHeight = 0;
                    let minColHeight  = Number.MAX_VALUE;
                    let maxColHeight  = 0;
                    const itemsClone  = flatColItems.concat();

                    // Now we can use the column height to redistribute the elements
                    while (itemsClone.length > 0) {
                        const item             = itemsClone[0];
                        const nextItem         = itemsClone.length > 1 ? itemsClone[1] : null;
                        const nextIsSublist    = item.type === FMMenuItemType.categoryButton && nextItem && nextItem.type === FMMenuItemType.categoryList && item.urlKeyPath === nextItem.parentUrlKeyPath;
                        const col              = cols[colIndex];
                        const itemHeight       = nextIsSublist ? item.height + nextItem.height : item.height;
                        const willFit          = currColHeight + itemHeight < columnHeightRestrict;
                        const isFirst          = col.items.length === 0;
                        const lessThanHalfFull = currColHeight < columnHeightRestrict / 2;
                        const isLastColumn     = colIndex === cols.length - 1;
                        let added              = false;

                        if (lessThanHalfFull || isFirst || isLastColumn || willFit) {
                            col.items.push(itemsClone.shift());
                            if (nextIsSublist) col.items.push(itemsClone.shift());
                            currColHeight += itemHeight;
                            added = true;
                        }

                        if (!added || itemsClone.length === 0) {
                            minColHeight = Math.min(currColHeight, minColHeight);
                            maxColHeight = Math.max(currColHeight, maxColHeight);
                            colIndex++;
                            currColHeight = 0;
                        }
                    }

                    //this.logger.debug('maxColHeight: ', maxColHeight);
                    //this.logger.debug('minColHeight: ', minColHeight);

                    return {
                        colDiff: maxColHeight - minColHeight,
                        cols   : cols
                    };
                };


                // try a combination of column heights and check the
                const base      = fullHeight / dynCols.length;
                const increment = (base / 2) / 4;
                let currentDiff = Number.MAX_VALUE;
                let bestResults:FMDesktopMenuDropDownColumn[];

                for (let i = 0; i < 5; i++) {
                    const results = tryLayout(base + increment * i);
                    //this.logger.debug('Testing with ', (base + increment * i), results.colDiff);
                    if (results.colDiff < currentDiff) {
                        currentDiff = results.colDiff;
                        bestResults = results.cols;
                    }
                }
                // Apply the best results to the original columns
                //this.logger.debug('using layout: ', currentDiff);
                bestResults.forEach((col, i) => dynCols[i].items = col.items);

                //this.logger.debug('Post layout cols: ', dynCols);
            }
            else {
                dynCols[0].items = flatColItems;
            }
        }

        return columns;
    }

    async buildUIElements(items:Array<FMDesktopMenuItemGroup | FMMobileMenuItemGroup>) {
        let uiElements:DesktopMenuUIItem[] = [];

        for (let i = 0; i < items.length; i++) {
            const configItem = items[i];

            let uiItem:DesktopMenuUIItem;

            switch (configItem.type) {
                case FMMenuItemType.categoryButton:
                    uiItem         = {type: 'link'};
                    const category = this.categoryUrlKeyHash[configItem.urlKeyPath];
                    if (category && category.is_active && category.include_in_menu) {
                        uiItem.label      = category.name;
                        uiItem.link       = this.urlService.buildUrlForProductList(category);
                        uiItem.urlKeyPath = category.urlKeyPath;
                        uiItem.icon       = configItem.icon;
                        this.resolveClasses(uiItem, configItem);
                        this.resolveItemWrapperStyle(uiItem, configItem);
                        uiElements.push(uiItem);
                    }
                    else {
                        this.logger.warn('Discarding button as category not present or inactive: ', uiItem);
                    }
                    break;


                case FMMenuItemType.categoryList:
                    const parentCategory = this.categoryUrlKeyHash[configItem.parentUrlKeyPath];
                    if (parentCategory && parentCategory.is_active && parentCategory.include_in_menu && parentCategory.children_data && parentCategory.children_data.length > 0) {
                        let oneAdded      = false;
                        let index         = 0;
                        let newItems      = [];
                        const excludeHash = ArrayUtil.valueKeyTrueHash(configItem.excludeCategoryIds);
                        const iconHash    = configItem.icons ? ArrayUtil.valueHash(configItem.icons, icon => icon.urlKeyPath, icon => icon.data) : {};

                        parentCategory.children_data.forEach(childCat => {
                            if (childCat.is_active && childCat.include_in_menu && excludeHash[childCat.urlKeyPath] == null) {
                                uiItem              = {type: 'link'};
                                uiItem.label        = childCat.name;
                                uiItem.link         = this.urlService.buildUrlForProductList(childCat);
                                uiItem.urlKeyPath   = childCat.urlKeyPath;
                                uiItem.index        = index;
                                uiItem.wrapperStyle = {};
                                uiItem.icon         = iconHash[childCat.urlKeyPath];
                                if (configItem.spaceLeft) uiItem.wrapperStyle['padding-left'] = configItem.spaceLeft + 'px';
                                if (configItem.spaceRight) uiItem.wrapperStyle['padding-right'] = configItem.spaceRight + 'px';

                                this.resolveClasses(uiItem, configItem);
                                newItems.push(uiItem);
                                index++;
                                oneAdded = true;
                            }
                        });

                        // Convert then add the injected items
                        if (configItem.injectItems) {
                            const parsed = await this.hashInjectItems(
                                configItem.injectItems,
                                async injectItem => await this.buildUIElements([injectItem.item])
                            );

                            // Iterate through the current list and look in the hashes for relevant entries, build up a new list
                            let combinedItems = [];
                            for (let k = 0; k < newItems.length; k++) {
                                uiItem = newItems[k];
                                if (uiItem.urlKeyPath && parsed.aboveHash[uiItem.urlKeyPath]) {
                                    combinedItems = combinedItems.concat(parsed.aboveHash[uiItem.urlKeyPath]);
                                }
                                combinedItems.push(uiItem);
                                if (uiItem.urlKeyPath && parsed.belowHash[uiItem.urlKeyPath]) {
                                    combinedItems = combinedItems.concat(parsed.belowHash[uiItem.urlKeyPath]);
                                }
                                if (uiItem.index && parsed.indexHash[uiItem.index]) {
                                    combinedItems = combinedItems.concat(parsed.indexHash[uiItem.index]);
                                }
                            }
                            newItems = combinedItems;
                        }


                        if (oneAdded) {
                            uiItem = newItems[0];
                            if (configItem.spaceBefore) uiItem.wrapperStyle['padding-top'] = configItem.spaceBefore + 'px';

                            // Bottom space on last
                            uiItem = newItems[newItems.length - 1];
                            if (configItem.spaceAfter) uiItem.wrapperStyle['padding-bottom'] = configItem.spaceAfter + 'px';
                        }

                        uiElements = uiElements.concat(newItems);
                    }
                    else {
                        this.logger.warn('Discarding button as category not present or inactive: ', configItem);
                    }
                    break;

                case FMMenuItemType.customButton:
                    uiItem      = {type: 'link', link: configItem.isCDNLink ? this.urlService.buildUrlForCDNRoot(configItem.link) : configItem.link, label: configItem.label};
                    uiItem.icon = configItem.icon;
                    if (configItem.locale) {
                        uiItem.label = await this.translateService.get(configItem.locale).toPromise();
                    }
                    this.resolveClasses(uiItem, configItem);
                    this.resolveItemWrapperStyle(uiItem, configItem);
                    uiElements.push(uiItem);
                    break;

                case FMMenuItemType.divider:
                    uiItem            = {type: 'divider'};
                    uiItem.cssClasses = configItem.cssClasses ? configItem.cssClasses + ' ' + 'item-divider' : 'item-divider';
                    this.resolveItemWrapperStyle(uiItem, configItem);
                    uiElements.push(uiItem);
                    break;

                case FMMenuItemType.image:
                    uiItem            = {type: 'image', src: configItem.src, link: configItem.link};
                    uiItem.cssClasses = configItem.cssClasses ? configItem.cssClasses + ' ' + 'item-image' : 'item-image';
                    this.resolveItemWrapperStyle(uiItem, configItem);
                    uiElements.push(uiItem);
                    break;
            }


        }

        return uiElements;
    }


    resolveClasses(uiItem:DesktopMenuUIItem, configItem:FMDesktopMenuItemGroup | FMMobileMenuItemGroup) {
        let classToAdd:string;
        if (configItem.type !== FMMenuItemType.divider && configItem.type !== FMMenuItemType.image) {
            if (configItem.style === FMMenuButtonStyle.title || (configItem.style == null && configItem.cssClasses == null)) {
                classToAdd = 'title';
            }
            else if (configItem.style === FMMenuButtonStyle.subTitle) {
                classToAdd = 'subTitle';
            }
            else {
                uiItem.type = 'button';
                classToAdd  = this.buttonClassMap[configItem.style];
            }
            if (configItem.cssClasses) {
                uiItem.cssClasses = configItem.cssClasses + ' ' + classToAdd;
            }
            else {
                uiItem.cssClasses = classToAdd;
            }
            const urlKeyClass = uiItem?.link?.substring(uiItem.link.lastIndexOf('/') + 1);
            if (urlKeyClass) {
                uiItem.cssClasses = uiItem.cssClasses?.length > 0 ? uiItem.cssClasses + ' ' + urlKeyClass : urlKeyClass;
            }

            uiItem.style = configItem.style;
        }
        return configItem;
    }

    resolveItemWrapperStyle(uiItem:DesktopMenuUIItem, configItem:BaseItem) {
        uiItem.wrapperStyle = {};
        if (configItem.spaceBefore) uiItem.wrapperStyle['padding-top'] = configItem.spaceBefore + 'px';
        if (configItem.spaceAfter) uiItem.wrapperStyle['padding-bottom'] = configItem.spaceAfter + 'px';
        if (configItem.spaceLeft) uiItem.wrapperStyle['padding-left'] = configItem.spaceLeft + 'px';
        if (configItem.spaceRight) uiItem.wrapperStyle['padding-right'] = configItem.spaceRight + 'px';
        return uiItem;
    }

    async hashInjectItems<T>(injectItems:FMDesktopMenuInjectItem[], processFn?:(injectItem:FMDesktopMenuInjectItem) => Promise<T[]>) {
        const aboveHash:{ [key:string]:T[] }  = {};
        const belowHash:{ [key:string]:T[] }  = {};
        const indexHash:{ [key:string]:T[] }  = {};
        const appendHash:{ [key:string]:T[] } = {};
        if (injectItems) {
            for (let j = 0; j < injectItems.length; j++) {
                const injectItem = injectItems[j];
                if (injectItem.item.type === FMMenuItemType.categoryButton) {
                    const cat = this.categoryUrlKeyHash[injectItem.item.urlKeyPath];
                    if (cat && cat.is_active && cat.include_in_menu) continue;
                }
                const newInjectElements = processFn ? await processFn(injectItem) : [injectItem];
                // Store for easy retrieval after
                if (newInjectElements.length > 0) {
                    let hash;
                    let index:number;
                    if (injectItem.aboveCategoryId) {
                        index = injectItem.aboveCategoryId;
                        hash  = aboveHash;
                    }
                    else if (injectItem.belowCategoryId) {
                        index = injectItem.belowCategoryId;
                        hash  = belowHash;
                    }
                    else if (injectItem.index) {
                        index = injectItem.index;
                        hash  = indexHash;
                    }
                    else {
                        index = 0;
                        hash  = appendHash;
                    }
                    if (indexHash) {
                        if (!hash[index]) hash[index] = [];
                        hash[index] = hash[index].concat(newInjectElements);
                    }
                }
            }
        }

        return {
            aboveHash : aboveHash,
            belowHash : belowHash,
            indexHash : indexHash,
            appendHash: appendHash,
        };
    }
}
