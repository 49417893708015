import {Injectable, Injector} from '@angular/core';
import {AbstractFacadeService} from '../abstract-facade.service';
import {map} from 'rxjs/operators';
import {ExternalResponse} from '../external-response';
import {Observable} from 'rxjs';
import {PagedSet, Paginator} from '../magento/util/paginator';
import {environment} from '../../implementation/environment';
import {WP_SEARCH, WP_SEARCH_CACHE} from './gql-queries/wp-search';
import {GqlEsResponse} from '../../../../../generated/graphql';
import {ESWPSearch} from './model/wordpress.types';

interface WpSearchResponse {
    wpSearch:GqlEsResponse;
}

@Injectable()
export class WordpressSearchService extends AbstractFacadeService {

    constructor(injector:Injector) {
        super(injector);
    }

    textSearch(searchTerm:string, perPage = 3, page = 1):Observable<Paginator<ESWPSearch>> {
        const site      = environment.config.api.wordpressRootPages[0];
        const variables = {searchTerm: searchTerm, website: site, perPage: String(perPage), page: String(page)};

        return this.apollo.query({
            query    : WP_SEARCH,
            variables: variables
        }, WP_SEARCH_CACHE)
            .pipe(
                map((res:ExternalResponse<WpSearchResponse>) => {
                    const pagedSet        = new PagedSet<ESWPSearch>();
                    pagedSet.totalPerPage = perPage;
                    pagedSet.totalItems   = res.data.wpSearch.hits.total.value;
                    pagedSet.setPageData(page, res.data.wpSearch.hits.hits.map(item => JSON.parse(JSON.stringify(item._source))));
                    return new Paginator<ESWPSearch>(pagedSet);
                })
            );
    }

    getName():string {
        return 'WordpressSearchService';
    }
}
