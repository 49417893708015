import {ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {FacadeAccordionBodyComponent} from './facade-accordion-body.component';
import {FacadeAccordionHeaderComponent} from './facade-accordion-header.component';

@Component({
    selector       : 'accordion-item',
    template       : '<ng-content></ng-content>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacadeAccordionItemComponent {

    @ContentChild(FacadeAccordionHeaderComponent)
    header:FacadeAccordionHeaderComponent;

    @ContentChild(FacadeAccordionBodyComponent)
    body:FacadeAccordionBodyComponent;

    @Output()
    onOpen = new EventEmitter();

    @Output() 
    onClose = new EventEmitter();

    public _open = false;

    @Input() collapsible:boolean = true;

    constructor(public el:ElementRef) {
        
    }

    public ngAfterViewInit() {
        if(!this.collapsible) {
            this.setOpen(true, false);
        }
    }

    public ngAfterContentInit() {
        if (this.header) this.header.isCollapsible = this.collapsible;
    }

    public setOpen(value:boolean, animate:boolean) {
        if (this._open === value) return;
        this._open = value;
        if (this.header) this.header.open = value;
        if (this.body) this.body.setOpen(value, animate);
        if (this._open){
            this.onOpen.emit();
        }
        else{
            this.onClose.emit();
        }
    }

    public get open():boolean {
        return this._open;
    }
}
