import {gql} from 'apollo-angular';

export const VALIDATE_ADDRESS_WITH_2SHIP_CACHE = false;

export const VALIDATE_ADDRESS_WITH_2SHIP = gql`
    query availableShippingMethods_validateAddressWith2Ship($cartId:String!) {
        availableShippingMethods(cart_id: $cartId) {
            available_shipping_methods {
                available
                carrier_code
                method_code
                error_message
            }
            selected_shipping_method {
                carrier_code
                method_code
                amount {
                    value
                    currency
                }
            }
        }
    }
`;
