import {ElementRef, Injectable} from '@angular/core';
import {DocumentRef} from '../document-ref';
import {WindowRef} from '../window-ref';
import {Logger} from '../../logger';
import {filter} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Injectable()
export class PhotoSwipeService {

    private logger:Logger;
    private currentInstance;
    private backListener:Subscription;

    // Lazy loaded library code
    private PhotoSwipeLightbox;
    private PhotoSwipe;

    constructor(private windowRef:WindowRef,
                private documentRef:DocumentRef,
                public router:Router,
                Logger:Logger) {
        this.logger = Logger.getLogger('PhotoSwipeDelegate');
    }

    async load() {
        if (!this.PhotoSwipeLightbox) {
            const lightbox          = await import('photoswipe/lightbox');
            const photoswipe        = await import('photoswipe');
            this.PhotoSwipeLightbox = lightbox.default;
            this.PhotoSwipe         = photoswipe.default;
        }
    }

    async showGallery(groupName:string, index:number, galleryItems:any, ele?:ElementRef) {
        return new Promise<boolean>((resolve, reject) => {
            if (!galleryItems || !galleryItems || galleryItems.length < 1) reject(false);
            this.load().then(() => {
                const options  = {
                    dataSource: galleryItems,
                    bgOpacity : 1,
                    mainClass : 'photoswipe-bg',
                    pswpModule: () => this.PhotoSwipe,
                };
                const lightbox = new this.PhotoSwipeLightbox(options);

                lightbox.addFilter('thumbEl', (thumbEl, data, index) => {
                    if (ele) {
                        return ele.nativeElement;
                    }
                    return thumbEl;
                });

                lightbox.on('afterInit', () => {
                    resolve(true);
                });

                lightbox.on('close', () => {
                    this.unsubscribeEvents();
                });

                lightbox.on('uiRegister', function () {
                    lightbox.pswp.ui.registerElement({
                        name    : 'custom-caption',
                        order   : 9,
                        isButton: false,
                        appendTo: 'root',
                        html    : '',
                        onInit  : (el, pswp) => {
                            if (lightbox?.pswp?.currSlide) {
                                lightbox.pswp.on('change', () => {
                                    const slide     = galleryItems[lightbox.pswp.currSlide.index];
                                    let captionHTML = '';
                                    if (slide?.title) captionHTML = slide.title;
                                    el.innerHTML = captionHTML || '';
                                });
                            }
                        }
                    });
                });

                lightbox.init();
                lightbox.loadAndOpen(index);
                this.currentInstance = lightbox;
                this.subscribeEvents();
            });
        });
    }

    closePhotoViewer() {
        // if (this.photoSwipeCompInst) this.photoSwipeCompInst.destroyGroup(groupName);
        if (this.currentInstance) this.currentInstance.destroy();
    }

    private subscribeEvents() {
        this.backListener = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event:NavigationStart) => {
                this.closePhotoViewer();
            });
    }

    private unsubscribeEvents() {
        this.backListener?.unsubscribe();
    }
}
