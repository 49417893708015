import {CategoryTree} from '../../../../../generated/graphql';
import {PepkorCategoryapiDataCategoryapiTreeInterface} from './magento.types';
import {CategoryHash} from './magento.reducer.types';

export interface ParsedCategories {
    categoryTree:PepkorCategoryapiDataCategoryapiTreeInterface;
    categoryIDHash:CategoryHash;
    categoryUrlKeyHash:CategoryHash;
}

// NB!!
// Separated to decouple Angular in sitemap generation

export const parseCategories = (categoryTree:CategoryTree[]):ParsedCategories => {
    const parsedCats:ParsedCategories = {
        categoryTree      : null,
        categoryIDHash    : {},
        categoryUrlKeyHash: {}
    };

    const recurseTree = (serverCat:CategoryTree, parentId:number, parentUrlKey:string):PepkorCategoryapiDataCategoryapiTreeInterface => {
        parentUrlKey += '/' + serverCat.url_key;
        const newCat:PepkorCategoryapiDataCategoryapiTreeInterface = {
            id               : serverCat.id,
            name             : serverCat.name,
            parent_id        : parentId,
            position         : serverCat.position,
            level            : serverCat.level,
            product_count    : serverCat.product_count,
            children_data    : null,
            url_key          : serverCat.url_key,
            urlKeyPath       : parentUrlKey,
            is_active        : true, // Graphql doesn't return disabled categories
            include_in_menu  : serverCat.include_in_menu && serverCat.include_in_menu > 0,
            available_sort_by: serverCat.available_sort_by,
            meta_title       : serverCat.meta_title,
            meta_description : serverCat.meta_description,
            robots_index     : serverCat.robots_index,
            rel_canonical    : serverCat.rel_canonical
        };
        parsedCats.categoryIDHash[newCat.id]                       = newCat;

        if (serverCat.children) {
            newCat.children_data = [];

            serverCat.children.forEach(cat => {
                const newChildCat                                     = recurseTree(cat, newCat.id, serverCat.level === 1 ? '' : parentUrlKey);
                parsedCats.categoryIDHash[newChildCat.id]             = newChildCat;
                parsedCats.categoryUrlKeyHash[newChildCat.urlKeyPath] = newChildCat;
                newCat.children_data.push(newChildCat);
            });

            // Order by position
            // No longer needed in magento 2.4.4
            //newCat.children_data.sort((a, b) => a.position - b.position);
        }
        parsedCats.categoryUrlKeyHash[newCat.urlKeyPath] = newCat;

        return newCat;
    };

    // QUESTION: Does this assume theres always just one category on root level? SK
    parsedCats.categoryTree = recurseTree(categoryTree[0], null, '');

    return parsedCats;
};
