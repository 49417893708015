import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {FacadePlatform} from '../../../cross-platform/shared/facade-platform';
import {Logger} from '../../../cross-platform/shared/logger';
import {FakeLinkEvent} from '../../../cross-platform/shared/ui/fake-link.directive';
import {WindowRef} from '../../../cross-platform/shared/ui/window-ref';
import {UrlService} from '../../../cross-platform/shared/url.service';
import {FacadeAppDefinitionType} from '../../../../scripts/shared/facade-config.types';
import {Observable} from 'rxjs';
import {
    NewsletterSignUpDialogService
} from '../../../cross-platform/shared/ui/newsletter/newsletter-sign-up-dialog.service';
import {FacadeLink} from '../../../cross-platform/shared/facade-link.enum';
import {environment} from '../../../cross-platform/implementation/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthModalComponent} from '../auth/auth-modal/auth-modal.component';
import {CartDoesntExistModalComponent} from './ui/cart-doesnt-exist-modal.component';
import {FMConfigRedirect} from '../../../cross-platform/external-data/firebase/firebase.types';
import {GTMActions} from '../../../cross-platform/external-data/google/google-tag-manager/gtm.actions';
import {Store} from '@ngxs/store';
import {NavigatorRef} from '../../../cross-platform/shared/ui/navigator-ref';

@Injectable()
export class DesktopFacadePlatform extends FacadePlatform {

    private logger:Logger;
    private redirects:FMConfigRedirect[] = [];
    public router:Router;

    constructor(public injector:Injector,
                public LogService:Logger,
                public windowRef:WindowRef,
                public navRef:NavigatorRef,
                public urlService:UrlService,
                public newsletterSignUpDialogService:NewsletterSignUpDialogService,
                public ngbModal:NgbModal,
                private store:Store
                // public ngbModalRef: NgbModalRef
    ) {
        super();
        this.logger    = LogService.getLogger('DesktopFacadePlatform');
        this.redirects = environment.config.redirects;
    }

    public get appType():FacadeAppDefinitionType {
        return FacadeAppDefinitionType.desktop;
    }

    public getDeps() {
        if (!this.router) {
            this.router = this.injector.get(Router);
        }
    }

    public handleLink(fakeLinkEvent:FakeLinkEvent) {
        this.getDeps();
        // Intercept diverts
        const matched = this.urlService.matchTrafficRedirects(fakeLinkEvent.href, this.redirects);
        if (matched != null) {
            fakeLinkEvent.href   = matched.targetURL;
            fakeLinkEvent.newTab = matched.newWindowWhenClicked ? true : false;
        }

        if (this.urlService.isUrlForFacadeFunctionality(fakeLinkEvent.href)) {
            const href = fakeLinkEvent.href;
            if (href != null && href.indexOf(FacadeLink.NEWSLETTER_SIGN_UP_DIALOG) === 0) {
                this.newsletterSignUpDialogService.showDialog();
            }
        }
        else if (!fakeLinkEvent.newTab && this.urlService.isUrlForThisDomain(fakeLinkEvent.href) && !this.urlService.isUrlForFileDownload(fakeLinkEvent.href)) {
            this.logger.info('Handling VALID link deemed this domain ', fakeLinkEvent.href);
            this.router.navigateByUrl(this.urlService.stripAbsoluteHost(fakeLinkEvent.href), {replaceUrl: fakeLinkEvent.replaceState});
        }
        else {
            this.logger.info(`Handling link deemed external '${fakeLinkEvent.href}'`);
            if (this.urlService.isUrlForTel(fakeLinkEvent.href)) {
                fakeLinkEvent.bypassNewTab = false;
            }
            this.windowRef.open(fakeLinkEvent.href, fakeLinkEvent.bypassNewTab === true ? '_self' : '_blank');
        }

        if (this.urlService.isUrlForFileDownload(fakeLinkEvent.href)) {
            // Track the page excluding the domain
            this.store.dispatch(new GTMActions.TrackFileDownload({
                file: this.urlService.convertPathToUrlSafely(fakeLinkEvent.href)
            }));
        }
    }

    public reAuthenticateUser() {
        this.ngbModal.open(AuthModalComponent);
    }

    handleCartNoLongerExists() {
        this.ngbModal.open(CartDoesntExistModalComponent, {backdrop: 'static'});
    }

    // Nothing for these
    get isAndroid():boolean {
        return false;
    }

    get isIOS():boolean {
        return false;
    }

    get isIPad():boolean {
        if (/iPad/.test(this.navRef.nativeNavigator.platform)) {
            return true;
        }
        else {
            // detection for +2019 version of ipad pros (with m5 chips)
            return this.navRef.nativeNavigator.maxTouchPoints &&
                this.navRef.nativeNavigator.maxTouchPoints > 2 &&
                /Mac/.test(this.navRef.nativeNavigator.platform);
        }
    }

    get isSupported():boolean {
        return true;
    }

    public scrollStartObserver(closeElement:any):Observable<{ yPos:number }> {
        throw new Error('Not yet supported');
    }

    public scrollEndObserver(closeElement?:any):Observable<{ yPos:number }> {
        throw new Error('Not yet supported');
    }

    public getScrollPos(closeElement?:any):Promise<number> {
        throw new Error('Not yet supported');
    }

    public scrollToElement(componentInjector:Injector, nativeElement:any, additionalOffset?:number) {
        const thisPos = nativeElement.getBoundingClientRect();
        // In this case the scrolling parent will always be the window
        const yPos    = this.windowRef.scrollY;
        const top     = thisPos.top + yPos + ((additionalOffset) ? additionalOffset : 0);
        this.windowRef.scrollToAnimate(top);
    }


    public scrollToTop() {
        this.windowRef.scrollTo(0, 0);
    }

    public scrollToPoint(x:number, y:number) {
        this.windowRef.scrollTo(x, y);
    }

    public getScrollElement() {
        return null;
    }
}
