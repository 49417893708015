import {ElementRef, Injectable, Injector} from '@angular/core';
import {Router, Scroll} from '@angular/router';
import {filter} from 'rxjs/operators';
import {FacadePlatform} from './facade-platform';
import {VisibilityService} from './visibility.service';
import {ImplementationDataService} from '../implementation-config/implementation-data.service';


@Injectable()
export class AnchorTagService {

    private fragment;
    private anchorHash:{ [id:string]:ElementRef } = {};

    constructor(private router:Router,
                private facadePlatform:FacadePlatform,
                private injector:Injector,
                private visibilityService:VisibilityService,
                private implementationDataService:ImplementationDataService) {
    }

    init() {
        this.router.events
            .pipe(
                filter(event => event instanceof Scroll)
            )
            .subscribe((event:Scroll) => {
                this.fragment = event.anchor;
                this.checkAnchors();
            });
    }

    registerAnchor(anchorName:string, anchorTagRef:ElementRef) {
        this.anchorHash[anchorName] = anchorTagRef;
        this.checkAnchors();
    }

    removeAnchor(anchorName:string) {
        delete this.anchorHash[anchorName];
    }

    checkAnchors() {
        if (this.anchorHash[this.fragment]) {
            this.visibilityService.triggerAllVisible();
            setTimeout(() => {
                if(this.anchorHash[this.fragment]) {
                    this.facadePlatform.scrollToElement(
                        this.injector,
                        this.anchorHash[this.fragment].nativeElement,
                        -this.implementationDataService.getPageHeaderOffset()
                    );
                }
            }, 1000);
        }
    }
}
