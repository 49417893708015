import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Injector, Input, Output} from '@angular/core';
import {AbstractFacadeComponent} from '../shared/ui/abstract-facade.component';
import {BodyRef} from '../shared/ui/body-ref';

@Component({
    selector       : 'app-messages',
    template       : `
        <div class="inner container">
            <span class="text-content" [ngStyle]="{'color': color}" [innerHTML]="message" (click)="isShowingFull = !isShowingFull"></span>
            <button *ngIf="dismissible" type="button" class="close-btn" (click)="onDismiss.emit()"><facade-icon icon="icon-close" [ngStyle]="{'color': color}"></facade-icon></button>
        </div>
        <div class="background" [ngStyle]="{'background-color': bgColor, 'opacity': bgOpacity}"></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppMessagesComponent extends AbstractFacadeComponent {

    @Input()
    message:string;

    @Input()
    dismissible:boolean;

    @Input()
    bgColor:string;

    @Input()
    bgOpacity:number;

    @Input()
    color:string;

    @Output()
    onDismiss = new EventEmitter();

    @HostBinding('class.hidden')
    isHidden = true;

    @HostBinding('class.full')
    isShowingFull = false;

    constructor(injector:Injector, public bodyRef:BodyRef) {
        super(injector);
    }

    @Input()
    set show(value:boolean) {
        if (this.isHidden === !value) return;
        this.isHidden = !value;
        if (value) {
            this.bodyRef.addClass('app-message-showing');
        }
        else {
            this.bodyRef.removeClass('app-message-showing');
        }
    }

    get name() {
        return 'AppMessagesComponent';
    }
}
