import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {combineLatest} from 'rxjs';
import {AbstractNoContentIncludeComponent} from '../../../../../../framework/src/cross-platform/no-content-page/abstract-no-content-include.component';
import {FooterMenuService} from '../../../../../../framework/src/cross-platform/menu/footer-menu.service';
import {FMMenuElementsUI} from '../../../../../../framework/src/cross-platform/external-data/firebase/firebase-ui.types';
import {environment} from '../../../../../../framework/src/cross-platform/implementation/environment';

@Component({
    selector       : 'no-content',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template       : `
        <section [class.no-padding]="noPadding" class="no-content">
            <h2>{{'404.heading' | translate}}</h2>
            <p>{{'404.errorMessage' | translate}}</p>
            <a class="btn btn-primary" [fakeLink]="urlService.buildUrlForHome()" (fakeClick)="handleLink($event)">{{ '404.button.home' | translate }}</a><br>
            <a class="btn btn-outline-primary" [fakeLink]="urlService.buildUrlForContact()" (fakeClick)="handleLink($event)">{{ '404.button.contactUs' | translate }}</a>
<!--            <p>-->
<!--                {{'404.helpfulLinks' | translate}}<br />-->
<!--                <ng-container *ngFor="let link of links$ | async;">-->
<!--                    <a [fakeLink]="link.href" (fakeClick)="handleLink($event)" [target]="link.newTab ? '_blank' : '_self'">{{link.localeKey | translate}}</a><br />-->
<!--                </ng-container>-->
<!--            </p>-->
        </section>
    `
})
export class PepNoContentIncludeComponent extends AbstractNoContentIncludeComponent {
    footerMenuService:FooterMenuService;
    noContentMenuItems:FMMenuElementsUI;

    constructor(injector:Injector) {
        super(injector);
        this.footerMenuService = injector.get(FooterMenuService);

    }

    init() {
        super.init();
        if (environment.uiConfig.noContentMenuElements) {
            this.addSub = combineLatest([
                this.selectWhenReady(s => s.magento.categoryUrlKeyHash),
                this.selectWhenReady(s => s.magento.categoryIDHash),
            ])
                .subscribe(
                    values => {
                        this.noContentMenuItems = this.footerMenuService.mapMenuElements(environment.uiConfig.noContentMenuElements, environment.config.customPageSEOData, values[0], values[1]);
                        this.changeDetectorRef.detectChanges();
                    }
                );
        }
    }
}
