import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {Logger} from '../../../../cross-platform/shared/logger';
import {DomPositionDirective} from '../../../../cross-platform/shared/ui/dom-position.directive';
import {WindowRef} from '../../../../cross-platform/shared/ui/window-ref';
import {UrlService} from '../../../../cross-platform/shared/url.service';
import {debounce, DebouncedReference} from '../../../../cross-platform/shared/util/debounce';
import {
    FMDesktopTopMenuItemCatBut,
    FMDesktopTopMenuItemCustomBut,
    FMDesktopTopMenuItemGroup,
    FMMenuItemType
} from '../../../../cross-platform/external-data/firebase/firebase.types';
import {FakeLinkEvent} from '../../../../cross-platform/shared/ui/fake-link.directive';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, Subscription} from 'rxjs';
import {CategoryHash} from '../../../../cross-platform/external-data/magento/magento.reducer.types';

export interface IMenuDropDown {
    onRollOver:EventEmitter<any>;
    onRollOut:EventEmitter<any>;

    show(rolledOverElement:DomPositionDirective);

    hide();
}

@Component({
    selector       : 'menu-button',
    templateUrl    : './menu-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuButtonComponent implements OnDestroy {

    @Input()
    customIcon:string;

    @Output()
    select = new EventEmitter<FakeLinkEvent>();

    menuClass  = 'hidden';
    menuTop    = 0;
    menuLeft   = 0;
    menuOffset = 0;
    menuElement:FMDesktopTopMenuItemGroup;
    categoryUrlKeyHash:CategoryHash;
    hasSubMenu = false;
    showMenu   = false;
    $buttonName:Observable<string>;
    buttonUrl:string;

    private overButton = false;
    private overMenu   = false;
    private debounceRef:DebouncedReference;
    private button:DomPositionDirective;
    private menu:DomPositionDirective;

    constructor(public logger:Logger,
                public windowRef:WindowRef,
                public translateService:TranslateService,
                public urlService:UrlService,
                public changeDetectorRef:ChangeDetectorRef) {
        this.logger = this.logger.getLogger('MenuButtonComponent');
        // remove unnecessary logging only used when testing this component
        //this.logger.logLevel = LogLevel.TEST;
    }

    @Input()
    set menuData(value:{ item:FMDesktopTopMenuItemGroup, categoryUrlKeyHash:CategoryHash }) {
        this.logger.test('Got the button data: ', value);
        this.menuElement        = value.item;
        this.categoryUrlKeyHash = value.categoryUrlKeyHash;
        this.hasSubMenu         = value.item.dropDown != null;

        if (this.menuElement.type === FMMenuItemType.categoryButton) {
            this.$buttonName = of(this.categoryUrlKeyHash[(<FMDesktopTopMenuItemCatBut>this.menuElement).urlKeyPath].name);
            this.buttonUrl   = this.urlService.buildUrlForProductList(this.categoryUrlKeyHash[(<FMDesktopTopMenuItemCatBut>this.menuElement).urlKeyPath]);
        }
        else {
            this.$buttonName = this.translateService.get((<FMDesktopTopMenuItemCustomBut>this.menuElement).locale);
            this.buttonUrl   = (<FMDesktopTopMenuItemCustomBut>this.menuElement).link;
        }
        this.changeDetectorRef.detectChanges();
    }

    private _dropDownElement:IMenuDropDown;
    private _dropDownSub1:Subscription;
    private _dropDownSub2:Subscription;

    @Input()
    set dropDownElement(value:IMenuDropDown) {
        if (this._dropDownSub1) this._dropDownSub1.unsubscribe();
        if (this._dropDownSub2) this._dropDownSub2.unsubscribe();
        this._dropDownElement = value;
        if (this._dropDownElement) {
            this._dropDownSub1 = this._dropDownElement.onRollOver.subscribe(e => this.menuMouseOver());
            this._dropDownSub2 = this._dropDownElement.onRollOut.subscribe(e => this.menuMouseOut());
        }
    }

    @ViewChildren(DomPositionDirective)
    set positionChildren(children:QueryList<DomPositionDirective>) {
        this.button = children.first;
        this.menu   = children.last;
    }

    ngOnDestroy():void {
        if (this._dropDownSub1) this._dropDownSub1.unsubscribe();
        if (this._dropDownSub2) this._dropDownSub2.unsubscribe();
    }

    close() {
        this.logger.test('Closing the menu');
        this.setShowMenu(false);
    }

    buttonMouseOver() {
        this.logger.test('button mouse over');
        this.overButton = true;
        this.setShowMenu(true); // set immediate here as this is the initial event
        this.checkState();
    }

    buttonMouseOut() {
        this.logger.test('button mouse out');
        this.overButton = false;
        this.checkState();
    }

    menuMouseOver() {
        this.logger.test('menu mouse over');
        this.overMenu = true;
        this.checkState();
    }

    menuMouseOut() {
        this.logger.test('menu mouse out');
        this.overMenu = false;
        this.checkState();
    }

    checkState() {
        this.logger.test('Debounce check');
        this.debounceRef = debounce(
            () => {
                this.setShowMenu(this.overMenu || this.overButton);
                this.logger.test('Determined the menu to be open ==', this.overMenu || this.overButton);
            },
            100,
            this.debounceRef
        );
    }

    setShowMenu(value:boolean) {
        if (this.showMenu !== value) {
            this.showMenu = value;
            if (this.showMenu) {
                this.menuTop   = 0;
                this.menuLeft  = 0;
                this.menuClass = 'invisible';

                // Allow some time to render the menu's size
                setTimeout(() => this.positionMenu(), 50);
            }
            else {
                this.menuClass = 'hidden';
            }
            if (this._dropDownElement) {
                if (this.showMenu) {
                    this._dropDownElement.show(this.button);
                }
                else {
                    this._dropDownElement.hide();
                }
            }
            this.changeDetectorRef.detectChanges();
        }
    }

    positionMenu() {
        const buttonPos = this.button.getAbsoluteBounds();
        this.logger.test('Button positioning: ', buttonPos);
        this.menuTop  = Math.round(buttonPos.bottom);
        this.menuLeft = Math.round(buttonPos.left);

        // Calculate any window offset needed
        const menuPos     = this.menu.getAbsoluteBounds();
        const windowWidth = this.windowRef.innerWidth;
        const difference  = (buttonPos.left + menuPos.width) - windowWidth;
        this.logger.test('difference: ', windowWidth, buttonPos.left, menuPos.width, difference);
        if (difference > 0) {
            this.menuOffset = Math.round(-(difference + 20));
        }
        else {
            this.menuOffset = -20;
        }
        this.menuClass = '';

        this.changeDetectorRef.detectChanges();
    }


}
