import {Pipe} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({name: 'price'})
export class PricePipe extends CurrencyPipe {

    transform(value: number | string, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null
    transform(value: null | undefined, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): null
    transform(value: number | string | null | undefined, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null {
        return super.transform(value, 'ZAR', 'symbol-narrow');
        //if (value == null || isNaN(Number(value))) return '0.00';
        //return PriceUtils.formatPrice(value);
    }
}
