import {Component, Injector, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, SelectControlValueAccessor} from '@angular/forms';
import {BuildCustomFormElementProvider} from '../../../cross-platform/forms/abstract-form.component';
import {AbstractProxyFormComponent} from '../../../cross-platform/forms/abstract-proxy-form.component';
import {ISelectOption} from '../../../cross-platform/forms/forms.types';

@Component({
    selector   : 'd-form-select',
    templateUrl: './d-form-select.component.html',
    providers  : [BuildCustomFormElementProvider(DFormSelectComponent)]
})
export class DFormSelectComponent extends AbstractProxyFormComponent {
    @ViewChild(SelectControlValueAccessor, {static: true})
    proxyFormElement:ControlValueAccessor | null;

    @Input()
    label:string;

    @Input()
    placeholder:string;

    @Input()
    options:ISelectOption[];

    @Input()
    showRequired:boolean;

    constructor(injector:Injector) {
        super(injector);
    }

    get inputClass() {
        return this.control.invalid && this.control.touched ? 'is-invalid' : (this.control.touched ? 'is-valid' : '');
    }
}
