import {Component, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
    AbstractLogoutPromptComponent
} from '../../../../cross-platform/auth/logout-prompt/abstract-logout-prompt.component';

@Component({
    selector: 'logout-modal',
    template: `
        <div class="modal-header">
            <h4 class="modal-title modal-basic-title">{{'logoutPrompt.title' | translate}}</h4>
        </div>
        <div class="modal-body">
            <div [innerHTML]="'logoutPrompt.message' | translate"></div>
            <div class="btn-holder">
                <a class="btn btn-primary" href="#" (click)="completeCheckout($event)">{{'logoutPrompt.completeCheckout' | translate}}</a><br /><br />
                <a class="btn btn-secondary" href="#" (click)="handleLogout($event)">{{'logoutPrompt.logoutButton' | translate}}</a>
            </div>
        </div>
    `
})
export class LogoutPromptComponent extends AbstractLogoutPromptComponent {
    ngbActiveModal:NgbActiveModal;

    constructor(injector:Injector) {
        super(injector);
        this.ngbActiveModal = injector.get(NgbActiveModal);
        this.router         = injector.get(Router);
    }

    handleLogout(event:Event) {
        super.handleLogout(event);
        this.ngbActiveModal.close(LogoutPromptComponent.LOGOUT);
    }

    completeCheckout(event:Event) {
        super.completeCheckout(event);
        this.ngbActiveModal.close(LogoutPromptComponent.CHECKOUT);
    }

    closeModal() {
        this.ngbActiveModal.close(LogoutPromptComponent.CANCEL);
    }

    get name() {
        return 'LogoutPromptComponent';
    }
}
