import {Pipe, PipeTransform} from '@angular/core';

// | isPopulated
@Pipe({name: 'isPopulated'})
export class IsPopulatedPipe implements PipeTransform {

    transform(value:any):boolean {
        return (value != null && value !== '' && value !== ' ' && value !== 'null' && value !== 'NULL');
    }
}
