import {PepkorCategoryapiDataCategoryapiTreeInterface} from '../../external-data/magento/magento.types';

export enum FilterType {
    // Can be used for sub categories
    //SelectableTree  = 'SelectableTree',
    Checkbox   = 'Checkbox',
    PriceRange = 'PriceRange',
    Links      = 'Links',
    Nav        = 'Nav',
    Toggle     = 'Toggle'
    //Radio           = 'Radio',
    //Range           = 'Range',
    //RangeGroups     = 'RangeGroups',
}

// Reusable
export interface BaseFilterModel {
    id?:string | number;
    localeKey?:string;
    label?:string;
}


// Grouping
export interface FilterGroup extends BaseFilterModel {
    controls:(FilterControlCheckbox | FilterControlPriceRange | FilterControlLinks | SortControlHolder)[];
    collapsed:boolean;
    collapsable?:boolean;
}


// Abstract control, specific controls below
export interface AbstractFilterControl extends BaseFilterModel {
    type:FilterType;
    collapsed:boolean;
    selections?:AttributeSelection[];
}

export interface FilterCategoryNav {
    id:number;
    name:string;
    parent_id:number;
    level:number;
    urlKeyPath:string;
    active:boolean;
    product_count?:number;
    children: FilterCategoryNav[]
}
export interface FilterLink {
    id:number;
    name:string;
    parent_id:number;
    level:number;
    urlKeyPath:string;
    active:boolean;
}


/*
// Select tree control
export interface FilterControlSelectTree extends AbstractFilterControl {
    type:FilterType.SelectableTree;
    items:FilterControlSelectTreeItem[];
    // No selected item
}
export interface FilterControlSelectTreeItem extends BaseFilterModel {
    children?:FilterControlSelectTreeItem[];
}
*/

export interface FilterControl extends AbstractFilterControl {
    type:FilterType;
    compareType:FilterControlCheckboxType;
    attributeCode:string;
}

// Checkbox control
export interface FilterControlCheckbox extends FilterControl {
    options:FilterControlCheckboxOption[];
}

export interface FilterControlNav {
    localeKey?:string;
    id:string;
    type:FilterType;
    options:FilterCategoryNav[];
    collapsed:boolean;
    name:string;
}

export interface FilterControlLinks {
    id:string;
    type:FilterType;
    options:FilterLink[];
    collapsed:boolean;
    name:string;
}

export interface SortControlHolder extends FilterControl {
    id:string;
}

export interface FilterControlPriceRange extends FilterControl {
    pricingSteps:number[];
    selectedRange:number[];
}

export enum FilterControlCheckboxType {
    Select = 'Select',
    Range  = 'Range'
}

export interface FilterControlCheckboxOption extends BaseFilterModel {
    position?:number;
    count?:number;
    values:Array<string | number | null>;
}


// Range control
/*
export interface FilterControlRange extends AbstractFilterControl {
    type:FilterType.Range;
    attributeCode:string;
    min:number;
    max:number;
    step?:number;
}

*/

export interface SortOption {
    localeKey?:string;
    sortField:string;
    sortDescending:boolean;
    selected?:boolean;
    value?:string | number;
}


// Selections
// attr_attr_code=compareType,value,valueN
// const temp = `attr_color=eq,red,black&attr_price=gt,100&attr_price=lt,500`;
export interface AttributeSelection {
    attributeCode:string;
    compareType:FilterControlCheckboxType;
    // This is sometimes numbers but lets force strings for comparisons
    values:Array<string | number>;
}
