import {AbstractAutoSignOutService} from '../../../../cross-platform/auth/auto-logout/abstract-auto-sign-out.service';
import {Injectable, Injector} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class AutoSignOutService extends AbstractAutoSignOutService {

    private modalRef:NgbModalRef;
    private ngbModal:NgbModal;

    constructor(injector:Injector) {
        super(injector);
        this.ngbModal = injector.get(NgbModal);
    }

    showAutoSignOutDialog() {
        // TODO Show dialog

        /*this.modalRef = this.ngbModal.open(StoreFinderPromptComponent, {windowClass: StoreFinderPromptComponent.CLASS});
        this.modalRef.result
            .then(
                result => {
                    this.modalRef = null;
                    if (result === StoreFinderPromptComponent.ENTER_ADDRESS) {

                    }
                },
                other => {
                    this.modalRef = null;

                }
            );*/
    }

    get isDialogShowing() {
        return false;
    }

    removeAutoSignOutDialog() {
    }
}
