import {gql} from 'apollo-angular';

export const SET_GUEST_PHONE_NUMBER_AND_EMAIL_ON_CART = gql`
    mutation setGuestPhoneNumberAndEmailOnCart($input: SetGuestPhoneNumberAndEmailOnCartInput) {
        setGuestPhoneNumberAndEmailOnCart(input: $input) {
            cart {
                email
                cellphone_number
            }
        }
    }
`;
