import {Directive, ElementRef, Input} from '@angular/core';
import {FacadePlatform} from '../shared/facade-platform';

/**
 * This is a cross platform icon component.
 * e.g. Desktop
 * <facade-icon icon="icon-spinner fa-spin"></facade-icon>
 */
@Directive({
    selector: 'facade-icon', // tslint:disable-line
    host    : { // tslint:disable-line
        'aria-hidden': 'true',
        'role'       : 'img'
    }
})
export class FacadeIconDirective {


    constructor(public facadePlatform:FacadePlatform,
                public el:ElementRef<Element>) {
    }


    @Input('icon')
    set facadeIcon(value:string) {
        const classes = this.el.nativeElement.classList;

        /*if (this.facadePlatform.isMobile){
            if (!classes.contains(className)){
                this.el.nativeElement.classList.add(className);
            }
        }
        else {*/
        if (!classes.contains('fa')) {
            this.el.nativeElement.classList.add('fa');
        }
        if (!classes.contains('icon')) {
            this.el.nativeElement.classList.add('icon');
        }
        value.split(' ').forEach(className => {
            if (!classes.contains(className)) {
                this.el.nativeElement.classList.add(className);
            }
        });
        //}
    }

    /*
    Old version didn't work with external classes
    @HostBinding('class')
    cssClass:string;

    @Input('icon')
    set facadeIcon(value:string) {
        let classes = '';
        /!*
        // Doesn't seem to work :(
        if (this.cssClass != null){
            classes = this.cssClass;
        }*!/
        if (this.facadePlatform.isMobile) {
            /!*if (classes.indexOf('icon') === -1){
                classes += ' icon';
            }*!/
            if (classes.indexOf(value) === -1) {
                classes += ' ' + value;
            }
        }
        else {
            if (classes.indexOf('fa') === -1) {
                classes += ' fa';
            }
            if (classes.indexOf('icon') === -1) {
                classes += ' icon';
            }
            if (classes.indexOf(value) === -1) {
                classes += ' ' + value;
            }
        }
        this.cssClass = classes;
    }*/
}
