import {Directive, ElementRef, Input, OnDestroy, Renderer2} from '@angular/core';
import {VisibilityService} from '../../visibility.service';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[lazyBgImage]' // tslint:disable-line
})
export class LazyBgImgDirective implements OnDestroy {

    @Input()
    set lazyBgImage(value:string) {
        this._lazyBgImage = value;
        if (value && this.visibleSubscription == null) {
            this.visibleSubscription = this.visibilityService
                .elementInSight(this.elementRef)
                .subscribe(() => this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', `url(${this._lazyBgImage})`));
        }
    }

    private _lazyBgImage;
    private visibleSubscription:Subscription;

    constructor(private elementRef:ElementRef,
                private visibilityService:VisibilityService,
                private renderer:Renderer2) {
    }

    ngOnDestroy():void {
        if (this.visibleSubscription) this.visibleSubscription.unsubscribe();
    }

}
