import {ProductInteractiveAttr} from '../product-page/product.types';


export enum StockFinderStateEnum {
    STORE_FETCH_ERROR
}

export enum StockFinderQueryTypeEnum {
    // Store set request to show an initial set, no position yet
    PRE_POSITION,
    // A position has been located from geo coords or address
    POSITION,
    // New store set fetched because of a map move
    MAP_MOVE
}

export enum StockOnHandType {
    ACK = 'ack',
    PEP = 'pep'
}

export interface SkuLabel {
    sku:string;
    label:string;
    source?:ProductInteractiveAttr;
    inStock?:boolean;
}

export interface StoreItem {
    branch_name: string;
    branch_no: number;
    branch_street_address: string;
    branch_telephone: string;
    business_hours?: any;
    location?: {
        lat:number;
        lon:number;
    }
    showHours?: boolean;
}
export interface StockFinderMapEvent {
    location?: google.maps.LatLng;
    radius?: number;
    zoom?: number;
    moved?: boolean;
    zoomed?: boolean;
}
