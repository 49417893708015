export interface NewsletterResponse {
    Response?:{ ResponseCode?:string };
    //status?:string; // This is a boolean status returned as false when there is an uncaught error error code
}

export const NewsletterResponseCodeEnum = {
    SUCCESS             : '0000',
    SERVICE_OFFLINE     : '1000',
    PARAM_MISSING       : '1001',
    INVALID_PARAM       : '1002',
    EMAIL_ALREADY_EXISTS: '1003',
    UNEXPECTED_ERROR    : '9000',
};

/*
This has not been added to any swagger file so its swimming around in emails.
Leaving here till the swagger has been completed


In general terms:
1000 - Service offline
1001 - Required Parm not provided
1002 - Invalid Parm
1003 - Entity already exists

In specific terms:
1000
 - Could not connect to the AdobeDataLayerDB

1001
 - You must provide a valid 'email_address'
 - You must provide a valid 'brand'
 - You must provide a valid 'type'

1002
 - Invalid 'email_address' supplied
 - Invalid 'brand' supplied
 - Invalid 'type' supplied

1003
 - Provided Email_Address/Type/Brand combination already registered
*/
