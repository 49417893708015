import {Injectable, Injector} from '@angular/core';
import {AbstractFacadeService} from '../abstract-facade.service';
import {AckStoresByLocationResponse} from '../../../../../generated/graphql';
import {StoresByCityResponse} from '../../../../../generated/graphql';
import {ExternalResponse} from '../external-response';
import {EnhancedAckStore} from '../../checkout/delivery/enhanced-ack-store';
import {Observable, of} from 'rxjs';
import {FETCH_STORES_BY_LOCATION, FETCH_STORES_BY_LOCATION_CACHE} from './queries/fetch-stores-by-location';
import {FETCH_STORES_BY_CITY, FETCH_STORES_BY_CITY_CACHE} from './queries/fetch-stores-by-city';
import {switchMap} from 'rxjs/operators';

interface FetchStoresByLocationResponse {
    ackStoresByLocation:AckStoresByLocationResponse;
}

interface FetchStoresByCityResponse {
    storesByCity:StoresByCityResponse;
}

export interface AckStoresByLocation {
    stores?:EnhancedAckStore[];
    total?:number;
}

export interface StoresByCity {
    stores?:EnhancedAckStore[];
    total?:number;
}

@Injectable()
export class SharedPhysicalStoreService extends AbstractFacadeService {

    constructor(injector:Injector) {
        super(injector);
    }

    fetchStoresByLocation(clickAndCollectOnly:boolean, lat:number, lon:number, from?:number, size?:number, radius?:number):Observable<ExternalResponse<AckStoresByLocation>> {
        return this.apollo.query<FetchStoresByLocationResponse>({
            query    : FETCH_STORES_BY_LOCATION,
            variables: {
                lat                : lat,
                lon                : lon,
                from               : from,
                size               : size,
                clickAndCollectOnly: clickAndCollectOnly,
                radius             : radius
            },
        }, FETCH_STORES_BY_LOCATION_CACHE).pipe(
            switchMap(response => {
                // @ts-ignore
                const newResponse:ExternalResponse<AckStoresByLocation> = Object.assign({}, response);
                newResponse.data                                        = {
                    stores: response.data.ackStoresByLocation.stores.map(store => new EnhancedAckStore(store)),
                    total : response.data.ackStoresByLocation.total
                };
                return of(newResponse);
            })
        );
    }
    
    fetchStoresByCity(city:string):Observable<ExternalResponse<StoresByCity>> {
        return this.apollo.query<FetchStoresByCityResponse>({
            query    : FETCH_STORES_BY_CITY,
            variables: {
                city                : city
            },
        }, FETCH_STORES_BY_CITY_CACHE).pipe(
            switchMap(response => {
                // @ts-ignore
                const newResponse:ExternalResponse<StoresByCity> = Object.assign({}, response);
                newResponse.data                                        = {
                    stores: response.data.storesByCity.stores.map(store => new EnhancedAckStore(store)),
                    total : response.data.storesByCity.total
                };
                return of(newResponse);
            })
        );
    }

    getName():string {
        return 'SharedPhysicalStoreService';
    }
}
