import {Injectable, Injector} from '@angular/core';
import {AbstractFacadeService} from '../abstract-facade.service';
import {ExternalResponse} from '../external-response';
import {AppDynamicConfig, AppDynamicConfigMessage, AppDynamicMessageOccurrenceType} from './model/wordpress.types';
import {WP_GET_PAGES} from './gql-queries/wp-get-pages';
import {catchError, map, mergeMap, repeat} from 'rxjs/operators';
import {Observable, of, timer} from 'rxjs';
import {environment} from '../../implementation/environment';
import {ArrayUtil} from '../../shared/util/array.util';
import {WpPagesResponse} from './wordpress-logic.service';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {StorageService} from '../../shared/ui/storage/storage.service';

dayjs.extend(customParseFormat);

@Injectable()
export class WordpressDynamicSettingsService extends AbstractFacadeService {
    private storagePrefix = 'message-dismissed-';
    private pageSlug      = 'app-dynamic-settings';
    private pollInterval  = 5 * 60 * 2000;

    private storageService:StorageService;

    //private pollInterval  = 2000;

    constructor(injector:Injector) {
        super(injector);
        this.storageService = injector.get(StorageService);
    }

    getDynamicSettingsOnInterval():Observable<ExternalResponse<AppDynamicConfig>> {
        let count = 0;
        return of(null)
            .pipe(
                mergeMap(() => {
                    count++;
                    return count === 1 ? of(null) : timer(this.pollInterval);
                }),
                mergeMap(() => this.getDynamicSettings()),
                // Ensure errors don't stop the stream, error handling to be done in caller
                catchError(err => of(err)),
                repeat()
            );
    }

    getDynamicSettings():Observable<ExternalResponse<AppDynamicConfig>> {
        const variables = {
            facade_url_tree: `${environment.config.api.wordpressRootPages[0]}/${this.pageSlug}`,
            status         : 'publish'
        };

        this.logger.debug('fetching app config');
        return this.apollo
            .query<WpPagesResponse>({
                query    : WP_GET_PAGES,
                variables: variables
            }, false)
            .pipe(
                map(res => {
                    const newRes = <ExternalResponse<AppDynamicConfig>><any>res;
                    newRes.data  = res.data?.wpPages?.items?.find(page => page.slug === this.pageSlug)?.acf || {};
                    return newRes;
                }),
                map(res => {
                    res.data.appMessages = this.filterAppMessagesAndCleanup(res.data.appMessages || []);
                    return res;
                })
            );
    }

    /**
     * Formats the message occurrence type to an enum value as AppDynamicMessageOccurrenceType
     *
     * @returns Occurence casted to AppDynamicMessageOccurrenceType
     */
    private getAppMessageOccurrenceType(message:AppDynamicConfigMessage) {
        return Boolean(message.occurrence)
            ? message.occurrence as AppDynamicMessageOccurrenceType
            : AppDynamicMessageOccurrenceType.onceOff;
    }

    private filterAppMessagesAndCleanup(appMessages:AppDynamicConfigMessage[]) {
        const keys    = this.storageService.getAllKeys().filter(key => key.indexOf(this.storagePrefix) === 0);
        const keyHash = ArrayUtil.valueKeyTrueHash(keys);

        appMessages = appMessages.filter(message => {
            if (!message.enabled) {
                return false;
            }

            const format   = 'DD-MM-YYYY HH:mm:ss Z';
            const timezone = ' +02:00';

            const now           = new Date();
            const nowjs         = dayjs(now);
            const nowjsDate     = nowjs.toDate();
            const nowDateString = nowjs.format('DD-MM-YYYY');

            const occurrence:AppDynamicMessageOccurrenceType = this.getAppMessageOccurrenceType(message);

            const startTime = message.startTime ? `${message.startTime}` : '00:00:00';
            const endTime   = message.endTime ? `${message.endTime}` : '23:59:59';

            const startTimeNowDate = dayjs(`${nowDateString} ${startTime} ${timezone}`, format).toDate();
            const endTimeNowDate   = dayjs(`${nowDateString} ${endTime} ${timezone}`, format).toDate();

            const nowTimeIsRecurringTime = nowjsDate >= startTimeNowDate && nowjsDate <= endTimeNowDate;

            // Parse dates
            // We make the assumption dates are entered and returned in SAST as they are
            // manually edited in a UI by SA users who won't know to use GMT values
            // therefore we need to add the timezone to the date before parsing
            // start is required, end is optional            
            const startDate = dayjs(message.startDate + timezone, format).toDate();
            const endDate   = message.endDate ? dayjs(message.endDate + timezone, format).toDate() : null;

            //this.logger.debug(`Start Date Str: ${message.startDate} | Parsed: ${startDate}`);
            //this.logger.debug(`End Date Str: ${message.endDate} | Parsed: ${endDate}`);

            const isPastStart = now.getTime() >= startDate.getTime();
            const isBeforeEnd = endDate == null ? true : now.getTime() <= endDate.getTime();

            let show = false;

            if (this.isReccuringOccurrence(occurrence)) {
                show = isPastStart && isBeforeEnd && nowTimeIsRecurringTime;
            }
            else {
                show = isPastStart && isBeforeEnd;
            }

            const key       = this.getAppMessageStorageKey(message);
            const dismissed = keyHash[key];
            delete keyHash[key];

            if (dismissed === true) {
                return false;
            }

            return show;
        });

        this.logger.debug('Remaining keys will be deleted: ', keyHash);

        // Now, importantly, lets delete any remaining dismissed keys as they are for old deleted messages
        Object.keys(keyHash).forEach(key => this.storageService.removeItem(key));

        return appMessages;
    }

    /**
     * Produce an app message storage key defined by occurence type for dismissal purposes
     * @returns An app message storage key
     */
    getAppMessageStorageKey(message:AppDynamicConfigMessage):string {
        const storageSuffix = this.getAppMessageStorageSuffix(message);

        return this.storagePrefix + message.id.replace(/[^a-zA-Z0-9_-]/, '') + storageSuffix;
    }

    /**
     * Produce an app message storage suffix defined by occurence type. Once off types do not need a date suffix
     * @returns
     */
    getAppMessageStorageSuffix(message:AppDynamicConfigMessage):string {
        const occurrence:AppDynamicMessageOccurrenceType = this.getAppMessageOccurrenceType(message);

        if (!this.isReccuringOccurrence(occurrence)) {
            return '';
        }

        const now           = new Date();
        const nowjs         = dayjs(now);
        const nowDateString = nowjs.format('DD-MM-YYYY');

        const startTime = message.startTime ? `${message.startTime}` : '00:00:00';
        const endTime   = message.endTime ? `${message.endTime}` : '23:59:59';

        return `-${nowDateString}:${startTime}-${endTime}`;
    }

    /**
     * Check whether the occurrence is recurring or not
     *
     * @returns Boolean value whether the message occurrence is a recurring type or not
     */
    isReccuringOccurrence(occurrence:AppDynamicMessageOccurrenceType) {
        if (occurrence === AppDynamicMessageOccurrenceType.recurringDaily) {
            return true;
        }
        return false;
    }

    dismissAppMessage(messages:AppDynamicConfigMessage[]):AppDynamicConfigMessage[] {
        if (!messages || messages.length <= 0) {
            return messages;
        }

        const message = messages[0];
        const key     = this.getAppMessageStorageKey(message);

        this.storageService.setItemBool(key, true);
        messages.shift();

        return messages;
    }


    getName():string {
        return 'WordpressDynamicSettingsService';
    }
}
