import {
    CheckoutOptions,
    CheckoutStepEnum,
    GAAuthEvent,
    GACellularContractsFormEvent,
    GACellularContractsProgressEvent,
    GAProductClick,
    GAProductDetail,
    GAProductFieldObject,
    GASohLocationEvent,
    GASohModalEvent,
    GASohProductEvent,
    GASohProgressEvent,
    GASohStoreEvent,
    GASohUIEvent,
    GAStockInStoreLocationEvent,
    GAStockInStoreModalEvent,
    GAStockInStoreProgressEvent,
    GAStockInStoreStoreEvent,
    GAStockInStoreUiEvent
} from './google-analytics.types';
import {
    GAAddPaymentInfo,
    GAAddShippingInfo,
    GAAddToCartGA4,
    GABarcodeSearch,
    GABarcodeSearchFailed,
    GABarcodeSearchModal,
    GABarcodeSearchSuccess,
    GACheckInStoreStock,
    GAContactSubmission,
    GAFileDownload,
    GAFindAStoreCall,
    GAFindAStoreDirections,
    GAFindAStoreSearch,
    GALogin,
    GANewsletterSignupSubmit,
    GAPageView,
    GAPeachNonce, GAProductsFilter,
    GASearch,
    GASelectItem,
    GAShareContent,
    GASignUp,
    GAViewCart,
    GAViewItem,
    GAViewItemList
} from './google-analytics-4-internal.types';

export namespace GTMActions {
    export class TrackEvent {
        static readonly type = '[GTM Actions] Track Event';

        constructor(public eventName:string, public meta?:object) {
        }
    }

    export class TrackPage {
        static readonly type = '[GTM Actions] Track Page';

        constructor(public page:string) {
        }
    }

    export class TrackAddThisError {
        static readonly type = '[GTM Actions] Track Add This Error';

        constructor(public url:URL) {
        }
    }

    export class TrackEvent404 {
        static readonly type = '[GTM Actions] TrackEvent404';

        constructor(public url:URL) {
        }
    }

    export class TrackEventFirebaseUploadError {
        static readonly type = '[GTM Actions] TrackEventFirebaseUploadError';

        constructor(public errorMsg:string) {
        }
    }

    export class TrackProductClick {
        static readonly type = '[GTM Actions] TrackProductClick';

        constructor(public gaProductClick:GAProductClick) {
        }
    }

    export class TrackProductDetail {
        static readonly type = '[GTM Actions] TrackProductDetail';

        constructor(public gaProductDetail:GAProductDetail) {
        }
    }

    export class TrackAddToCart {
        static readonly type = '[GTM Actions] TrackAddToCart';

        constructor(public cartItem:{ sku:string, simpleSku:string, name:string, price:number, list?:string, category?:string }, public quantity:number, public isPDP?:boolean) {
        }
    }

    export class TrackRemoveFromCart {
        static readonly type = '[GTM Actions] TrackRemoveFromCart';

        constructor(public cartItem:{ sku:string, simpleSku:string, name:string, price:number, category?:string }, public quantity:number, public option:'user-removed' | 'decrease-quantity' | 'error-removed') {
        }
    }

    export class TrackCheckout {
        static readonly type = '[GTM Actions] TrackCheckout';

        constructor(public cartId:string, public products:GAProductFieldObject[], public list?:string) {
        }
    }

    export class TrackCheckoutStep {
        static readonly type = '[GTM Actions] TrackCheckoutStep';

        constructor(public cartId:string, public step:CheckoutStepEnum, public option?:CheckoutOptions) {
        }
    }

    export class TrackSohModalEvent {
        static readonly type = '[GTM Actions] TrackSohModalEvent';

        constructor(public eventData:GASohModalEvent) {
        }
    }

    export class TrackCheckoutAuthentication {
        static readonly type = '[GTM Actions] TrackCheckoutAuthentication';

        constructor(public option:CheckoutOptions, public cartId:string) {
        }
    }

    export class TrackCheckoutOption {
        static readonly type = '[GTM Actions] TrackCheckoutOption';

        constructor(public cartId:string, public step:CheckoutStepEnum, public option?:CheckoutOptions) {
        }
    }

    export class TrackPurchase {
        static readonly type = '[GTM Actions] TrackPurchase';

        constructor(public orderNumber:string, public amount:number, public shipping:number, public products:GAProductFieldObject[]) {
        }
    }

    export class TrackAuthenticationStep {
        static readonly type = '[GTM Actions] TrackAuthenticationStep';

        constructor(public eventData:GAAuthEvent) {
        }
    }

    export class TrackStockInStoreModalEvent {
        static readonly type = '[GTM Actions] TrackStockInStoreModalEvent';

        constructor(public eventData:GAStockInStoreModalEvent) {
        }
    }

    export class TrackStockInStoreProgressEvent {
        static readonly type = '[GTM Actions] TrackStockInStoreProgressEvent';

        constructor(public eventData:GAStockInStoreProgressEvent) {
        }
    }

    export class TrackStockInStoreUIEvent {
        static readonly type = '[GTM Actions] TrackStockInStoreUIEvent';

        constructor(public eventData:GAStockInStoreUiEvent) {
        }
    }

    export class TrackStockInStoreStoreEvent {
        static readonly type = '[GTM Actions] TrackStockInStoreStoreEvent';

        constructor(public eventData:GAStockInStoreStoreEvent) {
        }
    }

    export class TrackStockInStoreLocationEvent {
        static readonly type = '[GTM Actions] TrackStockInStoreLocationEvent';

        constructor(public eventData:GAStockInStoreLocationEvent) {
        }
    }

    export class TrackSohProgressEvent {
        static readonly type = '[GTM Actions] TrackSohProgressEvent';

        constructor(public eventData:GASohProgressEvent) {
        }
    }

    export class TrackSohProductEvent {
        static readonly type = '[GTM Actions] TrackSohProductEvent';

        constructor(public eventData:GASohProductEvent) {
        }
    }

    export class TrackSohUIEvent {
        static readonly type = '[GTM Actions] TrackSohUIEvent';

        constructor(public eventData:GASohUIEvent) {
        }
    }

    export class TrackSohStoreEvent {
        static readonly type = '[GTM Actions] TrackSohStoreEvent';

        constructor(public eventData:GASohStoreEvent) {
        }
    }

    export class TrackSohLocationEvent {
        static readonly type = '[GTM Actions] TrackSohLocationEvent';

        constructor(public eventData:GASohLocationEvent) {
        }
    }

    export class TrackCellularContractFormEvent {
        static readonly type = '[GTM Actions] TrackCellularContractFormEvent';

        constructor(public eventData:GACellularContractsFormEvent) {
        }
    }

    export class TrackCellularContractProgressEvent {
        static readonly type = '[GTM Actions] TrackCellularContractProgressEvent';

        constructor(public eventData:GACellularContractsProgressEvent) {
        }
    }

    export class TrackFileDownload {
        static readonly type = '[GTM Actions] TrackFileDownload';

        constructor(public eventData:GAFileDownload) {
        }
    }

    // GA 4 only events
    export class TrackSelectItem {
        static readonly type = '[GTM Action] TrackSelectItem';

        constructor(public eventData:GASelectItem) {
        }
    }

    export class TrackViewItem {
        static readonly type = '[GTM Action] TrackViewItem';

        constructor(public eventData:GAViewItem) {
        }
    }

    export class TrackAddToCartGA4 {
        static readonly type = '[GTM Action] TrackAddToCartGA4';

        constructor(public eventData:GAAddToCartGA4) {
        }
    }

    export class TrackViewItemList {
        static readonly type = '[GTM Action] TrackViewItemList';

        constructor(public eventData:GAViewItemList) {
        }
    }

    export class TrackAddShippingInfo {
        static readonly type = '[GTM Action] TrackAddShippingInfo';

        constructor(public eventData:GAAddShippingInfo) {
        }
    }

    export class TrackAddPaymentInfo {
        static readonly type = '[GTM Action] TrackAddPaymentInfo';

        constructor(public eventData:GAAddPaymentInfo) {
        }
    }

    export class TrackViewCart {
        static readonly type = '[GTM Action] TrackViewCart';

        constructor(public eventData:GAViewCart) {
        }
    }

    export class TrackPeachNonce {
        static readonly type = '[GTM Action] TrackPeachNonce';

        constructor(public eventData:GAPeachNonce) {
        }
    }

    // General
    export class TrackSignUp {
        static readonly type = '[GTM Action] TrackSignUp';

        constructor(public eventData:GASignUp) {
        }
    }

    export class TrackGALogin {
        static readonly type = '[GTM Action] TrackGALogin';

        constructor(public eventData:GALogin) {
        }
    }

    export class TrackGAShareContent {
        static readonly type = '[GTM Action] TrackGAShareContent';

        constructor(public eventData:GAShareContent) {
        }
    }

    export class TrackGAPageView {
        static readonly type = '[GTM Action] TrackGAPageView';

        constructor(public eventData:GAPageView) {
        }
    }

    export class TrackGANewsletterSignupSubmit {
        static readonly type = '[GTM Action] TrackGANewsletterSignupSubmit';

        constructor(public eventData:GANewsletterSignupSubmit) {
        }
    }

    export class TrackGAContactSubmission {
        static readonly type = '[GTM Action] TrackGAContactSubmission';

        constructor(public eventData:GAContactSubmission) {
        }
    }

    export class TrackGAFindAStoreSearch {
        static readonly type = '[GTM Action] TrackGAFindAStoreSearch';

        constructor(public eventData:GAFindAStoreSearch) {
        }
    }

    export class TrackGAFindAStoreCall {
        static readonly type = '[GTM Action] TrackGAFindAStoreCall';

        constructor(public eventData:GAFindAStoreCall) {
        }
    }

    export class TrackGAFindAStoreDirections {
        static readonly type = '[GTM Action] TrackGAFindAStoreDirections';

        constructor(public eventData:GAFindAStoreDirections) {
        }
    }


    export class TrackGASearch {
        static readonly type = '[GTM Action] TrackGASearch';

        constructor(public eventData:GASearch) {
        }
    }

    export class TrackGACheckInStoreStock {
        static readonly type = '[GTM Action] TrackGACheckInStoreStock';

        constructor(public eventData:GACheckInStoreStock) {
        }
    }

    export class TrackGABarcodeSearchModal {
        static readonly type = '[GTM Action] TrackGABarcodeSearchModal';

        constructor(public eventData:GABarcodeSearchModal) {
        }
    }

    export class TrackGABarcodeSearch {
        static readonly type = '[GTM Action] TrackGABarcodeSearch';

        constructor(public eventData:GABarcodeSearch) {
        }
    }

    export class TrackGABarcodeSearchSuccess {
        static readonly type = '[GTM Action] TrackGABarcodeSearchSuccess';

        constructor(public eventData:GABarcodeSearchSuccess) {
        }
    }

    export class TrackGABarcodeSearchFailed {
        static readonly type = '[GTM Action] TrackGABarcodeSearchFailed';

        constructor(public eventData:GABarcodeSearchFailed) {
        }
    }
    export class TrackGAProductsFilter {
        static readonly type = '[GTM Action] TrackGAProductsFilter';

        constructor(public eventData:GAProductsFilter) {
        }
    }
}
