// !!! NB DO NOT RENAME THESE ERROR CODES! THEY MAP BACK TO THE LIB !!!
export enum LibPhoneErrorCodes {
    MALFORMED_JSON       = 'MALFORMED_JSON',
    INVALID_PARAMETER    = 'INVALID_PARAMETER',
    UNEXPECTED           = 'UNEXPECTED',
    INVALID_COUNTRY_CODE = 'INVALID_COUNTRY_CODE',
    NOT_A_NUMBER         = 'NOT_A_NUMBER',
    TOO_SHORT_AFTER_IDD  = 'TOO_SHORT_AFTER_IDD',
    TOO_SHORT_NSN        = 'TOO_SHORT_NSN',
    TOO_LONG             = 'TOO_LONG'
}

export enum LibPhoneType {
    FIXED_LINE           = 'FIXED_LINE',
    MOBILE               = 'MOBILE',
    FIXED_LINE_OR_MOBILE = 'FIXED_LINE_OR_MOBILE',
    TOLL_FREE            = 'TOLL_FREE',
    PREMIUM_RATE         = 'PREMIUM_RATE',
    SHARED_COST          = 'SHARED_COST',
    VOIP                 = 'VOIP',
    PERSONAL_NUMBER      = 'PERSONAL_NUMBER',
    PAGER                = 'PAGER',
    UAN                  = 'UAN',
    VOICEMAIL            = 'VOICEMAIL',
    UNKNOWN              = 'UNKNOWN',
}

export interface ParseAndCheckError {
    error:GeneralError;
}

export interface GeneralError {
    code:string;
    message:string;
}
