import {Injectable} from '@angular/core';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {ImplementationDataService} from '../../implementation-config/implementation-data.service';
import {RecaptchaAction} from './recaptcha-action';
import {Observable, of} from 'rxjs';
import {ExternalResponse} from '../external-response';
import {ResponseCode} from '../enum/response-code.enum';
import {catchError, map} from 'rxjs/operators';
import {Logger} from '../../shared/logger';

export interface RecaptchaHeaders {
    'x-re-captcha-token':string;
    'x-re-captcha-action':RecaptchaAction;
}

// Ensure there can only be 1 instance of this
@Injectable({providedIn: 'root'})
export class RecaptchaService {

    constructor(private reCaptchaV3Service:ReCaptchaV3Service,
                private implementationDataService:ImplementationDataService,
                private logger:Logger) {
        this.logger = logger.getLogger('RecaptchaService');
    }

    execute(action:RecaptchaAction, actionSuffix?:string):Observable<ExternalResponse<RecaptchaHeaders>> {
        if (this.implementationDataService.recaptchaEnabled()) {
            let actionStr = action as string;
            // Add the suffix
            if (actionSuffix) {
                actionStr += this.cleanSuffix(actionSuffix);
            }

            this.logger.test('Running reCAPTCHA action:' + actionStr);
            return this.reCaptchaV3Service.execute(actionStr).pipe(
                map(reCaptchaToken => {
                    const res:ExternalResponse<RecaptchaHeaders> = {
                        responseCode: ResponseCode.SUCCESS,
                        data        : {
                            'x-re-captcha-token' : reCaptchaToken,
                            'x-re-captcha-action': action
                        }
                    };
                    return res;
                }),
                catchError(err => {
                    const res:ExternalResponse = {responseCode: ResponseCode.RECAPTCHA_ERROR};
                    res.error                  = err;
                    throw res;
                })
            );
        }
        else {
            // Fake the response if its off
            const res:ExternalResponse = {responseCode: ResponseCode.SUCCESS, data: null};
            return of(res);
        }
    }

    cleanSuffix(name:string):string {
        return '_' + name.toLowerCase().replace(/[^0-9a-z]+/g, '_').replace(/^_|_$/g, '').toUpperCase();
    }
}
