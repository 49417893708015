import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {InjectComponentService} from './inject-component.service';
import {Subscription} from 'rxjs';
import {FacadePlatform} from '../shared/facade-platform';
import {AbstractFacadePageComponent} from '../shared/ui/abstract-facade-page.component';
import {ViewDidEnter, ViewWillLeave} from '@ionic/angular';
import {Logger} from '../shared/logger';


/**
 * This is a reusable component the creates a child component dynamically based on config
 * that is setup with a module declaration.
 * This approach allows implementation projects to override certain components
 * similar to the angular service dependency injection system.
 */

@Component({
    selector       : 'inject-component',
    templateUrl    : './inject-component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InjectComponent<T = any> implements OnInit, OnDestroy, ViewDidEnter, ViewWillLeave {

    @ViewChild('containerHolder', {read: ViewContainerRef})
    container:ViewContainerRef;

    @Input()
    componentClass:string;

    @Output()
    componentCreated = new EventEmitter();

    componentInst:T;


    protected injectComponentService:InjectComponentService;
    protected facadePlatform:FacadePlatform;
    protected changeDetectorRef:ChangeDetectorRef;
    protected logger:Logger;
    protected inputValueCache              = {};
    protected outputCache                  = {};
    protected subscriptions:Subscription[] = [];


    constructor(protected injector:Injector) {
        this.injectComponentService = injector.get(InjectComponentService);
        this.facadePlatform         = injector.get(FacadePlatform);
        this.changeDetectorRef      = injector.get(ChangeDetectorRef);
        this.logger                 = injector.get(Logger);
        this.logger.name            = 'InjectComponent';
    }

    async ngOnInit() {
        // It takes a tick to get this going
        let cnt = 0;
        while (this.container == null && cnt < 10) {
            cnt++;
            await new Promise(resolve => setTimeout(resolve));
        }
        if (this.container == null) throw new Error('null after waiting' + this.componentClass);
        this.loadComp();
    }

    ngOnDestroy():void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ionViewDidEnter() {
        if (this.facadePlatform.isMobile && this.componentInst && typeof (<any>this.componentInst).ionViewDidEnter === 'function') {
            this.logger.test('ionViewDidEnter');
            (<AbstractFacadePageComponent><any>this.componentInst).ionViewDidEnter();
            this.changeDetectorRef.detectChanges();
        }
    }

    ionViewWillLeave() {
        if (this.facadePlatform.isMobile && this.componentInst && typeof (<any>this.componentInst).ionViewWillLeave === 'function') {
            this.logger.test('ionViewWillLeave');
            (<AbstractFacadePageComponent><any>this.componentInst).ionViewWillLeave();
            this.changeDetectorRef.detectChanges();
        }
    }

    setProxyInputValue(key:string, value:any) {
        if (this.componentInst) {
            this.componentInst[key] = value;
        }
        else {
            this.inputValueCache[key] = value;
        }
    }

    getProxyInputValue(key:string) {
        if (this.componentInst) {
            return this.componentInst[key];
        }
        else {
            return this.inputValueCache[key];
        }
    }

    createProxyOutput<O>(key:string):EventEmitter<O> {
        this.outputCache[key] = new EventEmitter<O>();
        return this.outputCache[key];
    }

    loadComp() {
        if (!this.componentClass) {
            throw new Error('Unable to find the componentClass, the InjectComponent requires this to work');
        }
        const componentClass:any = this.injectComponentService.getComponentClass(this.componentClass);
        if (componentClass == null) {
            this.container.clear();
        }
        else {
            this.container.clear();
            const {instance}   = this.container.createComponent<T>(componentClass, {injector: this.injector});
            this.componentInst = instance;

            // Populate all the proxies values, if any
            Object.keys(this.inputValueCache).forEach(key => this.componentInst[key] = this.inputValueCache[key]);
            this.inputValueCache = {};

            // Create subscriptions for all the proxied outputs
            Object.keys(this.outputCache).forEach(key => {
                const source = <EventEmitter<any>>this.componentInst[key];
                if (source == null) throw new Error(`Unable to locate the EventEmitter for the key '${key}'`);
                if (!(source instanceof EventEmitter)) throw new Error(`Proxied output with key '${key}' is not of type EventEmitter - ` + source);

                // No need to catch as EventEmitter's never fail
                const target = this.outputCache[key];
                this.subscriptions.push(
                    source.subscribe(data => {
                        target.emit(data);
                    })
                );
            });
            this.changeDetectorRef.detectChanges();

            this.componentCreated.emit();
        }
    }
}
