import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {first, skipWhile} from 'rxjs/operators';
import {AbstractSelector} from './abstract.selector';
import {RootState} from './root.state';

@Injectable()
export class GlobalSelectors extends AbstractSelector {

    appReady$:BehaviorSubject<boolean>;
    appReadyTrue$:Observable<boolean>;
    cartQuantity$:Observable<number>;

    constructor(injector:Injector) {
        super(injector);
        this.logger = this.logger.getLogger('GlobalSelectors');

        this.appReady$ = new BehaviorSubject(false);

        this.store.select((s:RootState) => s.magento.categoryIDHash)
            .pipe(first(v => v != null))
            .subscribe(() => this.appReady$.next(true));

        this.appReadyTrue$ = this.appReady$.pipe(
            skipWhile(appReady => appReady === false)
        );

        this.cartQuantity$ = this.store.select((s:RootState) => s.sharedCart.total_quantity);
    }
}
