import {gql} from 'apollo-angular';
import {ProductListUrlSerializer} from '../../../product-list-page/product-list-url-serializer';

const buildFilter = (serializer:ProductListUrlSerializer) => {
    let filterStr = `{`;
    if (serializer.categoryId != null) filterStr += `category_id: { eq: "${serializer.categoryId}" }`;
    filterStr += `}`;
    return filterStr;
};

export const GET_BASE_AGGREGATIONS_CACHE = true;

export const GET_BASE_AGGREGATIONS = (serializer:ProductListUrlSerializer) => gql`
    query products_baseAggregations {
        products(
            filter: ${buildFilter(serializer)}
            search: "${(serializer.searchTerm != null ) ? serializer.searchTerm : ''}"
        ) {
            aggregations(filter: {category:{includeDirectChildrenOnly: true}}) {
                attribute_code
                count
                label
                options {
                    count
                    label
                    value
                }
            }
        }
    }
`;
