// NB!!
// This file is shared with the sitemap builder so cannot import gql from apollo-angular
import {gql} from '@apollo/client/core';

export const FETCH_STORES_BY_CITY_CACHE = false;

export const FETCH_STORES_BY_CITY = gql`
    query storesByCity($city:String!) {
        storesByCity(
            city: $city
        ) {
            stores {
                branchCode
                branchName
                branchNameUrlKey
                clickAndCollectEnabled
                addressLine1
                addressLine2
                suburb
                suburbUrlKey
                city
                cityUrlKey
                province
                provinceUrlKey
                locDivName
                locDivNameUrlKey
                postalCode
                countryName
                countryNameUrlKey
                countryCode
                branchTelephone
                location {
                    lat
                    lon
                }
                businessHours {
                    ClosingTime
                    OpeningTime
                    Weekday
                }
            }
            total
        }
    }
`;
