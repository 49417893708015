import {Injector} from '@angular/core';
import {FakeLinkEvent} from './ui/fake-link.directive';
import {FacadeAppDefinitionType} from '../../../scripts/shared/facade-config.types';
import {Observable} from 'rxjs';


// This should have an implementation class provided by each app
export abstract class FacadePlatform {

    abstract get appType():FacadeAppDefinitionType;

    abstract handleLink(fakeLinkEvent:FakeLinkEvent, options?:any):void;

    handleLinkDirect(url:string, newTab:boolean = false, isBackLink:boolean = false, options?:any):void {
        this.handleLink({href: url, newTab: newTab, isBackLink: isBackLink}, options);
    }

    abstract reAuthenticateUser();

    abstract handleCartNoLongerExists();

    abstract scrollStartObserver(closeElement?:any):Observable<{ yPos:number }>;

    abstract scrollEndObserver(closeElement?:any):Observable<{ yPos:number }>;

    abstract getScrollPos(closeElement?:any):Promise<number>;

    abstract scrollToElement(componentInjector:Injector, nativeElement:any, additionalOffset?:number);

    abstract scrollToTop(duration?:number);

    abstract scrollToPoint(x: number, y:number);

    abstract getScrollElement();

    abstract get isAndroid():boolean;

    abstract get isIOS():boolean;

    abstract get isIPad():boolean;

    abstract get isSupported():boolean;

    get isDesktop():boolean {
        return this.appType === FacadeAppDefinitionType.desktop;
    }

    get isMobile():boolean {
        return this.appType === FacadeAppDefinitionType.mobileapp || this.appType === FacadeAppDefinitionType.mobile;
    }

    get isMobileApp():boolean {
        return this.appType === FacadeAppDefinitionType.mobileapp;
    }

}
