import {gql} from 'apollo-angular';

export const CONFIRM_EMAIL = gql`
    mutation emailConfirm($confimationCode:String) {
        emailConfirm(confirmation_code: $confimationCode) {
            Response {
                ResponseCode
                ResponseDescription
            }
        }
    }
`;
