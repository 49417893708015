import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UrlService} from '../shared/url.service';
import {CrossPlatformModule} from '../cross-platform.module';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector       : 'plus-more-button',
    template       : `
        <div class="holder" (click)="handleClick()">
            <div class="claim-button">{{'account.plusMore.claimYour' | translate}}
                <span class="svg-holder"><svg width="60" height="16" viewBox="0 0 60 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.36833 5.58936H5.9012V10.6551H3.36833C1.97018 10.6551 0.835449 9.52038 0.835449 8.12223C0.835449 6.72408 1.97018 5.58936 3.36833 5.58936Z" fill="#0CAD4B" style="fill:#0CAD4B;fill:color(display-p3 0.0471 0.6784 0.2941);fill-opacity:1;" />
                    <path d="M13.5002 10.6551H10.9673V5.58936H13.5002C14.8983 5.58936 16.033 6.72408 16.033 8.12223C16.033 9.52038 14.8983 10.6551 13.5002 10.6551Z" fill="#0070B7" style="fill:#0070B7;fill:color(display-p3 0.0000 0.4392 0.7176);fill-opacity:1;" />
                    <path d="M8.43249 5.58936C7.63717 5.58936 6.92669 5.95662 6.46191 6.53032C6.10984 6.96471 5.90088 7.51561 5.90088 8.11843V5.58936H8.43249Z" fill="#464B55" style="fill:#464B55;fill:color(display-p3 0.2745 0.2941 0.3333);fill-opacity:1;" />
                    <path d="M10.9673 5.58936V8.11843C10.9673 6.72408 9.83632 5.59569 8.43311 5.58936H10.9673Z" fill="#4853A4" style="fill:#4853A4;fill:color(display-p3 0.2824 0.3255 0.6431);fill-opacity:1;" />
                    <path d="M10.9673 8.12207V10.6549H8.43311C9.2259 10.6549 9.9351 10.2927 10.3999 9.72158C10.5784 9.50376 10.719 9.25553 10.8165 8.98578C10.9141 8.71603 10.9673 8.42475 10.9673 8.12207Z" fill="#5C83C3" style="fill:#5C83C3;fill:color(display-p3 0.3608 0.5137 0.7647);fill-opacity:1;" />
                    <path d="M8.43249 10.6548H5.90088V8.12695C5.90088 9.5251 7.03434 10.6548 8.43249 10.6548Z" fill="#2F8264" style="fill:#2F8264;fill:color(display-p3 0.1843 0.5098 0.3922);fill-opacity:1;" />
                    <path d="M10.9666 8.11941V8.12321C10.9666 8.42716 10.9147 8.71717 10.8159 8.98692C10.7184 9.25667 10.5766 9.50489 10.3993 9.72272C9.93448 10.2926 9.22528 10.6561 8.43249 10.6561C7.03434 10.6561 5.90088 9.52642 5.90088 8.12828V8.12068C5.90088 7.51658 6.10984 6.96695 6.46191 6.5313C6.92669 5.9576 7.63717 5.59033 8.43249 5.59033C9.83444 5.59666 10.9666 6.72633 10.9666 8.11941Z" fill="#043957" style="fill:#043957;fill:color(display-p3 0.0157 0.2235 0.3412);fill-opacity:1;" />
                    <path d="M10.9666 3.05631V5.58919H5.90088V3.05631C5.90088 1.65817 7.03561 0.523438 8.43376 0.523438C9.8319 0.523438 10.9666 1.65817 10.9666 3.05631Z" fill="#E23694" style="fill:#E23694;fill:color(display-p3 0.8863 0.2118 0.5804);fill-opacity:1;" />
                    <path d="M5.90088 13.1877V10.6548H10.9666V13.1877C10.9666 14.5858 9.8319 15.7205 8.43376 15.7205C7.03561 15.7205 5.90088 14.5858 5.90088 13.1877Z" fill="#6863AC" style="fill:#6863AC;fill:color(display-p3 0.4078 0.3882 0.6745);fill-opacity:1;" />
                    <path d="M59.1378 8.36185V8.35805C59.1555 8.21241 59.1644 8.06804 59.1644 7.92746C59.1644 7.12074 58.9909 6.4014 58.6452 5.77072C58.2994 5.13876 57.8258 4.64232 57.223 4.28012C56.6189 3.91792 55.9249 3.73682 55.1409 3.73682C54.357 3.73682 53.5984 3.92932 52.9373 4.31558C52.275 4.70184 51.7519 5.22362 51.3644 5.88596C50.9781 6.54704 50.7856 7.29298 50.7856 8.12249C50.7856 8.95201 50.9819 9.70174 51.3745 10.3679C51.7671 11.034 52.299 11.5583 52.9728 11.9383C53.6465 12.3182 54.4039 12.5082 55.2486 12.5082C55.9033 12.5082 56.5251 12.3853 57.1115 12.1396C57.3737 12.0307 57.6168 11.8977 57.8422 11.7407C58.2931 11.4304 58.3349 10.7795 57.9524 10.392C57.6535 10.0893 57.1875 10.0462 56.8278 10.2716C56.705 10.3489 56.5745 10.416 56.4352 10.473C56.0654 10.625 55.664 10.701 55.2321 10.701C54.7281 10.701 54.2835 10.5921 53.8973 10.3755C53.5098 10.1589 53.2109 9.85245 53.0006 9.45479C52.8993 9.26482 52.8246 9.05966 52.7727 8.84057H58.5907C58.8668 8.84057 59.1049 8.63667 59.1391 8.36185M52.7803 7.26132C52.8284 7.06248 52.8955 6.87758 52.9829 6.70788C53.1818 6.32795 53.4629 6.03287 53.8251 5.82264C54.1886 5.61241 54.6154 5.50603 55.108 5.50603C55.6006 5.50603 55.9717 5.59975 56.2934 5.78718C56.6151 5.97335 56.8646 6.2431 57.0406 6.59517C57.1394 6.7902 57.2103 7.01436 57.2609 7.26132H52.7803Z" fill="white" style="fill:white;fill-opacity:1;" />
                    <path d="M50.2962 3.96449C49.9568 3.81251 49.5756 3.73779 49.1539 3.73779C48.1939 3.73779 47.4759 4.06833 47.0022 4.72815C46.987 4.74968 46.9743 4.77374 46.9592 4.794C46.9592 4.30642 46.5628 3.9113 46.0752 3.9113H45.9118C45.423 3.9113 45.0278 4.30642 45.0278 4.794V11.4479C45.0278 11.9367 45.4242 12.3318 45.9118 12.3318H46.0752C46.564 12.3318 46.9592 11.9355 46.9592 11.4479V7.64729C46.9592 6.92289 47.1352 6.38972 47.4847 6.05031C47.8368 5.7109 48.2813 5.5412 48.8208 5.5412C49.0665 5.5412 49.2831 5.57666 49.4705 5.64758C49.4806 5.65138 49.4895 5.65518 49.4996 5.65771C49.8441 5.79702 50.2367 5.73497 50.495 5.47028L50.5748 5.38923C50.9928 4.95991 50.8788 4.23804 50.3367 3.98222C50.3241 3.97588 50.3114 3.96955 50.2975 3.96322L50.2962 3.96449Z" fill="white" style="fill:white;fill-opacity:1;" />
                    <path d="M32.557 12.3319H32.7495C33.2295 12.3319 33.6196 11.9431 33.6196 11.4632V6.89385C33.6196 6.24037 33.4739 5.67807 33.1801 5.20949C32.8876 4.74217 32.495 4.37997 32.0036 4.12161C31.511 3.86326 30.9613 3.73535 30.3509 3.73535C29.7405 3.73535 29.2073 3.86706 28.7083 4.13048C28.241 4.3787 27.8573 4.71937 27.5495 5.14616C27.2735 4.71051 26.9087 4.36604 26.4503 4.12161C25.9716 3.86326 25.4333 3.73535 24.8356 3.73535C24.2378 3.73535 23.6996 3.86706 23.2196 4.13048C22.9068 4.30145 22.6395 4.52054 22.4116 4.78016C22.4116 4.30145 22.0215 3.91012 21.5415 3.91012H21.349C20.8703 3.91012 20.479 4.30018 20.479 4.78016V11.4619C20.479 11.9406 20.8691 12.3319 21.349 12.3319H21.5415C22.0228 12.3319 22.4116 11.9419 22.4116 11.4619V7.31431C22.4116 6.93944 22.4914 6.62157 22.6573 6.35815C22.8219 6.09599 23.0397 5.89336 23.3158 5.75279C23.5894 5.61095 23.9035 5.54129 24.2555 5.54129C24.7697 5.54129 25.2041 5.69833 25.5562 6.00608C25.9082 6.31635 26.0843 6.75074 26.0843 7.31304V11.4606C26.0843 11.9393 26.4743 12.3307 26.9543 12.3307H27.1468C27.6268 12.3307 28.0168 11.9406 28.0168 11.4606V7.31304C28.0168 6.93818 28.0979 6.6203 28.2625 6.35688C28.4259 6.09473 28.6488 5.8921 28.93 5.75152C29.2098 5.60968 29.5214 5.54003 29.8608 5.54003C30.375 5.54003 30.8094 5.69706 31.1614 6.00481C31.5135 6.31509 31.6895 6.74948 31.6895 7.31177V11.4594C31.6895 11.9381 32.0783 12.3294 32.5596 12.3294L32.557 12.3319Z" fill="white" style="fill:white;fill-opacity:1;" />
                    <path d="M41.538 4.31411C40.8706 3.92785 40.1209 3.73535 39.2901 3.73535C38.4593 3.73535 37.7273 3.93165 37.0586 4.32298C36.3912 4.71431 35.8593 5.23735 35.468 5.89336C35.0754 6.54811 34.8804 7.28518 34.8804 8.10457C34.8804 8.92395 35.0754 9.66608 35.468 10.3322C35.8606 10.9984 36.3912 11.529 37.0586 11.9191C37.7261 12.3117 38.4695 12.5067 39.2901 12.5067C40.1108 12.5067 40.8706 12.3117 41.538 11.9191C42.2055 11.5278 42.7348 10.9984 43.1274 10.3322C43.5213 9.66608 43.7163 8.92269 43.7163 8.10457C43.7163 7.28645 43.5213 6.54558 43.1274 5.88576C42.7348 5.22468 42.2055 4.70164 41.538 4.31538V4.31411ZM41.4228 9.41786C41.2164 9.80413 40.9301 10.1055 40.5616 10.3221C40.1918 10.5387 39.7676 10.6476 39.2888 10.6476C38.8101 10.6476 38.4011 10.5387 38.0338 10.3221C37.6653 10.1055 37.3778 9.80413 37.1739 9.41786C36.9675 9.0316 36.8649 8.59341 36.8649 8.10203C36.8649 7.61065 36.9675 7.17753 37.1739 6.80393C37.3778 6.42907 37.6653 6.13399 38.0338 5.91743C38.4023 5.70086 38.8203 5.59195 39.2888 5.59195C39.7574 5.59195 40.1893 5.70086 40.5515 5.91743C40.9149 6.13399 41.2012 6.42907 41.4127 6.80393C41.6229 7.17753 41.7293 7.61065 41.7293 8.10203C41.7293 8.59341 41.628 9.0316 41.4215 9.41786H41.4228Z" fill="white" style="fill:white;fill-opacity:1;" />
                </svg></span> {{'account.plusMore.rewards' | translate}}
            </div>
            <div class="paragraph">{{'account.plusMore.unlock' | translate}}</div>
            <div class="sign-up">
                @if (!isOpen) {
                    {{'account.plusMore.claimSavings' | translate}} <facade-icon icon="icon-chevron-down"></facade-icon>
                } @else {
                    {{'account.plusMore.signUpLater' | translate}} <facade-icon icon="icon-chevron-up"></facade-icon>
                }
            </div>
        </div>
    `,
    standalone     : true,
    imports: [
        CrossPlatformModule,
        TranslateModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlusMoreButtonComponent {

    @Input() set disabled(value:boolean) {
        this._disabled = value;
    }

    @Output() clicked:EventEmitter<boolean> = new EventEmitter<boolean>();

    protected _disabled:boolean;
    protected isOpen:boolean;


    constructor(protected urlService:UrlService) {
    }

    protected handleClick() {
        if (this._disabled) return;
        this.isOpen = !this.isOpen;
        this.clicked.emit(this.isOpen);
    }
}