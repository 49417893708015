import {Injectable, Injector} from '@angular/core';
import {FacadePlatform} from '../../shared/facade-platform';
import {UrlService} from '../../shared/url.service';
import {Logger} from '../../shared/logger';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthType} from '../components/auth.types';
import {Store} from '@ngxs/store';
import {map} from 'rxjs/operators';

@Injectable()
export class BlockWhenNotAuthenticatedGuard implements CanActivate {

    public facadePlatform:FacadePlatform;
    public urlService:UrlService;
    public logger:Logger;
    public router:Router;
    public store:Store;

    constructor(injector:Injector) {
        this.facadePlatform = injector.get(FacadePlatform);
        this.urlService     = injector.get(UrlService);
        this.logger         = injector.get(Logger);
        this.router         = injector.get(Router);
        this.store          = injector.get(Store);
        this.logger         = this.logger.getLogger('RequiresAuthenticationGuard');
    }

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.store.selectOnce(s => s.customer.isLoggedIn)
            .pipe(
                map((isLoggedIn) => {
                    const canActivate = isLoggedIn;
                    if (!canActivate) {
                        this.logger.debug('Blocked route as not currently logged in');
                        this.facadePlatform.handleLink({href: this.urlService.buildUrlForAuthLogin(AuthType.STANDARD), newTab: false, isBackLink: false});
                    }
                    return canActivate;
                })
            );
    }

}
