import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {
    FMData,
    FMFooterPageTypeEnum,
    FMMenuElementTypeEnum
} from '../../../cross-platform/external-data/firebase/firebase.types';
import {AbstractFacadeComponent} from '../../../cross-platform/shared/ui/abstract-facade.component';
import {UrlService} from '../../../cross-platform/shared/url.service';
import {FooterMenuService} from '../../../cross-platform/menu/footer-menu.service';
import {FMMenuBlogButtonUI, FMMenuElementsUI} from '../../../cross-platform/external-data/firebase/firebase-ui.types';
import {WordpressLogicService} from '../../../cross-platform/external-data/wordpress/wordpress-logic.service';
import {NetworkLoadMonitor} from '../../../cross-platform/external-data/interceptors/network-load-monitor.service';
import {mergeMap, take} from 'rxjs/operators';
import {BlogPageEnum} from '../../../cross-platform/router/page-type.enum';
import {environment} from '../../../cross-platform/implementation/environment';
import {RecaptchaSectionType} from '../../../cross-platform/implementation-config/implementation-data.types';

@Component({
    selector       : 'app-footer',
    templateUrl    : './app-footer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFooterComponent extends AbstractFacadeComponent {

    footerMenuService:FooterMenuService;
    urlService:UrlService;
    wordpressLogicService:WordpressLogicService;
    networkLoadMonitor:NetworkLoadMonitor;

    data$:Observable<FMData>;

    yearData = {year: new Date().getFullYear()};

    footerMenuItems:FMMenuElementsUI[];
    footerTermsItems:FMMenuElementsUI;

    protected recaptchaNoticeEnabled:boolean = this.implementationDataService.recaptchaNoticeEnabled(RecaptchaSectionType.FOOTER);

    constructor(injector:Injector) {
        super(injector);
        this.footerMenuService     = injector.get(FooterMenuService);
        this.urlService            = injector.get(UrlService);
        this.wordpressLogicService = injector.get(WordpressLogicService);
        this.networkLoadMonitor    = injector.get(NetworkLoadMonitor);

        this.data$ = of(environment.data);
    }

    init() {
        super.init();

        this.addSub = combineLatest([
            this.selectWhenReady(s => s.magento.categoryUrlKeyHash),
            this.selectWhenReady(s => s.magento.categoryIDHash),
            this.selectWhenReady(s => s.customer.isLoggedIn),
        ])
            .subscribe(
                values => {
                    this.footerMenuItems  = this.footerMenuService.mapFooterMenuElements(environment.uiConfig.footerMenuElements, environment.config.customPageSEOData, values[0], values[1], values[2]);
                    this.footerTermsItems = this.footerMenuService.mapMenuElements(environment.uiConfig.footerTermsLinkElements, environment.config.customPageSEOData, values[0], values[1], values[2]);
                    this.expandBlogMenu();
                    this.changeDetectorRef.detectChanges();
                }
            );
    }

    expandBlogMenu() {
        // Look for a blog menu item
        let blogMenuItem:FMMenuBlogButtonUI;
        for (let i = 0; i < this.footerMenuItems.length; i++) {
            const holder = this.footerMenuItems[i];
            blogMenuItem = holder.find(item => item.type === FMMenuElementTypeEnum.BLOG_BUTTON) as FMMenuBlogButtonUI;
            if (blogMenuItem) break;
        }

        if (blogMenuItem) {

            this.addSub = this.networkLoadMonitor.whenXhrIdle()
                .pipe(
                    take(1),
                    mergeMap(() => this.wordpressLogicService.getCategoriesOrTags(BlogPageEnum.CATEGORIES, true))
                )
                .subscribe(res => {
                    this.logger.debug('Found the categories: ', res);
                    const cats            = blogMenuItem.maxCategories != null ? res.data.hierarchicalList.slice(0, blogMenuItem.maxCategories) : res.data.hierarchicalList;
                    // Dynamically build some new menu elements
                    blogMenuItem.children = cats.map(cat => {
                        return {
                            type           : FMMenuElementTypeEnum.CUSTOM_BUTTON,
                            builtInPageType: FMFooterPageTypeEnum.FULL_LINK,
                            pathAfterType  : this.urlService.buildUrlForBlogCategory(cat, res.data.idHash),
                            label          : cat.name
                        };
                    });

                    this.changeDetectorRef.detectChanges();
                });
        }
    }

    get name() {
        return 'AppFooterComponent';
    }
}
