import {gql} from 'apollo-angular';

export const FETCH_CART_MINIMAL_CACHE = false;

export const FETCH_CART_MINIMAL = gql`
    query cart_minimal($cartId:String = "") {
        cart(cart_id: $cartId) {
            id
            total_quantity
        }
    }
`;
