import {Injectable} from '@angular/core';
import {ISelectOption} from '../forms/forms.types';


export interface CountryCodeHash {
    [code:string]:string;
}

export interface SouthAfricanProvincesHash {
    [code:string]:string;
}


@Injectable()
export class CountryService {

    private _countryDataForSelect:ISelectOption[];
    private countryCodeHash:CountryCodeHash;

    // Do not use this directly
    private __countryData:{ label:string, a2code:string, a3code:string }[];

    private southAfricanProvinces:ISelectOption[] = [
        {value: 'EC', label: 'Eastern Cape'},
        {value: 'FS', label: 'Free State'},
        {value: 'GP', label: 'Gauteng'},
        {value: 'KZN', label: 'KwaZulu-Natal'},
        {value: 'LP', label: 'Limpopo'},
        {value: 'MP', label: 'Mpumalanga'},
        {value: 'NW', label: 'North West'},
        {value: 'NC', label: 'Northern Cape'},
        {value: 'WC', label: 'Western Cape'}
    ];
    private southAfricanProvincesHash:SouthAfricanProvincesHash;


    async getCountryDataForSelect(saFirst:boolean = true, saOnly:boolean = false):Promise<ISelectOption[]> {
        if (!this._countryDataForSelect) {
            const data                 = saOnly ? [] : await this.getCountryData();
            this._countryDataForSelect = data.map(item => ({label: item.label, value: item.a2code}));
            if (saFirst) this._countryDataForSelect.unshift({label: 'South Africa', value: 'ZA'});
        }
        return saOnly ? [this._countryDataForSelect.find(country => country.value === 'ZA')] : this._countryDataForSelect;
    }

    async getCountryCodeHash():Promise<CountryCodeHash> {
        if (!this.countryCodeHash) {
            this.countryCodeHash = {};
            const data           = await this.getCountryData();
            data.forEach(item => {
                this.countryCodeHash[item.a2code] = item.label;
            });
        }
        return this.countryCodeHash;
    }

    public getSouthAfricanProvinces():ISelectOption[] {
        return this.southAfricanProvinces;
    }

    public getSouthAfricanProvincesHash():SouthAfricanProvincesHash {
        if (!this.southAfricanProvincesHash) {
            this.southAfricanProvincesHash = {};
            this.southAfricanProvinces.forEach(province => this.southAfricanProvincesHash[province.value] = province.label);
        }
        return this.southAfricanProvincesHash;
    }

    // Use this instead
    private async getCountryData() {
        if (!this.__countryData) {
            const data         = await import('./country.service.data');
            this.__countryData = data.countryData;
        }
        return this.__countryData;
    }


}
