import {Injectable, Injector} from '@angular/core';
import {NoContentLink} from '../no-content-page/abstract-no-content-include.component';
import {SEODefaults} from '../seo/seo-service';
import {Observable, of} from 'rxjs';
import {UrlService} from '../shared/url.service';
import {MagentoImageSizes} from '../external-data/enum/magento-constants';
import {FacadePlatform} from '../shared/facade-platform';
import {CountryPrefixCode, TelInputUtils} from '../forms/tel-input.utils';
import {SwiperOptions} from 'swiper';
import {
    AplusPromotionSettings,
    BrowserUpgradeOptions,
    DeliverySettings,
    GridSettings,
    ImplementationLinks,
    PaymentLogoType,
    PlusMorePostRegisterPromotion,
    PWASettings,
    RecaptchaSectionType,
    ScrollSettings,
    SisterSiteSettings
} from './implementation-data.types';
import {
    ProductGallerySettings,
    ProductInteractiveAttributeConfig,
    ProductModuleConfig
} from '../product-page/product.types';
import {AuthType} from '../auth/components/auth.types';
import {RegistrationFormSettings} from '../auth/components/abstract-auth-register.component';
import {StockOnHandType} from '../stock-on-hand/stock-on-hand.types';
import {MergeCartResponse} from '../cart-shared/shared-cart.service';
import {environment} from '../implementation/environment';
import {FMMenuItemType, FMMobileMenuItemGroup} from '../external-data/firebase/firebase.types';
import {GenericSEOData} from '../seo/seo-types';

export interface ProductFilterSettings {
    showFirstOnly?:boolean;
    showCategoryChildrenOnly?:boolean;
    showSortOnFilter?:boolean;
    showOnSaleFilter?:boolean;
    showInStockFilter?:boolean;
    shouldFilterSizeInStock?:boolean;
    productCategoryFilterEnabled?:boolean;
    productCategoryNavEnabled?:boolean;
    productFilterCategoryLinks?:{
        enabled:boolean;
        parentLevel:number;
    };
    attributesFilter?:{
        exclude?:string[];
    };
    funnelFilterAttributes?:boolean;
    usePriceSlider?:boolean;
    defaultCollapsed?:boolean;
    filterCollapsable?:boolean;
    replace?:{ [key:string]:string; };
    filterSortOrder?:any;
}

@Injectable()
export class ImplementationDataService {

    public urlService:UrlService;
    public facadePlatform:FacadePlatform;

    private getCustomModulePathFromNameFunc:Function;

    constructor(injector:Injector) {
        this.urlService     = injector.get(UrlService);
        this.facadePlatform = injector.get(FacadePlatform);
    }

    getSEOBase(env:string, versionNumber:string):GenericSEOData {
        return {};
    }

    getSEODefaults():SEODefaults {
        return {};
    }

    getNoContentLinks():Observable<NoContentLink[]> {
        return of([]);
    }

    getImplementationLinks():ImplementationLinks {
        return null;
    }

    getRegistrationFormSettings():RegistrationFormSettings {
        return {};
    }

    getGridBreakPoints():{ xs:number, sm:number, md:number, lg:number, xl:number } {
        return {
            xs: 0,
            sm: 0,
            md: 480,
            lg: 992,
            xl: 1200
        };
    }

    roundOverIonicButtons() {
        return true;
    }

    // This is actually only the default, you can supply these to the component as needed
    getCountryPrefixCodes():CountryPrefixCode[] {
        return [TelInputUtils.regions.southAfrica];
    }

    getDefaultSwiperEffect():{ desktop:string, mobile:string } {
        return {desktop: 'fade', mobile: 'slide'};
    }

    getShowFrameFooter():boolean {
        return false;
    }

    getUrlForSuccessfulLogin(authType:AuthType, mergeCart:MergeCartResponse, isPlusMore = false):string {
        if (authType === AuthType.CHECKOUT) {
            if (mergeCart?.mergedCartTotal !== mergeCart?.originalCartTotal && mergeCart?.originalCartTotal > 0) {
                return this.urlService.buildUrlForCheckoutCart() + `?mergedCartTotal=${mergeCart.mergedCartTotal}&originalCartTotal=${mergeCart.originalCartTotal}`;
            }
            else {
                return this.urlService.buildUrlForCheckoutDelivery();
            }
        }
        else {
            return this.urlService.buildUrlForAccountRoot(isPlusMore);
        }
    }

    getUseMobileAccountMenu():boolean {
        return true;
    }

    getUrlForAutoSignout():string {
        return this.urlService.buildUrlForHome();
    }

    getAuthAllowedIdleSeconds() {
        return 0; // Set to 0 to disable
    }

    getAuthEnabled() {
        return true;
    }

    getAutoSignoutDialogSeconds() {
        return 0; // Set to 0 to disable
    }

    getProductPageImageSizes() {
        return {
            desktop: {
                thumbSize     : MagentoImageSizes.thumb,
                previewSize   : MagentoImageSizes.d_md,
                largeSize     : MagentoImageSizes.d_lg,
                inViewerWidth : MagentoImageSizes.d_lg,
                inViewerHeight: MagentoImageSizes.d_lg
            },
            mobile : {
                photoSwipe: {
                    width   : MagentoImageSizes.m_lg,
                    height  : MagentoImageSizes.m_lg,
                    msrcSize: MagentoImageSizes.m_md,
                    srcSize : MagentoImageSizes.m_lg,
                },
                swiper    : {
                    size: MagentoImageSizes.m_lg
                },
                thumbSizes: {
                    thumbSize     : MagentoImageSizes.m_sm,
                    previewSize   : MagentoImageSizes.m_md,
                    largeSize     : MagentoImageSizes.m_lg,
                    inViewerWidth : MagentoImageSizes.m_lg,
                    inViewerHeight: MagentoImageSizes.m_lg
                }
            }
        };
    }

    getProductImageRatio():number {
        return 1;
    }

    getShowSubcategoryNavigation():boolean {
        return true;
    }

    getShowAddThisShareButtons():boolean {
        return false;
    }

    getShowBuyButton():boolean {
        return false;
    }

    getShowProductImageThumbnailsOnMobile() {
        return false;
    }

    getUrlForPaymentTermsAndConditionsPage() {
        return '';
    }

    getEnableAccountEdit() {
        return true;
    }

    getPromptOnAbandonCart() {
        return false;
    }

    // TEMP: Used to disable application status page
    getEnableAccountApplicationStatus() {
        return false;
    }

    getEnableMobileSearchHeader() {
        return true;
    }

    getUrlForCancelOrder():string {
        return this.urlService.buildUrlForHome();
    }

    getUrlForCompleteOrder():string {
        return this.urlService.buildUrlForHome();
    }

    getShowNewProductListCategoryNavigation():boolean {
        return false;
    }

    getCustomPlpLayout():boolean {
        return false;
    }

    getShowCategoryDiscription():boolean {
        return false;
    }

    getProductListUseInfiniteScroll():boolean {
        return true;
    }

    getPageHeaderOffset():number {
        return 0;
    }

    getCoreCategories():FMMobileMenuItemGroup[] {
        return environment.uiConfig.mobileMainMenu.items
            .filter(item => item.type === FMMenuItemType.categoryButton);
    }

    getProductFilterSettings():ProductFilterSettings {
        return {
            showFirstOnly               : false,
            showCategoryChildrenOnly    : false,
            showOnSaleFilter            : false,
            showInStockFilter           : false,
            funnelFilterAttributes      : false,
            defaultCollapsed            : false,
            filterCollapsable           : false,
            productCategoryFilterEnabled: true,
            productCategoryNavEnabled   : false,
            showSortOnFilter            : false,
            shouldFilterSizeInStock     : false
        };
    }

    getCarouselViewLink():boolean {
        return false;
    }

    getProductCarouselSwiperConfig(instanceId?:number | string):SwiperOptions {
        if (this.facadePlatform.isDesktop) {
            return {
                pagination : {
                    el       : instanceId ? `#swiper-pagination-${instanceId}` : '#swiper-pagination',
                    clickable: true,
                    type     : 'bullets',
                },
                breakpoints: {
                    1250: {
                        slidesPerView: 3,
                        spaceBetween : 20,
                    },
                    1500: {
                        slidesPerView: 4,
                        spaceBetween : 20,
                    },
                }
            };
        }
        else {
            return {
                breakpoints: {
                    360: {
                        slidesPerView: 2,
                        spaceBetween : 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween : 15,
                    },
                },
                // Using the inverse seems to fix a bug on mobile
                // breakpointsInverse: true
            };
        }
    }

    getContentItemsCarouselSwiperConfig(style:'style1' | 'style2' | 'style3' | 'style4'):SwiperOptions {
        if (this.facadePlatform.isDesktop) {
            if (style === 'style1' || style === 'style2') {
                return {
                    slidesPerView: 5,
                    spaceBetween : 24,
                };
            }
            else if (style === 'style3' || style === 'style4') {
                return {
                    slidesPerView: 6,
                    spaceBetween : 100,
                };
            }
        }
        else {
            if (style === 'style1' || style === 'style2') {
                return {
                    slidesPerView: 2,
                    spaceBetween : 24,
                };
            }
            else if (style === 'style3' || style === 'style4') {
                return {
                    slidesPerView: 3,
                    spaceBetween : 24,
                };
            }
            return {
                breakpoints: {
                    360: {
                        slidesPerView: 2,
                        spaceBetween : 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween : 15,
                    },
                },
                // Using the inverse seems to fix a bug on mobile
                // breakpointsInverse: true
            };
        }
    }

    getShowProductsTooltip():boolean {
        return false;
    }

    getShowProductCategoryLabels():boolean {
        return false;
    }

    getPreventScrollingOnSearch():boolean {
        return false;
    }

    getProductCustomActions() {
        return null;
    }

    getStockOnHand():StockOnHandType {
        return null;
    }

    /* TODO: Need to remove once removed for pep-implementationData */
    getShowStockFinder():boolean {
        return false;
    }

    getShowShopNowButtonOnProductListPage():boolean {
        return false;
    }

    getShowProductListPageHeading():boolean {
        return false;
    }

    getUseStockCheckOnProductListPage():boolean {
        return false;
    }

    // This must be passed in from the platform specific implementation app module
    registerGetCustomModulePathFromName(getModulePathFromNameFunc) {
        this.getCustomModulePathFromNameFunc = getModulePathFromNameFunc;
    }

    async getCustomModulePathFromName(moduleName:string) {
        return await this.getCustomModulePathFromNameFunc(moduleName);
    }

    getProductSelectableAttributeConfig():{ attributes:ProductInteractiveAttributeConfig[] } {
        return {
            attributes: [{
                label               : 'product.selectable.attributes.colour',
                name                : this.getColorAttrName(),
                parent              : null,
                display             : false,
                selectFirstByDefault: true
            }, {
                label               : 'product.selectable.attributes.size',
                name                : this.getSizeAttrName(),
                parent              : this.getColorAttrName(),
                display             : true,
                selectFirstByDefault: false
            }]
        };
    }

    getProductGallerySettings():ProductGallerySettings {
        return {
            verticalAlign: false,
            slider       : {
                blockWidth : 100,
                blockHeight: 100,
                gap        : 10
            }
        };
    }

    getShowBreadcrumbOnCoreProductPage():boolean {
        return true;
    }

    controlBreadcrumbPositionInCMS():boolean {
        return false;
    }

    getAccordionConfig():{ scrollingOffset:number } {
        if (this.facadePlatform.isDesktop) {
            return {
                scrollingOffset: 0
            };
        }
        else {
            return {
                scrollingOffset: 0
            };
        }
    }

    getBackToTopConfig():{ duration:number } {
        return {duration: 300};
    }

    getCatalogModalEnabled():boolean {
        return false;
    }

    showBlogTagPages():boolean {
        return true;
    }

    getRelatedPostsPageSize():number {
        return this.facadePlatform.isDesktop ? 100 : 5;
    }

    getAutoCompleteMaxItems():number {
        return 5;
    }

    displayStoreLocatorTradingHoursDisclaimer():boolean {
        return false;
    }

    getProductModules():ProductModuleConfig[] {
        return [];
    }

    getPromotionModule():ProductModuleConfig {
        return null;
    }

    getEcommerceEnabled():boolean {
        return false;
    }

    getShowCheckoutHelp():boolean {
        return true;
    }

    getBrowserUpgradeCheckEnabled():BrowserUpgradeOptions {
        return null;
    }

    setEcomEnabled(value:boolean) {
        // Must be handled in the retailer specific implementation
    }

    getEcomEnabled():boolean {
        return false;
    }

    getShowAttributeLabel():boolean {
        return false;
    }

    getShowWasNowPrice():boolean {
        return false;
    }

    getShowAPlus():boolean {
        return false;
    }

    getHasPimSupport() {
        return false;
    }

    // Magento defaults
    getColorAttrName() {
        return 'color';
    }

    getSizeAttrName() {
        return 'size';
    }

    getInteractiveInclusionHash():{ [code:string]:boolean } {
        const hash                    = {};
        hash[this.getColorAttrName()] = true;
        hash[this.getSizeAttrName()]  = true;
        return hash;
    }

    getShowStoreFinderInFooter() {
        return true;
    }

    getUseSOHDataForStoreFinder() {
        return false;
    }

    getPagerSetting():{ maxPages:number } {
        return {maxPages: 9};
    }


    getScrollSettings():ScrollSettings {
        return null;
    }

    getProductGridSettings():GridSettings {
        return null;
    }

    getShowCmsMenuContent():boolean {
        return false;
    }

    getPWASettings():PWASettings {
        return {
            enabled      : false,
            installPrompt: {
                enabled: false
            },
            notifications: {
                enabled: false
            }
        };
    }

    getBarcodeSeach():boolean {
        return false;
    }

    parseBarcode(input:string):string {
        return input;
    }

    getNoIndexMinProductCount():number | undefined {
        return undefined;
    }

    getShowBillingAddressInCheckout() {
        return false;
    }

    getAiRecommendationsToShow():{ similar:boolean, outfit:boolean, color:boolean } {
        return {similar: false, outfit: false, color: false};
    }

    recaptchaEnabled() {
        return false;
    }

    recaptchaNoticeEnabled(section:RecaptchaSectionType) {
        return false;
    }

    getDeliverySettings():DeliverySettings {
        return {
            homeDelivery: false
        };
    }

    getPushNotificationConfig():{ enabled:boolean, delay:number } {
        return {
            enabled: false,
            delay  : 0
        };
    }

    getStoresByLocationsFilter():string[] {
        return null;
    }

    paymentPageContent():string {
        return null;
    }

    getSisterSiteSettings():SisterSiteSettings[] {
        return null;
    }

    getPaymentLogos():PaymentLogoType[] {
        return null;
    }

    sohHideOutOfStockStores():boolean {
        return false;
    }

    stickyBuyButton():boolean {
        return false;
    }

    getAplusPromotionSettings():AplusPromotionSettings {
        return null;
    }

    getQuickCartEnabled():boolean {
        return false;
    }

    getPlusMorePostRegisterPromotion():PlusMorePostRegisterPromotion {
        return null;
    }

    getPlusMoreEnabled() {
        return false;
    }

    getPlusMoreEnabledOnForms() {
        return false;
    }

    getEinsteinLaybyEnabled() {
        return false;
    }

    getEinsteinAccountEnabled() {
        return false;
    }
}
