import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {AbstractFacadeComponent} from '../abstract-facade.component';

@Component({
    selector       : 'recaptcha-message',
    template       : `
        <div class="recaptcha-message" [innerHTML]="'forms.reCaptchaMessage' | translate"></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecaptchaMessageComponent extends AbstractFacadeComponent {
    constructor(injector:Injector) {
        super(injector);
    }

    get name() {
        return 'RecaptchaMessageComponent';
    }
}
