import {Component, EventEmitter, Injector, Output} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {AuthMergeWrapper, AuthService, LoginCustomerResponseData} from '../../external-data/auth/auth.service';
import {AbstractAuthBaseComponent} from './abstract-auth-base.component';
import {AuthType} from './auth.types';
import {ActivatedRoute} from '@angular/router';
import {PageTypeVariables} from '../../router/page-type.enum';
import {CustomerAuth} from '../customer-auth.state';
import {GetIdTypeDropDown, IdType, SelectOptions} from '../models/id-type';
import {ApiErrorObjectFormatTypes} from '../../error-handling/api-error-helper';
import {GTMActions} from '../../external-data/google/google-tag-manager/gtm.actions';
import {ErrorResponse} from '../../external-data/magento/magento.types';
import {ErrorCodes} from '../../external-data/error-codes';
import {GAAuthStep} from '../../external-data/google/google-tag-manager/google-analytics.types';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import Logout = CustomerAuth.Logout;

interface PageFormKeys {
    phone:any;
    password:any;
    idType:any;
    rsaIdNumber:any;
    passport:any;
    asylum:any;
    dob:any;
}

@Component({template: ''})
export class AbstractAuthLoginComponent extends AbstractAuthBaseComponent {

    @Output() onLogin         = new EventEmitter<any>();
    @Output() onGuestCheckout = new EventEmitter<any>();
    @Output() onLogout        = new EventEmitter<any>();

    AuthType = AuthType;

    protected plusMoreEnabled = false;
    protected redirectUrl:string;
    protected lastPhoneNumber:string;
    protected plusMoreOptIn   = false;
    protected isIdNumber      = true;
    protected isPassport      = false;
    protected isAsylum        = false;
    protected idLabel         = '';
    protected idTypes:SelectOptions[];

    private passControl:AbstractControl;

    // Dependencies
    private activatedRoute:ActivatedRoute;
    private authService:AuthService;


    constructor(injector:Injector) {
        super(injector);
        this.authService     = injector.get(AuthService);
        this.activatedRoute  = injector.get(ActivatedRoute);
        this.plusMoreEnabled = this.implementationDataService.getPlusMoreEnabledOnForms();
    }

    init() {
        this.addSub = this.activatedRoute.queryParams.subscribe(params => {
            this.logger.info('Received params: ', params);
            this.redirectUrl = params[PageTypeVariables.REDIRECT];
        });

        this.state.setReady();
        super.init();
    }

    async buildForm() {
        // Create group
        this.pageForm    = this.formBuilder.group<PageFormKeys>({
            phone      : ['', null, [this.facadeValidators.phoneNumberDefaultGroup(true, false, false)]],
            password   : ['', null, [this.facadeValidators.required(), this.facadeValidators.maxLengthInput()]],
            idType     : [IdType.ID, null, []],
            rsaIdNumber: ['', null, [this.facadeValidators.required(), this.facadeValidators.maxLengthInput(), this.facadeValidators.oneProfileRsaId(), this.facadeValidators.rsaId(), this.facadeValidators.minAgeOnRsaId()]],
            passport   : ['', null, [this.facadeValidators.required(), this.facadeValidators.maxLengthInput(), this.facadeValidators.oneProfilePassport()]],
            asylum     : ['', null, [this.facadeValidators.required(), this.facadeValidators.maxLengthInput()]],
            dob        : ['', null, [this.facadeValidators.required(), this.facadeValidators.dateFormat(), this.facadeValidators.dateMinAge(18)]],
        });
        this.passControl = this.pageForm.get('password');

        this.idTypes = await GetIdTypeDropDown(this.translate);

        this.updatePlusMoreFields(IdType.ID);
        this.addSub = this.pageForm.get<keyof PageFormKeys>('idType').valueChanges.subscribe((newVal) => {
            this.updatePlusMoreFields(newVal);
        });
        this.changeDetectorRef.detectChanges();
    }

    protected updatePlusMoreFields(idType:IdType) {
        this.isIdNumber = idType === IdType.ID;
        this.isPassport = idType === IdType.PASSPORT;
        this.isAsylum   = idType === IdType.ASYLUM;
        this.idLabel    = this.idTypes.find(v => v.value == idType)?.label;
        this.changeDetectorRef.detectChanges();
    }

    submitForm() {
        this.lastPhoneNumber = this.pageForm.value.phone;

        // Mark all as touched to trigger validation on untouched fields
        Object.keys(this.pageForm.controls).forEach(controlKey => this.pageForm.get(controlKey).markAsTouched({onlySelf: true}));

        if (this.formIsValid()) {
            this.pageForm.disable();
            this.state.setProcessing();

            let loginResponse:AuthMergeWrapper<LoginCustomerResponseData>;

            this.authService.login(this.lastPhoneNumber, this.pageForm.value.password, this.config.type)
                .pipe(
                    switchMap((res:AuthMergeWrapper<LoginCustomerResponseData>) => {
                        loginResponse = res;
                        if (this.plusMoreOptIn) {
                            const value = this.pageForm.value as PageFormKeys;
                            const dob   = this.facadeValidators.parseDateAndValidate(value.dob);
                            return this.authService.linkPlusMore({
                                plus_more_opt_in      : true,
                                rsa_id                : value.idType === IdType.ID ? value.rsaIdNumber?.trim() : null,
                                asylum_document_number: value.idType === IdType.ASYLUM ? value.asylum : null,
                                passport_number       : value.idType === IdType.PASSPORT ? value.passport : null,
                                date_of_birth         : value.idType === IdType.PASSPORT || value.idType === IdType.ASYLUM ? dob.formatted : null,
                            });
                        }
                        else {
                            return of(null);
                        }
                    })
                )
                .subscribe(
                    res => {
                        this.pageForm.enable();
                        this.pageForm.reset();
                        this.state.setReady();

                        if (this.redirectUrl && this.redirectUrl.trim().length > 0) {
                            this.logger.info('redirecting to: ', this.redirectUrl);
                            this.handleLinkDirect(this.redirectUrl);
                        }
                        else if (this.config.type === AuthType.RE_AUTHENTICATE) {
                            // do nothing popup listens for emit
                        }
                        else {
                            this.handleLinkDirect(this.implementationDataService.getUrlForSuccessfulLogin(this.config.type, loginResponse.merge?.data, this.plusMoreOptIn));
                        }
                        this.store.dispatch(new GTMActions.TrackAuthenticationStep({action: GAAuthStep.AUTH_EXISTING_USER}));
                        this.onLogin.emit(loginResponse);
                    },
                    this.apiErrorHelper.wrapErrorHandler<ErrorResponse>(
                        async error => {
                            this.state.errorStateName = null;
                            switch (Number(error.error.code)) {
                                case ErrorCodes.ERR_INVALID_CREDENTIAL:
                                    this.state.errorStateName = 'invalidCredential';
                                    break;
                                case ErrorCodes.PROFILE_NOT_VERIFIED:
                                    this.state.errorStateName = 'profileNotVerified';
                                    break;
                                case ErrorCodes.AUTH_SERVER_DISABLED:
                                    this.state.errorStateName = 'passwordExpired';
                                    break;

                                case ErrorCodes.ONE_PROFILE_TOKEN_ERROR:
                                    this.state.errorMessage = await this.translate.get('account.plusMore.errorConnecting').toPromise();
                                    break;
                                case ErrorCodes.PLUS_MORE_API_ERROR:
                                case ErrorCodes.PLUS_MORE_CREATE_ERROR:
                                    this.state.errorMessage = await this.translate.get('account.plusMore.unknownError').toPromise();
                                    break;
                                case ErrorCodes.PLUS_MORE_DUPLICATE_ID:
                                    this.state.errorMessage = await this.translate.get('account.plusMore.duplicateId').toPromise();
                                    break;
                                case ErrorCodes.PLUS_MORE_INVALID_ID_API:
                                    this.state.errorMessage = await this.translate.get('account.plusMore.invalidIdApi').toPromise();
                                    break;
                                case ErrorCodes.PLUS_MORE_DUPLICATE_CONTACT_API:
                                    this.state.errorMessage = await this.translate.get('account.plusMore.duplicateCellApi').toPromise();
                                    break;
                                case ErrorCodes.PLUS_MORE_ALREADY_LINKED:
                                    this.state.errorMessage = await this.translate.get('account.plusMore.alreadyLinked').toPromise();
                                    break;
                            }
                            return this.state.errorMessage;
                        },
                        (message, res) => {
                            if (this.state.errorStateName == null) this.state.errorMessage = message;
                            this.state.setErrorWithResponse(res, this.state.errorStateName);
                            if (loginResponse) {
                                this.store.dispatch(new Logout());
                                this.pageForm.enable();
                                this.changeDetectorRef.detectChanges();
                            }
                            else {
                                this.pageForm.enable();
                                this.changeDetectorRef.detectChanges();
                            }
                        },
                        ApiErrorObjectFormatTypes.magento
                    )
                );
        }
    }

    private formIsValid() {
        // Custom validation check so we don't have to alter the entire form object on each UI change
        const requiredElements:(keyof PageFormKeys)[] = [
            'phone',
            'password',
        ];

        if (this.plusMoreEnabled && this.plusMoreOptIn) {
            if (this.isIdNumber) {
                requiredElements.push('rsaIdNumber');
            }
            else if (this.isPassport) {
                requiredElements.push('passport', 'dob');
            }
            else if (this.isAsylum) {
                requiredElements.push('asylum', 'dob');
            }
        }

        let valid = true;
        requiredElements.forEach(key => {
            if (!this.pageForm.get(key).valid) {
                valid = false;
            }
        });
        return valid;
    }


    handleGuestCheckout(evt) {
        this.onGuestCheckout.emit(evt);
        this.handleLink(evt);
    }

    handleEnterOtpLink(event) {
        this.store.dispatch(new CustomerAuth.UpdateTempOtpPhoneNumber(this.lastPhoneNumber));
        const link = this.urlService.buildUrlForAuthOTP(this.config.type, true);
        this.handleLinkDirect(link);
    }

    handleLogout() {
        this.store.dispatch(new Logout());
        this.onLogout.emit();
    }

    protected togglePlusMore() {
        this.plusMoreOptIn = !this.plusMoreOptIn;
    }

    handleFormAPI() {
        // none
    }

    get name() {
        return 'AuthLoginComponent';
    }
}
