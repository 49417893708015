import {Injector} from '@angular/core';
import {Logger} from '../shared/logger';
import {UrlService} from '../shared/url.service';
import {ExtendedApolloService} from './extended-apollo/extended-apollo.service';
import {Store} from '@ngxs/store';

export abstract class AbstractFacadeService {

    public logger:Logger;
    public urlService:UrlService;
    public apollo:ExtendedApolloService;
    public store:Store;

    constructor(injector:Injector) {
        this.logger     = injector.get(Logger).getLogger(this.getName());
        this.urlService = injector.get(UrlService);
        this.apollo     = injector.get(ExtendedApolloService);
        this.store      = injector.get(Store);
    }

    abstract getName():string;
}

