import {InjectionToken} from '@angular/core';
import {ExternalResponse} from '../external-response';

export const EXTENDED_APOLLO_POST_REQUEST_HOOK = new InjectionToken<ExtendedApolloHook[]>('EXTENDED_APOLLO_POST_REQUEST_HOOK');

export abstract class ExtendedApolloHook {
    handleStart() {
    }

    handle(response:ExternalResponse<any>) {
    }

    handleError(response:ExternalResponse<any>) {
    }
}
