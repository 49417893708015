import {gql} from 'apollo-angular';

export const FORGOT_PASSWORD_GENERATE = gql`
    mutation forgotPasswordGenerate(
        $cellphoneNumber: String = "default"
    ) {
        forgotPasswordGenerate(cellphone_number: $cellphoneNumber) {
            message
        }
    }
`;
