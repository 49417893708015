import {Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {ControlValueAccessor, DefaultValueAccessor} from '@angular/forms';
import {BuildCustomFormElementProvider} from '../../../cross-platform/forms/abstract-form.component';
import {AbstractFormTelInputComponent} from '../../../cross-platform/forms/abstract-form-tel-input.component';

@Component({
    selector   : 'd-form-tel-input',
    templateUrl: './d-form-tel-input.component.html',
    providers  : [BuildCustomFormElementProvider(DFormTelInputComponent)]
})
export class DFormTelInputComponent extends AbstractFormTelInputComponent {

    @ViewChild('selectInputRef', {read: DefaultValueAccessor, static: true}) selectInputCtrl:ControlValueAccessor | null;
    @ViewChild('textInputRef', {read: DefaultValueAccessor, static: true}) textInputCtrl:ControlValueAccessor | null;

    @ViewChild('selectInputRef', {read: ElementRef, static: true}) selectInputRef:ElementRef;
    @ViewChild('textInputRef', {read: ElementRef, static: true}) textInputRef:ElementRef;

    constructor(injector:Injector) {
        super(injector);
    }

    get selectInput():ControlValueAccessor | null {
        return this.selectInputCtrl;
    }

    get textInput():ControlValueAccessor | null {
        return this.textInputCtrl;
    }

    get selectValue():string {
        return this.selectInputRef.nativeElement.value;
    }

    get textInputValue():string {
        return this.textInputRef.nativeElement.value;
    }
}
