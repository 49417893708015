import dayjs from 'dayjs';

export class DateUtil {

    static dateFormat     = 'yyyy-LL-dd';
    static dateTimeFormat = 'EEE, dd LLL yyyy, HH:mm';

    static isDate(input) {
        const dateToCheck = new Date(input);
        if (Object.prototype.toString.call(dateToCheck) === '[object Date]') {
            return !isNaN(dateToCheck.getTime());
        }
        else {
            return false;
        }
    }

    static parseMagentoDateTime(dateAsString:string):Date {
        // The format that arrives looks like this:
        // '2021-06-30 10:21:14'
        // iOS doesn't like the space so we modify it like so:
        // '2021-06-30T10:21:14Z'
        // The Z is for UTC
        dateAsString = dateAsString.replace(/\s/g, 'T');
        return new Date(dateAsString + 'Z');
    }

    static getSecondsPassed(startDate:Date):number {
        return Math.ceil((new Date().getTime() - startDate.getTime()) / 1000);
    }

    static formatBlogPostDateShort(date:Date):string {
        return dayjs(date).format('YYYY-MM-DD');
        //return formatDate(date, 'yyyy-MM-dd', 'en-US');
    }

    static formatBlogPostDateLong(date:Date):string {
        return dayjs(date).format('DD MMMM YYYY');
        //return `${formatDate(date, `d MMMM yyyy`, 'en-US')}`;
    }

    static formatBlogPostDateLongNth(date:Date):string {
        return `${date.getDate()}${DateUtil.nth(date.getDate())} of ${dayjs(date).format(`MMMM YYYY`)}`;
        //return `${date.getDate()}${DateUtil.nth(date.getDate())} of ${formatDate(date, `MMMM yyyy`, 'en-US')}`;
    }

    static formatSecondsToHours(seconds):string {
        const measuredTime = new Date(null);
        measuredTime.setSeconds(seconds);
        let HMSTime = measuredTime.toISOString().substr(11, 8);
        if (HMSTime.slice(0, 2) === '00') {
            HMSTime = HMSTime.slice(3);
        }
        return HMSTime;
    }

    static nth(date) {
        if (date > 3 && date < 21) return 'th';
        switch (date % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }
}
