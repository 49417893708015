import {gql} from 'apollo-angular';

export const GUEST_CART_OTP_RESEND = gql`
    mutation resendOtp_guestCart($msisdn: String!, $sessionId: String!) {
        resendOtp(msisdn: $msisdn, sessionId: $sessionId) {
            message
            status
        }
    }
`;
