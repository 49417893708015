import {ContentChildren, Directive, ElementRef, Injector, QueryList} from '@angular/core';
import {AbstractFacadeComponent} from '../../../../cross-platform/shared/ui/abstract-facade.component';
import {DomPositionDirective} from '../../../../cross-platform/shared/ui/dom-position.directive';

@Directive({
    selector: '[dScrollingContainer]'
})
export class DScrollingContainerDirective extends AbstractFacadeComponent {

    @ContentChildren(DomPositionDirective)
    children:QueryList<DomPositionDirective>;

    constructor(injector:Injector,
                public elementRef:ElementRef) {
        super(injector);
    }

    scrollToIndex(index:number, isDown:boolean = true) {
        //console.log('==> this.children', this.children);
        if (index >= 0 && this.children && this.children.length > index) {
            const elementToScroll = this.children.toArray()[index];
            const eleBounds       = elementToScroll.getAbsoluteBounds();
            const scrollBounds    = this.elementRef.nativeElement.getBoundingClientRect();
            const isOutOfSight    = eleBounds.bottom > scrollBounds.bottom || eleBounds.top < scrollBounds.top;
            const currScroll      = this.elementRef.nativeElement.scrollTop;
            // Up implementation
            let relativeScrollPos = eleBounds.top + currScroll - scrollBounds.top;
            if (isDown) {
                relativeScrollPos = eleBounds.bottom + currScroll - scrollBounds.bottom;
            }
            if (index === 0) {
                relativeScrollPos = 0;
            }
            if (isOutOfSight) {
                //console.log('Is out of view: ', relativeScrollPos);
                this.elementRef.nativeElement.scrollTop = relativeScrollPos;
            }
        }
    }

    get name() {
        return 'DScrollingContainerDirective';
    }
}
