import {IMetaPageData} from '../../../../../framework/src/cross-platform/seo/seo-service';
import {AckBuiltInPageMapping} from '../ack-custom-page-mapping';

/**
 * This class is here to defined locale keys for non custom page, implementation specific
 * pages. For example custom account sections.
 * This class leverages the framework LocaleConventionUtil to generate the mappings.
 */
export class AckImplementationLocaleConventionUtil {
    static seoPageOnlineStoreCredit   = AckImplementationLocaleConventionUtil.getSeoPage(AckBuiltInPageMapping.account.onlineStoreCredit.localePageType, AckBuiltInPageMapping.account.onlineStoreCredit.seoLocaleConvention);
    static seoPageAccountLayBys       = AckImplementationLocaleConventionUtil.getSeoPage(AckBuiltInPageMapping.account.laybys.localePageType, AckBuiltInPageMapping.account.laybys.seoLocaleConvention);
    static seoPageAccountAckAccount   = AckImplementationLocaleConventionUtil.getSeoPage(AckBuiltInPageMapping.account.ackAccount.localePageType, AckBuiltInPageMapping.account.ackAccount.seoLocaleConvention);
    static seoPageAccountMoreServices = AckImplementationLocaleConventionUtil.getSeoPage(AckBuiltInPageMapping.account.moreServices.localePageType, AckBuiltInPageMapping.account.moreServices.seoLocaleConvention);


    public static getSeoPage(pageType:string, localeSection:string):IMetaPageData {
        const result            = {title: `seo.${localeSection}.title`, description: `seo.${localeSection}.description`};
        result['localeSection'] = localeSection;
        result['pageType']      = pageType;
        return result;
    }
}
