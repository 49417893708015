<div class="form-group" [ngClass]="{'is-invalid': control.invalid && control.touched, 'is-valid': (control.valid && control.touched) || checked, 'is-disabled': control.disabled}">
    <div class="custom-control custom-checkbox">
        <input (change)="changeOption($event)" [checked]="checked" class="custom-control-input" type="checkbox" id="{{name}}"
               [formControl]="control"
               [disabled]="control.disabled"
        >
        <label class="custom-control-label" for="{{name}}">
            <ng-content></ng-content>
        </label>
    </div>
</div>
<d-form-messages [control]="control"></d-form-messages>
