import {tap} from 'rxjs/operators';
import {BehaviorSubject, fromEvent, Subscription, timer} from 'rxjs';

export class OtpTimer {
    private documentState:string;
    private subscriptions:Subscription[] = [];

    private waitSecondsToResendOtp    = 30;
    private currentSecondsToResendOtp = 0;

    public onTimerTick:BehaviorSubject<number> = new BehaviorSubject(this.waitSecondsToResendOtp);

    public get secondsLeftToResendOtp():number {
        return this.currentSecondsToResendOtp > 0
            ? this.waitSecondsToResendOtp - this.currentSecondsToResendOtp
            : this.waitSecondsToResendOtp;
    };

    public get resendOtpDisabled():boolean {
        return this.currentSecondsToResendOtp > 0;
    };

    /**
     * Reset and restart the timer from 30
     */
    public restartDisableResendTimer() {
        this.currentSecondsToResendOtp = 0;
        this.onTimerTick.next(this.currentSecondsToResendOtp);
        this.activateDisableResendTimer();
    }

    /**
     * Reset and stop the timer
     */
    public stopDisableResendTimer() {
        this.destroy();
        this.currentSecondsToResendOtp = 0;
        this.onTimerTick.next(this.currentSecondsToResendOtp);
    }

    /**
     * Activate or continue from the timer last run
     */
    public activateDisableResendTimer() {
        this.destroy();
        this.onVisibilityChange();

        this.subscriptions.push(
            timer(0, 1000).subscribe({
                    next : (time) => {
                        this.currentSecondsToResendOtp++;

                        this.onTimerTick.next(this.currentSecondsToResendOtp);

                        if (this.secondsLeftToResendOtp <= 0) {
                            this.stopDisableResendTimer();
                        }
                    },
                    error: (err) => {
                        console.error('Error in OtpTimer timer subscription:', err);
                        this.stopDisableResendTimer();
                    }
                }
            ));
    }

    public destroy() {
        //console.log('DESTROYING otpTimer, removing all subscriptions', this.subscriptions);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    /**
     * Mitigate browser background throttling and trigger a timer activation when the user re-enters the window
     */
    private onVisibilityChange():void {
        if (!this.documentState) {
            this.documentState = document.visibilityState;
        }

        this.subscriptions.push(
            fromEvent(document, 'visibilitychange').pipe(
                tap(() => {
                    const newState = document.visibilityState;
                    if (this.documentState === 'hidden' && newState === 'visible' && this.currentSecondsToResendOtp > 0) {
                        this.activateDisableResendTimer();
                    }
                    this.documentState = newState;
                })
            ).subscribe({
                error: (err) => console.error('Error in OtpTimer visibilitychange subscription:', err)
            })
        );
    }
}
