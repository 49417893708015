import {Injectable, Injector} from '@angular/core';
//import {PwaService} from '../../pwa/pwa.service';
import {DocumentRef} from '../../shared/ui/document-ref';
import {WindowRef} from '../../shared/ui/window-ref';
import {AbstractFacadeService} from '../abstract-facade.service';
import {FacadePlatform} from '../../shared/facade-platform';
import {Router} from '@angular/router';
import {CustomerAuth} from '../../auth/customer-auth.state';
import {ClearStorage} from '../../ngxs/runtime-error-plugin';
import {StorageService} from '../../shared/ui/storage/storage.service';
import {environment} from '../../implementation/environment';
import Logout = CustomerAuth.Logout;

@Injectable()
export class InterceptorService extends AbstractFacadeService {

    //private pwaService:PwaService;
    private documentRef:DocumentRef;
    private windowRef:WindowRef;
    private facadePlatform:FacadePlatform;
    private router:Router;
    private storageService:StorageService;

    constructor(private injector:Injector) {
        super(injector);
        //this.pwaService     = injector.get(PwaService);
        this.documentRef    = injector.get(DocumentRef);
        this.windowRef      = injector.get(WindowRef);
        this.facadePlatform = injector.get(FacadePlatform);
        this.router         = injector.get(Router);
        this.storageService = injector.get(StorageService);
        this.logger         = this.logger.getLogger('InterceptorService');
    }

    handleDowntimePodHeader() {
        this.logger.debug('Discovered a downtime pod in an XHR request!');
        //this.pwaService.handleDowntimePod();
        //this.documentRef.rewritePage(downtimeBody);
        this.windowRef.reload();
    }

    handleVersionHeader(newVersion:string) {
        // 0.0.0 is local testing, ignore
        if (environment.version !== '0.0.0' && newVersion !== environment.version) {
            const lastVersion = this.storageService.getItem('lastRefreshedVersion');
            // Only refresh if this isn't the second time we are trying to fetch the latest build
            if (lastVersion === newVersion) {
                this.logger.debug('Found a new version but already refreshed so ignoring');
                /*// Skip this error if the service worker is enabled as the check
                // will happen before it has a chance to update itself
                if (!this.swUpdate.isEnabled) {
                    // Throw in next frame otherwise it will be caught by redux logic lame silent error system
                    setTimeout(() => {
                        throw new Error('Invalid build newVersion, should be: ' + buildData.newVersion);
                    });
                }*/
            }
            else {
                this.logger.debug('Found a new version so refreshing the page');
                this.storageService.setItem('lastRefreshedVersion', newVersion);
                this.store.dispatch(new ClearStorage());
                this.windowRef.reload();
            }
        }
    }

    handleAuthenticationBounce() {
        // All places in the app should do this
        this.logger.info('401 Received from the server, launch auth popup');
        // Logout user as user needs to reauthenticate
        this.store.dispatch(new Logout());
        this.facadePlatform.reAuthenticateUser();

        /*if (this.urlService.isInCheckoutSection(this.router.url)) {
            this.logger.info('401 Received from the server, launch auth popup');
            this.facadePlatform.reAuthenticateUser();
        }
        else {
            const login = this.urlService.buildUrlForAuthLogin(this.windowRef.href);
            this.logger.info('401 Received from the server, clearing guid & redirecting: ', login);
            this.ngRedux.dispatch(CustomerAuthActions.logout());
            this.facadePlatform.handleLinkDirect(login);
        }*/
    }

    handleCartNoLongerExists() {
        this.facadePlatform.handleCartNoLongerExists();
    }

    getName():string {
        return 'InterceptorService';
    }
}
