import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import produce from 'immer';

export class UpdateCartQuantity {
    static readonly type = '[Shared Cart] Update cart quantity';

    constructor(public totalQuantity:number) {
    }
}

export interface SharedCartStateModel {
    total_quantity?:number;
}

@State<SharedCartStateModel>({
    name: 'sharedCart',
})
@Injectable()
export class SharedCartState {

    @Action(UpdateCartQuantity)
    searchAddress(ctx:StateContext<SharedCartStateModel>, action:UpdateCartQuantity) {
        return ctx.setState(produce(draft => {
            draft.total_quantity = action.totalQuantity;
        }));
    }

}
