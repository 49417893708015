import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';

@Component({
    selector       : 'desktop-page-preloader',
    template       : `
        <div class="bar-1"></div>
        <div class="bar-2"></div>
        <div class="bar-3"></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopPagePreloaderComponent {
    constructor(changeDetectorRef:ChangeDetectorRef) {
        // No changes needed
        changeDetectorRef.detach();
    }
}
