import {Injectable} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {ExtendedApolloHook} from '../extended-apollo/extended-apollo-hook';
import {ExternalResponse} from '../external-response';
import {Logger} from '../../shared/logger';
import {RuntimeErrorHandlerService} from '../../error-handling/runtime-error-handler.service';

@Injectable()
export class NetworkLoadMonitor extends ExtendedApolloHook {

    private static cnt   = 0;
    private xhrLoadCount = 0;
    private xhrIdle      = new BehaviorSubject(false);
    private xhrIsIdle    = false;

    private imgLoadCount = 0;
    private allIdle      = new BehaviorSubject(false);
    private allIsIdle    = false;

    constructor(public logger:Logger, public runtimeErrorHandlerService:RuntimeErrorHandlerService) {
        super();
        this.logger = this.logger.getLogger('NetworkLoadMonitor-' + NetworkLoadMonitor.cnt++);
        //this.logger.logLevel = LogLevel.TEST;
    }

    public handleStart() {
        this.trackXhrStart();
    }

    public handle(response:ExternalResponse<any>) {
        this.trackEnd();
        this.runtimeErrorHandlerService.checkForHttpErrors(response);
    }

    public handleError(response:ExternalResponse<any>) {
        this.trackEnd();
        this.runtimeErrorHandlerService.checkForHttpErrors(response);
    }

    // Without a debounce this class is utterly pointless, never pass 0
    public whenXhrIdle(debounce = 300) {
        this.logger.test('Adding when idle');
        const idle$ = this.xhrIdle.pipe(
            distinctUntilChanged(),
            filter(v => v === true),
            debounceTime(debounce)
        );
        // Simulate a single request for pages where there are none
        this.trackXhrStart();
        setTimeout(() => this.trackEnd(), debounce);
        return idle$;
    }

    public whenAllIdle(debounce = 300) {
        this.logger.test('Adding when idle');
        const idle$ = this.allIdle.pipe(
            distinctUntilChanged(),
            filter(v => v === true),
            debounceTime(debounce)
        );
        // Simulate a single request for pages where there are none
        this.trackImageToLoad();
        setTimeout(() => this.trackImageLoaded(), debounce);
        return idle$;
    }

    private trackXhrStart() {
        this.xhrLoadCount++;
        this.check();
    }

    private trackEnd() {
        this.xhrLoadCount--;
        this.check();
    }


    public trackImageToLoad() {
        this.imgLoadCount++;
        this.check();
    }

    public trackImageLoaded() {
        this.imgLoadCount--;
        this.check();
    }


    private check() {
        this.logger.test('check: ', this.xhrLoadCount, this.imgLoadCount);
        this.xhrIsIdle = this.xhrLoadCount === 0;
        this.allIsIdle = this.imgLoadCount === 0 && this.xhrIsIdle;

        this.xhrIdle.next(this.xhrIsIdle);
        this.allIdle.next(this.allIsIdle);
    }

}
