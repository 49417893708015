import {Injectable, Injector} from '@angular/core';
import {AbstractFacadeService} from '../external-data/abstract-facade.service';
import {switchMap, tap} from 'rxjs/operators';
import {ExternalResponse} from '../external-data/external-response';
import {FETCH_CUSTOMER_CART, FETCH_CUSTOMER_CART_CACHE} from './gql-queries/fetch-customer-cart';
import {CREATE_EMPTY_CART} from './gql-mutations/create-empty-cart';
import {FETCH_CART_MINIMAL, FETCH_CART_MINIMAL_CACHE} from './gql-queries/fetch-cart-minimal';
import {Observable, of, throwError} from 'rxjs';
import {ADD_TO_CART} from './gql-mutations/add-to-cart';
import {
    AddProductsToCartOutput,
    Cart,
    CartItemInput,
    CartItemInterface,
    ItemTrackingInfo,
    ReinstateCartInput,
    ReinstateCartOutput
} from '../../../../generated/graphql';
import {MERGE_CART, MergeCartVariables} from './gql-mutations/merge-cart';
import {
    FETCH_CART_FOR_CHECKOUT,
    FETCH_CART_FOR_CHECKOUT_CACHE
} from '../checkout/checkout-shared/queries/fetch-cart-for-checkout';
import {FETCH_CART_MAX, FETCH_CART_MAX_CACHE} from './gql-queries/fetch-cart';
import {Router} from '@angular/router';
import {FacadePlatform} from '../shared/facade-platform';
import {environment} from '../implementation/environment';
import {REINSTATE_CART} from '../checkout/payment/mutations/reinstate-cart';
import {ImplementationDataService} from '../implementation-config/implementation-data.service';
import {UpdateCartQuantity} from './shared-cart.state';
import {StorageService} from '../shared/ui/storage/storage.service';
import {RootState} from '../ngxs/root.state';

export interface FetchCustomerCartResponse {
    customerCart:{
        id:string;
        total_quantity:number;
    };
}

interface ReinstateCartResponse {
    reinstateCart:ReinstateCartOutput;
}


interface FetchCartResponse {
    cart:Cart;
}

interface CreateEmptyCartResponse {
    createEmptyCart:string;
}

interface CartInputVariables {
    cartId:string;
    cartItems:CartItemInput[];
}

export interface MergeCartResponse {
    mergeCarts:Cart;
    originalCartTotal?:number;
    mergedCartTotal?:number;
}

export interface AddProductsToCartResponse {
    addProductsToCart:AddProductsToCartOutput;
}

export interface PersistedCartStateModel {
    cart_id:string;
    isGuestCart:boolean;
}


@Injectable()
export class SharedCartService extends AbstractFacadeService {

    private cartStateModelKey = 'cartStateModel';
    private lastCartIdKey     = 'lastCartId';

    private storageService:StorageService;

    constructor(injector:Injector,
                private router:Router,
                private facadePlatform:FacadePlatform,
                private implementationDataService:ImplementationDataService) {
        super(injector);
        this.storageService = injector.get(StorageService);
    }

    getCartId():string {
        return this.fetchCartState().cart_id;
    }

    checkCartExists():boolean {
        return (this.fetchCartState()?.cart_id != null);
    }

    /**
     * Ensures the cart has been created (for guest) or fetched (for authenticated user)
     * and is available in localstorage and redux
     */
    checkCartExistsAndCreateIfNot(createIfNot = true):Observable<ExternalResponse<FetchCartResponse> | ExternalResponse<FetchCustomerCartResponse>> {
        if (this.fetchCartState().cart_id) return of(null);

        // The last cartId is used on the payment page in case the user hits the browser back button.
        // Is must be short lived, so clearing it the very next time we try and fetch a cart
        this.clearLastCartId();

        return this.store.selectOnce((s:RootState) => s.customer.isLoggedIn)
            .pipe(
                switchMap((isLoggedIn) => {
                    if (isLoggedIn) {
                        return this.fetchAuthenticatedCart().pipe(
                            tap((res:ExternalResponse<FetchCustomerCartResponse>) => this.storeCart(res.data.customerCart.id, false))
                        );
                    }
                    else {
                        return this.apollo.mutate<CreateEmptyCartResponse>({mutation: CREATE_EMPTY_CART}).pipe(
                            switchMap(res => {
                                return this.fetchCart('minimal', res.data.createEmptyCart);
                            }),
                            tap((res:ExternalResponse<FetchCartResponse>) => this.storeCart(res.data.cart.id, true))
                        );
                    }
                })
            );

    }

    /**
     * All requests to get the cart from the server must come via this method.
     * As this looks for cart errors and redirects to the cart page for users to resolve
     * @param type The specific amount of data you want to come back
     * @param cartId Optionally provide this, else the method will assume the cart already exists
     */
    fetchCart(type:'minimal' | 'max' | 'checkout', cartId?:string):Observable<ExternalResponse<FetchCartResponse>> {
        let query;
        let cachePolicy;

        return this.store.selectOnce(s => s.customer.isLoggedIn)
            .pipe(
                switchMap((isLoggedIn) => {
                    switch (type) {
                        case 'checkout':
                            query       = FETCH_CART_FOR_CHECKOUT(isLoggedIn);
                            cachePolicy = FETCH_CART_FOR_CHECKOUT_CACHE;
                            break;
                        case 'max':
                            query       = FETCH_CART_MAX(this.implementationDataService.getHasPimSupport(), isLoggedIn);
                            cachePolicy = FETCH_CART_MAX_CACHE;
                            break;
                        case 'minimal':
                            query       = FETCH_CART_MINIMAL;
                            cachePolicy = FETCH_CART_MINIMAL_CACHE;
                            break;
                    }


                    return this.apollo
                        .query<FetchCartResponse>(
                            {
                                query      : query,
                                variables  : {cartId: (cartId) ? cartId : this.getCartId()},
                                errorPolicy: 'all'
                            },
                            cachePolicy,
                            true
                        );
                }),
                switchMap(res => {

                    if (res?.data?.cart?.total_quantity != null) this.updateCartQuantity(res?.data?.cart?.total_quantity);

                    // When the cart is returned populated as well as the error object,
                    // then we're dealing a stock issue on an item in the cart.
                    if (res?.apolloErrors?.length > 0) {
                        // Check the current route
                        // If not on the cart page, then redirect to the cart page
                        if (res.data.cart !== null) {
                            const cartUrl = this.urlService.buildUrlForCheckoutCart();
                            if (this.router.url !== cartUrl) {
                                this.facadePlatform.handleLinkDirect(cartUrl);
                            }
                        }
                        return throwError(res);
                    }
                    return of(res);
                }),
                tap(res => {
                    // Look for and remove placeholder email applied
                    if (res?.data?.cart?.email) {
                        const testReg:RegExp = new RegExp(environment.config.api.emailPlaceholderReg);
                        if (testReg.test(res.data.cart.email)) res.data.cart.email = null;
                    }
                })
            );
    }

    /**
     * Adds a configurable product with it's selected_options to the cart.
     * @param configurableSku The sku for the configurable product in question
     * @param simpleSku The sku for the simple product in question
     * @param quantity How many items to add
     * @param fetchFullCart Return everything required to render the cart page
     * @param itemTracking
     */
    addToCart(configurableSku:string, simpleSku:string, quantity:number, fetchFullCart:boolean = false, itemTracking:ItemTrackingInfo = {}):Observable<ExternalResponse<AddProductsToCartResponse>> {
        let isLoggedIn:boolean;
        return this.store.selectOnce(s => s.customer.isLoggedIn).pipe(
            switchMap((loggedIn) => {
                isLoggedIn = loggedIn;
                return this.checkCartExistsAndCreateIfNot()
            }),
            switchMap(() => {
                const variables:CartInputVariables = {
                    cartId   : this.fetchCartState().cart_id,
                    cartItems: [{
                        parent_sku  : configurableSku,
                        sku         : simpleSku,
                        quantity    : quantity,
                        itemTracking: itemTracking
                    }]
                };
                return this.apollo.mutate({mutation: ADD_TO_CART(this.implementationDataService.getHasPimSupport(), fetchFullCart, isLoggedIn), variables: variables}, true);
            }),
            switchMap((res:ExternalResponse<AddProductsToCartResponse>) => {
                // Update the quantity if we have it as some errors are ignored
                if (res?.data?.addProductsToCart?.cart) {
                    this.updateCartQuantity(res.data.addProductsToCart.cart.total_quantity);
                }

                if (res?.data?.addProductsToCart?.user_errors?.length > 0) {
                    res.error = res.data;
                    return throwError(res);
                }
                else {
                    return of(res);
                }
            })
        );
    }

    /**
     * Required when moving from a guest cart to an authenticated one
     * This happens when a user is logged in
     */
    mergeCart():Observable<ExternalResponse<MergeCartResponse | FetchCustomerCartResponse>> {
        const state = this.fetchCartState();

        // If the current cart is a guest, then we need to merge
        if (state.isGuestCart) {
            let oldTotal:number;
            return this.fetchAuthenticatedCart()
                .pipe(
                    switchMap(res => {
                        // Store old total
                        oldTotal = res.data.customerCart.total_quantity;
                        return this.apollo.mutate<MergeCartResponse, MergeCartVariables>(
                            {
                                mutation : MERGE_CART,
                                variables: {guestCartId: state.cart_id, authenticatedCartId: res.data.customerCart.id}
                            },
                            true
                        );
                    }),
                    tap((res:ExternalResponse<MergeCartResponse>) => {
                        this.storeCart(res.data.mergeCarts.id, false);
                        this.updateCartQuantity(res.data.mergeCarts.total_quantity);
                        res.data.originalCartTotal = oldTotal;
                        res.data.mergedCartTotal   = res.data.mergeCarts.total_quantity;
                    })
                );
        }
            // If not a guest, we may already have the cart but we need to clear and re-fetch
        // as the user logging in may be different, we don't know
        else {
            this.clearCart();
            return this.fetchAuthenticatedCart().pipe(
                tap((res:ExternalResponse<FetchCustomerCartResponse>) => this.storeCart(res.data.customerCart.id, false))
            );
        }
    }

    reinstateCart(cartId:string):Observable<ExternalResponse<ReinstateCartResponse>> {
        const input:ReinstateCartInput = {cart_id: cartId};
        let isLoggedIn;
        return this.store.selectOnce((s:RootState) => s.customer.isLoggedIn)
            .pipe(
                switchMap((pIsLoggedIn) => {
                    isLoggedIn = pIsLoggedIn;
                    return this.apollo.mutate(
                        {
                            mutation : REINSTATE_CART,
                            variables: {input: input}
                        },
                        true
                    );
                }),
                tap((res:ExternalResponse<ReinstateCartResponse>) => {
                    this.clearLastCartId();
                    this.storeCart(res.data.reinstateCart.cart.id, !isLoggedIn);
                })
            );
    }

    restoreCartOnPaymentError(cartId:string, isLoggedIn:boolean) {
        this.saveCartState({cart_id: cartId, isGuestCart: !isLoggedIn});
    }

    /**
     * Used to recover from a back button press on peach page
     */
    setLastCartId() {
        this.storageService.setItemObject(this.lastCartIdKey, this.getCartId());
    }

    getLastCartId():string {
        return this.storageService.getItemObject(this.lastCartIdKey);
    }

    private clearLastCartId() {
        this.storageService.setItemObject(this.lastCartIdKey, null);
    }

    /**
     * Clears the cart count and local storage entries
     */
    clearCart() {
        this.updateCartQuantity(0);
        this.clearCartState();
    }

    private fetchAuthenticatedCart():Observable<ExternalResponse<FetchCustomerCartResponse>> {
        return this.apollo.query<FetchCustomerCartResponse>({query: FETCH_CUSTOMER_CART}, FETCH_CUSTOMER_CART_CACHE, true).pipe(
            tap(res => {
                if (res?.data?.customerCart?.total_quantity != null) this.updateCartQuantity(res?.data?.customerCart?.total_quantity);
            })
        );
    }


    // Cart persistence
    // ----------------
    private saveCartState(value:PersistedCartStateModel) {
        this.storageService.setItemObject(this.cartStateModelKey, value);
    }

    private fetchCartState():PersistedCartStateModel {
        const state = this.storageService.getItemObject(this.cartStateModelKey);
        return (!state) ? {} : state;
    }

    private clearCartState() {
        this.storageService.setItemObject(this.cartStateModelKey, {});
    }

    private storeCart(cartId:string, isGuestCart:boolean) {
        this.saveCartState({cart_id: cartId, isGuestCart: isGuestCart});
    }

    private updateCartQuantity(total_quantity:number) {
        this.store.dispatch(new UpdateCartQuantity(total_quantity));
    }

    /**
     * Fetch the prices and validate if all larger than 0
     * @param cart
     * @returns boolean value indicating if there is 0 price invalid items
     */
    public hasInvalidPriceValues(cart:Cart):boolean {
        return cart.items?.filter(x => x !== null).findIndex((x:CartItemInterface) => {
            return x.prices.price.value <= 0;
        }) >= 0;
    }

    // ----------------

    getName():string {
        return 'AddToCartService';
    }

}
