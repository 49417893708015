import {gql} from 'apollo-angular';

export const SET_SHIPPING_ADDRESSES_ON_CART = gql`
    mutation setShippingAddressesOnCart($input: SetShippingAddressesOnCartInput) {
        setShippingAddressesOnCart(input: $input) {
            cart {
                shipping_addresses {
                    firstname
                    lastname
                    telephone
                    company
                    street
                    city
                    postcode
                    region {
                        code
                        label
                        region_id
                    }
                    country {
                        code
                        label
                    }
                    
                    customer_notes
                }
            }
        }
    }
`;
