import {Injectable, Injector} from '@angular/core';
import {combineLatest, map} from 'rxjs/operators';
import {AbstractSelector} from '../../ngxs/abstract.selector';
import {ViewState, ViewStateEnum} from './view-state';
import {Observable, of} from 'rxjs';
import {RootState} from '../../ngxs/root.state';

@Injectable()
export class ViewStateSelector extends AbstractSelector {

    constructor(injector:Injector) {
        super(injector);
    }

    getState$<T extends ViewState>(viewName:string, ViewStateObj?:T):Observable<T> {
        return of(ViewStateObj == null ? new ViewState() : ViewStateObj).pipe(
            combineLatest(this.store.select((s:RootState) => s.view.stateHash[viewName])),
            map(values => {
                const inst   = values[0];
                inst.current = values[1] != null ? values[1] : ViewStateEnum.INITIALIZING;
                // This is an <any> hack, as we want the above 'extends' ViewState so we can override ViewState only if its an extension
                // To be honest the error here I think is a bug with typescript 2.4
                return <any>inst;
            })
        );
    }

    getProps$<T>(viewName:string):Observable<T> {
        return this.store.select((s:RootState) => s.view.propHash[viewName]);
    }
}
