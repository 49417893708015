import {tap} from 'rxjs/operators';
import {Logger} from './logger';
import {LogLevel} from './logger-impl-core';

const debuggerOn   = false;
// Generics here are key to pass on the data type in the pipe stream
export const debug = <T>(message:string) => tap<T>(
    next => {
        if (debuggerOn) console.log('-------------------------> ', message, next);
    },
    err => {
        if (debuggerOn) console.error('ERROR >>> ', message, err);
    },
    () => {
        if (debuggerOn) console.log('Completed.');
    }
);

// Generics here are key to pass on the data type in the pipe stream
export const debugWithLogger = <T>(message:string, logger:Logger, levelToLogAt = LogLevel.DEBUG) => tap<T>(
    next => {
        logger.outputLog(levelToLogAt, [message, next]);
    },
    err => {
        logger.outputLog(LogLevel.ERROR, [message, err]);
    },
    () => {
        // not really needed
        // logger.outputLog(levelToLogAt, ['Completed']);
    }
);
