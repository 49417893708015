export interface ImplementationLinks {
    pepkorRetailSegments?:string;
    pepkorPrivacyStatement?:string;
    termsGeneral?:string;
    termsCookiePolicy?:string;
    shippingInfo?:string;
    termsPrivacy?:string;
    returnsFAQ?:string;
}

export interface BrowserUpgradeOptions {
    /* Brower-upgrade.org, for more options see: http://browser-update.org/customize.html */
    // Specifies required browser versions
    // Browsers older than this will be notified.
    // f:22 ---> Firefox < 22 gets notified
    // Negative numbers specify how much versions behind current version.
    // c:-5 ---> Chrome < 35  gets notified if latest Chrome version is 40.
    // more details (in english)
    reminder:number,
    // after how many hours should the message reappear
    // 0 = show all the time

    reminderClosed:number,
    // if the user explicitly closes message it reappears after x hours
    required:{
        e:number;
        f:number;
        o:number;
        s:number;
        c:number;
        samsung:number;
    },
    text?:string;
    // This is the version of the browser-update api to use. Please do not remove.
    insecure?:boolean;
    api:number;
    test?:boolean; // true = always show the bar (for testing)
    style?:'bottom' | 'top' | 'corner';

    onshow?(info?:any):void;

    onclick?(info?:any):void;

    onclose?(info?:any):void;
}

export interface GridSettings {
    grid:GridOption[];
    default:number;
}

export interface GridOption {
    pageSize:number;
    gridClass:string;
    iconClass?:string;
    dots?:number;
}

export interface ScrollSettings {
    header:{
        fixed?:boolean;
        scrollingOffset?:number;
        collapseHeight?:number;
        hideOnScrollDown?:boolean;
        conditionalCssClass?:string;
        unCollapseAtBottom?:boolean;
    };
    productFilters?:{
        fixed?:boolean;
        adjustToHeader?:boolean;
        headerOffset?:number;
        footerOffset?:number;
    };
}

export interface PWASettings {
    enabled:boolean;
    notifications?:{
        enabled:boolean
    };
    installPrompt?:{
        enabled:boolean
    };
}


export interface DeliverySettings {
    homeDelivery:boolean;
    forceGoogleValidation?:boolean;
    forceLeadingStreetNumber?:boolean;
    options?:DeliveryOption[];
    deliveryPageStaticInfoSlug?:string;
}

export enum DeliveryType {
    DELIVERY = 'delivery',
    COLLECT  = 'collect',
}

export interface DeliveryMethod {
    title:string;
    code:string;
    price?:number;
    currency?:string;
}

export interface DeliveryOption {
    type:DeliveryType;
    heading:string;
    detail:string;
    deliveryTime:string;
    icon?:string;
    methods?:DeliveryMethod[];
}

export enum AddressType {
    SHIPPING = 'shipping',
    BILLING  = 'billing'
}

export enum DeliveryAddressType {
    RESIDENTIAL = 'residential',
    BUSINESS    = 'business'
}

export interface SisterSiteSettings {
    imageUrl:string;
    link:string;
    alt:string;
    active:boolean;
}

export interface AplusPromotionSettings {
    enabled:boolean;
    link?:string;
    imageUrl?:string;
    interestRate?:number;
    repaymentTerm?:number;
    monthServiceFee?:number;
    paymentLogos?:PaymentLogo[];
}

export interface PaymentLogoType {
    type:'cards' | 'accounts';
    items:PaymentLogo[];
}

export interface PaymentLogo {
    label:string;
    imageUrl:string;
}

export interface PlusMorePostRegisterPromotion {
    enabled:boolean;
    cmsContentSlug:string[];
    querySelector:string,
    queryValue:string,
    cssClass:string,
    ctaButtons:{ locale:string, type:'primary' | 'light', action:'view' | 'cancel' }[]
}

export enum RecaptchaSectionType {
    AUTH              = 'auth',
    AUTH_REGISTER     = 'auth-register',
    CART              = 'cart',
    DELIVERY          = 'delivery',
    DELIVERY_OTP      = 'delivery-otp',
    PAYMENT           = 'payment',
    PRODUCT           = 'product',
    CELLULAR          = 'cellular',
    FOOTER            = 'footer',
    IN_PAGE_FOOTER    = 'in-page-footer',
    CONTACT_US        = 'contact-us',
    SOCIAL_INVESTMENT = 'social-investment',
    CALL_ME           = 'call-me'

}
