<ng-container *ngIf="viewProps$ | async as viewProps">
    <form (ngSubmit)="handleSubmit()" [formGroup]="newsletterForm" novalidate>
        <span class="newsletter-info">{{'newsletter.infoReason' | translate}}</span>
        <d-form-input
            class="smaller"
            [placeHolder]="'newsletter.email' | translate"
            [formControlName]="'emailControl'"
        ></d-form-input>
        <error-message *ngIf="(state$ | async)?.isError" [errorMsgHtml]="'error.defaultMessage' | translate"></error-message>
        <button type="submit" class="btn btn-sm btn-info subscribe-but" [attr.disabled]="formIsDisabled ? 'disabled' : null">
            <span *ngIf="!formIsDisabled">{{'newsletter.subscribe'  | translate}}</span>
            <spinner *ngIf="formIsDisabled"></spinner><span *ngIf="formIsDisabled">{{'submitting' | translate}}</span>
        </button>
    </form>
</ng-container>
