import {gql} from 'apollo-angular';


export const GET_REST_PRODUCT_ATTRIBUTES_CACHE = true;

export const GET_REST_PRODUCT_ATTRIBUTES = gql`
    query restProductAttributes {
        restProductAttributes {
            used_for_sort_by
            is_filterable
            is_filterable_in_search
            position
            is_comparable
            is_visible_on_front
            used_in_product_listing
            is_visible
            attribute_id
            attribute_code
            frontend_input
            is_user_defined
            default_frontend_label
            default_value
            apply_to
            options {
                label
                value
                sort_order
            }
        }
    }
`;
