import {gql} from 'apollo-angular';


export const UPLOAD_DOCUMENT = gql`
    mutation uploadDocument($CompanyNo: Int, $DestinationId: Int, $Files: [DocumentFile], $Metadata: JSON, $Origin: String) {
        uploadDocument(
            CompanyNo: $CompanyNo,
            Origin: $Origin,
            DestinationId: $DestinationId,
            Files: $Files,
            Metadata: $Metadata
        ) {
            ResponseCode
            ResponseDescription
        }
    }
`;

