import {AbstractFacadeService} from '../abstract-facade.service';
import {Injectable, Injector} from '@angular/core';
import {WindowRef} from '../../shared/ui/window-ref';
import {Observable} from 'rxjs';
import {ExternalResponse} from '../external-response';
import {WP_GET_HIT_COUNT, WP_GET_HIT_COUNT_CACHE} from './gql-queries/wp-get-hit-count';
import {GqlHitCount} from '../../../../../generated/graphql';
import {WP_ADD_HIT} from './gql-queries/wp-add-hit';
import {StorageService} from '../../shared/ui/storage/storage.service';

interface HitCountResponse {
    wpGetHitCount:GqlHitCount;
}

@Injectable()
export class HitCounterService extends AbstractFacadeService {

    private storageService:StorageService;

    constructor(injector:Injector,
                public windowRef:WindowRef) {
        super(injector);
        this.storageService = injector.get(StorageService);
    }

    getHitCount():Observable<ExternalResponse<HitCountResponse>> {
        // First determine the page url for the key
        const currentUrl      = new URL(this.windowRef.href);
        const url             = decodeURIComponent(currentUrl.pathname);
        let hitCache:string[] = this.storageService.getItemObject('hitCache') as string[];
        if (!hitCache) hitCache = [];
        const hasBeenHit = hitCache.find(page => page === url) != null;

        if (hasBeenHit) {
            // Just return GET
            return this.getHitCountAPI(url);
        }
        else {
            // add to cache and trim to 20
            hitCache.unshift(url);
            hitCache = hitCache.slice(0, 20);
            this.storageService.setItemObject('hitCache', hitCache);
            return this.getAndUpdateHitCountAPI(url);
        }
    }

    private getHitCountAPI(url:string):Observable<ExternalResponse<HitCountResponse>> {
        return this.apollo.query({
            query    : WP_GET_HIT_COUNT,
            variables: {url: url}
        }, WP_GET_HIT_COUNT_CACHE);
    }

    private getAndUpdateHitCountAPI(url:string):Observable<ExternalResponse<HitCountResponse>> {
        return this.apollo.mutate<HitCountResponse>({
            mutation : WP_ADD_HIT,
            variables: {url: url}
        });
    }

    getName():string {
        return 'HitCounterService';
    }
}
