import {AbstractFacadeComponent} from '../../shared/ui/abstract-facade.component';
import {filter} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';

export abstract class AbstractLogoutPromptComponent extends AbstractFacadeComponent {
    static LOGOUT   = 'LOGOUT';
    static CHECKOUT = 'CHECKOUT';
    static CANCEL   = 'CANCEL';

    router:Router;

    abstract closeModal();

    constructor(injector) {
        super(injector);
        this.router = injector.get(Router);
        // close when navigating away
        this.addSub = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(event => {
                this.destroy();
                this.closeModal();
            });
    }

    handleLogout(event:Event) {
        event.preventDefault();
        event.stopPropagation();
        this.destroy();
    }

    completeCheckout(event:Event) {
        event.preventDefault();
        event.stopPropagation();
        this.destroy();
        this.handleLinkDirect(this.urlService.buildUrlForCheckoutCart());
    }


    get name() {
        return 'LogoutPromptComponent';
    }
}