import {gql} from 'apollo-angular';

export const FETCH_CUSTOMER_LOCATION_CODE_CACHE = false;

export const FETCH_CUSTOMER_LOCATION_CODE = gql`
    query customer_locationCode {
        customer {
            pickup_location_code
        }
    }
`;
