import {
    ApplicationRef,
    ComponentRef,
    createComponent,
    EnvironmentInjector,
    Injectable,
    Injector,
    Type
} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LazyComponentLoader {
    constructor(private applicationRef:ApplicationRef) {
    }

    /**
     * Wraps up the lazy load to make it quicker and easier to reason about when implementing.
     * Example impl:
     * `this.productCarouselComponent = await this.lazyComponentLoader.load(
     *      import('../../product-carousel/product-carousel.component').then(c => c.ProductCarouselComponent),
     *      this.productCarouselHolder.nativeElement
     * );`
     */
    async load<C>(loadPromise:Promise<Type<C>>,
                  hostElement?:Element,
                  environmentInjector?:EnvironmentInjector,
                  elementInjector?:Injector,
                  projectableNodes?:Node[][]):Promise<C> {
        // See https://angular.io/api/core/createComponent

        // Lazy load the component
        const lazyComp = await loadPromise;

        // Either use supplied environmentInjector or default
        environmentInjector = (environmentInjector) ? environmentInjector : this.applicationRef.injector;

        const compRef:ComponentRef<C> = createComponent(
            lazyComp,
            {
                hostElement,
                environmentInjector,
                elementInjector,
                projectableNodes
            }
        );

        // Last step is to register the newly created ref using the `ApplicationRef` instance
        // to include the component view into change detection cycles.
        this.applicationRef.attachView(compRef.hostView);

        return compRef.instance;
    }
}
