import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import produce from 'immer';

export class HasStartupError {
    static readonly type = '[Startup] Error';

    constructor(public startupError:Error, public hasStartupError = true) {
    }
}

export class AppStatePageLoaded {
    static readonly type = '[AppState] PageLoaded';

    constructor(public pageLoaded = true) {
    }
}

export interface AppStateModel {
    hasStartupError:boolean;
    // This indicates if the content for the first page has been loaded, this can be overridden and controller per page should you want, see AbstractFacadePageComponent.pageLoaded
    initialPageLoaded:boolean;
    startupError?:Error;
}

@State<AppStateModel>({
    name    : 'app',
    defaults: {
        hasStartupError  : false,
        initialPageLoaded: false
    }
})
@Injectable()
export class AppState {

    @Action(HasStartupError)
    hasStartupError(ctx:StateContext<AppStateModel>, action:HasStartupError) {
        ctx.setState(produce(draft => {
            draft.hasStartupError = action.hasStartupError;
            draft.startupError    = action.startupError;
        }));
    }

    @Action(AppStatePageLoaded)
    pageLoaded(ctx:StateContext<AppStateModel>, action:AppStatePageLoaded) {
        ctx.setState(produce(draft => {
            draft.initialPageLoaded = action.pageLoaded;
        }));
    }

}
