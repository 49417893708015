import {Injectable} from '@angular/core';
import {DocumentRef} from '../../shared/ui/document-ref';
import {WindowRef} from '../../shared/ui/window-ref';
import {Logger} from '../../shared/logger';
import {StaticConfig} from '../../shared/static-config';

@Injectable()
export class YoutubeScriptLoaderService {

    state:'none' | 'added' | 'loaded' | 'failed' = 'none';

    promiseReferences:{ resolve:() => void, reject:(reason?) => void }[] = [];

    constructor(public documentRef:DocumentRef,
                public windowRef:WindowRef,
                public logger:Logger) {
        this.logger = this.logger.getLogger('YoutubeScriptLoaderService');
    }

    loadAPIOrResolve():Promise<void> {

        if (this.state === 'none') {
            this.logger.test('Adding YouTube API script');
            return new Promise((resolve, reject) => {
                this.promiseReferences.push({resolve, reject});
                this.state   = 'added';
                let timedOut = false;

                // API on load reference
                const onLoad = () => {
                    if (timedOut) {
                        this.logger.test('You tube API loaded but already timed out');
                        this.state = 'loaded';
                        return;
                    }
                    clearTimeout(timer);

                    this.logger.test('You tube API loaded');
                    this.state = 'loaded';
                    delete this.windowRef.nativeWindow['onYouTubeIframeAPIReady'];
                    this.resolve();
                };

                // Basic script error
                const onError = () => {
                    if (timedOut) {
                        this.logger.error('You tube API error but already timed out');
                        return;
                    }
                    clearTimeout(timer);

                    this.logger.error('You tube API load error');
                    this.state = 'failed';
                    this.reject('YouTube API load error');
                };

                // Timeout fallback
                const onTimeout = () => {
                    timedOut   = true;
                    this.state = 'failed';
                    this.logger.error('You tube API timed out');
                    this.reject('YouTube API load timeout');
                };

                // Add script & handlers
                const script                                           = this.documentRef.nativeDocument.createElement('script');
                script.src                                             = 'https://www.youtube.com/iframe_api';
                script.async                                           = true;
                const timer                                            = setTimeout(onTimeout, StaticConfig.SCRIPT_TAG_TIMEOUT);
                this.windowRef.nativeWindow['onYouTubeIframeAPIReady'] = onLoad;
                script.onerror                                         = onError;
                this.documentRef.nativeDocument.head.appendChild(script);
            });
        }
        // Different responses per state as 1 script must be loaded but
        // this API should support multiple clients
        else if (this.state === 'added') {
            this.logger.test('Additional load request, pending ready');
            return new Promise((resolve, reject) => {
                this.promiseReferences.push({resolve, reject});
            });
        }
        else if (this.state === 'loaded') {
            return Promise.resolve();
        }
        else if (this.state === 'failed') {
            return Promise.reject();
        }
    }

    reject(reason?) {
        const clone            = this.promiseReferences.concat();
        this.promiseReferences = [];
        clone.forEach(ref => ref.reject(reason));
    }

    resolve() {
        const clone            = this.promiseReferences.concat();
        this.promiseReferences = [];
        clone.forEach(ref => ref.resolve());
    }

}
