import {Injector} from '@angular/core';
import {Logger} from '../shared/logger';
import {Store} from '@ngxs/store';

export abstract class AbstractSelector {

    public logger:Logger;
    protected store:Store;

    protected constructor(injector:Injector) {
        this.logger = injector.get(Logger);
        this.store  = injector.get(Store);
    }
}
