import {SortOption} from '../../product-list-page/filters/filters.types';
import {FacadeAppDefinitionType} from '../../../../scripts/shared/facade-config.types';
import {GenericSEOData} from '../../seo/seo-types';

export interface FMDBRoot {
    [environment:string]:FMRoot;
}

export interface FMRoot {
    //mobile:FMPlatform;
    //desktop:FMPlatform;
    [appName:string]:FMPlatform;
}

export interface FMPlatform {
    envName:string;
    version:string;
    pwaName:string;
    appType:FacadeAppDefinitionType;
    config:FMConfig;
    uiConfig:FMUIConfig;
    data:FMData;
    build:FMBuildInfo;
}


export interface FMConfig {
    api:FMConfigAPI;
    // Deprecated with wordpress, to be removed when moving to QA
    customPageSEOData:FMCustomPageSEOData[];
    defaultLatLng:FMLatLong;
    headOfficeLatLng:FMLatLong;
    redirects?:FMConfigRedirect[];
    useHashUrlMobile?:boolean;
    sisterSites?:FMSisterSiteUrls;
}

export interface FMConfigAPI {
    // These two will be the same except on local when testing
    websiteBasePath:string;
    middlewareBasePath:string;
    paymentShopperResultUrl:string;

    dbPrefix?:string;

    production:boolean;
    firebaseUploadPath:string;
    firebaseConfig:any[];
    gtmTracker:string;
    sentryDSN:string;
    sentryAllowUrls:string[];
    recaptcha:string;

    gotbotScriptUrl?:string;
    gotbotPercentageThrottle?:number;

    // Generic Form API, used for most forms
    genericFormCompanyNo:string;
    genericFormOrigin:string;

    cdnBaseUrl:string;
    magentoImageBaseUrl:string;
    imagekitProductImagesUrl:string;
    imagekitWordpressMediaUrl:string;

    documentBaseUrl:string;
    cdnRootUrl:string;
    attributeStylesUrl:string;
    attributeStylesFontName:string;
    brandLogoImageBaseUrl?:string;
    productAttributeAssetBaseUrl?:string;
    careersUrl?:string;

    // CustomerAPI, used for news letters, email confirmation etc. Written by Duncan
    newsletterAPIBrand:string;

    // Regex used to identify placeholder numbers
    emailPlaceholderReg:string;

    // Store finder api
    storeFinderBrand:string;

    // Micro Service APIs
    tenant:string;

    addThisTracker?:string;

    buyButtonJs?:string;
    buyButtonCss?:string;
    buyButtonToken?:string;

    wordpressURL:string;
    // Ordering is used to determine page overrides and the website base paths is
    wordpressRootPages:string[];
    wordpressTypeOverride?:FacadeAppDefinitionType;
    // Used to map, the root page key for each website & the base url
    // used to redirect across domain on wordpress preview link
    wordpressRootPageMap:FMWordpressRootPageMap[];

    // TEMP
    wordpressBlogPostsEnabled:boolean;

    paxiNodeSelectorWidgetUrl?:string;

    // Not a config requirement but used internally, therefore calculated in the reducer
    urlOrigin?:string;

    nativeAssetPath:string;

    imagekitWordpressEnabled?:boolean;
    imagekitProductImagesEnabled?:boolean;
    crazyeggScriptUrl?:string;

    segmentifyEnabled:boolean
    segmentifyScriptUrl?:string;

    storeCreditEnabled:boolean;

    seoBase:GenericSEOData;
}


export interface FMFirebaseConnectionDetails {
    apiKey:string;
    authDomain:string;
    databaseURL:string;
    projectId:string;
    storageBucket:string;
    messagingSenderId:string;
    timeout:number;
}

export interface FMWordpressRootPageMap {
    key:string;
    websiteBasePath:string;
}

export interface FMData {
    contactEmail?:string;
    contactNumber?:string;
    contactNumberInternational?:string;
    facebookLink?:string;
    twitterLink?:string;
    youTubeLink?:string;
    tikTokLink?:string;
    linkedInLink?:string;
    instagramLink?:string;
    iosAppUrl?:string;
    androidAppUrl?:string;
    whatsappLink?:string;
}

export interface FMLatLong {
    latitude:number;
    longitude:number;
}

export interface FMConfigRedirect {
    // Relative url only
    sourcePath:string;
    // Relative or absolute url
    targetURL:string;
    newWindowWhenClicked?:boolean;
    includeChildPages?:boolean;
}

export interface FMUIConfig {
    //enableCart?:boolean;
    //enableWishlist?:boolean;
    //enableCompare?:boolean;
    disablePromotionalPages:boolean;
    productListSortFields:SortOption[];

    footerLinkElements?:FMMenuElementsGroup;
    footerMenuElements?:FMMenuElementsGroup;
    footerTermsLinkElements?:FMMenuElements;
    noContentMenuElements?:FMMenuElements;

    desktopMainMenu?:FMDesktopMenuList;
    mobileMainMenu?:FMMobileMenuList;

    catalogs?:FMCatalogData[];
    addToCartCategoryIDs?:number[];

    homePageBannerRatio:FMUIConfigBannerRatio;
    promotionalPageBannerRatio:FMUIConfigBannerRatio;
    subPromotionalPageBannerRatio:FMUIConfigBannerRatio;

    iframeExternalPages?:FMIFrameExternalPage[];


    attributeCodes?:AttributeGroup[];

    // Defaults to false
    showScrollToTop?:boolean;


    // Not a config requirement but used internally, therefore calculated in the reducer
    //attributeCodeInclusionListHash?:{[attribute_code:string]:boolean};
    interactiveAttrCodeInclusionListHash?:{ [attribute_code:string]:boolean };
}

export enum AttributeGroupType {
    NAME_AND_ICON = 'NAME_AND_ICON',
    NAME_ONLY     = 'NAME_ONLY'
}

export interface AttributeGroup {
    name:string;
    type:AttributeGroupType;
    singleAttrCodes:AttributeCodeToIcon[];
    singleIncludeCodes:string[];
    //multiSelectAttrCodes:MultiAttribute[];
}

/*export interface MultiAttribute {
    id:string;
    attrs:AttributeCodeToIcon[];
}*/

export interface AttributeCodeToIcon {
    code:string;
    icon:string;
}

export interface FMUIConfigBannerRatio {
    width:number;
    height:number;
}


export interface FMIFrameExternalPage {
    urlKey:string;
    url:string;
    title:string;
    authRequired?:boolean;
    dummyPage?:boolean;
}

export interface FMSisterSiteUrls {
    ackermans: string;
    cube: string;
    connect: string;
}


/**
 * Used to render a grid of available print catalogs
 */
export interface FMCatalogData {
    // Optional label
    label?:string;
    // URL to open when clicking on a catalog image
    url:string;
    // Image to show as a preview of the catalog
    image:string;
    // Start date the catalog is running for. This can also be simple text
    startDate:Date | string;
    // End date the catalog is running for. This can also be simple text
    endDate:Date | string;
}

// Still used in the pepclub seed data
export interface FMImageData {
    path?:string | boolean;
    pathXL?:string;
    pathLG?:string;
    pathMD?:string;
    pathSM?:string;
    link?:string;
    content?:string;
    allowWebpConversion?:boolean;
}

// Used to keep a cached reference to the custom page seo data in firebase, used to generate links & menu
export interface FMCustomPageSEOData {
    id:number;
    urlKey:string;
    pageTitle?:string;
    seoTitle?:string;
    seoDescription?:string;
}


export interface FMBuildInfo {
    addNoIndex:boolean;
    implPath:string;
    lazyModules?:string[];
    assets?:FMAssetsDefinition[];
    proxies?:IProxyConfig[];
}

export interface FMAssetsDefinition {
    input:string;
    glob:string;
    output:string;
}

export interface IProxyConfig {
    path:string;
    proxyUrl:string;
    secure:boolean;
    changeOrigin:boolean;
    logLevel:string;
}

/*
New Menu Models
--------------------------------------------
*/

// Reusable
// --------------

export enum FMMenuItemType {
    customButton,
    categoryButton,
    blogCategoryButton,
    categoryList,
    autoLayout,
    divider,
    image
}

export enum FMMenuButtonStyle {
    title,
    subTitle,
    buttonPrimaryLarge,
    buttonPrimaryStd,
    buttonSecondaryLarge,
    buttonSecondaryStd,
    buttonSuccessLarge,
    buttonSuccessStd,
    buttonOutlineLarge,
    buttonOutlineStd,
}

export interface FMMenuItemDivider extends BaseItem {
    type:FMMenuItemType.divider;
    // Used for injectChildren to position this element in a list
    // negative values will position from the bottom
    index?:number;
}

export interface FMMenuItemImage extends BaseItem {
    type:FMMenuItemType.image;
    src:string;
    //width:number;
    // Height of the image when displayed, used to determine auto layouts.
    // Keep in mind a smaller column width will reduce what this should be
    imageHeight:number;
    link?:string;
}

export interface BaseItem {
    spaceBefore?:number;
    spaceAfter?:number;
    spaceLeft?:number;
    spaceRight?:number;
    cssClasses?:string;
    // Don't set, used internally when calculating dynamic layouts
    height?:number;
    // Only on mobile, defaults to true
    showDivider?:boolean;
    // Will only show when a user is authenticated
    authenticated?:boolean;
}

export interface BaseInjectItem<T> {
    item:T;
    // Injected above the category ID
    aboveCategoryId?:number;
    belowCategoryId?:number;
    index?:number;
}

export interface CategoryMapping<T> {
    urlKeyPath:string;
    data:T;
}


/*
DESKTOP
--------------------------------------------
*/

export type FMDesktopMenuItemGroup =
    FMDesktopMenuItemCustomBut
    | FMDesktopMenuItemCatBut
    | FMDesktopMenuItemCatList
    | FMMenuItemDivider
    | FMMenuItemImage;

export type FMDesktopMenuInjectItem = BaseInjectItem<FMDesktopMenuItemCustomBut | FMDesktopMenuItemCatBut | FMMenuItemDivider | FMMenuItemImage>;

export type FMDesktopTopMenuItemGroup = FMDesktopTopMenuItemCatBut | FMDesktopTopMenuItemCustomBut;

export interface FMDesktopMenuList {
    items:FMDesktopTopMenuItemGroup[];
    sizes:FMDesktopMenuElementSizes;
}

// These sizes are used to programmatically determine config elements
// height to auto distribute between columns and should be set to pixel values
export interface FMDesktopMenuElementSizes {
    title:number;
    subTitle:number;
    buttonLarge:number;
    buttonStd:number;
    divider:number;
    // Gap added between primary categories
    autoLayoutGap:number;
}


export interface FMDesktopTopMenuItemCustomBut extends FMDesktopMenuItemCustomBut {
    dropDown?:FMDesktopMenuDropDown;
}

export interface FMDesktopTopMenuItemCatBut extends FMDesktopMenuItemCatBut {
    dropDown?:FMDesktopMenuDropDown;
}


export interface FMDesktopMenuItemCustomBut extends BaseItem {
    type:FMMenuItemType.customButton;
    // Locale key for the button's name
    locale?:string;
    // Plain text for the button's name
    label?:string;
    link?:string;
    // Defaults to ItemButtonStyle.title
    style?:FMMenuButtonStyle;
    // Used for injectChildren to position this element in a list
    // negative values will position from the bottom
    index?:number;
    icon?:string;
    image?:string;
    isCDNLink?:boolean;
}


export interface FMDesktopMenuItemCatBut extends BaseItem {
    type:FMMenuItemType.categoryButton;
    urlKeyPath:string;
    // Defaults to ItemButtonStyle.title
    style?:FMMenuButtonStyle;
    // Used for injectChildren to position this element in a list
    // negative values will position from the bottom
    index?:number;
    icon?:string;
    image?:string;
}


export interface FMDesktopMenuItemCatList extends BaseItem {
    type:FMMenuItemType.categoryList;
    parentUrlKeyPath:string;
    // Defaults to ItemButtonStyle.title
    style?:FMMenuButtonStyle;
    injectItems?:FMDesktopMenuInjectItem[];
    excludeCategoryIds?:number[];
    // Used for injectChildren to position this element in a list
    // negative values will position from the bottom
    index?:number;
    icons?:CategoryMapping<string>[];
}

export interface FMDesktopMenuItemAutoLayout extends BaseItem {
    type:FMMenuItemType.autoLayout;
    parentUrlKeyPath:string;
    injectItems?:FMDesktopMenuInjectItem[];
    excludeCategoryIds?:number[];
    icons?:CategoryMapping<string>[];
}


export interface FMDesktopMenuDropDown {
    columns:FMDesktopMenuDropDownColumn[];
    // Level 1 override will apply to all columns
    defaultColumnWidth?:number;
    // If true the drop down will start inline with the button
    startUnderButton?:boolean;
}

export interface FMDesktopMenuDropDownColumn {
    //type:FMMMenuColumnType.column;
    cssClasses?:string;
    // Level 2 override will apply to only this column
    defaultWidth?:number;
    items:Array<FMDesktopMenuItemGroup | FMDesktopMenuItemAutoLayout>;
}

/*
MOBILE
--------------------------------------------
*/
export type FMMobileMenuItemGroup =
    FMMobileMenuItemCustomBut
    | FMMobileMenuItemCatBut
    | FMMobileMenuItemCatList
    | FMMobileMenuItemBlogCatBut
    | FMMenuItemDivider
    | FMMenuItemImage;

export type FMMobileMenuInjectItem = BaseInjectItem<FMMobileMenuItemCustomBut | FMMobileMenuItemCatBut | FMMenuItemDivider | FMMenuItemImage>;


export interface FMMobileMenuList {
    items:FMMobileMenuItemGroup[];
    subMenuOverrides?:CategoryMapping<FMMobileMenuList>[];
}

export interface FMMobileMenuItemCustomBut extends BaseItem {
    type:FMMenuItemType.customButton;
    // Locale key for the button's name
    locale?:string;
    // Plain text for the button's name
    label?:string;
    link?:string;
    // Defaults to ItemButtonStyle.title
    style?:FMMenuButtonStyle;
    // Used for injectChildren to position this element in a list
    // negative values will position from the bottom
    index?:number;
    subList?:FMMobileMenuList;
    icon?:string;
    image?:string;
    isCDNLink?:boolean;
}


export interface FMMobileMenuItemCatBut extends BaseItem {
    type:FMMenuItemType.categoryButton;
    urlKeyPath:string;
    // Defaults to ItemButtonStyle.title
    style?:FMMenuButtonStyle;
    // Used for injectChildren to position this element in a list
    // negative values will position from the bottom
    index?:number;
    subList?:FMMobileMenuList;

    // defaults to true, if true, sub menus for all children will be auto generated
    autoSubMenuGenerate?:boolean;
    icon?:string;
    image?:string;
}


export interface FMMobileMenuItemCatList extends BaseItem {
    type:FMMenuItemType.categoryList;
    parentUrlKeyPath:string;
    // Defaults to ItemButtonStyle.title
    style?:FMMenuButtonStyle;
    excludeCategoryIds?:number[];
    // Used for injectChildren to position this element in a list
    // negative values will position from the bottom
    index?:number;
    injectItems?:FMMobileMenuInjectItem[];

    // defaults to true, if true, a button at the top of the list will show to view all in category
    addViewAll?:boolean;
    viewAllIcon?:string;

    // defaults to true, if true, sub menus for all children will be auto generated
    autoSubMenuGenerate?:boolean;

    icons?:CategoryMapping<string>[];
}


export interface FMMobileMenuItemBlogCatBut extends BaseItem {
    type:FMMenuItemType.blogCategoryButton;
    // Locale key for the button's name
    locale?:string;
    // Defaults to ItemButtonStyle.title
    style?:FMMenuButtonStyle;
    // Used for injectChildren to position this element in a list
    // negative values will position from the bottom
    index?:number;

    // defaults to true, if true, sub menus for all children will be auto generated
    icon?:string;
    image?:string;
}


/*
DEPRECATED, only used in footer now
--------------------------------------------
*/

export type FMMenuElements = Array<FMMenuCategoryButton | FMMenuCustomButton | FMMenuBlogButton>;
export type FMMenuElementsGroup = Array<Array<FMMenuCategoryButton | FMMenuCustomButton | FMMenuBlogButton>>;


/**
 * This menu type displays only a magento category
 */
export interface FMMenuCategoryButton {
    type:FMMenuElementTypeEnum.CATEGORY_BUTTON;

    urlKeyPath:string;

    // Used to determine if the child categories are shown in a drop down or not
    enableChildren?:boolean;

    // Defaults to true, This only applies if the button has children, if set to false then the button will not be selectable
    selectable?:boolean;

    adHocButtons?:FMMenuCustomButton[];

    // Will only show when a user is authenticated, false will only show if not authenticated (default / null will always display)
    authenticated?:boolean;
}

/**
 * Used to display a menu button for everything other than a category
 */
export interface FMMenuCustomButton {
    type:FMMenuElementTypeEnum.CUSTOM_BUTTON;

    localeKey?:string;
    label?:string;

    children?:FMMenuElements;

    // Defaults to true, This only applies if the button has children, if set to false then the button will not be selectable
    selectable?:boolean;


    // This will either be something from PageTypeEnum or FMFooterPageTypeEnum or Empty
    builtInPageType?:string;

    // e.g 123/home?etc=true
    pathAfterType?:string;

    // Used for adHocButtons in category links
    // This is the offset from the top or bottom of the list of category pages
    // Negative value for offset from the bottom
    offset?:number;

    // Used to deeplink to the category page #deep-link
    anchor?:string;

    // Will only show when a user is authenticated, false will only show if not authenticated (default / null will always display)
    authenticated?:boolean;
}

export interface FMMenuBlogButton {
    type:FMMenuElementTypeEnum.BLOG_BUTTON;
    localeKey:string;
    maxCategories?:number;
    // Will only show when a user is authenticated, false will only show if not authenticated (default / null will always display)
    authenticated?:boolean;
}

export const FMFooterPageTypeEnum = {
    CDN_LINK : 'cdn-link',
    FULL_LINK: 'full-link'
};


export enum FMMenuElementTypeEnum {
    CATEGORY_BUTTON = 'CATEGORY_BUTTON',
    CUSTOM_BUTTON   = 'CUSTOM_BUTTON',
    BLOG_BUTTON     = 'BLOG_BUTTON',
}
