import {Component, EventEmitter, Injector, Input, Output, ViewChild} from '@angular/core';
import {BuildCustomFormElementProvider} from '../../../cross-platform/forms/abstract-form.component';
import {AbstractProxyFormComponent} from '../../../cross-platform/forms/abstract-proxy-form.component';
import {CheckboxControlValueAccessor, ControlValueAccessor} from '@angular/forms';

@Component({
    selector   : 'd-form-checkbox',
    templateUrl: './d-form-checkbox.component.html',
    providers  : [BuildCustomFormElementProvider(DFormCheckboxComponent)]
})
export class DFormCheckboxComponent extends AbstractProxyFormComponent {

    @Input()
    public set checked(checked) {
        this._checked = checked;
        this.control.patchValue(checked);
    }

    public get checked() {
        return this._checked;
    }

    @Input()
    public set disabled(value:boolean) {
        this.control.disable();
        this._disabled = value;
        value ? this.control.disable() : this.control.enable();
    }

    public get disabled() {
        return !!this._disabled;
    }

    @Output() public onChange = new EventEmitter<any>();

    @ViewChild(CheckboxControlValueAccessor, {static: true}) proxyFormElement:ControlValueAccessor | null;

    private _checked  = false;
    private _disabled = false;

    constructor(injector:Injector) {
        super(injector);
    }

    public changeOption(event:Event) {
        this.onChange.emit(event);
    }
}
