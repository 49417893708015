// page
// These are the implementation project custom page section url segments
export class CustomPageEnum {
    static onlineStoreCredit = 'online-store-credit';
    static more              = 'find-out-more';
    static ackAccount        = 'ack-account';
    static moreServices      = 'more-services';
    static laybys            = 'laybys';
    static braGuide          = 'bra-guide';
    static characterShop     = 'the-character-shop';
}
