import {environment} from '../../implementation/environment';
import {RegexUtils} from '../../../../scripts/shared/regex-utils';
import {CategoryHash} from '../../external-data/magento/magento.reducer.types';

export class HTMLUtil {
    static parseCmsHtml(html: string, categoryIDHash:CategoryHash, exludedInlineStyleAttributes:any[]):string {
        const rootPages:string[]  = environment.config.api.wordpressRootPages;
        const wordpressURL:string = environment.config.api.wordpressURL;
        const cdnBaseUrl:string   = environment.config.api.cdnBaseUrl;
        const pageCategoryMatch   = RegexUtils.escape(wordpressURL + '/') + '[^\\s\"\'\?\&\.\(\)\^]+';


        html = html.replace(new RegExp(pageCategoryMatch, 'g'), (match) => {
            // replace: category-<id> with names
            match = match.replace(/category-([0-9]+)/g, (slug, catid) => {
                return categoryIDHash && categoryIDHash[catid] ? categoryIDHash[catid].url_key : slug;
            });
            // replace: root page slug
            rootPages.forEach(slug => {
                match = match.replace(new RegExp('\/' + slug + '\/', 'g'), '/');
            });
            // replace: base URL
            match = match.replace(new RegExp(wordpressURL, 'g'), '');
            return match;
        });

        // Implement a check for any references to cdn.ackermans.co.za & cdn.pepstores.com
        // TODO: This can be removed once we have migrated all of the wordpress content to the new URLs
        html = html.replace(RegexUtils.escapeToReg('https://cdn.ackermans.co.za', 'g'), cdnBaseUrl);
        html = html.replace(RegexUtils.escapeToReg('https://cdn.pepstores.com', 'g'), cdnBaseUrl);

        exludedInlineStyleAttributes.forEach((exclude) => {
            const regex = new RegExp('(' + exclude + '):\s*([^;]*)[;]', 'g');
            html        = html.replace(regex, '');
        });

        return html;
    }
}
