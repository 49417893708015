export class PagedSet<T = any> {
    totalPerPage?:number;
    items?:{ [pageNumber:number]:T[] } = {};
    totalItems?:number;

    setPageData(pageNumber:number, items:T[]) {
        if (!this.items) this.items = {};
        this.items[pageNumber] = items;
    }
}

export class Paginator<T> {
    constructor(public set?:PagedSet<T>) {
    }

    public getTotalPages() {
        if (this.set) {
            return Math.ceil(this.set.totalItems / this.set.totalPerPage);
        }
        return 0;
    }

    public getTotalItems() {
        if (this.set) {
            return this.set.totalItems;
        }
        return 0;
    }

    public hasMorePages(currentPage:number) {
        return currentPage < this.getTotalPages();
    }

    public hasPageData(currentPage:number) {
        return (this.set && this.set.items[currentPage] != null);
    }

    public hasPageDataUpto(currentPage:number) {
        let hasData = (this.set != null && this.set.items != null);
        if (hasData) {
            for (let i = currentPage; i > 0; i--) {
                if (this.set.items[i] == null) {
                    hasData = false;
                    break;
                }
            }
        }
        return hasData;
    }

    public getItemsForPage(currentPage:number) {
        if (this.set && this.set.items && this.set.items[currentPage]) {
            return this.set.items[currentPage];
        }
        return null;
    }

    public getAllData():T[] {
        let items = [];
        if (this.set) {
            for (const index in this.set.items) {
                if (this.set.items.hasOwnProperty(index)) {
                    items = items.concat(this.set.items[index]);
                }
            }
        }
        return items;
    }

    public getDataUpToPage(currentPage:number):T[] {
        let items = [];
        if (this.set) {
            for (const index in this.set.items) {
                if (this.set.items.hasOwnProperty(index) && Number(index) <= currentPage) {
                    items = items.concat(this.set.items[index]);
                }
            }
        }
        return items;
    }

    /**
     * Used to build a string something like: "showing 1 to 5 of 30 items"
     */
    public getTextVars(currentPage:number):{ start:number, end:number, total:number } {
        if (this.set) {
            return {
                start: this.set.totalPerPage * (currentPage - 1) + 1,
                end  : Math.min(this.set.totalPerPage * currentPage, this.set.totalItems),
                total: this.set.totalItems
            };
        }
        else {
            return {start: 0, end: 0, total: 0};
        }

    }
}
