import {Injectable} from '@angular/core';

@Injectable()
export class CustomPageRenderService {

    private renderers:{ [name:string]:any }             = {};
    private urlTemplateStore:{ [urlKey:string]:string } = {};
    private noContentComponentClass:any;

    constructor() {
    }

    public registerNoContentComponent(ComponentClass:any) {
        this.noContentComponentClass = ComponentClass;
    }

    public registerComponentRoute(urlKey:string, ComponentClass:any) {
        this.urlTemplateStore[urlKey]  = ComponentClass;
        this.renderers[ComponentClass] = ComponentClass;
    }

    public retrieveComponentForURL(urlKey:string) {
        //console.log('Resolving component for', urlKey, this.urlTemplateStore[urlKey]);
        return this.urlTemplateStore[urlKey] || this.noContentComponentClass;
    }
}
