import {gql} from 'apollo-angular';

export const cartForCheckoutGQL = (loggedIn:boolean) => `
    id
    email
    ${(!loggedIn) ? '' : `
        applied_store_credit {
            applied_balance {
                value
            }
            current_balance {
                value
            }
        }
    `}
    prices {
        grand_total {
            value
        }
        subtotal_excluding_tax {
            value
        }
        subtotal_including_tax {
            value
        }
        applied_taxes {
            label
            amount {
                value
            }
        }
        discounts {
            amount {
                value
            }
            label
        }
    }
    billing_address {
        firstname
        lastname
        telephone
        company
        street
        city
        postcode
        region {
            code
            label
            region_id
        }
        country {
            code
            label
        }
    }
    shipping_addresses {
        firstname
        lastname
        telephone
        company
        street
        city
        postcode
        region {
            code
            label
            region_id
        }
        country {
            code
            label
        }
        
        customer_notes
        selected_shipping_method {
            carrier_code
            method_code
            amount {
                value
                currency
            }
        }
    }
    available_payment_methods {
        code
        title
    }
    items {
        id
        prices {
            row_total_including_tax {
                value
            }
            price {
                value
            }
            row_total {
                value
            }
            total_item_discount {
                value
            }
        }
        quantity
        product {
            sku
            price_range {
                minimum_price {
                    final_price {
                        value
                    }
                }
            }
            ... on SimpleProduct {
                parent_product {
                    sku
                    name
                    categories {
                        id
                    }
                }
            }
        }
        itemTracking {
            item_category
            item_list_id
            item_list_name
        }
    }
    total_quantity
`;

export const FETCH_CART_FOR_CHECKOUT_CACHE = false;

export const FETCH_CART_FOR_CHECKOUT = (loggedIn:boolean) => gql`
    query cart_forCheckout($cartId:String!) {
        cart(cart_id: $cartId) {
            ${cartForCheckoutGQL(loggedIn)}
        }
    }
`;
