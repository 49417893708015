import {environment} from '../implementation/environment';
import {NgxsModuleOptions} from '@ngxs/store';


export const ngxsConfig:NgxsModuleOptions = {
    developmentMode: !environment.config.api.production,
    selectorOptions: {
        suppressErrors      : false,
        injectContainerState: false
    },
    compatibility  : {
        strictContentSecurityPolicy: true
    }
};
