import {Injector} from '@angular/core';
import {ImplementationDataService} from '../../implementation-config/implementation-data.service';
import {WindowRef} from '../../shared/ui/window-ref';
import {first} from 'rxjs/operators';
import {FacadePlatform} from '../../shared/facade-platform';
import {AbstractFacadeService} from '../../external-data/abstract-facade.service';
import {CustomerAuth} from '../customer-auth.state';
import {StorageService} from '../../shared/ui/storage/storage.service';
import {RootState} from '../../ngxs/root.state';
import Logout = CustomerAuth.Logout;


/**
 *
 *
 *
 * This is a problem area and is overly complex
 * It's a problem because we're managing state for 3 instances:
 * 1. Current browser tab
 * 2. Previous browser tab
 * 3. Still logged in new tab
 *
 * This needs to be rewritten to only manage timeouts for the current tab.
 * App state like logged in / out needs to be kept in sync via an alternative dedicated system
 *
 *
 *
 */


export abstract class AbstractAutoSignOutService extends AbstractFacadeService {

    public IDLE_CHECK_IN_SECONDS = 1;

    public IDLE_START_TIME = 'IDLE_START_TIME';

    private authAllowedIdleSeconds;
    private autoSignOutDialogSeconds;
    private timer;
    private mouseupListener;
    private keyupListener;

    private windowRef:WindowRef;
    private implementationDataService:ImplementationDataService;
    private facadePlatform:FacadePlatform;
    private storageService:StorageService;

    constructor(injector:Injector) {
        super(injector);
        this.windowRef                 = injector.get(WindowRef);
        this.implementationDataService = injector.get(ImplementationDataService);
        this.facadePlatform            = injector.get(FacadePlatform);
        this.storageService            = injector.get(StorageService);
    }

    getName():string {
        return 'AbstractAutoSignOutService';
    }

    init() {
        this.authAllowedIdleSeconds   = this.implementationDataService.getAuthAllowedIdleSeconds();
        this.autoSignOutDialogSeconds = this.implementationDataService.getAutoSignoutDialogSeconds();
        this.log('this.authAllowedIdleSeconds: ', this.authAllowedIdleSeconds);
        if (this.authAllowedIdleSeconds != null && this.authAllowedIdleSeconds > 0) {
            this.store.select((s:RootState) => s.customer.isLoggedIn)
                .pipe(first((value) => value === true)) // Filters out app startup with a null value here
                .subscribe(isLoggedIn => {
                    this.log('isLoggedIn: ', isLoggedIn);
                    if (isLoggedIn) {
                        // First check if already expired
                        const timeIsUp = this.checkTimeIsUp();
                        this.log('timeIsUp: ', timeIsUp);
                        switch (timeIsUp) {
                            case 'expired':
                            case 'show-dialog-time-left':
                                // Called async because handleSignOut causes the state to change synchronously
                                // causing this subscription not to fire again
                                setTimeout(() => this.handleSignOut(), 1);
                                break;
                            case 'removed-in-another-tab':
                            case 'not-expired':
                                this.startIdleWatch();
                                break;
                        }
                    }
                    else {
                        this.stopIdleWatch();
                    }

                }, err => {
                    console.error('Runtime error ', err);
                });
        }
    }

    startIdleWatch() {
        this.log('startIdleWatch');
        this.timer = setInterval(() => this.handleIdleCheck(), this.IDLE_CHECK_IN_SECONDS * 1000);
        this.resetIdleTime();

        // Reset idle time
        this.mouseupListener = () => this.resetIdleTime();
        this.keyupListener   = () => this.resetIdleTime();
        this.windowRef.addEventListener('mouseup', this.mouseupListener);
        this.windowRef.addEventListener('keyup', this.keyupListener);
    }

    stopIdleWatch() {
        this.log('stopIdleWatch');
        this.storageService.removeItem(this.IDLE_START_TIME);
        clearInterval(this.timer);

        // Clear idle time listeners
        this.windowRef.removeEventListener('mouseup', this.mouseupListener);
        this.windowRef.removeEventListener('keyup', this.keyupListener);
    }

    resetIdleTime() {
        this.log('resetIdleTime');
        if (!this.isDialogShowing) this.storageService.setItem(this.IDLE_START_TIME, `${new Date().getTime()}`);
    }

    handleSignOut() {
        this.log('handleSignOut');
        this.store.dispatch(new Logout());
        this.facadePlatform.handleLink({href: this.implementationDataService.getUrlForAutoSignout(), newTab: false, isBackLink: false});
    }

    handleIdleCheck() {
        this.log('handleIdleCheck');
        const timeIsUp = this.checkTimeIsUp();
        this.log('timeIsUp: ', timeIsUp);
        switch (timeIsUp) {
            case 'expired':
            case 'removed-in-another-tab':
                this.removeAutoSignOutDialog();
                this.handleSignOut();
                break;
            case 'show-dialog-time-left':
                if (this.mustShowDialog) this.showAutoSignOutDialog();
                break;
            case 'not-expired':
                // Do nothing
                break;
        }
    }

    abstract get isDialogShowing()

    abstract showAutoSignOutDialog()

    abstract removeAutoSignOutDialog()


    // Utilities
    private get mustShowDialog() {
        return (this.autoSignOutDialogSeconds != null && this.autoSignOutDialogSeconds > 0);
    }

    private checkTimeIsUp():'expired' | 'not-expired' | 'removed-in-another-tab' | 'show-dialog-time-left' {
        const idleStartTimeEntry = this.storageService.getItem(this.IDLE_START_TIME);
        if (idleStartTimeEntry != null) {
            const convertMilliToSeconds  = (timeInMilli) => timeInMilli / 1000;
            const timeSpentIdleInSeconds = convertMilliToSeconds(new Date().getTime() - Number(idleStartTimeEntry));
            const expired                = timeSpentIdleInSeconds > this.authAllowedIdleSeconds;
            const timeLeft               = this.authAllowedIdleSeconds - timeSpentIdleInSeconds;
            const showAutoSignoutDialog  = (this.mustShowDialog && timeLeft <= this.autoSignOutDialogSeconds);

            this.log('timeSpentIdleInSeconds: ', timeSpentIdleInSeconds);
            this.log('expired: ', expired);
            this.log('showAutoSignoutDialog: ', showAutoSignoutDialog);

            if (expired) return 'expired';
            if (showAutoSignoutDialog) return 'show-dialog-time-left';
            return 'not-expired';
        }
        else {
            return 'removed-in-another-tab';
        }
    }

    protected log(msg, param?) {
        //this.logger.debug(msg, (param != null) ? param : '');
    }
}
