import {ChangeDetectorRef, Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {ResponseCode} from '../external-data/enum/response-code.enum';
import {ExternalResponse} from '../external-data/external-response';
import {GTMEvents} from '../external-data/google/google-tag-manager/gtm.events';
import {CustomerCommunicationService} from '../external-data/newsletter/customer-communication.service';
import {NewsletterResponse, NewsletterResponseCodeEnum} from '../external-data/newsletter/newsletter.types';
import {FacadeValidators} from '../forms/facade-validators';
import {StaticConfig} from '../shared/static-config';
import {AbstractFacadeComponent} from '../shared/ui/abstract-facade.component';
import {ViewState} from '../shared/view-state/view-state';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ImplementationLinks} from '../implementation-config/implementation-data.types';
import {View} from '../shared/view-state/view.state';

interface ViewProps {
    submissionSent?:boolean;
    submitted?:boolean;
    duplicateEmail?:boolean;
}

@Component({template: ''})
export abstract class AbstractNewsletterSignUpModalComponent extends AbstractFacadeComponent {
    @ViewChild('popiaMessage', {static: false}) popiaMessage:ElementRef;

    // externalSites = PepExternalSites;
    // cmsPageLinks  = PepCMSPageLinks;
    policyLinks:ImplementationLinks = this.implementationDataService.getImplementationLinks();
    state$:Observable<ViewState>;
    viewProps$:Observable<ViewProps>;
    newsletterForm:FormGroup;
    emailControl:FormControl;
    formBuilder:FormBuilder;

    // Dependencies
    facadeValidators:FacadeValidators;
    newsletterApiService:CustomerCommunicationService;
    changeDetectorRef:ChangeDetectorRef;

    showTermsDetail:boolean = false;
    popiaHTML:any;
    translate:TranslateService;
    sanitizer:DomSanitizer;

    protected constructor(injector:Injector) {
        super(injector);
        this.formBuilder          = injector.get(FormBuilder);
        this.facadeValidators     = injector.get(FacadeValidators);
        this.newsletterApiService = injector.get(CustomerCommunicationService);
        this.changeDetectorRef    = injector.get(ChangeDetectorRef);
        this.translate            = injector.get(TranslateService);
        this.sanitizer            = injector.get(DomSanitizer);

        this.newsletterForm = this.formBuilder.group({
            emailControl: ['', null, [this.facadeValidators.required(), this.facadeValidators.maxLength(StaticConfig.INPUT_MAX), this.facadeValidators.email()]],
            termsCheck  : [false, null, [this.facadeValidators.required('contactUs.form.acceptTermsError')]]
        });
        this.state$         = this.viewStateSelector.getState$(this.viewName);
        this.viewProps$     = this.viewStateSelector.getProps$(this.viewName);
    }

    populate(email?:string) {
        if (email) {
            this.newsletterForm.controls.emailControl.setValue(email);
        }
        const translatePopiaMessage = this.translate.instant('newsletter.popiaTermsFullMessage', {retailSegment: this.policyLinks?.pepkorRetailSegments, privacyPolicy: this.policyLinks?.pepkorPrivacyStatement, termsPage: this.policyLinks?.termsGeneral});
        this.popiaHTML              = this.sanitizer.bypassSecurityTrustHtml(translatePopiaMessage);
    }

    created() {
        super.created();

        this.addSub = this.state$
            .subscribe(state => {
                if (state.isProcessing) {
                    this.newsletterForm.disable();
                }
                else if (state.isError) {
                    this.newsletterForm.enable();
                }
                else if (state.isReady) {
                    this.addSub = this.viewProps$.subscribe((props:ViewProps) => {
                        this.newsletterForm.enable();
                        if (props && props.submissionSent) {
                            this.store.dispatch(new View.SetProps<ViewProps>(this.viewName, {submitted: true}));
                        }
                    });
                }
            });
    }

    init() {
        super.init();
        // Init the view's props

        // Execute this after the validation cycle has completed
        // https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
        Promise.resolve(null).then(() => this.store.dispatch(new View.SetProps<ViewProps>(this.viewName, {submitted: false, duplicateEmail: false})));
    }

    handleSubmit() {
        this.logger.info('Submit called, form:', this.newsletterForm);
        // Mark all as touched to trigger validation on untouched fields
        Object.keys(this.newsletterForm.controls).forEach(controlKey => this.newsletterForm.controls[controlKey].markAsTouched());

        if (this.newsletterForm.valid) {
            this.store.dispatch(new View.SetProcessing(this.viewName));
            this.store.dispatch(new View.SetProps<ViewProps>(this.viewName, {submissionSent: true}));
            this.addSub = this.newsletterApiService.newsletterSubscribe(this.newsletterForm.value.emailControl)
                .subscribe(
                    response => {
                        this.store.dispatch(new View.SetReady(this.viewName));
                        this.trackGTMEvent(GTMEvents.NEWSLETTER_SIGNUP_SUBMIT);
                    },
                    (error:ExternalResponse<NewsletterResponse>) => {
                        if (error.responseCode === ResponseCode.GENERAL_ERROR && error.data && error.data.Response && error.data.Response.ResponseCode === NewsletterResponseCodeEnum.EMAIL_ALREADY_EXISTS) {
                            this.store.dispatch(new View.SetProps<ViewProps>(this.viewName, {duplicateEmail: true}));
                            this.store.dispatch(new View.SetReady(this.viewName));
                        }
                        else {
                            this.store.dispatch(new View.SetError(this.viewName, error));
                        }
                    }
                );
        }
    }

    togglePopiaDetail(evt:Event) {
        evt.preventDefault();
        evt.stopPropagation();
        this.showTermsDetail = !this.showTermsDetail;
    }

    get name():string {
        return 'NewsletterSignUpModalComponent';
    }

    get viewName() {
        return this.name;
    }

    get formIsDisabled() {
        return this.newsletterForm.status === 'DISABLED'; // Can't seem to import the constant
    }
}
