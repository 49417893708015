export interface MagentoFacadeAddress {
    complex:string | null;
    address:string | null;
    suburb:string | null;
}

export class MagentoFacadeAddressUtil {
    /*
    Due to legacy click and collect using the following structure:
    [complex, street address, suburb]
    Home delivery has had to follow suit to keep things consistent. Hence, the util below.

    NOTE: We expect at least 2 elements in the address
    */
    static convertMagentoStreetArray(streetArr:string[]):MagentoFacadeAddress {
        if (!(streetArr?.length > 0)) return {complex: null, suburb: null, address: null};

        return {
            complex: streetArr?.length > 2 ? streetArr[0] : null,
            address: streetArr?.length > 2 ? streetArr[1] : streetArr[0],
            suburb : streetArr?.length > 2 ? streetArr[2] : streetArr[1]
        };
    }
}