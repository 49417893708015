import {APP_INITIALIZER, ErrorHandler, Injector, NgModule, PLATFORM_ID} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CrossPlatformModule} from '../../cross-platform/cross-platform.module';
import {RuntimeErrorHandlerService} from '../../cross-platform/error-handling/runtime-error-handler.service';
import {CrossPlatformService} from '../../cross-platform/shared/cross-platform.service';
import {FacadePlatform} from '../../cross-platform/shared/facade-platform';
import {TranslateHttpLoader} from '../../cross-platform/shared/translate-http-loader';
import {AppComponent} from './app.component';
import {CustomPageSharedModule} from './custom-pages/custom-page-shared.module';
import {DesktopRoutes} from './desktop-routes';
import {AppFooterComponent} from './footer/app-footer.component';
import {DesktopFacadePlatform} from './shared/desktop-facade-platform';
import {GlobalSharedUIModule} from './shared/ui/global-shared-ui.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {UrlService} from '../../cross-platform/shared/url.service';
import {InjectComponentService} from '../../cross-platform/inject-component/inject-component.service';
import {StaticConfig} from '../../cross-platform/shared/static-config';
import {DesktopHeaderComponent} from './header/desktop-header.component';
import {MenuButtonComponent} from './header/menu-button/menu-buttton.component';
import {MenuSubMenuComponent} from './header/menu-sub-menu/menu-sub-menu.component';
import {MenuSub2xDropdownComponent} from './header/menu-sub-2x-dropdown/menu-sub-2x-dropdown.component';
import {SearchBarGenericComponent} from './header/search/search-bar-generic.component';
import {ProductSearchBarRendererComponent} from './header/search/renderers/product-search-bar-renderer.component';
import {SearchHiddenComponent} from './header/search/search-hidden/search-hidden.component';
import {environment} from '../../cross-platform/implementation/environment';
import {AutoSignOutService} from './auth/auto-sign-out/auto-sign-out.service';
import {
    NewsletterSignUpDialogService
} from '../../cross-platform/shared/ui/newsletter/newsletter-sign-up-dialog.service';
import {DesktopNewsletterSignUpDialogService} from './newsletter-sign-up/desktop-newsletter-sign-up-dialog.service';
import {NoContentPageModule} from './no-content-page/no-content-page.module';
import {FacadeFormsModule} from './forms/facade-forms.module';
import {ColumnMenuComponent} from './header/column-menu/column-menu.component';
import {DesktopMainMenuConfigParser} from './header/column-menu/desktop-main-menu-config-parser';
import {AnchorTagService} from '../../cross-platform/shared/anchor-tag.service';
import {NewsletterSignupModule} from './footer/newsletter-signup.module';
import {EcommerceGuard} from '../../cross-platform/checkout/ecommerce-guard';
import {AuthGuard} from '../../cross-platform/auth/auth-guard';
import {NGXS_PLUGINS, NgxsModule} from '@ngxs/store';
import {ngxsConfig} from '../../cross-platform/ngxs/ngxs.config';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {ngxsStoragePluginOptions} from '../../cross-platform/ngxs/ngxs-storage.config';
import {AppState} from '../../cross-platform/ngxs/app.state';
import {SharedCartState} from '../../cross-platform/cart-shared/shared-cart.state';
import {SearchState} from '../../cross-platform/menu/search/search.state';
import {CustomerAuthState} from '../../cross-platform/auth/customer-auth.state';
import {UserPreferencesState} from '../../cross-platform/user-preferences/user-preferences.state';
import {ViewState} from '../../cross-platform/shared/view-state/view.state';
import {HistoryState} from '../../cross-platform/shared/history-state/history.state';
import {MagentoState} from '../../cross-platform/external-data/magento/magento.state';
import {
    GoogleTagManagerState
} from '../../cross-platform/external-data/google/google-tag-manager/google-tag-manager.state';
import {runtimeErrorPlugin} from '../../cross-platform/ngxs/runtime-error-plugin';
import {StorageService} from '../../cross-platform/shared/ui/storage/storage.service';
import {isPlatformBrowser} from '@angular/common';
import {Logger} from '../../cross-platform/shared/logger';
import {GlobalSelectors} from '../../cross-platform/ngxs/global.selectors';
import {DeliveryState} from '../../cross-platform/checkout/delivery/delivery.state';


export function createTranslateLoader(urlService:UrlService, injector:Injector, httpClient:HttpClient, logger:Logger) {
    const isBrowser = isPlatformBrowser(injector.get(PLATFORM_ID));
    return new TranslateHttpLoader(isBrowser, httpClient, logger, false);
}

export const translateConfig = {
    loader: {
        provide   : TranslateLoader,
        useFactory: (createTranslateLoader),
        deps      : [UrlService, Injector, HttpClient, Logger]
    }
};

const injectableComponents = [
    AppFooterComponent,
    DesktopHeaderComponent
];

const injectableComponentsToRegister = [
    {className: 'AppFooterComponent', componentClass: AppFooterComponent},
    {className: 'DesktopHeaderComponent', componentClass: DesktopHeaderComponent}
];

const states = [AppState, SharedCartState, SearchState, CustomerAuthState, UserPreferencesState, ViewState, HistoryState, MagentoState, GoogleTagManagerState, DeliveryState];

@NgModule({
    imports     : [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        RouterModule.forRoot(DesktopRoutes, {initialNavigation: 'enabledBlocking'}),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(translateConfig),
        // Third party
        NgxsModule.forRoot(states, ngxsConfig),
        NgxsStoragePluginModule.forRoot(ngxsStoragePluginOptions),
        //NgxsReduxDevtoolsPluginModule.forRoot(),
        // Internal
        CrossPlatformModule.forRoot(),
        FacadeFormsModule,
        CustomPageSharedModule,
        GlobalSharedUIModule.forRoot(),
        NewsletterSignupModule,
        NoContentPageModule,
        // HACK There's a strange cyclic dependency issue if this isn't imported.
        // Have tired to set service worker in angular.json to false etc, doesn't seem to fix it.
        //ServiceWorkerModule.register('ngsw-worker.js', {enabled: false}),
        // Comment out if you want to remove mock functionality
        //MockModule,
        //MockFirebaseModule
    ],
    declarations: [
        AppComponent,
        // Components
        AppFooterComponent,
        // NewsletterSignUpComponent,
        MenuButtonComponent,
        MenuSubMenuComponent,
        MenuSub2xDropdownComponent,
        ColumnMenuComponent,
        SearchBarGenericComponent,
        ProductSearchBarRendererComponent,
        SearchHiddenComponent,
        //DebugPageComponent,
        ...injectableComponents
    ],
    exports     : [
        DesktopHeaderComponent,
        MenuButtonComponent,
        MenuSubMenuComponent,
        MenuSub2xDropdownComponent,
        SearchBarGenericComponent,
        ProductSearchBarRendererComponent,
        SearchHiddenComponent,
        ColumnMenuComponent,
    ],
    providers   : [
        RuntimeErrorHandlerService,
        {provide: ErrorHandler, useClass: RuntimeErrorHandlerService},
        {provide: FacadePlatform, useClass: DesktopFacadePlatform},
        AutoSignOutService,
        {provide: NewsletterSignUpDialogService, useClass: DesktopNewsletterSignUpDialogService},
        {provide: APP_INITIALIZER, useFactory: appModuleInit, multi: true, deps: [Injector]},
        {provide: NGXS_PLUGINS, useValue: runtimeErrorPlugin, multi: true},
        DesktopMainMenuConfigParser,
        EcommerceGuard,
        AuthGuard
    ]
})

export class AppModule {
    constructor(injectComponentService:InjectComponentService) {
        injectComponentService.register(injectableComponentsToRegister);
    }
}

export function appModuleInit(injector:Injector) {
    return async () => {
        // We fetch the dependencies at runtime otherwise there is some kind of cyclic dependency
        const crossPlatformService = injector.get(CrossPlatformService);

        // Get the enable logging key & default it to is production or not
        const enableLogging = injector.get(StorageService).getItemBool(StaticConfig.ENABLE_LOGGING_KEY, !environment.config.api.production);
        //const enableDevTools = StorageUtil.instance.getItemBool(StaticConfig.ENABLE_DEV_TOOLS_KEY, !environment.config.api.production);

        // Important that this happens first
        crossPlatformService.configureApp(enableLogging);


        injector.get(AnchorTagService).init();
        injector.get(AutoSignOutService).init();

        // Startup
        const isBrowser = isPlatformBrowser(injector.get(PLATFORM_ID));
        if (isBrowser) {
            await crossPlatformService.handleCategoryDataStartup();
        }
        else {
            // Fake app is ready, so that the app-shell renders
            injector.get(GlobalSelectors).appReady$.next(true);
        }

        return null;
    };
}
