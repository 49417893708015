import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CrossPlatformModule} from '../../../cross-platform/cross-platform.module';
import {FileInputDirective} from '../../../cross-platform/forms/file-input.directive';
import {DFormInputComponent} from './d-form-input.component';
import {DFormMessagesComponent} from './d-form-messages.component';
import {DFormSelectComponent} from './d-form-select.component';
import {DFormTextAreaComponent} from './d-form-text-area.component';
import {DFormUploadComponent} from './d-form-upload.component';
import {DFormPassInputComponent} from './d-form-pass-input.component';
import {DFormTelInputComponent} from './d-form-tel-input.component';
import {DFormCheckboxComponent} from './d-form-checkbox.component';
import {DFormRadioComponent} from './d-form-radio.component';
import {DFormUploadsComponent} from './d-form-uploads.component';
import {DFormDateInputComponent} from './d-form-date-input.component';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {DFormStepperComponent} from './d-form-stepper.component';

@NgModule({
    imports     : [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        CrossPlatformModule,
        NgbDatepickerModule,
        FormsModule
    ],
    declarations: [
        DFormInputComponent,
        DFormTelInputComponent,
        DFormPassInputComponent,
        DFormSelectComponent,
        DFormTextAreaComponent,
        DFormUploadComponent,
        DFormUploadsComponent,
        DFormMessagesComponent,
        DFormCheckboxComponent,
        DFormRadioComponent,
        DFormDateInputComponent,
        FileInputDirective,
        DFormStepperComponent
    ],
    exports     : [
        DFormInputComponent,
        DFormTelInputComponent,
        DFormPassInputComponent,
        DFormSelectComponent,
        DFormTextAreaComponent,
        DFormUploadComponent,
        DFormUploadsComponent,
        DFormMessagesComponent,
        DFormCheckboxComponent,
        DFormRadioComponent,
        DFormDateInputComponent,
        FileInputDirective,
        DFormStepperComponent
    ]
})
export class FacadeFormsModule {
}
