import {gql} from 'apollo-angular';

export const LINK_PLUS_MORE = gql`
    mutation linkPlusMore($input:LinkPlusMoreInput!) {
        linkPlusMore(input:$input) {
            plus_more_card_number
            plus_more_guid
            plus_more_opt_in
        }
    }
`;
