import {gql} from 'apollo-angular';

export const GET_PRODUCT_FOR_PRODUCT_PAGE_CACHE = true;

export const GET_PRODUCT_FOR_PRODUCT_PAGE = (hasPimSupport:boolean, customAttrCodes?:string[]) => {

    return gql`
        query products_pdp($urlKey: String) {
            products(filter: { url_key: { eq: $urlKey } }) {
                items {
                    __typename
                    id
                    sku
                    name
                    description {
                        html
                    }
                    price_range {
                        minimum_price {
                            ...AllPriceFields
                        }
                    }
                    url_key
                    meta_title
                    meta_description
                    stock_status
                    product_attribute
                    product_decal
                    categories {
                        id
                        name
                        url_path
                    }
                    related_products {
                        sku
                    }
                    ${
                    hasPimSupport ?
                    `media_gallery {
                        file_name
                        label
                        position
                        disabled
                    }
                    size_guide
                    washcare_guide` :
                    `image_name
                    image_name_label
                    product_image_names`
                    }

                    ${customAttrCodes ? customAttrCodes.join('\n') : ''}

                    ... on ConfigurableProduct {
                        variants {
                            product {
                                id
                                sku
                                price_range {
                                    minimum_price {
                                        ...AllPriceFields
                                    }
                                }
                                stock_status
                                ${hasPimSupport ? `sizesortseq` : ''}
                            }
                            attributes {
                                label
                                code
                                value_index
                                uid
                            }
                        }
                    }

                }
            }
        }

        fragment AllPriceFields on ProductPrice {
            discount {
                ...ProductDiscountFields
            }
            final_price {
                ...MoneyFields
            }
            regular_price {
                ...MoneyFields
            }
        }

        fragment ProductDiscountFields on ProductDiscount {
            amount_off
            percent_off
        }

        fragment MoneyFields on Money {
            value
        }
    `;
};
