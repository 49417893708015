import {Routes} from '@angular/router';
import {PageTypeEnum, ProductsDisplayPageEnum} from '../../cross-platform/router/page-type.enum';
import {NoContentPageComponent} from './no-content-page/no-content-page.component';
import {implRoutes} from '@impl/impl-imports';
import {EcommerceGuard} from '../../cross-platform/checkout/ecommerce-guard';
import {AuthGuard} from '../../cross-platform/auth/auth-guard';


export const DesktopRoutes:Routes = [
    {path: PageTypeEnum.HOME_SEGMENT, pathMatch: 'full', loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule)},
    //{path: PageTypeEnum.HOME_SEGMENT, loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule)},
    {path: 'index', redirectTo: PageTypeEnum.HOME_SEGMENT},
    {path: 'index.html', redirectTo: PageTypeEnum.HOME_SEGMENT},
    //{path: 'debug-page', component: DebugPageComponent},
    //{path: '', redirectTo: PageTypeEnum.HOME, pathMatch: 'full'},

    // Routes for static products display page
    {path: PageTypeEnum.PRODUCT_LIST + '/' + ProductsDisplayPageEnum.TERM, loadChildren: () => import('./products-display/products-display-page.module').then(m => m.ProductsDisplayPageModule)},
    {path: PageTypeEnum.PRODUCT_LIST + '/' + ProductsDisplayPageEnum.LIST, loadChildren: () => import('./products-display/products-display-page.module').then(m => m.ProductsDisplayPageModule)},

    {path: PageTypeEnum.PRODUCT_LIST, loadChildren: () => import('./product-list-page/product-list-page.module').then(m => m.ProductListPageModule)},
    {path: PageTypeEnum.SEARCH_RESULTS, loadChildren: () => import('./search-results/search-results-page.module').then(m => m.SearchResultsPageModule)},
    // {path: PageTypeEnum.PRODUCT_LIST, loadChildren: () => import('./products-display/products-display-page.module').then(m => m.ProductsDisplayPageModule)},
    {path: PageTypeEnum.PRODUCT, loadChildren: () => import('./product-page/product-page.module').then(m => m.ProductPageModule)},
    {path: PageTypeEnum.CART, loadChildren: () => import('./checkout/cart-page/cart-page.module').then(m => m.CartPageModule), canActivate: [EcommerceGuard]},
    {path: PageTypeEnum.CHECKOUT, loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule), canActivate: [EcommerceGuard]},

    {path: PageTypeEnum.STORE_FINDER, loadChildren: () => import('./store-finder-page/store-finder-page.module').then(m => m.StoreFinderPageModule)},
    {path: PageTypeEnum.STORE_DIRECTORY, loadChildren: () => import('./store-directory/store-directory.module').then(m => m.StoreDirectoryModule)},

    {path: PageTypeEnum.KITCHEN_SINK, loadChildren: () => import('./kitchen-sink-page/kitchen-sink-page.module').then(m => m.KitchenSinkPageModule)},
    {path: PageTypeEnum.EMAIL_CONFIRM, loadChildren: () => import('./email-confirm-page/email-confirm-page.module').then(m => m.EmailConfirmPageModule)},
    {path: PageTypeEnum.CATEGORY_DISPLAY, loadChildren: () => import('./category-display-page/category-display-page.module').then(m => m.CategoryDisplayPageModule)},
    {path: PageTypeEnum.AUTH, loadChildren: () => import(`./auth/auth.module`).then(m => m.AuthModule), canActivate: [AuthGuard]},

    {path: PageTypeEnum.CMS_PREVIEW, loadChildren: () => import('./cms-preview-page/cms-preview-page.module').then(m => m.CmsPreviewPageModule)},
    {path: PageTypeEnum.CMS_LOGIN, loadChildren: () => import('./cms-login-page/cms-login-page.module').then(m => m.CmsLoginPageModule)},

    ...implRoutes,

    {path: PageTypeEnum.CUSTOM, loadChildren: () => import('./wp-content/wp-content-page.module').then(m => m.WpContentPageModule)},
    {path: PageTypeEnum.BLOG, loadChildren: () => import('./blog/blog-page.module').then(m => m.BlogPageModule)},

    {path: PageTypeEnum.CATALOGUE, loadChildren: () => import('./catalogue-page/catalogue-page.module').then(m => m.CataloguePageModule)},

    {path: '**', component: NoContentPageComponent}
];
