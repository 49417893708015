import {gql} from 'apollo-angular';

export const REGISTER_RESEND_OTP = gql`
    mutation registerResendOtp($cellphoneNumber:String = "default") {
        registerResendOtp(
            cellphone_number: $cellphoneNumber
        ) {
            token_response
        }
    }
`;
