/**
 * This is a basic static class for in app config we may want to tweak while testing.
 * It keeps everything in the same place.
 * These config values have been deemed not a requirement for the server config.
 */

export class StaticConfig {
    static ENABLE_HOME_DELIVERY              = 'Facade:EnableHomeDelivery';
    static ENABLE_PLUS_MORE                  = 'Facade:EnablePlusMore';
    static ENABLE_EINSTEIN_LAYBY             = 'Facade:EnableEinsteinLayby';
    static ENABLE_EINSTEIN_ACCOUNT           = 'Facade:EnableEinsteinAccount';
    static ENABLE_CRAZYEGG                   = 'Facade:EnableCrazyEgg';
    static ENABLE_LOGGING_KEY                = 'Facade:EnableLogging';
    static ENABLE_DEV_TOOLS_KEY              = 'Facade:EnableDevTools';
    static ENABLE_SERVICE_WORKER             = 'Facade:EnableServiceWorker';
    static GOTBOT_CACHE_KEY                  = 'Facade:GotbotQualification';
    static PRODUCTS_TOTAL_TO_LOAD            = 20;
    static PRODUCTS_TOTAL_TO_LOAD_NO_FILTERS = 24;
    static PRODUCT_DISPLAY_CURRENCY          = 'R';
    static PRODUCT_CURRENCY_CODE             = 'ZAR';

    static STORE_FINDER_INITIAL_RADIUS    = 20 * 1000; // 20km initially
    static STORE_FINDER_RADIUS_MULTIPLIER = 2;
    // Location comes at the end
    static GOOGLE_DIRECTIONS_BASE_URL     = 'https://www.google.com/maps/dir/Current+Location/';
    static SCRIPT_TAG_TIMEOUT             = 30 * 1000;
    static GOOGLE_PREDICTION_TIMEOUT      = 15 * 1000;
    static HTTP_TIMEOUT                   = 30 * 1000;
    static MOCK_DELAY                     = 1000;
    static IFRAME_TIMEOUT                 = 30 * 1000;
    // https://browser.sentry-cdn.com/6.12.0/bundle.min.js
    static SENTRY_SCRIPT_NAME             = 'sentry-6.12.0.js';
    //static PHOTO_SWIPE_SCRIPT_NAME        = 'photoswipe.4.1.3.min.js';
    //static PHOTO_SWIPE_SKIN_SCRIPT_NAME   = 'photoswipe-ui-default.4.1.3.min.js';

    static PLACEHOLDER_EMAIL          = 'thisnameisnevertobeusedbyfacade@nevertobeusedbyfacade.com';
    static PLACEHOLDER_NAME           = 'thisnameisnevertobeusedbyfacade';
    static PLACEHOLDER_NAME_CHECKOUT  = '---';
    static PLACEHOLDER_PHONE_CHECKOUT = '0000000000';

    static IMAGE_SCROLLER_DELAY = 3000; // matches the swiper autoplay default

    static INPUT_MAX = 225;
    static AREA_MAX  = 980;


    // Override for config caching, set to false to test
    static CACHE_CONFIG = true;
}
