import {Component, Injector, Input} from '@angular/core';
import {BuildCustomFormElementProvider} from '../../../cross-platform/forms/abstract-form.component';
import {DFormInputComponent} from './d-form-input.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector   : 'd-form-pass-input',
    templateUrl: './d-form-pass-input.component.html',
    providers  : [BuildCustomFormElementProvider(DFormPassInputComponent)]
})
export class DFormPassInputComponent extends DFormInputComponent {

    @Input()
    showPassLabel:string;

    @Input()
    hidePassLabel:string;

    showPass = false;

    type = 'password';

    constructor(injector:Injector, public translateService:TranslateService) {
        super(injector);
    }

    toggleShowPass() {
        this.showPass = !this.showPass;
        return false;
    }

    getLabel() {
        if (this.showPass) {
            return this.hidePassLabel ? this.hidePassLabel : this.translateService.instant('forms.hidePassword');
        }
        else {
            return this.showPassLabel ? this.showPassLabel : this.translateService.instant('forms.showPassword');
        }
    }
}
