import {DesktopImplementationAppModule} from './app/desktop-implementation-app.module';
import {enableProdMode} from '@angular/core';
import {WebPManager} from '../../../../framework/src/cross-platform/webp/webp-manager';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from '../../../../framework/src/cross-platform/implementation/environment';

console.info('environment.production: ', environment.config.api.production);
if (environment.config.api.production) {
    enableProdMode();
}

const bootstrap = () => {
    WebPManager.testForSupport()
        .then(() => {
            platformBrowserDynamic().bootstrapModule(DesktopImplementationAppModule)
                .catch(err => {
                    console.log('err: ', err);
                    // Added for browsers that don't support proper error logging out
                    console.log('err.message: ', err.message);
                });
        });
};

if (document.readyState === 'complete') {
    bootstrap();
}
else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}
