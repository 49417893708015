import {Injectable, Injector} from '@angular/core';
import {GenericCustomer} from '../../auth/models/generic-customer';
import {ExternalResponse} from '../external-response';
import {Observable} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {LOGIN_CUSTOMER} from './gql-mutations/login-customer';
import {REGISTER_CUSTOMER} from './gql-mutations/register-customer';
import {REGISTER_VALIDATE_OTP} from './gql-mutations/register-validate-otp';
import {REGISTER_RESEND_OTP} from './gql-mutations/register-resend-otp';
import {
    AccessLoginResponse,
    CustomerInputWithCellphone,
    LinkPlusMoreInput,
    LinkPlusMoreResponse,
    OtpResponse,
    UpdatePasswordResponse
} from '../../../../../generated/graphql';
import {FORGOT_PASSWORD_GENERATE} from './gql-mutations/forgot-password-generate';
import {FORGOT_PASSWORD_VALIDATE} from './gql-mutations/forgot-password-validate';
import {MergeCartResponse, SharedCartService} from '../../cart-shared/shared-cart.service';
import {GTMActions} from '../google/google-tag-manager/gtm.actions';
import {AbstractFacadeService} from '../abstract-facade.service';
import {ErrorCodes, ErrorFieldNames} from '../error-codes';
import {ErrorResponse} from '../magento/magento.types';
import {RecaptchaService} from '../recaptcha/recaptcha-service';
import {RecaptchaAction} from '../recaptcha/recaptcha-action';
import {AuthType} from '../../auth/components/auth.types';
import {LOGOUT_CUSTOMER} from './gql-mutations/logout';
import {of} from 'rxjs/internal/observable/of';
import {CustomerAuth} from '../../auth/customer-auth.state';
import {LINK_PLUS_MORE} from './gql-mutations/link-plus-more';
import {CustomerLogicService} from '../customer/customer-logic.service';


export interface AuthMergeWrapper<T> {
    merge?:ExternalResponse<MergeCartResponse>;
    mainResponse?:ExternalResponse<T>;
}

export interface LoginCustomerResponseData {
    loginCustomer:AccessLoginResponse;
}

interface RegisterCustomerResponse {
    createCustomerWithCellphone:{
        customer:{
            created_at
        }
    };
}

interface RegisterValidateOtpResponse {
    registerValidateOtp:AccessLoginResponse;
}

interface RegisterResendOtpResponse {
    registerResendOtp:OtpResponse;
}

interface ForgotPasswordGenerateResponse {
    forgotPasswordGenerate:OtpResponse;
}

interface ForgotPasswordValidateResponse {
    forgotPasswordValidate:UpdatePasswordResponse;
}

interface LinkPlusMoreResponseWrapper {
    linkPlusMore:LinkPlusMoreResponse;
}

interface UnlinkPlusMoreResponseWrapper {
    linkPlusMore:LinkPlusMoreResponse;
}


export enum RegisterStatus {
    PHONENUMBER_ALREADY_IN_USE = 'phoneNumberAlreadyInUse',
    PHONENUMBER_INVALID        = 'phoneNumberInvalid',
    SMS_FAILURE                = 'smsFailure',
    EMAIL_ALREADY_EXISTS       = 'emailAlreadyExists',
}

@Injectable()
export class AuthService extends AbstractFacadeService {

    constructor(public injector:Injector,
                private sharedCartService:SharedCartService,
                private recaptchaService:RecaptchaService,
                private customerService:CustomerLogicService) {
        super(injector);
    }

    register(customer:GenericCustomer, password:string, guestConversion:boolean = false):Observable<ExternalResponse<RegisterCustomerResponse>> {
        const variables:CustomerInputWithCellphone = {
            firstname             : customer.firstName,
            lastname              : customer.lastName,
            cellphone_number      : customer.cellPhoneNumber,
            password              : password,
            is_subscribed         : customer.newsletterSubscribe === true,
            email                 : customer.emailAddress,
            plus_more_opt_in      : customer.plusMoreOptIn,
            rsa_id                : customer.plusMoreOptIn ? customer.rsaIdNumber : null,
            passport_number       : customer.plusMoreOptIn ? customer.passportNumber : null,
            asylum_document_number: customer.plusMoreOptIn ? customer.asylumDocumentNumber : null,
            date_of_birth         : customer.plusMoreOptIn ? customer.dateOfBirth : null,
        };

        const action = guestConversion ? RecaptchaAction.REGISTER_GUEST_CONVERSION : RecaptchaAction.REGISTER;

        return this.recaptchaService.execute(action).pipe(
            switchMap(res => {
                return this.apollo.mutate<RegisterCustomerResponse>({
                    mutation : REGISTER_CUSTOMER,
                    variables: {input: variables},
                    context  : {headers: res.data}
                });
            })
        );
    }

    registerValidateOtp(oneTimePin:string, username:string, orderId:string = ''):Observable<AuthMergeWrapper<RegisterValidateOtpResponse>> {
        this.recaptchaService.execute(RecaptchaAction.AUTH_VALIDATE_OTP);
        return this.apollo.mutate<RegisterValidateOtpResponse>(
            {
                mutation : REGISTER_VALIDATE_OTP,
                variables: {
                    cellphoneNumber: username,
                    token          : oneTimePin,
                    //orderId        : orderId
                }
            },
            true
        ).pipe(
            tap(res => this.store.dispatch(new GTMActions.TrackSignUp({method: 'cell-phone', user_id: res.data?.registerValidateOtp?.user_id}))),
            switchMap(res => this.postLoginTasks<RegisterValidateOtpResponse>({mainResponse: res}, username)),
        );
    }

    login(username:string, password:string, authType:AuthType):Observable<AuthMergeWrapper<LoginCustomerResponseData>> {
        this.recaptchaService.execute(RecaptchaAction.AUTH_LOGIN, authType);
        return this.apollo.mutate<LoginCustomerResponseData>(
            {
                mutation : LOGIN_CUSTOMER,
                variables: {
                    cellphoneNumber: username,
                    password       : password
                }
            },
            true
        ).pipe(
            tap(res => this.store.dispatch(new GTMActions.TrackGALogin({method: 'cell-phone', user_id: res.data?.loginCustomer?.user_id}))),
            switchMap(res => this.postLoginTasks<LoginCustomerResponseData>({mainResponse: res}, username))
        );
    }

    logout() {
        this.sharedCartService.clearCart();
        return this.apollo.mutate({mutation: LOGOUT_CUSTOMER}, true).pipe(
            // Swallow errors. The customer doesn't care if logout on the server side fails.
            catchError((err, caught) => of(err))
        );
    }

    registerResendOtp(username:string) {
        return this.recaptchaService.execute(RecaptchaAction.REGISTER_REQUEST_ANOTHER_PIN).pipe(
            switchMap(res => {
                return this.apollo.mutate<RegisterResendOtpResponse>({
                    mutation : REGISTER_RESEND_OTP,
                    variables: {
                        cellphoneNumber: username
                    },
                    context  : {headers: res.data}
                });
            })
        );

    }

    forgotPasswordGenerate(username:string) {
        return this.recaptchaService.execute(RecaptchaAction.REQUEST_PASSWORD_RESET).pipe(
            switchMap(res => {
                return this.apollo.mutate<ForgotPasswordGenerateResponse>({
                    mutation : FORGOT_PASSWORD_GENERATE,
                    variables: {
                        cellphoneNumber: username,
                    },
                    context  : {headers: res.data}
                });
            })
        );

    }

    forgotPasswordValidate(username:string, otp:string, password:string):Observable<AuthMergeWrapper<ForgotPasswordValidateResponse>> {
        return this.apollo.mutate<ForgotPasswordValidateResponse>({
            mutation : FORGOT_PASSWORD_VALIDATE,
            variables: {
                oneTimePin     : otp,
                cellphoneNumber: username,
                password       : password
            }
        })
            .pipe(
                map(res => {
                    return {mainResponse: res};
                })
            );
    }

    postLoginTasks<T>(wrapper:AuthMergeWrapper<T>, phoneNumber:string):Observable<AuthMergeWrapper<T>> {
        this.store.dispatch(new CustomerAuth.UpdateCustomerUsername(phoneNumber));
        return this.sharedCartService.mergeCart().pipe(
            map(res => {
                if ((<MergeCartResponse>res.data)?.originalCartTotal != null) {
                    wrapper.merge = res as ExternalResponse<MergeCartResponse>;
                }
                return wrapper;
            })
        );
    }

    linkPlusMore(input:LinkPlusMoreInput) {
        return this.apollo.mutate<LinkPlusMoreResponseWrapper>({mutation: LINK_PLUS_MORE, variables: {input: input}}, true).pipe(tap(res => {
            this.customerService.invalidateCachedCustomer();
            this.store.dispatch(new CustomerAuth.UpdatePlusMore(true));
        }));
    }

    unlinkPlusMore() {
        return this.apollo.mutate<UnlinkPlusMoreResponseWrapper>({mutation: LINK_PLUS_MORE, variables: {input: {plus_more_opt_in: false}}}, true).pipe(tap(res => {
            this.customerService.invalidateCachedCustomer();
            this.store.dispatch(new CustomerAuth.UpdatePlusMore(false));
        }));
    }

    getAuthStateErrorName(error:ExternalResponse<ErrorResponse>):string {
        // Swagger says its number but in all likelyhood it wont always be
        let stateErrorName;
        switch (Number(error?.error?.code)) {
            case ErrorCodes.ERR_MAGENTO_EMAIL_DUPLICATE:
                stateErrorName = RegisterStatus.EMAIL_ALREADY_EXISTS;
                break;
            case ErrorCodes.ERR_MAGENTO_DUPLICATE_CUSTOMER:
            case ErrorCodes.ERR_DUPLICATE_CREDENTIAL:
                stateErrorName = RegisterStatus.PHONENUMBER_ALREADY_IN_USE;
                break;
            case ErrorCodes.SMS_FAILURE:
                stateErrorName = RegisterStatus.SMS_FAILURE;
                break;
            case ErrorCodes.ERR_INVALID_PARAMETER:
                if (error.error.errors[0].parameters) {
                    if (error.error.errors[0].parameters instanceof Array && error.error.errors[0].parameters.find(param => param.fieldName === ErrorFieldNames.REGISTER_MSISDN) != null) {
                        stateErrorName = RegisterStatus.PHONENUMBER_INVALID;
                    }
                    else if (error.error.errors[0].parameters['fieldName'] === ErrorFieldNames.REGISTER_MSISDN) {
                        stateErrorName = RegisterStatus.PHONENUMBER_INVALID;
                    }
                }
                break;
            default:
                stateErrorName = null;
        }
        return stateErrorName;
    }


    getName() {
        return 'AuthService';
    }

}
