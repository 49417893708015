import {BasicImageModel, IProductModel} from '../model/iproduct.model';
import {MagentoAttribute, MagentoProductTypeEnum} from '../../enum/magento-constants';
import {ArrayUtil} from '../../../shared/util/array.util';
import {ChildProductModel} from './child-product.model';
import {
    AmLabel,
    CategoryInterface,
    ConfigurableProduct,
    ConfigurableVariant,
    GqlMagentoAttribute,
    OrderItemInterface,
    ProductInterface,
    ProductStockStatus
} from '../../../../../../generated/graphql';
import {PromotionModel} from '../../../promotions/promotion.model';
import {CategoryHash} from '../magento.reducer.types';


export class ProductModel implements IProductModel<ProductInterface> {

    public labels:AmLabel[]; // These are applied to products as a separate GraphQL call

    private _source:ProductInterface;
    private _catIds:string[];
    private _relatedProductSkus:string[];
    private _childProductsSortedByPrice:ChildProductModel[];
    private _promotions:PromotionModel[];
    private _variantIds:number[];
    private _categories:CategoryInterface[];
    private _customAttributeMetadata:GqlMagentoAttribute[];
    private _customAttributeMetadataHash:{ [id:string]:GqlMagentoAttribute };

    constructor(product:ProductInterface,
                // This is a necessary dependency to fix a Magento issue:
                // Magento returns only enabled categories for a category fetch,
                // but returns both enabled and disabled categories during a product fetch,
                // with no way to determine it's enabled/disabled status.
                public categoryIDHash:CategoryHash,
                public allAttributeMetadata?:GqlMagentoAttribute[],
                public index?:number) {
        this.source = product;
    }

    set source(value:ProductInterface) {
        this._source                     = value;
        this._catIds                     = null;
        this._relatedProductSkus         = null;
        this._childProductsSortedByPrice = null;
    }

    get source():ProductInterface {
        return this._source;
    }

    get id():number {
        return this.source.id;
    }

    get sku():string {
        return this.source.sku;
    }

    get name():string {
        return this.source.name;
    }

    get description():string {
        return this.source?.description?.html;
    }

    get images():BasicImageModel[] {
        if (this.source?.media_gallery) {
            return ArrayUtil
                .sortByProp(this.source.media_gallery.concat(), false, item => item.position)
                .filter(v => !v.disabled)
                .map(galItem => {
                    return {image: galItem.file_name, alt: galItem.label};
                });
        }
        // Order items still use this old style mapping
        else if (this.source?.product_image_names || this.source?.image_name) {
            // NOTE: This is confusing as image_name is coming back with a | delimited list of multiple images.
            // However, in other API calls image_name is always singular and image_names is many.
            // This is an API issue in my mind, but we're dealing with it regardless
            /*return this.source.custom_attributes && this.source.custom_attributes.image_name ?
                this.source.custom_attributes.image_name
                    .split('|')
                    .filter(v => v != null && v !== '')
                    .map(value => {
                        return {image: value, alt: this.source.custom_attributes.image_name_label};
                    })
                : [];*/

            const multiImagesStr = this.source.product_image_names;
            const alt            = this.source?.['image_name_label'];
            const imageName      = this.source.image_name;

            if (multiImagesStr != null) {
                return multiImagesStr
                    .split('|')
                    .filter(v => v != null && v !== '')
                    .map(name => {
                        return {image: name, alt: alt};
                    });
            }
            else {
                return [{image: imageName, alt: alt}];
            }
        }
        return [];
    }

    getFirstImagePath():string {
        const img = this.images;
        return img && img.length > 0 ? img[0].image : null;
    }

    get price():number {
        return this.source.price_range.minimum_price.final_price.value;
    }

    get oldPrice():number {
        return this.source?.price_range?.minimum_price?.regular_price?.value;
    }

    get discount():number {
        return this.source?.price_range?.minimum_price?.discount?.amount_off;
    }

    get categoryIds():string[] {
        if (!this._catIds && this.categories) {
            this._catIds = this.categories.map(category => category.id + '');
        }
        return this._catIds;
    }

    get categoryId():string {
        return this.categoryIds && this.categoryIds.length > 0 ? this.categoryIds[0] : null;
    }

    get promotions():PromotionModel[] {
        if (!this._promotions) {
            // This is dirty and hardcoded, but currently the only agreed way we can find promotions related to a product
            const promotionsUrlKey = 'promotions';
            const promotionsRegex  = new RegExp(promotionsUrlKey + '\/', 'g');
            this.categories.forEach(cat => {
                if (cat.url_path.match(promotionsRegex) != null) {
                    if (!this._promotions) this._promotions = [];
                    this._promotions.push({
                        name      : cat.name,
                        path      : cat.url_path,
                        categoryId: cat.id
                    });
                }
            });
        }
        return this._promotions;
    }

    get categories():CategoryInterface[] {
        if (!this._categories) {
            this._categories = this._source?.categories?.filter(cat => this.categoryIDHash[cat.id] != null);
        }
        return this._categories;
    }

    get urlKey() {
        return this.source?.url_key;
    }

    get metaTitle() {
        return this.source?.meta_title;
    }

    get metaDescription() {
        return this.source?.meta_description;
    }

    get customAttributeHash() {
        return this.source;
    }

    get customAttributeMetadata():GqlMagentoAttribute[] {
        this.generateAttrData();
        return this._customAttributeMetadata;
    }

    get customAttributeMetadataHash():{ [id:string]:GqlMagentoAttribute } {
        this.generateAttrData();
        return this._customAttributeMetadataHash;
    }

    private generateAttrData() {
        if (!this._customAttributeMetadata) {
            this._customAttributeMetadata     = [];
            this._customAttributeMetadataHash = {};
            this.allAttributeMetadata.forEach(attr => {
                let appliedToConfigurable = true;

                if (attr.apply_to?.length > 0) {
                    appliedToConfigurable = false;
                    const val             = attr.apply_to.find(v => v === 'configurable');
                    if (val != null) appliedToConfigurable = true;
                }

                if (attr.is_user_defined && attr.is_visible_on_front === '1' && appliedToConfigurable) {
                    this._customAttributeMetadata.push(attr);
                    this._customAttributeMetadataHash[attr.attribute_code] = attr;
                }
            });
        }
    }

    get isOnPromotion():boolean {
        // TODO: Special This needs to be refactored when the special prices are in place
        return false;
    }

    get brandLogo() {
        return this.source.product_decal;
    }

    get isInStock() {
        let isInStock    = false;
        let foundVariant = false;

        // Look for 1 variant with a stock_status of is in stock
        // We may have no variants loaded or stock_status not loaded either
        const variants = (<ConfigurableProduct>this.source)?.variants;
        if (variants?.length > 0) {
            for (let i = 0; i < variants.length; i++) {
                const simple = variants[i];
                if (simple?.product?.stock_status === ProductStockStatus.InStock) {
                    isInStock    = true;
                    foundVariant = true;
                    break;
                }
            }
        }
        // If nothing found then fallback onto the configurable
        if (!foundVariant) {
            isInStock = this.source.stock_status === ProductStockStatus.InStock;
        }
        return isInStock;
    }

    get isSimple() {
        return this.source['__typename'] === MagentoProductTypeEnum.simple;
    }

    get isConfigurable() {
        return this.source['__typename'] === MagentoProductTypeEnum.configurable;
    }

    get productDecalOld() {
        return this.source[MagentoAttribute.product_decal_old];
    }

    get washcareGuide() {
        // product_attribute is the legacy attr for washcare guide, still in use by pep
        return (this.source?.washcare_guide != null) ? this.source?.washcare_guide : this.source?.product_attribute;
    }

    get sizeGuide() {
        return this.source?.size_guide;
    }

    get relatedProductSKUs() {
        if (!this._relatedProductSkus) {
            this._relatedProductSkus = this.source.related_products.map(related => related.sku);
        }
        return this._relatedProductSkus;
    }

    getChildProductsSortedByPrice() {
        if (!this._childProductsSortedByPrice && this.isConfigurable && (<ConfigurableProduct>this.source)?.variants?.length > 0) {
            const variants:ConfigurableVariant[] = (<ConfigurableProduct>this.source).variants.map(variant => Object.assign({}, variant));
            this._childProductsSortedByPrice     = ArrayUtil
                .sortByProp(variants, false, item => item.product.price_range.minimum_price.final_price.value)
                .map(p => new ChildProductModel(p));
        }
        return this._childProductsSortedByPrice;
    }

    getAllChildrenHaveValidSortOrder() {
        let allChildrenHaveReliableSort = true;
        const sortHash                  = {};
        this.getChildProductsSortedByPrice()?.forEach(child => {
            if (child.variantSortOrder != null) {
                // Duplicate sort id
                if (sortHash[child.variantSortOrder]) {
                    allChildrenHaveReliableSort = false;
                }
                sortHash[child.variantSortOrder] = true;
            }
            else {
                allChildrenHaveReliableSort = false;
            }
        });
        return allChildrenHaveReliableSort;
    }

    // This is used in the order management section as other code requires a proper product to function
    static createFakeProduct(orderItem:OrderItemInterface, categoryIDHash:CategoryHash):IProductModel {
        const fakeProduct = {
            sku                : (orderItem?.parent_product?.product_sku) ? orderItem.parent_product.product_sku : orderItem.product_sku,
            name               : (orderItem?.parent_product?.product_name) ? orderItem.parent_product.product_name : orderItem.product_name,
            url_key            : (orderItem?.parent_product?.product_url_key) ? orderItem.parent_product.product_url_key : orderItem.product_url_key,
            product_image_names: orderItem.image_info.product_image_names,
            image_name_label   : orderItem.image_info.image_name_label,
            image_name         : orderItem.image_info.image_name,
            id                 : null,
            staged             : null,
            uid                : null,
            price              : null,
            oldPrice           : null,
            discount           : null,
            description        : null,
            categoryIds        : null,
            categoryId         : null,
            metaTitle          : null,
            metaDescription    : null,
            isOnPromotion      : null,
            brandLogo          : null,
            isInStock          : null,
            isSimple           : null,
            isConfigurable     : null,
            customAttributeHash: null,
            price_range        : null,
            rating_summary     : null,
            review_count       : null,
            reviews            : null,
            washcareGuide      : null,
            sizeGuide          : null
        };
        return new ProductModel(fakeProduct, categoryIDHash);
    }

}
