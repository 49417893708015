/**
 * This is a basic enum & class paring to handle all of the basic states a UI will move between.
 * The class is simply there to make integration easier, the enum is at the heart of processing.
 *
 *
 * Working example for the state, changing:
 * 1) Screen init               => INITIALIZING
 * 2) Load data for the screen  => INITIALIZING
 * 3) Data loaded               => READY
 * 4) Reload screen data        => INITIALIZING
 * 5) Data loaded               => READY
 * 6) Process inner form submit => PROCESSING
 * 6) Error occurs              => ERROR
 * 7) Error load retry          => ERROR_RECOVERY
 * 8) Process passed            => READY
 * 9) Submit Data               => PROCESSING
 * 10) Done                     => DONE
 */

import {ExternalResponse} from '../../external-data/external-response';

export enum ViewStateEnum {
    // Before the initial view & data has loaded
    INITIALIZING   = 300,
    // Loading that takes place after the screen has fully loaded, i.e. form submission
    PROCESSING     = 301,
    // Once all processing / error have been mitigated
    READY          = 302,
    // Once the screen has captured all the required data and successfully been completed. Used for forms.
    DONE           = 303,
    // Screen has encountered an error
    ERROR          = 304,
    // Screen is trying to recover from an error
    ERROR_RECOVERY = 305
}

export class ViewState {
    _current:number = ViewStateEnum.INITIALIZING;
    debugName:string;

    errorStateName:string;
    errorMessage:string;

    errorResponse:ExternalResponse;

    constructor(initialState = ViewStateEnum.INITIALIZING, debugName?:string) {
        this.current   = initialState;
        this.debugName = debugName;
    }

    get current() {
        return this._current;
    }

    set current(value:number) {
        this._current = value;
        if (this._current !== ViewStateEnum.ERROR) {
            this.errorStateName = null;
            this.errorResponse  = null;
        }
        if (this.debugName) console.log(`[${this.debugName}] --------------> View State Change: `, ViewStateEnum[value], this.errorStateName, this.errorMessage);
    }

    // Below are a list of utility methods for ease of use

    get isInitializing() {
        return this.current === ViewStateEnum.INITIALIZING;
    }

    setInitializing() {
        this.current = ViewStateEnum.INITIALIZING;
    }

    get isProcessing() {
        return this.current === ViewStateEnum.PROCESSING;
    }

    setProcessing() {
        this.current = ViewStateEnum.PROCESSING;
    }

    get isErrorRecovery() {
        return this.current === ViewStateEnum.ERROR_RECOVERY;
    }

    setErrorRecovery() {
        this.current = ViewStateEnum.ERROR_RECOVERY;
    }


    get isReady() {
        return this.current === ViewStateEnum.READY;
    }

    setReady() {
        this.current = ViewStateEnum.READY;
    }

    get isDone() {
        return this.current === ViewStateEnum.DONE;
    }

    setDone() {
        this.current = ViewStateEnum.DONE;
    }

    get isError() {
        return this.current === ViewStateEnum.ERROR;
    }

    get isErrorNoName() {
        return this.current === ViewStateEnum.ERROR && this.errorStateName == null;
    }

    isErrorName(name:string) {
        return this.current === ViewStateEnum.ERROR && this.errorStateName === name;
    }

    setError(errorStateName:string = null) {
        this.current        = ViewStateEnum.ERROR;
        this.errorStateName = errorStateName;
    }

    setErrorWithMessage(errorStateName:string = null, errorMessage:string = null) {
        this.current        = ViewStateEnum.ERROR;
        this.errorStateName = errorStateName;
        this.errorMessage   = errorMessage;
    }

    setErrorWithResponse(errorResponse:ExternalResponse, errorStateName:string = null) {
        this.setError(errorStateName);
        this.errorResponse = errorResponse;
    }


    get isAnyLoadingState() {
        return this.isInitializing || this.isProcessing || this.isErrorRecovery;
    }

    /**
     * Used to set the relative loading state from the current state
     */
    setLogicalLoadingState() {
        if (this.isError) {
            this.setErrorRecovery();
        }
        else if (this.isReady) {
            this.setProcessing();
        }
        else {
            this.setInitializing();
        }
    }
}
