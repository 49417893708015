import {Injectable, Injector} from '@angular/core';
import {first, map, switchMap, tap} from 'rxjs/operators';
import {ExternalResponse} from '../external-response';
import {
    ACFBase,
    ACFComponent,
    BannerScrollerComponentACF,
    BannerScrollerWithTextComponentACF,
    CategoryOrTag,
    CategoryOrTagResponse,
    ComponentTypesACF,
    DisplayTypesACF,
    DynamicPageACF,
    NonRenderableComponents,
    OnPageMenuConfigComponentACF,
    PopupConfigComponentACF,
    ProductListComponentACF,
    RelatedPosts,
    ReusableDefinitionACF,
    RootConfigPageACF,
    RootConfigTypesACF,
    SEOMetadataComponentACF,
    WordpressConstants,
    WordpressImage,
    WordpressListResponse,
    WordpressPage,
    WordpressPost
} from './model/wordpress.types';
import {interval, Observable, of, throwError} from 'rxjs';
import {AbstractFacadeService} from '../abstract-facade.service';
import {FacadePlatform} from '../../shared/facade-platform';
import {FacadeAppDefinitionType} from '../../../../scripts/shared/facade-config.types';
import {FacadeParsedUrl, FacadeUrlUtil} from '../../shared/util/facade-url-util';
import {WebPManager} from '../../webp/webp-manager';
import {CookieService} from 'ngx-cookie-service';
import {WindowRef} from '../../shared/ui/window-ref';
import {ArrayUtil} from '../../shared/util/array.util';
import {UrlSegment} from '@angular/router';
import {UrlService} from '../../shared/url.service';
import {BreadcrumbLink} from '../../shared/ui/breadcrumb/breadcrumb.types';
import {RegexUtils} from '../../../../scripts/shared/regex-utils';
import {ResponseCode} from '../enum/response-code.enum';
import {BlogPageEnum, PageTypeEnum} from '../../router/page-type.enum';
import {environment} from '../../implementation/environment';
import {WP_GET_PAGES, WP_GET_PAGES_CACHE} from './gql-queries/wp-get-pages';
import {
    GqlWpCategoryOrTagResponse,
    GqlWpPagesResponse,
    GqlWpPost,
    GqlWpPostsResponse,
    GqlWpRelatedPosts,
    GqlWpTokenResponse,
} from '../../../../../generated/graphql';
import {WP_GET_POSTS, WP_GET_POSTS_CACHE} from './gql-queries/wp-get-posts';
import {WP_GET_CATEGORIES, WP_GET_CATEGORIES_CACHE} from './gql-queries/wp-get-categories';
import {WP_GET_TAGS, WP_GET_TAGS_CACHE} from './gql-queries/wp-get-tags';
import {WP_GET_PAGE_BY_ID, WP_GET_PAGE_BY_ID_CACHE} from './gql-queries/wp-get-page-by-id';
import {WP_GET_RELATED_POSTS, WP_GET_RELATED_POSTS_CACHE} from './gql-queries/wp-get-related-posts';
import {WP_GET_TOKEN} from './gql-queries/wp-get-token';
import {PepkorCategoryapiDataCategoryapiTreeInterface} from '../magento/magento.types';
import {PathSegment} from '../../shared/abstract-url.service';
import dayjs from 'dayjs';
import {RootState} from '../../ngxs/root.state';

export enum CloudFunctionConst {
    OPTIM_IMG_PREFIX     = 'OPTIM_',
    OPTIMISE_IMAGE_TOPIC = 'OPTIMISE_IMAGE'
}

export interface WpPagesResponse {
    wpPages:GqlWpPagesResponse;
}

interface WpPostsResponse {
    wpPosts:GqlWpPostsResponse;
}

interface WpCategoriesResponse {
    wpCategories:GqlWpCategoryOrTagResponse;
}

interface WpTagsResponse {
    wpTags:GqlWpCategoryOrTagResponse;
}

interface WpRelatedResponse {
    wpRelatedPosts:GqlWpRelatedPosts;
}

interface WpTokenResponse {
    wpToken:GqlWpTokenResponse;
}

@Injectable()
export class WordpressLogicService extends AbstractFacadeService {

    private jwt:string;
    private loadingCategories = false;

    constructor(injector:Injector,
                public facadePlatform:FacadePlatform,
                public cookieService:CookieService,
                public windowRef:WindowRef,
                public urlService:UrlService) {
        super(injector);
        //this.logger.logLevel = LogLevel.TEST;
    }

    public componentShouldDisplaySync(component:ACFComponent, expectedType:ComponentTypesACF, isLoggedIn:boolean) {
        if (component.acf_fc_layout === expectedType) {
            if (component.display === DisplayTypesACF.always) {
                return true;
            }
            else if (component.display === DisplayTypesACF.loggedIn) {
                return isLoggedIn;
            }
            else if (component.display === DisplayTypesACF.loggedOut) {
                return !isLoggedIn;
            }
        }
        return false;
    }


    /*
    Pages
    --------------------------------------------
    */
    public getCategoryACFData(category:PepkorCategoryapiDataCategoryapiTreeInterface) {
        const slug = this.urlService.buildSlugForCategoryPage(category);
        return this.getPageCached(slug, false, false, true);
    }

    public getHomeACFData() {
        const slug = this.urlService.buildSlugForHome();
        return this.getPageCached(slug);
    }

    public getStaticContentPage(segments:Array<UrlSegment | PathSegment>) {
        const slug = this.urlService.buildSlugForStaticPage(segments);
        if (slug) {
            return this.getPageCached(slug, true);
        }
        else {
            return of(null);
        }
    }

    public getBlogCategoryPage(urlParts:string[]) {
        const slug       = this.urlService.buildSlugForBlogCategory(urlParts);
        const isMainPage = (urlParts.length === 1);
        return this.getPageCached(slug, false, isMainPage);
    }

    public getCataloguePage() {
        const slug = PageTypeEnum.CATALOGUE;
        return this.getPageCached(slug, false, true);
    }

    public getMenuConfigPage(page:string) {
        return this.getPageCached('menu-config' + page, false, false).pipe(
            map(res => {
                return res;
            })
        );
    }

    public getPromotionalPopup(pageId:number) {
        return this.getPageCached('popups', false, true).pipe(
            map(res => {
                const popups:PopupConfigComponentACF[] = res.data?.acf.popups;
                const filteredPopups                   = popups ? this.filterPopups(popups, pageId) : null;
                return filteredPopups;
            })
        );
    }

    public getLinkListPage(page:string) {
        const pathArray:Array<PathSegment> = ['link-lists', page].map(path => ({path}));
        const slug                         = pathArray.length > 0 ? pathArray.map((seg:{
            path:string
        }) => encodeURIComponent(seg.path)).join('/') : null;
        if (slug) {
            return this.getPageCached(slug, false, false);
        }
        else {
            return of(null);
        }
    }

    public filterPopups(popups:PopupConfigComponentACF[], pageId:number) {
        return popups.filter(popup => {
            let canDisplay      = false;
            // check if current page is
            const onCurrentPage = popup.pageIds.indexOf(pageId) >= 0;
            if (onCurrentPage) {
                // check cookies if page has already been displayed
                const cookieName = popup.name.toLowerCase().replace(/\s/g, '');
                const hasCookie  = this.cookieService.get(cookieName);
                const isInEnv    =
                          (this.getIsMobile() && popup.environments === WordpressConstants.DESKTOP) ||
                          (this.getIsDesktop() && popup.environments === WordpressConstants.MOBILE)
                              ? false
                              : true;

                if (!hasCookie && isInEnv) {
                    // in date range
                    const today               = new Date();
                    const startDate           = new Date(popup.startDate);
                    const endDate             = new Date(popup.endDate);
                    const inDateRange:boolean = startDate
                        && startDate.getTime() <= today.getTime()
                        && endDate
                        && endDate.getTime() + 24 * 60 * 60 * 1000 >= today.getTime();
                    if (inDateRange) canDisplay = true;
                }
            }

            return canDisplay;
        });
    }


    public dismissPromotionalPopup(popup:PopupConfigComponentACF) {
        const cookieName = popup.name.toLowerCase().replace(/\s/g, '');
        this.cookieService.set(cookieName, 'true', 365, '/', null, true, 'Strict');
    }

    public getPromotionalPost():Observable<ExternalResponse<WordpressPost<DynamicPageACF>>> {

        return this.getBlogCategoryPage([PageTypeEnum.BLOG])
            .pipe(
                switchMap((response:ExternalResponse<WordpressPage<DynamicPageACF>>) => {
                    const promotionPageId:any = response?.data?.acf?.promotionalPost;
                    if (!isNaN(promotionPageId)) return this.getPost(null, Number(promotionPageId));
                    return of(null);
                }),
                map((promoResponse:ExternalResponse<WordpressPost<DynamicPageACF>>) => {
                    return promoResponse;
                })
            );
    }

    getPageTreeForRedirect(orderedIds:number[]):Observable<ExternalResponse<WordpressPage[]>> {
        const variables = {
            include: orderedIds.join(','),
            status : 'publish',
        };
        const headers   = this.getDraftHeaders(variables);
        return this.apollo.query({
            query    : WP_GET_PAGES,
            variables: variables,
            context  : {
                headers: headers
            }
        }, WP_GET_PAGES_CACHE).pipe(
            map((res:ExternalResponse<WpPagesResponse>) => {
                // Apollo cache responses are immutable
                const pages:WordpressPage[] = res.data.wpPages.items.map(item => this.unlockObject(item)) as WordpressPage[];
                this.rebuildPageLinks(pages);
                // @ts-ignore
                const newRes:ExternalResponse<WordpressPage[]> = Object.assign({}, res);
                newRes.data                                    = pages;
                return newRes;
            })
        );
    }


    /*
    Posts
    --------------------------------------------
    */
    public getPostList(page:number = 1, perPage:number = 10, categorySlugs?:string[], tagSlugs?:string[]):Observable<ExternalResponse<WordpressListResponse>> {
        page    = Number(page);
        perPage = Number(perPage);
        let categoryResponse:CategoryOrTagResponse;
        let tagResponse:CategoryOrTagResponse;
        return this.getCategoriesOrTags(BlogPageEnum.CATEGORIES).pipe(
            switchMap(res => {
                categoryResponse = res.data;
                return this.getCategoriesOrTags(BlogPageEnum.TAGS);
            }),
            switchMap(res => {
                tagResponse                          = res.data;
                const variables                      = {
                    page    : String(page),
                    per_page: String(perPage),
                    order   : 'desc',
                    status  : 'publish'
                };
                const errorResponse:ExternalResponse = {responseCode: ResponseCode.GENERAL_ERROR};

                if (categorySlugs) {
                    variables['categories'] = categorySlugs.map(slug => {
                        if (!categoryResponse.slugHash[slug]) {
                            errorResponse.developerErrorMessage = `This category (${slug}) does not exist.`;
                            throwError(errorResponse);
                        }
                        else {
                            return categoryResponse.slugHash[slug].id;
                        }
                    });
                }
                if (tagSlugs) {
                    variables['tags'] = tagSlugs.map(slug => {
                        if (!tagResponse.slugHash[slug]) {
                            errorResponse.developerErrorMessage = `This tag (${slug}) does not exist.`;
                            throwError(errorResponse);
                        }
                        else {
                            return tagResponse.slugHash[slug].id;
                        }
                    });
                }

                const headers = this.getDraftHeaders(variables);
                return this.apollo.query({
                    query    : WP_GET_POSTS,
                    variables: variables,
                    context  : {
                        headers: headers
                    }
                }, WP_GET_POSTS_CACHE);
            }),
            map((response:ExternalResponse<WpPostsResponse>) => {
                const newRes:ExternalResponse<WpPostsResponse> = Object.assign({}, response);
                newRes.data                                    = this.unlockObject(newRes.data);
                return newRes;
            }),
            map((response:ExternalResponse<WpPostsResponse>) => this.parsePostList(response, categoryResponse, tagResponse)),
            tap(response => this.rebuildPostSlugs(response.data.wpPosts.items)),
            map((response:ExternalResponse<WpPostsResponse>) => {
                // @ts-ignore
                const newResponse:ExternalResponse<WordpressListResponse> = Object.assign({}, response);
                const totalPages                                          = response.data.wpPosts.totalPages;
                const totalPosts                                          = response.data.wpPosts.total;
                newResponse.data                                          = {
                    currentPage: Number(page),
                    totalPages : totalPages,
                    hasPrevPage: (page > 1),
                    hasNextPage: (page !== totalPages),
                    posts      : response.data.wpPosts.items as WordpressPost<DynamicPageACF>[]
                };
                return newResponse;
            })
        );
    }


    getCategoriesOrTags(type:BlogPageEnum.CATEGORIES | BlogPageEnum.TAGS, inHierarchy:boolean = false):Observable<ExternalResponse<CategoryOrTagResponse>> {
        return interval(100).pipe(
            // Debounce loading to ensure concurrent requests have a chance to use the cache
            first(() => (this.loadingCategories === false)),
            switchMap(() => {
                this.loadingCategories = true;

                const variables = {
                    // TODO Implement paging for all categories
                    per_page: '100'
                };
                const isCats    = type === BlogPageEnum.CATEGORIES;

                const headers = this.getDraftHeaders(variables);
                return this.apollo.query({
                    query    : (isCats) ? WP_GET_CATEGORIES : WP_GET_TAGS,
                    variables: variables,
                    context  : {
                        headers: headers
                    }
                }, (isCats) ? WP_GET_CATEGORIES_CACHE : WP_GET_TAGS_CACHE).pipe(
                    map((res:ExternalResponse<WpCategoriesResponse | WpTagsResponse>) => {
                        // @ts-ignore
                        const newResponse:ExternalResponse<CategoryOrTagResponse> = Object.assign({}, res);

                        let items:CategoryOrTag[] = [];
                        if ('wpCategories' in res?.data) {
                            items = res?.data?.wpCategories?.items.map(item => this.unlockObject(item)) as CategoryOrTag[];
                        }
                        else if ('wpTags' in res?.data) {
                            items = res?.data?.wpTags?.items.map(item => this.unlockObject(item)) as CategoryOrTag[];
                        }

                        const newData:CategoryOrTagResponse = {slugHash: {}, idHash: {}, list: items, hierarchicalList: null};
                        items.forEach((item:CategoryOrTag) => {
                            item.type                   = isCats ? BlogPageEnum.CATEGORY : BlogPageEnum.TAG;
                            newData.slugHash[item.slug] = item;
                            newData.idHash[item.id]     = item;
                        });
                        if (inHierarchy && type === BlogPageEnum.CATEGORIES) {
                            newData.hierarchicalList = [];
                            items.forEach(cat => {
                                if (cat.parent === 0 || cat.parent === '0') {
                                    newData.hierarchicalList.push(cat);
                                }
                                else {
                                    const parent = newData.idHash[cat.parent];
                                    if (!parent) throw new Error('Unable to locate category parent: ' + cat.id);
                                    if (!parent.children) parent.children = [];
                                    parent.children.push(cat);
                                }
                            });
                        }

                        newResponse.data       = newData;
                        this.loadingCategories = false;
                        return newResponse;
                    })
                );
            })
        );
    }

    /*public getCategoryOrTagList(type:BlogPageEnum.CATEGORIES | BlogPageEnum.TAGS, inHierarchy:boolean = true):Observable<ExternalResponse<CategoryOrTag[]>> {
        const cacheKey = `list-${type}-${inHierarchy}`;
        const cached   = this.cacheGet(cacheKey);
        if (cached != null) return of(cached);

        return this.getCategoriesOrTags(type).pipe(
            map((response) => {
                const listData                                      = response.data.list.concat();
                // @ts-ignore
                const newResponse:ExternalResponse<CategoryOrTag[]> = Object.assign({}, response);
                newResponse.data                                    = listData;
                // Lets parse these items into an hierarchical structure
                if (type === 'categories' && inHierarchy) {
                    const parentHash = {};
                    const newList    = [];
                    newResponse.data.forEach(item => {
                        if (!parentHash[item.parent]) parentHash[item.parent] = [];
                        parentHash[item.parent].push(item);
                    });
                    newResponse.data.forEach(item => {
                        if (parentHash[item.id]) {
                            item.children = parentHash[item.id];
                        }
                        if (item.parent === 0) {
                            newList.push(item);
                        }
                    });
                    newResponse.data = newList;
                }
                this.cachePut(cacheKey, newResponse);
                return newResponse;
            })
        );
    }*/

    public getCategoryOrTag(type:BlogPageEnum.CATEGORIES | BlogPageEnum.TAGS, slug:string):Observable<ExternalResponse<CategoryOrTag>> {
        return this.getCategoriesOrTags(type).pipe(
            map((response:ExternalResponse<CategoryOrTagResponse>) => {
                // @ts-ignore
                const newResponse:ExternalResponse<CategoryOrTag> = Object.assign({}, response);
                newResponse.data                                  = response.data.slugHash[slug];
                return newResponse;
            })
        );
    }

    public getPost(slug:string, id:number = null):Observable<ExternalResponse<WordpressPost<DynamicPageACF>>> {
        let categoryResponse:CategoryOrTagResponse;
        let tagResponse:CategoryOrTagResponse;
        let rootPage;
        let finalResponse;
        return this.getCategoriesOrTags(BlogPageEnum.CATEGORIES).pipe(
            switchMap(res => {
                categoryResponse = res.data;
                return this.getCategoriesOrTags(BlogPageEnum.TAGS);
            }),
            switchMap(res => {
                tagResponse = res.data;
                return this.getRootPage();
            }),
            switchMap(incomingRootPage => {
                rootPage        = incomingRootPage;
                // slug_or_title custom wordpress API to filter by slug then title
                const variables = {
                    status: 'publish',
                    // field restriction
                    //'_fields[]': WordpressPostFields,
                };
                if (slug) {
                    variables['slug_or_title'] = slug;
                }
                else {
                    variables['include'] = id;
                }

                const headers = this.getDraftHeaders(variables);
                return this.apollo.query({
                    query    : WP_GET_POSTS,
                    variables: variables,
                    context  : {
                        headers: headers
                    }
                }, WP_GET_POSTS_CACHE);
            }),
            map((response:ExternalResponse<WpPostsResponse>) => {
                const newRes:ExternalResponse<WpPostsResponse> = Object.assign({}, response);
                newRes.data                                    = this.unlockObject(newRes.data);
                return newRes;
            }),
            map((response:ExternalResponse<WpPostsResponse>) => this.parsePostList(response, categoryResponse, tagResponse)),
            tap((response:ExternalResponse<WpPostsResponse>) => this.rebuildPostSlugs(response.data.wpPosts.items)),
            switchMap((response:ExternalResponse<WpPostsResponse>) => {
                finalResponse = response;
                return this.store.select((s:RootState) => s.customer.isLoggedIn);
            }),
            map((isLoggedIn) => {
                // @ts-ignore
                const newResponse:ExternalResponse<WordpressPost<DynamicPageACF>> = Object.assign({}, finalResponse);
                newResponse.data                                                  = finalResponse.data.wpPosts.items[0] as WordpressPost;
                const post                                                        = newResponse.data;

                if (!post || !this.hasRenderableComponents(post.acf.components)) {
                    newResponse.responseCode          = ResponseCode.GENERAL_ERROR;
                    newResponse.developerErrorMessage = 'No renderable components';
                    newResponse.data                  = null;
                    throw newResponse;
                }
                else {
                    post.breadcrumbs    = [
                        this.urlService.getHomeCrumb(),
                        this.urlService.getBlogCrumb(),
                        {label: post.title.rendered, url: this.urlService.buildUrlForBlogPost(post)}
                    ];
                    post.acf.components = this.postProcessDynamicPage(post.acf.components, rootPage, isLoggedIn, slug, null, null);
                }
                newResponse.data = post;
                return newResponse;
            })
        );
    }

    public getPostForRedirect(id:number):Observable<ExternalResponse<WordpressPost[]>> {
        const variables = {
            include: id + '',
            status : 'publish',
        };

        const headers = this.getDraftHeaders(variables);
        return this.apollo.query({
            query    : WP_GET_POSTS,
            variables: variables,
            context  : {
                headers: headers
            }
        }, WP_GET_POSTS_CACHE).pipe(
            tap((response:ExternalResponse<WpPostsResponse>) => this.rebuildPostSlugs(response.data.wpPosts.items)),
            map((response:ExternalResponse<WpPostsResponse>) => {
                // @ts-ignore
                const newResponse:ExternalResponse<WordpressPost[]> = Object.assign({}, response);
                newResponse.data                                    = response.data.wpPosts.items as WordpressPost[];
                return newResponse;
            })
        );
    }

    public getPageById(id:number, parentSlug?:string):any {
        const variables = {
            include: id + '',
            status : 'publish'
        };

        const headers = this.getDraftHeaders(variables);
        return this.apollo.query({
            query    : WP_GET_PAGE_BY_ID,
            variables: variables,
            context  : {
                headers: headers
            }
        }, WP_GET_PAGE_BY_ID_CACHE).pipe(
            switchMap((res:ExternalResponse<WpPagesResponse>) => {
                const urlPart = parentSlug ? parentSlug + '/' + res.data.wpPages.items[0].slug : res.data.wpPages.items[0].slug;
                return this.getPageCached(urlPart, false);
            })
        );
    }

    public getRelatedPosts(postId:number, page:number = 1, perPage:number = 100):Observable<ExternalResponse<RelatedPosts>> {
        let relatedResponse:ExternalResponse<WpRelatedResponse>;
        let categoryResponse:CategoryOrTagResponse;
        let tagResponse:CategoryOrTagResponse;

        return this.apollo.query({
            query    : WP_GET_RELATED_POSTS,
            variables: {
                postId: postId
            }
        }, WP_GET_RELATED_POSTS_CACHE).pipe(
            switchMap((relatedRes:ExternalResponse<WpRelatedResponse>) => {
                relatedResponse = relatedRes;
                return this.getCategoriesOrTags(BlogPageEnum.CATEGORIES);
            }),
            switchMap(categoryRes => {
                categoryResponse = categoryRes.data;
                return this.getCategoriesOrTags(BlogPageEnum.TAGS);
            }),
            switchMap(tagRes => {
                tagResponse = tagRes.data;

                const variables = {
                    // ONLY render posts that are linking TO.
                    //'include'  : Object.keys(relatedResponse.data.from).concat(Object.keys(relatedResponse.data.to)).join(','),
                    include : Object.keys(relatedResponse.data.wpRelatedPosts.to).join(','),
                    page    : page.toString(),
                    per_page: perPage.toString(),
                    status  : 'publish',
                };

                return this.apollo.query({
                    query    : WP_GET_POSTS,
                    variables: variables,
                }, WP_GET_POSTS_CACHE);
            }),
            map((response:ExternalResponse<WpPostsResponse>) => {
                const newRes:ExternalResponse<WpPostsResponse> = Object.assign({}, response);
                newRes.data                                    = this.unlockObject(newRes.data);
                return newRes;
            }),
            tap((response:ExternalResponse<WpPostsResponse>) => this.rebuildPostSlugs(response.data.wpPosts.items)),
            map((response:ExternalResponse<WpPostsResponse>) => this.parsePostList(response, categoryResponse, tagResponse)),
            map((response:ExternalResponse<WpPostsResponse>) => {
                // Replace the related articles with each actual article
                const postHash = {};
                response.data.wpPosts.items.forEach(post => postHash[post.id] = post);
                // @ts-ignore
                const newResponse:ExternalResponse<RelatedPosts> = Object.assign({}, relatedResponse);
                newResponse.data                                 = {to: {}, from: {}, totalPages: response.data.wpPosts.totalPages, page: Number(page)};
                // ONLY render posts that are linking TO.
                //Object.keys(relatedResponse.data.from).forEach((key) => (postHash[key]) ? relatedResponse.data.from[key] = postHash[key] : delete relatedResponse.data.from[key]);
                // If the postHash doesn't contain the related post, then it's a draft, so remove it completely
                Object.keys(relatedResponse.data.wpRelatedPosts.to).forEach((key) => (postHash[key]) ? newResponse.data.to[key] = postHash[key] : delete relatedResponse.data.wpRelatedPosts.to[key]);
                newResponse.data.totalPages = response.data.wpPosts.totalPages;
                newResponse.data.page       = Number(page);
                return newResponse;
            })
        );
    }


    /*
    Main reusable fetch
    --------------------------------------------
    */
    /**
     *
     * @param slug Slug of the page you want to fetch
     * @param buildBreadCrumbs Build breadcrumbs or not
     * @param allowRootPageFetch Will allow fetching the root page. This is necessary for /blog as it's possible to put content there.
     */
    private getPageCached(slug:string, buildBreadCrumbs = false, allowRootPageFetch = false, isProductListPage = false):Observable<ExternalResponse<WordpressPage<DynamicPageACF>>> {

        // TODO: This could be better as we could cache the root page but not the end of the world
        const rootSlugs:string[] = this.getRootPageSlugs();
        const cacheKey           = `page-${slug}`;

        const variables = {
            // Fetch url tree
            facade_url_tree: rootSlugs.map(root => root + '/' + slug).join(','),
            status         : 'publish'

            // We can no longer use this as its necessary to have the entire structure to rebuild urls for drafts
            //'facade_parent_include_level': facade_parent_include_level,

            // This doesn't make sense when dealing with multiple overriding locales and sub pages
            /*'per_page'                   : (per_page) ? per_page : `${rootSlugs.length * 2}`,*/
        };

        const headers = this.getDraftHeaders(variables);
        let isLoggedIn;
        return this.store.select((s:RootState) => s.customer.isLoggedIn).pipe(
            switchMap((value) => {
                isLoggedIn = value;
                return this.apollo.query({
                    query    : WP_GET_PAGES,
                    variables: variables,
                    context  : {
                        headers: headers
                    }
                }, WP_GET_PAGES_CACHE);
            }),
            map((res:ExternalResponse<WpPagesResponse>) => {
                const pages:WordpressPage[] = res?.data?.wpPages?.items?.map(item => this.unlockObject(item)) as WordpressPage[];

                if (pages) this.rebuildPageLinks(pages);

                let rootPage:WordpressPage<RootConfigPageACF>;
                let mainPage:WordpressPage<DynamicPageACF>;

                // Lets look for the root page and the main page
                // This is done using the order of the
                let fullSlugHash:{ [path:string]:WordpressPage };
                if (pages) {
                    // Create a page hash based on the link provided
                    fullSlugHash = ArrayUtil.valueHash(pages, page => new URL(page.link).pathname, page => page);
                    this.logger.test('fullSlugHash: ', fullSlugHash, rootSlugs);

                    // Now lets find the root slugs in their order as we might receive multiple pages
                    for (let i = 0; i < rootSlugs.length; i++) {
                        const root = '/' + rootSlugs[i] + '/';
                        let full   = root + slug + '/';
                        this.logger.test('Looking for: ', root, full);

                        if (allowRootPageFetch && full.slice(full.length - 2, full.length) === '//') {
                            full = full.slice(0, full.length - 1);
                        }

                        if (fullSlugHash[root] && fullSlugHash[full]) {
                            rootPage = fullSlugHash[root];
                            mainPage = fullSlugHash[full];
                            break;
                        }
                        else if (fullSlugHash[root]) {
                            rootPage = fullSlugHash[root];
                        }
                    }
                }

                const newRes:ExternalResponse<WordpressPage<DynamicPageACF>> = <any>res;
                this.logger.test('Root page: ', rootPage);
                this.logger.test('Main page: ', mainPage);
                this.logger.test('pages: ', pages);


                // This code inherits the hero row config from the parent pages if none exists on the current page
                if (isProductListPage) {
                    let productListComponent;
                    if (mainPage?.acf?.components) {
                        // Find productList config on current page
                        const compsHash      = ArrayUtil.valueHash(mainPage.acf.components, comp => comp.acf_fc_layout, comp => comp);
                        productListComponent = compsHash[ComponentTypesACF.productList];
                    }
                    else if (!mainPage) {
                        // For pages that has no wordpress page
                        mainPage = {
                            id : -1,
                            acf: {
                                components: []
                            }
                        };
                    }
                    // Find the closes parent that has configuration of the productList rowConfiguration
                    let foundParentWithRowConfig = null;
                    for (let i = 0; i <= pages.length - 1; i++) {
                        const page = pages[i];
                        if (page.acf) {
                            let productListComp;
                            for (let a = 0; a < page?.acf?.components?.length; a++) {
                                const comp = page.acf.components[a];
                                if (comp.acf_fc_layout === ComponentTypesACF.productList) {
                                    productListComp = comp;
                                    break;
                                }
                            }
                            if (productListComp?.rowConfiguration) {
                                foundParentWithRowConfig = productListComp;
                                break;
                            }
                        }
                    }
                    if (foundParentWithRowConfig) {
                        if (productListComponent && !productListComponent.rowConfiguration && pages) {
                            // has a wordpress page with no rowConfiguration
                            productListComponent.rowConfiguration = Object.assign({}, foundParentWithRowConfig.rowConfiguration);
                        }
                        else if (pages && mainPage) {
                            // mainPage
                            mainPage.acf.components.push(foundParentWithRowConfig);
                        }
                    }
                }


                // If found then post process and return single page
                if (rootPage && mainPage) {
                    if (mainPage && mainPage.acf && mainPage.acf.components) {
                        if (!this.hasRenderableComponents(mainPage.acf.components)) {
                            newRes.responseCode          = ResponseCode.GENERAL_ERROR;
                            newRes.developerErrorMessage = 'No renderable components';
                            newRes.data                  = null;
                            throw newRes;
                        }
                        else {
                            if (buildBreadCrumbs) mainPage.breadcrumbs = this.buildBreadcrumbs(slug, rootSlugs, fullSlugHash, mainPage);
                            mainPage.acf.components = this.postProcessDynamicPage(mainPage.acf.components, rootPage, isLoggedIn, slug, /*mainPage,*/ rootSlugs, fullSlugHash);
                            newRes.data             = mainPage;
                            return newRes;
                        }
                    }
                    newRes.data = mainPage;
                }
                else {
                    // Pages not found so lets null the data
                    newRes.data = null;
                }

                return newRes;
            }),
            // Cache response in 5 min cache
            tap(
                res => {
                },
                (err:ExternalResponse) => {
                    if (err && err.httpError && (err.httpError.status === 400 || err.httpError.status === 403)) this.logout();
                }
            )
        );
    }


    private getRootPage():Observable<WordpressPage<RootConfigPageACF>> {
        let rootSlug;

        const rootSlugs:string[] = this.getRootPageSlugs();
        rootSlug                 = rootSlugs[rootSlugs.length - 1];
        const variables          = {
            facade_url_tree: rootSlug,
            status         : 'publish'
        };

        return this.apollo.query({
            query    : WP_GET_PAGES,
            variables: variables
        }, WP_GET_PAGES_CACHE).pipe(
            map((res:ExternalResponse<WpPagesResponse>) => {
                let rootPage:WordpressPage<RootConfigPageACF>;
                let fullSlugHash:{ [path:string]:WordpressPage };
                if (res?.data?.wpPages?.items) {
                    // Create a page hash based on the link provided
                    fullSlugHash = ArrayUtil.valueHash(res.data.wpPages.items, page => new URL(page.link).pathname, page => page as WordpressPage);
                    const root   = '/' + rootSlug + '/';
                    rootPage     = fullSlugHash[root];
                }
                return rootPage;
            })
        );
    }


    /*
    mapping methods
    --------------------------------------------
    */
    /**
     * Used to rebuild the urls for a set of wordpress pages
     * These are set to https://dev-wordpress.ackermans.co.za/?page_id=6321
     * for draft pages so we need to manually rebuild them
     */
    private rebuildPageLinks(pageList:WordpressPage[]) {
        // Create an ID hash
        const pageIdHash = ArrayUtil.valueHash(pageList, p => p.id, p => {
            p.children = [];
            return p;
        });
        // Loop through all pages and assign them as children to their parent
        // Also identify the parent page for the next loop
        const roots:WordpressPage[] = [];
        pageList.forEach(page => {
            if (page.parent === 0) {
                roots.push(page);
            }
            else {
                pageIdHash[page.parent + ''].children.push(page);
            }
        });

        // Now that we have a hierarchical list, lets recursively iterate and rebuild
        const iterate = (pages:WordpressPage[], prefix:string) => {
            pages.forEach(page => {
                // Use the slug and fallback to the RAW title
                // The API used to search by slug does the same thing
                const slug = page.slug != null && page.slug !== '' ? page.slug : encodeURIComponent(page.title.rendered);
                page.slug  = slug;
                page.link  = prefix + slug + '/';
                if (page.children) iterate(page.children, page.link);
            });
        };

        // Might happen if there are zero results (new instance)
        if (roots.length > 0) {
            const host = new URL(roots[0].link).origin + '/';
            iterate(roots, host);
        }
    }


    private rebuildPostSlugs(postList:GqlWpPost[]) {
        postList.forEach(post => {
            if (post.slug === '' || post == null) {
                post.slug = encodeURIComponent(post.title.rendered);
            }
        });
    }

    private buildBreadcrumbs(slug:string, rootSlugs:string[], fullSlugHash:{
        [path:string]:WordpressPage
    }, mainPage:WordpressPage):BreadcrumbLink[] {
        const breadcrumbs:BreadcrumbLink[] = [];
        const findBreadcrumbLabel          = (components:ACFComponent[]) => {
            const compHash = ArrayUtil.straightHash(<ACFComponent[]>components, component => component.acf_fc_layout);
            return (compHash[ComponentTypesACF.seoMetadata]) ? (<SEOMetadataComponentACF>compHash[ComponentTypesACF.seoMetadata]).breadcrumbLabel : null;
        };
        const findBreadcrumbs              = (slugArray:string[]) => {
            let found = false;
            for (let i = 0; i < rootSlugs.length; i++) {
                const root     = '/' + rootSlugs[i] + '/';
                const full     = root + slugArray.join('/') + '/';
                const currPage = fullSlugHash[full];
                this.logger.debug('currPage: ', currPage);
                this.logger.debug('full: ', full);
                if (currPage && currPage.template !== '' && currPage.acf && currPage.acf.components && this.hasRenderableComponents(currPage.acf.components)) {
                    const breadcrumbLabel = findBreadcrumbLabel(currPage.acf.components);
                    if (breadcrumbLabel) {
                        found = true;
                        breadcrumbs.unshift({label: breadcrumbLabel, url: this.urlService.convertWordpressStaticPageUrl(currPage.link, false)});
                    }
                }
                if (found) break;
            }

            // We simply don't render breadcrumbs that don't exist
            //if (!found) throw new Error('Please ensure at least 1 wordpress parent page has both a template and meta data setup.');

            slugArray.pop();
            if (slugArray.length > 1) findBreadcrumbs(slugArray);
        };

        // First add the main page's breadcrumb
        let mainFound = false;
        if (mainPage.template != '' && mainPage.acf && mainPage.acf.components && this.hasRenderableComponents(mainPage.acf.components)) {
            const breadcrumbLabel = findBreadcrumbLabel(mainPage.acf.components);
            if (breadcrumbLabel) {
                mainFound = true;
                breadcrumbs.push({label: breadcrumbLabel, url: this.urlService.convertWordpressStaticPageUrl(mainPage.link, false)});
            }
        }
        if (!mainFound) return null;

        // Then find parent crumbs
        const slugArrayToSearchWith = slug.split('/');
        slugArrayToSearchWith.pop();
        this.logger.debug('slugArrayToSearchWith: ', slugArrayToSearchWith);
        if (slugArrayToSearchWith.length > 1) findBreadcrumbs(slugArrayToSearchWith);

        // Lastly add the home page
        breadcrumbs.unshift(this.urlService.getHomeCrumb());

        this.logger.debug('breadcrumbs: ', breadcrumbs);

        return breadcrumbs;
    }

    private parsePostList(response:ExternalResponse<WpPostsResponse>, categoryResponse:CategoryOrTagResponse, tagResponse:CategoryOrTagResponse):ExternalResponse<WpPostsResponse> {
        response.data.wpPosts.items.forEach(post => {
            if (post.categories && post.categories.length > 0) {
                post.categoriesPopulated = [];
                // @ts-ignore
                post.categories.forEach(categoryId => post.categoriesPopulated.push(categoryResponse.idHash[categoryId]));
            }
            if (post.tags && post.tags.length > 0) {
                post.tagsPopulated = [];
                // @ts-ignore
                post.tags.forEach(tagId => post.tagsPopulated.push(tagResponse.idHash[tagId]));
            }
            for (let i = 0; i < post?.acf?.components?.length; i++) {
                const comp = post.acf.components[i];
                if (comp.acf_fc_layout === ComponentTypesACF.blogPostImages) {
                    post.postImages = comp;
                }
                if (comp.acf_fc_layout === ComponentTypesACF.youtube) {
                    post.youtubeComp = comp;
                }
            }
        });
        return response;
    }

    private postProcessDynamicPage(components:ACFComponent[],
                                   rootPage:WordpressPage<RootConfigPageACF>,
                                   isLoggedIn:boolean,
                                   slug?:string,
                                   rootSlugs?:string[],
                                   fullSlugHash?:{ [key:string]:WordpressPage }) {
        try {

            if (components && components instanceof Array) {
                this.logger.test('Data before processing: ', components);

                // Filter and loop all at the same time
                let productComp:ProductListComponentACF;
                const now      = new Date();
                const format   = 'DD-MM-YYYY HH:mm:ss Z';
                const timezone = ' +02:00';

                components = components.filter((component:ACFComponent) => {
                    // This is a new property that was added to the template after the fact, therefore it's potentially not set on every implementation
                    if (component.display === undefined) component.display = DisplayTypesACF.always;

                    // Time filtering
                    const acfBase       = <ACFBase>component;
                    const showAfterDate = acfBase.show_after && acfBase.show_after !== '' ? dayjs(acfBase.show_after + timezone, format).toDate() : null;
                    const hideAfterDate = acfBase.hide_after && acfBase.hide_after !== '' ? dayjs(acfBase.hide_after + timezone, format).toDate() : null;
                    if (showAfterDate && now.getTime() < showAfterDate.getTime()) return false;
                    if (hideAfterDate && now.getTime() > hideAfterDate.getTime()) return false;

                    // Env filtering
                    if (acfBase.environments) {
                        if (this.getIsMobile() && acfBase.environments === WordpressConstants.DESKTOP) return false;
                        else if (this.getIsDesktop() && acfBase.environments === WordpressConstants.MOBILE) return false;
                    }

                    if (component.acf_fc_layout === ComponentTypesACF.bannerScroller) {

                        // Filter and loop all at the same time
                        component.bannerScrollerData = component.bannerScrollerData.filter(item => {
                            // Filter out the grids NOT for this environment
                            if (this.getIsMobile() && item.environments === WordpressConstants.DESKTOP) return false;
                            else if (this.getIsDesktop() && item.environments === WordpressConstants.MOBILE) return false;

                            // optional data fixing
                            item.youTubeVideoId = <any>item.youTubeVideoId === false || item.youTubeVideoId === '' ? null : item.youTubeVideoId;

                            // Remap urls to the CDN
                            item.imageXL = this.convertImageObject(item.imageXL);
                            item.imageSM = this.convertImageObject(item.imageSM);

                            item.mobileVideo  = this.convertToOptimUrl(item.mobileVideo, true);
                            item.desktopVideo = this.convertToOptimUrl(item.desktopVideo, true);

                            return true;
                        });

                        // Filter out comp if there are no images
                        if (component.bannerScrollerData.length === 0) return false;

                        // Ratio width check is a work around for pre populating these in backward compatible conversions
                        if (component.ratioWidth == null && component.ratioHeight == null) {

                            // Workaround to exclude component before merge to dev, can be removed once deployed to prod if needed
                            if (!rootPage || !rootPage.acf || !rootPage.acf.config) return false;

                            // Lets split out the sizes
                            const bannerConfig = rootPage.acf.config.find(c => c.acf_fc_layout === RootConfigTypesACF.bannerConfig);
                            const size         = bannerConfig ? bannerConfig.bannerSizes[Number((<BannerScrollerComponentACF>component).bannerScrollerSize)] : null;
                            if (!size) throw new Error('Unable to locate config for the banner size');

                            if (this.getIsDesktop()) {
                                component.ratioWidth  = Number(size.desktopWidth);
                                component.ratioHeight = Number(size.desktopHeight);
                            }
                            else {
                                component.ratioWidth  = Number(size.mobileWidth);
                                component.ratioHeight = Number(size.mobileHeight);
                            }
                        }
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.contentItems) {

                        // Filter and loop all at the same time
                        component.items = component.items.filter(item => {

                            // optional data fixing
                            item.imageXL = <any>item.imageXL === false ? null : item.imageXL;
                            item.imageSM = <any>item.imageSM === false ? null : item.imageSM;

                            // Remap urls to the CDN
                            item.imageXL = this.convertImageObject(item.imageXL);
                            item.imageSM = this.convertImageObject(item.imageSM);

                            return true;
                        });
                    }
                    if (component.acf_fc_layout === ComponentTypesACF.bannerScrollerWithText) {
                        // Filter and loop all at the same time
                        component.bannerScrollerData = component.bannerScrollerData.filter(item => {
                            // Filter out the grids NOT for this environment
                            if (this.getIsMobile() && item.environments === WordpressConstants.DESKTOP) return false;
                            else if (this.getIsDesktop() && item.environments === WordpressConstants.MOBILE) return false;

                            // optional data fixing
                            item.imageXL = this.convertImageObject(item.imageXL);
                            item.imageSM = this.convertImageObject(item.imageSM);

                            return true;
                        });

                        // Filter out comp if there are no images
                        if (component.bannerScrollerData.length === 0) return false;

                        // Ratio width check is a work around for pre populating these in backward compatible conversions
                        if (component.ratioWidth == null && component.ratioHeight == null) {

                            // Workaround to exclude component before merge to dev, can be removed once deployed to prod if needed
                            if (!rootPage || !rootPage.acf || !rootPage.acf.config) return false;

                            // Lets split out the sizes
                            const bannerConfig = rootPage.acf.config.find(c => c.acf_fc_layout === RootConfigTypesACF.bannerConfig);
                            const size         = bannerConfig ? bannerConfig.bannerSizes[Number((<BannerScrollerWithTextComponentACF>component).bannerScrollerSize)] : null;
                            if (!size) throw new Error('Unable to locate config for the banner size');

                            if (this.getIsDesktop()) {
                                component.ratioWidth  = Number(size.desktopWidth);
                                component.ratioHeight = Number(size.desktopHeight);
                            }
                            else {
                                component.ratioWidth  = Number(size.mobileWidth);
                                component.ratioHeight = Number(size.mobileHeight);
                            }
                        }
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.complexGrid) {
                        // Filter out the grids NOT for this environment
                        if (!component.rows || component.rows.length === 0) return false;

                        // Remap urls to the CDN
                        component.rows.forEach(row => {
                            if (row.cells) {
                                row.cells.forEach(cell => {
                                    cell.image = this.convertImageObject(cell.image);
                                });
                            }
                        });
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.dynamicGrid) {
                        if (!component.cells || component.cells.length === 0) return false;

                        component.cells.forEach(cell => {
                            cell.components = this.postProcessDynamicPage(cell.components, rootPage, isLoggedIn, slug, rootSlugs, fullSlugHash);
                        });
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.image) {
                        component.image = this.convertImageObject(component.image);
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.accordion) {
                        component.rows.forEach(row => {
                            row.components = this.postProcessDynamicPage(row.components, rootPage, isLoggedIn, slug, rootSlugs, fullSlugHash);
                        });
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.video) {
                        component.video = this.convertToOptimUrl(component.video, true);
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.wysiwyg) {
                        const cdnBaseUrl        = environment.config.api.cdnBaseUrl;
                        const pageCategoryMatch = RegexUtils.escape(cdnBaseUrl) + '(\/wordpress\/)[^]+?(\/media\/)[^]+?\.(gif|jpg|jpeg|tiff|png)';
                        component.wysiwyg       = component.wysiwyg.replace(
                            new RegExp(pageCategoryMatch, 'g'),
                            (imgUrl) => this.convertToOptimUrl(imgUrl)
                        );
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.catalogues) {
                        component.catalogues.forEach(catalogue => {
                            catalogue.image = this.convertImageObject(catalogue.image);
                        });
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.productList) {
                        productComp = component;
                        return false;
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.onPageMenuPlacement) {
                        const parentPage = this.getParentPage(slug, rootSlugs, fullSlugHash);

                        if (parentPage) {
                            const onPageMenuConfig = this.findOnPageMenuConfig(parentPage);
                            if (this.getIsMobile() && onPageMenuConfig.environments === WordpressConstants.DESKTOP) return false;
                            else if (this.getIsDesktop() && onPageMenuConfig.environments === WordpressConstants.MOBILE) return false;
                            component.onPageMenuConfig = (this.componentShouldDisplaySync(onPageMenuConfig, ComponentTypesACF.onPageMenuConfig, isLoggedIn)) ? onPageMenuConfig : null;
                        }
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.reusableDefinitionPlacement) {
                        const parentPage = this.getParentPage(slug, rootSlugs, fullSlugHash);

                        if (parentPage) {
                            const reusableDefinition = this.findReusableDefinition(parentPage, component.reusableDefinitionId);
                            if (this.getIsMobile() && reusableDefinition.environments === WordpressConstants.DESKTOP) return false;
                            else if (this.getIsDesktop() && reusableDefinition.environments === WordpressConstants.MOBILE) return false;
                            component.components = (this.componentShouldDisplaySync(reusableDefinition, ComponentTypesACF.reusableDefinition, isLoggedIn)) ? reusableDefinition.components : null;
                        }
                    }
                    else if (component.acf_fc_layout === ComponentTypesACF.blogPostImages) {
                        // Didn't convert these to image objects as its a bigger change to graphql too and the blog is not in use
                        // If blogs are implemented these should be changed over to the image object and include width and height everywhere
                        component.desktopPostHeader  = this.convertToOptimUrl(component.desktopPostHeader);
                        component.desktopFeatured    = this.convertToOptimUrl(component.desktopFeatured);
                        component.desktopPreview     = this.convertToOptimUrl(component.desktopPreview);
                        component.desktopThumbnail   = this.convertToOptimUrl(component.desktopThumbnail);
                        component.desktopPromotional = this.convertToOptimUrl(component.desktopPromotional);
                        component.mobilePostHeader   = this.convertToOptimUrl(component.mobilePostHeader);
                        component.mobileFeatured     = this.convertToOptimUrl(component.mobileFeatured);
                        component.mobilePreview      = this.convertToOptimUrl(component.mobilePreview);
                        component.mobileThumbnail    = this.convertToOptimUrl(component.mobileThumbnail);
                        component.mobilePromotional  = this.convertToOptimUrl(component.mobilePromotional);
                    }
                    return true;
                });

                // Move the product list component to the end if there is one
                // This is not possible in the cms so we are doing it here
                if (productComp) components.push(productComp);

                this.logger.test('Data after processing: ', components);
            }
        }
        catch (err) {
            this.logger.error('Error post processing', err);
            throw err;
        }
        return components;
    }

    private convertImageObject(image:WordpressImage):WordpressImage {
        if (image != null) {
            if (image.url) image.url = this.convertToOptimUrl(image.url);
            return image;
        }
        return null;
    }

    private convertToOptimUrl(sourceUrl:string, isVideo:boolean = false) {
        if (sourceUrl != null && typeof sourceUrl === 'string') {
            const parsedUrl:FacadeParsedUrl = FacadeUrlUtil.parse(sourceUrl);

            if (!environment.config.api.imagekitWordpressEnabled) {
                if (parsedUrl.ext.toLowerCase() !== 'gif') {
                    sourceUrl = `${parsedUrl.origin}${parsedUrl.dir}/${CloudFunctionConst.OPTIM_IMG_PREFIX}${parsedUrl.name}.`;
                    sourceUrl += (WebPManager.supported) ? 'webp' : 'jpg';
                }
            }
            else {
                const path                 = parsedUrl.dir.replace(/.*\/media/, 'media');
                const imageTransformations = this.urlService.getImageTransformations({pixelRatio: this.windowRef.getDevicePixelRatio()});
                if (isVideo) {
                    sourceUrl = `${environment.config.api.imagekitWordpressMediaUrl}/${path}/${parsedUrl.name}.${parsedUrl.ext}`;
                }
                else {
                    sourceUrl = `${environment.config.api.imagekitWordpressMediaUrl}/${path}/${parsedUrl.name}.${parsedUrl.ext}?${imageTransformations}`;
                }
            }
        }
        return sourceUrl;
    }

    private hasRenderableComponents(components:ACFComponent[]) {
        if (components == null) return false;

        const nonRenderableCompHash = ArrayUtil.straightHash(NonRenderableComponents, comp => comp);
        let renderableCompFound     = false;
        components.forEach((component) => {
            const compType = component.acf_fc_layout;
            if (!nonRenderableCompHash[compType]) renderableCompFound = true;
        });
        return renderableCompFound;
    }

    private getParentPage(slug:string, rootSlugs:string[], fullSlugHash:{ [key:string]:WordpressPage }) {
        const slugArray = slug.split('/');
        slugArray.pop(); // Remove the last item, so we're just dealing with the parent page

        let found:boolean;
        let currPage;

        // Loop through all locale roots to find a parent page that has menu config
        for (let i = 0; i < rootSlugs.length; i++) {
            const root = '/' + rootSlugs[i] + '/';
            const full = root + slugArray.join('/') + '/';
            currPage   = fullSlugHash[full];
            if (currPage && currPage.template !== '' && currPage.acf && currPage.acf.components) {
                const menuConfig = this.findOnPageMenuConfig(currPage);
                if (menuConfig) found = true;
            }
            if (found) break;
        }

        if (found) return currPage;
    }

    private findOnPageMenuConfig(page:WordpressPage<DynamicPageACF>):OnPageMenuConfigComponentACF {
        for (let i = 0; i < page.acf.components.length; i++) {
            const currComp = page.acf.components[i];
            if (currComp.acf_fc_layout === ComponentTypesACF.onPageMenuConfig) {
                return currComp;
            }
        }
    }

    private findReusableDefinition(page:WordpressPage<DynamicPageACF>, definitionId:string):ReusableDefinitionACF {
        for (let i = 0; i < page.acf.components.length; i++) {
            const currComp = page.acf.components[i];
            if (currComp.acf_fc_layout === ComponentTypesACF.reusableDefinition && currComp.reusableDefinitionId === definitionId) {
                return currComp;
            }
        }
    }

    /*
    Auth
    --------------------------------------------
    */
    public getDraftHeaders(params:any) {
        const headers = {};
        if (this.getIsLoggedIn()) {
            params.status            = 'publish,future,draft,pending,private';
            headers['Authorization'] = `Bearer ${this.getJWTToken()}`;
        }
        return headers;
    }

    public getIsLoggedIn() {
        return this.getJWTToken() != null;
    }

    public getJWTToken() {
        // Not null as it can be 3, null, undefined (not fetched), populated
        if (this.jwt === undefined) {
            this.jwt = this.cookieService.get('wordpressJWT');
            if (this.jwt === undefined || this.jwt === '') this.jwt = null;
        }
        return this.jwt;
    }

    public setJWTToken(value:string) {
        this.jwt = value;
        if (value == null) {
            this.cookieService.delete('wordpressJWT', '/');
        }
        else {
            this.cookieService.set('wordpressJWT', value, 1, '/', null, true, 'Strict');
        }
    }

    public login(username:string, password:string) {
        const variables = {
            'username': username,
            'password': password
        };

        return this.apollo.mutate({
            mutation : WP_GET_TOKEN,
            variables: variables
        })
            .pipe(
                tap((response:ExternalResponse<WpTokenResponse>) => {
                    if (response.data.wpToken.token) {
                        this.setJWTToken(response.data.wpToken.token);
                    }
                    else {
                        this.setJWTToken(null);
                    }
                })
            );
    }

    public logout() {
        this.setJWTToken(null);
    }


    /*
    General API methods
    --------------------------------------------
    */
    public getCDNUrl() {
        return environment.config.api.cdnBaseUrl;
    }

    public getRootPageSlugs():string[] {
        return environment.config.api.wordpressRootPages;
    }

    public getIsDesktop() {
        return !this.getIsMobile();
    }

    public getIsMobile() {
        // Lets determine desktop or mobile with the override
        let isMobile = this.facadePlatform.isMobile;
        const api    = environment.config.api;
        if (api.wordpressTypeOverride != null) {
            isMobile = (api.wordpressTypeOverride === FacadeAppDefinitionType.mobile || api.wordpressTypeOverride === FacadeAppDefinitionType.mobileapp);
        }
        return isMobile;
    }

    private unlockObject(obj:any) {
        return JSON.parse(JSON.stringify(obj));
    }

    public getName() {
        return 'WordpressLogicService';
    }
}
