import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Renderer2} from '@angular/core';

@Component({
    selector       : 'accordion-body',
    template       : '<div class="clearfix inner"><ng-content></ng-content></div>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacadeAccordionBodyComponent {

    @HostBinding('class')
    class = 'closed';

    _height:string;

    private _isOpen = false;

    constructor(public el:ElementRef, public changeDetectorRef:ChangeDetectorRef, public renderer2:Renderer2) {
        this.height = '0';
    }

    public setOpen(value:boolean, animate:boolean) {
        this.class = value ? 'open' : 'closed';
        this._isOpen = value;

        if (value) {
            let height = this.el.nativeElement.scrollHeight;
            // work around in case browser doesn't support scrollHeight
            if (!animate || height === 0 || height == null || isNaN(Number(height))) {
                //console.warn('scroll height was invalid: ', height);
                height = 'auto';
            }
            else {
                height += 'px';
            }
            this.height = height;

            // Set back to auto after the animation time so we can adjust to dynamic content heights
            setTimeout(() => {
                if (this._isOpen) this.height = 'auto';
            }, 300);
        }
        else {
            // Cater for auto, we want to make sure there is an
            // explicit height on the element otherwise the animation won't render
            if (this.height === 'auto') {
                this.setOpen(true, true);
                setTimeout(() => this.setOpen(false, true), 50);
            }
            else {
                this.height = '0';
            }
        }
    }

    public refreshHeight() {
        this.setOpen(this._isOpen, false);
    }

    set height(value:string) {
        this._height = value;
        this.renderer2.setStyle(this.el.nativeElement, 'height', value);
    }

    get height():string {
        return this._height;
    }
}
