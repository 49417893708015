// Implemented as an object so codes can be back referenced as their keys for messages
export const ErrorCodes = {
    // Global
    ERR_SYSTEM_ERROR                      : 1006,
    ERR_PROFILE_USER_ALREADY_EXISTS       : 1010,
    ERR_DISTINCT_PROFILE_VALUE_ALREADY_SET: 1008,
    ERR_INVALID_PARAMETER                 : 1002,
    ERR_INVALID_REQUEST                   : 1003,
    ERR_UNAUTHORISED_REQUEST              : 1004,
    ERR_NO_SUCH_PROFILE_TYPE              : 1007,
    ERR_MAX_COUNT_OF_PROFILE_TYPE_REACHED : 1009,
    ERR_INVALID_USER_ID                   : 1012,
    ERR_NO_SUCH_PARENT_PROFILE_TYPE       : 1016,
    ERR_NO_SUCH_PARENT_PROFILE_VALUE      : 1017,
    ERR_INVALID_GOOGLE_ADDRESS            : 1018,
    ERR_KONG_CRITICAL                     : 2001,
    // Register
    ERR_INVALID_CREDENTIAL  : 4001,
    ERR_DUPLICATE_CREDENTIAL: 4002,
    AUTH_SERVER_DISABLED    : 4040,
    // OTP, forgot password
    INVALID_USERNAME            : 4003,
    INVALID_ONE_TIME_PIN        : 4004,
    ERR_CREDENTIAL_NOT_FOUND    : 4005,
    OTP_EXPIRED_OR_LIMIT_REACHED: 4006,
    PROFILE_NOT_VERIFIED        : 4007,
    SMS_FAILURE                 : 5001,

    // error getting a one profile auth token, action try again. "Error connecting to +more. Please try again."
    ONE_PROFILE_TOKEN_ERROR: 4021,
    // Unknown error on the one profile service. "Error with +more link/create, please try again later. Alternatively, contact the call centre if this continues."
    PLUS_MORE_API_ERROR   : 4011,
    PLUS_MORE_CREATE_ERROR: 4030,

    // Magento duplicate id. "A customer with the same ID number already exists on the Ackermans website. Please check what you entered and try again. Alternatively, contact the call centre if you feel this is incorrect."
    PLUS_MORE_DUPLICATE_ID: 3003,

    // Specific to plus more API
    // "You have entered an invalid ID number. Please check what you entered and try again."
    PLUS_MORE_INVALID_ID_API: 4031,
    // "A customer with the same cellphone number already exists on +more. Please check what you entered and try again. Alternatively, contact the call centre if you feel this is incorrect."
    PLUS_MORE_DUPLICATE_CONTACT_API: 4032,
    // "Your +more account has already been linked"
    PLUS_MORE_ALREADY_LINKED: 4033,


    // Magento: Customer
    ERR_MAGENTO_EMAIL_DUPLICATE   : 3001,
    ERR_MAGENTO_DUPLICATE_CUSTOMER: 3002,
    // Magento: Cart
    ERR_MAGENTO_INSUFFICIENT_STOCK_WHEN_PLACING_ORDER: 5010,
    ERR_MAGENTO_INCORRECT_COUPON_CODE                : 5020,
    ERR_MAGENTO_INVALID_CART_ITEM_WITH_ZERO_PRICE    : 5030,
    ERR_MAGENTO_SHIPPING_ADDRESS_NOT_SUPPORTED       : 6000,
    ERR_MAGENTO_SHIPPING_API_ERROR                   : 6001,
    ERR_MAGENTO_SHIPPING_API_NOT_AVAILABLE           : 6002
};

// Field names to map error cases to
export const ErrorFieldNames = {
    REGISTER_MSISDN: 'msisdn'
};

export const ErrorCodeBackReference = {};
Object.keys(ErrorCodes).forEach(key => ErrorCodeBackReference[ErrorCodes[key]] = key);
