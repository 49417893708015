import {gql} from 'apollo-angular';

export const GET_AI_RECOMMENDATIONS_CACHE = true;

export const GET_AI_RECOMMENDATIONS = gql`
    query aiRecommendations($sku:String!, $recommendationType:AIRecommendationType!) {
        aiRecommendations(sku:$sku, recommendationType:$recommendationType) {
            response
        }
    }
`;
