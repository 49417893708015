import {gql} from 'apollo-angular';

export const GET_PRODUCT_LABELS_CACHE = true;

export const GET_PRODUCT_LABELS = gql`
    query amLabelProvider($productIds: [Int], $mode: AmLabelMode) {
        amLabelProvider(productIds: $productIds, mode: $mode) {
            items {
                position
                product_id
                image
                is_visible
                label_id
                position
                size
                style
                txt
                redirect_url
                alt_tag
            }
        }
    }
`;
