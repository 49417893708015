import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {from, throwError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {RuntimeErrorHandlerService} from '../../error-handling/runtime-error-handler.service';
import {FacadePlatform} from '../../shared/facade-platform';
import {ExternalResponse} from '../external-response';
import {
    FirebaseWrapperService,
    IFirebaseError,
    IFirebaseUploadError
} from '../firebase-wrapper/firebase-wrapper.service';
import {UrlService} from '../../shared/url.service';
import {GTMActions} from '../google/google-tag-manager/gtm.actions';
import {AbstractFacadeService} from '../abstract-facade.service';

@Injectable()
export class FirebaseLogicService extends AbstractFacadeService {

    public runtimeErrorHandlerService:RuntimeErrorHandlerService;

    constructor(public injector:Injector,
                public firebaseWrapperService:FirebaseWrapperService,
                public facadePlatform:FacadePlatform,
                public translate:TranslateService,
                public urlService:UrlService) {
        super(injector);
        this.runtimeErrorHandlerService = <RuntimeErrorHandlerService>this.injector.get(ErrorHandler);
    }

    public uploadFile(path:string, file:File) {
        return from(this.firebaseWrapperService.initForUpload())
            .pipe(
                mergeMap(() => {
                    return this.firebaseWrapperService.signIn().pipe(
                        catchError((response:ExternalResponse<IFirebaseError>) => {
                            switch (response.error.errorCode) {
                                case 'auth/invalid-email':              // Thrown if the email address is not valid.
                                case 'auth/user-disabled':              // Thrown if the user account has been disabled by an administrator. Accounts can be enabled or disabled in the Firebase Console, the Auth section and Users subsection.
                                case 'auth/user-not-found':             // Thrown if there is no user corresponding to the given email.
                                case 'auth/wrong-password':             // Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
                                case 'auth/app-deleted':                // Thrown if the instance of FirebaseApp has been deleted.
                                case 'auth/app-not-authorized':         // Thrown if the app identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key. Review your key configuration in the Google API console.
                                case 'auth/argument-error':             // Thrown if a method is called with incorrect arguments.
                                case 'auth/invalid-api-key':            // Thrown if the provided API key is invalid. Please check that you have copied it correctly from the Firebase Console.
                                case 'auth/invalid-user-token':         // Thrown if the user's credential is no longer valid. The user must sign in again.
                                case 'auth/operation-not-allowed':      // Thrown if you have not enabled the provider in the Firebase Console. Go to the Firebase Console for your project, in the Auth section and the Sign in Method tab and configure the provider.
                                case 'auth/requires-recent-login':      // Thrown if the user's last sign-in time does not meet the security threshold. Use firebase.User#reauthenticateWithCredential to resolve. This does not apply if the user is anonymous.
                                case 'auth/too-many-requests':          // Thrown if requests are blocked from a device due to unusual activity. Trying again after some delay would unblock.
                                case 'auth/unauthorized-domain':        // Thrown if the app domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.
                                case 'auth/user-token-expired':         // Thrown if the user's credential has expired. This could also be thrown if a user has been deleted. Prompting the user to sign in again should resolve this for either case.
                                case 'auth/web-storage-unsupported':    // Thrown if the browser does not support web storage or if the user disables them.
                                    response.error.userMsg = this.translate.instant('error.firebase.signIn.infrastructureError');
                                    break;
                                case 'auth/network-request-failed':     // Thrown if a network error (such as timeout, interrupted connection or unreachable host) has occurred.
                                    response.error.userMsg = this.translate.instant('error.firebase.signIn.networkError');
                                    break;
                                default:
                                    response.error.errorMsg = 'No error returned from firebase';
                                    response.error.userMsg  = this.translate.instant('error.firebase.signIn.unknownError');
                            }
                            this.logger.error('FirebaseUpload Error. error: ', response.error.error);
                            this.logger.error('FirebaseUpload Error. errorCode: ', response.error.errorCode);
                            this.logger.error('FirebaseUpload Error. errorMsg: ', response.error.errorMsg);
                            this.logger.error('FirebaseUpload Error. userMsg: ', response.error.userMsg);
                            this.store.dispatch(new GTMActions.TrackEventFirebaseUploadError(`code: ${response.error.errorCode} msg: ${response.error.errorMsg}`));

                            return throwError(response);
                        })
                    );
                }),

                mergeMap(() => {
                    return this.firebaseWrapperService.uploadFile(path, file).pipe(
                        catchError((response:ExternalResponse<IFirebaseUploadError>) => {
                            console.log('Caught firebase wrapper error: ', response);
                            switch (response.error.errorCode) {
                                case 'storage/unknown':             // An unknown error occurred.
                                    response.error.userMsg = this.translate.instant('error.firebase.upload.unknownError');
                                    break;
                                case 'storage/object_not_found':    // No object exists at the desired reference.
                                case 'storage/object-not-found':
                                case 'storage/bucket_not_found':    // No bucket is configured for Cloud Storage
                                case 'storage/bucket-not-found':
                                case 'storage/project_not_found':   // No project is configured for Cloud Storage
                                case 'storage/project-not-found':
                                case 'storage/quota_exceeded':      // Quota on your Cloud Storage bucket has been exceeded. If you're on the free tier, upgrade to a paid plan. If you're on a paid plan, reach out to Firebase support.
                                case 'storage/quota-exceeded':
                                case 'storage/unauthenticated':     // User is unauthenticated, please authenticate and try again.
                                case 'storage/unauthorized':        // User is not authorized to perform the desired action, check your security rules to ensure they are correct.
                                case 'storage/invalid_event_name':  // Invalid event name provided. Must be one of [`running`, `progress`, `pause`]
                                case 'storage/invalid-event-name':
                                case 'storage/invalid_url':         // Invalid URL provided to refFromURL(). Must be of the form: gs://bucket/object or https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=<TOKEN>
                                case 'storage/invalid-url':
                                case 'storage/invalid-argument':    // The argument passed to put() must be `File`, `Blob`, or `UInt8` Array. The argument passed to putString() must be a raw, `Base64`, or `Base64URL` string.
                                case 'storage/no_default_bucket':   // No bucket has been set in your config's storageBucket property.
                                case 'storage/no-default-bucket':
                                    response.error.userMsg = this.translate.instant('error.firebase.upload.infrastructureError');
                                    break;
                                case 'storage/retry_limit_exceeded': // The maximum time limit on an operation (upload, download, delete, etc.) has been exceeded. Try uploading again.
                                case 'storage/retry-limit-exceeded': // The maximum time limit on an operation (upload, download, delete, etc.) has been exceeded. Try uploading again.
                                    response.error.userMsg = this.translate.instant('error.firebase.upload.retry_limit_exceeded');
                                    break;
                                case 'storage/invalid_checksum':    // File on the client does not match the checksum of the file received by the server. Try uploading again.
                                case 'storage/invalid-checksum':
                                    response.error.userMsg = this.translate.instant('error.firebase.upload.invalid_checksum');
                                    break;
                                case 'storage/canceled':            // User canceled the operation.
                                    response.error.userMsg = this.translate.instant('error.firebase.upload.canceled');
                                    break;
                                case 'storage/cannot_slice_blob':   // Commonly occurs when the local file has changed (deleted, saved again, etc.). Try uploading again after verifying that the file hasn't changed.
                                case 'storage/cannot-slice-blob':
                                    response.error.userMsg = this.translate.instant('error.firebase.upload.cannot_slice_blob');
                                    break;
                                case 'storage/server_wrong_file_size': // File on the client does not match the size of the file received by the server. Try uploading again.
                                case 'storage/server-file-wrong-size':
                                    response.error.userMsg = this.translate.instant('error.firebase.upload.server_wrong_file_size');
                                    break;
                                default:
                                    response.error.errorMsg = 'No error returned from firebase';
                                    response.error.userMsg  = this.translate.instant('error.firebase.upload.unknownError');
                            }
                            this.logger.error('FirebaseUpload Error. error: ', response.error.error);
                            this.logger.error('FirebaseUpload Error. errorCode: ', response.error.errorCode);
                            this.logger.error('FirebaseUpload Error. errorMsg: ', response.error.errorMsg);
                            this.logger.error('FirebaseUpload Error. userMsg: ', response.error.userMsg);
                            this.store.dispatch(new GTMActions.TrackEventFirebaseUploadError(`code: ${response.error.errorCode} msg: ${response.error.errorMsg}`));

                            return throwError(response);
                        })
                    );
                })
            );
    }

    getName() {
        return 'FirebaseLogicService';
    }
}
