// NB: Do not change these string values, you can change the names if you must but recaptcha has historical data on them
export enum RecaptchaAction {
    // Validated scores
    REGISTER                     = 'REGISTER',
    REGISTER_REQUEST_ANOTHER_PIN = 'REGISTER_REQUEST_ANOTHER_PIN',
    REQUEST_PASSWORD_RESET       = 'REQUEST_PASSWORD_RESET',
    REGISTER_GUEST_CONVERSION    = 'REGISTER_GUEST_CONVERSION',
    GUEST_CREATE_SESSION         = 'GUEST_CREATE_SESSION',
    GUEST_RESEND_OTP             = 'GUEST_RESEND_OTP',

    // Fire and forget events
    AUTH_LOGIN                   = 'AUTH_LOGIN', // This one has data appended to it for the auth type
    AUTH_LINK_ID                 = 'AUTH_LINK_ID',
    AUTH_VALIDATE_OTP            = 'AUTH_VALIDATE_OTP',

    CART_ADD                     = 'CART_ADD',
    CART_REMOVE                  = 'CART_REMOVE',
    CART_UPDATE                  = 'CART_UPDATE',

    CHECKOUT_CART_PROCEED        = 'CHECKOUT_CART_PROCEED',
    CHECKOUT_AS_GUEST            = 'CHECKOUT_AS_GUEST',
    CHECKOUT_SHIPPING_PROCEED    = 'CHECKOUT_SHIPPING_PROCEED',
    CHECKOUT_PAYMENT_PROCEED     = 'CHECKOUT_PAYMENT_PROCEED',
    CHECKOUT_REINSTATE_CART      = 'CHECKOUT_REINSTATE_CART',
    CHECKOUT_GUEST_VALIDATE_OTP  = 'CHECKOUT_GUEST_VALIDATE_OTP',

    CONTACT_US                   = 'CONTACT_US',
    CELLULAR_CONTRACTS           = 'CELLULAR_CONTRACTS',
    SOCIAL_INVESTMENT            = 'SOCIAL_INVESTMENT',
    HANDSET_INSURANCE            = 'HANDSET_INSURANCE',

}
