import {SafeHtmlPipe} from './shared/ui/pipes/safe-html.pipe';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FacadeAccordionModule} from './accordion/facade-accordion.module';
import {AppMessagesComponent} from './app-messages/app-messages.component';
import {ComponentContainerDirective} from './custom-pages/component-container.directive';
import {CustomPageRenderService} from './custom-pages/custom-page-render.service';
import {FirebaseWrapperService} from './external-data/firebase-wrapper/firebase-wrapper.service';
import {FirebaseLogicService} from './external-data/firebase/firebase-logic.service';
import {GenericFormApiWrapperService} from './external-data/generic-form-api/generic-form-api-wrapper.service';
import {MagentoLogicService} from './external-data/magento/magento-logic.service';
import {SearchService} from './external-data/magento/search/search.service';
import {CustomerCommunicationService} from './external-data/newsletter/customer-communication.service';
import {WordpressLogicService} from './external-data/wordpress/wordpress-logic.service';
import {FacadeValidators} from './forms/facade-validators';
import {FacadeIconModule} from './icon/facade-icon.module';
import {ImplementationDataService} from './implementation-config/implementation-data.service';
import {GlobalSelectors} from './ngxs/global.selectors';
import {SEOService} from './seo/seo-service';
import {ComponentDelegate} from './shared/component-delegate';
import {CrossPlatformService} from './shared/cross-platform.service';
import {Logger} from './shared/logger';
import {AddThisInlineComponent} from './shared/ui/add-this-inline.component';
import {BodyRef} from './shared/ui/body-ref';
import {DocumentRef} from './shared/ui/document-ref';
import {DomPositionDirective} from './shared/ui/dom-position.directive';
import {FakeLinkDirective} from './shared/ui/fake-link.directive';
import {LocalVariablesDirective} from './shared/ui/local-variables.directive';
import {CleanNullPipe} from './shared/ui/pipes/clean-null.pipe';
import {PricePipe} from './shared/ui/pipes/price.pipe';
import {StripHtmlPipe} from './shared/ui/pipes/strip-html.pipe';
import {WindowRef} from './shared/ui/window-ref';
import {UrlService} from './shared/url.service';
import {Sanitize} from './shared/util/sanitize';
import {ViewStateSelector} from './shared/view-state/view-state.selector';
import {InjectComponent} from './inject-component/inject-component';
import {InjectComponentService} from './inject-component/inject-component.service';
import {YoutubeScriptLoaderService} from './external-data/youtube/youtube-script-loader.service';
import {SafePipe} from './shared/ui/pipes/safe.pipe';
import {ApiErrorHelper} from './error-handling/api-error-helper';
import {BlockWhenNotAuthenticatedGuard} from './auth/auth-guards/block-when-not-authenticated.guard';
import {BlockedWhenAuthenticatedGuard} from './auth/auth-guards/blocked-when-authenticated.guard';
import {NetworkLoadMonitor} from './external-data/interceptors/network-load-monitor.service';
import {CustomerLogicService} from './external-data/customer/customer-logic.service';
import {VisibilityService} from './shared/visibility.service';
import {LazyBgImgDirective} from './shared/ui/lazy-bg-img/lazy-bg-img.directive';
import {LazyIframeDirective} from './shared/ui/lazy-iframe/lazy-iframe.directive';
import {GoogleMapsApiService} from './external-data/google/google-maps/google-maps-api.service';
import {CloudFunctionsWrapperService} from './external-data/cloud-functions/cloud-functions-wrapper.service';
import {CategorySearchService} from './external-data/magento/search/category-search.service';
import {CookieService} from 'ngx-cookie-service';
import {AnchorTagService} from './shared/anchor-tag.service';
import {NavigatorRef} from './shared/ui/navigator-ref';
import {RunScriptsDirective} from './shared/ui/run-scripts.directive';
import {ProductsService} from './external-data/magento/elasticsearch/products.service';
import {IsPopulatedPipe} from './shared/ui/pipes/is-populated-pipe';
import {FooterMenuService} from './menu/footer-menu.service';
import {HitCounterService} from './external-data/wordpress/hit-counter.service';
import {WordpressSearchService} from './external-data/wordpress/wordpress-search.service';
import {HttpLink} from 'apollo-angular/http';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {createApollo} from './external-data/apollo/apollo-init';
import {ExtendedApolloService} from './external-data/extended-apollo/extended-apollo.service';
import {InterceptorService} from './external-data/interceptors/interceptor.service';
import {EXTENDED_APOLLO_POST_REQUEST_HOOK} from './external-data/extended-apollo/extended-apollo-hook';
import {SharedCartService} from './cart-shared/shared-cart.service';
import {AuthService} from './external-data/auth/auth.service';
import {SpinnerComponent} from './shared/ui/spinner/spinner.component';
import {SharedPhysicalStoreService} from './external-data/physical-store/shared-physical-store.service';
import {WordpressDynamicSettingsService} from './external-data/wordpress/wordpress-dynamic-settings.service';
import {
    GoogleAnalytics4BuilderService
} from './external-data/google/google-tag-manager/google-analytics4-builder.service';
import {PhotoSwipeLinkDirective} from './shared/ui/photoswipe/photo-swipe-link.directive';
import {PhotoSwipeService} from './shared/ui/photoswipe/photo-swipe.service';
import {CountryService} from './shared/country.service';
import {StorageService} from './shared/ui/storage/storage.service';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {environment} from './implementation/environment';
import {RecaptchaService} from './external-data/recaptcha/recaptcha-service';
import {FacadeImgDirective} from './shared/ui/facade-img.directive';

const uiElements = [
    // Tricky to move
    PhotoSwipeLinkDirective,

    DomPositionDirective,
    ComponentContainerDirective,
    FakeLinkDirective,

    FacadeImgDirective,
    AddThisInlineComponent,
    LocalVariablesDirective,

    PricePipe,
    StripHtmlPipe,
    CleanNullPipe,
    IsPopulatedPipe,
    SafePipe,
    SafeHtmlPipe,
    RunScriptsDirective,
    AppMessagesComponent,
    LazyBgImgDirective,
    LazyIframeDirective,
    SpinnerComponent,

    InjectComponent
];


@NgModule({
    imports     : [
        CommonModule,
        TranslateModule.forChild(),
        FacadeAccordionModule,
        FacadeIconModule,
        ApolloModule,
        RecaptchaV3Module
    ],
    declarations: uiElements,
    exports     : [
        FacadeAccordionModule,
        FacadeIconModule,
        ...uiElements,
    ],
    providers   : [/*Do not add providers here as multiple will be created, add in forRoot*/]
})
export class CrossPlatformModule {

    static forRoot():ModuleWithProviders<CrossPlatformModule> {
        return {
            ngModule : CrossPlatformModule,
            providers: [
                CrossPlatformService,
                FirebaseWrapperService,
                FirebaseLogicService,
                MagentoLogicService,
                PhotoSwipeService,
                ProductsService,
                SharedCartService,
                SharedPhysicalStoreService,

                CustomerCommunicationService,
                SearchService,
                CategorySearchService,
                WordpressLogicService,
                WordpressSearchService,
                WordpressDynamicSettingsService,
                HitCounterService,
                GlobalSelectors,
                ViewStateSelector,
                BlockWhenNotAuthenticatedGuard,
                BlockedWhenAuthenticatedGuard,
                //PwaService,
                GenericFormApiWrapperService,
                ImplementationDataService,
                CustomerLogicService,
                ApiErrorHelper,
                // Goooogs
                SEOService,
                Logger,
                WindowRef,
                BodyRef,
                DocumentRef,
                NavigatorRef,
                FooterMenuService,
                CustomPageRenderService,
                Sanitize,
                UrlService,
                VisibilityService,
                AnchorTagService,
                ExtendedApolloService,
                ComponentDelegate,
                FacadeValidators,
                InjectComponentService,
                YoutubeScriptLoaderService,
                // Unfortunately logic defs dont support lazy loading so we can't separate these:
                GoogleMapsApiService,
                CloudFunctionsWrapperService,
                CookieService,
                AuthService,
                NetworkLoadMonitor,
                {provide: EXTENDED_APOLLO_POST_REQUEST_HOOK, useExisting: NetworkLoadMonitor, multi: true},
                InterceptorService,
                {provide: APOLLO_OPTIONS, useFactory: createApollo, deps: [HttpLink, InterceptorService, ImplementationDataService]},
                GoogleAnalytics4BuilderService,
                CountryService,
                StorageService,
                {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.config.api.recaptcha},
                RecaptchaService, // Custom
            ]
        };
    }
}
