import {getActionTypeFromInstance} from '@ngxs/store';

export class ClearStorage {
    static readonly type = '[Clear Storage] Clear Storage';
}

let initialState = null;

// This is am NgXs plugin that gets passed the global state object. Useful for wiping all the state.
export function runtimeErrorPlugin(state, action, next) {
    // Note: its important that we don't reset the state to an empty object as that causes runtime
    // lookup errors on the selectors. We need to store and then reset to the initial state
    if (initialState == null) {
        initialState = state;
    }
    if (getActionTypeFromInstance(action) === ClearStorage.type) {
        state = initialState;
    }
    return next(state, action);

}