import {AfterViewInit, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {AbstractFacadeComponent} from './abstract-facade.component';
import {ViewState} from '../view-state/view-state';
import {BreadcrumbLink} from './breadcrumb/breadcrumb.types';
import {ActivatedRoute, ActivationStart, Router, UrlSegment} from '@angular/router';
import {filter, take} from 'rxjs/operators';
import {AppStatePageLoaded} from '../../ngxs/app.state';

@Component({template: ''})
export abstract class AbstractFacadePageComponent extends AbstractFacadeComponent implements OnInit, OnDestroy, AfterViewInit {

    // Deps
    router:Router;
    activatedRoute:ActivatedRoute;

    state             = new ViewState();
    breadcrumbs:BreadcrumbLink[];
    viewWasChecked    = false;
    contentWasChecked = false;

    //initCallbacks:Function[];
    //initCalled = false;

    protected lastUrl:string;
    protected pageHasRenderedOnce      = false;
    protected dispatchPageLoadedOnInit = true;
    protected sisterSiteBarEnabled = this.implementationDataService.getSisterSiteSettings()?.length > 0;

    protected constructor(injector:Injector) {
        super(injector);
        this.router         = injector.get(Router);
        this.activatedRoute = injector.get(ActivatedRoute);
    }

    /**
     * We need the same component life cycle for root PAGES across mobile & desktop.
     * On mobile will 'enter' and 'leave' as a screen is changed.
     * On desktop the same component is used when a url changes instead of creating a new one so there are no enter and exit life cycle events :(
     * We need to mock these events then and only use the custom life cycle methods in this class to align the 2 systems:
     *
     * In order to address desktop situation, you need to subscribe to url param changes in the component, when that happens you call reInit()
     * The subscription on the url changes can be created in the constructor and stored using this.addComponentSub. this will then only get destroyed when the component is destroyed.
     */
    ngOnInit() {
        //this.initCalled = true;
        this.created();

        if (!this.isMobile) this.init();

        /*if (this.initCallbacks != null) {
            this.initCallbacks.forEach(callback => callback());
            this.initCallbacks = undefined;
        }*/
    }

    ngOnDestroy() {
        this.destroy();
        //this.initCallbacks = undefined;
    }

    ionViewDidEnter() {
        this.init();
        // Mobile scroll to the top on activate
        this.facadePlatform.scrollToTop();

        /*
        // This delayed callback of the didEnterCB() method is a bugfix picked up on android chrome 56
        // The ngOnInit, for some reason only executes after ionViewDidEnter
        const didEnterCB = () => {
            this.init();
            // Mobile scroll to the top on activate
            this.facadePlatform.scrollToTop();
        };
        if (this.initCalled) {
            didEnterCB();
        }
        else {
            this.initCallbacks = this.initCallbacks || [];
            this.initCallbacks.push(() => didEnterCB());
        }*/


    }

    ionViewWillLeave() {
        this.reset();
    }

    init() {
        super.init();
        this.setMetaForPage();
    }

    ngAfterViewInit() {
        if (this.dispatchPageLoadedOnInit) this.pageLoaded();
    }

    created() {
        super.created();

        this.lastUrl         = this.router.url;
        this.addComponentSub = this.router.events
            .pipe(filter(event => event instanceof ActivationStart))
            .subscribe((event:ActivationStart) => {
                this.lastUrl = this.router.url;
            });
        this.addComponentSub = this.activatedRoute.url.subscribe((event:UrlSegment[]) => {
            const currentEndpoint = this.urlToEndpoint(this.router.url);
            const lastEndpoint    = this.urlToEndpoint(this.lastUrl);
            if (currentEndpoint !== lastEndpoint) this.onPageUrlChanged();
        });
    }

    private urlToEndpoint(url:string) {
        const index1 = url.indexOf('?');
        if (index1 !== -1) url = url.substr(0, index1);
        const index2 = url.indexOf('#');
        if (index2 !== -1) url = url.substr(0, index2);
        return url;
    }


    // To be called by the implementing page if you want to make
    // use of the associated functionality & life cycle hooks
    handlePageRender() {
        // TODO: This has overlap with the pageLoaded() implementation but slightly different, needs to be merged
        if (!this.pageHasRenderedOnce) {
            this.pageHasRenderedOnce = true;
            this.handleFirstPageRender();
        }
    }

    handleFirstPageRender() {
        // Scroll logic
        this.storeSelect(s => s.history.current).pipe(take(1)).subscribe(result => {
            setTimeout(() => {
                if (result && result.domPosition) {
                    this.facadePlatform.scrollToPoint(result.domPosition.x, result.domPosition.y);
                }
                else {
                    this.facadePlatform.scrollToTop();
                }
            });
        });
    }

    onPageUrlChanged() {
        // Reset the rendered state when the url changes
        this.pageHasRenderedOnce = false;
    }

    pageLoaded() {
        // So this method, will be executed after the view has initialized
        // It's here to control footer layout in the desktop frame
        // We hide the footer till the first page has loaded then show it to
        // avoid layout shift penalty from google page speed
        // Its implemented here in the component vs some router event so that
        // it can be overridden should the page take longer to load stuff i.e. cms / product pages
        this.store.dispatch(new AppStatePageLoaded());
    }

    /*ionViewWillEnter(){
        console.log('-----> ionViewWillEnter()');
    }
    ionViewDidEnter(){
        console.log('-----> ionViewDidEnter()');
    }
    ionViewWillLeave(){
        console.log('-----> ionViewWillLeave()');
    }
    ionViewDidLeave(){
        console.log('-----> ionViewDidLeave()');
    }*/

    abstract setMetaForPage():void;

    // This must return the class name
    abstract get name():string;
}
