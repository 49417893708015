import {NgModule} from '@angular/core';
import {FacadeIconDirective} from './facade-icon.directive';

@NgModule({
    declarations: [
        FacadeIconDirective
    ],
    exports     : [
        FacadeIconDirective
    ]
})
export class FacadeIconModule {
}
