import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {ImplementationDataService} from '../implementation-config/implementation-data.service';
import {Logger} from '../shared/logger';
import {ProductModel} from '../external-data/magento/elasticsearch/product.model';
import {UrlService} from '../shared/url.service';
import {StaticConfig} from '../shared/static-config';
import {DocumentRef} from '../shared/ui/document-ref';
import {IProductModel} from '../external-data/magento/model/iproduct.model';


export interface IMetaPageData {
    title?:string;
    description?:string;
    social?:SocialMetaData;
    robots?:string;
    // TODO: Google plus profile url for the author
    author?:string;
    publisher?:string;
}

export interface SocialMetaData {
    // https://developers.facebook.com/docs/reference/opengraph
    type?:string; // website, article, product, etc
    imageURL?:string;
    secureImageURL?:string;
    imageType?:string;
    imageHeight?:string;
    imageWidth?:string;
    imageAlt?:string;
    url?:string;
}

type MetaType =
    'TITLE'
    | 'DESCRIPTION'
    | 'SOCIAL_IMAGE_URL'
    | 'SOCIAL_SECURE_IMAGE_URL'
    | 'SOCIAL_IMAGE_TYPE'
    | 'SOCIAL_IMAGE_WIDTH'
    | 'SOCIAL_IMAGE_HEIGHT'
    | 'SOCIAL_IMAGE_ALT'
    | 'CANONICAL'
    | 'SOCIAL_TYPE'
    | 'AUTHOR'
    | 'ROBOTS'
type MetaPropertyType = 'property' | 'itemprop' | 'name' | 'canonical';
type TypeName =
    'og:title'
    | 'name'
    | 'og:description'
    | 'description'
    | 'og:image'
    | 'og:image:secure_url'
    | 'image'
    | 'og:type'
    | 'og:image:type'
    | 'og:image:width'
    | 'og:image:height'
    | 'og:image:alt'
    | 'og:url'
    | 'twitter:url'
    | 'twitter:title'
    | 'twitter:description'
    | 'twitter:image'
    | 'canonical'
    | 'robots';

interface MetaTagDefinition {
    created:boolean;
    value:string;
    metaObjs:Array<MetaObj>;
}

interface MetaObj {
    type:MetaPropertyType;
    typeName:TypeName;
}

interface MetaSchema {
    '@type':string;
}

/* Product Meta Data */
interface ProductItemListSchema {
    '@context':'https://schema.org/';
    '@type':'ItemList',
    name:string,
    description:string,
    url:string,
    itemListElement:ProductListItemSchema[]
}

interface ProductListItemSchema {
    '@type':'ListItem',
    position:number,
    item:ProductSchema
}

interface ProductSchema {
    '@context'?:'https://schema.org/';
    '@type':'Product';
    name:string;
    image:string[];
    description:string;
    sku:string;
    brand?:ProductSchemaBrand;
    review?:ProductSchemaReview;
    aggregateRating?:ProductSchemaAggregateRating;
    offers:ProductSchemaOffers;
}

interface ProductSchemaBrand {
    '@type':'Brand';
    name:string;
}

interface ProductSchemaReview {
    '@type':'Review';
    reviewRating:ProductSchemaReviewRating;
    author:ProductSchemaAuthor;
}

interface ProductSchemaReviewRating {
    '@type':'Rating';
    ratingValue:string;
    bestRating:string;
}

interface ProductSchemaAuthor {
    '@type':'Person';
    name:string;
}

interface ProductSchemaAggregateRating {
    '@type':'AggregateRating';
    ratingValue:string;
    reviewCount:string;
}

interface ProductSchemaOffers {
    '@type':'Offer';
    url:string;
    priceCurrency:string;
    price:string;
    itemCondition:string;
    availability:string;
}

export class SEODefaults implements IMetaPageData {
    constructor(public title?:string, public description?:string, public social?:SocialMetaData, public author?:string, public publisher?:string) {
    }
}

@Injectable()
export class SEOService {

    private readonly metaTagDefinitionsHash:{ [key:string]:MetaTagDefinition };

    private readonly seoDefaults:SEODefaults;

    private readonly scriptType = 'application/ld+json';

    constructor(public meta:Meta,
                public title:Title,
                public logger:Logger,
                public translateService:TranslateService,
                public implementationConfigService:ImplementationDataService,
                public implementationDataService:ImplementationDataService,
                public urlService:UrlService,
                private documentRef:DocumentRef) {
        this.logger = this.logger.getLogger('SEOService');

        // Create a blank defaults if not passed in
        this.seoDefaults = implementationConfigService.getSEODefaults();

        this.metaTagDefinitionsHash = {
            'TITLE'                  : {
                // These are currently pre-populated in the index file
                created: false, value: null, metaObjs: [
                    {type: 'property', typeName: 'og:title'},
                    {type: 'itemprop', typeName: 'name'},
                    // Dropped due to pre-pop bugs and currently not useful
                    /*{type: 'name', typeName: 'twitter:title'}*/
                ]
            },
            'DESCRIPTION'            : {
                // These are currently pre-populated in the index file
                created: false, value: null, metaObjs: [
                    {type: 'name', typeName: 'description'},
                    {type: 'property', typeName: 'og:description'},
                    {type: 'itemprop', typeName: 'description'},
                    // Dropped due to pre-pop bugs and currently not useful
                    /*{type: 'name', typeName: 'twitter:description'}*/
                ]
            },
            'ROBOTS'                 : {
                created: false, value: null, metaObjs: [
                    {type: 'name', typeName: 'robots'},
                ]
            },
            'SOCIAL_IMAGE_URL'       : {
                created: false, value: null, metaObjs: [
                    {type: 'property', typeName: 'og:image'},
                    {type: 'itemprop', typeName: 'image'},
                    {type: 'name', typeName: 'twitter:image'},
                ]
            },
            'SOCIAL_SECURE_IMAGE_URL': {
                created: false, value: null, metaObjs: [
                    {type: 'property', typeName: 'og:image:secure_url'}
                ]
            },
            'SOCIAL_TYPE'            : {
                created: false, value: null, metaObjs: [{type: 'property', typeName: 'og:type'}]
            },
            'SOCIAL_IMAGE_TYPE'      : {
                created: false, value: null, metaObjs: [{type: 'property', typeName: 'og:image:type'}]
            },
            'SOCIAL_IMAGE_WIDTH'     : {
                created: false, value: null, metaObjs: [{type: 'property', typeName: 'og:image:width'}]
            },
            'SOCIAL_IMAGE_HEIGHT'    : {
                created: false, value: null, metaObjs: [{type: 'property', typeName: 'og:image:height'}]
            },
            'SOCIAL_IMAGE_ALT'       : {
                created: false, value: null, metaObjs: [{type: 'property', typeName: 'og:image:alt'}]
            },
            'CANONICAL'              : {
                created: false, value: null, metaObjs: [
                    {type: 'property', typeName: 'og:url'},
                    {type: 'name', typeName: 'twitter:url'},
                    {type: 'canonical', typeName: 'canonical'}
                ]
            },

            /*'AUTHOR': {
                created: false, value:null, metaObjs: [{type: 'property', typeName: 'og:author'}]
            }*/
        };

        // Add this by default, the rest of the properties will come from the social values
        this.meta.addTags([
            {name: 'twitter:card', value: 'summary'}
        ]);
    }

    public removePageData() {
        this.populatePageData({}, false, null);
    }

    public async populatePageDataAutoTranslate(metaData:IMetaPageData, extendWithDefaults:boolean = true, canonicalUrl:string) {
        //this.logger.test('Auto translating 1: ', metaData);
        // Bug Fix, very important we clone before manipulating otherwise later iterations will
        // used the updated object.
        metaData = Object.assign({}, metaData);
        if (metaData.title) {
            const titleResult = await this.translateService.get(metaData.title).toPromise();
            metaData.title    = metaData.title === titleResult ? '' : titleResult;
        }
        if (metaData.description) {
            const descResult     = await this.translateService.get(metaData.description).toPromise();
            metaData.description = metaData.description === descResult ? '' : descResult;
        }
        //this.logger.test('Auto translating 2: ', metaData);
        this.populatePageData(metaData, extendWithDefaults, canonicalUrl);
    }

    public populateDefaultPageData() {
        this.populatePageData(this.seoDefaults, false, null);
    }

    public populatePageData(metaData:IMetaPageData, extendWithDefaults:boolean = true, canonicalUrl:string) {
        this.logger.test('Metadata with: ', metaData);
        if (extendWithDefaults) {
            metaData        = Object.assign({}, this.seoDefaults, metaData);
            metaData.social = Object.assign({}, this.seoDefaults.social, metaData.social);
        }
        this.updateMetaTag('TITLE', metaData.title);
        // TODO: Move to the lower implementation code methods
        this.title.setTitle(metaData.title);

        this.updateMetaTag('DESCRIPTION', metaData.description);

        if (!metaData.social) metaData.social = {};
        this.updateMetaTag('SOCIAL_TYPE', metaData.social.type);
        this.updateMetaTag('SOCIAL_IMAGE_URL', metaData.social.imageURL);

        // Clone the imageURL if empty
        if (metaData.social.imageURL && metaData.social.imageURL.indexOf('https') === 0 && !metaData.social.secureImageURL) {
            metaData.social.secureImageURL = metaData.social.imageURL;
        }
        // Reset if not actually secure
        if (metaData.social.secureImageURL && metaData.social.secureImageURL.indexOf('https') !== 0) metaData.social.secureImageURL = null;
        this.updateMetaTag('SOCIAL_SECURE_IMAGE_URL', metaData.social.secureImageURL);
        this.updateMetaTag('SOCIAL_IMAGE_TYPE', metaData.social.imageType);
        this.updateMetaTag('SOCIAL_IMAGE_WIDTH', metaData.social.imageWidth);
        this.updateMetaTag('SOCIAL_IMAGE_HEIGHT', metaData.social.imageHeight);
        this.updateMetaTag('CANONICAL', (canonicalUrl) ? canonicalUrl : metaData.social.url);

        // Remove incase (also removed on component destroy)
        this.removeTag('ROBOTS');
        this.removeStructuredData();
    }

    public populateProductStructuredData(product:ProductModel, url:string) {
        let schema = this.getProductSchema(product, url);

        this.logger.info('Product for structured data', product);
        this.logger.info('Structured data for product', schema);

        this.insertSchema(schema);
    }

    public populateProductListStructuredData(metaData:IMetaPageData, canonicalUrl:string, products:Array<IProductModel>, baseDomain:string = '') {
        let productsListSchema:ProductListItemSchema[] = products?.map(product => {
            return product ? {
                '@type' : 'ListItem',
                position: product.index + 1,
                item    : this.getProductSchema(product as ProductModel, baseDomain + this.urlService.buildUrlForProductCanonical(product), false)
            } as ProductListItemSchema : null;
        }).filter(product => product);
        if(productsListSchema.length <= 0) return;

        let schema:ProductItemListSchema               = {
            '@context'     : 'https://schema.org/',
            '@type'        : 'ItemList',
            name           : metaData.title,
            description    : metaData.description,
            url            : canonicalUrl,
            itemListElement: productsListSchema
        };

        this.logger.info('Structured data for product list', schema);
        this.insertSchema(schema);
    }

    private getProductSchema(product:ProductModel, url:string, isRoot:boolean = true) {
        return {
            ...(isRoot ? {'@context': 'https://schema.org/'} : {}),
            '@type'    : 'Product',
            name       : product.name,
            image      : product.images.map(img => {
                return this.urlService.buildUrlForMagentoImage(img.image, this.implementationDataService.getProductPageImageSizes().desktop.largeSize);
            }),
            description: product.metaDescription,
            sku        : product.sku,
            offers     : {
                '@type'      : 'Offer',
                url          : url,
                priceCurrency: StaticConfig.PRODUCT_CURRENCY_CODE,
                price        : product.price.toFixed(2),
                itemCondition: 'https://schema.org/NewCondition',
                availability : product.isInStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
            },
        };
    }

    removeStructuredData():void {
        this.logger.info('Removing structured data');
        const els = [];
        ['structured-data', 'structured-data-org'].forEach(c => {
            els.push(...Array.from(this.documentRef.head.getElementsByClassName(c)));
        });
        els.forEach(el => this.documentRef.head.removeChild(el));
    }

    insertSchema(schema:Record<string, any>, className = 'structured-data'):void {
        let script;
        let shouldAppend = false;
        if (this.documentRef.head.getElementsByClassName(className).length) {
            script = this.documentRef.head.getElementsByClassName(className)[0];
        }
        else {
            script       = this.documentRef.createElement('script');
            shouldAppend = true;
        }
        script.setAttribute('class', className);
        script.type = this.scriptType;
        script.text = JSON.stringify(schema);

        if (shouldAppend) {
            this.documentRef.head.appendChild(script);
        }
    }

    /**
     * Adds a noindex robot meta tag to the html document
     * Bypass and remove the noindex robot metatag with false
     *
     * @param flag Remove the existing `ROBOTS` tag element from the current HTML document by setting this to false
     */
    public disableRobots(flag:boolean) {
        flag ? this.updateMetaTag('ROBOTS', 'noindex, nofollow') : this.removeTag('ROBOTS');
    }

    /**
     * Adds a noindex robot meta tag to the html document when the product count is below or euqual to a threshhold
     * @param productCount The product count threshhold
     */
    public addNoIndexOnLowProductCount(productCount:number) {
        if (productCount <= this.implementationDataService.getNoIndexMinProductCount()) {
            this.logger.debug(`Adding 'ROBOTS', 'noindex, nofollow' for product count ${productCount}`);
            this.disableRobots(true);
        }
    }

    private updateMetaTag(metaType:MetaType, content:string) {
        if (content == null || content === '') {
            this.removeTag(metaType);
            if (metaType === 'CANONICAL') this.removeCanonicalURL();
        }
        else {
            const currMetaTagDefinition:MetaTagDefinition = this.metaTagDefinitionsHash[metaType];

            currMetaTagDefinition.metaObjs.forEach((metaObj:MetaObj) => {
                const addUpdateNormalTag    = () => {
                    const tag         = {};
                    tag[metaObj.type] = metaObj.typeName;
                    tag['content']    = content || '';

                    // Removed as this should be up 2 the meta author
                    /*switch (metaType) {
                        case 'TITLE':
                            tag['content'] = tag['content'].substr(0, 60);
                            break;
                        case 'DESCRIPTION':
                            tag['content'] = tag['content'].substr(0, 160);
                            break;
                    }*/

                    if (!currMetaTagDefinition.created) {
                        this.logger.test('SEO Meta Add:', tag);
                        this.meta.addTag(tag);
                    }
                    else {
                        this.logger.test('SEO Meta Update:', tag);
                        this.meta.updateTag(tag, `${metaObj.type}='${metaObj.typeName}'`);
                    }
                };
                const addUpdateCanonicalTag = () => {
                    if (!currMetaTagDefinition.created) {
                        this.addCanonicalURL(content);
                    }
                    else {
                        this.updateCanonicalURL(content);
                    }
                };

                (metaObj.type === 'canonical') ? addUpdateCanonicalTag() : addUpdateNormalTag();
            });

            if (!currMetaTagDefinition.created)
                currMetaTagDefinition.created = true;
        }
    }

    private removeTag(metaType:MetaType):void {
        const currMetaTagDefinition:MetaTagDefinition = this.metaTagDefinitionsHash[metaType];
        if (currMetaTagDefinition.created) {
            currMetaTagDefinition.created = false;

            currMetaTagDefinition.metaObjs.forEach((metaObj:MetaObj) => {
                this.meta.removeTag(`${metaObj.type}='${metaObj.typeName}'`);
            });
        }
    }

    canonicalLink:HTMLLinkElement;

    addCanonicalURL(url:string) {
        this.canonicalLink = this.documentRef.createElement('link');
        this.canonicalLink.setAttribute('rel', 'canonical');
        this.documentRef.head.appendChild(this.canonicalLink);
        this.canonicalLink.setAttribute('href', url);
    }

    updateCanonicalURL(url:string) {
        this.canonicalLink.setAttribute('href', url);
    }

    removeCanonicalURL() {
        if (this.canonicalLink) {
            this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            this.canonicalLink = null;
        }
    }

}
