// NB!!
// This file is shared with the sitemap builder so cannot import gql from apollo-angular
import {gql} from '@apollo/client/core';

export const GET_CATEGORIES_CACHE = true;

export const GET_CATEGORIES = gql`
    query categories(
        $fitler: CategoryFilterInput
        $pageSize: Int
        $currentPage: Int
    ) {
        categories(filters: $fitler, pageSize: $pageSize, currentPage: $currentPage) {
            total_count
            items {
                ...ChildProperties
                children {
                    ...ChildProperties
                    children {
                        ...ChildProperties
                        children {
                            ...ChildProperties
                            children {
                                ...ChildProperties
                                children {
                                    ...ChildProperties
                                    children {
                                        ...ChildProperties
                                        children {
                                            ...ChildProperties
                                            children {
                                                ...ChildProperties
                                                children {
                                                    ...ChildProperties
                                                    children {
                                                        ...ChildProperties
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            page_info {
                current_page
                page_size
                total_pages
            }
        }
    }

    fragment ChildProperties on CategoryTree {
        id
        global_category_id
        name
        url_key
        product_count
        position
        level
        include_in_menu
        available_sort_by
        meta_title
        meta_description
        rel_canonical
        robots_index
    }
`;
