import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FirebaseLogicService} from '../external-data/firebase/firebase-logic.service';
import {Logger} from './logger';
import {WindowRef} from './ui/window-ref';
import {MagentoLogicService} from '../external-data/magento/magento-logic.service';
import {LoggerGlobalDefaults, LogLevel} from './logger-impl-core';
import {Store} from '@ngxs/store';
import {Magento} from '../external-data/magento/magento.state';

@Injectable()
export class CrossPlatformService {

    private initialized = false;


    constructor(private firebaseService:FirebaseLogicService,
                private translateService:TranslateService,
                private logger:Logger,
                private windowRef:WindowRef,
                private magentoLogicService:MagentoLogicService,
                private store:Store) {
    }

    configureApp(enableLogging:boolean = true) {
        if (!this.initialized) {
            this.initialized = true;

            // Setup the logging defaults
            LoggerGlobalDefaults.prefixFormat = '[$LOG_LEVEL$] $NAME$ -';
            if (!enableLogging) {
                LoggerGlobalDefaults.logLevelOverride = LogLevel.ERROR;
            }
            this.logger.prefixFormat = '[$LOG_LEVEL$]';
            this.logger              = this.logger.getLogger('CrossPlatformService');
            this.logger.debug('Module Created');

            // this language will be used as a fallback when a translation isn't found in the current language
            this.translateService.setDefaultLang('en');
            // the lang to use, if the lang isn't available, it will use the current loader to get them
            this.translateService.use('en');
        }
    }

    async handleCategoryDataStartup() {
        if (this.windowRef.startupData?.categories) {
            this.store.dispatch(new Magento.StoreCategories(MagentoLogicService.parseCategories(this.windowRef.startupData.categories)));
        }
        else {
            await this.magentoLogicService.fetchCategories().toPromise();
        }
    }
}
