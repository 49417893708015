export interface CountryPrefixCode {
    value:string;
    label:string;
    regionCode:string;
    selected?:boolean;
}

export class TelInputUtils {

    static regions = {
        southAfrica: <CountryPrefixCode>{value: '27', label: '+27', regionCode: 'ZA', selected: true},
        botswana   : <CountryPrefixCode>{value: '267', label: '+267', regionCode: 'BW'},
        lesotho    : <CountryPrefixCode>{value: '266', label: '+266', regionCode: 'LS'},
        namibia    : <CountryPrefixCode>{value: '264', label: '+264', regionCode: 'NA'},
        swaziland  : <CountryPrefixCode>{value: '268', label: '+268', regionCode: 'SZ'},
        zambia     : <CountryPrefixCode>{value: '260', label: '+260', regionCode: 'ZM'},
        zimbabwe   : <CountryPrefixCode>{value: '263', label: '+263', regionCode: 'ZW'},

        afghanistan                : <CountryPrefixCode>{value: '93', label: '+93', regionCode: 'AF'},
        albania                    : <CountryPrefixCode>{value: '355', label: '+355', regionCode: 'AL'},
        algeria                    : <CountryPrefixCode>{value: '213', label: '+213', regionCode: 'DZ'},
        americanSamoa              : <CountryPrefixCode>{value: '1684', label: '+1-684', regionCode: 'AS'},
        andorra                    : <CountryPrefixCode>{value: '376', label: '+376', regionCode: 'AD'},
        angola                     : <CountryPrefixCode>{value: '244', label: '+244', regionCode: 'AO'},
        anguilla                   : <CountryPrefixCode>{value: '1264', label: '+1-264', regionCode: 'AI'},
        antarctica                 : <CountryPrefixCode>{value: '672', label: '+672', regionCode: 'AQ'},
        antiguaAndBarbuda          : <CountryPrefixCode>{value: '1268', label: '+1-268', regionCode: 'AG'},
        argentina                  : <CountryPrefixCode>{value: '54', label: '+54', regionCode: 'AR'},
        armenia                    : <CountryPrefixCode>{value: '374', label: '+374', regionCode: 'AM'},
        aruba                      : <CountryPrefixCode>{value: '297', label: '+297', regionCode: 'AW'},
        australia                  : <CountryPrefixCode>{value: '61', label: '+61', regionCode: 'AU'},
        austria                    : <CountryPrefixCode>{value: '43', label: '+43', regionCode: 'AT'},
        azerbaijan                 : <CountryPrefixCode>{value: '994', label: '+994', regionCode: 'AZ'},
        bahamas                    : <CountryPrefixCode>{value: '1242', label: '+1-242', regionCode: 'BS'},
        bahrain                    : <CountryPrefixCode>{value: '973', label: '+973', regionCode: 'BH'},
        bangladesh                 : <CountryPrefixCode>{value: '880', label: '+880', regionCode: 'BD'},
        barbados                   : <CountryPrefixCode>{value: '1246', label: '+1-246', regionCode: 'BB'},
        belarus                    : <CountryPrefixCode>{value: '375', label: '+375', regionCode: 'BY'},
        belgium                    : <CountryPrefixCode>{value: '32', label: '+32', regionCode: 'BE'},
        belize                     : <CountryPrefixCode>{value: '501', label: '+501', regionCode: 'BZ'},
        benin                      : <CountryPrefixCode>{value: '229', label: '+229', regionCode: 'BJ'},
        bermuda                    : <CountryPrefixCode>{value: '1441', label: '+1-441', regionCode: 'BM'},
        bhutan                     : <CountryPrefixCode>{value: '975', label: '+975', regionCode: 'BT'},
        bolivia                    : <CountryPrefixCode>{value: '591', label: '+591', regionCode: 'BO'},
        bosniaAndHerzegovina       : <CountryPrefixCode>{value: '387', label: '+387', regionCode: 'BA'},
        brazil                     : <CountryPrefixCode>{value: '55', label: '+55', regionCode: 'BR'},
        britishIndianOceanTerritory: <CountryPrefixCode>{value: '246', label: '+246', regionCode: 'IO'},
        britishVirginIslands       : <CountryPrefixCode>{value: '1284', label: '+1-284', regionCode: 'VG'},
        brunei                     : <CountryPrefixCode>{value: '673', label: '+673', regionCode: 'BN'},
        bulgaria                   : <CountryPrefixCode>{value: '359', label: '+359', regionCode: 'BG'},
        burkinaFaso                : <CountryPrefixCode>{value: '226', label: '+226', regionCode: 'BF'},
        burundi                    : <CountryPrefixCode>{value: '257', label: '+257', regionCode: 'BI'},
        cambodia                   : <CountryPrefixCode>{value: '855', label: '+855', regionCode: 'KH'},
        cameroon                   : <CountryPrefixCode>{value: '237', label: '+237', regionCode: 'CM'},
        //canada                      : <CountryPrefixCode>{value: '1', label: '+1', regionCode: 'CA'},
        capeVerde                   : <CountryPrefixCode>{value: '238', label: '+238', regionCode: 'CV'},
        caymanIslands               : <CountryPrefixCode>{value: '1345', label: '+1-345', regionCode: 'KY'},
        centralAfricanRepublic      : <CountryPrefixCode>{value: '236', label: '+236', regionCode: 'CF'},
        chad                        : <CountryPrefixCode>{value: '235', label: '+235', regionCode: 'TD'},
        chile                       : <CountryPrefixCode>{value: '56', label: '+56', regionCode: 'CL'},
        china                       : <CountryPrefixCode>{value: '86', label: '+86', regionCode: 'CN'},
        christmasIsland             : <CountryPrefixCode>{value: '61', label: '+61', regionCode: 'CX'},
        cocosIslands                : <CountryPrefixCode>{value: '61', label: '+61', regionCode: 'CC'},
        colombia                    : <CountryPrefixCode>{value: '57', label: '+57', regionCode: 'CO'},
        comoros                     : <CountryPrefixCode>{value: '269', label: '+269', regionCode: 'KM'},
        cookIslands                 : <CountryPrefixCode>{value: '682', label: '+682', regionCode: 'CK'},
        costaRica                   : <CountryPrefixCode>{value: '506', label: '+506', regionCode: 'CR'},
        croatia                     : <CountryPrefixCode>{value: '385', label: '+385', regionCode: 'HR'},
        cuba                        : <CountryPrefixCode>{value: '53', label: '+53', regionCode: 'CU'},
        curacao                     : <CountryPrefixCode>{value: '599', label: '+599', regionCode: 'CW'},
        cyprus                      : <CountryPrefixCode>{value: '357', label: '+357', regionCode: 'CY'},
        czechRepublic               : <CountryPrefixCode>{value: '420', label: '+420', regionCode: 'CZ'},
        democraticRepublicOfTheCongo: <CountryPrefixCode>{value: '243', label: '+243', regionCode: 'CD'},
        denmark                     : <CountryPrefixCode>{value: '45', label: '+45', regionCode: 'DK'},
        djibouti                    : <CountryPrefixCode>{value: '253', label: '+253', regionCode: 'DJ'},
        dominica                    : <CountryPrefixCode>{value: '1767', label: '+1-767', regionCode: 'DM'},
        dominicanRepublic1          : <CountryPrefixCode>{value: '1809', label: '+1-809', regionCode: 'DO'},
        dominicanRepublic2          : <CountryPrefixCode>{value: '1829', label: '+1-829', regionCode: 'DO'},
        dominicanRepublic3          : <CountryPrefixCode>{value: '1849', label: '+1-849', regionCode: 'DO'},
        eastTimor                   : <CountryPrefixCode>{value: '670', label: '+670', regionCode: 'TL'},
        ecuador                     : <CountryPrefixCode>{value: '593', label: '+593', regionCode: 'EC'},
        egypt                       : <CountryPrefixCode>{value: '20', label: '+20', regionCode: 'EG'},
        elSalvador                  : <CountryPrefixCode>{value: '503', label: '+503', regionCode: 'SV'},
        equatorialGuinea            : <CountryPrefixCode>{value: '240', label: '+240', regionCode: 'GQ'},
        eritrea                     : <CountryPrefixCode>{value: '291', label: '+291', regionCode: 'ER'},
        estonia                     : <CountryPrefixCode>{value: '372', label: '+372', regionCode: 'EE'},
        ethiopia                    : <CountryPrefixCode>{value: '251', label: '+251', regionCode: 'ET'},
        falklandIslands             : <CountryPrefixCode>{value: '500', label: '+500', regionCode: 'FK'},
        faroeIslands                : <CountryPrefixCode>{value: '298', label: '+298', regionCode: 'FO'},
        fiji                        : <CountryPrefixCode>{value: '679', label: '+679', regionCode: 'FJ'},
        finland                     : <CountryPrefixCode>{value: '358', label: '+358', regionCode: 'FI'},
        france                      : <CountryPrefixCode>{value: '33', label: '+33', regionCode: 'FR'},
        frenchPolynesia             : <CountryPrefixCode>{value: '689', label: '+689', regionCode: 'PF'},
        gabon                       : <CountryPrefixCode>{value: '241', label: '+241', regionCode: 'GA'},
        gambia                      : <CountryPrefixCode>{value: '220', label: '+220', regionCode: 'GM'},
        georgia                     : <CountryPrefixCode>{value: '995', label: '+995', regionCode: 'GE'},
        germany                     : <CountryPrefixCode>{value: '49', label: '+49', regionCode: 'DE'},
        ghana                       : <CountryPrefixCode>{value: '233', label: '+233', regionCode: 'GH'},
        gibraltar                   : <CountryPrefixCode>{value: '350', label: '+350', regionCode: 'GI'},
        greece                      : <CountryPrefixCode>{value: '30', label: '+30', regionCode: 'GR'},
        greenland                   : <CountryPrefixCode>{value: '299', label: '+299', regionCode: 'GL'},
        grenada                     : <CountryPrefixCode>{value: '1473', label: '+1-473', regionCode: 'GD'},
        guam                        : <CountryPrefixCode>{value: '1671', label: '+1-671', regionCode: 'GU'},
        guatemala                   : <CountryPrefixCode>{value: '502', label: '+502', regionCode: 'GT'},
        guernsey                    : <CountryPrefixCode>{value: '441481', label: '+44-1481', regionCode: 'GG'},
        guinea                      : <CountryPrefixCode>{value: '224', label: '+224', regionCode: 'GN'},
        guineaBissau                : <CountryPrefixCode>{value: '245', label: '+245', regionCode: 'GW'},
        guyana                      : <CountryPrefixCode>{value: '592', label: '+592', regionCode: 'GY'},
        haiti                       : <CountryPrefixCode>{value: '509', label: '+509', regionCode: 'HT'},
        honduras                    : <CountryPrefixCode>{value: '504', label: '+504', regionCode: 'HN'},
        hongKong                    : <CountryPrefixCode>{value: '852', label: '+852', regionCode: 'HK'},
        hungary                     : <CountryPrefixCode>{value: '36', label: '+36', regionCode: 'HU'},
        iceland                     : <CountryPrefixCode>{value: '354', label: '+354', regionCode: 'IS'},
        india                       : <CountryPrefixCode>{value: '91', label: '+91', regionCode: 'IN'},
        indonesia                   : <CountryPrefixCode>{value: '62', label: '+62', regionCode: 'ID'},
        iran                        : <CountryPrefixCode>{value: '98', label: '+98', regionCode: 'IR'},
        iraq                        : <CountryPrefixCode>{value: '964', label: '+964', regionCode: 'IQ'},
        ireland                     : <CountryPrefixCode>{value: '353', label: '+353', regionCode: 'IE'},
        isleOfMan                   : <CountryPrefixCode>{value: '441624', label: '+44-1624', regionCode: 'IM'},
        israel                      : <CountryPrefixCode>{value: '972', label: '+972', regionCode: 'IL'},
        italy                       : <CountryPrefixCode>{value: '39', label: '+39', regionCode: 'IT'},
        ivoryCoast                  : <CountryPrefixCode>{value: '225', label: '+225', regionCode: 'CI'},
        jamaica                     : <CountryPrefixCode>{value: '1876', label: '+1-876', regionCode: 'JM'},
        japan                       : <CountryPrefixCode>{value: '81', label: '+81', regionCode: 'JP'},
        jersey                      : <CountryPrefixCode>{value: '441534', label: '+44-1534', regionCode: 'JE'},
        jordan                      : <CountryPrefixCode>{value: '962', label: '+962', regionCode: 'JO'},
        kazakhstan                  : <CountryPrefixCode>{value: '7', label: '+7', regionCode: 'KZ'},
        kenya                       : <CountryPrefixCode>{value: '254', label: '+254', regionCode: 'KE'},
        kiribati                    : <CountryPrefixCode>{value: '686', label: '+686', regionCode: 'KI'},
        kosovo                      : <CountryPrefixCode>{value: '383', label: '+383', regionCode: 'XK'},
        kuwait                      : <CountryPrefixCode>{value: '965', label: '+965', regionCode: 'KW'},
        kyrgyzstan                  : <CountryPrefixCode>{value: '996', label: '+996', regionCode: 'KG'},
        laos                        : <CountryPrefixCode>{value: '856', label: '+856', regionCode: 'LA'},
        latvia                      : <CountryPrefixCode>{value: '371', label: '+371', regionCode: 'LV'},
        lebanon                     : <CountryPrefixCode>{value: '961', label: '+961', regionCode: 'LB'},
        liberia                     : <CountryPrefixCode>{value: '231', label: '+231', regionCode: 'LR'},
        libya                       : <CountryPrefixCode>{value: '218', label: '+218', regionCode: 'LY'},
        liechtenstein               : <CountryPrefixCode>{value: '423', label: '+423', regionCode: 'LI'},
        lithuania                   : <CountryPrefixCode>{value: '370', label: '+370', regionCode: 'LT'},
        luxembourg                  : <CountryPrefixCode>{value: '352', label: '+352', regionCode: 'LU'},
        macau                       : <CountryPrefixCode>{value: '853', label: '+853', regionCode: 'MO'},
        macedonia                   : <CountryPrefixCode>{value: '389', label: '+389', regionCode: 'MK'},
        madagascar                  : <CountryPrefixCode>{value: '261', label: '+261', regionCode: 'MG'},
        malawi                      : <CountryPrefixCode>{value: '265', label: '+265', regionCode: 'MW'},
        malaysia                    : <CountryPrefixCode>{value: '60', label: '+60', regionCode: 'MY'},
        maldives                    : <CountryPrefixCode>{value: '960', label: '+960', regionCode: 'MV'},
        mali                        : <CountryPrefixCode>{value: '223', label: '+223', regionCode: 'ML'},
        malta                       : <CountryPrefixCode>{value: '356', label: '+356', regionCode: 'MT'},
        marshallIslands             : <CountryPrefixCode>{value: '692', label: '+692', regionCode: 'MH'},
        mauritania                  : <CountryPrefixCode>{value: '222', label: '+222', regionCode: 'MR'},
        mauritius                   : <CountryPrefixCode>{value: '230', label: '+230', regionCode: 'MU'},
        mayotte                     : <CountryPrefixCode>{value: '262', label: '+262', regionCode: 'YT'},
        mexico                      : <CountryPrefixCode>{value: '52', label: '+52', regionCode: 'MX'},
        micronesia                  : <CountryPrefixCode>{value: '691', label: '+691', regionCode: 'FM'},
        moldova                     : <CountryPrefixCode>{value: '373', label: '+373', regionCode: 'MD'},
        monaco                      : <CountryPrefixCode>{value: '377', label: '+377', regionCode: 'MC'},
        mongolia                    : <CountryPrefixCode>{value: '976', label: '+976', regionCode: 'MN'},
        montenegro                  : <CountryPrefixCode>{value: '382', label: '+382', regionCode: 'ME'},
        montserrat                  : <CountryPrefixCode>{value: '1664', label: '+1-664', regionCode: 'MS'},
        morocco                     : <CountryPrefixCode>{value: '212', label: '+212', regionCode: 'MA'},
        mozambique                  : <CountryPrefixCode>{value: '258', label: '+258', regionCode: 'MZ'},
        myanmar                     : <CountryPrefixCode>{value: '95', label: '+95', regionCode: 'MM'},
        nauru                       : <CountryPrefixCode>{value: '674', label: '+674', regionCode: 'NR'},
        nepal                       : <CountryPrefixCode>{value: '977', label: '+977', regionCode: 'NP'},
        netherlands                 : <CountryPrefixCode>{value: '31', label: '+31', regionCode: 'NL'},
        netherlandsAntilles         : <CountryPrefixCode>{value: '599', label: '+599', regionCode: 'AN'},
        newCaledonia                : <CountryPrefixCode>{value: '687', label: '+687', regionCode: 'NC'},
        newZealand                  : <CountryPrefixCode>{value: '64', label: '+64', regionCode: 'NZ'},
        nicaragua                   : <CountryPrefixCode>{value: '505', label: '+505', regionCode: 'NI'},
        niger                       : <CountryPrefixCode>{value: '227', label: '+227', regionCode: 'NE'},
        nigeria                     : <CountryPrefixCode>{value: '234', label: '+234', regionCode: 'NG'},
        niue                        : <CountryPrefixCode>{value: '683', label: '+683', regionCode: 'NU'},
        northKorea                  : <CountryPrefixCode>{value: '850', label: '+850', regionCode: 'KP'},
        northernMarianaIslands      : <CountryPrefixCode>{value: '1670', label: '+1-670', regionCode: 'MP'},
        norway                      : <CountryPrefixCode>{value: '47', label: '+47', regionCode: 'NO'},
        oman                        : <CountryPrefixCode>{value: '968', label: '+968', regionCode: 'OM'},
        pakistan                    : <CountryPrefixCode>{value: '92', label: '+92', regionCode: 'PK'},
        palau                       : <CountryPrefixCode>{value: '680', label: '+680', regionCode: 'PW'},
        palestine                   : <CountryPrefixCode>{value: '970', label: '+970', regionCode: 'PS'},
        panama                      : <CountryPrefixCode>{value: '507', label: '+507', regionCode: 'PA'},
        papuaNewGuinea              : <CountryPrefixCode>{value: '675', label: '+675', regionCode: 'PG'},
        paraguay                    : <CountryPrefixCode>{value: '595', label: '+595', regionCode: 'PY'},
        peru                        : <CountryPrefixCode>{value: '51', label: '+51', regionCode: 'PE'},
        philippines                 : <CountryPrefixCode>{value: '63', label: '+63', regionCode: 'PH'},
        pitcairn                    : <CountryPrefixCode>{value: '64', label: '+64', regionCode: 'PN'},
        poland                      : <CountryPrefixCode>{value: '48', label: '+48', regionCode: 'PL'},
        portugal                    : <CountryPrefixCode>{value: '351', label: '+351', regionCode: 'PT'},
        puertoRico1                 : <CountryPrefixCode>{value: '1787', label: '+1-787', regionCode: 'PR'},
        puertoRico2                 : <CountryPrefixCode>{value: '1939', label: '+1-939', regionCode: 'PR'},
        qatar                       : <CountryPrefixCode>{value: '974', label: '+974', regionCode: 'QA'},
        republicOfTheCongo          : <CountryPrefixCode>{value: '242', label: '+242', regionCode: 'CG'},
        reunion                     : <CountryPrefixCode>{value: '262', label: '+262', regionCode: 'RE'},
        romania                     : <CountryPrefixCode>{value: '40', label: '+40', regionCode: 'RO'},
        russia                      : <CountryPrefixCode>{value: '7', label: '+7', regionCode: 'RU'},
        rwanda                      : <CountryPrefixCode>{value: '250', label: '+250', regionCode: 'RW'},
        saintBarthelemy             : <CountryPrefixCode>{value: '590', label: '+590', regionCode: 'BL'},
        saintHelena                 : <CountryPrefixCode>{value: '290', label: '+290', regionCode: 'SH'},
        saintKittsAndNevis          : <CountryPrefixCode>{value: '1869', label: '+1-869', regionCode: 'KN'},
        saintLucia                  : <CountryPrefixCode>{value: '1758', label: '+1-758', regionCode: 'LC'},
        saintMartin                 : <CountryPrefixCode>{value: '590', label: '+590', regionCode: 'MF'},
        saintPierreAndMiquelon      : <CountryPrefixCode>{value: '508', label: '+508', regionCode: 'PM'},
        saintVincentAndTheGrenadines: <CountryPrefixCode>{value: '1784', label: '+1-784', regionCode: 'VC'},
        samoa                       : <CountryPrefixCode>{value: '685', label: '+685', regionCode: 'WS'},
        sanMarino                   : <CountryPrefixCode>{value: '378', label: '+378', regionCode: 'SM'},
        saoTomeAndPrincipe          : <CountryPrefixCode>{value: '239', label: '+239', regionCode: 'ST'},
        saudiArabia                 : <CountryPrefixCode>{value: '966', label: '+966', regionCode: 'SA'},
        senegal                     : <CountryPrefixCode>{value: '221', label: '+221', regionCode: 'SN'},
        serbia                      : <CountryPrefixCode>{value: '381', label: '+381', regionCode: 'RS'},
        seychelles                  : <CountryPrefixCode>{value: '248', label: '+248', regionCode: 'SC'},
        sierraLeone                 : <CountryPrefixCode>{value: '232', label: '+232', regionCode: 'SL'},
        singapore                   : <CountryPrefixCode>{value: '65', label: '+65', regionCode: 'SG'},
        sintMaarten                 : <CountryPrefixCode>{value: '1721', label: '+1-721', regionCode: 'SX'},
        slovakia                    : <CountryPrefixCode>{value: '421', label: '+421', regionCode: 'SK'},
        slovenia                    : <CountryPrefixCode>{value: '386', label: '+386', regionCode: 'SI'},
        solomonIslands              : <CountryPrefixCode>{value: '677', label: '+677', regionCode: 'SB'},
        somalia                     : <CountryPrefixCode>{value: '252', label: '+252', regionCode: 'SO'},
        southKorea                  : <CountryPrefixCode>{value: '82', label: '+82', regionCode: 'KR'},
        southSudan                  : <CountryPrefixCode>{value: '211', label: '+211', regionCode: 'SS'},
        spain                       : <CountryPrefixCode>{value: '34', label: '+34', regionCode: 'ES'},
        sriLanka                    : <CountryPrefixCode>{value: '94', label: '+94', regionCode: 'LK'},
        sudan                       : <CountryPrefixCode>{value: '249', label: '+249', regionCode: 'SD'},
        suriname                    : <CountryPrefixCode>{value: '597', label: '+597', regionCode: 'SR'},
        svalbardAndJanMayen         : <CountryPrefixCode>{value: '47', label: '+47', regionCode: 'SJ'},
        sweden                      : <CountryPrefixCode>{value: '46', label: '+46', regionCode: 'SE'},
        switzerland                 : <CountryPrefixCode>{value: '41', label: '+41', regionCode: 'CH'},
        syria                       : <CountryPrefixCode>{value: '963', label: '+963', regionCode: 'SY'},
        taiwan                      : <CountryPrefixCode>{value: '886', label: '+886', regionCode: 'TW'},
        tajikistan                  : <CountryPrefixCode>{value: '992', label: '+992', regionCode: 'TJ'},
        tanzania                    : <CountryPrefixCode>{value: '255', label: '+255', regionCode: 'TZ'},
        thailand                    : <CountryPrefixCode>{value: '66', label: '+66', regionCode: 'TH'},
        togo                        : <CountryPrefixCode>{value: '228', label: '+228', regionCode: 'TG'},
        tokelau                     : <CountryPrefixCode>{value: '690', label: '+690', regionCode: 'TK'},
        tonga                       : <CountryPrefixCode>{value: '676', label: '+676', regionCode: 'TO'},
        trinidadAndTobago           : <CountryPrefixCode>{value: '1868', label: '+1-868', regionCode: 'TT'},
        tunisia                     : <CountryPrefixCode>{value: '216', label: '+216', regionCode: 'TN'},
        turkey                      : <CountryPrefixCode>{value: '90', label: '+90', regionCode: 'TR'},
        turkmenistan                : <CountryPrefixCode>{value: '993', label: '+993', regionCode: 'TM'},
        turksAndCaicosIslands       : <CountryPrefixCode>{value: '1649', label: '+1-649', regionCode: 'TC'},
        tuvalu                      : <CountryPrefixCode>{value: '688', label: '+688', regionCode: 'TV'},
        uSVirginIslands             : <CountryPrefixCode>{value: '1340', label: '+1-340', regionCode: 'VI'},
        uganda                      : <CountryPrefixCode>{value: '256', label: '+256', regionCode: 'UG'},
        ukraine                     : <CountryPrefixCode>{value: '380', label: '+380', regionCode: 'UA'},
        unitedArabEmirates          : <CountryPrefixCode>{value: '971', label: '+971', regionCode: 'AE'},
        unitedKingdom               : <CountryPrefixCode>{value: '44', label: '+44', regionCode: 'GB'},
        unitedStates                : <CountryPrefixCode>{value: '1', label: '+1', regionCode: 'US'},
        uruguay                     : <CountryPrefixCode>{value: '598', label: '+598', regionCode: 'UY'},
        uzbekistan                  : <CountryPrefixCode>{value: '998', label: '+998', regionCode: 'UZ'},
        vanuatu                     : <CountryPrefixCode>{value: '678', label: '+678', regionCode: 'VU'},
        vatican                     : <CountryPrefixCode>{value: '379', label: '+379', regionCode: 'VA'},
        venezuela                   : <CountryPrefixCode>{value: '58', label: '+58', regionCode: 'VE'},
        vietnam                     : <CountryPrefixCode>{value: '84', label: '+84', regionCode: 'VN'},
        wallisAndFutuna             : <CountryPrefixCode>{value: '681', label: '+681', regionCode: 'WF'},
        westernSahara               : <CountryPrefixCode>{value: '212', label: '+212', regionCode: 'EH'},
        yemen                       : <CountryPrefixCode>{value: '967', label: '+967', regionCode: 'YE'},
    };

    static allRegions:CountryPrefixCode[] = [];

    static findRegionByNumber(number:string):CountryPrefixCode {
        if (number && typeof number === 'string') {
            const key = Object.keys(TelInputUtils.regions).find(key => {
                const val:CountryPrefixCode = TelInputUtils.regions[key];
                return number.indexOf(val.value) === 0;
            });
            if (key) return TelInputUtils.regions[key];
        }
        return null;
    }
}

// convert to hash array, removing duplicate values
const allRegionHash = {};
Object.keys(TelInputUtils.regions).forEach(key => {
    allRegionHash[TelInputUtils.regions[key].value] = TelInputUtils.regions[key];
});

TelInputUtils.allRegions = Object.keys(allRegionHash)
    // Remap to normal array
    .map(key => allRegionHash[key])
    // Sort using padded out numbers
    .sort((a:CountryPrefixCode, b:CountryPrefixCode) => {
        let aVal = a.value;
        let bVal = b.value;
        for (let i = a.value.length; i < 10; i++) {
            aVal += '0';
        }
        for (let i = b.value.length; i < 10; i++) {
            bVal += '0';
        }
        return aVal < bVal ? -1 : 1;
    })
;


///https://countrycode.org/


/*

const raw = `
Afghanistan\t93\tAF / AFG\t29,121,286\t647,500\t20.65 Billion
Albania\t355\tAL / ALB\t2,986,952\t28,748\t12.8 Billion
Algeria\t213\tDZ / DZA\t34,586,184\t2,381,740\t215.7 Billion
American Samoa\t1-684\tAS / ASM\t57,881\t199\t462.2 Million
Andorra\t376\tAD / AND\t84,000\t468\t4.8 Billion
Angola\t244\tAO / AGO\t13,068,161\t1,246,700\t124 Billion
Anguilla\t1-264\tAI / AIA\t13,254\t102\t175.4 Million
Antarctica\t672\tAQ / ATA\t0\t14,000,000\t
Antigua and Barbuda\t1-268\tAG / ATG\t86,754\t443\t1.22 Billion
Argentina\t54\tAR / ARG\t41,343,201\t2,766,890\t484.6 Billion
Armenia\t374\tAM / ARM\t2,968,000\t29,800\t10.44 Billion
Aruba\t297\tAW / ABW\t71,566\t193\t2.516 Billion
Australia\t61\tAU / AUS\t21,515,754\t7,686,850\t1.488 Trillion
Austria\t43\tAT / AUT\t8,205,000\t83,858\t417.9 Billion
Azerbaijan\t994\tAZ / AZE\t8,303,512\t86,600\t76.01 Billion
Bahamas\t1-242\tBS / BHS\t301,790\t13,940\t8.373 Billion
Bahrain\t973\tBH / BHR\t738,004\t665\t28.36 Billion
Bangladesh\t880\tBD / BGD\t156,118,464\t144,000\t140.2 Billion
Barbados\t1-246\tBB / BRB\t285,653\t431\t4.262 Billion
Belarus\t375\tBY / BLR\t9,685,000\t207,600\t69.24 Billion
Belgium\t32\tBE / BEL\t10,403,000\t30,510\t507.4 Billion
Belize\t501\tBZ / BLZ\t314,522\t22,966\t1.637 Billion
Benin\t229\tBJ / BEN\t9,056,010\t112,620\t8.359 Billion
Bermuda\t1-441\tBM / BMU\t65,365\t53\t5.6 Billion
Bhutan\t975\tBT / BTN\t699,847\t47,000\t2.133 Billion
Bolivia\t591\tBO / BOL\t9,947,418\t1,098,580\t30.79 Billion
Bosnia and Herzegovina\t387\tBA / BIH\t4,590,000\t51,129\t18.87 Billion
Botswana\t267\tBW / BWA\t2,029,307\t600,370\t15.53 Billion
Brazil\t55\tBR / BRA\t201,103,330\t8,511,965\t2.19 Trillion
British Indian Ocean Territory\t246\tIO / IOT\t4,000\t60\t
British Virgin Islands\t1-284\tVG / VGB\t21,730\t153\t1.095 Billion
Brunei\t673\tBN / BRN\t395,027\t5,770\t16.56 Billion
Bulgaria\t359\tBG / BGR\t7,148,785\t110,910\t53.7 Billion
Burkina Faso\t226\tBF / BFA\t16,241,811\t274,200\t12.13 Billion
Burundi\t257\tBI / BDI\t9,863,117\t27,830\t2.676 Billion
Cambodia\t855\tKH / KHM\t14,453,680\t181,040\t15.64 Billion
Cameroon\t237\tCM / CMR\t19,294,149\t475,440\t27.88 Billion
Canada\t1\tCA / CAN\t33,679,000\t9,984,670\t1.825 Trillion
Cape Verde\t238\tCV / CPV\t508,659\t4,033\t1.955 Billion
Cayman Islands\t1-345\tKY / CYM\t44,270\t262\t2.25 Billion
Central African Republic\t236\tCF / CAF\t4,844,927\t622,984\t2.05 Billion
Chad\t235\tTD / TCD\t10,543,464\t1,284,000\t13.59 Billion
Chile\t56\tCL / CHL\t16,746,491\t756,950\t281.7 Billion
China\t86\tCN / CHN\t1,330,044,000\t9,596,960\t9.33 Trillion
Christmas Island\t61\tCX / CXR\t1,500\t135\t
Cocos Islands\t61\tCC / CCK\t628\t14\t
Colombia\t57\tCO / COL\t47,790,000\t1,138,910\t369.2 Billion
Comoros\t269\tKM / COM\t773,407\t2,170\t658 Million
Cook Islands\t682\tCK / COK\t21,388\t240\t183.2 Million
Costa Rica\t506\tCR / CRI\t4,516,220\t51,100\t48.51 Billion
Croatia\t385\tHR / HRV\t4,491,000\t56,542\t59.14 Billion
Cuba\t53\tCU / CUB\t11,423,000\t110,860\t72.3 Billion
Curacao\t599\tCW / CUW\t141,766\t444\t5.6 Billion
Cyprus\t357\tCY / CYP\t1,102,677\t9,250\t21.78 Billion
Czech Republic\t420\tCZ / CZE\t10,476,000\t78,866\t194.8 Billion
Democratic Republic of the Congo\t243\tCD / COD\t70,916,439\t2,345,410\t18.56 Billion
Denmark\t45\tDK / DNK\t5,484,000\t43,094\t324.3 Billion
Djibouti\t253\tDJ / DJI\t740,528\t23,000\t1.459 Billion
Dominica\t1-767\tDM / DMA\t72,813\t754\t495 Million
Dominican Republic\t1-809, 1-829, 1-849\tDO / DOM\t9,823,821\t48,730\t59.27 Billion
East Timor\t670\tTL / TLS\t1,154,625\t15,007\t6.129 Billion
Ecuador\t593\tEC / ECU\t14,790,608\t283,560\t91.41 Billion
Egypt\t20\tEG / EGY\t80,471,869\t1,001,450\t262 Billion
El Salvador\t503\tSV / SLV\t6,052,064\t21,040\t24.67 Billion
Equatorial Guinea\t240\tGQ / GNQ\t1,014,999\t28,051\t17.08 Billion
Eritrea\t291\tER / ERI\t5,792,984\t121,320\t3.438 Billion
Estonia\t372\tEE / EST\t1,291,170\t45,226\t24.28 Billion
Ethiopia\t251\tET / ETH\t88,013,491\t1,127,127\t47.34 Billion
Falkland Islands\t500\tFK / FLK\t2,638\t12,173\t164.5 Million
Faroe Islands\t298\tFO / FRO\t48,228\t1,399\t2.32 Billion
Fiji\t679\tFJ / FJI\t875,983\t18,270\t4.218 Billion
Finland\t358\tFI / FIN\t5,244,000\t337,030\t259.6 Billion
France\t33\tFR / FRA\t64,768,389\t547,030\t2.739 Trillion
French Polynesia\t689\tPF / PYF\t270,485\t4,167\t5.65 Billion
Gabon\t241\tGA / GAB\t1,545,255\t267,667\t19.97 Billion
Gambia\t220\tGM / GMB\t1,593,256\t11,300\t896 Million
Georgia\t995\tGE / GEO\t4,630,000\t69,700\t15.95 Billion
Germany\t49\tDE / DEU\t81,802,257\t357,021\t3.593 Trillion
Ghana\t233\tGH / GHA\t24,339,838\t239,460\t45.55 Billion
Gibraltar\t350\tGI / GIB\t27,884\t7\t1.106 Billion
Greece\t30\tGR / GRC\t11,000,000\t131,940\t243.3 Billion
Greenland\t299\tGL / GRL\t56,375\t2,166,086\t2.16 Billion
Grenada\t1-473\tGD / GRD\t107,818\t344\t811 Million
Guam\t1-671\tGU / GUM\t159,358\t549\t4.6 Billion
Guatemala\t502\tGT / GTM\t13,550,440\t108,890\t53.9 Billion
Guernsey\t44-1481\tGG / GGY\t65,228\t78\t2.742 Billion
Guinea\t224\tGN / GIN\t10,324,025\t245,857\t6.544 Billion
Guinea-Bissau\t245\tGW / GNB\t1,565,126\t36,120\t880 Million
Guyana\t592\tGY / GUY\t748,486\t214,970\t3.02 Billion
Haiti\t509\tHT / HTI\t9,648,924\t27,750\t8.287 Billion
Honduras\t504\tHN / HND\t7,989,415\t112,090\t18.88 Billion
Hong Kong\t852\tHK / HKG\t6,898,686\t1,092\t272.1 Billion
Hungary\t36\tHU / HUN\t9,982,000\t93,030\t130.6 Billion
Iceland\t354\tIS / ISL\t308,910\t103,000\t14.59 Billion
India\t91\tIN / IND\t1,173,108,018\t3,287,590\t1.67 Trillion
Indonesia\t62\tID / IDN\t242,968,342\t1,919,440\t867.5 Billion
Iran\t98\tIR / IRN\t76,923,300\t1,648,000\t411.9 Billion
Iraq\t964\tIQ / IRQ\t29,671,605\t437,072\t221.8 Billion
Ireland\t353\tIE / IRL\t4,622,917\t70,280\t220.9 Billion
Isle of Man\t44-1624\tIM / IMN\t75,049\t572\t4.076 Billion
Israel\t972\tIL / ISR\t7,353,985\t20,770\t272.7 Billion
Italy\t39\tIT / ITA\t60,340,328\t301,230\t2.068 Trillion
Ivory Coast\t225\tCI / CIV\t21,058,798\t322,460\t28.28 Billion
Jamaica\t1-876\tJM / JAM\t2,847,232\t10,991\t14.39 Billion
Japan\t81\tJP / JPN\t127,288,000\t377,835\t5.007 Trillion
Jersey\t44-1534\tJE / JEY\t90,812\t116\t5.1 Billion
Jordan\t962\tJO / JOR\t6,407,085\t92,300\t34.08 Billion
Kazakhstan\t7\tKZ / KAZ\t15,340,000\t2,717,300\t224.9 Billion
Kenya\t254\tKE / KEN\t40,046,566\t582,650\t45.31 Billion
Kiribati\t686\tKI / KIR\t92,533\t811\t173 Million
Kosovo\t383\tXK / XKX\t1,800,000\t10,887\t7.15 Billion
Kuwait\t965\tKW / KWT\t2,789,132\t17,820\t179.5 Billion
Kyrgyzstan\t996\tKG / KGZ\t5,508,626\t198,500\t7.234 Billion
Laos\t856\tLA / LAO\t6,368,162\t236,800\t10.1 Billion
Latvia\t371\tLV / LVA\t2,217,969\t64,589\t30.38 Billion
Lebanon\t961\tLB / LBN\t4,125,247\t10,400\t43.49 Billion
Lesotho\t266\tLS / LSO\t1,919,552\t30,355\t2.457 Billion
Liberia\t231\tLR / LBR\t3,685,076\t111,370\t1.977 Billion
Libya\t218\tLY / LBY\t6,461,454\t1,759,540\t70.92 Billion
Liechtenstein\t423\tLI / LIE\t35,000\t160\t5.113 Billion
Lithuania\t370\tLT / LTU\t2,944,459\t65,200\t46.71 Billion
Luxembourg\t352\tLU / LUX\t497,538\t2,586\t60.54 Billion
Macau\t853\tMO / MAC\t449,198\t254\t51.68 Billion
Macedonia\t389\tMK / MKD\t2,062,294\t25,333\t10.65 Billion
Madagascar\t261\tMG / MDG\t21,281,844\t587,040\t10.53 Billion
Malawi\t265\tMW / MWI\t15,447,500\t118,480\t3.683 Billion
Malaysia\t60\tMY / MYS\t28,274,729\t329,750\t312.4 Billion
Maldives\t960\tMV / MDV\t395,650\t300\t2.27 Billion
Mali\t223\tML / MLI\t13,796,354\t1,240,000\t11.37 Billion
Malta\t356\tMT / MLT\t403,000\t316\t9.541 Billion
Marshall Islands\t692\tMH / MHL\t65,859\t181\t193 Million
Mauritania\t222\tMR / MRT\t3,205,060\t1,030,700\t4.183 Billion
Mauritius\t230\tMU / MUS\t1,294,104\t2,040\t11.9 Billion
Mayotte\t262\tYT / MYT\t159,042\t374\t
Mexico\t52\tMX / MEX\t112,468,855\t1,972,550\t1.327 Trillion
Micronesia\t691\tFM / FSM\t107,708\t702\t339 Million
Moldova\t373\tMD / MDA\t4,324,000\t33,843\t7.932 Billion
Monaco\t377\tMC / MCO\t32,965\t2\t5.748 Billion
Mongolia\t976\tMN / MNG\t3,086,918\t1,565,000\t11.14 Billion
Montenegro\t382\tME / MNE\t666,730\t14,026\t4.518 Billion
Montserrat\t1-664\tMS / MSR\t9,341\t102\t
Morocco\t212\tMA / MAR\t31,627,428\t446,550\t104.8 Billion
Mozambique\t258\tMZ / MOZ\t22,061,451\t801,590\t14.67 Billion
Myanmar\t95\tMM / MMR\t53,414,374\t678,500\t59.43 Billion
Namibia\t264\tNA / NAM\t2,128,471\t825,418\t12.3 Billion
Nauru\t674\tNR / NRU\t10,065\t21\t
Nepal\t977\tNP / NPL\t28,951,852\t140,800\t19.34 Billion
Netherlands\t31\tNL / NLD\t16,645,000\t41,526\t722.3 Billion
Netherlands Antilles\t599\tAN / ANT\t136,197\t960\t
New Caledonia\t687\tNC / NCL\t216,494\t19,060\t9.28 Billion
New Zealand\t64\tNZ / NZL\t4,252,277\t268,680\t181.1 Billion
Nicaragua\t505\tNI / NIC\t5,995,928\t129,494\t11.26 Billion
Niger\t227\tNE / NER\t15,878,271\t1,267,000\t7.304 Billion
Nigeria\t234\tNG / NGA\t154,000,000\t923,768\t502 Billion
Niue\t683\tNU / NIU\t2,166\t260\t10.01 Million
North Korea\t850\tKP / PRK\t22,912,177\t120,540\t28 Billion
Northern Mariana Islands\t1-670\tMP / MNP\t53,883\t477\t733 Million
Norway\t47\tNO / NOR\t5,009,150\t324,220\t515.8 Billion
Oman\t968\tOM / OMN\t2,967,717\t212,460\t81.95 Billion
Pakistan\t92\tPK / PAK\t184,404,791\t803,940\t236.5 Billion
Palau\t680\tPW / PLW\t19,907\t458\t221 Million
Palestine\t970\tPS / PSE\t3,800,000\t5,970\t6.641 Billion
Panama\t507\tPA / PAN\t3,410,676\t78,200\t40.62 Billion
Papua New Guinea\t675\tPG / PNG\t6,064,515\t462,840\t16.1 Billion
Paraguay\t595\tPY / PRY\t6,375,830\t406,750\t30.56 Billion
Peru\t51\tPE / PER\t29,907,003\t1,285,220\t210.3 Billion
Philippines\t63\tPH / PHL\t99,900,177\t300,000\t272.2 Billion
Pitcairn\t64\tPN / PCN\t46\t47\t
Poland\t48\tPL / POL\t38,500,000\t312,685\t513.9 Billion
Portugal\t351\tPT / PRT\t10,676,000\t92,391\t219.3 Billion
Puerto Rico\t1-787, 1-939\tPR / PRI\t3,916,632\t9,104\t93.52 Billion
Qatar\t974\tQA / QAT\t840,926\t11,437\t213.1 Billion
Republic of the Congo\t242\tCG / COG\t3,039,126\t342,000\t14.25 Billion
Reunion\t262\tRE / REU\t776,948\t2,517\t
Romania\t40\tRO / ROU\t21,959,278\t237,500\t188.9 Billion
Russia\t7\tRU / RUS\t140,702,000\t17,100,000\t2.113 Trillion
Rwanda\t250\tRW / RWA\t11,055,976\t26,338\t7.7 Billion
Saint Barthelemy\t590\tBL / BLM\t8,450\t21\t
Saint Helena\t290\tSH / SHN\t7,460\t410\t
Saint Kitts and Nevis\t1-869\tKN / KNA\t51,134\t261\t767 Million
Saint Lucia\t1-758\tLC / LCA\t160,922\t616\t1.377 Billion
Saint Martin\t590\tMF / MAF\t35,925\t53\t561.5 Million
Saint Pierre and Miquelon\t508\tPM / SPM\t7,012\t242\t215.3 Million
Saint Vincent and the Grenadines\t1-784\tVC / VCT\t104,217\t389\t742 Million
Samoa\t685\tWS / WSM\t192,001\t2,944\t705 Million
San Marino\t378\tSM / SMR\t31,477\t61\t1.866 Billion
Sao Tome and Principe\t239\tST / STP\t175,808\t1,001\t311 Million
Saudi Arabia\t966\tSA / SAU\t25,731,776\t1,960,582\t718.5 Billion
Senegal\t221\tSN / SEN\t12,323,252\t196,190\t15.36 Billion
Serbia\t381\tRS / SRB\t7,344,847\t88,361\t43.68 Billion
Seychelles\t248\tSC / SYC\t88,340\t455\t1.271 Billion
Sierra Leone\t232\tSL / SLE\t5,245,695\t71,740\t4.607 Billion
Singapore\t65\tSG / SGP\t4,701,069\t693\t295.7 Billion
Sint Maarten\t1-721\tSX / SXM\t37,429\t34\t794.7 Million
Slovakia\t421\tSK / SVK\t5,455,000\t48,845\t96.96 Billion
Slovenia\t386\tSI / SVN\t2,007,000\t20,273\t46.82 Billion
Solomon Islands\t677\tSB / SLB\t559,198\t28,450\t1.099 Billion
Somalia\t252\tSO / SOM\t10,112,453\t637,657\t2.372 Billion
South Africa\t27\tZA / ZAF\t49,000,000\t1,219,912\t353.9 Billion
South Korea\t82\tKR / KOR\t48,422,644\t98,480\t1.198 Trillion
South Sudan\t211\tSS / SSD\t8,260,490\t644,329\t11.77 Billion
Spain\t34\tES / ESP\t46,505,963\t504,782\t1.356 Trillion
Sri Lanka\t94\tLK / LKA\t21,513,990\t65,610\t65.12 Billion
Sudan\t249\tSD / SDN\t35,000,000\t1,861,484\t52.5 Billion
Suriname\t597\tSR / SUR\t492,829\t163,270\t5.009 Billion
Svalbard and Jan Mayen\t47\tSJ / SJM\t2,550\t62,049\t
Swaziland\t268\tSZ / SWZ\t1,354,051\t17,363\t3.807 Billion
Sweden\t46\tSE / SWE\t9,555,893\t449,964\t552 Billion
Switzerland\t41\tCH / CHE\t7,581,000\t41,290\t646.2 Billion
Syria\t963\tSY / SYR\t22,198,110\t185,180\t64.7 Billion
Taiwan\t886\tTW / TWN\t22,894,384\t35,980\t484.7 Billion
Tajikistan\t992\tTJ / TJK\t7,487,489\t143,100\t8.513 Billion
Tanzania\t255\tTZ / TZA\t41,892,895\t945,087\t31.94 Billion
Thailand\t66\tTH / THA\t67,089,500\t514,000\t400.9 Billion
Togo\t228\tTG / TGO\t6,587,239\t56,785\t4.299 Billion
Tokelau\t690\tTK / TKL\t1,466\t10\t
Tonga\t676\tTO / TON\t122,580\t748\t477 Million
Trinidad and Tobago\t1-868\tTT / TTO\t1,228,691\t5,128\t27.13 Billion
Tunisia\t216\tTN / TUN\t10,589,025\t163,610\t48.38 Billion
Turkey\t90\tTR / TUR\t77,804,122\t780,580\t821.8 Billion
Turkmenistan\t993\tTM / TKM\t4,940,916\t488,100\t40.56 Billion
Turks and Caicos Islands\t1-649\tTC / TCA\t20,556\t430\t
Tuvalu\t688\tTV / TUV\t10,472\t26\t38 Million
U.S. Virgin Islands\t1-340\tVI / VIR\t108,708\t352\t
Uganda\t256\tUG / UGA\t33,398,682\t236,040\t22.6 Billion
Ukraine\t380\tUA / UKR\t45,415,596\t603,700\t175.5 Billion
United Arab Emirates\t971\tAE / ARE\t4,975,593\t82,880\t390 Billion
United Kingdom\t44\tGB / GBR\t62,348,447\t244,820\t2.49 Trillion
United States\t1\tUS / USA\t310,232,863\t9,629,091\t16.72 Trillion
Uruguay\t598\tUY / URY\t3,477,000\t176,220\t57.11 Billion
Uzbekistan\t998\tUZ / UZB\t27,865,738\t447,400\t55.18 Billion
Vanuatu\t678\tVU / VUT\t221,552\t12,200\t828 Million
Vatican\t379\tVA / VAT\t921\t0\t
Venezuela\t58\tVE / VEN\t27,223,228\t912,050\t367.5 Billion
Vietnam\t84\tVN / VNM\t89,571,130\t329,560\t170 Billion
Wallis and Futuna\t681\tWF / WLF\t16,025\t274\t
Western Sahara\t212\tEH / ESH\t273,008\t266,000\t
Yemen\t967\tYE / YEM\t23,495,361\t527,970\t43.89 Billion
Zambia\t260\tZM / ZMB\t13,460,305\t752,614\t22.24 Billion
Zimbabwe\t263\tZW / ZWE\t11,651,858\t390,580\t10.48 Billion
`;

const excludeHash = {
    southAfrica: true,
    botswana   : true,
    lesotho    : true,
    namibia    : true,
    swaziland  : true,
    zambia     : true,
    zimbabwe   : true,
};
let values        = '';
raw
    .split('\n')
    .filter(v => v != '' && v != null)
    .forEach(line => {
        const parts      = line.split('\t');
        const regionCode = parts[2].split(' / ')[0];

        const name       = parts[0]
            .split(' ')
            .map(v => v.replace(/\./g, '').replace(/-/g, ''))
            .map((v, i) => {
                if (i === 0) {
                    return v.charAt(0).toLowerCase() + v.substring(1);
                }
                else {
                    return v.charAt(0).toUpperCase() + v.substring(1);
                }
            })
            .join('');

        if (excludeHash[name] == null) {
            const codes = parts[1].split(',');
            codes.forEach((code, i) => {
                code          = code.trim();
                const newName = codes.length === 1 ? name : name + (i + 1);
                values += `${newName}: <CountryPrefixCode>{value: '${code.replace(/[^0-9]/g, '')}', label: '+${code}', regionCode: '${regionCode}'},\n`;
            });
        }
    });


console.log(values);
*/
