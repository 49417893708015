import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import produce from 'immer';
import {ParsedCategories} from './magento-logic.util';
import {PepkorCategoryapiDataCategoryapiTreeInterface} from './magento.types';
import {CategoryHash} from './magento.reducer.types';

export namespace Magento {
    export class StoreCategories {
        static readonly type = '[Magento] Store Categories';

        constructor(public parsedCategories:ParsedCategories) {
        }
    }

}

export interface MagentoStateModel {
    categoryTree?:PepkorCategoryapiDataCategoryapiTreeInterface;
    categoryIDHash?:CategoryHash;
    categoryUrlKeyHash?:CategoryHash;
}

@State<MagentoStateModel>({
    name    : 'magento',
    defaults: {}
})
@Injectable()
export class MagentoState {

    @Action(Magento.StoreCategories)
    storeCategories(ctx:StateContext<MagentoStateModel>, action:Magento.StoreCategories) {
        ctx.setState(produce(draft => {
            draft.categoryTree       = action.parsedCategories.categoryTree;
            draft.categoryIDHash     = action.parsedCategories.categoryIDHash;
            draft.categoryUrlKeyHash = action.parsedCategories.categoryUrlKeyHash;
        }));
    }
}
