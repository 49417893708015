import {gql} from 'apollo-angular';

export const WP_GET_TOKEN = gql`
    mutation wpToken($username:String, $password:String) {
        wpToken(username:$username, password:$password){
            token
            user_display_name
            user_email
            user_nicename
        }
    }
`;
