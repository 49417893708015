import {
    FMMenuBlogButtonUI,
    FMMenuCategoryButtonUI,
    FMMenuCustomButtonUI
} from '../external-data/firebase/firebase-ui.types';
import {
    FMConfigRedirect,
    FMCustomPageSEOData,
    FMFooterPageTypeEnum,
    FMMenuElementTypeEnum
} from '../external-data/firebase/firebase.types';
import {IProductModel} from '../external-data/magento/model/iproduct.model';
import {StaticConfig} from './static-config';
import {BreadcrumbLink} from './ui/breadcrumb/breadcrumb.types';
import {
    AccountPageEnum,
    AuthPageEnum,
    BlogPageEnum,
    CheckoutAuthPageEnum,
    CheckoutPageEnum,
    PageTypeEnum,
    PageTypeVariables,
    PlusMorePageEnum,
    ProductsDisplayPageEnum,
    SearchPageEnum
} from '../router/page-type.enum';
import {ProductListUrlSerializer} from '../product-list-page/product-list-url-serializer';
import {environment} from '../implementation/environment';
import {WebPManager} from '../webp/webp-manager';
import {UrlSegment} from '@angular/router';
import {
    CategoryOrTag,
    ESWPSearch,
    WordpressPage,
    WordpressPost
} from '../external-data/wordpress/model/wordpress.types';
import {PepkorCategoryapiDataCategoryapiTreeInterface} from '../external-data/magento/magento.types';
import {AuthType} from '../auth/components/auth.types';
import {CAME_FROM_LOGIN} from '../auth/components/abstract-auth.constants';
import {CategoryHash} from '../external-data/magento/magento.reducer.types';
import {DateUtil} from './util/date-util';
import {LoggerImplCore} from './logger-impl-core';
import {WindowRef} from './ui/window-ref';

export interface PathSegment {
    path:string;
}

interface CDNUrls {
    nativeAssetPath:string;
}

/**
 * This is the Url service with all the decencies abstracted into simple getters
 * so the business logic can be reused outside of the angular application
 */
export abstract class AbstractUrlService {

    private urls:CDNUrls;

    constructor(public logger:LoggerImplCore) {

    }

    /*
    General URL Building
    --------------------------------------------
    */
    public buildMenuElementUrl(element:FMMenuCustomButtonUI | FMMenuCategoryButtonUI | FMMenuBlogButtonUI) {
        const parts = [];

        switch (element.type) {
            case FMMenuElementTypeEnum.CATEGORY_BUTTON:
                const categoryButton = element as FMMenuCategoryButtonUI;
                return this.buildUrlForProductList(categoryButton.category);

            case FMMenuElementTypeEnum.CUSTOM_BUTTON:
                const customButton = element as FMMenuCustomButtonUI;
                parts.push(customButton.builtInPageType);

                switch (customButton.builtInPageType) {
                    case PageTypeEnum.CUSTOM:
                        const urlKey = (customButton.anchor && customButton.anchor.length > 0) ? customButton.urlKey + '#' + customButton.anchor : customButton.urlKey;
                        parts.push(urlKey);
                        break;

                    case FMFooterPageTypeEnum.CDN_LINK:
                        return this.buildUrlForCDNRoot(customButton.pathAfterType);
                    // For an external link the entire link will be defined in the pathAfterType
                    case FMFooterPageTypeEnum.FULL_LINK:
                        return customButton.pathAfterType;
                    // For all other built in types
                    default:
                        parts.push(customButton.pathAfterType);
                        break;
                }
                break;

            case FMMenuElementTypeEnum.BLOG_BUTTON:
                return this.buildUrlForBlog();
        }
        return this.joinParts(this.cleanParts(parts));
    }

    public buildUrlForBuiltIn(builtInPageType:string, urlKey?:string, urlKey2?:string, urlKey3?:string) {
        return '/' + this.cleanParts([builtInPageType, urlKey, urlKey2, urlKey3]).join('/');
    }

    public buildRedirect(redirect:string) {
        return (redirect ? `?${PageTypeVariables.REDIRECT}=${encodeURIComponent(redirect)}` : '');
    }

    public buildUrlForHome() {
        return this.buildUrlForBuiltIn(PageTypeEnum.HOME_SEGMENT);
    }

    public buildUrlForBlog() {
        return this.buildUrlForBuiltIn(PageTypeEnum.BLOG);
    }

    public buildUrlForContact() {
        return this.buildUrlForBuiltIn(PageTypeEnum.CONTACT_US);
    }

    public buildUrlForStoreFinder() {
        return this.buildUrlForBuiltIn(PageTypeEnum.STORE_FINDER);
    }

    public buildUrlForStoreDirectory() {
        return this.buildUrlForBuiltIn(PageTypeEnum.STORE_DIRECTORY);
    }

    public buildUrlForCustomPage(urlKey:string) {
        return this.buildUrlForBuiltIn(PageTypeEnum.CUSTOM, urlKey);
    }

    public buildUrlForCatalogue(urlKey:string) {
        return this.buildUrlForBuiltIn(PageTypeEnum.CATALOGUE);
    }

    public buildUrlForCustomPageFromId(id:string) {
        const customPageSeoDataItem = this.customPageSEOData.find(item => item.id + '' === id);
        if (customPageSeoDataItem) return this.buildUrlForCustomPage(customPageSeoDataItem.urlKey);
        return null;
    }


    /*
    Auth / Account
    --------------------------------------------
    */
    public buildUrlForAuthRegister(authType:AuthType) {
        if (authType === AuthType.STANDARD) {
            return this.buildUrlForBuiltIn(PageTypeEnum.AUTH, AuthPageEnum.REGISTER);
        }
        else if (authType === AuthType.CHECKOUT || AuthType.RE_AUTHENTICATE) {
            return this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.AUTH, CheckoutAuthPageEnum.AUTH_REGISTER);
        }
    }

    public buildUrlForAuthLogin(authType:AuthType = AuthType.STANDARD, redirect?:string) {
        if (authType === AuthType.STANDARD) {
            return this.buildUrlForBuiltIn(PageTypeEnum.AUTH, AuthPageEnum.LOGIN) + this.buildRedirect(redirect);
        }
        else if (authType === AuthType.CHECKOUT || AuthType.RE_AUTHENTICATE) {
            return this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.AUTH, CheckoutAuthPageEnum.AUTH_LOGIN) + this.buildRedirect(redirect);
        }
    }

    public buildUrlForAuthOTP(authType:AuthType, cameFromLogin = false, orderId?:string) {
        let url;
        if (authType === AuthType.STANDARD) {
            if (orderId) {
                url = this.buildUrlForBuiltIn(PageTypeEnum.AUTH, AuthPageEnum.OTP, orderId);
            }
            else {
                url = this.buildUrlForBuiltIn(PageTypeEnum.AUTH, AuthPageEnum.OTP);
            }
        }
        else if (authType === AuthType.CHECKOUT || AuthType.RE_AUTHENTICATE || AuthType.GUEST_CHECKOUT) {
            if (orderId) {
                url = this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.AUTH, CheckoutAuthPageEnum.AUTH_OTP, orderId);
            }
            else {
                url = this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.AUTH, CheckoutAuthPageEnum.AUTH_OTP);
            }
        }
        if (cameFromLogin) url += `?${CAME_FROM_LOGIN}=true`;
        return url;
    }


    public buildUrlForAuthForgot(authType:AuthType, currentUrl:string) {
        // RE_AUTHENTICATE, forgot password, should go to the checkout flow if inside any of the checkout sections
        // We also include a redirect url to go back to the current page at the end
        if (authType === AuthType.CHECKOUT && this.isInCheckoutSection(currentUrl)) {
            return this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.AUTH, CheckoutAuthPageEnum.AUTH_FORGOTPASS) + this.buildRedirect(currentUrl);
        }
        // Everything else to the normal flow
        else {
            return this.buildUrlForBuiltIn(PageTypeEnum.AUTH, AuthPageEnum.FORGOT);
        }
    }

    public buildUrlForAuthForgotPassOTP(authType:AuthType, redirect?:string) {
        if (authType === AuthType.STANDARD) {
            return this.buildUrlForBuiltIn(PageTypeEnum.AUTH, AuthPageEnum.FORGOT, AuthPageEnum.OTP) + this.buildRedirect(redirect);
        }
        else if (authType === AuthType.CHECKOUT || AuthType.RE_AUTHENTICATE) {
            return this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.AUTH, CheckoutAuthPageEnum.AUTH_FORGOTPASS_OTP) + this.buildRedirect(redirect);
        }
    }

    // The root page of account details, not the same for desktop & mobile
    public buildUrlForAccountRoot(isPlusMore = false) {
        this.logger.info(`Build menu mobile: ${this.isMobile}, is mobileapp: ${this.isMobileApp}`);
        const query = isPlusMore ? '?promotion=plusmore' : '';
        if (this.isMobile) {
            return this.buildUrlForAccountMenu() + query;
        }
        else {
            return this.buildUrlForAccountDetails() + query;
        }
    }

    public buildUrlForAccountMenu() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.MENU);
    }

    public buildUrlForAccountDetails() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.ACCOUNT_DETAILS);
    }

    public buildUrlForAccountEditDetails() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.ACCOUNT_EDIT, AccountPageEnum.EDIT_DETAILS);
    }

    public buildUrlForAccountEditEmail() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.ACCOUNT_EDIT, AccountPageEnum.EDIT_EMAIL);
    }

    public buildUrlForAccountEditCell() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.ACCOUNT_EDIT, AccountPageEnum.EDIT_CELL);
    }

    public buildUrlForAccountEditCellOtp() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.ACCOUNT_EDIT, AccountPageEnum.EDIT_CELL_OTP);
    }

    public buildUrlForAccountEditPassword() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.ACCOUNT_EDIT, AccountPageEnum.EDIT_PASSWORD);
    }

    public buildUrlForAccountLink() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.ACCOUNT_EDIT, AccountPageEnum.LINK_ID);
    }

    public buildUrlForAccountPlusMoreLink() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, PageTypeEnum.PLUS_MORE, PlusMorePageEnum.PROFILE_LINK);
    }

    public buildUrlForAccountPlusMoreRewards() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, PageTypeEnum.PLUS_MORE, PlusMorePageEnum.PROFILE);
    }

    public buildUrlForAccountOrders() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.ORDERS);
    }

    public buildUrlForOnlineCreditHistory() {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.ONLINE_STORE_CREDIT);
    }

    public buildUrlForAccountOrder(orderNumber:string) {
        return this.buildUrlForBuiltIn(PageTypeEnum.ACCOUNT, AccountPageEnum.ORDERS, orderNumber);
    }

    public buildUrlForIFrame(urlKey:string) {
        return this.buildUrlForBuiltIn(PageTypeEnum.IFRAME, urlKey);
    }


    /*
    Products
    --------------------------------------------
    */
    public buildUrlForCategoryId(categoryId:number) {
        return this.buildUrlForProductList(this.categoryIDHash[categoryId]);
    }

    public buildUrlForProductList(category:PepkorCategoryapiDataCategoryapiTreeInterface) {
        return this.buildUrlForBuiltIn(PageTypeEnum.PRODUCT_LIST, this.buildProductListUrlKey(category));
    }

    public buildUrlForProductListWithAttributes(urlSerializer:ProductListUrlSerializer) {
        const category = this.categoryIDHash[urlSerializer.categoryId];
        return this.buildUrlForBuiltIn(PageTypeEnum.PRODUCT_LIST, this.buildProductListUrlKey(category)) + '?' + urlSerializer.serializeParamsForUrl(false);
    }


    public buildProductListUrlKey(category:PepkorCategoryapiDataCategoryapiTreeInterface):string {
        return this.buildProductListParentList(category)
            .filter(cat => cat.url_key != null && cat.url_key !== '')
            .map(cat => cat.url_key)
            .map(cat => encodeURI(cat)) // May contain forward slashes
            .join('/');
    }

    public buildProductListParentList(category:PepkorCategoryapiDataCategoryapiTreeInterface):PepkorCategoryapiDataCategoryapiTreeInterface[] {
        // build a url using the parents
        let hasParents = category.level > 2 && category.parent_id;
        let parentId   = category.parent_id;
        const parents  = [category];
        let iterations = 0;

        while (hasParents) {
            const parent = this.categoryIDHash[parentId];
            // Sanity check
            if (parent == null) break;
            // Add the parent url key
            parents.unshift(parent);
            parentId   = parent.parent_id;
            hasParents = parent.level > 2 && parent.parent_id;
            iterations++;
            if (iterations > 15) break;
        }

        return parents;
    }

    public findCategoryFromFullProductUrl(url:string, product:IProductModel):PepkorCategoryapiDataCategoryapiTreeInterface {
        // First trim the url of the product
        let catUrl  = this.removeUpTo(url, PageTypeEnum.PRODUCT + '/');
        // Now trim the end portion as this is the sku
        const parts = catUrl.split('/').filter(v => v != null && v !== '');
        parts.pop();
        catUrl = parts.join('/');
        // Now look to see if the product has a url key in the portion
        if (product.urlKey != null) {
            catUrl = this.removeSegment(catUrl, product.urlKey);
        }

        return this.findCategoryFromUrl(catUrl, this.categoryTree);
    }

    public findCategoryFromUrl(categoryUrl:string, categoryTree:PepkorCategoryapiDataCategoryapiTreeInterface):PepkorCategoryapiDataCategoryapiTreeInterface {
        // First clean the url
        let workingUrl = this.cleanSegment(categoryUrl);

        // Now run through the tree looking for each part
        let currentLevel = categoryTree.children_data;

        let category:PepkorCategoryapiDataCategoryapiTreeInterface = null;
        this.logger.test('findCategoryFromUrl(): ' + categoryUrl);

        while (workingUrl !== '') {
            // Now lets look for the child in currentLevel
            let found = false;
            this.logger.test('while iteration: ' + workingUrl);

            for (let c = 0; c < currentLevel.length; c++) {
                const urlKey          = this.cleanSegment(currentLevel[c].url_key);
                const endOfURL        = (workingUrl.length === urlKey.length);
                const nextCharForward = !endOfURL && workingUrl.charAt(urlKey.length) === '/';

                if (workingUrl.indexOf(urlKey) === 0 && (endOfURL || nextCharForward)) {
                    found = true;

                    this.logger.test('Found portion: ' + urlKey);

                    // Strip the url key from the workingUrl
                    workingUrl = this.cleanSegment(this.removeUpTo(workingUrl, urlKey));

                    // If no more url then assign the category
                    if (workingUrl === '') {
                        category = currentLevel[c];
                    }
                    // Otherwise the new level to search
                    else {
                        currentLevel = currentLevel[c].children_data;
                    }
                    break;
                }
            }
            if (!found) {
                this.logger.error('Unable to find category for url key portion: ', workingUrl);
                break;
            }
        }

        return category;
    }

    public buildUrlForCDNRoot(documentPath:string):string {
        return this.absURL(this.cdnRootUrl + documentPath);
    }

    public buildUrlForMagentoImage(imagePath:string, width:any, convertToJpg:boolean = true):string {
        if (imagePath) {
            // Work around for testing search before its ready, not going to hurt anything
            if (imagePath.indexOf('http://') === 0 || imagePath.indexOf('https://') === 0) return imagePath;
            let base = this.magentoImageBaseUrl;
            // Correct the slashes initial has no trailing slash path does
            if (base.charAt(base.length - 1) === '/') base = base.substr(0, base.length - 1);

            // Temp to address multiple images bug
            imagePath = imagePath.split(',').filter(v => v !== '').map(v => v.trim())[0];

            // Drop any query params from the path
            imagePath = imagePath.split('?')[0];

            // find the base name
            const sections = imagePath.split('/').filter(v => v !== '' && v != null);
            const filename = sections[sections.length - 1];
            let ext        = filename.lastIndexOf('.') !== -1 ? filename.substr(filename.lastIndexOf('.')) : '';
            const name     = filename.lastIndexOf('.') !== -1 ? filename.substr(0, filename.lastIndexOf('.')) : filename;

            if (environment.config.api.imagekitProductImagesEnabled) {
                // TODO Remove: Hardcoding this for now, for Ackermans specifically until PIM is sending the extension through
                if (ext == '') imagePath = `${imagePath}.png`;
                return `${environment.config.api.imagekitProductImagesUrl}/${imagePath}?${this.getImageTransformations({width: width, pixelRatio: this.windowRef.getDevicePixelRatio()})}`;
            }
            else {
                if (convertToJpg === true) ext = '.jpg';

                sections[sections.length - 1] = `${width}_${width}_${name}${ext}`;

                return WebPManager.convertPathIfSupported(this.absURL(base + '/' + sections.join('/')));
            }
        }
        else {
            // return this.absURL('/assets/images/general/no-product-image.b83a7d0d988a88383d5636b68bbab5dc.gif');
            return this.buildUrlForAsset('/assets/images/general/no-product-image.b83a7d0d988a88383d5636b68bbab5dc.gif');
        }
    }

    public getImageTransformations(options:{ width?:number, height?:number, pixelRatio:number }) {
        const format = (WebPManager.supported) ? 'webp' : 'jpg';
        return `tr=w-${options.width ?? 'auto'},${options.height ? 'h-' + options.height + ',' : ''}bg-FFFFFF,f-${format},dpr-${options.pixelRatio}`;
    }

    public getVideoTransformations(mute = true) {
        if (mute) {
            return `tr=ac-none`;
        }
        else {
            return ``;
        }
    }

    public buildUrlForBrandLogo(imageName:string) {
        return this.absURL(this.joinParts([this.brandLogoImageBaseUrl, imageName]));
    }

    public buildUrlForProduct(product:IProductModel, category?:PepkorCategoryapiDataCategoryapiTreeInterface) {
        category    = this.deriveUrlCategory(product, category);
        const parts = [PageTypeEnum.PRODUCT];
        if (category) parts.push(this.buildProductListUrlKey(category));
        parts.push(encodeURIComponent(product.urlKey));
        return this.joinParts(this.cleanParts(parts));
    }

    public buildUrlForProductCanonical(product:IProductModel) {
        const parts = [PageTypeEnum.PRODUCT];
        parts.push(encodeURIComponent(product.urlKey));
        return this.joinParts(this.cleanParts(parts));
    }

    public buildCategoryHierarchUrlForProduct(product:IProductModel, category?:PepkorCategoryapiDataCategoryapiTreeInterface) {
        category    = this.deriveUrlCategory(product, category);
        const parts = [];
        if (category) parts.push(this.buildProductListUrlKey(category));
        return this.joinParts(this.cleanParts(parts));
    }

    public buildUrlForSearchResults(searchTerm:string, queryString:string = '') {
        return this.joinParts(this.cleanParts([PageTypeEnum.SEARCH_RESULTS, SearchPageEnum.PRODUCTS, searchTerm])) + queryString;
    }

    public buildUrlForProductDisplayList(searchTerm:string, queryString:string = '') {
        return this.joinParts(this.cleanParts([PageTypeEnum.PRODUCT_LIST, ProductsDisplayPageEnum.LIST, searchTerm])) + queryString;
    }

    public buildUrlForProductDisplayTerm(searchTerm:string, queryString:string = '') {
        return this.joinParts(this.cleanParts([PageTypeEnum.PRODUCT_LIST, ProductsDisplayPageEnum.TERM, searchTerm])) + queryString;
    }

    public buildUrlForSearchPageResults(searchTerm:string) {
        return this.joinParts(this.cleanParts([PageTypeEnum.SEARCH_RESULTS, SearchPageEnum.PAGES, searchTerm]));
    }

    public buildDirectionsLink(latitude, longitude) {
        return StaticConfig.GOOGLE_DIRECTIONS_BASE_URL + latitude + ',' + longitude;
    }

    public buildUrlForCanonical(url:string, params:string = '') {
        return this.websiteBasePath + url.split('?')[0] + (params.length > 0 ? '?' + params : '');
    }


    public get noImageSmall():string {
        return this.absURL('/assets/images/general/no-image-small.226e8af08d4c6e1ebf33542a1ce1bbe0.gif');
    }

    public get noImageBannerHome():string {
        return this.absURL('/assets/images/general/no-image-banner-home.5c7ff0079a418985ea545e475752ee6d.gif');
    }

    /*
    Checkout
    --------------------------------------------
    */
    public buildUrlForCheckoutCart() {
        return this.buildUrlForBuiltIn(PageTypeEnum.CART);
    }

    public buildUrlForCheckoutAuth() {
        return this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.AUTH, CheckoutAuthPageEnum.AUTH_LOGIN);
    }

    public buildUrlForCheckoutDelivery() {
        return this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.DELIVERY);
    }

    public buildUrlForCheckoutDeliveryOtp() {
        return this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.GUEST_OTP);
    }

    public buildUrlForCheckoutPayment() {
        return this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.PAYMENT);
    }

    public buildUrlForCheckoutComplete() {
        return this.buildUrlForBuiltIn(PageTypeEnum.CHECKOUT, CheckoutPageEnum.CONFIRMATION);
    }

    public isUrlCheckoutCart(url:string) {
        return this.urlMatchesSection(url, [PageTypeEnum.CART]);
    }

    public isUrlCheckoutAuth(url:string) {
        return this.urlMatchesSection(url, [PageTypeEnum.CHECKOUT, CheckoutPageEnum.AUTH], true);
    }

    public isUrlCheckoutDelivery(url:string) {
        return this.urlMatchesSection(url, [PageTypeEnum.CHECKOUT, CheckoutPageEnum.DELIVERY]);
    }

    public isUrlCheckoutGuestOtp(url:string) {
        return this.urlMatchesSection(url, [PageTypeEnum.CHECKOUT, CheckoutPageEnum.GUEST_OTP], true);
    }

    public isUrlCheckoutPayment(url:string) {
        return this.urlMatchesSection(url, [PageTypeEnum.CHECKOUT, CheckoutPageEnum.PAYMENT]);
    }

    public isUrlCheckoutComplete(url:string) {
        return this.urlMatchesSection(url, [PageTypeEnum.CHECKOUT, CheckoutPageEnum.CONFIRMATION]);
    }

    public isInCheckoutSection(url:string):boolean {
        return url && this.urlMatchesSection(url, [PageTypeEnum.CHECKOUT], true);
    }

    public buildUrlForInvoice(invoiceNumber:string) {
        return environment.config.api.middlewareBasePath + '/api/invoice/' + invoiceNumber;
    }

    public buildUrlForCreditNote(creditNoteNumber:string) {
        return environment.config.api.middlewareBasePath + '/api/credit-note/' + creditNoteNumber;
    }

    /*
    Breadcrumb Management
    --------------------------------------------
    */
    public getBreadcrumbsForProductList(category:PepkorCategoryapiDataCategoryapiTreeInterface):BreadcrumbLink[] {
        const crumbs:BreadcrumbLink[] = [
            this.getHomeCrumb()
        ];
        const categoryChain           = this.buildProductListParentList(category);
        categoryChain.forEach(cat => {
            crumbs.push({
                label: cat.name,
                url  : this.buildUrlForProductList(cat)
            });
        });
        return crumbs;
    }

    public getBreadcrumbsForSearchResults(searchTerm:string):BreadcrumbLink[] {
        return [
            this.getHomeCrumb(),
            {
                localeKey: 'search.searchResults',
                url      : this.buildUrlForSearchResults(searchTerm)
            }
        ];
    }

    public getBreadcrumbsForProductDisplay(searchTerm:string):BreadcrumbLink[] {
        return [
            this.getHomeCrumb(),
            {
                localeKey: 'search.productDisplay',
                url      : this.buildUrlForSearchResults(searchTerm)
            }
        ];
    }

    public getBreadcrumbsForProduct(url:string, product:IProductModel):BreadcrumbLink[] {
        // determine the category for said product
        // identifier
        let crumbs:BreadcrumbLink[] = [
            this.getHomeCrumb()
        ];
        if (url) {
            // First trim the url of the product
            let catUrl  = this.removeUpTo(url, PageTypeEnum.PRODUCT + '/');
            // Now trim the end portion as this is the sku
            const parts = catUrl.split('/').filter(v => v != null && v !== '');
            parts.pop();
            catUrl = parts.join('/');
            // Now look to see if the product has a url key in the portion
            if (product.urlKey != null) {
                catUrl = this.removeSegment(catUrl, product.urlKey);
            }

            const category = this.findCategoryFromUrl(catUrl, this.categoryTree);
            if (category) {
                const categoryCrumbs = this.getBreadcrumbsForProductList(category);
                if (categoryCrumbs && categoryCrumbs.length > 0) {
                    crumbs = categoryCrumbs;
                }
            }
        }
        // Add the product crumb
        if (product) {
            crumbs.push({
                label: product.name,
                url  : PageTypeEnum.PRODUCT + '/' + url
            });
        }
        return crumbs;
    }

    public getHomeCrumb():BreadcrumbLink {
        return {
            localeKey: 'menu.home',
            url      : this.buildUrlForHome()
        };
    }

    public getBlogCrumb():BreadcrumbLink {
        return {
            localeKey: 'menu.blog',
            url      : this.buildUrlForBlog()
        };
    }

    public get404Crumbs():BreadcrumbLink[] {
        return [
            this.getHomeCrumb(),
            {
                localeKey: '404.breadcrumbName',
                url      : null
            }
        ];
    }

    public mapBreadcrumbsForCustomPage(url:string, customPageSEOData:FMCustomPageSEOData[]):BreadcrumbLink[] {
        let workingUrl = this.cleanSegment(url);
        let crumbs     = [];

        // Look at the url, match the url to a custom page
        while (workingUrl !== '') {
            //console.log('Checking against the url key: ', workingUrl);

            for (let i = 0; i < customPageSEOData.length; i++) {
                const currPage = customPageSEOData[i];
                if (currPage.urlKey === workingUrl) {
                    // Add a segment
                    crumbs.push({
                        label: currPage.pageTitle,
                        url  : this.buildUrlForCustomPage(currPage.urlKey)
                    });
                    break;
                }
            }

            // Lop off the last segment, and match again
            // Repeat till there are no more pages
            const parts = workingUrl.split('/');
            parts.pop();
            workingUrl = parts.join('/');
        }

        crumbs = crumbs.reverse();
        crumbs.unshift(this.getHomeCrumb());

        return crumbs;
    }

    public getBreadcrumbsForBlogCategory(category:CategoryOrTag, categoryIdHash:{ [id:number]:CategoryOrTag }) {
        const crumbs:BreadcrumbLink[] = [];

        // Iterate backward from the end category and push the previous into the start of the array
        const iterate = (cat:CategoryOrTag) => {
            crumbs.unshift({label: cat.name, url: this.buildUrlForBlogCategory(cat, categoryIdHash)});
            if (cat.parent !== 0 && cat.parent !== '0') {
                if (!categoryIdHash[cat.parent]) throw new Error('Missing category parent in hash: ' + cat.parent);
                iterate(categoryIdHash[cat.parent]);
            }
        };
        iterate(category);
        // Push remaining fixed elements
        crumbs.unshift(this.getBlogCrumb());
        crumbs.unshift(this.getHomeCrumb());

        return crumbs;
    }

    /*
    Wordpress
    --------------------------------------------
    */
    buildSlugForCategoryPage(category:PepkorCategoryapiDataCategoryapiTreeInterface) {
        // Build up the URL that will be in the CMS
        const parts = category.urlKeyPath.split('/');
        parts.unshift('products');
        return this.cleanParts(parts).join('/');
    }

    buildUrlForBlogPost(post:WordpressPost) {
        return this.buildUrlForBuiltIn(PageTypeEnum.BLOG, DateUtil.formatBlogPostDateShort(new Date(post.date_gmt)), post.slug);
    }

    buildUrlForBlogCategories() {
        return this.buildUrlForBuiltIn(PageTypeEnum.BLOG, BlogPageEnum.CATEGORIES);
    }

    buildUrlForBlogTags() {
        return this.buildUrlForBuiltIn(PageTypeEnum.BLOG, BlogPageEnum.TAGS);
    }

    buildUrlForBlogTagOrCat(categoryOrTag:CategoryOrTag, hash?:{ [id:number]:CategoryOrTag }) {
        if (categoryOrTag.type === 'category') {
            return this.buildUrlForBlogCategory(categoryOrTag, hash);
        }
        else if (categoryOrTag.type === 'tag') {
            return this.buildUrlForBlogTag(categoryOrTag);
        }
        return null;
    }

    buildUrlForBlogTag(categoryOrTag:CategoryOrTag) {
        return this.buildUrlForBuiltIn(PageTypeEnum.BLOG, categoryOrTag.type, categoryOrTag.slug);
    }

    buildUrlForBlogCategory(categoryOrTag:CategoryOrTag, categoryHash:{ [id:number]:CategoryOrTag }) {
        // iterate recursively up the chain
        const slugs   = [];
        const iterate = (cat:CategoryOrTag) => {
            slugs.unshift(cat.slug);
            if (cat.parent != null && cat.parent !== '0' && cat.parent !== 0) {
                if (categoryHash[cat.parent] == null) throw new Error('Missing WP category parent with id: ' + cat.parent + ', ' + cat.name + JSON.stringify(categoryHash));
                iterate(categoryHash[cat.parent]);
            }
        };
        iterate(categoryOrTag);

        return this.buildUrlForBuiltIn(PageTypeEnum.BLOG, categoryOrTag.type, slugs.join('/'));
    }

    buildSlugForHome() {
        return 'home';
    }

    buildSlugForStaticPage(segments:Array<UrlSegment | PathSegment>) {
        return segments.length > 0 ? 'page/' + segments.map((seg:UrlSegment | {
            path:string
        }) => encodeURIComponent(seg.path)).join('/') : null;
    }

    buildSlugForBlogCategory(urlParts:string[]):string {
        let slug = '';
        urlParts.forEach((part, index) => {
            if (part !== BlogPageEnum.CATEGORY) {
                slug += (index === urlParts.length - 1) ? part : part + '/';
            }
        });
        return slug;
    }

    buildUrlForCMSLogin(redirect?:string) {
        let url = this.buildUrlForBuiltIn(PageTypeEnum.CMS_LOGIN);
        if (redirect != null) {
            url += `?${PageTypeVariables.REDIRECT}=${encodeURIComponent(redirect)}`;
        }
        return url;
    }

    determineValidWordpressStaticPages(allPages:WordpressPage[]):WordpressPage[] {
        // Lets run through all the pages and look for matching results against root page slug + /page
        // Lets store them in a hash using the url path, as well as generate another hash of
        // just urls after /za/page (short urls) so we can loop through them below.
        // There might be 1 or more pages for the same short url.
        // additionally build a lookup hash of page ids to clean at the end
        // e.g of the key: /za/page/financial-solutions/account-card/
        const pathnamePageHash = {};
        // e.g of the key: financial-solutions/account-card/
        const simplePageKeys   = {};
        const pageIdHash       = {};
        allPages.forEach(page => {
            pageIdHash[page.id] = page;
            for (let i = 0; i < this.rootPageSlugs.length; i++) {
                const prefix     = this.rootPageSlugs[i];
                const fullPrefix = '/' + prefix + '/page/';
                //console.log('Testing prefix: ', fullPrefix);
                const pageUrl    = new URL(page.link);

                // e.g pathname starts with /en-za/page and is longer than that
                if (pageUrl.pathname.indexOf(fullPrefix) === 0 && pageUrl.pathname.length > fullPrefix.length) {
                    //console.log('Matched: ', page.link);
                    const remainder                    = pageUrl.pathname.substr(prefix.length + 1);
                    simplePageKeys[remainder]          = true;
                    pathnamePageHash[pageUrl.pathname] = page;
                    // break out after finding the first relevant page
                    break;
                }
            }
        });
        //console.log('pathnamePageHash: ', JSON.stringify(pathnamePageHash, null, 4));
        //console.log('remainderPageHash: ', JSON.stringify(remainderPageHash, null, 4));

        // Used to determine the entire parent change was available, this will not be the case
        // if one of the parents was changed to a draft
        const pageHasParentChain = (page:WordpressPage):boolean => {
            const parent:WordpressPage = pageIdHash[page.parent];
            if (parent == null || parent.parent == null) {
                return false;
            }
            else if (parent.parent === 0) {
                return true;
            }
            else {
                return pageHasParentChain(parent);
            }
        };

        // Now lets run through all the short urls and find the actual page we want to
        // use by looking for it with the fully qualified path including the /za/page prefix
        // we run through the rootPageSlugs in order so that order defines the desired page.
        const result = [];
        Object.keys(simplePageKeys).forEach(key => {
            for (let i = 0; i < this.rootPageSlugs.length; i++) {
                // e.g. /en-za
                const prefix   = this.rootPageSlugs[i];
                // e.g. /en-za/pep-money
                const pathname = '/' + prefix + key;
                if (pathnamePageHash[pathname] && pageHasParentChain(pathnamePageHash[pathname])) {
                    result.push(pathnamePageHash[pathname]);
                    break;
                }
            }
        });

        return result;
    }

    /**
     * Converts <cms-domain>/<country>/* to <website-domain>/*
     */
    convertWordpressStaticPageUrl(wordpressUrl:string, absolute = true) {
        if (wordpressUrl && typeof wordpressUrl === 'string' && (wordpressUrl.indexOf('http://') === 0 || wordpressUrl.indexOf('https://') === 0)) {
            const parsed = new URL(wordpressUrl);
            const path   = parsed.pathname;
            const parts  = this.cleanParts(path.split('/'));
            // Remove country
            parts.shift();
            // Add base path
            if (absolute) {
                const thisUrl = new URL(this.windowHref);
                parts.unshift(thisUrl.origin);
            }
            return parts.join('/');
        }
        return null;
    }

    buildUrlForPageSearchResult(result:ESWPSearch) {
        if (result.post_type === 'post') {
            const post:WordpressPost = <any>{};
            post.date_gmt            = result.post_date_gmt;
            // No need for the title fallback as search results will never show drafts
            post.slug                = result.post_name;
            return this.buildUrlForBlogPost(post);
        }
        else if (result.post_type === 'page') {
            // the result.parent_slug_chain will begin with the country so lets strip it then good to go
            const parts = result.parent_slug_chain.split('/');
            parts.shift();
            return this.joinParts(parts);
        }
        return null;
    }


    /*
    Url parsing & querying
    --------------------------------------------
    */

    /**
     * Determine's if a link is for an internal page or not
     */
    public isUrlForThisDomain(href:string):boolean {
        let response = false;
        if (href != null && typeof href === 'string') {
            // Relative urls are all for this domain
            if (href.indexOf('http://') === 0 || href.indexOf('https://') === 0) {
                const thisUrl  = new URL(this.windowHref);
                const checkUrl = new URL(href);
                response       = thisUrl.host === checkUrl.host;
            }
            else if (href.match(/(\/cdn\/)+(documents|images|)/g)) {
                response = false;
            }
            else if (this.isUrlForTel(href)) {
                response = false;
            }
            else {
                response = true;
            }
        }
        return response;
    }

    public isUrlForTel(href:string):boolean {
        return (href != null && href.indexOf('tel:') === 0);
    }

    public isUrlForFacadeFunctionality(href:string):boolean {
        return (href != null && href.indexOf('facade-link:') === 0);
    }

    public isUrlForFileDownload(href:string):boolean {
        return href && /\.(pdf|docx|doc|txt|rtf|xlsx|xls|csv|exe|key|pps|ppt|pptx|7z|pkg|rar|gz|zip|avi|mov|mp4|mpeg|mpg|wmv|midi|mid|mp3|wav|wma|eps|svg|ai|jpeg|jpg|gif)$/.test(href);
    }

    public convertPathToUrlSafely(path:string) {
        // Absolute already
        if (path.indexOf('http://') === 0) {
            return new URL(path);
        }
        else if (path.charAt(0) === '/') {
            return new URL(this.windowRef.origin + path);
        }
        else {
            return new URL(this.windowRef.origin + '/' + path);
        }
    }

    public matchTrafficRedirects(currentURL:string, redirectConfig:FMConfigRedirect[]):FMConfigRedirect {
        if (currentURL && redirectConfig) {
            // const parsed = new URL(currentURL);
            return redirectConfig.find(redirect => {
                const sourcePath = redirect.sourcePath.charAt(0) !== '/' ? '/' + redirect.sourcePath : redirect.sourcePath;
                const queryIndex = currentURL.indexOf('?');
                const path       = queryIndex > 0 ? currentURL.substring(0, queryIndex != -1 ? queryIndex : sourcePath.length) : currentURL;
                return redirect.includeChildPages ? currentURL.indexOf(sourcePath) === 0 : (path === sourcePath);
            });
        }
        return null;
    }

    // If native mobile app: load from local nativeAssetPath
    public buildUrlForAsset(href:string):string {
        if (!this.urls) this.createCleanUrls();

        // Keeping this in place for if we need to implement a mobile app otherwise this method does nothing
        if (this.isMobileApp) {
            if (href.charAt(0) === '/') href = href.slice(1, href.length);
            href = this.urls.nativeAssetPath + '/' + href;
        }
        else {
            return href;
        }
    }

    public isSearchResultsUrl(url:string):boolean {
        if (url != null && typeof url === 'string') {
            if (url.charAt(0) !== '/') url = '/' + url;
            return url.indexOf('/' + PageTypeEnum.SEARCH_RESULTS) === 0;
        }
        return false;
    }

    public isProductListUrl(url:string):boolean {
        if (url != null && typeof url === 'string') {
            if (url.charAt(0) !== '/') url = '/' + url;
            return url.indexOf('/' + PageTypeEnum.PRODUCT_LIST) === 0;
        }
        return false;
    }

    public isProductPageUrl(url:string):boolean {
        if (url != null && typeof url === 'string') {
            if (url.charAt(0) !== '/') url = '/' + url;
            return url.indexOf('/' + PageTypeEnum.PRODUCT) === 0;
        }
        return false;
    }

    /**
     * Used to update an absolute url for mobile
     */
    public absURL(url:string):string {
        // If actually an absolute url update to be /
        if (url && url.charAt(0) === '/') {
            url = url.substr(1);
        }
        return url;
    }

    /**
     * Used to match a route url to a set of expected parts
     * e.g:
     * urlMatchesSection('/checkout/123/delivery?filter=123', ['checkout', 'key', 'delivery']) === true
     */
    public urlMatchesSection(url:string, checkParts:Array<string | 'key'>, partialMatch:boolean = false) {
        if (url.indexOf('?') !== -1) url = url.substr(0, url.indexOf('?'));
        const urlParts = url.split('/').filter(v => v !== '' && v != null);
        let allMatch   = true;
        const len      = partialMatch ? checkParts.length : Math.max(urlParts.length, checkParts.length);
        for (let i = 0; i < len; i++) {
            const urlPart   = i < urlParts.length ? urlParts[i] : null;
            const checkPart = i < checkParts.length ? checkParts[i] : null;
            if (urlPart !== checkPart && checkPart !== 'key') {
                allMatch = false;
                break;
            }
        }
        return allMatch;
    }

    public stripAbsoluteHost(url:string) {
        if (url != null && (url.indexOf('http://') === 0 || url.indexOf('https://') === 0)) {
            const temp2 = new URL(url);
            return temp2.pathname + temp2.search + temp2.hash;
        }
        return url;
    }


    /*
    Utils
    --------------------------------------------
    */
    private deriveUrlCategory(product:IProductModel, category?:PepkorCategoryapiDataCategoryapiTreeInterface) {
        // Derive the category from the product if not passed in
        // There is likely multiple categories so if we know the real one we pass it into this method
        // Otherwise we use the deepest category we can find
        if (category == null && product.categoryIds && product.categoryIds.length > 0) {
            for (let i = 0; i < product.categoryIds.length; i++) {
                const curr = this.categoryIDHash[product.categoryIds[i]];
                // Removed category on product checking as some products are associated with categories that have since been deleted and need to still work
                // Only apply it if deeper or null
                if (curr != null && (category == null || curr.level > category.level)) {
                    category = curr;
                }
            }
        }
        return category;
    }

    private joinParts(urlParts:string[]) {
        if (urlParts) {
            return '/' + urlParts.join('/');
        }
        return '';
    }

    private cleanParts(urlParts:string[]) {
        if (urlParts) {
            return urlParts.filter(part => part != null && part !== '');
        }
        return urlParts;
    }

    private removeUpTo(url:string, upTo:string) {
        if (url && upTo && url.indexOf(upTo) !== -1) {
            return url.substring(url.indexOf(upTo) + upTo.length);
        }
        return url;
    }

    private removeSegment(url:string, segment:string) {
        // if segment has a leading or trailing slash, remove
        segment = this.cleanSegment(segment);

        if (url && segment && url.indexOf(segment) !== -1) {
            url = url.substring(0, url.indexOf(segment)) + url.substring(url.indexOf(segment) + segment.length);
            // Clean the url
            url = url.split('/').filter(v => v != null && v !== '').join('/');
            //return url.substring(url.indexOf(upTo) + upTo.length);
            return url;
        }
        return url;
    }

    private createCleanUrls():void {
        const stripBeginAndEndSlash = (str:string) => {
            if (str == null) return '';
            if (str.charAt(0) === '/') str = str.slice(1, str.length);
            if (str.charAt(str.length - 1) === '/') str = str.slice(0, str.length - 1);
            return str;
        };

        this.urls = {
            nativeAssetPath: stripBeginAndEndSlash(environment.config.api.nativeAssetPath)
        };
    }


    // Removes and leading or trailing slashes, removes any double slashes
    public cleanSegment(segment:string) {
        if (segment != null) return segment.split('/').filter(v => v != null && v !== '').join('/');
        return segment;
    }

    abstract get categoryIDHash():CategoryHash;

    abstract get categoryUrlKeyHash():CategoryHash;

    abstract get categoryTree():PepkorCategoryapiDataCategoryapiTreeInterface;

    abstract get customPageSEOData():FMCustomPageSEOData[];

    abstract get documentBaseUrl():string;

    abstract get cdnRootUrl():string;

    abstract get magentoImageBaseUrl():string;

    abstract get brandLogoImageBaseUrl():string;

    abstract get productAttributeAssetBaseUrl():string;

    abstract get windowHref():string;

    abstract get isMobile():boolean;

    abstract get isMobileApp():boolean;

    abstract get rootPageSlugs():string[];

    abstract get websiteBasePath():string;

    abstract get windowRef():WindowRef;
}
