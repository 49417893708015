import {FirebaseWrapperService} from '../../firebase-wrapper/firebase-wrapper.service';

export class GoogleTagManager {

    private static _embedded = false;

    static async embedWithTracker(firebaseWrapper:FirebaseWrapperService, tracker:string) {
        if (GoogleTagManager._embedded === true) return;
        GoogleTagManager._embedded = true;
        /*
        Paste this code as high in the <head> of the page as possible:

        <!-- Google Tag Manager -->
        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','TRACKERHERE');</script>
        <!-- End Google Tag Manager -->


        Additionally, paste this code immediately after the opening <body> tag:

        <!-- Google Tag Manager (noscript) -->
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TJQX882"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        <!-- End Google Tag Manager (noscript) -->
        */

        // It's necessary to initialize firebase analytics before gtm
        // https://firebase.google.com/docs/analytics/get-started?platform=web#firebase-gtag
        await firebaseWrapper.initFirebaseAnalytics();

        // tslint:disable
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event      : 'gtm.js'
            });
            var f                          = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j['async']                     = true;
            j['src']                       = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', tracker);
        // tslint:enable

        // Append to the body
        // https://stackoverflow.com/questions/6013861/how-to-insertbefore-element-in-body-tag
        const body       = document.body || document.getElementsByTagName('body')[0];
        const iframe     = document.createElement('iframe');
        iframe['src']    = `https://www.googletagmanager.com/ns.html?id=${tracker}`;
        iframe['width']  = '0';
        iframe['height'] = '0';
        iframe.setAttribute('style', 'display:none; visibility:hidden');
        const noscript = document.createElement('noscript');
        noscript.appendChild(iframe);
        body.insertBefore(noscript, body.childNodes[0]);
    }
}
