import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Logger} from '../../shared/logger';
import {ExternalResponse} from '../external-response';
import {GET_CATEGORIES, GET_CATEGORIES_CACHE} from './gql-queries/get-categories.query';
import {ExtendedApolloService} from '../extended-apollo/extended-apollo.service';
import {CategoriesResponse} from './gql-queries/gql-query-response-types';
import {CategoryTree, FacadeUrlResolverResponse} from '../../../../../generated/graphql';
import {parseCategories, ParsedCategories} from './magento-logic.util';
import {Store} from '@ngxs/store';
import {HasStartupError} from '../../ngxs/app.state';
import {Magento} from './magento.state';
import {FACADE_URL_RESOLVER, FACADE_URL_RESOLVER_CACHE} from './gql-queries/facade-url-resolver.query';

export interface FacadeUrlResolverResponseWrapper {
    facadeUrlResolver:FacadeUrlResolverResponse;
}

@Injectable()
export class MagentoLogicService {

    public logger:Logger;

    public static parseCategories(categoryTree:CategoryTree[]):ParsedCategories {
        return parseCategories(categoryTree);
    }

    constructor(private store:Store,
                private apollo:ExtendedApolloService,
                logService:Logger) {
        this.logger = logService.getLogger('MagentoLogicService');
    }

    public fetchCategories() {
        return this.apollo.query({
            query    : GET_CATEGORIES,
            variables: {pageSize: 1000}
        }, GET_CATEGORIES_CACHE).pipe(
            tap(
                (result:ExternalResponse<CategoriesResponse>) => {
                    this.store.dispatch(new Magento.StoreCategories(MagentoLogicService.parseCategories(result.data.categories.items)));
                },
                (result:ExternalResponse<any>) => {
                    this.store.dispatch(new HasStartupError(result.error));
                }
            )
        );
    }

    public facadeUrlResolver(url:string) {
        return this.apollo.query<FacadeUrlResolverResponseWrapper>({query: FACADE_URL_RESOLVER, variables: {url: url}}, FACADE_URL_RESOLVER_CACHE);
    }

}
