import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FacadeIconModule} from '../icon/facade-icon.module';
import {FacadeAccordionBodyComponent} from './facade-accordion-body.component';
import {FacadeAccordionHeaderComponent} from './facade-accordion-header.component';
import {FacadeAccordionItemComponent} from './facade-accordion-item.component';
import {FacadeAccordionComponent} from './facade-accordion.component';

@NgModule({
    imports     : [
        CommonModule,
        FacadeIconModule
    ],
    declarations: [
        FacadeAccordionComponent,
        FacadeAccordionItemComponent,
        FacadeAccordionHeaderComponent,
        FacadeAccordionBodyComponent
    ],
    exports     : [
        FacadeAccordionComponent,
        FacadeAccordionItemComponent,
        FacadeAccordionHeaderComponent,
        FacadeAccordionBodyComponent
    ]
})
export class FacadeAccordionModule {
}
