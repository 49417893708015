import {gql} from 'apollo-angular';

export const WP_GET_POSTS_CACHE = true;
// page:String, per_page:String, order:String, status:String, slug_or_title:String, include:String, categories:[Int], tags:[Int]
export const WP_GET_POSTS = gql`
    query wpPosts($status:String, $slug_or_title:String, $page:String, $per_page:String, $order:String, $include:String, $categories:[Int], $tags:[Int]) {
        wpPosts(status: $status, slug_or_title: $slug_or_title, page: $page, per_page: $per_page, order: $order, include: $include, categories: $categories, tags: $tags) {
            total
            totalPages
            items {
                id
                date_gmt
                modified_gmt
                slug
                link
                title {
                    rendered
                }
                author
                categories
                categoriesPopulated {
                    ...CategoryOrTag
                }
                tags
                tagsPopulated {
                    ...CategoryOrTag
                }
                acf
                breadcrumbs {
                    label
                    localeKey
                    url
                }
                postImages {
                    acf_fc_layout
                    display
                    desktopPostHeader
                    desktopFeatured
                    desktopPreview
                    desktopThumbnail
                    desktopPromotional
                    mobilePostHeader
                    mobileFeatured
                    mobilePreview
                    mobileThumbnail
                    mobilePromotional
                    altTag
                }
                youtubeComp {
                    acf_fc_layout
                    display
                    cssClasses
                    anchor
                    environments
                    loop
                    mute
                    hideControls
                    autoplay
                    height
                    alignment
                    maxWidthValue
                    widthType
                    youtubeVideoID
                    duration
                }
            }
        }
    }
    fragment CategoryOrTag on GqlWpCategoryOrTag {
        id
        type
        name
        description
        slug
        parent
        count
        children {
            id
            type
            name
            description
            slug
            parent
            count
        }
    }
`;
