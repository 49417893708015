import {gql} from 'apollo-angular';

export const FETCH_CUSTOMER_STORE_CREDIT_CACHE = false;

export const FETCH_CUSTOMER_STORE_CREDIT = gql`
    query customer_fetchStoreCredit($currentPage:Int, $pageSize:Int) {
        customer {
            store_credit {
                balance_history(currentPage: $currentPage, pageSize: $pageSize) {
                    items {
                        action
                        actual_balance {
                            value
                        }
                        balance_change {
                            value
                        }
                        date_time_changed
                    }
                    page_info {
                        current_page
                        page_size
                        total_pages
                    }
                    total_count
                }
                current_balance {
                    value
                }
            }
        }
    }
`;
