export enum FirebaseMessagingErrors {
    PERMISSION_BLOCKED                = 'messaging/permission-blocked',
    INVALID_ARGUMENT                  = 'messaging/invalid-argument',
    INVALID_RECIPIENT                 = 'messaging/invalid-recipient',
    INVALID_PAYLOAD                   = 'messaging/invalid-payload',
    INVALID_DATA_PAYLOAD_KEY          = 'messaging/invalid-data-payload-key',
    PAYLOAD_SIZE_LIMIT_REACHED        = 'messaging/payload-size-limit-exceeded',
    INVALID_OPTIONS                   = 'messaging/invalid-options',
    INVALID_REGISTRATION_TOKEN        = 'messaging/invalid-registration-token',
    REGISTRATION_TOKEN_NOT_REGISTERED = 'messaging/registration-token-not-registered',
    INVALID_PACKAGE_NAME              = 'messaging/invalid-package-name',
    MESSAGE_RATE_EXCEEDED             = 'messaging/message-rate-exceeded',
    DEVICE_MESSAGE_RATE_EXCEEDED      = 'messaging/device-message-rate-exceeded',
    TOPIC_MESSAGE_RATE_EXCEEDED       = 'messaging/topics-message-rate-exceeded',
    TOO_MANY_TOPICS                   = 'messaging/too-many-topics',
    INVALID_APNS_CREDENTIALS          = 'messaging/invalid-apns-credentials',
    MISMATCHED_CREDENTIAL             = 'messaging/mismatched-credential',
    AUTHENTICATION_ERROR              = 'messaging/authentication-error',
    SERVER_UNAVAILABLE                = 'messaging/server-unavailable',
    INTERNAL_ERROR                    = 'messaging/internal-error',
    UNKNOWN_ERROR                     = 'messaging/unknown-error'
}
