import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import produce from 'immer';
import {tap} from 'rxjs/operators';
import {AuthService} from '../external-data/auth/auth.service';


export namespace CustomerAuth {
    export class UpdateCustomerUsername {
        static readonly type = '[Customer Auth] Update Customer username';

        constructor(public username:string) {
        }
    }

    export class UpdateTempOtpPhoneNumber {
        static readonly type = '[Customer Auth] Update customer phone number';

        constructor(public tempOtpPhoneNumber:string) {
        }
    }

    export class UpdatePlusMore {
        static readonly type = '[Customer Auth] Update plus more';

        constructor(public isPlusMore:boolean) {
        }
    }

    export class Logout {
        static readonly type = '[Customer Auth] Logout';
    }
}

export interface CustomerStateModel {
    username?:string;
    isLoggedIn?:boolean;
    isPlusMore?:boolean;
    tempOtpPhoneNumber?:string;
}

@State<CustomerStateModel>({
    name    : 'customer',
    defaults: {
        isLoggedIn: false,
        isPlusMore: false
    }
})
@Injectable()
export class CustomerAuthState {

    constructor(private authService:AuthService) {
    }

    @Action(CustomerAuth.UpdateCustomerUsername)
    updateCustomer(ctx:StateContext<CustomerStateModel>, action:CustomerAuth.UpdateCustomerUsername) {
        return ctx.setState(produce(draft => {
            draft.username   = action.username;
            draft.isLoggedIn = true;
        }));
    }

    @Action(CustomerAuth.UpdateTempOtpPhoneNumber)
    updateCustomerPhoneNumber(ctx:StateContext<CustomerStateModel>, action:CustomerAuth.UpdateTempOtpPhoneNumber) {
        return ctx.setState(produce(draft => {
            draft.tempOtpPhoneNumber = action.tempOtpPhoneNumber;
        }));
    }

    @Action(CustomerAuth.Logout)
    logout(ctx:StateContext<CustomerStateModel>) {
        return this.authService.logout()
            .pipe(
                tap(res => {
                    ctx.setState(produce(draft => {
                        delete draft.username;
                        draft.isLoggedIn = false;
                        draft.isPlusMore = false;
                    }));
                })
            );
    }

    @Action(CustomerAuth.UpdatePlusMore)
    updatePlusMore(ctx:StateContext<CustomerStateModel>, action:CustomerAuth.UpdatePlusMore) {
        return ctx.setState(produce(draft => {
            draft.isPlusMore = action.isPlusMore;
        }));
    }

}
