<div *ngIf="isSearching" class="search-message">
    <facade-icon icon="icon-search"></facade-icon> {{'search.searchingFor' | translate:{searchTerm: searchTerm} }}
</div>

<div *ngIf="!isSearching" dScrollingContainer>

    <div class="search-message" *ngIf="hasError">{{'search.error' | translate}}</div>

    <div class="search-message" *ngIf="!hasError && productData == null">{{'search.enterQuery' | translate}}</div>

    <div class="category-list" *ngIf="!hasError && categoryData != null && categoryData.length > 0">
        <div class="title-holder"><div class="title">{{'search.categories' | translate}}</div></div>
        <ng-container *ngFor="let category of categoryData; let index = index;">
            <a [fakeLink]="urlService.buildUrlForCategoryId(category.id)"
               (fakeClick)="handleCategoryClick($event, category)"
               class="category-item">
                <span>{{category.name}}</span>
                <facade-icon icon="icon-chevron-right"></facade-icon>
            </a>
            <hr class="divider" *ngIf="(index+1) != categoryData.length">
        </ng-container>
    </div>

    <ng-container *ngIf="!hasError && (productData != null || wpData != null)">
        <div class="search-message" *ngIf="productData?.length === 0 && wpData?.length === 0">{{'search.noResults' | translate:{searchTerm: searchTerm} }}</div>

        <div [ngClass]="(productData?.length > 0 && wpData?.length > 0) ? 'two-result-sets' : '' ">
            <div *ngIf="productData != null && productData?.length > 0" class="product-list">
                <a class="see-all-link" [fakeLink]="urlService.buildUrlForSearchResults(searchTerm)" (fakeClick)="handleLink($event)">{{'search.viewAllResults' | translate}}</a>

                <div class="title-holder">
                    <div class="title">{{'search.products' | translate}}</div>
                    <a *ngIf="dataCount > productData.length" class="see-more-link" [fakeLink]="urlService.buildUrlForSearchResults(searchTerm)" (fakeClick)="handleLink($event)">
                        {{ 'search.viewMoreResults' | translate:{more: dataCount - productData.length, total: dataCount} }}
                    </a>
                </div>
                <div class="product-results">
                <a
                    *ngFor="let product of productData; let i = index;"
                    [fakeLink]="urlService.buildUrlForProduct(product)"
                    (fakeClick)="handleProductClick($event, product)"
                    (mouseover)="setSelectedIndex(i, true)"
                    (mouseout)="setSelectedIndex(-1, true)"
                    class="product-wrapper clearfix"
                    [ngClass]="{active:i === currentIndex}"
                    domPosition>
                    <div class="product-wrapper-inner clearfix">
                        <div class="product-image">
                            <img *ngIf="product?.images[0]?.image" [attr.alt]="product?.images[0]?.alt" [src]="urlService.buildUrlForMagentoImage(product.images[0].image, MagentoImageSizes.m_sm)">
                        </div>
                        <div class="product-content">
                            <div class="name" [innerHTML]="product.name"></div>
                            <div class="description">{{product.description | stripHTML}}</div>
                            <div class="price">
                                <span class="price-number">{{product.price | price}}</span>
                            </div>
                        </div>
                    </div>
                </a>
                </div>
            </div>

            <div *ngIf="wpData?.length > 0" class="pages-list">
                <div class="title-holder">
                    <div class="title">{{'search.pages' | translate}}</div>
                    <a *ngIf="wpDataCount > wpData.length" class="see-more-link" [fakeLink]="urlService.buildUrlForSearchPageResults(searchTerm)" (fakeClick)="handleLink($event)">
                        {{ 'search.viewMorePages' | translate:{more: wpDataCount - wpData.length, total: wpDataCount} }}
                    </a>
                </div>
                <a *ngFor="let page of wpData; let i = index;"
                   class="wrapper clearfix"
                   [ngClass]="{active:i === currentIndex}"
                   [fakeLink]="urlService.buildUrlForPageSearchResult(page)"
                   (fakeClick)="handleLink($event)">

                    <div class="content-wrapper" *ngIf="page?.meta?.seoTitle?.length > 0 && page?.meta?.seoTitle[0]?.value != null && page?.meta?.seoDescription?.length > 0 && page?.meta?.seoDescription[0]?.value != null">
                        <div class="content">
                            <div class="name">{{page.meta.seoTitle[0].value}}</div>
                            <div class="description">{{page.meta.seoDescription[0].value}}</div>
                        </div>
                    </div>
                </a>
            </div>

        </div>
    </ng-container>
</div>
