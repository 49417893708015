// environment.config.api.crazyeggScriptUrl
// Determines if the manager will be called at all
export class CrazyEggManager {
    private static _initalised = false;

    static init(crazyeggScriptUrl:string) {
        if (CrazyEggManager._initalised === true) return;
        
        CrazyEggManager._initalised = true;

        CrazyEggManager.embed(crazyeggScriptUrl);
    }

    static embed(crazyeggScriptUrl:string) {
        const body                     = document.body || document.getElementsByTagName('body')[0];
        const script:HTMLScriptElement = document.createElement('script');
        script.type                    = 'text/javascript';
        script.id                      = 'crazyegg';
        script.src                     = crazyeggScriptUrl;
        script.async                   = true;
        body.appendChild(script);
    }
}
