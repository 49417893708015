import {gql} from 'apollo-angular';

export const GET_PRODUCT_FOR_AUTO_COMPLETE_CACHE = true;

export const GET_PRODUCT_FOR_AUTO_COMPLETE = (hasPimSupport:boolean) => {
    return gql`
        query products_autoComplete($searchTerm: String, $pageSize: Int) {
            products(search: $searchTerm pageSize:$pageSize currentPage:1) {
                items {
                    id
                    sku
                    name
                    description {
                        html
                    }

                    ${
                    hasPimSupport ?
                    `media_gallery {
                        file_name
                        label
                        position
                        disabled
                    }` :
                    `image_name
                    image_name_label
                    product_image_names`
                    }

                    price_range {
                        minimum_price {
                            ...AllPriceFields
                        }
                    }
                    url_key
                    categories {
                        id
                    }
                }
            }
        }

        fragment AllPriceFields on ProductPrice {
            final_price {
                ...MoneyFields
            }
        }

        fragment MoneyFields on Money {
            value
        }
    `;
};
