import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {AbstractNewsletterSignUpModalComponent} from '../../../cross-platform/newsletter/abstract-newsletter-sign-up-modal.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector       : 'newsletter-sign-up-modal',
    template       : `
        <div class="modal-header">
            <h4 class="modal-title modal-basic-title">{{'newsletter.modalTitle' | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="viewProps$ | async as viewProps">
                <div *ngIf="viewProps.submitted === true" class="response-msg">
                    <ng-container *ngIf="!viewProps.duplicateEmail">
                        <p class="newsletter-info">{{'newsletter.sentBody' | translate}}</p>
                    </ng-container>
                    <ng-container *ngIf="viewProps.duplicateEmail">
                        <p class="newsletter-info">{{'newsletter.sentErrorDup' | translate}}</p>
                    </ng-container>
                    <button type="submit" class="btn-submit btn btn-success" (click)="closeModal(true)">
                        <span>{{'close'  | translate}}</span>
                    </button>
                </div>
                <form *ngIf="!viewProps.submitted" (ngSubmit)="handleSubmit()" [formGroup]="newsletterForm" novalidate>
                    <!--<span class="list-header">{{'newsletter.header' | translate}}</span>-->
                    <span class="newsletter-detail">{{'newsletter.detail' | translate}}</span>
                    <d-form-input
                        class="smaller"
                        [label]="'newsletter.email' | translate"
                        [placeHolder]="'newsletter.email' | translate"
                        [formControlName]="'emailControl'"
                    ></d-form-input>
                    <d-form-checkbox [formControlName]="'termsCheck'">
                        {{'newsletter.popiaTermsMessage' | translate}}
                    </d-form-checkbox>
                    <div class="popia-inline">
                        <div #popiaMessage class="popia-consent-message" [ngClass]="!showTermsDetail ? 'hidden' : ''">
                            <div class="popia-consent-message-wrapper" [innerHTML]="popiaHTML">
                            </div>
                        </div>
                        <a href="#" class="view-link" (click)="togglePopiaDetail($event)">
                            <span *ngIf="!showTermsDetail">{{'newsletter.popiaTermsViewMore' | translate}}</span>
                            <span *ngIf="showTermsDetail">{{'newsletter.popiaTermsViewLess' | translate}}</span>
                        </a>
                    </div>
                    <error-message *ngIf="(state$ | async)?.isError" [errorMsgHtml]="'error.defaultMessage' | translate"></error-message>
                    <div class="button-wrapper">
                        <button type="submit" class="btn-submit btn btn-primary" [attr.disabled]="formIsDisabled ? 'disabled' : null">
                            <span *ngIf="!formIsDisabled">{{'newsletter.subscribe'  | translate}}</span>
                            <span *ngIf="formIsDisabled"><spinner></spinner>
                                {{'submitting' | translate}}</span>
                        </button>
                    </div>
                </form>
            </ng-container>
        </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsletterSignUpModalComponent extends AbstractNewsletterSignUpModalComponent {
    ngbActiveModal:NgbActiveModal;

    constructor(injector:Injector) {
        super(injector);
        this.ngbActiveModal = injector.get(NgbActiveModal);
    }

    closeModal(complete?:boolean) {
        this.destroy();
        // this.ngbActiveModal.dismiss();
        this.ngbActiveModal.close(complete);
    }
}
