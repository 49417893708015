import {Injectable, NgZone} from '@angular/core';
import {DocumentRef} from './document-ref';


@Injectable()
export class BodyRef {

    constructor(private ngZone:NgZone,
                private documentRef:DocumentRef) {
    }

    get nativeBody() {
        return this._body;
    }

    addClass(className:string):void {
        this.ngZone.runOutsideAngular(() => {
            this.nativeBody.classList.add(className);
            //console.log('Changing classlist, adding: ', className, this.nativeBody.classList);
        });
    }

    removeClass(className:string):void {
        this.ngZone.runOutsideAngular(() => {
            this.nativeBody.classList.remove(className);
            //console.log('Changing classlist, removing: ', className, this.nativeBody.classList);
        });
    }

    private get _body():Element {
        // return the global native body DOM element
        return this.documentRef.getElementsByTagName('body')[0];
    }
}
