import {
    Directive,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import {InstanceCounter} from '../instance-counter';
import {Logger} from '../logger';
import {VisibilityService} from '../visibility.service';
import {Subscription} from 'rxjs';
import {DocumentRef} from './document-ref';
import {NetworkLoadMonitor} from '../../external-data/interceptors/network-load-monitor.service';

@Directive({
    selector: 'img[facadeImg]' // tslint:disable-line
})
export class FacadeImgDirective implements OnDestroy, OnChanges, OnInit {

    @Input()
    index:number;

    // Can be overridden externally to force visibility
    @Input()
    isVisible = false;


    @Input()
    set autoLoad(value:boolean) {
        this._autoLoad = value;
        this.checkForLoad();
    }

    get autoLoad() {
        return this._autoLoad;
    }

    @Output()
    public onLoad    = new EventEmitter<any>();
    public isLoading = false;
    public isLoaded  = false;

    protected instanceName:string;
    private visibleSubscription:Subscription;
    private _source:string;
    private _autoLoad = true;


    constructor(private elementRef:ElementRef,
                private renderer:Renderer2,
                private injector:Injector,
                private logger:Logger,
                private visibilityService:VisibilityService,
                private networkLoadMonitor:NetworkLoadMonitor) {
    }

    @Input('facadeImg')
    set source(value:string) {
        this._source = value;
    }

    // Just a utility method
    @Input()
    set lazyLoadWhenVisible(value:boolean) {
        this.isVisible = !value;
    }

    ngOnInit() {
        const name        = 'FacadeImg_' + (this.index != null ? this.index + '_' : '');
        this.instanceName = InstanceCounter.getName(name);
        this.logger       = this.logger.getLogger(this.instanceName);
        this.renderer.addClass(this.elementRef.nativeElement, this.instanceName);
        //this.logger.logLevel = LogLevel.TEST;

        // Add visibility selector
        if (!this.isVisible) {
            this.visibleSubscription = this.visibilityService.elementInSight(this.elementRef).subscribe(() => {
                this.logger.test('Visibility change!');
                if (this.visibleSubscription) this.visibleSubscription.unsubscribe();
                this.visibleSubscription = null;
                this.isVisible           = true;
                this.checkForLoad();
            });
        }
    }

    ngOnChanges(changes:SimpleChanges) {
        this.logger.test('ngOnChanges: ', changes);
        this.checkForLoad();
    }

    ngOnDestroy():void {
        this.logger.test('Destroying: ');
        this.visibleSubscription?.unsubscribe();
    }

    public loadImageNow() {
        // This never gets reset
        if (!this.isLoading) {
            this.logger.test('Starting load now');
            return new Promise((resolve, reject) => {
                const listener = (e) => {
                    if (this.isLoaded) return;
                    this.isLoaded = true;
                    this.onLoad.emit(e);
                    resolve(null);
                    this.networkLoadMonitor.trackImageLoaded();
                };

                this.networkLoadMonitor.trackImageToLoad();

                this.elementRef.nativeElement['onload']  = (e) => listener(e);
                this.elementRef.nativeElement['onerror'] = (e) => listener(e);
                this.isLoading                           = true;
                this.elementRef.nativeElement.src        = this._source;
            });
        }
        else {
            throw new Error('trying to load an is loading image');
        }
    }

    protected checkForLoad() {
        if (this.viableForLoad()) {
            this.loadImageNow();
        }
    }

    protected viableForLoad() {
        return this.autoLoad && this._source != null && this._source !== '' && !this.isLoading && !this.isLoaded && this.isVisible;
    }

    get documentRef():DocumentRef {
        return this.injector.get(DocumentRef);
    }
}
