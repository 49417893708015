import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {PepCMSPageLinks} from '../../../cross-platform/pep-custom-page-mapping';
import {DesktopHeaderComponent} from '../../../../../../framework/src/desktop/app/header/desktop-header.component';

@Component({
    selector       : 'pep-desktop-header',
    templateUrl    : './pep-desktop-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PepDesktopHeaderComponent extends DesktopHeaderComponent {

    public isSearchResultsPage = false;

    PepCMSPageLinks = PepCMSPageLinks;

    constructor(injector:Injector, public router:Router) {
        super(injector);
    }

    init() {
        super.init();

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event:NavigationEnd) => {
                this.isSearchResultsPage = this.urlService.isSearchResultsUrl(event.url);
                //console.log('isSearchResultsPage: ', this.isSearchResultsPage);
                this.changeDetectorRef.detectChanges();
            });
    }
}
