import {gql} from 'apollo-angular';

export const REINSTATE_CART = gql`
    mutation reinstateCart($input: ReinstateCartInput) {
        reinstateCart(input: $input) {
            cart {
                id
            }
        }
    }
`;
