import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CustomPageData, CustomPageRender} from '../../../cross-platform/custom-pages/custom-page.types';
import {AbstractFacadePageComponent} from '../../../cross-platform/shared/ui/abstract-facade-page.component';
import {WindowRef} from '../../../cross-platform/shared/ui/window-ref';

@Component({
    template       : `
        <bread-crumb [breadcrumbs]="breadcrumbs"></bread-crumb>
        <no-content></no-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoContentPageComponent extends AbstractFacadePageComponent implements CustomPageRender {

    constructor(public injector:Injector,
                public windowRef:WindowRef) {
        super(injector);
    }

    created() {
        super.created();
        this.breadcrumbs = this.urlService.get404Crumbs();
        this.logger.debug('Handling no content as route not matched');

        this.handlePageRender();
    }

    setData(value:CustomPageData<any>):void {
        // do nothing
    }

    setMetaForPage() {
        // This responsibility is moved to the underlying component
    }

    get name() {
        return 'NoContentPageComponent';
    }
}
