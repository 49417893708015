import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {
    ISearchBarDataSource,
    ISearchBarRenderer
} from '../../../../cross-platform/external-data/magento/search/search.types';
import {KeyCode} from '../../../../cross-platform/shared/keyboard-constants';
import {AbstractFacadeComponent} from '../../../../cross-platform/shared/ui/abstract-facade.component';
import {WindowRef} from '../../../../cross-platform/shared/ui/window-ref';
import {first} from 'rxjs/operators';
import {Search} from '../../../../cross-platform/menu/search/search.state';
import ResetSearchResultsPageTerm = Search.ResetSearchResultsPageTerm;


@Component({
    selector       : 'search-bar-generic',
    templateUrl    : './search-bar-generic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarGenericComponent extends AbstractFacadeComponent {

    @Input()
    placeholder = '';

    @Input()
    dataSource:ISearchBarDataSource;

    @Input()
    isSearchResultsPage = false;

    @ViewChild('input', {static: true})
    input:ElementRef;

    @Output()
    modalShow = new EventEmitter<void>();

    @Output()
    modalHide = new EventEmitter<void>();


    modalShowing = false;

    modalClasses  = '';
    dropDownClass = '';

    _dropDownReference:ISearchBarRenderer;
    dropDownSub1:Subscription;
    dropDownSub2:Subscription;
    searchControl:FormControl = new FormControl();
    //searchDebounce:number;
    searchSubscription:Subscription;
    focused                   = false;

    private searchDebounce;

    constructor(injector:Injector, public windowRef:WindowRef) {
        super(injector);
    }

    created() {
        this.addComponentSub = this.searchControl.valueChanges.subscribe((value:string) => {
            if (!this.modalShowing) {
                this.logger.debug('Showing because of text input');
                this.showHideModal(true);
            }
            //clearTimeout(this.searchDebounce);
            this.logger.test('search term change: ', value);

            //this.searchDebounce = <any> setTimeout(() => {
            this.doSearch();
            //}, 300);
        });

        this.addComponentSub = this.storeSelect(s => s.search.autoCompleteCurrentSearchTerm).subscribe(searchTerm => {
            if (this.searchControl.value !== searchTerm) {
                this.logger.debug('Setting search term from redux: ', searchTerm);
                if (searchTerm == null || searchTerm === '') {
                    this.searchControl.reset(null, {emitEvent: false});
                }
                else {
                    this.searchControl.setValue(searchTerm, {emitEvent: false});
                }
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    /*initAfterAppReady(){
        super.initAfterAppReady();
        this.searchControl.setValue('testing');
        this.focusInEvent();
    }*/

    searchClick() {
        const isEmpty  = this.searchControl.value == null || this.searchControl.value === '';
        const isClosed = !this.modalShowing;

        this.logger.debug('searchClick(), isEmpty: ', isEmpty, ', isClosed: ', isClosed);

        // Click when closed and empty, open, run search
        // Click when closed and populated, link
        // Click when open and empty, nothing
        // Click when open and populated, link, close
        if (isClosed && isEmpty) {
            this.showHideModal(true);
            this.doSearch();
        }
        else if (isClosed && !isEmpty) {
            this.handleLinkToSearchPage();
        }
        else if (!isClosed && isEmpty) {
            // Nothing
        }
        else if (!isClosed && !isEmpty) {
            this.handleLinkToSearchPage(true);
        }
    }

    handleLinkToSearchPage(closeModal = false) {
        this.addSub = this.store.selectOnce(s => s.search.autoCompleteResults)
            .pipe(first())
            .subscribe(results => {
                const productsTotal = results?.productRes.data?.length;
                const wpDataTotal   = results?.blogRes?.getTotalItems();
                let href:string     = this.urlService.buildUrlForSearchResults(this.searchControl.value);
                if (productsTotal && productsTotal > 0) {
                    href = this.urlService.buildUrlForSearchResults(this.searchControl.value);
                }
                else if (wpDataTotal && wpDataTotal > 0) {
                    href = this.urlService.buildUrlForSearchPageResults(this.searchControl.value);
                }

                this.facadePlatform.handleLink({
                    href      : href,
                    newTab    : false,
                    isBackLink: false
                });
                if (closeModal) this.showHideModal(false);
            });
    }

    @Input()
    set dropDownReference(value:ISearchBarRenderer) {
        if (this.dropDownSub1 != null) this.dropDownSub1.unsubscribe();
        if (this.dropDownSub2 != null) this.dropDownSub2.unsubscribe();

        this._dropDownReference = value;
        if (this._dropDownReference) {
            this.dropDownSub1 = this._dropDownReference.onFocusLost.subscribe(() => {
                if (this.modalShowing) {
                    this.logger.debug('Received focus lost');
                    this.focusIn();
                }
            });
            this.dropDownSub2 = this._dropDownReference.onHideRequest.subscribe(() => {
                if (this.modalShowing) {
                    this.logger.debug('Received hide request');
                    this.showHideModal(false);
                }
            });
        }
    }

    keyListener = (event:KeyboardEvent) => {
        //console.log('========> KEY EVENT: ', event);

        if (event.keyCode === KeyCode.ESC) {
            this.logger.debug('Escape key pressed, hiding content');
            this.showHideModal(false);
        }
        else if (event.keyCode === KeyCode.DOWN || event.keyCode === KeyCode.TAB) {
            this.focusOut();
            //this.logger.debug('Down key pressed, preventing scrolling');
            event.preventDefault(); // Prevent scrolling on the page
            if (this._dropDownReference) this._dropDownReference.handleNavigation(event);
            return false;
        }
        else if (event.keyCode === KeyCode.UP) {
            //this.logger.debug('Down key pressed, preventing scrolling');
            event.preventDefault(); // Prevent scrolling on the page
            if (this._dropDownReference) this._dropDownReference.handleNavigation(event);
            return false;
        }
        else if (event.keyCode === KeyCode.LEFT || event.keyCode === KeyCode.RIGHT) {
            // Do nothing atm
        }
        else if (event.keyCode === KeyCode.ENTER) {
            // If we have focus then lets go to the search page
            if (this.focused) {
                this.searchClick();
            }
            // Otherwise lets send to the drop down
            else if (this._dropDownReference) {
                this._dropDownReference.handleNavigation(event);
            }
        }
    };

    focusInEvent() {
        this.focusIn();
        // Do a search when opening to trigger any updates
        if (!this.modalShowing && this.searchControl.value != null && this.searchControl.value !== '') {
            this.logger.debug(`Focus in with text available, showing and searching: '${this.searchControl.value}'`);
            this.showHideModal(true);
            this.doSearch();
        }
    }

    focusIn() {
        this.focused = true;
        if (this.input && this.input.nativeElement.focus) this.input.nativeElement.focus();
    }

    focusOut() {
        this.focused = false;
        if (this.input && this.input.nativeElement.blur) this.input.nativeElement.blur();
    }

    modalClick() {
        this.showHideModal(false);
    }

    showHideModal(show:boolean) {
        if (this.modalShowing !== show) {
            this.modalShowing = show;
            if (this._dropDownReference) this._dropDownReference.onShowHide(show);

            if (show) {
                this.windowRef.addEventListener('keydown', this.keyListener);
                this.modalClasses  = 'show';
                this.dropDownClass = 'show';
                setTimeout(() => {
                    this.modalClasses = 'show fade';
                    this.changeDetectorRef.detectChanges();
                });
                if(this.implementationDataService.getPreventScrollingOnSearch()) this.windowRef.nativeWindow?.document?.body?.classList?.add('modal-open');
                this.modalShow.emit();
            }
            else {
                this.focusOut();
                //clearTimeout(this.searchDebounce);
                this.windowRef.removeEventListener('keydown', this.keyListener);
                if (this.searchSubscription) this.searchSubscription.unsubscribe();
                this.modalClasses  = 'show';
                this.dropDownClass = '';
                setTimeout(() => {
                    this.modalClasses = '';
                    this.changeDetectorRef.detectChanges();
                }, 300);
                if (this.isSearchResultsPage) this.store.dispatch(new ResetSearchResultsPageTerm());
                if(this.implementationDataService.getPreventScrollingOnSearch()) this.windowRef.nativeWindow?.document?.body?.classList?.remove('modal-open');
                this.modalHide.emit();
            }
            this.changeDetectorRef.detectChanges();
        }
    }

    doSearch() {
        if (this.searchDebounce) clearTimeout(this.searchDebounce);
        this.searchDebounce = <any>setTimeout(() => {
            if (this.searchSubscription) this.searchSubscription.unsubscribe();
            if (this.dataSource) {
                // Subscribe with a noop to ensure errors don't bubble up to the runtime error handler
                this.searchSubscription = this.dataSource.textSearch(this.searchControl.value).subscribe(() => {
                }, () => {
                });
            }
        }, 300);
    }

    get name() {
        return 'SearchDropDownComponent';
    }
}
