import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CrossPlatformModule} from '../../../../cross-platform/cross-platform.module';
import {BreadCrumbComponent} from './bread-crumb/bread-crumb.component';
import {DPageMenuComponent} from './d-page-menu.component';
import {DScrollingContainerDirective} from './d-scrolling-container.directive';
import {DesktopPagePreloaderComponent} from './desktop-page-preloader/desktop-page-preloader.component';
import {ErrorDisplayComponent} from './error-display.component';
import {ErrorMessageComponent} from './error-message/error-message.component';
import {DSidePageMenuComponent} from './d-side-page-menu.component';
import {FacadeSwiperLazyUtils} from '../../../../mobile/app/shared/ui/facade-swiper/facade-swiper-lazy-utils';
import {FacadeSwiperComponent} from './facade-swiper/facade-swiper.component';
import {AuthModalComponent} from '../../auth/auth-modal/auth-modal.component';
import {AuthLoginComponent} from '../../auth/components/auth-login.component';
import {FacadeFormsModule} from '../../forms/facade-forms.module';
import {SubHeaderNavComponent} from './sub-header-nav.component';
import {RendererPreloaderComponent} from '../../product-page/interactive-renderers/renderer-preloader.component';
import {CartDoesntExistModalComponent} from './cart-doesnt-exist-modal.component';
import {ProductPriceComponent} from '../../../../cross-platform/product-list-page/product-list/product-price.component';
import {ProductLabelComponent} from '../../../../cross-platform/shared/ui/product-label/product-label.component';
import {LogoutPromptModule} from '../../auth/logout-prompt/logout-prompt.module';
import {IntersectionObserverComponent} from '../../../../cross-platform/shared/ui/intersection-observer.component';
import {ProductImageComponent} from '../../../../cross-platform/shared/ui/product-image.component';
import {SisterSiteBar} from '../../../../cross-platform/shared/ui/sister-site-bar/sister-site-bar.component';
import {
    SimplePaginatorComponent
} from '../../../../cross-platform/shared/ui/simple-paginator/simple-paginator.component';
import {
    RecaptchaMessageComponent
} from '../../../../cross-platform/shared/ui/recaptcha-message/recaptcha-message.component';
import {PlusMoreButtonComponent} from '../../../../cross-platform/plus-more/plus-more-button.component';
import {RecaptchaBlockComponent} from '../../../../cross-platform/checkout/recaptcha/recaptcha-block.component';

@NgModule({
    imports     : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FacadeFormsModule,
        TranslateModule,
        CrossPlatformModule,
        LogoutPromptModule,
        PlusMoreButtonComponent
    ],
    declarations: [
        DesktopPagePreloaderComponent,
        ErrorDisplayComponent,
        ErrorMessageComponent,
        DPageMenuComponent,
        BreadCrumbComponent,
        FacadeSwiperComponent,
        DScrollingContainerDirective,
        DSidePageMenuComponent,
        AuthModalComponent,
        CartDoesntExistModalComponent,
        AuthLoginComponent,
        SubHeaderNavComponent,
        RendererPreloaderComponent,
        ProductPriceComponent,
        ProductImageComponent,
        ProductLabelComponent,
        IntersectionObserverComponent,
        SisterSiteBar,
        SimplePaginatorComponent,
        RecaptchaMessageComponent,
        RecaptchaBlockComponent
    ],
    exports     : [
        DesktopPagePreloaderComponent,
        ErrorDisplayComponent,
        ErrorMessageComponent,
        DPageMenuComponent,
        BreadCrumbComponent,
        FacadeSwiperComponent,
        DScrollingContainerDirective,
        DSidePageMenuComponent,
        AuthModalComponent,
        CartDoesntExistModalComponent,
        AuthLoginComponent,
        SubHeaderNavComponent,
        RendererPreloaderComponent,
        ProductPriceComponent,
        ProductImageComponent,
        ProductLabelComponent,
        IntersectionObserverComponent,
        SisterSiteBar,
        SimplePaginatorComponent,
        RecaptchaMessageComponent,
        RecaptchaBlockComponent
    ]
})
export class GlobalSharedUIModule {
    static forRoot() {
        return {
            ngModule : GlobalSharedUIModule,
            providers: [
                FacadeSwiperLazyUtils
            ]
        };
    }
}
