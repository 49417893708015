import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Logger} from '../../shared/logger';
import {ResponseCode} from '../enum/response-code.enum';
import {ExternalResponse} from '../external-response';
import {environment} from '../../implementation/environment';
import {UploadTaskSnapshot} from '@firebase/storage';
import {FirebaseApp} from '@firebase/app-types';
import {FirebaseMessagingErrors} from './firebase-messaging-errors';
import {FacadePlatform} from '../../shared/facade-platform';
import {
    FirebaseAnalyticsLib,
    FirebaseAppLib,
    FirebaseAuthLib,
    FirebaseMessagingLib,
    FirebaseStorageLib
} from './firebase.types';
import {RuntimeErrorHandlerService} from '../../error-handling/runtime-error-handler.service';

export interface IFirebaseError {
    error:any;
    errorCode?:string;
    errorMsg?:string;
    userMsg?:string;
}

export interface IFirebaseUploadError extends IFirebaseError {
    snapshot:UploadTaskSnapshot;
}

export interface UploadResponse {
    bytesTransferred?:number;
    totalBytes?:number;
    downloadURL?:string;
    name?:string;
}

export interface GetTokenResponse {
    success:boolean;
    token?:string;
    denied?:boolean;
    error?:any;
}

@Injectable()
export class FirebaseWrapperService {
    private messageListening = false;
    private app:FirebaseApp;

    private appLib:FirebaseAppLib;
    private analyticsLib:FirebaseAnalyticsLib;
    private messagingLib:FirebaseMessagingLib;
    private authLib:FirebaseAuthLib;
    private storageLib:FirebaseStorageLib;

    constructor(public logger:Logger,
                public facadePlatform:FacadePlatform,
                public runtimeErrorHandlerService:RuntimeErrorHandlerService) {
        this.logger = this.logger.getLogger('FirebaseWrapperService');
    }


    public initForMessaging() {
        //this.logger.debug('Init for messaging');
        // It's fine to do this multiple times, if already loaded it simply resolves
        return Promise
            .all([
                import('firebase/app'),
                import('firebase/analytics'),
                import('firebase/messaging'),
            ])
            .then(modules => {
                this.appLib       = <any>modules[0];
                this.analyticsLib = <any>modules[1];
                this.messagingLib = <any>modules[2];
                this.initApp();
            })
            .catch(error => {
                return Promise.reject('Error loading firebase SDK' + error && error.hasOwnProperty('message') ? error.message : error);
            });
    }

    public initForUpload() {
        //this.logger.debug('Init for upload');
        return Promise
            .all([
                import('firebase/app'),
                import('firebase/auth'),
                import('firebase/storage'),
            ])
            .then(modules => {
                this.appLib     = <any>modules[0];
                this.authLib    = <any>modules[1];
                this.storageLib = <any>modules[2];
                this.initApp();
            })
            .catch(error => {
                return Promise.reject('Error loading firebase SDK' + error && error.hasOwnProperty('message') ? error.message : error);
            });
    }

    public initApp() {
        if (this.app == null) {
            const config                              = {};
            config['a' + 'p' + 'i' + 'K' + 'e' + 'y'] = atob(environment.config.api.firebaseConfig[0]);
            config['authDomain']                      = atob(environment.config.api.firebaseConfig[1]);
            config['databaseURL']                     = atob(environment.config.api.firebaseConfig[2]);
            config['projectId']                       = atob(environment.config.api.firebaseConfig[3]);
            config['storageBucket']                   = atob(environment.config.api.firebaseConfig[4]);
            config['messagingSenderId']               = atob(environment.config.api.firebaseConfig[5]);
            config['appId']                           = atob(environment.config.api.firebaseConfig[6]);
            config['measurementId']                   = atob(environment.config.api.firebaseConfig[7]);
            this.app                                  = this.appLib.initializeApp(config);
        }
    }


    /*
    Analytics
    --------------------------------------------
    */
    async initFirebaseAnalytics() {
        await this.initForMessaging();
        return this.analyticsLib.initializeAnalytics(this.app, {config: {send_page_view: false}});
    }

    /*
    Uploads
    --------------------------------------------
    */
    signIn():Observable<ExternalResponse> {
        return Observable.create(observer => {
            this.logger.debug('signing into firebase');

            this.authLib.signInAnonymously(this.authLib.getAuth(this.app))
                .then(
                    () => {
                        this.logger.debug('signInAnonymously() successful');
                        const response:ExternalResponse = {responseCode: ResponseCode.SUCCESS};
                        observer.next(response);
                        observer.complete();
                    },
                    error => {
                        this.logger.debug('signInAnonymously() error: ', error);
                        const response:ExternalResponse<IFirebaseError> = {
                            responseCode: ResponseCode.GENERAL_ERROR,
                            error       : {
                                error    : error,
                                errorCode: error['code'],
                                errorMsg : error['message']
                            }
                        };
                        observer.error(response);
                        // observer.complete(); // No need to complete as an error ends the stream
                    }
                );
        });
    }

    uploadFile(folder:string, file:File):Observable<ExternalResponse<UploadResponse>> {
        return Observable.create(observer => {
            let filePath       = this.cleanupFilePath(folder, file.name);
            filePath           = this.makeFilePathUnique(filePath);
            const fileRef      = this.storageLib.ref(this.storageLib.getStorage(this.app), filePath);
            const uploadTask   = this.storageLib.uploadBytesResumable(fileRef, file);
            const eventRemover = uploadTask.on(
                'state_changed',
                (snapshot:UploadTaskSnapshot) => {

                    this.logger.test(`Upload is ${Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100)}% done`);

                    switch (snapshot.state) {
                        case 'paused':
                            this.logger.test('Upload is paused');
                            // Nothing to do here
                            break;
                        case 'running':
                            // Dispatch the progress
                            this.logger.test('Upload is running');
                            const response:ExternalResponse<UploadResponse> = {responseCode: ResponseCode.PROGRESS, data: {bytesTransferred: snapshot.bytesTransferred, totalBytes: snapshot.totalBytes}};
                            observer.next(response);
                            break;
                        case 'canceled':
                            this.logger.test('Upload is cancelled');
                            // Handled in the error handler
                            break;
                        case 'error':
                            this.logger.test('Upload had an error');
                            // Handled in the error handler
                            break;
                        case 'success':
                            this.logger.test('Upload had an error');
                            // Handled in the success handler
                            break;
                    }
                },
                (error) => {
                    const response:ExternalResponse<IFirebaseUploadError> = {
                        responseCode: ResponseCode.GENERAL_ERROR,
                        error       : {
                            snapshot : uploadTask.snapshot,
                            error    : error,
                            errorCode: error['code'],
                            errorMsg : error['message']
                        }
                    };
                    observer.error(response);
                },
                async () => {
                    const downloadURL                               = await this.storageLib.getDownloadURL(uploadTask.snapshot.ref);
                    // Handle unsuccessful uploads
                    const response:ExternalResponse<UploadResponse> = {responseCode: ResponseCode.SUCCESS, data: {downloadURL: downloadURL, name: uploadTask.snapshot.ref.name}};
                    observer.next(response);
                    observer.complete();
                }
            );

            return () => {
                uploadTask.cancel();
                eventRemover();
            };
        });
    }


    cleanupFilePath(folder:string, fileName:string):string {
        // Cleanup the folder
        if (folder.charAt(0) === '/') folder = folder.substr(1);
        if (folder.charAt(folder.length - 1) === '/') folder = folder.substr(0, folder.length - 1);
        // Remove any non character, dash, underscore, dot, bracket. This makes it compatible with firebase
        fileName     = fileName.replace(/[^0-9a-zA-Z._\-\(\) ]/g, '');
        // Replace any spaces with underscore, expecting Clifford's third party system will most likely break with spaces
        fileName     = fileName.replace(/  /g, ' ');
        fileName     = fileName.replace(/ /g, '_');
        // Now lets join the folder and filename
        const result = folder + '/' + fileName;
        this.logger.debug('Cleaning file path', folder, fileName, result);
        return result;
    }

    makeFilePathUnique(filePath:string, unique:string = new Date().getTime() + ''):string {
        // Strip off the extension
        let prefix = filePath;
        let ext    = '';

        // If it has an extension
        if (filePath.indexOf('.') !== -1) {
            prefix = filePath.substr(0, filePath.lastIndexOf('.'));
            ext    = filePath.substr(filePath.lastIndexOf('.'));
        }
        // Now join the three parts
        const result = prefix + unique + ext;

        this.logger.debug('Making filepath unique', filePath, prefix, unique, ext);
        return result;
    }


    /*
    Messaging
    --------------------------------------------
    */
    async isPushSupported() {
        await this.initForMessaging();
        return this.messagingLib.isSupported();
    }

    currentPermission():'denied' | 'granted' | 'default' | null {
        return window?.Notification?.permission;
    }

    async getToken():Promise<GetTokenResponse> {
        await this.initForMessaging();

        const formatErr = (err) => {
            return {
                success: false,
                denied : (err?.code === FirebaseMessagingErrors.PERMISSION_BLOCKED),
                error  : err
            };
        };

        try {
            const vapidKey = atob(environment.config.api.firebaseConfig[8]);
            const token    = await this.messagingLib.getToken(this.messagingLib.getMessaging(this.app), {vapidKey: vapidKey});
            this.logger.test('token:', token);
            return {success: true, token: token};
        }
        catch (err) {
            /*
            We get this exception if there is no service worker yet registered.
            It's really the role of the firebase lib to wait for the registration then attempt the subscribe
            But it seems there is an internal bug there so we will deal with this by waiting ourselves and retrying once

            Error DOMException: Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker
            */
            if (err?.message?.indexOf('no active Service Worker') !== -1) {
                this.logger.debug('Retrying as got the service worker bug.', err);
                try {
                    await navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope');
                    const vapidKey = atob(environment.config.api.firebaseConfig[8]);
                    const token    = await this.messagingLib.getToken(this.messagingLib.getMessaging(this.app), {vapidKey: vapidKey});
                    return {success: true, token: token};
                }
                catch (err) {
                    this.runtimeErrorHandlerService.logExceptionObject(err);
                    return formatErr(err);
                }
            }
            else {
                this.logger.error('Get token failed with code: ' + err.code, err);
                this.runtimeErrorHandlerService.logExceptionObject(err);
                return formatErr(err);
            }
        }
    }

    async setupMessageHandler() {
        if (!this.messageListening) {
            await this.initForMessaging();

            if (this.messagingLib.isSupported() && this.currentPermission() === 'granted') {
                // Here we need to "re-fetch" the token, this is in case the original request failed due to the service worker not yet ready
                const token = await this.getToken();
                this.logger.debug('Setting up message handler: ', token);

                /*
                The below onMessage() is what you would call the "foreground" message handler
                firebase wants you to handle your own notification if the app is open and in focus.

                We have decided to use the default native notification system here
                so we send the notification to the service worker.

                What is key here is the data payload which includes FCM_MSG and the original payload from firebase.
                This is how firebase structures things when showing the notification when in the background and is critical for
                handling of the click action and maintaining the default functionality there

                The second listener, 'message', is a generic service worker to app event.
                The firebase source will send this message to send the analytics event on so we hook into this
                to solve the problem of navigating to a new url
                */
                this.messagingLib.onMessage(this.messagingLib.getMessaging(this.app), (payload:any) => {
                    this.logger.debug('Message received. ', payload);
                    navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope')
                        .then(registration => {
                            const title   = payload.notification.title;
                            const options = {
                                body : payload.notification.body,
                                image: payload.notification.image,
                                icon : payload.notification.icon,
                                data : {FCM_MSG: payload}
                            };
                            this.logger.debug('Sending message to service worker:', title, options);
                            registration.showNotification(payload.notification.title, options);
                        });
                });
                this.messageListening = true;

                // This is a listener for messages from the service worker
                navigator.serviceWorker.addEventListener('message', e => {
                    this.logger.debug('Received message from the service worker: ', e);
                    if (e?.data?.messageType === 'notification-clicked' && e?.data?.notification?.click_action) {
                        this.facadePlatform.handleLinkDirect(e.data.notification.click_action);
                    }
                });
            }
        }
    }

    hasReceivedMessage():Promise<boolean> {
        return new Promise(resolve => {
            if (navigator?.serviceWorker) {
                try {
                    this.logger.debug('Waiting for service worker message!');
                    // Add listener
                    const listener = e => {
                        this.logger.debug('App ready message listener: ', e);
                        if (e?.data?.name === 'has-received-message') complete(e?.data?.data === true, 'Completing from event listener');
                    };
                    navigator.serviceWorker.addEventListener('message', listener);
                    const complete = (hasMessage:boolean, message:string) => {
                        this.logger.debug(message, hasMessage);
                        navigator.serviceWorker.removeEventListener('message', listener);
                        clearTimeout(timer);
                        resolve(hasMessage);
                    };
                    let timer;

                    // Wait for the service worker, then tell it we are listening
                    navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope')
                        .then(registration => {
                            registration.active.postMessage({name: 'app-ready'});

                            timer = setTimeout(() => complete(false, 'Completing from timeout'), 100);
                        })
                        .catch(err => {
                            complete(false, 'Completing getRegistration catch()');
                        });
                }
                catch (err) {
                    this.logger.error('Error in hasReceivedMessage', err);
                    resolve(false);
                }
            }
            else {
                resolve(false);
            }
        });
    }

}
