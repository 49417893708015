import {Routes} from '@angular/router';
import {
    PageMenuConfigItem
} from '../../../../../../framework/src/cross-platform/shared/ui/page-menu/abstract-page-menu.component';
import {
    ComponentRegistrationConfig
} from '../../../../../../framework/src/cross-platform/inject-component/inject-component.service';
import {PageTypeEnum} from '../../../../../../framework/src/cross-platform/router/page-type.enum';
import {CustomPageEnum} from '../../../cross-platform/custom-page.enum';

// relative to framework\src\desktop\app\app.module.ts

export const implRoutes:Routes = [
    {path: PageTypeEnum.CONTACT_US, loadChildren: () => import(`../contact-us-page/contactus-page.module`).then(m => m.ContactusPageModule)},
    {path: PageTypeEnum.CUSTOM + '/' + CustomPageEnum.more, loadChildren: () => import('../custom-pages/more/more-custom-page.module').then(m => m.MoreCustomPageModule)},
];

export const implAccountRoutes:Routes                                  = [];
export const implAccountComponents                                     = [];
export const implAccountInjectComponents:ComponentRegistrationConfig[] = [];
export const implAccountSideMenuConfigAdditions:PageMenuConfigItem[]   = [];
