import {Injectable, Injector} from '@angular/core';
import {Observable, of} from 'rxjs';
import {FMCustomPageSEOData} from '../external-data/firebase/firebase.types';
import {AbstractUrlService} from './abstract-url.service';
import {Logger} from './logger';
import {BreadcrumbLink} from './ui/breadcrumb/breadcrumb.types';
import {WindowRef} from './ui/window-ref';
import {FacadePlatform} from './facade-platform';
import {environment} from '../implementation/environment';
import {PepkorCategoryapiDataCategoryapiTreeInterface} from '../external-data/magento/magento.types';
import {CategoryHash} from '../external-data/magento/magento.reducer.types';
import {Store} from '@ngxs/store';
import {first} from 'rxjs/operators';
import {MagentoStateModel} from '../external-data/magento/magento.state';

/**
 * Angular injectable version of the AbstractUrlService
 */

@Injectable()
export class UrlService extends AbstractUrlService {

    private _windowRef:WindowRef;
    public facadePlatform:FacadePlatform;
    private magentoState:MagentoStateModel;

    constructor(public injector:Injector, logger:Logger) {
        super(logger.getLogger('UrlService'));
    }

    public getDeps() {
        if (!this.facadePlatform) {
            this.facadePlatform = this.injector.get(FacadePlatform);
            this._windowRef     = this.injector.get(WindowRef);
            const store         = this.injector.get(Store);
            store.select(s => s.magento).pipe(first(v => v.categoryIDHash != null))
                .subscribe(magento => this.magentoState = magento);
        }
    }

    public getBreadcrumbForCustomPage(url:string):Observable<BreadcrumbLink[]> {
        this.getDeps();
        return of(this.mapBreadcrumbsForCustomPage(url, environment.config.customPageSEOData));
    }

    get categoryIDHash():CategoryHash {
        this.getDeps();
        return this.magentoState.categoryIDHash;
    }

    get categoryUrlKeyHash():CategoryHash {
        this.getDeps();
        return this.magentoState.categoryUrlKeyHash;
    }

    get categoryTree():PepkorCategoryapiDataCategoryapiTreeInterface {
        this.getDeps();
        return this.magentoState.categoryTree;
    }

    get customPageSEOData():FMCustomPageSEOData[] {
        this.getDeps();
        return environment.config.customPageSEOData;
    }

    get documentBaseUrl():string {
        this.getDeps();
        return environment.config.api.documentBaseUrl;
    }

    get cdnRootUrl():string {
        this.getDeps();
        return environment.config.api.cdnRootUrl;
    }

    get magentoImageBaseUrl():string {
        this.getDeps();
        return environment.config.api.magentoImageBaseUrl;
    }

    get brandLogoImageBaseUrl():string {
        this.getDeps();
        return environment.config.api.brandLogoImageBaseUrl;
    }

    get productAttributeAssetBaseUrl():string {
        this.getDeps();
        return environment.config.api.productAttributeAssetBaseUrl;
    }

    get windowHref():string {
        this.getDeps();
        return this.windowRef.href;
    }

    get isMobile():boolean {
        this.getDeps();
        //this.logger.info(`Building menu, mobile: ${this.facadePlatform.isMobile}`, this.facadePlatform);
        return this.facadePlatform.isMobile;
    }

    get isMobileApp():boolean {
        this.getDeps();
        return this.facadePlatform.isMobileApp;
    }

    get rootPageSlugs():string[] {
        this.getDeps();
        return environment.config.api.wordpressRootPages;
    }

    get websiteBasePath():string {
        return environment.config.api.websiteBasePath;
    }

    get windowRef():WindowRef {
        this.getDeps();
        return this._windowRef;
    }
}
