import {Injectable, Injector} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {ExternalResponse} from '../../external-response';
import {
    ImplementationFilterSettings,
    ProductListUrlSerializer
} from '../../../product-list-page/product-list-url-serializer';
import {ProductsPagedSet} from '../model/products-paged-set';
import {SortOption} from '../../../product-list-page/filters/filters.types';
import {MagentoAttribute} from '../../enum/magento-constants';
import {Observable, of} from 'rxjs';
import {ImplementationDataService} from '../../../implementation-config/implementation-data.service';
import {
    GET_PRODUCT_LIST_AND_AGGREGATIONS,
    GET_PRODUCT_LIST_AND_AGGREGATIONS_CACHE
} from '../gql-queries/get-product-list-and-aggregations.query';
import {
    Aggregation,
    AmLabel,
    AmLabelList,
    AmLabelMode,
    ConfigurableProduct,
    GqlMagentoAttribute,
    ProductInterface,
    Products
} from '../../../../../../generated/graphql';
import {ProductModel} from './product.model';
import {GET_BASE_AGGREGATIONS, GET_BASE_AGGREGATIONS_CACHE} from '../gql-queries/get-base-aggregations';
import {
    GET_PRODUCT_FOR_PRODUCT_PAGE,
    GET_PRODUCT_FOR_PRODUCT_PAGE_CACHE
} from '../gql-queries/get-product-for-product-page.query';
import {
    GET_PRODUCT_FOR_AUTO_COMPLETE,
    GET_PRODUCT_FOR_AUTO_COMPLETE_CACHE
} from '../gql-queries/get-product-for-auto-complete.query';
import {
    GET_PRODUCTS_FOR_RELATED_AND_CAROUSEL,
    GET_PRODUCTS_FOR_RELATED_AND_CAROUSEL_CACHE
} from '../gql-queries/get-products-for-related-and-carousel.query';
import {ProductsResponse, RestProductAttributeResponse} from '../gql-queries/gql-query-response-types';
import {AbstractFacadeService} from '../../abstract-facade.service';
import {PepkorCategoryapiDataCategoryapiTreeInterface} from '../magento.types';
import {GET_PRODUCT_LABELS, GET_PRODUCT_LABELS_CACHE} from '../gql-queries/get-product-labels';
import {
    GET_REST_PRODUCT_ATTRIBUTES,
    GET_REST_PRODUCT_ATTRIBUTES_CACHE
} from '../gql-queries/get-rest-product-atttributes.query';
import {CategoryHash} from '../magento.reducer.types';
import {IProductModel} from '../model/iproduct.model';
import {GET_AI_RECOMMENDATIONS, GET_AI_RECOMMENDATIONS_CACHE} from '../gql-queries/get-ai-recommendations.query';
import {AiRecommendationType} from '../../../product-carousel/ai-recommendation-type';
import {RootState} from '../../../ngxs/root.state';


export interface ProductPageResponse {
    product:ProductModel;
}


export interface ProductAttribute {
    attribute:GqlMagentoAttribute;
    aggregation:Aggregation;
    options_hash?:{ [p:string]:{ index:number; label:any } };
}

export interface FetchProductLabelsResponse {
    amLabelProvider:AmLabelList[];
}

@Injectable()
export class ProductsService extends AbstractFacadeService {

    constructor(injector:Injector,
                private implementationDataService:ImplementationDataService) {
        super(injector);
    }

    fetchForProductList(serializer:ProductListUrlSerializer):Observable<ProductsPagedSet> {
        this.logger.debug('Fetch for product list graphql: ', serializer);
        if (serializer.categoryId == null && serializer.searchTerm == null) throw new Error('A categoryId or search term are required to execute this method');

        const hasFilters = (serializer?.selectedAttr?.length > 0);

        let products:Products;
        let baseAggregations:Aggregation[];
        let restProductAttributes:GqlMagentoAttribute[];
        let categoryUrlKeyHash;

        return this.store.select((s:RootState) => s.magento.categoryUrlKeyHash)
            .pipe(
                switchMap(catHash => {
                    categoryUrlKeyHash = catHash;
                    return this.apollo.query({query: GET_REST_PRODUCT_ATTRIBUTES}, GET_REST_PRODUCT_ATTRIBUTES_CACHE);
                }),
                switchMap((result:ExternalResponse<RestProductAttributeResponse>) => {
                    // store
                    restProductAttributes = result.data.restProductAttributes;

                    // If filters have been applied then we need to fetch the base set of aggregations
                    if (hasFilters) {
                        this.logger.debug('Has filters getting aggs: ', serializer);
                        return this.apollo.query({query: GET_BASE_AGGREGATIONS(serializer)}, GET_BASE_AGGREGATIONS_CACHE);
                    }
                    else {
                        return of(null);
                    }
                }),
                switchMap((result:ExternalResponse<ProductsResponse>) => {
                    if (hasFilters) baseAggregations = result.data.products.aggregations;
                    const implementationSettings:ImplementationFilterSettings = {
                        colorAttributeName     : this.implementationDataService.getColorAttrName(),
                        sizeAttributeName      : this.implementationDataService.getSizeAttrName(),
                        shouldFilterSizeInStock: this.implementationDataService.getProductFilterSettings().shouldFilterSizeInStock
                    };
                    return this.apollo.query({query: GET_PRODUCT_LIST_AND_AGGREGATIONS(serializer, restProductAttributes, categoryUrlKeyHash, this.implementationDataService.getHasPimSupport(), implementationSettings)}, GET_PRODUCT_LIST_AND_AGGREGATIONS_CACHE);
                }),
                map((result:ExternalResponse<ProductsResponse>) => {
                    if (!hasFilters) baseAggregations = result.data.products.aggregations;
                    products = result.data.products;

                    const set = new ProductsPagedSet();

                    if (hasFilters) {
                        set.baseAggregations     = baseAggregations;
                        set.filteredAggregations = products.aggregations;
                        set.allAttributeMetadata = restProductAttributes;
                    }
                    else {
                        set.baseAggregations     = baseAggregations;
                        set.filteredAggregations = baseAggregations;
                        set.allAttributeMetadata = restProductAttributes;
                    }

                    // Final filtering
                    set.baseAggregations     = this.filterAggregations(set.baseAggregations, serializer);
                    set.filteredAggregations = this.filterAggregations(set.filteredAggregations, serializer);

                    set.totalPerPage                   = serializer.pageSize;
                    set.totalItems                     = products.total_count;
                    set.sortFields                     = products.sort_fields;
                    const configurables:ProductModel[] = [];
                    products.items.forEach((product, index:number) => {
                        // The product's position in the fetched product list
                        let productIndex = index;
                        if (serializer.currentPage > 1) {
                            productIndex = productIndex + ((serializer.currentPage - 1) * serializer.pageSize);
                        }
                        // We only support ConfigurableProducts
                        // Possible Types: simple, virtual, bundle, downloadable, grouped, or configurable.
                        if ((<ConfigurableProduct>product).__typename === 'ConfigurableProduct') {
                            configurables.push(new ProductModel(product, this.urlService.categoryIDHash, restProductAttributes, productIndex));
                        }
                    });
                    set.setPageData(
                        serializer.currentPage,
                        configurables
                    );

                    return set;
                }),
            );
    }

    /*fetchForProductList(serializer:ProductListUrlSerializer):Observable<ProductsPagedSet> {
        this.logger.debug('Fetch for product list graphql');
        if (serializer.categoryId == null && serializer.searchTerm == null) throw new Error('A categoryId or search term are required to execute this method');

        let products:Products;
        let baseAggregations:Aggregation[];
        let startingQuery:Observable<ExternalResponse<ProductsResponse>> = of(null);
        const hasFilters                                                 = (serializer?.selectedAttr?.length > 0);
        // If filters have been applied then we need to fetch the base set of aggregations
        if (hasFilters) startingQuery = this.apollo.query({query: GET_BASE_AGGREGATIONS(serializer)}, GET_BASE_AGGREGATIONS_CACHE);

        return startingQuery.pipe(
            switchMap((result:ExternalResponse<ProductsResponse>) => {
                if (hasFilters) baseAggregations = result.data.products.aggregations;
                return this.apollo.query({query: GET_PRODUCT_LIST_AND_AGGREGATIONS(serializer, this.implementationDataService.getHasPimSupport())}, GET_PRODUCT_LIST_AND_AGGREGATIONS_CACHE);
            }),
            switchMap((result:ExternalResponse<ProductsResponse>) => {
                if (!hasFilters) baseAggregations = result.data.products.aggregations;
                products = result.data.products;
                return this.apollo.query({query: GET_REST_PRODUCT_ATTRIBUTES}, GET_REST_PRODUCT_ATTRIBUTES_CACHE);
            }),
            map((result:ExternalResponse<RestProductAttributeResponse>) => {
                const set = new ProductsPagedSet();

                if (hasFilters) {
                    set.baseAggregations     = baseAggregations;
                    set.filteredAggregations = products.aggregations;
                    set.allAttributeMetadata = result.data.restProductAttributes;
                }
                else {
                    set.baseAggregations     = baseAggregations;
                    set.filteredAggregations = baseAggregations;
                    set.allAttributeMetadata = result.data.restProductAttributes;
                }

                // Final filtering
                set.baseAggregations     = this.filterAggregations(set.baseAggregations, serializer);
                set.filteredAggregations = this.filterAggregations(set.filteredAggregations, serializer);

                set.totalPerPage                   = serializer.pageSize;
                set.totalItems                     = products.total_count;
                const configurables:ProductModel[] = [];
                products.items.forEach(product => {
                    // We only support ConfigurableProducts
                    // Possible Types: simple, virtual, bundle, downloadable, grouped, or configurable.
                    if ((<ConfigurableProduct>product).__typename === 'ConfigurableProduct') {
                        configurables.push(new ProductModel(product, this.urlService.categoryIDHash, result.data.restProductAttributes));
                    }
                });
                set.setPageData(
                    serializer.currentPage,
                    configurables
                );

                return set;
            })
        );
    }*/

    private filterAggregations(aggregations:Aggregation[], serializer:ProductListUrlSerializer) {
        if (aggregations) {
            // We first need to make a clone as properties will be read only
            aggregations = JSON.parse(JSON.stringify(aggregations));

            // When aggregations are returned they include categories that are not sub categories of the parent page
            // These need to be filtered out to only include categories in the children
            const categoryAgg = aggregations.find(agg => agg.attribute_code === MagentoAttribute.category_id);
            const catIdHash   = this.urlService.categoryIDHash;

            if (categoryAgg?.options?.length > 0 && catIdHash) {
                const rootCat = catIdHash[serializer.categoryId];
                if (rootCat == null) {
                    this.logger.error('No category found for the aggregation filtering');
                }
                else {
                    // Iterate the root category children to build a hash of all sub categories below the parent
                    const childHash:CategoryHash = {};
                    const iterate                = (cat:PepkorCategoryapiDataCategoryapiTreeInterface, include:boolean) => {
                        if (include) childHash[cat.id] = cat;
                        cat.children_data?.forEach(subCat => iterate(subCat, true));
                    };
                    iterate(rootCat, false);

                    // Now that we have a hash we can filter the options
                    categoryAgg.options = categoryAgg.options.filter(option => childHash[option.value + ''] != null);
                }
            }
        }
        return aggregations;
    }

    fetchForProductPage(productUrlKey:string):Observable<ExternalResponse<ProductPageResponse>> {
        let product:ProductInterface;

        return this.apollo.query({query: GET_REST_PRODUCT_ATTRIBUTES}, GET_REST_PRODUCT_ATTRIBUTES_CACHE)
            .pipe(
                switchMap((res:ExternalResponse<RestProductAttributeResponse>) => {
                    const temp        = new ProductModel(null, null, res.data.restProductAttributes);
                    const customCodes = temp.customAttributeMetadata.map(attr => attr.attribute_code);

                    return this.apollo.query({
                        query    : GET_PRODUCT_FOR_PRODUCT_PAGE(this.implementationDataService.getHasPimSupport(), customCodes),
                        variables: {urlKey: productUrlKey}
                    }, GET_PRODUCT_FOR_PRODUCT_PAGE_CACHE);
                }),

                switchMap((result:ExternalResponse<ProductsResponse>) => {
                    product = result.data.products?.items[0];
                    return this.apollo.query({query: GET_REST_PRODUCT_ATTRIBUTES}, GET_REST_PRODUCT_ATTRIBUTES_CACHE);
                }),

                map((result:ExternalResponse<RestProductAttributeResponse>) => {
                    const newRes:ExternalResponse<ProductPageResponse> = <any>result;
                    if (product) {
                        const convertedProduct = new ProductModel(product, this.urlService.categoryIDHash, result.data.restProductAttributes);
                        newRes.data            = {product: convertedProduct};
                    }
                    else {
                        newRes.data = null;
                    }
                    return newRes;
                })
            );
    }

    fetchAllBySkuList(skuList:string[], sort:SortOption = {sortField: MagentoAttribute.price, sortDescending: false}):Observable<ExternalResponse<ProductModel[]>> {
        const variables                = {
            filter  : {
                'sku': {
                    'in': skuList
                }
            },
            pageSize: skuList.length,
            sort    : {}
        };
        variables.sort[sort.sortField] = (sort.sortDescending) ? 'DESC' : 'ASC';

        return this.apollo
            .query({
                query    : GET_PRODUCTS_FOR_RELATED_AND_CAROUSEL(this.implementationDataService.getHasPimSupport()),
                variables: variables
            }, GET_PRODUCTS_FOR_RELATED_AND_CAROUSEL_CACHE)
            .pipe(
                map((result:ExternalResponse) => {
                    result.data = result?.data?.products?.items.map(product => new ProductModel(product, this.urlService.categoryIDHash));

                    // Sort the results by the same order requested
                    const map = {};
                    for (let i = 0; i < skuList.length; i++) {
                        map[skuList[i]] = i;
                    }
                    result.data.sort((a, b) => map[a.sku] - map[b.sku]);

                    return result;
                })
            );
    }

    fetchAiRecommendationsBySku(sku:string, type:AiRecommendationType):Observable<ExternalResponse<string[]>> {
        const variables = {
            sku               : sku,
            recommendationType: type
        };
        return this.apollo
            .query({
                query    : GET_AI_RECOMMENDATIONS,
                variables: variables
            }, GET_AI_RECOMMENDATIONS_CACHE)
            .pipe(
                map((result:ExternalResponse) => {
                    result.data = result.data.aiRecommendations.response;
                    return result;
                })
            );
    }

    fetchByCategories(categories:number[], size:number, sort:SortOption = {sortField: MagentoAttribute.price, sortDescending: false}) {
        const variables                = {
            filter  : {
                'category_id': {
                    'in': categories.map(id => String(id))
                }
            },
            pageSize: Number(size),
            sort    : {}
        };
        variables.sort[sort.sortField] = (sort.sortDescending) ? 'DESC' : 'ASC';

        return this.apollo
            .query({
                query    : GET_PRODUCTS_FOR_RELATED_AND_CAROUSEL(this.implementationDataService.getHasPimSupport()),
                variables: variables
            }, GET_PRODUCTS_FOR_RELATED_AND_CAROUSEL_CACHE)
            .pipe(
                map((result:ExternalResponse) => {
                    result.data = result?.data?.products?.items.map(product => new ProductModel(product, this.urlService.categoryIDHash));
                    return result;
                })
            );
    }

    fetchForAutocomplete(searchTerm:string):Observable<ExternalResponse<ProductInterface[]>> {
        return this.apollo.query(
            {
                query    : GET_PRODUCT_FOR_AUTO_COMPLETE(this.implementationDataService.getHasPimSupport()),
                variables: {searchTerm: searchTerm, pageSize: this.implementationDataService.getAutoCompleteMaxItems()}
            },
            GET_PRODUCT_FOR_AUTO_COMPLETE_CACHE
        ).pipe(
            map((result:ExternalResponse) => {
                result.data = result.data.products.items;
                return result;
            })
        );
    }

    fetchProductLabels(productIds:number[], mode:AmLabelMode = AmLabelMode.Product):Observable<ExternalResponse<FetchProductLabelsResponse>> {
        return this.apollo.query({
            query    : GET_PRODUCT_LABELS,
            variables: {productIds: productIds, mode: mode}
        }, GET_PRODUCT_LABELS_CACHE);
    }

    getProductsLabels(products:IProductModel[], mode:AmLabelMode = AmLabelMode.Product):Observable<IProductModel[]> {
        return new Observable<IProductModel[]>((observer) => {
            if (products?.length > 0) {
                // first get all of the product ids to load labels for
                // Sentry issue picked up products coming back as null sometimes:
                // https://sentry.io/organizations/pepkor-it/issues/2654816594/?project=5952058
                const productIds = products.filter(v => v != null).map(v => v.id);
                if (productIds.length > 0) {
                    this.fetchProductLabels(productIds, mode)
                        .subscribe(
                            res => {
                                if (res?.data?.amLabelProvider?.length > 0) {
                                    const items                                = res.data.amLabelProvider;
                                    const labelsHash:{ [id:number]:AmLabel[] } = {};
                                    const newProductList                       = [];
                                    items.forEach(item => {
                                        if (item?.items.length > 0) labelsHash[`${item.items[0].product_id}`] = item.items;
                                    });
                                    products.forEach(product => {
                                        let currProduct  = product as ProductModel;
                                        const currLabels = labelsHash[`${currProduct.id}`];
                                        if (currLabels) {
                                            // Creating new products:
                                            // This is a necessary evil to trigger a change detection
                                            currProduct        = new ProductModel(currProduct.source, this.urlService.categoryIDHash, currProduct.allAttributeMetadata);
                                            currProduct.labels = currLabels;
                                        }
                                        newProductList.push(currProduct);
                                    });
                                    observer.next(newProductList);
                                }
                                else {
                                    observer.error('no labels loaded');
                                }
                            },
                            err => {
                                // Do nothing - we don't need to disturb the experience if a label fetch fails
                                observer.error('error loading labels');
                            }
                        );
                }
                else {
                    observer.error('products coming back as null sometimes');
                }
            }
            else {
                observer.error('no products');
            }
        });
    }

    getName():string {
        return 'ProductsService';
    }
}
