import {Directive, ElementRef, OnInit} from '@angular/core';
import {DocumentRef} from './document-ref';

@Directive({selector: '[runScripts]'})
export class RunScriptsDirective implements OnInit {
    constructor(private elementRef:ElementRef,
                private documentRef:DocumentRef) {
    }

    ngOnInit():void {
        setTimeout(() => { // wait for DOM rendering
            this.reinsertScripts();
        });
    }

    reinsertScripts():void {
        const scripts              = <HTMLScriptElement[]>this.elementRef.nativeElement.getElementsByTagName('script');
        const scriptsInitialLength = scripts.length;
        for (let i = 0; i < scriptsInitialLength; i++) {
            const script     = scripts[i];
            const scriptCopy = <HTMLScriptElement>this.documentRef.createElement('script');
            scriptCopy.type  = script.type ? script.type : 'text/javascript';
            if (script.innerHTML) {
                scriptCopy.innerHTML = script.innerHTML;
            }
            else if (script.src) {
                scriptCopy.src = script.src;
            }
            scriptCopy.async = false;
            script.parentNode.replaceChild(scriptCopy, script);
        }
    }
}
