import {Compiler, Injector} from '@angular/core';
import {
    NavigationEnd,
    NavigationStart,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router,
    RouterEvent
} from '@angular/router';
import {merge, Observable} from 'rxjs';
import {
    combineLatest as combineLatestOp,
    distinctUntilChanged,
    filter,
    first,
    map,
    skipWhile,
    startWith,
    take
} from 'rxjs/operators';
import {FMConfigRedirect} from './external-data/firebase/firebase.types';
import {GoogleTagManager} from './external-data/google/google-tag-manager/google-tag-manager';
import {debug} from './shared/rxjs-operators';
import {AbstractFacadeComponent} from './shared/ui/abstract-facade.component';
import {BodyRef} from './shared/ui/body-ref';
import {RuntimeErrorEnum} from './error-handling/runtime-error.enum';
import {NetworkLoadMonitor} from './external-data/interceptors/network-load-monitor.service';
import {environment} from './implementation/environment';
import {SharedCartService} from './cart-shared/shared-cart.service';
import {BrowserUpgradeOptions} from './implementation-config/implementation-data.types';
import {FakeLinkEvent} from './shared/ui/fake-link.directive';
import {WordpressDynamicSettingsService} from './external-data/wordpress/wordpress-dynamic-settings.service';
import {TranslateService} from '@ngx-translate/core';
import {ResponseCode} from './external-data/enum/response-code.enum';
import {AppDynamicConfig, AppDynamicConfigMessage} from './external-data/wordpress/model/wordpress.types';
import {Store} from '@ngxs/store';
import {Search} from './menu/search/search.state';
import {History, HistoryStateObject} from './shared/history-state/history.state';
import {NavigatorRef} from './shared/ui/navigator-ref';
import {StorageService} from './shared/ui/storage/storage.service';
import {CrazyEggManager} from './external-data/crazyegg/crazyegg-manager';
import {StaticConfig} from './shared/static-config';
import {FirebaseWrapperService} from './external-data/firebase-wrapper/firebase-wrapper.service';
import {IPromptComponent} from './prompts/IPromptComponent';
import {LazyComponentLoader} from './utils/lazy-component-loader';
import {ScriptLoader} from './external-data/script-loader/script-loader';
import UpdateCurrentSearchTerm = Search.UpdateCurrentSearchTerm;

enum Prompt {
    cookie            = 'cookie',
    browsercheck      = 'browsercheck',
    pwa               = 'pwa',
    pushnotifications = 'pushnotifications'
}

export abstract class AbstractAppComponent extends AbstractFacadeComponent {

    // selectors
    criticalError$:Observable<boolean>;
    runTimeErrorType$:Observable<RuntimeErrorEnum>;
    hideContent$:Observable<boolean>;
    isPreloading$:Observable<boolean>;
    _isLazyLoading$:Observable<boolean>;


    lastUrl:string;

    // App messages
    appDynamicConfig:AppDynamicConfig;
    appMessage:AppDynamicConfigMessage;

    // Deps
    store:Store;
    bodyRef:BodyRef;
    navigatorRef:NavigatorRef;
    router:Router;
    networkLoadMonitor:NetworkLoadMonitor;
    sharedCartService:SharedCartService;
    translate:TranslateService;
    wordpressDynamicSettingsService:WordpressDynamicSettingsService;
    compiler:Compiler;
    //swUpdate:SwUpdate;
    RuntimeErrorEnum = RuntimeErrorEnum;
    configRedirects:FMConfigRedirect[];
    storageService:StorageService;
    firebaseWrapperService:FirebaseWrapperService;
    lazyComponentLoader:LazyComponentLoader;

    browserCheckIsShowing:boolean;

    currentUrl:string;
    promptCycleStarted = false;

    beforeinstallpromptEvent:any;

    // NOTE: PWA install has a bug where the app is installed and the cookie has been cleared
    // we never get the installation event and the dialog never shows hence we never know when its done
    // because of this its always last (potentially fixable with a timeout)
    prompts:Prompt[]    = [Prompt.cookie, Prompt.browsercheck, Prompt.pushnotifications, Prompt.pwa];
    lastPromptShown:Prompt;
    hasPushNotification = false;

    abstract initConsentCookie():Promise<IPromptComponent>;


    abstract initPushNotifications():Promise<IPromptComponent>;

    abstract initPWA():Promise<IPromptComponent>;

    protected sisterSiteBarEnabled = this.implementationDataService.getSisterSiteSettings()?.length > 0;

    protected constructor(injector:Injector) {
        super(injector);
        this.store                           = injector.get(Store);
        this.bodyRef                         = injector.get(BodyRef);
        this.router                          = injector.get(Router);
        this.networkLoadMonitor              = injector.get(NetworkLoadMonitor);
        this.sharedCartService               = injector.get(SharedCartService);
        this.translate                       = injector.get(TranslateService);
        this.wordpressDynamicSettingsService = injector.get(WordpressDynamicSettingsService);
        this.compiler                        = injector.get(Compiler);
        this.navigatorRef                    = injector.get(NavigatorRef);
        this.storageService                  = injector.get(StorageService);
        this.firebaseWrapperService          = injector.get(FirebaseWrapperService);
        this.lazyComponentLoader             = injector.get(LazyComponentLoader);
    }

    setup() {
        /*
        Create selectors
        --------------------------------------------
        */
        this.criticalError$ = merge(
            this.runtimeErrorHandlerService.hasRuntimeError$,
            this.storeSelect(s => s.app.hasStartupError)
        );

        this.criticalError$.subscribe(e => {
            if (e) this.facadePlatform.scrollToTop();
        });

        this.runTimeErrorType$ = this.runtimeErrorHandlerService.runTimeErrorType$;

        this.hideContent$ = this.criticalError$.pipe(
            combineLatestOp(this.globalSelectors.appReady$, (hasError, appReady) => hasError || !appReady),
            distinctUntilChanged()
        );


        /*
        Show hide preloader
        --------------------------------------------
        */
        this.isPreloading$ = this.globalSelectors.appReady$.pipe(
            debug('app ready for body fire'),
            combineLatestOp(this.isLazyLoading$, this.criticalError$, (appReady, isLazyLoading, criticalError) => (!appReady || isLazyLoading) && !criticalError),
            distinctUntilChanged(),
            debug('should hide angular app')
        );


        this.isPreloading$
            .subscribe(isPreloading => {
                if (isPreloading) {
                    //this.bodyRef.removeClass('ready');
                }
                else {
                    this.bodyRef.addClass('ready');
                }
            });


        /*
        Post startup actions
        --------------------------------------------
        */
        if (this.isBrowser) {

            // Lets ask the service worker if there was a push notification message
            this.firebaseWrapperService.hasReceivedMessage().then(async result => {
                this.hasPushNotification = result;
                this.logger.debug('Resolved this.hasPushNotification to: ', this.hasPushNotification);
                if (this.hasPushNotification) {
                    await GoogleTagManager.embedWithTracker(this.firebaseWrapperService, environment.config.api.gtmTracker);
                    await this.firebaseWrapperService.setupMessageHandler();
                }
            });

            this.addSub = this.router.events
                .pipe(filter(event => event['url']), take(1))
                .subscribe(evt => {
                    const event = evt as RouterEvent;

                    // Wait for the first router event that has a URL
                    const isConfirmationPage = event.url ? this.urlService.isUrlCheckoutComplete(event.url) : false;
                    if (isConfirmationPage) {
                        this.lazyLoadEarly();
                    }
                    else {
                        this.networkLoadMonitor.whenXhrIdle(4000).pipe(take(1)).subscribe(() => {
                            this.lazyLoadEarly();
                        });
                    }
                    this.networkLoadMonitor.whenXhrIdle(100).pipe(take(1)).subscribe(() => {
                        // Load segmentify
                        if (environment.config.api.segmentifyScriptUrl && environment.config.api.segmentifyEnabled) ScriptLoader.loadNewScript(environment.config.api.segmentifyScriptUrl).catch(e => console.error(e));
                    });

                    // We have to keep this below the delay as this delay keeps the monitor "unidle" untill all banner scroller images have loaded
                    this.networkLoadMonitor.whenAllIdle(StaticConfig.IMAGE_SCROLLER_DELAY - 500).pipe(take(1)).subscribe(() => {
                        this.lazyLoadLate();
                    });
                });

        }


        // If there is a critical error
        this.criticalError$.pipe(first(v => v === true)).subscribe(() => {
            if (environment.config.api.gtmTracker && this.isBrowser) GoogleTagManager.embedWithTracker(this.firebaseWrapperService, environment.config.api.gtmTracker);
        });

        /*
        Custom service worker register if enabled
        -------------------------------------------
         */
        if (this.navigatorRef.serviceWorker && this.implementationDataService.getPWASettings().enabled) {
            const serviceWorkerPath = this.urlService.buildUrlForAsset('service-worker.js');
            this.navigatorRef.serviceWorker.register(serviceWorkerPath, {scope: '/'}).then((registration) => {
                this.logger.debug('Service worker registration succeeded:', registration);
            }, /*catch*/ (error) => {
                this.logger.debug(`Service worker registration failed: ${error}`);
            });
        }


        /*
        On page change
        --------------------------------------------
        */
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event:NavigationEnd) => this.onPageNavEnd(event));

        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event:NavigationStart) => this.onPageNavStart(event));


        // Capturing of this event for the PWA module has to happen as soon as the app is instantiated otherwise it's lost
        this.windowRef.addEventListener('beforeinstallprompt', evt => {
            this.logger.debug('beforeinstallprompt()', evt);
            evt.preventDefault();
            this.beforeinstallpromptEvent = evt;
        });
    }

    initAfterAppReady() {
        super.initAfterAppReady();

    }

    async initNextPrompt() {
        if (this.prompts.length > 0) {
            const currentPrompt = this.prompts.shift();
            this.logger.debug('Attempting to run next prompt: ' + currentPrompt);

            const handleGenericPrompt = async (comp:IPromptComponent) => {

                if (!(await comp.willShow())) {
                    this.logger.debug('Prompt wont show ' + currentPrompt);
                    this.initNextPrompt();
                }
                else {
                    this.logger.debug('Showing next generic prompt: ', currentPrompt);
                    this.lastPromptShown = currentPrompt;
                    this.addSub          = comp.$onDismiss.subscribe(value => {
                        this.logger.debug('On dismiss ', value, currentPrompt);
                        this.initNextPrompt();
                    });
                }
            };

            // See specific component willShow() for exact business logic on if a component should show
            // the business logic to show a prompt in this class looks at the impl data service and the ordering of prompts shown
            if (currentPrompt === Prompt.cookie) {
                // This will always show up immediately if there is no cookie
                handleGenericPrompt(await this.initConsentCookie());
            }
            else if (currentPrompt === Prompt.browsercheck) {
                // This will always show up next if there is an outdated browser
                this.browserCheck();
            }
            else if (currentPrompt === Prompt.pushnotifications) {
                if (this.implementationDataService.getPushNotificationConfig().enabled && this.lastPromptShown == null) {
                    // This will only show if there were zero prompts before
                    // It will wait 1 minute before showing
                    setTimeout(async () => {
                        handleGenericPrompt(await this.initPushNotifications());
                    }, this.implementationDataService.getPushNotificationConfig().delay);
                }
            }
            else if (currentPrompt === Prompt.pwa) {
                if (this.implementationDataService.getPWASettings().installPrompt.enabled && (this.lastPromptShown === Prompt.pushnotifications || this.lastPromptShown == null)) {
                    // This will attempt to show next but fail to show in some situations
                    // where the event for install is needed (desktop browser, android) this will
                    // only fire if this is the first prompt that is shown
                    // i.e. if not the first prompt then it will be shown on the next load
                    handleGenericPrompt(await this.initPWA());
                }
            }

        }
    }

    async browserCheck() {
        /* Browser detection script */
        /* for customisation options see: http://browser-update.org/customize.html */
        const browserCheck:BrowserUpgradeOptions = this.implementationDataService.getBrowserUpgradeCheckEnabled();

        if (browserCheck && this.isBrowser) {
            browserCheck.onshow  = (info) => {
                this.lastPromptShown       = Prompt.browsercheck;
                this.browserCheckIsShowing = true;
            };
            browserCheck.onclose = (info) => {
                // when closing / ignoring
                this.browserCheckIsShowing = false;
                this.initNextPrompt();
            };
            browserCheck.onclick = (info) => {
                // when clicking to upgrade
                this.browserCheckIsShowing = false;
                this.initNextPrompt();
            };
            browserCheck.text    = await this.translate.get('ui.browserUpdate').toPromise();
            const browserUpdate  = await import('browser-update');
            browserUpdate.default(browserCheck);
            if (!this.browserCheckIsShowing) {
                this.initNextPrompt();
            }
        }
        else {
            this.initNextPrompt();
        }
    }


    onPageNavEnd(event:NavigationEnd) {
        // Ensure a URL change as query change will trigger this too
        let newUrl = event.url;
        if (newUrl.indexOf('?') !== -1) newUrl = newUrl.substr(0, newUrl.indexOf('?'));

        if (this.lastUrl !== newUrl && !this.urlService.isSearchResultsUrl(newUrl)) {
            this.logger.debug('Router change to other than search, clearing search bar', event);
            this.store.dispatch(new UpdateCurrentSearchTerm(null));
        }
        this.lastUrl = newUrl;
    }

    onPageNavStart(event:NavigationStart) {
        // Divert traffic
        this.configRedirects = environment.config.redirects;
        const matched        = this.urlService.matchTrafficRedirects(event.url, this.configRedirects);
        if (matched != null) {
            this.handleLink({href: matched.targetURL, isBackLink: false, newTab: false, bypassNewTab: true});
        }

        if (event.id !== 1) this.checkNavigationStates(event); // ignore the first event (first page load) as this should not be added to the history
    }

    async checkNavigationStates(event:NavigationStart) {
        /*
        Manage Navigation States
        --------------------------------------------
        */
        // The "navigationTrigger" will be one of:
        // - imperative (ie, user clicked a link).
        // - popstate (ie, browser controlled change such as Back button / Forward button).
        // - hashchange
        // events without restored state was entered manually
        // forward browser navigation will also trigger this state (should not be an issue as that can also be treated as history navigation)
        this.storeSelect(s => s.history.history)
            .pipe(take(1))
            .subscribe(history => {
                const lastHistory = history[history.length - 1];
                if (event.navigationTrigger === 'popstate') {
                    if (lastHistory) {
                        if (lastHistory.path === event.url) {
                            // found, assume this was a browser back event
                            this.logNavigationState('back');
                        }
                        else if (this.urlToEndpoint(lastHistory.path) !== this.urlToEndpoint(event.url)) {
                            // not found, assume that this is a browser forward or URL change event
                            this.logNavigationState('forward');
                        }
                    }
                    else {
                        // no history found (greater than 10 back steps)
                        // this should then resume as normal on popstates
                        // this would mean no browser forward back states would be captured
                    }
                }
                else {
                    if (!lastHistory || this.urlToEndpoint(event.url) !== this.urlToEndpoint(lastHistory.path)) this.logNavigationState('forward');
                }
            });
    }

    async logNavigationState(dir:'back' | 'forward') {
        if (dir === 'back') {
            this.store.dispatch(new History.SetBack());
        }
        else if (dir === 'forward') {
            const state = await this.getNavigationState();
            this.store.dispatch(new History.AddHistory(state));
        }
    }

    async getNavigationState() {
        const path:string            = this.router.url;
        let state:HistoryStateObject = {
            path       : path,
            domPosition: {
                x: 0,
                y: 0
            }
        };
        if (this.facadePlatform.isDesktop) {
            // Desktop
            state.domPosition.x = this.windowRef.scrollX ? this.windowRef.scrollX : 0,
                state.domPosition.y = this.windowRef.scrollY ? this.windowRef.scrollY : 0;
        }
        else if (this.facadePlatform.isMobile) {
            // Mobile
            const el = await this.facadePlatform.getScrollElement();
            if (el) {
                state.domPosition.x = el.scrollLeft;
                state.domPosition.y = el.scrollTop;
            }
        }
        return state;
    }

    urlToEndpoint(url:string) {
        const index1 = url.indexOf('?');
        if (index1 !== -1) url = url.substr(0, index1);
        const index2 = url.indexOf('#');
        if (index2 !== -1) url = url.substr(0, index2);
        if (url.lastIndexOf('/') === url.length - 1) url = url.substr(0, url.lastIndexOf('/'));
        return url;
    }

    reload() {
        this.logger.info('Reloading the page due to a click event');
        this.windowRef.reload();
    }

    handleLinkAndReload(event:FakeLinkEvent) {
        // restart the app
        this.windowRef.nativeWindow.location.href = event.href;
    }

    get name() {
        return 'AppComponent';
    }

    get isLazyLoading$() {
        if (!this._isLazyLoading$) {
            this._isLazyLoading$ = this.router.events.pipe(
                skipWhile(event => !(event instanceof RouteConfigLoadStart || event instanceof RouteConfigLoadEnd)),
                /*filter((event:RouteConfigLoadStart | RouteConfigLoadEnd) => !IdlePreloadingStrategy.isRouteIdlePreloading(event)),*/
                map(event => event instanceof RouteConfigLoadStart),
                startWith(false),
                distinctUntilChanged()
            );
        }
        return this._isLazyLoading$;
    }

    lazyLoadEarly() {
        // This happens as soon as there is quiet on the XHR requests.
        // Previously it was moved to the below method but there were problems with the stats and crazy egg
        this.logger.info('Running lazyLoadEarly()');
        if (environment.config.api.gtmTracker && this.isBrowser) GoogleTagManager.embedWithTracker(this.firebaseWrapperService, environment.config.api.gtmTracker);
        // Currently not in use
        //if (environment.config.api.gotbotScriptUrl && this.isBrowser) GotbotManager.init(environment.config.api.gotbotScriptUrl, environment.config.api.gotbotPercentageThrottle, this.storageService);
        const crazyEggEnabled = this.injector.get(StorageService).getItemBool(StaticConfig.ENABLE_CRAZYEGG, true);
        if (environment.config.api.crazyeggScriptUrl && this.isBrowser && crazyEggEnabled) CrazyEggManager.init(environment.config.api.crazyeggScriptUrl);
        if (environment.config.api.segmentifyScriptUrl && environment.config.api.segmentifyEnabled) ScriptLoader.loadNewScript(environment.config.api.segmentifyScriptUrl).catch(e => console.error(e));
    }

    lazyLoadLate() {
        this.logger.info('Running lazyLoadLate()');

        // Sentry
        this.runtimeErrorHandlerService.init();

        // If to safeguard against multiple init events (which would be a bug)
        if (!this.promptCycleStarted && this.isBrowser) {
            this.promptCycleStarted = true;

            // Slight delay here so not everything is shown at once
            setTimeout(() => {
                this.initNextPrompt();
            }, 1000);
        }


        this.firebaseWrapperService.setupMessageHandler();

        this.startLoadingAppConfig();
    }

    /*
    App messages
    --------------------------------------------
    */
    startLoadingAppConfig() {
        this.addSub = this.wordpressDynamicSettingsService.getDynamicSettingsOnInterval()
            .subscribe(res => {
                if (res.responseCode === ResponseCode.SUCCESS) {
                    this.logger.debug('Received an updated dynamic app config: ', res);
                    this.appDynamicConfig = res.data;
                    this.populateNextAppConfig();
                }
                else {
                    this.logger.error('Error on dynamic app config ', res);
                }
            })
        ;
    }

    populateNextAppConfig() {
        if (this.appDynamicConfig.appMessages.length > 0) {

            if (this.appMessage?.message !== this.appDynamicConfig.appMessages[0].message || this.appMessage?.id !== this.appDynamicConfig.appMessages[0].id) {
                this.appMessage = this.appDynamicConfig.appMessages[0];
                this.changeDetectorRef.detectChanges();
            }

        }
        else {
            this.appMessage = null;
            this.changeDetectorRef.detectChanges();
        }
    }

    dismissAppAlert() {
        this.appDynamicConfig.appMessages = this.wordpressDynamicSettingsService.dismissAppMessage(this.appDynamicConfig.appMessages);
        this.appMessage                   = null;
        this.changeDetectorRef.detectChanges();
        // So there is a visual delay
        setTimeout(() => this.populateNextAppConfig(), 500);
    }
}
