import {ChangeDetectorRef, Component, Injector, Input, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({template: ''})
export abstract class AbstractFormMessagesComponent implements OnDestroy {

    private _control:FormControl;
    private statusSub:Subscription;

    private changeDetectorRef:ChangeDetectorRef;

    constructor(injector:Injector) {
        this.changeDetectorRef = injector.get(ChangeDetectorRef);
    }

    @Input()
    set control(value:FormControl) {
        this._control = value;
        if (this.statusSub) this.statusSub.unsubscribe();
        this.statusSub = this._control.statusChanges.subscribe(() => this.changeDetectorRef.detectChanges());
    }

    get control() {
        return this._control;
    }

    // An array of validator names not to display
    // Create a hash for fast access
    @Input()
    set errorExceptions(value:string[]) {
        this.exceptionHash = {};
        if (value) value.forEach(exception => this.exceptionHash[exception] = true);
    }

    private exceptionHash:{ [name:string]:boolean } = {};

    getMessages() {
        const messages = [];
        if (this.control.errors) {
            for (const errorName in this.control.errors) {
                if (this.control.errors.hasOwnProperty(errorName) && !this.exceptionHash[errorName]) {
                    const errorObj = this.control.errors[errorName];
                    if (typeof errorObj === 'object' && errorObj.hasOwnProperty('message')) {
                        messages.push(errorObj.message);
                    }
                    else {
                        messages.push(errorName + ' error: ' + JSON.stringify(errorObj));
                    }
                }
            }
        }
        return messages.join('<br />');
    }

    ngOnDestroy():void {
        if (this.statusSub) this.statusSub.unsubscribe();
    }
}
