import {gql} from 'apollo-angular';

export const REGISTER_VALIDATE_OTP = gql`
    mutation registerValidateOtp(
        $cellphoneNumber: String = "default"
        $token: String = "default"
    ) {
        registerValidateOtp(cellphone_number: $cellphoneNumber, otp: $token) {
            user_id
            magento_token
            plus_more_opt_in
        }
    }
`;
