import {ChangeDetectionStrategy, Component, Injector, ViewChild} from '@angular/core';
import {AbstractProductSearchBarRendererComponent} from '../../../../../cross-platform/menu/search/abstract-product-search-bar-renderer.component';
import {DScrollingContainerDirective} from '../../../shared/ui/d-scrolling-container.directive';

@Component({
    selector       : 'product-search-bar-renderer',
    templateUrl    : './product-search-bar-renderer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductSearchBarRendererComponent extends AbstractProductSearchBarRendererComponent {

    @ViewChild(DScrollingContainerDirective)
    scroller:DScrollingContainerDirective;

    constructor(public injector:Injector) {
        super(injector);
    }

    scrollToIndex(index:number, isDown:boolean) {
        if (this.scroller) this.scroller.scrollToIndex(index, isDown);
    }
}
