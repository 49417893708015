<div class="menu-top clearfix">
    <!--    <div class="left">-->
    <!--        <span [innerHTML]="'home.customerServiceWithLink' | translate"></span> |-->
    <!--        <a [fakeLink]="urlService.buildUrlForContact()" (fakeClick)="handleLink($event)">{{'menu.contactUs' | translate}}</a>-->
    <!--    </div>-->
    <div class="logo-left">
        <a role="button" class="home-button home-button-with-link" [fakeLink]="urlService.buildUrlForHome()" (fakeClick)="handleLink($event)" [attr.title]="'menu.home' | translate"></a>
        <span class="home-button home-button-no-link"></span>
    </div>
    <div class="right header-button-bar" *ngIf="isBrowser">
        <search-bar-generic [isSearchResultsPage]="isSearchResultsPage" [dropDownReference]="searchResults" [placeholder]="'search.placeholder' | translate" [dataSource]="productSearchDataSource">
            <product-search-bar-renderer #searchResults></product-search-bar-renderer>
        </search-bar-generic>
        <a [fakeLink]="urlService.buildUrlForStoreFinder()" (fakeClick)="handleLink($event)" class="button-bar-button store-finder-button">
            <facade-icon slot="icon-only" icon="icon-map-pin"></facade-icon>
        </a>
        <!--        <a [fakeLink]="PepCMSPageLinks.feelGoodFm" (fakeClick)="handleLink($event)" class="feelgood-fm-header-link">-->
        <!--            <facade-icon icon="icon-feel-good-fm"></facade-icon>{{'menu.feelGoodFM' | translate}}</a> |-->
        <!--        <a [fakeLink]="urlService.buildUrlForStoreFinder()" (fakeClick)="handleLink($event)">{{'menu.findAStore' | translate}}</a>-->
    </div>
</div>

<div class="menu-main clearfix">
    <ng-container *ngIf="isBrowser">
        <ng-container *ngFor="let item of menuItems">
            <ng-container *ngIf="item.dropDown">
                <menu-button [menuData]="{item:item, categoryUrlKeyHash:categoryUrlKeyHash}" [dropDownElement]="menu" (select)="handleLink($event)" [class]="item.cssClasses"></menu-button>
                <column-menu [menuData]="{dropDown:item.dropDown, sizes:sizes, categoryUrlKeyHash:categoryUrlKeyHash, parentItem:item}" #menu></column-menu>
            </ng-container>
            <ng-container *ngIf="!item.dropDown">
                <menu-button [menuData]="{item:item, categoryUrlKeyHash:categoryUrlKeyHash}" (select)="handleLink($event)" [class]="item.cssClasses"></menu-button>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<!--Enable / Disable Search-->
