import {Component, ElementRef, Injector, Input, ViewChild} from '@angular/core';
import {BuildCustomFormElementProvider} from '../../../cross-platform/forms/abstract-form.component';
import {AbstractProxyFormComponent} from '../../../cross-platform/forms/abstract-proxy-form.component';

@Component({
    selector   : 'd-form-input',
    templateUrl: './d-form-input.component.html',
    providers  : [BuildCustomFormElementProvider(DFormInputComponent)]
})
export class DFormInputComponent extends AbstractProxyFormComponent {
    @ViewChild('mainInput') inputElement:ElementRef;

    @Input() label:string;
    @Input() showRequired:boolean;
    @Input() maxChars:number;
    @Input() placeHolder:string;
    @Input() tabindex:number;
    @Input() type           = 'text';
    @Input() autocomplete   = 'off';
    @Input() showGoogleLogo = false;
    @Input() autofocus      = false;

    private focusWasSet = false;

    constructor(injector:Injector) {
        super(injector);
    }

    get inputClass() {
        return this.control.invalid && this.control.touched ? 'is-invalid' : (this.control.touched ? 'is-valid' : '');
    }

    setFocus() {
        if (this.inputElement?.nativeElement) this.inputElement.nativeElement.focus();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.autofocus && !this.focusWasSet) {
                this.setFocus();
                this.focusWasSet = true;
            }
        }, 100);
    }
}
