import {BreadcrumbLink} from '../../../shared/ui/breadcrumb/breadcrumb.types';

// Critical that these align with the interface below
export const WordpressSlugFields = ['id', 'slug'];
export const WordpressPageFields = ['id', 'slug', 'template', 'link', 'parent', 'title', 'acf'];
export const WordpressPostFields = ['id', 'date_gmt', 'modified_gmt', 'slug', 'link', 'title', 'author', 'categories', 'tags', 'acf'];

export interface WordpressPage<T = any, C = any> {
    id:number;
    slug?:string;
    template?:string;
    link?:string;
    parent?:number | string;
    title?:{ rendered:string, raw:string };
    acf:T;
    // Used if we want to build up a tree in the codebase
    children?:WordpressPage<C>[];
    // Used in sitemap: 2019-10-24T14:58:41
    modified_gmt?:string;
    // Built up as part of the post processing
    breadcrumbs?:BreadcrumbLink[];
}

export interface WordpressListResponse {
    totalPages:number;
    currentPage:number;
    hasPrevPage:boolean;
    hasNextPage:boolean;
    posts:WordpressPost<DynamicPageACF>[];
}

export interface WordpressPost<T = any> {
    id:number;
    date_gmt?:string;
    // Used in sitemap: 2019-10-24T14:58:41
    modified_gmt?:string;
    slug?:string;
    link?:string;
    title?:{ rendered:string, raw:string };
    author?:number;
    categories:number[];
    categoriesPopulated:CategoryOrTag[];
    tags:number[];
    tagsPopulated:CategoryOrTag[];
    acf:T;
    // Built up as part of the post processing
    breadcrumbs?:BreadcrumbLink[];
    postImages?:BlogPostImagesACF;
    youtubeComp?:YoutubeComponentACF;
}

export interface CategoryOrTagResponse {
    slugHash:{ [key:string]:CategoryOrTag };
    idHash:{ [key:number]:CategoryOrTag };
    list:CategoryOrTag[];
    hierarchicalList?:CategoryOrTag[];
}

export interface CategoryOrTag {
    id:number;
    type:'category' | 'tag';
    name:string;
    description:string;
    slug:string;
    parent:number | string;
    count:number;
    // Locally parsed hierarchy
    children?:CategoryOrTag[];
}

export interface RelatedPosts {
    totalPages:number;
    page:number;
    from:{ [key:string]:WordpressPost };
    to:{ [key:string]:WordpressPost };
}

export interface DynamicPageACF {
    components:ACFComponent[];
    promotionalPost?:number;
    popups?:PopupConfigComponentACF[];
}

export class WordpressConstants {
    static DESKTOP        = 'desktop';
    static MOBILE         = 'mobile';
    static DESKTOP_MOBILE = 'both';

    static MEDIA_IMAGES   = 'images';
    static MEDIA_YOU_TUBE = 'youTube';
    static MEDIA_VIDEO    = 'video';

    static MEDIA_IMAGE_MAP = 'imageMap';

    static HOME_TEMPLATE         = 'home-page.php';
    static CATEGORY_TEMPLATE     = 'category-page.php';
    static SUB_CATEGORY_TEMPLATE = 'sub-category-page.php';
    static DYNAMIC_PAGE          = 'component-page.php';

    static BANNER_TYPE_CATEGORY     = 'categoryPage';
    static BANNER_TYPE_SUB_CATEGORY = 'subCategoryPage';
}

export enum ComponentTypesACF {
    // NB: If you add to this list, ensure you update the wp-check-links
    bannerScroller              = 'bannerScroller',
    bannerScrollerWithText      = 'bannerScrollerWithText',
    complexGrid                 = 'complexGrid', // Can't change the string value as it ties to the ACF field
    dynamicGrid                 = 'dynamicGrid',
    image                       = 'image',
    iframe                      = 'iframe',
    accordion                   = 'accordion',
    productCarousel             = 'productCarousel',
    youtube                     = 'youtube',
    wysiwyg                     = 'wysiwyg',
    catalogues                  = 'catalogues',
    productList                 = 'productList',
    header                      = 'header',
    seoMetadata                 = 'seoMetadata',
    onPageMenuConfig            = 'onPageMenuConfig',
    onPageMenuPlacement         = 'onPageMenuPlacement',
    customEmbed                 = 'customEmbed',
    reusableDefinition          = 'reusableDefinition',
    reusableDefinitionPlacement = 'reusableDefinitionPlacement',
    cincopaComponent            = 'cincopaComponent',
    viralsweep                  = 'viralsweep',
    blogPostImages              = 'blogPostImages',
    blogPostList                = 'blogPostList',
    seoDisable                  = 'seoDisable',
    cataloguesEmbeds            = 'cataloguesEmbeds',
    popupConfig                 = 'popupConfig',
    linkTags                    = 'linkTags',
    contentItems                = 'contentItems',
    menuPageConfig              = 'menuPageConfig',
    breadcrumbs                 = 'breadcrumbs',
    imageMap                    = 'imageMap',
    video                       = 'video'
}

export enum DisplayTypesACF {
    // NB: If you add to this list, ensure you update the wp-check-links
    always    = 'always',
    loggedIn  = 'loggedIn',
    loggedOut = 'loggedOut'
}

export enum AlignmentACF {
    center = 'center',
    left   = 'left',
    right  = 'right'
}

export type ACFComponentList =
    Array<ACFComponent>
    | 'no-data';


// Please see non renderable component list below
// always keep that list updated if you add a new component that's non renderable
export type ACFComponent =
    HeaderComponentACF
    | BannerScrollerComponentACF
    | BannerScrollerWithTextComponentACF
    | ComplexGridComponentACF
    | DynamicGridComponentACF
    | ProductListComponentACF
    | SEOMetadataComponentACF
    | YoutubeComponentACF
    | ImageComponentACF
    | AccordionComponentACF
    | ProductCarouselComponentACF
    | CataloguesComponentACF
    | WysiwygComponentACF
    | OnPageMenuConfigComponentACF
    | OnPageMenuPlacementComponentACF
    | CustomEmbedACF
    | ReusableDefinitionACF
    | ReusableDefinitionPlacementACF
    | IframeComponentACF
    | CincopaComponentACF
    | ViralSweepComponentACF
    | BlogPostImagesACF
    | BlogPostListACF
    | SEODisableComponentACF
    | CataloguesEmbedsComponentACF
    | PopupConfigsComponentACF
    | ContentItemsACF
    | LinkTagsACF
    | MenuPageConfigComponentACF
    | BreadcrumbsComponentACF
    | ImageMapComponentACF
    | VideoComponentACF;

// These are added on a lot of components, not all
export interface ACFBase {
    show_after?:string;
    hide_after?:string;
    environments?:string;
}

// IMPORTANT: Keep this list up to date!
export const NonRenderableComponents = [
    ComponentTypesACF.seoMetadata,
    ComponentTypesACF.onPageMenuConfig,
    ComponentTypesACF.reusableDefinition
];

export interface ACFComponentBase {
    show_after?:string;
    hide_after?:string;
}

export interface MenuPageConfigComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.menuPageConfig;
    display:string;
    menuAdvertImage:string;
    menuAdvertLink:string;
}

export interface PopupConfigsComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.popupConfig;
    display:string;
    popups:PopupConfigComponentACF[];
}

export interface ImageMapComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.imageMap;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    anchor?:string;
    imageMap:any[];
    altTag:string;
    alignment:AlignmentACF;
    height?:number;
    width?:number;
}

export interface BreadcrumbsComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.breadcrumbs;
    display:string;
    environments:string;
}

export interface PopupConfigComponentACF {
    name:string;
    environments:string;
    contentPageId:number;
    pageIds:number[];
    startDate:string | Date;
    endDate:string | Date;
    titlebarColour:string;
    backgroundColour?:string;
    desktopWidth?:number;
    desktopHeight?:number;
    desktopAutoHeight?:boolean;
    mobileWidth?:number;
    mobileMaxWidth?:number;
    mobileHeight?:number;
    mobileAutoHeight?:boolean;
}

export interface LinkTagsACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.linkTags;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    anchor?:string;
    title:string;
    items:{ label:string, link?:string }[];
}

export interface ContentItemsACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.contentItems;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    anchor?:string;
    randomize?:boolean;
    title:string;
    link:string;
    linkLabel:string;
    style:'style1' | 'style2' | 'style3' | 'style4';
    items:ContentItemRowItemACF[];
}

export interface ContentItemRowItemACF {
    text:string;
    link:string;
    imageXL:WordpressImage;
    imageSM:WordpressImage;
    imageMap:any[];
}


export interface SEOMetadataComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.seoMetadata;
    description:string;
    display:string;
    title:string;
    breadcrumbLabel:string;
}

export interface SEODisableComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.seoDisable;
    display:string;
    disableIndexing:boolean;
}

export interface AccordionComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.accordion;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    rows:Array<AccordionComponentRowACF>;
}

export interface ProductCarouselComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.productCarousel;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    anchor?:string;
    autoplay:boolean;
    productCount:number;
    // Category ids are infact urlKeyPaths but the name remains.
    // CMS update didn't seem worth the trouble as no one even uses this
    productSelectionType:'categoryIds' | 'productSkus' | 'searchQuery';
    categoryIDs?:string;
    productSkus?:string;
    searchQuery?:string;
}

export interface AccordionComponentRowACF {
    title:string;
    anchor?:string;
    components:Array<ACFComponent>;
}


export interface YoutubeComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.youtube;
    display:DisplayTypesACF;
    cssClasses?:string;
    anchor?:string;
    environments:string;
    loop:boolean;
    mute:boolean;
    hideControls:boolean;
    autoplay:boolean;
    height:number;
    alignment:AlignmentACF;
    maxWidthValue:number;
    widthType:'fullWidth' | 'maxWidth';
    youtubeVideoID:string;
    duration?:number;
}

export interface VideoComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.video;
    display:DisplayTypesACF;
    cssClasses?:string;
    anchor?:string;
    environments:string;
    loop:boolean;
    mute:boolean;
    autoplay:boolean;
    hideControls:boolean;
    height:number;
    alignment:AlignmentACF;
    widthType:'fullWidth' | 'maxWidth';
    heightType:'fullHeight' | 'maxHeight';
    maxWidthValue:number;
    maxHeightValue:number;
    video:string;
    link:string;
}

export interface ImageComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.image;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    anchor?:string;
    image:WordpressImage;
    altTag:string;
    link:string;
    backgroundImage:boolean;
    responsive:boolean;
    alignment:AlignmentACF;
    height?:number;
    width?:number;
    countdownTimerEnabled?:boolean;
    countdownDate?:string;
    countdownMode?:'light' | 'dark';
    countdownSize?:'small' | 'medium' | 'large';
    countdownPosition?:'topleft' | 'topcenter' | 'topright ' | 'middleleft' | 'middlecenter' | 'middleright' | 'bottomleft' | 'bottomcenter' | 'bottomright';
    countdownCssClasses?:string;
}

export interface IframeComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.iframe;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    anchor?:string;
    iframeUrl:string;
    responsive:boolean;
    alignment:AlignmentACF;
    height?:number;
    width?:number;
}

export interface CincopaComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.cincopaComponent;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    anchor?:string;
    templateId:string;
    galleryId:string;
}

export interface ViralSweepComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.viralsweep;
    display:DisplayTypesACF;
    environments:string;
    anchor:string;
    cssClasses?:string;
    fileId:string;
    scriptId:string;
    embedType:'widget' | 'embed' | 'lightbox' | 'full';
}

export interface HeaderComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.header;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    anchor?:string;
    useDefaultHeader:boolean;
    customHeaderText?:string; // can be false
}

export interface ProductListComponentACF {
    acf_fc_layout:ComponentTypesACF.productList;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    anchor?:string;
    showFilters:boolean;
    rowConfiguration:ProductListRowConfigurationACF[];
    orderDefault?:number;
}

export interface ProductListRowConfigurationACF {
    desktopHero:boolean;
    mobileHero:boolean;
}

export interface BannerScrollerComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.bannerScroller;
    display:DisplayTypesACF;
    cssClasses?:string;
    anchor?:string;
    autoPlayVideos:boolean;
    bannerScrollerData:BannerScrollerComponentEntryACF[];
    randomize?:boolean;
    type?:string;

    // Actually a number
    bannerScrollerSize:string;
    // Determined at runtime from the above and config
    ratioWidth?:number;
    ratioHeight?:number;

    // Swiper data resolved once on load
    //swiperData?:FacadeSwiperItem[];
}

export interface BannerScrollerComponentEntryACF {
    mediaType:string;
    environments:string;
    youTubeVideoId:string;
    altTag:string;
    link:string;
    imageXL:WordpressImage;
    //imageLG?:WordpressImage;
    imageSM:WordpressImage;

    imageMap?:any[];
    desktopVideo:string;
    mobileVideo:string;
}

export interface BannerScrollerWithTextComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.bannerScrollerWithText;
    display:DisplayTypesACF;
    cssClasses?:string;
    anchor?:string;
    bannerScrollerData:BannerScrollerWithTextComponentEntryACF[];
    randomize?:boolean;

    // Actually a number
    bannerScrollerSize:string;
    // Determined at runtime from the above and config
    ratioWidth?:number;
    ratioHeight?:number;

    // Swiper data resolved once on load
    //swiperData?:FacadeSwiperItem[];
}

export interface BannerScrollerWithTextComponentEntryACF {
    environments:string;
    imageXL:WordpressImage;
    imageSM:WordpressImage;
    cssClasses:string;
    bannerText:string;

    imageMap:any[];
}

export interface ComplexGridComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.complexGrid;
    display:DisplayTypesACF;
    cssClasses?:string;
    anchor?:string;
    environments:string;
    blockSpacing?:string; // Number or empty string
    gutterSize?:string; // Number or empty string
    spaceBefore?:string; // Number or empty string
    spaceAfter?:string; // Number or empty string
    rows:GridRowACF[];
}

export interface WysiwygComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.wysiwyg;
    display:DisplayTypesACF;
    cssClasses?:string;
    anchor?:string;
    backgroundColour?:string;
    environments:string;
    wysiwyg:string;
}

export interface CataloguesComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.catalogues;
    display:DisplayTypesACF;
    cssClasses?:string;
    environments:string;
    anchor?:string;
    catalogues:CatalogueACF[];
}

export interface CataloguesEmbedsComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.cataloguesEmbeds;
    display:DisplayTypesACF;
    environments:string;
    landingpageMessage:string;
    unavailableMessage:string;
    cataloguesEmbeds:CatalogueEmbedACF[];
}

export interface BlogPostListACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.blogPostList;
    display:DisplayTypesACF;
    cssClasses?:string;
    environments:string;
    anchor?:string;
    showBlogPosts:boolean;
}

export interface CatalogueACF {
    label:string;
    url:string;
    image:WordpressImage;
    startDate:string;
    endDate:string;
    uniqueID:string;

    // Only used in the UI
    startDateObj?:Date;
    endDateObj?:Date;
}

export interface CatalogueEmbedACF {
    title:string;
    yumpuID:string;
    startDate:string | Date;
    endDate:string | Date;
}

export interface GridRowACF {
    maxHeight?:string; // Number or empty string
    equalCellSizes:boolean;
    cells:GridCellACF[];
}

export interface GridCellACF {
    width:string; // Number %
    image:WordpressImage;
    imageMap:any[];
    altTag:string;
    link?:string;

    mediaType?:string;
}

export interface DynamicGridComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.dynamicGrid;
    environments:string;
    display:DisplayTypesACF;
    anchor?:string;
    spaceBefore?:string; // Number or empty string
    gutterSize?:string; // Number or empty string
    cssClasses?:string;
    totalColumns?:string; // Number or empty string
    totalRows?:string; // Number or empty string
    cellsHaveSpacing?:boolean;
    blockSpacing?:string; // Number or empty string
    fullGridWidth?:string; // Number or empty string
    cells?:DynamicGridCell[];
}

export interface DynamicGridCell {
    // Not really ALL the components, in wordpress we restrict them but easier for types
    // and in theory the code could handle infinite recursion
    components:ACFComponent[];

    columnEnd?:string;
    columnStart?:string;
    rowEnd?:string;
    rowStart?:string;
    backgroundColor?:string;
    cssClasses?:string;
}

export interface OnPageMenuConfigComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.onPageMenuConfig;
    display:DisplayTypesACF;
    environments:string;
    menuItems:OnPageMenuItemACF[];
}

export interface OnPageMenuItemACF {
    label:string;
    link:string;
    cssClasses?:string;
}

export interface OnPageMenuPlacementComponentACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.onPageMenuPlacement;
    display:DisplayTypesACF;
    environments:string;
    cssClasses?:string;
    onPageMenuConfig?:OnPageMenuConfigComponentACF;
}

export interface ReusableDefinitionACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.reusableDefinition;
    environments:string;
    display:DisplayTypesACF;
    reusableDefinitionId:string;
    components:ACFComponent[];
}

export interface ReusableDefinitionPlacementACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.reusableDefinitionPlacement;
    environments:string;
    display:DisplayTypesACF;
    anchor?:string;
    cssClasses?:string;
    reusableDefinitionId:string;
    components?:ACFComponent[];
}

export interface WordpressImage {
    //ID:number;
    //alt:string;
    //caption:string;
    //description:string;
    url:string;
    width:number;
    height:number;
    //name:string;
}

export interface CustomEmbedACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.customEmbed;
    display:DisplayTypesACF;
    environments:string;
    anchor:string;
    cssClasses?:string;
    componentName:string;
    jsonParams?:string;
}

export interface BlogPostImagesACF extends ACFComponentBase {
    acf_fc_layout:ComponentTypesACF.blogPostImages;
    display:DisplayTypesACF;
    desktopPostHeader:string;
    desktopFeatured:string;
    desktopPreview:string;
    desktopThumbnail:string;
    desktopPromotional:string;
    mobilePostHeader:string;
    mobileFeatured:string;
    mobilePreview:string;
    mobileThumbnail:string;
    mobilePromotional:string;
    altTag:string;
}

export enum RootConfigTypesACF {
    bannerConfig = 'bannerConfig'
}

export interface RootConfigPageACF {
    config:Array<RootConfigBannersACF>;
}

export interface RootConfigBannersACF {
    acf_fc_layout:RootConfigTypesACF.bannerConfig;
    bannerSizes:BannerSizeConfigACF[];
}

export interface BannerSizeConfigACF {
    // Actually all numbers
    desktopWidth:string;
    mobileWidth:string;
    mobileHeight:string;
    desktopHeight:string;
}


/*
ES Search
--------------------------------------------
*/
export interface ESWPSearch {
    post_id:number;
    ID:number;
    post_author:ESWPAuthor;
    post_date:string;
    post_date_gmt:string;
    post_title:string;
    post_excerpt:string;
    post_content_filtered:string;
    post_content:string;
    post_status:string;
    post_name:string;
    post_modified:string;
    post_modified_gmt:string;
    post_parent:number;
    post_type:string;
    post_mime_type:string;
    permalink:string;
    terms:string;
    meta:ESWPMeta;
    date_terms:ESWPDateTerms;
    comment_count:number;
    comment_status:string;
    ping_status:string;
    menu_order:string;
    guid:number;
    // This is a list of websites this page / post should be included in
    // This is built up in indexing by looking at the page parents
    // Posts are hard coded for all root pages atm
    // Its used to do website based searching
    websites:string[];
    // on pages, this is built up from the slug or title of each parent
    // This is done on the index
    parent_slug_chain:string;
}

export interface ESWPAuthor {
    raw:string;
    login:string;
    display_name:string;
    id:number;
}

export interface ESWPMeta {
    seoBreadcrumbLabel:ESWPMetaItem[];
    seoDescription:ESWPMetaItem[];
    seoTitle:ESWPMetaItem[];
    wysiwyg:ESWPMetaItem[];
}

export interface ESWPMetaItem {
    value:string;
    raw:string;
    boolean:boolean;
    date:string;
    datetime:string;
    time:string;
}

export interface ESWPDateTerms {
    year:number;
    month:number;
    week:number;
    dayofyear:number;
    day:number;
    dayofweek:number;
    dayofweek_iso:number;
    hour:number;
    minute:number;
    second:number;
    m:number;
}


/*
Archive, old format:
--------------------------------------------
*/
export interface HomePageV1ACF {
    randomize:boolean;
    autoPlayVideos:boolean;
    bannerScrollerData:BannerScrollerV1Item[];
    complexGrids:ComplexGridComponentACF[] | boolean;
}

export interface CategoryPageV1ACF {
    showProducts:boolean;
    showHeader:boolean;
    showFilters?:boolean;

    bannerScrollerEnabled:boolean;
    autoPlayVideos:boolean;
    bannerScrollerData?:BannerScrollerV1Item[];
    gridEnabled:boolean;
    gridData:GridV1Image[];
    complexGrids:ComplexGridComponentACF[] | boolean;
}

export interface BannerScrollerV1Item {
    altTag:string;
    mediaType:string;
    environments:string;
    youTubeVideoId?:string;

    link?:string;
    // urls
    image_xl:string;
    image_lg:string;
    image_sm:string;
}

export interface GridV1Image {
    altTag:string;
    link?:string;
    // urls
    image_xl:string;
    //image_lg:string;
    image_sm:string;
}

/*
App Dynamic Config
--------------------------------------------
*/
export interface AppDynamicConfig {
    appMessages?:AppDynamicConfigMessage[];
}

export enum AppDynamicMessageOccurrenceType {
    // NOTE: If you add to this list, ensure you update the app-messages acf field occurrence dropdown
    onceOff        = 'onceOff',
    recurringDaily = 'recurringDaily',
}

export interface AppDynamicConfigMessage {
    id:string;
    enabled:boolean;
    textColour:string;
    backgroundColour:string;
    startDate:string;
    endDate:string;
    startTime:string;
    endTime:string;
    occurrence:AppDynamicMessageOccurrenceType;
    message:string;
}
