import {gql} from 'apollo-angular';

export const UPDATE_CUSTOMER_ADDRESS = gql`
    mutation updateCustomerAddress($id:Int!, $address:CustomerAddressInput!) {
        updateCustomerAddress(id:$id, input:$address) {
            id
            city
            country_code
            default_billing
            default_shipping
            firstname
            lastname
            postcode
            street
        }
    }
`;
