import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CrossPlatformModule} from '../../../cross-platform/cross-platform.module';
import {GlobalSharedUIModule} from '../shared/ui/global-shared-ui.module';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FacadeFormsModule} from '../forms/facade-forms.module';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {NewsletterSignUpModalComponent} from './newsletter-sign-up-modal.component';
import {NewsletterSignUpComponent} from './newsletter-sign-up.component';
import {CustomerCommunicationService} from '../../../cross-platform/external-data/newsletter/customer-communication.service';

@NgModule({
    imports     : [
        CommonModule,
        CrossPlatformModule,
        GlobalSharedUIModule,
        TranslateModule,

        ReactiveFormsModule,
        FacadeFormsModule,

        NgbModalModule
    ],
    providers   : [
        CustomerCommunicationService
    ],
    declarations: [NewsletterSignUpComponent, NewsletterSignUpModalComponent],
    exports     : [NewsletterSignUpComponent, NewsletterSignUpModalComponent]
})
export class NewsletterSignupModule {
}
