export class RegexUtils {
    public static escape(source:string) {
        return source.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    public static escapeToReg(source:string, flags?:string) {
        return new RegExp(this.escape(source), flags);
    }

    public static replaceAll(source:string, strToLookFor:string, replacement:string):string {
        return source.replace(new RegExp(RegexUtils.escape(strToLookFor), 'g'), replacement);
    }

    public static getMatchedValue(source:string, reg:RegExp) {
        if (source && typeof source === 'string' && reg) {
            const matched = reg.exec(source);
            if (matched && matched.length > 0) return matched[0];
        }
        return null;
    }
}
