import {Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[domPosition]'
})
export class DomPositionDirective {

    constructor(private el:ElementRef) {
    }

    public getAbsoluteBounds():{ top:number, right:number, bottom:number, left:number, width:number, height:number } {
        const rect = this.el.nativeElement.getBoundingClientRect();
        // Clone so the object is editable
        return {
            left  : rect.left,
            top   : rect.top,
            right : rect.right,
            bottom: rect.bottom,
            width : rect.width,
            height: rect.height
        };
    }

    public getRelativeBounds():{ top:number/*, right:number, bottom:number, left:number, width:number, height:number*/ } {
        const rect = {
            top   : this.el.nativeElement.offsetTop,
            // Not yet implemented
            right : 0,
            bottom: 0,
            left  : 0,
            width : 0,
            height: 0
        };
        return rect;
    }

    public get offsetTop():number {
        return this.el.nativeElement.offsetTop;
    }

}
